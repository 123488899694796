import {START_TIMESHEET_FETCH,START_TIMESHEET_ADD,
ADDING_TIMESHEET_FAILED,TIMESHEET_FETCH_SUCCESS,
TIMESHEET_FETCH_FAILED,TIMESHEET_ADDED_SUCCESSFULLY} from "../actions/types";

const DEFAULT_STATE = {
    timesheet: {},
    loading: false,
    timesheetAddFailed:true,
    timesheetAddLoading:false,
    timesheetAddMessage:'',
    errorMessage:'',
    timesheetAddSuccess: false
}
export default (state = DEFAULT_STATE,action) => {
    switch(action.type){
    case START_TIMESHEET_ADD:
        return {
            ...state,
            timesheetAddLoading:true,
            timesheetAddFailed:false,
            timesheetAddMessage:'',
            timesheetAddSuccess: false
        }

    case TIMESHEET_ADDED_SUCCESSFULLY:
        return {
            ...state,
            timesheetAddLoading:false,
            timesheetAddFailed:false,
            timesheetAddMessage:action.payload,
            timesheetAddSuccess: true
        }
    case ADDING_TIMESHEET_FAILED:
        return {
            ...state,
            timesheetAddLoading:false,
            timesheetAddFailed:true,
            timesheetAddMessage:action.payload
        }

        case START_TIMESHEET_FETCH:
            return{
                ...state,
                loading:true,
                errorMessage:''
            }

        case TIMESHEET_FETCH_SUCCESS:
            return{
                ...state,
                loading:false,
                timesheet:action.payload
            }
        case TIMESHEET_FETCH_FAILED:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        default :
            return state

    }
}
