import { Card,Button } from '@material-ui/core';
import React from 'react';
import "./profile.css";
import { Row, Col } from 'react-bootstrap';
import {InputField} from '../../utils/formikComponents'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {usePasswordUpdate} from '../../hooks/profile-query'
import { AlertComponent} from "../../utils/formComponents";
import {logout} from "../../actions/authAction"
import { useDispatch } from 'react-redux';
    let initialValues = {
        old_password: '',
        new_password: '',
        confirm_new_password: ''
    }

export function Security(props){
    const {isLoading, mutate, data, error} = usePasswordUpdate()
    const [showDetails, setShowDetails] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const dispatch = useDispatch();
    React.useEffect(()=>{
        if(isLoading) setMessage("updating password,please wait on sucess you will be logged out")
        if(data && data.message) setMessage(data['message'])
        if(error) setMessage(error)
    },[data, isLoading, error])

    React.useEffect(()=>{
        if(!showDetails){
            if(data && data['status']){
                dispatch(logout())
            }
        }
    },[showDetails])
        function validate(values){
            let errors ={};
            Object.keys(values).forEach((key)=>{
                if(!values[key] || values[key].length < 6){
                    errors[key] ="Password should include 6 or more charecters"
                }
                if(key === 'confirm_new_password' && values[key] !== values['new_password']){
                    errors['confirm_new_password'] = 'confirm password should include 6 or more charecters and should match new password'
                }
            })
            return errors

        }
        return (
            <div className="body_background" >
                <Card className="P4" style={{backgroundColor:'#222b36', color:'azure' }}>
                    <Formik
                        validate={validate}
                        initialValues={initialValues}
                        onSubmit={async (values) => { setShowDetails(true); mutate({...values})}}
                    >
                        {({ values,errors, touched,setFieldValue }) => (
                        <Form>
                            <Row style={{ marginTop: '6%'}}>
                                <Col>
                                    <Field
                                        name="old_password"
                                        type="password"
                                        label="Enter old password"
                                    component={InputField}
                                    onChange={(event)=>{ setFieldValue('old_password',event.target.value)}}
                                    />
                                {errors.old_password && touched.old_password && <div  style={{ marginTop: '2%',alignItems: 'center'}}>{errors.old_password}</div>}

                                </Col>
                            </Row>
                            <Row style={{ marginTop: '6%'}}>
                                <Col>
                                    <Field
                                        name="new_password"
                                        type="password"
                                        label="Enter new password"
                                    component={InputField}
                                    onChange={(event)=>{ setFieldValue('new_password',event.target.value); }}
                                    />
                                    {errors.new_password && touched.new_password && <div  style={{ marginTop: '2%',alignItems: 'center'}}>{errors.new_password}</div>}

                                </Col>
                            </Row>
                            <Row style={{ marginTop: '6%'}}>
                                <Col>
                                    <Field
                                        name="confirm_new_password"
                                        type="password"
                                        label="Enter new password again"
                                        onChange={(event)=>{ setFieldValue('confirm_new_password',event.target.value)}}
                                        component={InputField}
                                    />
                                </Col>
                                {errors.confirm_new_password && touched.confirm_new_password && <div  style={{ marginTop: '2%',alignItems: 'center'}}>{errors.confirm_new_password}</div>}
                            </Row>
                            <Row style={{ marginTop: '6%', marginBottom: '6%'}}><Col sm={4}></Col>
                                <Col sm={8}>
                                    <Button type="submit" style={{backgroundColor:'#688eff' ,color:'azure', borderRadius:"20px",marginTop:"4px",outline:"none" }} variant="contained">Save Changes</Button></Col>
                            </Row>
                        </Form>
                        )}
                    </Formik>





                </Card>
                <AlertComponent title="Updating password" description = {message} saveText ={"OK"} open={showDetails} proceed={()=> setShowDetails(false)}/>

        </div>
    )
    }
