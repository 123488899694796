import {
    START_FETCH_PLACE_OF_SUPPLY,
    SUCCESS_FETCH_PLACE_OF_SUPPLY,
    FAILED_FETCH_PLACE_OF_SUPPLY
} from "../actions/types";

const DEFAULT_STATE = {
    placeOfSupplyList:[]
};

export default (state = DEFAULT_STATE, action) =>{
    switch(action.type){
        case START_FETCH_PLACE_OF_SUPPLY:
            return{
                ...state,
                placeOfSupplyList: []
            }
        case SUCCESS_FETCH_PLACE_OF_SUPPLY:
            return{
                ...state,
                placeOfSupplyList: action.payload
            }
        case FAILED_FETCH_PLACE_OF_SUPPLY:
            return{
                ...state,
                placeOfSupplyList: []
            }
        default:
            return state
    }
}

