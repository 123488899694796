import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { CircularProgress, Typography } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { CommonToolbar, CommonTable } from './components';
import styles from './style';

class CommonList extends Component {
  signal = true;

  state = {
    isLoading: false,
    limit: 10,

    clients: [],
    projects: [],
    invoices: [],
    reports: [],
    opes: [],
    project_class: [],
    client_class: [],

    users: [],
    selectedUsers: [],
    error: null,
  };

  componentDidMount() {
    this.signal = true;
    if (this.props.page === 'client') {
      //this.props.searchOver()
      this.props.fetchClient(this.props.token);
    } else if (this.props.page === 'inward_register') {
      this.props.fetchInwardRegisterList(this.props.token);
    } else if (this.props.page === 'holiday_master') {
      this.props.fetchHolidayList(this.props.token);
    } else if (this.props.page === 'attendence') {
      this.props.fetchAttendenceList(this.props.token);
    } else if (this.props.page === 'outward_register') {
      this.props.fetchOutwardRegisterList(this.props.token);
    } else if (this.props.page === 'invoice') {
      this.props.fetchInvoicesList(this.props.token);
    } else if (this.props.page === 'project') {
      //this.props.searchOver()
      this.props.fetchProject(this.props.token);
    } else if (this.props.page === 'pendingArchivedProjects') {
      //this.props.searchOver()
      this.props.fetchAllProjectByFilter(this.props.token, 'Pending_archival');
    } else if (this.props.page === 'archivedProjects') {
      //this.props.searchOver()
      this.props.fetchAllProjectByFilter(this.props.token, 'Archived');
    } else if (this.props.page === 'ope') {
      this.props.fetchOpeList(this.props.token);
    } else if (this.props.page === 'project_class') {
      this.props.fetchProjectSuperClassList(this.props.token);
    } else if (this.props.page === 'project_sub_class') {
      this.props.fetchProjectCategoryList(this.props.token);
    } else if (this.props.page === 'client_class') {
      this.props.fetchClientClassList(this.props.token);
    } else if (this.props.page === 'activity') {
      this.props.fetchActivity(this.props.token);
    } else if (this.props.page === 'movement_register') {
      this.props.fetchMovementRegistersList(this.props.token);
    } else if (this.props.page === 'employee') {
      this.props.fetchEmployeeList(this.props.token);
    } else if (this.props.page === 'organisation') {
      this.props.fetchOrganisation(this.props.token);
    } else if (this.props.page === 'nature_of_expense') {
      this.props.fetchNatureofexp(this.props.token);
    } else if (this.props.page === 'issue_register') {
      this.props.fetchIssueRegistersList(this.props.token);
    } else if (this.props.page === 'leave_management') {
      this.props.fetchLeaveList(this.props.token);
    } else if (this.props.page === 'certificate_register') {
      this.props.fetchCertificateRegistersList(this.props.token);
    } else if (this.props.page === 'audit_document_register') {
      this.props.fetchDocumentRegisterList(this.props.token);
    } else if (this.props.page === 'sac_master') {
      this.props.fetchSacmasterList(this.props.token);
    } else if (this.props.page === 'spec_master') {
      this.props.fetchSpecMasterList(this.props.token);
    } else if (this.props.page === 'settings') {
      this.props.fetchSettingList(this.props.token);
    } else {
    }
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSelect = (selectedUsers) => {
    this.setState({ selectedUsers });
  };

  renderUsers(page) {
    const { classes } = this.props;
    const { isLoading, users, error } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant='h6'>{error}</Typography>;
    }

    if (page === 'invoice') {
      if (this.props.invoicesList.length === 0) {
        return <Typography variant='h6'>There are no invoices</Typography>;
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.invoicesList}
            page={page}
          />
        );
      }
    } else if (page === 'sac_master') {
      if (this.props.sacMasterList.length === 0) {
        return (
          <Typography variant='h6'>Sac Master lists seems empty</Typography>
        );
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.sacMasterList}
            page={page}
          />
        );
      }
    } else if (page === 'spec_master') {
      if (this.props.specMasterList.length === 0) {
        return (
          <Typography variant='h6'>Spec Master lists seems empty</Typography>
        );
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.specMasterList}
            page={page}
          />
        );
      }
    } else if (page === 'holiday_master') {
      if (this.props.holidayMaster.length === 0) {
        return <Typography variant='h6'>Holiday list is empty!!</Typography>;
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.holidayMaster}
            page={page}
          />
        );
      }
    } else if (page === 'archivedProjects') {
      if (this.props.project.length === 0) {
        return (
          <Typography variant='h6'>Archived Project list is empty!!</Typography>
        );
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.project}
            page={page}
          />
        );
      }
    } else if (page === 'pendingArchivedProjects') {
      if (this.props.project.length === 0) {
        return (
          <Typography variant='h6'>
            {' '}
            Pending archival project list is empty!!
          </Typography>
        );
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.project}
            page={page}
          />
        );
      }
    } else if (page === 'attendence') {
      if (this.props.attendence.length === 0) {
        return <Typography variant='h6'>Attendece list is empty!!</Typography>;
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.attendence}
            page={page}
          />
        );
      }
    } else if (page === 'leave_management') {
      if (this.props.leaveList.length === 0) {
        return (
          <Typography variant='h6'>Active leave lists seems empty</Typography>
        );
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.leaveList}
            page={page}
          />
        );
      }
    } else if (page === 'inward_register') {
      if (this.props.inwardRegisterList.length === 0) {
        return (
          <Typography variant='h6'>
            Inward register lists seems empty
          </Typography>
        );
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.inwardRegisterList}
            page={page}
          />
        );
      }
    } else if (page === 'outward_register') {
      if (this.props.outwardRegisterList.length === 0) {
        return (
          <Typography variant='h6'>
            Outward register lists seems empty
          </Typography>
        );
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.outwardRegisterList}
            page={page}
          />
        );
      }
    } else if (page === 'nature_of_expense') {
      if (this.props.natureofexpense.length === 0) {
        return (
          <Typography variant='h6'>
            There are no nature of expense list
          </Typography>
        );
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.natureofexpense}
            page={page}
          />
        );
      }
    } else if (page === 'issue_register') {
      if (this.props.issueRegisterList.length === 0) {
        return <Typography variant='h6'>There is no DSCs saved yet</Typography>;
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.issueRegisterList}
            page={page}
          />
        );
      }
    } else if (page === 'certificate_register') {
      if (this.props.certificateRegisterList.length === 0) {
        return (
          <Typography variant='h6'>
            There is no Certificate registers saved yet
          </Typography>
        );
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.certificateRegisterList}
            page={page}
          />
        );
      }
    } else if (page === 'movement_register') {
      let temp = this.props.searchng
        ? this.props.movementRegisterSearchList
        : this.props.movementRegisterList;
      if (temp.length === 0) {
        return (
          <Typography variant='h6'>
            There is no movement registers saved yet
          </Typography>
        );
      } else {
        return (
          <CommonTable onSelect={this.handleSelect} users={temp} page={page} />
        );
      }
    } else if (page === 'organisation') {
      if (this.props.organisation.length === 0) {
        return <Typography variant='h6'>There are no organisations</Typography>;
      } else {
        return (
          <CommonTable
            onSelect={this.handleSelect}
            users={this.props.organisation}
            page={page}
          />
        );
      }
    } else if (page === 'project_sub_class') {
      if (this.props.projectCategory.length === 0) {
        return (
          <Typography variant='h6'>There are no project sub classes</Typography>
        );
      } else {
        return (
          <CommonTable
            //
            onSelect={this.handleSelect}
            users={this.props.projectCategory}
            page={page}
          />
        );
      }
    } else if (page === 'project_class') {
      if (this.props.projectSuperClass.length === 0) {
        return (
          <Typography variant='h6'>There are no project classes</Typography>
        );
      } else {
        return (
          <CommonTable
            //
            onSelect={this.handleSelect}
            users={this.props.projectSuperClass}
            page={page}
          />
        );
      }
    } else if (page === 'project') {
      let temp = this.props.searchng
        ? this.props.projectSearchList
        : this.props.project;
      if (temp && temp.length === 0) {
        return <Typography variant='h6'>There are no projects</Typography>;
      } else {
        return (
          <CommonTable
            //
            onSelect={this.handleSelect}
            users={temp}
            page={page}
          />
        );
      }
    } else if (page === 'client') {
      let temp = this.props.searchng
        ? this.props.clientSearchList
        : this.props.clients;
      if (temp.length === 0) {
        return <Typography variant='h6'>There are no clients</Typography>;
      } else {
        return (
          <CommonTable onSelect={this.handleSelect} users={temp} page={page} />
        );
      }
    } else if (page === 'ope') {
      if (this.props.opeList.length === 0) {
        return <Typography variant='h6'>There are no opes</Typography>;
      } else {
        return (
          <CommonTable
            //
            onSelect={this.handleSelect}
            users={this.props.opeList}
            page={page}
          />
        );
      }
    } else if (page === 'client_class') {
      if (this.props.clientClass.length === 0) {
        return (
          <Typography variant='h6'>There are no client classes</Typography>
        );
      } else {
        return (
          <CommonTable
            //
            onSelect={this.handleSelect}
            users={this.props.clientClass}
            page={page}
          />
        );
      }
    } else if (page === 'audit_document_register') {
      if (this.props.auditDocumentList.length === 0) {
        return (
          <Typography variant='h6'>
            There are no document registers added yet
          </Typography>
        );
      } else {
        return (
          <CommonTable
            //
            onSelect={this.handleSelect}
            users={this.props.auditDocumentList}
            page={page}
          />
        );
      }
    } else if (page === 'settings') {
      if (this.props.settingsList.length === 0) {
        return <Typography variant='h6'>There are no settings</Typography>;
      } else {
        return (
          <CommonTable
            //
            onSelect={this.handleSelect}
            users={this.props.settingsList}
            page={page}
          />
        );
      }
    } else if (page === 'employee') {
      if (this.props.employeeList.length === 0) {
        return <Typography variant='h6'>There are no employees</Typography>;
      } else {
        return (
          <CommonTable
            //
            onSelect={this.handleSelect}
            users={this.props.employeeList}
            page={page}
          />
        );
      }
    } else if (page === 'activity') {
      if (this.props.activityList.length === 0) {
        return (
          <Typography variant='h6'>There are no activities yet</Typography>
        );
      } else {
        return (
          <CommonTable
            //
            onSelect={this.handleSelect}
            users={this.props.activityList}
            page={page}
          />
        );
      }
    } else {
      return (
        <CommonTable
          //
          onSelect={this.handleSelect}
          users={users}
          page={page}
        />
      );
    }
  }

  render() {
    const { classes, page } = this.props;
    const { selectedUsers } = this.state;
    return (
      <div className={classes.root}>
        <CommonToolbar page={page} selectedUsers={selectedUsers} />
        <div className={classes.content}>{this.renderUsers(page)}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clients: state.client.client,
    project: state.project.projectList,
    invoicesList: state.invoice.invoicesList,
    token: state.auth.token,
    projectCategory: state.projectClass.dprojectCategoryList,
    projectSuperClass: state.projectSuperClass.dprojectCategoryList,
    clientClass: state.clientClass.dclientclassList,
    organisation: state.organisation.dorganisationList,
    natureofexpense: state.natureofexpense.dnatureofexpenseList,
    opeList: state.ope.opeList,
    employeeList: state.employee.employeeList,
    clientSearchList: state.search.clientSearchList,
    projectSearchList: state.search.projectSearchList,
    movementRegisterSearchList: state.search.movementRegisterSearchList,
    searchng: state.search.searching,
    issueRegisterList: state.issueregister.issueRegisterList,
    leaveList: state.leave.leaveList,
    certificateRegisterList: state.certificateregister.certificateRegisterList,
    movementRegisterList: state.movementregister.movementRegisterList,
    activityList: state.activity.activityList,
    auditDocumentList: state.auditDocument.auditDocumentList,
    outwardRegisterList: state.outwardRegister.outwardRegisterList,
    inwardRegisterList: state.inwardRegister.inwardRegisterList,
    sacMasterList: state.sacmaster.sacMasterList,
    specMasterList: state.specmaster.specMasterList,
    holidayMaster: state.holidayMaster.holidayList,
    attendence: state.attendence.attendenceList,
    settingsList: state.settingsReducer.settingsList,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  withStyles(styles)
)(CommonList);
