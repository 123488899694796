import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CommonList from '../views/CommonTableView';
const LeaveManagementPage = () => {
    return (
        <PageTemplate>
 <CommonList page={"leave_management"}/>
        </PageTemplate>
    );
};

export default LeaveManagementPage;

