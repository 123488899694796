import React from 'react';
import classNames from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
const hexToRgb = (input) => {
  input = input + '';
  input = input.replace('#', '');
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error('input is not a valid hex color.');
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ', ' +
    parseInt(second, 16) +
    ', ' +
    parseInt(last, 16)
  );
};
const useStyles = makeStyles((theme) => ({
  dropdownItem: {
    fontSize: '13px',
    padding: '10px 20px',
    margin: '0 5px',
    borderRadius: '2px',
    position: 'relative',
    transition: 'all 150ms linear',
    display: 'block',
    clear: 'both',
    fontWeight: '400',
    height: '100%',
    color: '#555555',
    whiteSpace: 'nowrap',
    minHeight: 'unset',
    '&:hover': {
      backgroundColor: '#9c27b0',
      color: 'white',
      boxShadow:
        '0 4px 20px 0 rgba(' +
        hexToRgb('#000') +
        ',.14), 0 7px 10px -5px rgba(' +
        hexToRgb('#9c27b0') +
        ',.4)',
    },
  },
  dropdown: {
    borderRadius: '3px',
    border: '0',
    boxShadow: '0 2px 5px 0 rgba(' + hexToRgb('#000') + ', 0.26)',
    top: '100%',
    zIndex: '1000',
    minWidth: '160px',
    padding: '5px 0',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: 'white',
    backgroundClip: 'padding-box',
  },
  popperResponsive: {
    zIndex: '1200',
    [theme.breakpoints.down('sm')]: {
      zIndex: '1640',
      position: 'static',
      float: 'none',
      width: 'auto',
      marginTop: '0',
      backgroundColor: 'transparent',
      border: '0',
      boxShadow: 'none',
      color: 'black',
    },
  },
  popperClose: {
    pointerEvents: 'none',
    display: 'none !important',
  },
  popperNav: {
    [theme.breakpoints.down('sm')]: {
      position: 'static !important',
      left: 'unset !important',
      top: 'unset !important',
      transform: 'none !important',
      willChange: 'unset !important',
      '& > div': {
        boxShadow: 'none !important',
        marginLeft: '0rem',
        marginRight: '0rem',
        transition: 'none !important',
        marginTop: '0px !important',
        marginBottom: '0px !important',
        padding: '0px !important',
        backgroundColor: 'transparent !important',
        '& ul li': {
          color: 'white' + ' !important',
          margin: '10px 15px 0!important',
          padding: '10px 15px !important',
          '&:hover': {
            backgroundColor: 'hsla(0,0%,78%,.2)',
            boxShadow: 'none',
          },
        },
      },
    },
  },
}));

export default function RenderMenu({
  anchorEl,
  handleCloseNotification,
  isMenuOpen,
  notifications,
}) {
  const classes = useStyles();
  return (
    <Popper
      open={Boolean(isMenuOpen)}
      anchorEl={anchorEl}
      transition
      disablePortal
      placement='bottom'
      className={classNames({
        [classes.popperClose]: !isMenuOpen,
        [classes.popperResponsive]: true,
        [classes.popperNav]: true,
      })}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          id='notification-menu-list'
          style={{ transformOrigin: '0 0 0' }}
        >
          <Paper className={classes.dropdown}>
            <ClickAwayListener onClickAway={handleCloseNotification}>
              <MenuList role='menu'>
                {notifications.map((item) => {
                  return (
                    <MenuItem
                      onClick={() => handleCloseNotification(item)}
                      className={classes.dropdownItem}
                    >
                      {item.msg}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
