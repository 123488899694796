import {START_FETCH_HOLIDAY_CODE_LIST, HOLIDAY_CODE_LIST_FETCH_SUCCESS, HOLIDAY_CODE_LIST_FETCH_FAILED} from '../actions/types';

const DEFAULT_STATE={
    holiday_codes:[]
}

export default(state=DEFAULT_STATE,action)=>{
    switch(action.type){
        case START_FETCH_HOLIDAY_CODE_LIST:
            return{
                ...state,
                holiday_codes:[]
            }
        case HOLIDAY_CODE_LIST_FETCH_SUCCESS:
            return{
                ...state,
                holiday_codes: action.payload
            }
        case HOLIDAY_CODE_LIST_FETCH_FAILED:
            return{
                ...state,
                holiday_codes:[]
            }
        default:
            return state
    }
}
