import axios from "axios";
import { BaseUrl } from "./api";
import moment from "moment";
import {
    START_TIMESHEET_FETCH,
    START_TIMESHEET_ADD,
    TIMESHEET_FETCH_SUCCESS,
    TIMESHEET_FETCH_FAILED,
    TIMESHEET_ADDED_SUCCESSFULLY,
    ADDING_TIMESHEET_FAILED,
    FETCH_TIME_BOOKED_FOR_PROJECT_START,
    FETCH_TIME_BOOKED_FOR_PROJECT_SUCCESS,
    FETCH_TIME_BOOKED_FOR_PROJECT_FAILED,
    BOOK_TIME_FOR_PROJECT_START,
    BOOK_TIME_FOR_PROJECT_SUCCESS,
    BOOK_TIME_FOR_PROJECT_FAILED,
    UPDATE_PROJECT_STATUS_TO_IN_PROGRESS_LOCALLY,
} from "./types";
import { padZeros, getMinutes } from "../utils/formComponents";
import { editableDate } from "../utils/timeSheet"
function parseLogdata(data, user, editable_days) {
    var timesheet = {
        project_data: [],
        codedata: [],
    };
    //let user_id = user['emp_id'].split("JNR");
    let user_id = user["id"];
    let minutes = 0,
        date = "",
        parts = "",
        search = false,
        date_parts;
    Object.keys(data).forEach(function (element) {
        if (element.includes("code_attributes")) {
            var i = 0;
            data["code_attributes"].forEach((item, index) => {
                Object.keys(item).forEach(function (e) {
                    if (
                        e.includes("time_sheet_code_id") ||
                        e.includes("isdisabled")
                    ) {
                        //timesheet[0]['codedata'][index]['timesheet_code_id'] = item['time_sheet_code_id']['id']
                    } else {
                        date_parts = e.split("-");
                        let c_check = editableDate(date_parts,moment(new Date()).format("DD-MM-YYYY").split("-"), editable_days )
                        console.log("code data check", c_check, date_parts, moment(new Date()).format("DD-MM-YYYY").split("-"))
                        if(c_check)
                        {
                            timesheet["codedata"][i] = {};
                            minutes = getMinutes(item[e]);
                            date =
                                date_parts[0] +
                                "/" +
                                date_parts[1] +
                                "/" +
                                date_parts[2];
                            timesheet["codedata"][i]["user_id"] = user_id;
                            timesheet["codedata"][i]["date"] = date;
                            timesheet["codedata"][i]["allocated_time"] = minutes;
                            timesheet["codedata"][i]["timesheet_code_id"] =
                                item["time_sheet_code_id"]["id"];
                            i = i + 1;
                        }
                    }
                });
            });
        } else {
            parts = element.split("_");
            date_parts = parts[1].split("-");
            let p_check = editableDate(date_parts,moment(new Date()).format("DD-MM-YYYY").split("-"), editable_days )
            console.log("code data check", p_check, date_parts, moment(new Date()).format("DD-MM-YYYY").split("-"))
            if(p_check)
            {
                date = date_parts[0] + "/" + date_parts[1] + "/" + date_parts[2];
                minutes = getMinutes(data[element]);
                timesheet["project_data"].push({
                    user_id: user_id,
                    project_id: Number(parts[0]),
                    date: date,
                    allocated_time: minutes,
                });
            }
        }
    });

    return timesheet;
}
function formatTimesheet(data) {
    var date, parts, actual_time, min, hour;
    const new_data = {};
    for (const key of data["project_data"]) {
        date = key.date;
        parts = date.split("-");
        date = parts[2] + "-" + parts[1] + "-" + parts[0];
        hour = Math.floor(key.allocated_time / 60);
        hour = padZeros(hour);
        min = key.allocated_time % 60;
        min = padZeros(min);
        new_data[key.project_id + "_" + date] = hour + ":" + min;
        //new_data[key.project_id+"_"+date+"_mm"] = {"label":""+min,"value":Number(min)};
    }
    let new_attr = data["code_attributes"].filter(function (val) {
        return val !== null;
    });
    let temp_data = new_attr.slice();
    let temp = [],
        i = 0;
    for (var item in temp_data) {
        temp[i] = {};
        for (const key in temp_data[item]) {
            if (key.includes("timesheet_code_id")) {
                temp[i]["time_sheet_code_id"] = temp_data[item][key];
            } else {
                hour = Math.floor(temp_data[item][key] / 60);
                hour = padZeros(hour);
                min = temp_data[item][key] % 60;
                min = padZeros(min);
                temp[i][key] = hour + ":" + min;
            }
            temp[i]["isdisabled"] = true;
        }
        i = i + 1;
    }
    new_data["code_attributes"] = temp;
    return new_data;
}
export const fetchTimesheet = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_TIMESHEET_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/timesheet`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            const new_data = formatTimesheet(res["data"]["timesheet"]);
            if (res.statusText == "OK") {
                dispatch({
                    type: TIMESHEET_FETCH_SUCCESS,
                    payload: new_data,
                });
                return new_data;
            } else {
                dispatch({
                    type: TIMESHEET_FETCH_FAILED,
                    payload: "Fetching timesheet  failed",
                });
            }
        } catch (error) {
            dispatch({
                type: TIMESHEET_FETCH_FAILED,
                payload: "Fetching timesheet  failed",
            });
        }
    };
};

export const logTimesheet = (data, token, user, editable_days) => {
    let new_data = parseLogdata(data, user, editable_days);
    return async (dispatch) => {
        dispatch({
            type: START_TIMESHEET_ADD,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/timesheet`,
                { timesheet: new_data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: TIMESHEET_ADDED_SUCCESSFULLY,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: ADDING_TIMESHEET_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: ADDING_TIMESHEET_FAILED,
                payload: error.message,
            });
        }
    };
};
//for booking within project list page
export const fetchTimeBooked = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_TIMESHEET_FETCH, //FETCH_TIME_BOOKED_FOR_PROJECT_START
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/get_time_booked_for_project`,
                { timesheet: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: TIMESHEET_FETCH_SUCCESS, //FETCH_TIME_BOOKED_FOR_PROJECT_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: TIMESHEET_FETCH_FAILED, //FETCH_TIME_BOOKED_FOR_PROJECT_FAILED,
                    payload: "Fetching time booked for this project failed",
                });
            }
        } catch (error) {
            dispatch({
                type: TIMESHEET_FETCH_FAILED, //FETCH_TIME_BOOKED_FOR_PROJECT_FAILED,
                payload: error.message,
            });
        }
    };
};
export const bookTimeForProject = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_TIMESHEET_ADD,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/book_project_time`,
                { timesheet: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: TIMESHEET_ADDED_SUCCESSFULLY,
                    payload: res.data.message,
                });
                dispatch({
                    type: UPDATE_PROJECT_STATUS_TO_IN_PROGRESS_LOCALLY,
                    payload: data.project_id,
                });
            } else {
                dispatch({
                    type: ADDING_TIMESHEET_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: ADDING_TIMESHEET_FAILED,
                payload: error.message,
            });
        }
    };
};
