import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CommonList from '../views/CommonTableView';
const MovementRegisterPage = () => {
    return (
        <PageTemplate>
 <CommonList page={"movement_register"}/>
        </PageTemplate>
    );
};

export default MovementRegisterPage;

