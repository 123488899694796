import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_MOVEMENT_REGISTER_LIST_FETCH,
    MOVEMENT_REGISTER_LIST_FETCH_SUCCESS,
    MOVEMENT_REGISTER_LIST_FETCH_FAILED,
    START_ADD_NEW_MOVEMENT_REGISTER,
    ADDING_NEW_MOVEMENT_REGISTER_FAILED,
    ADDING_NEW_MOVEMENT_REGISTER_SUCCESS,
    MOVEMENT_REGISTER_STATUS_CHANGE_SUCCESS,
    MOVEMENT_REGISTER_STATUS_CHANGE_FAILED,
    START_MOVEMENT_REGISTER_STATUS_CHANGE,
    START_MOVEMENT_REGISTER_FETCH_BY_ID,
    MOVEMENT_REGISTER_FETCH_BY_ID_DONE,
    MOVEMENT_REGISTER_FETCH_BY_ID_FAILED,
    START_MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH,
    MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_SUCCESS,
    MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_FAILED,
    START_REAUTH,
    DONE_REAUTH,
    FAILED_REAUTH,
    START_UPDATE_DSC_CLIENT,
    START_UPDATE_DSC_CUSTODIAN,
    UPDATE_DSC_CUSTDOIAN_FAILED,
    UPDATE_DSC_CUSTDOIAN_SUCCESS,
    UPDATE_DSC_CLIENT_FAILED,
    UPDATE_DSC_CLIENT_SUCCESS,
    START_IN_MOVEMENT,
    IN_MOVEMENT_SUCCESS,
    IN_MOVEMENT_FAILED,
    START_BASIC_DETAILS_UPDATE,
    BASIC_DETAILS_UPDATE_SUCCESS,
    BASIC_DETAILS_UPDATE_FAILED,
} from "./types";

//for dripdown
export const fetchRegisterList = (token, status_type) => {
    return async (dispatch) => {
        dispatch({
            type: START_MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH,
        });
        try {
            const res = await axios.get(
                `${BaseUrl}/movement_register_dropdown/${status_type}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_SUCCESS,
                    payload: res.data["movement_registers"],
                });
            } else {
                dispatch({
                    type: MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_FAILED,
                    payload: "movement register drop down fetch failed",
                });
            }
        } catch (error) {
            dispatch({
                type: MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_FAILED,
                payload: "movement register drop down fetch failed",
            });
        }
    };
};
export const fetchMovementRegisterById = (token, id) => {
    return async (dispatch) => {
        dispatch({
            type: START_MOVEMENT_REGISTER_FETCH_BY_ID,
        });
        try {
            const res = await axios.get(`${BaseUrl}/movement_register/${id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: MOVEMENT_REGISTER_FETCH_BY_ID_DONE,
                    payload: res.data["movement_register"],
                });
                return res.data["movement_register"]["clients"];
            } else {
                dispatch({
                    type: MOVEMENT_REGISTER_FETCH_BY_ID_FAILED,
                    payload: "movement register fetch failed",
                });
            }
        } catch (error) {
            dispatch({
                type: MOVEMENT_REGISTER_FETCH_BY_ID_FAILED,
                payload: "movement register fetch failed",
            });
        }
    };
};
export const reAuthenticateDsc = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_REAUTH,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/reauth_for_movement_register`,
                { reauth: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: DONE_REAUTH,
                    payload: [],
                });
                dispatch({
                    type: MOVEMENT_REGISTER_FETCH_BY_ID_DONE,
                    payload: res.data["movement_register"],
                });
            } else {
                dispatch({
                    type: FAILED_REAUTH,
                    payload: res.errors,
                });
            }
        } catch (error) {
            dispatch({
                type: FAILED_REAUTH,
                payload: error.message,
            });
        }
    };
};

export const fetchMovementRegistersList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_MOVEMENT_REGISTER_LIST_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/movement_registers`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.data.status) {
                dispatch({
                    type: MOVEMENT_REGISTER_LIST_FETCH_SUCCESS,
                    payload: res.data["movement_registers"],
                });
            } else {
                dispatch({
                    type: MOVEMENT_REGISTER_LIST_FETCH_FAILED,
                    payload: "Fetching movement registers list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: MOVEMENT_REGISTER_LIST_FETCH_FAILED,
                payload: "Fetching movement registers list failed",
            });
        }
    };
};

export const addNewMovemenRegister = (token, data) => {
    return async (dispatch) => {
        dispatch({
            type: START_ADD_NEW_MOVEMENT_REGISTER,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/movement_register`,
                { movement_register: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: ADDING_NEW_MOVEMENT_REGISTER_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: ADDING_NEW_MOVEMENT_REGISTER_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: ADDING_NEW_MOVEMENT_REGISTER_FAILED,
                payload: error.message,
            });
        }
    };
};

export const dscBasicDetailsUpdate = (token, data, id) => {
    return async (dispatch) => {
        dispatch({
            type: START_BASIC_DETAILS_UPDATE,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/movement_register/basic_details/${id}`,
                { movement_register: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: BASIC_DETAILS_UPDATE_SUCCESS,
                    payload: {
                        message: res.data["message"],
                        data: res.data["basic_details"],
                    },
                });
            } else {
                dispatch({
                    type: BASIC_DETAILS_UPDATE_FAILED,
                    payload: res.data.errors,
                });
            }
        } catch (error) {
            dispatch({
                type: BASIC_DETAILS_UPDATE_FAILED,
                payload: error.message,
            });
        }
    };
};

export const dscMovementIn = (token, data, id) => {
    return async (dispatch) => {
        dispatch({
            type: START_IN_MOVEMENT,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/movement_register/move_in/${id}`,
                { movement_register: data},
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: IN_MOVEMENT_SUCCESS,
                    payload: { message: res.data["message"], data: res.data },
                });
            } else {
                dispatch({
                    type: IN_MOVEMENT_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: IN_MOVEMENT_FAILED,
                payload: error.message,
            });
        }
    };
};

export const dscClientUpdate = (token, data, id) => {
    return async (dispatch) => {
        dispatch({
            type: START_UPDATE_DSC_CLIENT,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/movement_register/update_dsc_client/${id}`,
                { movement_register: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.status) {
                dispatch({
                    type: UPDATE_DSC_CLIENT_SUCCESS,
                    payload: {
                        message: res.data["message"],
                        data: res.data["clients"],
                    },
                });
            } else {
                dispatch({
                    type: UPDATE_DSC_CLIENT_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: UPDATE_DSC_CLIENT_FAILED,
                payload: error.message,
            });
        }
    };
};

export const dscCustodianUpdate = (token, data) => {
    return async (dispatch) => {
        dispatch({
            type: START_UPDATE_DSC_CUSTODIAN,
        });
        try {
            const res = await axios.post(`${BaseUrl}/custodian_details`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status) {
                dispatch({
                    type: UPDATE_DSC_CUSTDOIAN_SUCCESS,
                    payload: {
                        message: res.data["message"],
                        data: res.data["custodian_details"],
                    },
                });
            } else {
                dispatch({
                    type: UPDATE_DSC_CUSTDOIAN_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: UPDATE_DSC_CUSTDOIAN_FAILED,
                payload: error.message,
            });
        }
    };
};

export const movementRegisterMovement = (token, data) => {
    return async (dispatch) => {
        dispatch({
            type: START_MOVEMENT_REGISTER_STATUS_CHANGE,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/movement_reg_details`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.status) {
                dispatch({
                    type: MOVEMENT_REGISTER_STATUS_CHANGE_SUCCESS,
                    payload: {
                        message: res.data["message"],
                        data: res.data["movement_details"],
                    },
                });
            } else {
                dispatch({
                    type: MOVEMENT_REGISTER_STATUS_CHANGE_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: MOVEMENT_REGISTER_STATUS_CHANGE_FAILED,
                payload: error.message,
            });
        }
    };
};
