import axios from '../../axiosConfig';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// query function
async function getSpecMasterList() {
  try {
    const res = await axios.get(`/spec_masters`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}
async function createSpecMaster(data) {
  try {
    const res = await axios.post(`/spec_master`, data);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function updateSpecMaster({ data, id }) {
  try {
    const res = await axios.patch(`/spec_master/${id}`, data);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

// query hooks

export const useSpecMasterList = () =>
  useQuery(['spec_master'], getSpecMasterList);

export const useCreateSpecMaster = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createSpecMaster(data), {
    onSuccess: () => queryClient.invalidateQueries('spec_master'),
  });
};

export const useUpdateSpecMaster = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => updateSpecMaster(form), {
    onSuccess: () => queryClient.invalidateQueries('spec_master'),
  });
};
