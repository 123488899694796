import React from "react";
import { useHistory } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";

// COMPONENTS
import CoreUITable from "../components/Layout/Table";
import PageTemplate from "../components/Layout/PageTemplate/PageTemplate";

// HOOKS
import {
  useEmployeeList,
  useSuspendEmployee,
} from "../hooks/employee-query/employee";

// UTILS
import { AlertComponent } from "../utils/formComponents";

const EmployeePage = () => {
  const history = useHistory();
  const employeQuery = useEmployeeList();
  const suspendMutate = useSuspendEmployee();
  const [employeeId, setEmployeeId] = React.useState(undefined);
  const [showAlert, setShowSuspend] = React.useState(false);

  const options = [
    {
      text: "View Employee",
      action: function (id) {
        history.push("/approve_employee", { id });
      },
      params: true,
    },
    {
      text: "Suspend",
      action: function (id) {
        setShowSuspend(true);
        setEmployeeId(id);
      },
      params: true,
    },
  ];

  const suspendEmployee = () => {
    setShowSuspend(false);
    suspendMutate.mutate(employeeId);
  };

  if (employeQuery.isLoading)
    return <ProgressBar striped variant="success" animated now={40} />;

  return (
    <PageTemplate>
      <CoreUITable
        page="employees"
        title="Employee"
        options={options}
        data={employeQuery.data}
        fields={[
          "id",
          "name",
          "designation",
          "approvel_status",
          "employee_status",
          {
            key: "show_details",
            label: "",
            _style: { width: "1%" },
            filter: false,
          },
        ]}
      />

      <AlertComponent
        title="Suspend Employee"
        description={`Are you sure you want to suspend the employee`}
        open={showAlert}
        cancelText="No, dont suspend"
        saveText="Yes suspend"
        handleClose={() => {
          setShowSuspend(false);
        }}
        proceed={suspendEmployee}
      />
    </PageTemplate>
  );
};

export default EmployeePage;
