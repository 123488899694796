
import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field,change, formValueSelector } from "redux-form";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import MultiSelectComponent from "../../utils/multiselect"
import {InputText,TextArea,SelectInput,validate,SavingDetailsModal,RenderSwitch} from "../../utils/formComponents";
class AddActivity extends Component{

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        activity:{},
        activityAddFailed:true,
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
}

componentWillMount(){
    this.props.fetchProjectCategoryList(this.props.token)
}
componentDidUpdate(prevProps){
    if(this.props.copyExisting && this.props.copyExisting !== prevProps.copyExisting){
        this.props.fetchCombinedActivity(this.props.token)
    }
    if(!this.state.activityAddFailed){
        this.props.history.goBack();
    }
  }

closeModal(){
    this.setState({
        loading:this.props.loading,
        activityAddFailed:this.props.activityAddFailed
    })
}
async onsubmit(formData) {

    this.setState({
        loading:true,
    })
    if(this.props.copyExisting){
        let d_activity = {...formData}
        d_activity['activity_id'] = d_activity['activity_id']['id']
        await this.props.addActivity(d_activity,this.props.token);
    }else{
        await this.props.addActivity({
            project_sub_class_id: formData.project_sub_class_id,
            activity_short_description: formData.activity_short_description,
            activity_long_description: formData.activity_long_description
        },this.props.token);
    }
    this.setState({
        message:this.props.message
    })
}
  render () {
    let state = this.state;
const { handleSubmit,classes } = this.props;
    return (
      <Container>
        <Header title="add_activity" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
       Add Activity
      </Typography>
        <Row>
          <Col sm={4}>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(this.onsubmit)}>
          <Row>
            <Col sm={8}>

              <span>Select Category</span>
              <Field
                  name="project_sub_class_id"
                  label="Category *"
                  component={SelectInput}
                  fullWidth
                  data={this.props.dprojectCategoryList}
              />
            </Col>
            <Col sm={4}>
             <Field
                name= "copy_existing"
                component={RenderSwitch}
                label= "Select existing activity?"
            />
        </Col>

          </Row>
        {this.props.copyExisting ?
            <Row>
             <Col sm={8}>
              <span>Select Existing Activity</span>
              <Field
                  name="activity_id"
                  label=""
                  props={{
                      type: 'object'
                  }}
                  component={MultiSelectComponent}
                  {...{
                      data:this.props.activityList,
                      isMulti : false
                  }
                  }
                  fullWidth
              />
            </Col>
            </Row> :
            <Row>
            <Col sm={8}>

              <span>Activity short description</span>
              <Field
                        name="activity_short_description"
                        label="short description"
                        component={InputText}
                        type="text"
                        fullWidth
              />
        </Col>
            <Col sm={4}>

              <span>Activity long decsription</span>

              <Field
                        name="activity_long_description"
                        label="long description"
                        component={InputText}
                        type="text"
                        fullWidth
              />
            </Col>
          </Row>}
        <Row>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving activity Details"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>
          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    )
  }
}
const selector = formValueSelector('AddActivity')
function mapStateToProps(state) {
    return {
        token:state.auth.token,
        message:state.activity.activityAddMessage,
         dprojectCategoryList:state.projectClass.dprojectCategoryList,
        loading:state.activity.activityAddLoading,
        activityList:state.activity.activityList,
        activityAddFailed:state.activity.activityAddFailed,
        copyExisting: selector(state,'copy_existing')
    }
}
export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddActivity",
      enableReinitialize: true,
validate }),
    withStyles(styles)
)(AddActivity);
