import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
const Modal = ({
  isOpen,
  handleClose,
  title,
  subtitle,
  children,
  hasClosed = true,
}) => {
  return (
    <>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={isOpen}
        onClose={handleClose}
        aria-labelledby={title}
      >
        <DialogTitle id={title}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subtitle}</DialogContentText>
          {children}
          {hasClosed && (
            <DialogActions>
              <Button onClick={handleClose} color='primary'>
                Close
              </Button>
            </DialogActions>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Modal;
