import axios from "../../axiosConfig";
import { useQuery} from "react-query";

async function getClientDscs({queryKey}){
    try{
        const res = await axios.get(`/client_dscs/${queryKey[1]}`);
        return res.data;
    }catch(err){

        throw err.response.data;
    }
}
export const useDscs = (client_id) => useQuery(['clientDscs',client_id], getClientDscs,{
    refetchOnWindowFocus: false,
    enabled: !!client_id
})
