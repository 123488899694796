import {START_ORGANISATION_ADD,START_ORGANISATION_FETCH,
DONE_ORGANISATION_ADD,DONE_ORGANISATION_FETCH,
FAILED_ORGANISATION_ADD,FAILED_ORGANISATION_FETCH} from "../actions/types";



const DEFAULT_STATE ={
    dorganisationList:[],
    organisationList:[],
    organisation:[],
    loading: false,
    errorMessage:'',
    message:'',
    organisationAddLoading:false,
    organisationAddFailed:true
};

export default (state =DEFAULT_STATE,action)=>{
    switch(action.type){

        case START_ORGANISATION_ADD:
            return{
                ...state,
                organisationAddLoading:true,
                organisationAddFailed:false,
                message:''
            }

        case DONE_ORGANISATION_ADD:
            return{
                ...state,
                organisationAddLoading:false,
                organisationAddFailed:false,
                loading:false,
                message:action.payload
            }
        case FAILED_ORGANISATION_ADD:
            return{
                ...state,
                organisationAddLoading:false,
                organisationAddFailed:true,
                loading:false,
                message:action.payload
            }
        case START_ORGANISATION_FETCH:
            return{
                ...state,
                loading:true
            }

        case DONE_ORGANISATION_FETCH:
            return{
                ...state,
                loading:false,
                dorganisationList:action.payload
            }
        case FAILED_ORGANISATION_FETCH:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        default :
            return state

    }
}
