import { Card } from '@material-ui/core';
import React from 'react';
import "./profile.css";
import {useIcai} from '../../hooks/profile-query'
import { Row, Col , Badge} from 'react-bootstrap';
    export const ICAI  = props =>{
        const { data, isLoading } = useIcai()
    return (
        <div className="body_background" >
        <Card className="ICAI1" style={{backgroundColor:'#222b36' ,color:'azure' }} >
            {
                isLoading ?
                <p>Loading..</p>
                : <>
                    <Row>
                        <Col><p>ICAI Article:</p></Col>
                        <Col><p>Yes</p></Col></Row>
                    <Row>
                        <Col><p>Transfer Case:</p></Col>
                        <Col><p>{data.transfer_case}</p></Col></Row>
                    <Row>
                        <Col><p>Scheme:</p></Col>
                        <Col><p>{data.icai_scheme}</p></Col>
                    </Row>
                    <Row>
                        <Col><p>Pricipal:</p></Col>
                        <Col><p>{data.principal}</p></Col>
                    </Row>
                    <Row>
                        <Col><p>Member in charge:</p></Col>
                        <Col><p>{data.member_in_charge}</p></Col>
                    </Row>
<Row>
    <Col><p>Organisation</p></Col>
    <Col><p>{data.organisation}</p></Col>
</Row>
<Row>
    <Col><p>ICAI Student Registration No.</p></Col>
    <Col><p>{data.icai_srn}</p></Col>
</Row>
		    <Row>
    <Col><Badge variant="dark"><h3>Previous Employement</h3></Badge></Col>
    <Col></Col>
</Row>
	
                    <Row>
                        <Col><p>Previous Employer:</p></Col>
                        <Col><p>{data.previous_employer}</p></Col>
                    </Row>
<Row>
    <Col><p>Commencement Date</p></Col>
    <Col><p>{data.pe_article_start_date}</p></Col>
</Row>
<Row>
    <Col><p>Termination Date</p></Col>
    <Col><p>{data.pe_article_termination_date}</p></Col>
</Row>
<Row>
    <Col><p>Date of Form 109</p></Col>
    <Col><p>{data.pe_date_109}</p></Col>
</Row>
<Row>
    <Col><p>No. of leaves taken</p></Col>
    <Col><p>{data.pe_leaves_taken}</p></Col>
</Row>

<Row>
    
    <Col><Badge variant="dark"><h3>Current Employment</h3></Badge></Col>
    <Col></Col>
</Row>

                    <Row>
                        <Col><p>Date of Commencement of Articleship:</p></Col>
                        <Col><p>{data.firm_article_start_date}</p></Col>
                    </Row>
	<Row>
    <Col><p>Date of Form 102</p></Col>
    <Col><p>{data.firm_date_102}</p></Col>
</Row>
<Row>
    <Col><p>Date of Form 103</p></Col>
    <Col><p>{data.firm_date_103}</p></Col>
</Row>
<Row>
    <Col><p>Articleship Completion Date</p></Col>
    <Col><p>{data.firm_article_end_date}</p></Col>


</Row>

<Row>
    <Col><p>Articleship Termination Date</p></Col>
    <Col><p>{data.firm_article_termination_date}</p></Col>


</Row>

<Row>
    <Col><p>Articleship Completion Date - Extended</p></Col>
    <Col><p>{data.firm_article_extended_end_date}</p></Col>


</Row>

<Row>

    <Col><p>Date of Form 109</p></Col>
    <Col><p>{data.firm_date_109}</p></Col>


</Row>

<Row>
    <Col><Badge variant="dark"><h3>Leave Details</h3></Badge></Col>
    <Col></Col>
</Row>
                    <Row>
                        <Col><p>Balance number of leaves(ICAI)</p></Col>
                        <Col><p>{data.balence_number_of_leaves}</p></Col>
                    </Row>

                </>
            }


        </Card>
        </div>
    )
    }
