import { START_GENERAL_ACTIVITY_FETCH,START_GENERAL_ACTIVITY_ADD,GENERAL_ACTIVITY_ADD_SUCCESS,GENERAL_ACTIVITY_ADD_FAILED,GENERAL_ACTIVITY_FECH_SUCCESS,GENERAL_ACTIVITY_FETCH_FAILED,START_FETCH_COMBINED_ACTIVITY,FETCH_COMBINED_ACTIVITY_SUCCESS,FETCH_COMBINED_ACTIVITY_FAILED} from "../actions/types";

const DEFAULT_STATE = {
    activityList:[],
    activityAddFailed: true,
    activityAddLoading: false,
    activityAddMessage: "",
    activityFetchLoading: false
}

export default (state = DEFAULT_STATE,action) =>{
    switch(action.type){
        case START_FETCH_COMBINED_ACTIVITY:
            return{
                ...state,
                activityList:[]
            }
        case FETCH_COMBINED_ACTIVITY_SUCCESS:
            return{
                ...state,
                activityList: action.payload
            }
        case FETCH_COMBINED_ACTIVITY_FAILED:
            return{
                ...state,
                activityList:[]
            }
        case START_GENERAL_ACTIVITY_FETCH:
            return{
                ...state,
                activityList:[],
                activityFetchLoading: true
            }
        case GENERAL_ACTIVITY_FECH_SUCCESS:
            return{
                ...state,
                activityList: action.payload,
                activityFetchLoading: false
            }
        case GENERAL_ACTIVITY_FETCH_FAILED:
            return{
                ...state,
                activityList:[],
                activityAddLoading: false,
            }
        case START_GENERAL_ACTIVITY_ADD:
            return{
                ...state,
                activityAddLoading: true,
                activityAddFailed: false,
                activityAddMessage: ''
            }
        case GENERAL_ACTIVITY_ADD_SUCCESS:
            return{
                ...state,
                activityAddFailed: false,
                activityAddLoading: false,
                activityAddMessage: action.payload
            }
        case GENERAL_ACTIVITY_ADD_FAILED:
            return{
                ...state,
                activityAddFailed: true,
                activityAddLoading: false,
                activityAddMessage: action.payload
            }
        default:
            return state
    }
}
