
import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from 'react-redux';
import * as actions from "../../actions";
import Header from "../../components/Layout/Header/Header";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import {InputText,SelectInput,TextArea,validate,SavingDetailsModal} from "../../utils/formComponents";
class AddNatureOfExpense extends Component {

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        natureofexpenseAddFailed:true
    };

    this.onsubmit = this.onsubmit.bind(this);
 this.closeModal = this.closeModal.bind(this);

}

closeModal(){
    this.setState({
        loading:this.props.loading,
        natureofexpenseAddFailed:this.props.natureofexpenseAddFailed
    })
}
componentDidUpdate(){
    setTimeout(() => this.setState({message:''}), 9000);
    if(!this.state.natureofexpenseAddFailed){
        this.props.history.goBack();
    }
  }
async onsubmit(formData) {

    this.setState({
        loading:true
    })
    await this.props.addNatureofexp(formData,this.props.token);
    this.setState({
        message:this.props.message
    })
}
  render () {
const { handleSubmit,classes } = this.props;
    let state = this.state;
    return (
      <Container>
      <Header title="add_nature_of_expense" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
      Add Nature Of Expense
      </Typography>
        <Row>
          <Col sm={4}>
          </Col>

        </Row>
        <form onSubmit={handleSubmit(this.onsubmit)} noValidate>
          <Row>
            <Col sm={8}>
              <span>Nature of expense</span>
              <Field
                        name="name"
                        label="Name *"
                        component={InputText}
                        type="text"
                        fullWidth
              />

            </Col>
           <Col sm={4}>


          </Col>
        </Row>

        <Row>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving Nature of expense Details"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>


          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>

      </Container>
    )
  }
}

function mapStateToProps(state) {
    return {
        token:state.auth.token,
        message:state.natureofexpense.message,
        loading:state.natureofexpense.natureofexpenseAddLoading,
        natureofexpenseAddFailed:state.natureofexpense.natureofexpenseAddFailed
    }
}

export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddNatureOfExpense",
validate }),
    withStyles(styles)
)(AddNatureOfExpense);
