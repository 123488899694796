import {FETCH_TIMESHEET_CODE_SUCCESS,FETCH_TIMESHEET_CODE_FAILED, FETCH_TINESHEET_CODE_START, REMOVE_TIMESHEET_CODE_ITEM, ADD_ITEM_BACK_IN_TIMESHEET_CODE,INITIAL_FILTERING_TIME_SHEET_CODES} from '../actions/types';

const DEFAULT_STATE = {
    timesheetCodes: [],
    loading: false,
}
export default (state = DEFAULT_STATE, action)=>{
   switch(action.type){
        case INITIAL_FILTERING_TIME_SHEET_CODES:
           return{
               ...state,
               timesheetCodes: state.timesheetCodes.filter(function(item){
                    return action.payload.indexOf(item.id) === -1;
               })
           }
        case FETCH_TINESHEET_CODE_START:
           return{
               ...state,
               timesheetCodes:[]
           }
        case FETCH_TIMESHEET_CODE_SUCCESS:
           return{
                ...state,
               timesheetCodes: action.payload
           }
        case FETCH_TIMESHEET_CODE_FAILED:
            return{
                ...state,
                timesheetCodes: []
            }
       case ADD_ITEM_BACK_IN_TIMESHEET_CODE:
           return{
               ...state,
               timesheetCodes: [...state.timesheetCodes, action.payload]
           }
       case REMOVE_TIMESHEET_CODE_ITEM:
           return{
               ...state,
               timesheetCodes: state.timesheetCodes.filter(item => JSON.stringify(item) !== JSON.stringify(action.payload) )

           }
        default:
            return state
   }
}
