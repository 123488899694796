import React from "react";
import { styles } from "./styles";
import { ContactAccordion } from "./ContactAccordion";

export const Contact = ({ management_details_attributes }) => {
    return (
        <div style={styles.infoSectionContainer}>
            {management_details_attributes &&
            management_details_attributes.length > 0 ? (
                management_details_attributes.map((cont) => (
                    <div style={styles.infoContainer}>
                        <div style={styles.info}>
                            <div>
                                <div>Name</div>
                                <div>Mobile</div>
                                <div>Email</div>
                                <div>Designation</div>
                            </div>
                            <div>
                                <div>{cont.name}</div>
                                <div>{cont.contact}</div>
                                <div>{cont.email}</div>
                                <div>{cont.designation}</div>
                            </div>
                        </div>
                        <ContactAccordion contact={cont} />
                    </div>
                ))
            ) : (
                <div style={styles.infoContainer}>
                    No contact details available
                </div>
            )}
        </div>
    );
};
