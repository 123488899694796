export default (theme) => ({
    modal: {
        top: "calc(50% - 150px)",
        left: "calc(50% - 150px)",
        height: 300,
        width: 300,
    },
    root: {
        float: "right",
    },
    opeModal: {
        top: "calc(50% - 150px)",
    },
    hide: {
        display: "none",
    },
    hourMinutes: {
        width: "100%",
    },
    dialogPaper: {
        minHeight: "60vh",
        maxHeight: "60vh",
    },
    topPart: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 16,
    },
    dashboardTopBoxValues: {
        color: "#9E9E9E",
        fontSize: "14px",
        fontWeight: "700",
        paddingLeft: "4%",
        paddingTop: "2%",
        paddingBottom: "2%",
    },
    tableContainer: {
        height: "45vh",
    },
    tableRowHeight: {
        minWidth: 650,
    },
    root: {
        width: "100%",
    },
    progressWrapper: {
        paddingTop: "48px",
        paddingBottom: "24px",
        display: "flex",
        justifyContent: "center",
    },
    dscSpanColor: {
        color: "black",
        fontSize: "18px",
        fontWeight: "700",
        textTransform: "uppercase",
    },
    dscSpanColorPassword: {
        color: "black",
        fontSize: "18px",
        fontWeight: "700",
    },
    dashboardSpanColor: {
        color: "white",
        fontSize: "18px",
        fontWeight: "700",
        textTransform: "uppercase",
    },
    tableHead: {
        fontSize: "11px",
        align: "right",
    },
    scroll: {
        overflow: "auto",
    },
    dsc_details_toolbar: {
        flexDirection: "row",
        justifyContent: "flex-end",
        marginRight: "auto",
    },
    rowStyle: {
        flexDirection: "row",
        padding: "8px",
        flex: 1,
        justifyContent: "flex-end",
        fontSize: "14px",
        fontWeight: "700",
    },
    timesheetItem: {
        //display: 'flex',
        alignItems: "center",
        //justifyContent: 'center',
        paddingTop: "2%",
        paddingBottom: "2%",
        paddingLeft: "3%",
    },
    Grid: {
        border: "1px solid #d9dddd",
    },
    GridItemEven: {
        //display: 'flex',
        //alignItems: 'center',
        //justifyContent: 'center',
        paddingTop: "2%",
        paddingBottom: "2%",
        marginLeft: "10%",
    },

    GridItemOdd: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "2%",
        paddingBottom: "2%",
    },
    GridItemEven: {
        backgroundColor: "#f8f8f0",
    },
    container: {
        width: window.innerWidth * 0.75,
        paddingRight: "15px",
        paddingLeft: "15px",
        marginRight: "30px",
        marginLeft: "30px",
    },
    containerScroll: {
        overflowY: "auto",
    },
});
