import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CommonList from '../views/CommonTableView';
const ProjectSubClassPage = () => {
    return (
        <PageTemplate>
           <CommonList page={"project_sub_class"}/>
        </PageTemplate>
    );
};

export default ProjectSubClassPage;

