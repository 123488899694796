import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field,change, formValueSelector} from "redux-form";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import MultiSelectComponent from "../../utils/multiselect"
import {InputText,TextArea,SelectInput,SavingDetailsModal,RenderSwitch} from "../../utils/formComponents";
const validate = inputs =>{
    const errors = {};
     if(!('details_of_document' in inputs)){
          errors.details_of_document = "Please enter details";
      }

     if(!('date_of_receipt' in inputs)){
          errors.date_of_receipt = "Please enter date of receipt";
      }


    if(!('client_id' in inputs)){
        errors.client_id = "Please select a client";
    }
    if(!('client_particulars' in inputs)){
        errors.client_particulars = "Please enter client particular";
    }
    return errors;

}

class AddInwardRegister extends Component{

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        inwardRegisterAddFailed:true,
        inwardRegister:{}
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
}

componentWillMount(){
    this.props.fetchClientList(this.props.token)
}
componentDidUpdate(){
    setTimeout(() => this.setState({message:''}), 9000);
    if(!this.state.inwardRegisterAddFailed){
        this.props.history.goBack();
    }
  }

closeModal(){
    this.setState({
        loading:this.props.loading,
        inwardRegisterAddFailed:this.props.inwardRegisterAddFailed
    })
}
async onsubmit(formData) {

    this.setState({
        loading:true,
        inwardRegister: formData
    })
    await this.setState(prevState =>({
        inwardRegister: {
            ...prevState.inwardRegister,
            client_id: formData['client_id']?formData['client_id']['id']:null
        }
    }))
    await this.props.addInwardRegister(this.state.inwardRegister,this.props.token);
    this.setState({
        message:this.props.message
    })
}
  render () {
    let state = this.state;
    const { handleSubmit,classes } = this.props;
    return (
      <Container>
        <Header title="add_inward_register" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
       Add Inward Register
      </Typography>
        <form onSubmit={handleSubmit(this.onsubmit)}>
          <Row>
            <Col sm={8}>
            <Field
                name= "client_present"
                component={RenderSwitch}
                label= "Client Present?"
            />
            </Col>
          {this.props.client_present ? <Col sm={4}>
            <span>Select Client</span>
              <Field
                  name="client_id"
                  label=""
                  props={{
                      type: 'object'
                  }}
                  component={MultiSelectComponent}
                  {...{
                      data:this.props.dclients,
                      isMulti : false
                  }

                  }
                  fullWidth
              />
            </Col>:
            <Col sm={4}>
              <Field
                        name="client_particulars"
                        label="Client particulars "
                        component={InputText}
                        type="text"
                        fullWidth
              />
            </Col>
}
         </Row>
          <Row>
            <Col sm={8}>

              <Field
                        name="details_of_document"
                        label="Details of document"
                        component={InputText}
                        type="text"
                        fullWidth
              />
            </Col>
            <Col sm={4}>

              <span>Date of receipt</span>
              <Field
                        name="date_of_receipt"
                        label=""
                        component={InputText}
                        type="date"
                        fullWidth
              />
            </Col>


        </Row>
          <Row>
            <Col sm={8}>
              <Field
                        name="remarks"
                        label="remarks"
                        component={InputText}
                        type="text"
                        fullWidth
              />
            </Col>
          </Row>
        <Row>
                    <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving inward document Details"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>
          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    )
  }
}
const selector = formValueSelector('AddInwardRegister')
function mapStateToProps(state) {
    return {
        dclients: state.client.dclientList,
        token:state.auth.token,
        message:state.inwardRegister.message,
        loading:state.inwardRegister.loading,
        inwardRegisterAddFailed: state.inwardRegister.inwardRegisterAddFailed,
        client_present: selector(state,'client_present')
    }
}
export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddInwardRegister",
      enableReinitialize: true,
validate }),
    withStyles(styles)
)(AddInwardRegister);
