import { START_FETCH_INDUSTRIES, FETCH_INDUSTRIES_SUCCESS, FETCH_INDUSTRIES_FAILED} from '../actions/types';

const DEFAULT_STATE={
    industries:[]
}
export default(state=DEFAULT_STATE, action)=>{
    switch(action.type){
        case START_FETCH_INDUSTRIES:
        return{
            ...state,
            industries:[]
        }
        case FETCH_INDUSTRIES_SUCCESS:
            return{
                ...state,
                industries: action.payload
            }
        case FETCH_INDUSTRIES_FAILED:
            return{
                ...state,
                industries:[]
            }
        default:
            return state
    }
}

