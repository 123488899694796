import {SET_PROJECT_DASHBOARD_DATA} from '../actions/types';
const DEFAULT_STATE = {
    project_data:{}
}
export default (state=DEFAULT_STATE,action)=>{
    switch(action.type){
        case SET_PROJECT_DASHBOARD_DATA:
            return{
                ...state,
                project_data: action.payload
            }
        default:
            return state
    }
}
