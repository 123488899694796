import axios from '../axiosConfig';
import {START_FETCH_INDUSTRIES,
    FETCH_INDUSTRIES_SUCCESS,
    FETCH_INDUSTRIES_FAILED } from "./types";

export const fetchIndustries = () => {
    return async (dispatch) => {
        dispatch({
            type: START_FETCH_INDUSTRIES,
        });
        try {
            const res = await axios.get(`/industries`);
            console.log('response',res);
            if (res.statusText == "OK") {
                // console.log("status ok!!!", res.data);
                dispatch({
                    type: FETCH_INDUSTRIES_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: FETCH_INDUSTRIES_FAILED,
                    payload: res.data,
                });
            }
        } catch (error) {
            // console.log(error);
            dispatch({
                type: FETCH_INDUSTRIES_FAILED,
                payload: error,
            });
        }
    };
};

