import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_FETCH_NOTIFICATION,
    FAILED_NOTIFICATION_FETCH,
    SUCCESS_NOTIFICATION_FETCH,
    CLEAR_NOTIFICATION_COUNT,
} from "./types";

export const fetchNotifications = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_FETCH_NOTIFICATION,
        });
        try {
            const res = await axios.get(`${BaseUrl}/notifications`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.data["status"] === "success") {
                dispatch({
                    type: SUCCESS_NOTIFICATION_FETCH,
                    payload: res.data["notifications"],
                });
            } else {
                dispatch({
                    type: FAILED_NOTIFICATION_FETCH,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: FAILED_NOTIFICATION_FETCH,
                payload: error.message,
            });
        }
    };
};
