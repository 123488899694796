import {START_FETCH_ACCESS_ROLES, ACCESS_ROLES_FETCH_SUCCESS, ACCESS_ROLES_FETCH_FAILED} from '../actions/types';

const DEFAULT_STATE={
    roles:[]
}

export default(state=DEFAULT_STATE,action)=>{
    switch(action.type){
        case START_FETCH_ACCESS_ROLES:
            return{
                ...state,
                roles:[]
            }
        case ACCESS_ROLES_FETCH_SUCCESS:
            return{
                ...state,
                roles: action.payload
            }
        case ACCESS_ROLES_FETCH_FAILED:
            return{
                ...state,
                roles:[]
            }
        default:
            return state
    }
}
