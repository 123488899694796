import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
// import AboutWrapper from '../containers/AboutWrapper/AboutWrapper'

const AdminPage = () => {
    return (
        <PageTemplate>
           admin Test
        </PageTemplate>
    );
};

export default AdminPage;

