import {
    START_INWARD_REGISTER_ADD,
    FETCH_INWARD_REGISTER_START,
    INWARD_REGISTER_ADD_SUCCESS,
    INWARD_REGISTER_ADD_FAILED,
    FETCH_INWARD_REGISTER_FAILED,
    FETCH_INWARD_REGISTER_SUCCESS,
} from "./types";
import axios from "axios";
import { BaseUrl } from "./api";

export const fetchInwardRegisterList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_INWARD_REGISTER_START,
        });
        try {
            const res = await axios.get(`${BaseUrl}/inward_register`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (res.data.status) {
                dispatch({
                    type: FETCH_INWARD_REGISTER_SUCCESS,
                    payload: res.data["inward_register"],
                });
            } else {
                dispatch({
                    type: FETCH_INWARD_REGISTER_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_INWARD_REGISTER_FAILED,
                payload: error.message,
            });
        }
    };
};

export const addInwardRegister = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_INWARD_REGISTER_ADD,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/inward_register`,
                { inward_register: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: INWARD_REGISTER_ADD_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: INWARD_REGISTER_ADD_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: INWARD_REGISTER_ADD_FAILED,
                payload: error.message,
            });
        }
    };
};
