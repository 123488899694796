import { CONNECTED_TO_CHANNEL,DISCONNECTED_CHANNEL} from '../actions/types';
const DEFAULT_STATE = {
    cable: {},
    channel: {},
}
export default (state = DEFAULT_STATE,action) =>{
    switch(action.type){
        case CONNECTED_TO_CHANNEL:
            return{
                ...state,
                channel: action.payload['channel'],
                cable:action.payload['cable']
            }
        case DISCONNECTED_CHANNEL:
            return {
                ...state,
                ...DEFAULT_STATE
            }
        default:
            return state
    }
}
