import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CommonList from '../views/CommonTableView';
const InwardRegisterPage = () => {
    return (
        <PageTemplate>
 <CommonList page={"inward_register"}/>
        </PageTemplate>
    );
};

export default InwardRegisterPage;

