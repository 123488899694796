import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_ADD_HOLIDAY,
    HOLIDAY_ADD_SUCCESS,
    HOLIDAY_ADD_FAILED,
    START_FETCH_HOLIDAY_LIST,
    HOLIDAY_LIST_FETCH_SUCCESS,
    HOLIDAY_LIST_FETCH_FAILED,
    START_FETCH_RESPECTIVE_HOLIDAY_CODE,
    FETCH_RESPECTIVE_HOLIDAY_CODE_SUCCESS,
    FETCH_RESPECTIVE_HOLIDAY_CODE_FAILED,
} from "./types";
export const fetchRespectiveCode = (token, date) => {
    return async (dispatch) => {
        dispatch({
            type: START_FETCH_RESPECTIVE_HOLIDAY_CODE,
        });
        try {
            const res = await axios.get(`${BaseUrl}/holiday_masters/${date}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.data.status) {
                dispatch({
                    type: FETCH_RESPECTIVE_HOLIDAY_CODE_SUCCESS,
                    payload: res.data["code"],
                });
                return res.data["code"];
            } else {
                dispatch({
                    type: FETCH_RESPECTIVE_HOLIDAY_CODE_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_RESPECTIVE_HOLIDAY_CODE_FAILED,
                payload: error.message,
            });
        }
    };
};
export const fetchHolidayList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_FETCH_HOLIDAY_LIST,
        });
        try {
            const res = await axios.get(`${BaseUrl}/holiday_masters`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (res.data.status) {
                dispatch({
                    type: HOLIDAY_LIST_FETCH_SUCCESS,
                    payload: res.data["holiday_master"],
                });
            } else {
                dispatch({
                    type: HOLIDAY_LIST_FETCH_FAILED,
                    payload: "Fetching holiday list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: HOLIDAY_LIST_FETCH_FAILED,
                payload: error.message,
            });
        }
    };
};

export const addHoliday = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_ADD_HOLIDAY,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/holiday_masters`,
                { holiday: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: HOLIDAY_ADD_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: HOLIDAY_ADD_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: HOLIDAY_ADD_FAILED,
                payload: error.message,
            });
        }
    };
};
