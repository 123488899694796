import { Card, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import "./profile.css";
import { Row, Col } from "react-bootstrap";
import { useFirmAssetinMyHand } from "../../hooks/profile-query";
import Table from "react-bootstrap/Table";
const useStyles = makeStyles({
    root: {
        "& .MuiInput-root": {
            color: "#FFD700",
            fontWeight: 700,
            fontSize: "20px",
            marginLeft: "10px",
        },
    },
});
export const FirmAssets = (props) => {
    const classes = useStyles();
    const { data, isLoading } = useFirmAssetinMyHand();
    return (
        <div className="body_background">
            {isLoading ? (
                <p>Loading..</p>
            ) : (
                <Table striped bordered hover variant="dark" size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Serial No</th>
                            <th>Description</th>
                            <th>Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.firm_assets.map((item, key) => (
                            <tr>
                                <td>
                                    <p>{item.id}</p>
                                </td>
                                <td>
                                    <p>{item.serial_no}</p>
                                </td>
                                <td>
                                    <p>{item.description}</p>
                                </td>
                                <td>
                                    <p>{item.category_of_asset}</p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
};
