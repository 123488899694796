import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_GENERAL_ACTIVITY_FETCH,
    START_GENERAL_ACTIVITY_ADD,
    GENERAL_ACTIVITY_ADD_SUCCESS,
    GENERAL_ACTIVITY_ADD_FAILED,
    GENERAL_ACTIVITY_FECH_SUCCESS,
    GENERAL_ACTIVITY_FETCH_FAILED,
    START_FETCH_COMBINED_ACTIVITY,
    FETCH_COMBINED_ACTIVITY_SUCCESS,
    FETCH_COMBINED_ACTIVITY_FAILED,
} from "./types";

export const fetchCombinedActivity = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_FETCH_COMBINED_ACTIVITY,
        });
        try {
            const res = await axios.get(`${BaseUrl}/get_all_activity`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            // console.log('response',res);
            if (res.statusText == "OK") {
                // console.log("status ok!!!", res.data);
                dispatch({
                    type: FETCH_COMBINED_ACTIVITY_SUCCESS,
                    payload: res.data["activities"],
                });
            } else {
                dispatch({
                    type: FETCH_COMBINED_ACTIVITY_FAILED,
                    payload: res.data,
                });
            }
        } catch (error) {
            // console.log(error);
            dispatch({
                type: FETCH_COMBINED_ACTIVITY_FAILED,
                payload: error,
            });
        }
    };
};
export const fetchActivity = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_GENERAL_ACTIVITY_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/activity`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            // console.log("response", res);
            if (res.statusText == "OK") {
                // console.log("status ok!!!", res.data);
                dispatch({
                    type: GENERAL_ACTIVITY_FECH_SUCCESS,
                    payload: res.data["activity_list"]["activities"],
                });
            } else {
                dispatch({
                    type: GENERAL_ACTIVITY_FETCH_FAILED,
                    payload: res.data,
                });
            }
        } catch (error) {
            // console.log(error);
            dispatch({
                type: GENERAL_ACTIVITY_FETCH_FAILED,
                payload: error,
            });
        }
    };
};
export const addActivity = (data, token) => {
    // console.log("add client data", data);
    return async (dispatch) => {
        dispatch({
            type: START_GENERAL_ACTIVITY_ADD,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/activity`,
                { activity: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log("response", res);
            if (res.status === 201) {
                dispatch({
                    type: GENERAL_ACTIVITY_ADD_SUCCESS,
                    payload: "Sucessfully added activity",
                });
            } else {
                dispatch({
                    type: GENERAL_ACTIVITY_ADD_FAILED,
                    payload: res.data,
                });
            }
        } catch (error) {
            // console.log(error);
            dispatch({
                type: GENERAL_ACTIVITY_ADD_FAILED,
                payload: error,
            });
        }
    };
};
