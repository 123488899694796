export default theme => ({
  tableContainer: {
    height: '70vh',

  },
  tableRowHeight:{
      minWidth: 650,
  },
  root: {
    width: '100%',
  },
     progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  }

});
