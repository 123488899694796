import axios from "../../axiosConfig";
import { useQuery,useMutation, useQueryClient} from "react-query";

async function getRecurringProjects(){
    try{
        const res = await axios.get(`/recurring_projects`);
        return res.data['recurrences']
    }catch(err){
        throw err.response.data;
    }
}
async function updateRecurringProject(data){
    try{
        const res = await axios.patch(`/recurring_project/${data.id}`,data);
        return res.data
    }catch(err){
        throw err.response.data;
    }
}

async function abortRecurringProjects(data){
    try{
        const res = await axios.patch(`/abort_recurrence/${data}`);
        return res.data['message']
    }catch(err){
        throw err.response.data;
    }
}

async function getRecurringProjectById({queryKey}){
    try{
        const res = await axios.get(`/recurring_project/${queryKey[1]}`);
        return res.data.recurrence;
    }catch(err){

        throw err.response.data;
    }
}

export const useAbortRecurringProjects = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => abortRecurringProjects(data),{
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries('recurringProjects')
        },
    })
}

export const useRecurringProjects = () => useQuery('recurringProjects', getRecurringProjects)


export const useRecurringProject = (id) => useQuery(['recurringProject',id], getRecurringProjectById,{
    refetchOnWindowFocus: false,
    enabled: !!id
})
export const useRecurringProjectUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation(
  (data) => updateRecurringProject(data),
    {
        onSuccess: (recurringProject,data) => { queryClient.invalidateQueries('recurringProjects')}
    }
)}

