
import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field,change, initialize} from "redux-form";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import MultiSelectComponent from "../../utils/multiselect"
import {InputText,TextArea,SelectInput,validate,SavingDetailsModal,RenderSwitch} from "../../utils/formComponents";
class AddSettings extends Component{

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        settingsAddFailed:true,
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
}
componentDidMount(){
    if(this.props.location.data){
        this.props.initialize(this.props.location.data)
    }
}
componentDidUpdate(prevProps){
        if(!this.state.settingsAddFailed){
        this.props.history.goBack();
    }
  }

closeModal(){
    this.setState({
        loading:this.props.loading,
        settingsAddFailed:this.props.settingsAddFailed
    })
}
async onsubmit(formData) {

    this.setState({
        loading:true,
    })
    if(this.props.location.data){
        await this.props.updateSetting(formData,this.props.token);
        this.setState({
            message:this.props.message
        })
    }else{
        await this.props.addSetting(formData,this.props.token);
        this.setState({
            message:this.props.message
        })
    }
}
  render () {
    let state = this.state;
const { handleSubmit,classes } = this.props;
    return (
      <Container>
        <Header title="add_settings" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
       Add Settings
      </Typography>
        <Row>
          <Col sm={4}>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(this.onsubmit)}>
        <Row>
            <Col sm={8}>

              <span>Key</span>
              <Field
                        name="key"
                        label="key"
                        component={InputText}
                        type="text"
                        disabled={this.props.location.data?true: false}
                        fullWidth
              />
        </Col>
            <Col sm={4}>

              <span>Value</span>

              <Field
                        name="value"
                        label="value"
                        component={InputText}
                        type="text"
                        fullWidth
              />
            </Col>
          </Row>
        <Row>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving Settings Details"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>
          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">{this.props.location.data ? "Update" : "Add"}</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    )
  }
}
function mapStateToProps(state) {
    return {
        token:state.auth.token,
        message:state.settingsReducer.message,
        loading:state.settingsReducer.settingAddLoading,
        settingsAddFailed:state.settingsReducer.settingAddFailed
    }
}
export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddSettings",
      enableReinitialize: true,
      keepDirtyOnReinitialize : true,
validate }),
    withStyles(styles)
)(AddSettings);
