import axios from "../../axiosConfig";
import { useMutation, useQuery, useQueryClient } from "react-query";

async function getEmployees() {
  try {
    const res = await axios.get(`/employee_list`);
    return res.data.employee_details;
  } catch (err) {
    throw err.response.data;
  }
}

async function getEmployeesDetails(id) {
  try {
    const res = await axios.get(`/employee/${id}`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getEmployeesDesignation(id) {
  try {
    const res = await axios.get(`/employee_designation/${id}`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getEmployeesDropDown() {
  try {
    const res = await axios.get(`/employee_drop_down_list`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}
async function employeeAprove(data){
    try{
        const res = await axios.patch(`/approve_employee/${data.id}`,data);
        return res.data
    }catch(err){
        console.log("error",err)
        throw err.response.data;
    }
}
async function suspendEmployee(id) {
  try {
    const res = await axios.patch(`/suspend_employee/${id}`);
    return res.data["message"];
  } catch (err) {
    throw err.response.data;
  }
}

export const useEmployeeList = () => useQuery("employees", getEmployees);

export const useEmployeeDropDown = () =>
  useQuery("employees-dropdown", getEmployeesDropDown);

export const useEmployeeDetails = (id) =>
  useQuery(["employee", id], () => getEmployeesDetails(id), {
    enabled: !!id,
  });

export const useEmployeeDesignation = (id) =>
  useQuery(["employee", id], () => getEmployeesDesignation(id), {
    enabled: !!id,
  });

export const useSuspendEmployee = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => suspendEmployee(data), {
    onSuccess: () => queryClient.invalidateQueries("employees"),
  });
};
export const useEmployeeApprove = () => {
    const queryClient = useQueryClient();
    return useMutation(
  (data) => employeeAprove(data),
    {
        onSuccess: () => { queryClient.invalidateQueries('employees')}
    }
)}
