import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_APPLYING_FOR_LEAVE,
    APPLYING_FOR_LEAVE_SUCCESS,
    APPLYING_FOR_LEAVE_FAILED,
    START_FETCH_LEAVE_APPLICATION,
    LEAVE_APPLICATION_FETCH_SUCCESS,
    LEAVE_APPLICATION_FETCH_FAILED,
    START_LEAVE_APPLICATION_LIST_FETCH,
    LEAVE_APPLICATION_LIST_FETCH_SUCCESS,
    LEAVE_APPLICATION_LIST_FETCH_FAILED,
    LEAVE_CATEGORY_FETCH_START,
    LEAVE_CATEGORY_FETCH_SUCCESS,
    LEAVE_CATEGORY_FETCH_FAILED,
} from "./types";

export const fetchLeaveCategory = (token) => {
    return async (dispatch) => {
        dispatch({
            type: LEAVE_CATEGORY_FETCH_START,
        });
        try {
            const res = await axios.get(`${BaseUrl}/nature_of_leaves`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: LEAVE_CATEGORY_FETCH_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: LEAVE_CATEGORY_FETCH_FAILED,
                    payload: "Fetching leave list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: LEAVE_CATEGORY_FETCH_FAILED,
                payload: error.message,
            });
        }
    };
};

export const fetchLeaveList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_LEAVE_APPLICATION_LIST_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/leaves`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.data["status"] === "success") {
                dispatch({
                    type: LEAVE_APPLICATION_LIST_FETCH_SUCCESS,
                    payload: res.data["leaves"],
                });
            } else {
                dispatch({
                    type: LEAVE_APPLICATION_LIST_FETCH_FAILED,
                    payload: "Fetching leave list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: LEAVE_APPLICATION_LIST_FETCH_FAILED,
                payload: "Fetching leave list failed",
            });
        }
    };
};
export const applyForLeave = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_APPLYING_FOR_LEAVE,
        });
        try {
            const res = await axios.post(`${BaseUrl}/leave`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.data.status) {
                dispatch({
                    type: APPLYING_FOR_LEAVE_SUCCESS,
                    payload: "Sucessfully applied for leave",
                });
            } else {
                dispatch({
                    type: APPLYING_FOR_LEAVE_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: APPLYING_FOR_LEAVE_FAILED,
                payload: error.message,
            });
        }
    };
};
