import React from "react";
import PageTemplate from "../components/Layout/PageTemplate/PageTemplate";
import CoreUITable from "../components/Layout/Table";
import { useHistory } from "react-router-dom";
import { useClientDetailList } from "../hooks/client-query/clients";
import ProgressBar from "react-bootstrap/ProgressBar";
const ClientPage = () => {
    const { isLoading, isError, data, error, refetch } = useClientDetailList();
    const history = useHistory();
    if (isLoading)
        return <ProgressBar striped variant="success" animated now={40} />;
    if (isError) return <>Error...</>;
    const options = [
        {
            text: "View",
            action: function (id) {
                history.push({
                    pathname: "/clientdetails",
                    data: { id: id, type: "update" },
                });
            },
            params: true,
        },
        {
            text: "Edit",
            action: function (id) {
                history.push({
                    pathname: "/addclient",
                    data: { id: id, type: "update" },
                });
            },
            params: true,
        },
    ];

    return (
        <PageTemplate>
            <CoreUITable
                page="client"
                title="Clients"
                addPath="/addclient"
                options={options}
                data={data}
                fields={[
                    "clientid",
                    "name",
                    "client_class",
                    "contact_person",
                    "contact_no",
                    {
                        key: "show_details",
                        label: "",
                        _style: { width: "1%" },
                        filter: false,
                    },
                ]}
            />
        </PageTemplate>
    );
};

export default ClientPage;
