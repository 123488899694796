import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { connect } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import { Button, TextField } from '@material-ui/core';
import { compose } from 'redux';
import * as actions from '../../actions';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
import {
  InputText,
  SelectInput,
  validate,
  SavingDetailsModal,
} from '../../utils/formComponents';
import Modal from '../../utils/modals/Modal';
import { withStyles } from '@material-ui/core';
import styles from '../../views/modelstyles';
class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      loading: false,
      signupFailed: true,
      isOpen: true,
      key: '',
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onPassKey = this.onPassKey.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  closeModal() {
    this.setState({
      loading: this.props.loading,
      signupFailed: this.props.signupFailed,
    });
  }

  async componentWillMount() {
    await this.props.getPassKey();
  }

  async componentDidUpdate() {
    if (!this.state.signupFailed) {
      this.props.history.push('/pending_for_verification');
    }
  }

  async onsubmit(formData) {
    this.setState({
      loading: true,
    });
    await this.props.signup(formData);
    this.setState({
      message: this.props.message,
    });
  }

  async onPassKey() {
    await this.props.verifyPasskey({ passkey: this.state.key });
  }

  handleClose() {
    this.props.history.push('/login');
  }

  render() {
    const { handleSubmit, classes } = this.props;
    if (this.props.isAuth) {
      return <Redirect to='/' />;
    }
    return (
      <Container>
        <Typography
          component='h1'
          variant='h2'
          align='center'
          color='textPrimary'
          gutterBottom
        >
          Registration
        </Typography>

        <form onSubmit={handleSubmit(this.onsubmit)} noValidate>
          <Row>
            <Col sm={8}>
              <span>Name</span>
              <Field
                name='name'
                label='Name *'
                component={InputText}
                type='text'
                fullWidth
              />
            </Col>
            <Col sm={4}>
              <span>Gender</span>
              <Field
                name='gender'
                label='Gender *'
                component={SelectInput}
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <span>Blood Group</span>
              <Field
                name='bloodgroup'
                label='Blood Group *'
                component={SelectInput}
                fullWidth
              />
            </Col>
            <Col sm={4}>
              <span>Address</span>
              <Field
                name='address'
                label='Address *'
                component={InputText}
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <span>District</span>
              <Field
                name='district'
                label='District *'
                component={InputText}
                fullWidth
              />
            </Col>
            <Col sm={4}>
              <span>Emergency Contact #</span>
              <Field
                name='emergency_contact_no'
                label='Emergency Contact # *'
                component={InputText}
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <span>State</span>
              <Field
                name='state'
                label='State *'
                component={SelectInput}
                fullWidth
              />
            </Col>
            <Col sm={4}>
              <span>City</span>
              <Field
                name='city'
                label='City *'
                component={InputText}
                type='text'
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <span>Pin Code</span>
              <Field
                name='pincode'
                label='Pin Code *'
                component={InputText}
                type='number'
                fullWidth
              />
            </Col>
            <Col sm={4}>
              <span>Date Of Birth</span>
              <Field
                name='dob'
                label=''
                component={InputText}
                type='date'
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <span>Aadhaar Number</span>
              <Field
                name='aadhaar'
                label='Aadhaar No *'
                component={InputText}
                type='number'
                fullWidth
              />
            </Col>

            <Col sm={4}>
              <span>PAN Number</span>
              <Field
                name='pan'
                label='PAN no *'
                component={InputText}
                type='text'
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <span>Personal Email ID</span>
              <Field
                name='email'
                label='Personal Email'
                component={InputText}
                type='email'
                fullWidth
              />
            </Col>
            <Col sm={4}>
              <span>Contact Number</span>
              <Field
                name='contact'
                label='Contact No*'
                component={InputText}
                type='tel'
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <span>Password</span>
              <Field
                name='password'
                label='Password *'
                component={InputText}
                type='password'
                fullWidth
              />
            </Col>
            <Col sm={4}>
              <span>User ID (Work Email Id)</span>
              <Field
                name='work_email'
                label='User Id (Work Email) *'
                component={InputText}
                type='email'
                fullWidth
              />
            </Col>
          </Row>
          <Row>

            <Col>
              <Button
                variant='contained'
                color='primary'
                onClick={this.props.history.goBack}
                type='button'
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type='submit' variant='contained' color='primary'>
                Register
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <SavingDetailsModal
                className={classes.modal}
                show={this.state.loading}
                title={'Registering... '}
                loading={this.props.loading}
                message={this.props.message}
                onClick={this.closeModal}
              />
            </Col>
          </Row>
          <Row></Row>
        </form>

        <Modal
          handleClose={this.handleClose}
          isOpen={!this.props.passKey}
          title='Passkey'
        >
          <div style={{ display: 'flex', gap: '6px', marginBottom: '12px' }}>
            <TextField
              id='passkey'
              label='Enter passkey receeived from HR Department'
              variant='outlined'
              style={{ width: '100%' }}
              value={this.state.key}
              onChange={(e) => this.setState({ key: e.target.value })}
            />
            <Button
              onClick={() => this.onPassKey()}
              variant='contained'
              color='primary'
            >
              Submit
            </Button>
          </div>
        </Modal>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.auth.errorMessage,
    isAuth: state.auth.isAuthenticated,
    loading: state.auth.loading,
    signupFailed: state.auth.signupFailed,
    passKey: state.auth.passKey,
    passKeyLoading: state.auth.passKeyLoading,
    passKeyFailed: state.auth.passKeyFailed,
  };
}

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({ form: 'SignUp', validate }),
  withStyles(styles)
)(SignUp);
