import axios from "axios";

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
		'Accept': 'application/json',
        'Content-Type': 'application/json',
        'dataType': 'json',
	},
})

instance.interceptors.request.use(async (config) => {
	config.headers.Authorization = `Bearer ${localStorage.getItem("JWT_TOKEN")}`;
	return config;
});
export default instance;
