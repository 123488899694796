import {START_SAC_MASTER_ADD,START_SAC_MASTER_FETCH_LIST,SAC_MASTER_ADD_SUCCESS,SAC_MASTER_ADD_FAILED,SAC_MASTER_FETCH_LIST_SUCCESS,SAC_MASTER_FETCH_LIST_FAILED} from '../actions/types';

const DEFAULT_STATE ={
    sacMasterList: [],
    loading: false,
    errorMessage: '',
    message:'',
    sacmasterAddLoading: false,
    sacmasterAddFailed: true
}

export default (state = DEFAULT_STATE,action)=>{
    switch(action.type){
        case START_SAC_MASTER_ADD:
            return{
                ...state,
                sacmasterAddLoading: true,
                sacmasterAddFailed: false,
                message:''
            }
        case SAC_MASTER_ADD_SUCCESS:
            return{
                ...state,
                sacmasterAddLoading: false,
                sacmasterAddFailed: false,
                message: action.payload
            }
        case SAC_MASTER_ADD_FAILED:
            return{
                ...state,
                sacmasterAddLoading: false,
                sacmasterAddFailed: true,
                message: action.payload
            }
        case START_SAC_MASTER_FETCH_LIST:
            return{
                ...state,
                loading: true,
                sacMasterList: []
            }
        case SAC_MASTER_FETCH_LIST_FAILED:
            return{
                ...state,
                loading: false,
                errorMessage: action.payload
            }
        case SAC_MASTER_FETCH_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                sacMasterList: action.payload
            }
        default:
            return state
    }
}

