import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import configStore from "./store/store";
import App from "./App";
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from "react-query";
import serviceWorkerDev from './service-workerDev';
let configstore = configStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
// console.log("HI",process.env)
if (process.env.NODE_ENV !== "development") console.log = () => {};

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={configstore}>
      <App />
    </Provider>
  </QueryClientProvider>,
  document.querySelector("#root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerDev();
