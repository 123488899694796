import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CommonList from '../views/CommonTableView';
const ProjectClassPage = () => {
    return (
        <PageTemplate>
           <CommonList page={"project_class"}/>
        </PageTemplate>
    );
};

export default ProjectClassPage;

