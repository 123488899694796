import {START_INWARD_REGISTER_ADD,FETCH_INWARD_REGISTER_START,INWARD_REGISTER_ADD_SUCCESS,INWARD_REGISTER_ADD_FAILED,FETCH_INWARD_REGISTER_FAILED,FETCH_INWARD_REGISTER_SUCCESS} from '../actions/types';
const DEFAULT_STATE={
    inwardRegisterList:[],
    loading: false,
    message:'',
    inwardRegisterAddFailed:true
}
export default(state=DEFAULT_STATE,action)=>{
    switch(action.type){
        case START_INWARD_REGISTER_ADD:
            return{
                ...state,
                loading: true,
                message:'',
                inwardRegisterAddFailed: false
            }
        case INWARD_REGISTER_ADD_SUCCESS:
            return{
                ...state,
                loading: false,
                message: action.payload,
                inwardRegisterAddFailed: false
            }
        case INWARD_REGISTER_ADD_FAILED:
            return{
                ...state,
                loading: false,
                message: action.payload,
                inwardRegisterAddFailed: true
            }
        case FETCH_INWARD_REGISTER_START:
            return{
                ...state,
                loading: true,
                message:''
            }
        case FETCH_INWARD_REGISTER_SUCCESS:
            return{
                ...state,
                loading: false,
                inwardRegisterList: action.payload
            }
        case FETCH_INWARD_REGISTER_FAILED:
            return{
                ...state,
                loading: false,
                message: action.payload
            }
        default:
            return state
    }
}
