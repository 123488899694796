import { Card, TextField } from '@material-ui/core';
import React from 'react';
import "./profile.css";
import { Row, Col } from 'react-bootstrap';
import {useLifeCycle} from '../../hooks/profile-query'
    export const LifeCycle  = props =>{

    const { data, isLoading } = useLifeCycle()
        return (
            <div className="body_background" >
                <Card className="LC1" style={{backgroundColor:'#222b36' ,color:'azure' }}>
                    {isLoading ? <p>Loading</p>
                    :
                    <>
                        <Row>
                            <Col><p>Employee Id:</p></Col>
                            <Col><p>{data.emp_id}</p></Col>
                        </Row>
                        <Row>
                            <Col><p>Organisation:</p></Col>
                            <Col><p>{data.organisation}</p></Col>
                        </Row>
                        <Row>
                            <Col><p>Date of Joining:</p></Col>
                            <Col><p>{data.doj}</p></Col>
                        </Row>
                        <Row>
                            <Col><p>Date of termination:</p></Col>
                            <Col><p>{data.dot}</p></Col>
                        </Row>
                        <Row>
                            <Col><p>Current Designation:</p></Col>
                            <Col><p>{data.designation}</p></Col>
                        </Row>
                        <Row>
                            <Col><p>Effective From:</p></Col>
                            <Col><p>{data.effective_from}</p></Col>
                        </Row>
                        <Row>
                            <Col><p>Reporting to:</p></Col>
                            <Col><p>{data.reporting_to}</p></Col>
                        </Row>
                        <Row>
                            <Col><p>Previous Employee-ID:</p></Col>
                            <Col><p>{data.previous_employee_id}</p></Col>
                        </Row>
                        <Row>
                            <Col><p>Current Status:</p></Col>
                            <Col><p>{data.current_status}</p></Col>
                        </Row>
                        <Row>
                            <Col><p>Qualification:</p></Col>
                            <Col><p>{data.qualification}</p></Col>
                        </Row>
                        <Row>
                            <Col><p>Prior years of experience:</p></Col>
                            <Col><p>{data.prior_years_of_experiance}</p></Col>
                        </Row>
                        <Row>
                            <Col><p>Years of experience:</p></Col>
                            <Col><p>{data.years_of_experiance}</p></Col>
                        </Row>

                    </>}
                </Card>

            </div>
        )
    }
