

import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field,change,getFormValues } from "redux-form";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import {find} from 'lodash';
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import {InputText,TextArea,SelectInput,validate,SavingDetailsModal} from "../../utils/formComponents";
class MovementRegisterOutForm extends Component{

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        movementStatusChangeFailed:true,
        movement_register_id: 0
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
}

componentWillMount(){
    this.props.fetchRegisterList(this.props.token,"In");
}
componentDidUpdate(){
    if(typeof(this.props.formValues) != 'undefined'){
        if(this.state.movement_register_id !== this.props.formValues["movement_register_id"] && this.props.formValues['movement_register_id'] !== undefined){
            let temp = find(this.props.movementRegisterDropdownList,{id:this.props.formValues['movement_register_id']})

            this.props.dispatch(change("MovementRegisterOutForm","expiry_date",temp['expiry_date']))
            this.props.dispatch(change("MovementRegisterOutForm","token_provider",temp['token_provider']))
            this.setState({
                movement_register_id: this.props.formValues['movement_register_id']
            })

        }
    }
    setTimeout(() => this.setState({message:''}), 9000);
    if(!this.state.movementStatusChangeFailed){
        this.props.history.goBack();
    }
  }

closeModal(){
    this.setState({
        loading:this.props.loading,
        movementStatusChangeFailed:this.props.movementStatusChangeFailed
    })
}
async onsubmit(formData) {

    this.setState({
        loading:true
    })
    await this.props.movementRegisterOut(this.props.token,formData);
    this.setState({
        message:this.props.message
    })
}
  render () {
    let state = this.state;
const { handleSubmit,classes } = this.props;
    return (
      <Container>
        <Header title="movement_register_in_form" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
       Movement Register Out
      </Typography>
        <Row>
          <Col sm={4}>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(this.onsubmit)}>
          <Row>
            <Col sm={8}>

              <span>Select Name</span>
              <Field
                name="movement_register_id"
                        label=" "
                        props={{
                            type: 'object'
                        }}
                        component={SelectInput}
                        data={this.props.movementRegisterDropdownList}
                        fullWidth
             />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>

              <span>Token Provider</span>
              <Field
                        name="token_provider"
                        label="Token Provider *"
                        component={InputText}
                        type="text"
                        disabled={true}
                        fullWidth
              />
        </Col>

            <Col sm={4}>

              <span>Expiry Date</span>

              <Field
                        name="expiry_date"
                        label=" expiry date"
                        component={InputText}
                        disabled={true}
                        type = "date"
                        fullWidth
              />
            </Col>
          </Row>
        <Row>

            <Col sm={8}>
                           <span>Handed Over On</span>
                           <Field
                        name="handed_over_on"
                        label=""
                        component={InputText}
                        type="date"
                        fullWidth
                    />
            </Col>
            <Col sm={4}>
                         <span>Handed Over To</span>
                           <Field
                        name="handed_over_to"
                        label=""
                        component={InputText}
                        type="text"
                        fullWidth
                    />
            </Col>
        </Row>
        <Row>
        <Col>
                <span>Remarks</span>
                           <Field
                        name="remarks_on_hand_over"
                        label="Remarks"
                        component={InputText}
                        type="text"
                        fullWidth
                    />
        </Col>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Changeing movemen register satus"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>
          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    )
  }
}

function mapStateToProps(state) {
    return {
        token:state.auth.token,
        message:state.movementregister.movementStatusChangeMessage,
        movementRegisterDropdownList:state.movementregister.movementRegisterDropdownList,
        loading:state.movementregister.movementStatusChangeLoading,
        movementStatusChangeFailed:state.movementregister.movementStatusChangeFailed,
        formValues: getFormValues('MovementRegisterOutForm')(state)

    }
}
export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "MovementRegisterOutForm",
      enableReinitialize: true,
validate }),
    withStyles(styles)
)(MovementRegisterOutForm);
