import React from "react";
import { Button, TextField } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "grey",
            borderRadius: "20px",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "azure",
        },

        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
                borderColor: "#08698a",
                fontSize: "20px",
            },

        "& .MuiOutlinedInput-root": {
            color: "azure",
        },
        "&:hover .MuiOutlinedInput-root": {
            backgroundColor: "#222b36",
        },
    },
    formLabel: {
        color: "azure",
        "&.Mui-focused": {
            color: "#129ac7",
            fontSize: "20px",
        },
    },
});
export const InputField = (props) => {
    let {
        disabled,
        type,
        label,
        field: { name, value, onChange },
    } = props;

    const classes = useStyles();
    return (
        <TextField
            className={classes.root}
            id={name}
            label={label}
            variant="outlined"
            InputLabelProps={{
                className: classes.formLabel,
            }}
            disabled={disabled}
            value={value}
            type={type}
            onChange={onChange}
        />
    );
};
