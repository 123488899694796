import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Hidden,
  MenuList,
  MenuItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import {
  Apps,
  Timeline,
  Money,
  Assignment,
  Person,
  Layers,
  Close,
  PersonPinCircleTwoTone,
} from '@material-ui/icons';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import NotificationsIcon from '@material-ui/icons/Notifications';
const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  materialIcons: {
    marginRight: '10px',
  },
});

const SignedInLink = (props) => {
  const {
    classes,
    pathname,
    open,
    handleClose,
    handleClickOpen,
    handleCloseSignOut,
    user,
  } = props;
  const [dscopen, setDscopen] = React.useState(false);
  const [tandlopen, setTandlopen] = React.useState(false); //tandl =>time and leave management
  const [inoutopen, setInOut] = React.useState(false);
  const [openAdminD, setAdminD] = React.useState(false); //show admin dropdown
  const [openProjectsD, setProjectsD] = React.useState(false);
  const [openInvoice, setOpenInvoice] = React.useState(false);
    const [openProfile, setOpenProfile] = React.useState(false);
    const [reportOpen, setReportOpen] = React.useState(false);
  const handleTandlclick = () => {
    setTandlopen(!tandlopen);
  };
    const handleReportClick = () => {
        setReportOpen(!reportOpen);
    };
  const handleProjectDropDownClick = () => {
    setProjectsD(!openProjectsD);
  };
  const handleAdminDropDownClick = () => {
    setAdminD(!openAdminD);
  };
  const handleInOut = () => {
    setInOut(!inoutopen);
  };
  const handleDscClick = () => {
    setDscopen(!dscopen);
  };
  const handleProfileOnClick = () => {
    setOpenProfile(!openProfile);
  };
  const renderMenuItems = () => {
    return (
      <MenuList>
        <Divider />
        <MenuItem component={Link} to='/' selected={'/' === pathname}>
          <ListItemIcon className={classes.icon}>
            <Apps />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary='Dashboard'
          />
        </MenuItem>
        <Divider />
        <MenuItem
          component={Link}
          to='/client'
          selected={'/client' === pathname}
        >
          <ListItemIcon className={classes.icon}>
            <PersonPinCircleTwoTone />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary='Clients'
          />
        </MenuItem>
        <Divider />
        <ListItem button onClick={handleProjectDropDownClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary='Projects' />
          {openProjectsD ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openProjectsD} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/project'>
                <ListItemText primary='Ongoing Projects' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/pendingArchivedProjects'>
                <ListItemText primary='Pending archival' />
              </Link>
            </ListItem>

            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/archivedProjects'>
                <ListItemText primary='Archived/Suspended' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/pendingRecApprovel'>
                <ListItemText primary='Pending project creation' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/recurring_projects'>
                <ListItemText primary='Recurring Projects' />
              </Link>
          </ListItem>
          {user !== undefined &&
          (user.designation === 'Partner' || user.designation == 'Admin') && (
          <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/progressive_and_unbilled'>
                <ListItemText primary='Unbilled Projects' />
              </Link>
          </ListItem>)}
          </List>
        </Collapse>
        <Divider />
        <ListItem button onClick={() => setOpenInvoice(!openInvoice)}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary='Invoice' />
          {openInvoice ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openInvoice} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/final_invoices'>
                <ListItemText primary='Final' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/invoice'>
                <ListItemText primary='Drafts' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/ope'>
                <ListItemText primary='OPE' />
              </Link>
            </ListItem>
          </List>
        </Collapse>
        <Divider />
        <ListItem button onClick={handleDscClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary='DSC' />
          {dscopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={dscopen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/movementRegister'>
                <ListItemText primary='Movement Register' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/dsc_signatures'>
                <ListItemText primary='DSC Signatures' />
              </Link>
            </ListItem>
          </List>
      </Collapse>
      <Divider />
        <ListItem button onClick={handleReportClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary='Report' />
          {reportOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={reportOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/invoice_report'>
                <ListItemText primary='Invoice Report' />
              </Link>
            </ListItem>
          </List>
        </Collapse>
        <Divider />
        <ListItem button onClick={handleInOut}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary='Registers' />
          {inoutopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={inoutopen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/certificate_register'>
                <ListItemText primary='Certificate Register' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/audit_document_register'>
                <ListItemText primary='Audit Document Register' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/inward_register'>
                <ListItemText primary='Inward Register' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/outward_register'>
                <ListItemText primary='Outward Register' />
              </Link>
            </ListItem>
          </List>
        </Collapse>
        <Divider />
        <ListItem button onClick={handleTandlclick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary='HR' />
          {tandlopen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={tandlopen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon className={classes.icon}>
                <Money />
              </ListItemIcon>
              <Link to='/attendence'>
                <ListItemText primary='Attendence' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/addNewTimeSheet'>
                <ListItemText primary='TimesheetNew' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/timesheet'>
                <ListItemText primary='Timesheet' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/leavemanagement'>
                <ListItemText primary='Leave Management' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <Link to='/pending_attendence'>
                <ListItemText primary='Pending' />
              </Link>
            </ListItem>
          </List>
        </Collapse>
        {user !== undefined &&
          (user.designation === 'Partner' || user.designation == 'Admin') && (
            <>
              <Divider />
              <ListItem button onClick={handleAdminDropDownClick}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary='Admin' />
                {openAdminD ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openAdminD} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/activity'>
                      <ListItemText primary='Activity' />
                    </Link>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/sacmaster'>
                      <ListItemText primary='SAC Masters' />
                    </Link>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/specmaster'>
                      <ListItemText primary='SPEC Masters' />
                    </Link>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/specifics'>
                      <ListItemText primary='Specifics' />
                    </Link>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/holiday_master'>
                      <ListItemText primary='Holiday Master' />
                    </Link>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/employee'>
                      <ListItemText primary='Employees' />
                    </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/firm_assets'>
                      <ListItemText primary='Firm Assets' />
                    </Link>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/client_class'>
                      <ListItemText primary='Client Class' />
                    </Link>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/organisation'>
                      <ListItemText primary='Organisation' />
                    </Link>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/nature_of_expense'>
                      <ListItemText primary='Nature of expense' />
                    </Link>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/project_class'>
                      <ListItemText primary='project Class' />
                    </Link>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/project_sub_class'>
                      <ListItemText primary='Project sub-class' />
                    </Link>
                  </ListItem>

                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <Link to='/settings'>
                      <ListItemText primary='Settings' />
                    </Link>
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}
        <Divider />
        <ListItem button onClick={handleProfileOnClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary='Profile' />
          {openProfile ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openProfile} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem
              className={classes.nested}
              button
              onClick={() => handleClickOpen()}
            >
              <ListItemIcon className={classes.icon}>
                <Money />
              </ListItemIcon>
              <ListItemText primary='Logout' />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <Link to='/notifications'>
                <ListItemText primary='Notifications' />
              </Link>
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <Money />
              </ListItemIcon>
              <Link to='/profile'>
                <ListItemText primary='Profile' />
              </Link>
            </ListItem>
          </List>
        </Collapse>
      </MenuList>
    );
  };
  return (
    <div>
      <Hidden xsDown>
        <div className={classes.toolbar} />
      </Hidden>
      {renderMenuItems()}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Sign out?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are u sure u want to sign out
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            cancel
          </Button>
          <Button onClick={handleCloseSignOut} color='primary' autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(SignedInLink);
