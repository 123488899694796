import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { PDFViewer } from "@react-pdf/renderer";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
} from "@react-pdf/renderer";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import FooterLastLine from "./FooterLastLine";
// Create styles
import openSans from "../../assets/fonts/OpenSans-Regular.ttf";
import openSansiLight from "../../assets/fonts/OpenSans-Light.ttf";
import openSansBold from "../../assets/fonts/OpenSans-Bold.ttf";
import jamesAndJames from "../../assets/fonts/Flamenco-Regular.ttf";
import subHeading from "../../assets/fonts/ERAS.TTF";
import { useDispatch, useSelector } from "react-redux";
import { finalInvoiceDetailsFetch } from "../../actions/invoiceAction";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useLocation } from "react-router-dom";
Font.register({
    family: "'Open Sans', sans-serif",
    src: openSansBold,
});
Font.register({
    family: "'Open Sans',light",
    src: openSansiLight,
});
Font.register({
    family: "Open Sans",
    src: openSans,
});
Font.register({
    family: "James",
    src: jamesAndJames,
});
Font.register({
    family: "subHeading",
    src: subHeading,
});

const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        padding: 30,
        //justifyContent: 'space-between'
    },
});

export default function ViewInvoice(props) {
    const { auth } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [progress, setProgress] = React.useState(0);
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [finalInvoice, setFinalInvoice] = useState({});
    const isFirstRender = useRef(true);
    const id = location.pathname.split("viewInvoice/").pop() + "";
    useEffect(() => {
        dispatch(finalInvoiceDetailsFetch(auth.token, id)).then((resp) => {
            setFinalInvoice({ ...resp });
        });
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false; // toggle flag after first render/mounting
            return;
        }
        setLoading(false);
    }, [finalInvoice]);

    return (
        <>
            {loading ? (
                <ProgressBar striped variant="success" animated now={40} />
            ) : (
                <PDFViewer style={{ height: "100vh", width: "100vw" }}>
                    <Document>
                        <Page size="A4" style={styles.page}>
                            <Header props={finalInvoice.organisation} nature={finalInvoice.nature} />
                            <Body props={finalInvoice} />
                            <Footer props={finalInvoice} />
                            <FooterLastLine />
                        </Page>
                    </Document>
                </PDFViewer>
            )}
        </>
    );
}
