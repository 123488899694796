import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CommonList from '../views/CommonTableView';
const SettingsPage = () => {
    return (
        <PageTemplate>
           <CommonList
            page={"settings"}
        />
        </PageTemplate>
    );
};

export default SettingsPage;

