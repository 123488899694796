import React from 'react';
import{useDscAdd,useDocumentCategories} from '../../hooks/dsc-query/dscSignatures'
import{useProjects} from '../../hooks/project-query/projects'
import{useClients} from '../../hooks/client-query/clients'
import{useDscs} from '../../hooks/dsc-query/dscs'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Row, Col,Container,Badge,Table } from 'react-bootstrap';
import Header from "../../components/Layout/Header/Header";
import { Formik, Field, Form, ErrorMessage, FieldArray,getIn } from 'formik';
import { AlertComponent} from "../../utils/formComponents";
import Select from 'react-select';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import moment from 'moment';
const initialValues = {
    client_id: '',
    project_id: '',
    dsc_id: '',
    request_particulars: '',
    dsc_signature_details_attributes:[
        {
            date_of_signing: '',
            document_category_id: '',
            particulars_of_document: ''
        }
    ]
}
const selectStyles = {
      menu: base => ({
        ...base,
        zIndex: 100
      })
    };
export default function AddDscSignature(props){
    const [clientId,setClientId] = React.useState(undefined)
    const {  data:projects } = useProjects(clientId);
    const {  data:dscs } = useDscs(clientId);
    const { data:clients } = useClients()
    const [formValues,setFormValues] = React.useState({})
    const {data: document_categories} = useDocumentCategories()
    const [showDetails, setShowDetails] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const {isLoading:dscAddLoading, mutate, data: dscAddData, error} = useDscAdd()
    const history = useHistory();
    const state = useSelector(state => state)

    const validateSelection=(value)=>{
        let error;
        if(!value){
            error = 'Required to proceed';
        }
        return error
    }
    const signingDateValidation =(expiry_date)=>{
        if( moment(expiry_date).isBefore(moment().format("YYYY-MM-DD")) ){
            return expiry_date
        }else{
            return moment().format("YYYY-MM-DD")
        }
    }
    React.useEffect(()=>{
        if(dscAddLoading) setMessage("Adding new dsc signature details")
        if(dscAddData && dscAddData.message) setMessage(dscAddData['message'])
        if(error) setMessage(error)
    },[dscAddLoading,dscAddData,error])

    React.useEffect(()=>{
        if(!showDetails){
            if(dscAddData && dscAddData['status']){
              history.goBack();
            }
        }
    },[showDetails])
    return(
        <Container>
            <Header title="add_dsc_signature"/>
            <h1 style={{marginLeft: '20%'}}> <Badge variant="dark">Add new DSC signature details</Badge> </h1>
            <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
                values['created_by_id'] = state.auth.user.id
                mutate({...values})
                setShowDetails(true)
            }}
            >
            {({ values,errors, touched,setFieldValue }) => (
            <Form>
                <Row style={{ marginTop: '5%'}}></Row>

                <Row style={{ alignItems: 'center'}}>
                    <Col sm={4}>
                        <span>Client</span>
                    </Col>
                    <Col sm={4}>
                    <Field
                        name="client_id"
                        options={clients}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        component={Select}
                        onChange={(event)=>{setClientId(event.id); setFieldValue('client_id',event.id); setFieldValue('project_id', null)}}
                        getOptionLabel={(option)=>option.name}
                        getOptionValue={(option)=>option.id}
                        validate={validateSelection}
                    />
                    {errors.client_id && touched.client_id && <div  style={{ marginTop: '2%',alignItems: 'center'}}>{errors.client_id}</div>}
                    </Col>
                </Row>
                <Row style={{ marginTop: '2%',alignItems: 'center'}}>
                    <Col sm={4}>
                        <span>Project</span>
                    </Col>
                    <Col sm={4}>
                        <Field
                            name="project_id"
                            options={projects}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            component={Select}
                            onChange={(event)=>{ setFieldValue('project_id',event.id)}}
                            getOptionLabel={(option)=>option.name}
                            getOptionValue={(option)=>option.id}
                            styles={selectStyles}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '2%',alignItems: 'center'}}>
                    <Col sm={4} >
                        <span>Request Particulars</span>
                    </Col>
                    <Col sm={4}>
                        <Field
                            name="request_particulars"
                            type="text"
                            style={{width: '100%'}}
                            validate={ values.project_id ? undefined : validateSelection}
                        />
                        { !values.project_id && errors.request_particulars && touched.request_particulars && <div  style={{ marginTop: '2%',alignItems: 'center'}}>{errors.request_particulars}</div>}
                    </Col>

                </Row>
                <Row style={{ marginTop: '2%',alignItems: 'center'}}>
                    <Col sm={4} >
                        <span>DSC Id</span>
                    </Col>
                    <Col sm={4}>
                        <Field
                            name="dsc_id"
                            options={dscs}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            component={Select}
                            getOptionLabel={(option)=>option.name}
                            onChange={(event)=>{
                                setFieldValue('token_no',event.token_no);
                                setFieldValue('expiry_date',event.expiry_date)
                                setFieldValue('dsc_id',event.id)
                            }}
                            getOptionValue={(option)=>option.id}
                            validate={validateSelection}
                            styles={selectStyles}
                    />
                    {errors.dsc_id && touched.dsc_id && <div  style={{ marginTop: '2%',alignItems: 'center'}}>{errors.dsc_id}</div>}
                    </Col>

                </Row>
                <Row style={{ marginTop: '2%',alignItems: 'center'}}>
                    <Col sm={4} >
                        <span>Token No</span>
                    </Col>
                    <Col sm={4}>
                        <Field
                            name="token_no"
                            type="text"
                        style={{width: '100%'}}
                        disabled= {true}
                        />
                    </Col>

                </Row>
                <Row style={{ marginTop: '2%',alignItems: 'center'}}>
                    <Col sm={4} >
                        <span>Expiry Date</span>
                    </Col>
                    <Col sm={4}>
                        <Field
                            name="expiry_date"
                            type="text"
                        style={{width: '100%'}}
                        disabled= {true}
                        />
                    </Col>

                </Row>
                <Row style={{width: '70%', marginTop: '3%'}}><Col sm={4}><h5>Signature details</h5></Col> </Row>
                <Table responsive style={{width: '70%', marginTop: '2%'}}>
                    <thead>
                        <tr>
                        <th style={{ width: "30%" }}>Date of Signing</th>
                        <th>Document Category</th>
                        <th>Particulars of document</th>
                        <th></th>
                        <th></th>
                        </tr>
                    </thead>

                <FieldArray name="dsc_signature_details_attributes">
                    {({ insert, remove, push }) => (
                    <tbody>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <td sm={5}><Button onClick={()=>{
                                push({
                                    date_of_signing: '',
                                    document_category_id: '',
                                    particulars_of_document: ''
                                } )
                                }}>Add</Button>
                            </td>
                        </tr>

                        {values.dsc_signature_details_attributes.length > 0 &&
                        values.dsc_signature_details_attributes.map((details, index) => (
                            <tr style={{ marginTop: '2%',alignItems: 'center'}}>
                                <td sm={5}>
                                    <Field
                                        name={`dsc_signature_details_attributes.${index}.date_of_signing`}
                                        type="date"
                                    style={{width: '100%', padding: '5%'}}
                                    validate={validateSelection}
                                    max={signingDateValidation(values.expiry_date)}
                                />
                                        <div  style={{ marginTop: '2%',alignItems: 'center'}}>{getIn(errors,`dsc_signature_details_attributes.${index}.date_of_signing`)? "Required to proceed": ''}</div>
                                </td>
                                <td sm={5} >
                                <Field
                                    name={`dsc_signature_details_attributes.${index}.document_category_id`}
                                    options={document_categories}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    component={Select}
                                    validate={validateSelection}
                                    onChange={(event)=> {setFieldValue(`dsc_signature_details_attributes.${index}.document_category_id`,event.id)}}
                                    getOptionLabel={(option)=>option.name}
                                getOptionValue={(option)=>option.id}
                                validate={validateSelection}
                                styles={selectStyles}
                            />
                                    <div  style={{ marginTop: '2%',alignItems: 'center'}}>{getIn(errors,`dsc_signature_details_attributes.${index}.document_category_id`)? "Required to proceed": ''}</div>


                                 </td>
                                <td sm={5}>
                                    <Field
                                        name={`dsc_signature_details_attributes.${index}.particulars_of_document`}
                                        type="text"
                                    style={{width: '100%', height: '5%'}}
                                    validate={validateSelection}
                                    validate={validateSelection}
                                />
                                        <div  style={{ marginTop: '2%',alignItems: 'center'}}>{getIn(errors,`dsc_signature_details_attributes.${index}.particulars_of_document`)? "Required to proceed": ''}</div>

                                </td>
                                <td sm={5}>
                                    <Button onClick={() =>remove(index) }><ClearIcon/></Button>
                                </td>


                            </tr>
                            ))}

                        </tbody>

                        )}

                </FieldArray>
            </Table>
                <Button type="submit" style={{marginLeft: '89%'}} variant="contained" color="primary">Save</Button>
                </Form>
                )}
            </Formik>

            <AlertComponent title="Saving" description = {message} saveText ={"OK"} open={showDetails} proceed={()=> setShowDetails(false)}/>
        </Container>
                )
            }


