import React from 'react';
import {  Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '1%'
    },
    footerText:{
        marginTop: '2%',
        fontSize: 8,
        fontFamily: "'Open Sans',light"
    },
})

export default ()=>(
    <View style={styles.container}>
        <Text style={styles.footerText}>We thank you for choosing us</Text>
        <Text style={styles.footerText}>Authorised Signatory</Text>
    </View>
)

