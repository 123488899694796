
import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field,change,getFormValues } from "redux-form";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import MultiSelectComponent from "../../utils/multiselect"
import {InputText,TextArea,SelectInput,validate,SavingDetailsModal,RadioButtonGroup, AlertComponent} from "../../utils/formComponents";
import moment from 'moment';
class ApplyForLeave extends Component{

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        applyLeaveLoading:false,
        multipleDayleave: "false",
        applyLeavedFailed: true,
        showConfirmationAlert: false,
        formData:{}
    };
    this.shouldHide = this.shouldHide.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.showConfirmation = this.showConfirmation.bind(this);
}

    componentDidUpdate(){
        if(typeof(this.props.formValues) != 'undefined'){
            if( this.state.multipleDayleave !== this.props.formValues["multiple_days"] && this.props.formValues["multiple_days"] !== undefined){
                this.setState({
                    multipleDayleave:this.props.formValues["multiple_days"]
                })
            }
        }
        if(!this.state.applyLeavedFailed){
            this.props.history.goBack();
        }
    }
    componentDidMount(){
       this.props.fetchLeaveCategory(this.props.token)
    }
    closeModal(){
        this.setState({
            applyLeaveLoading:this.props.applyLeaveLoading,
            applyLeavedFailed:this.props.applyLeavedFailed
        })
    }
    componentWillMount(){
        this.props.dispatch(change("ApplyForLeave","multiple_days","false"))
        this.props.dispatch(change("ApplyForLeave","from_date_leave_type",0))
        this.props.dispatch(change("ApplyForLeave","to_date_leave_type",0))
    }
shouldHide(){
    if(this.state.multipleDayleave === "false"){
        return {display:'none'}
    }else{
        return null
    }
}
showConfirmation(data){
    this.setState({
        showConfirmationAlert: true,
        formData: data
    })
}
async onsubmit() {
    this.setState({
        showConfirmationAlert: false
    })
    let formData = {...this.state.formData}
    this.setState({
        applyLeaveLoading:true
    })
    if(formData['multiple_days'] === "false"){
        delete formData['to_date']
        delete formData['to_date_leave_type']
    }
    await this.props.applyForLeave(formData,this.props.token);
    this.setState({message:this.props.message})
}
  render () {
    let state = this.state;
    const { handleSubmit,classes } = this.props;
    return (
      <Container>
        <Header title="apply_for_leave" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
       Apply For Leave
      </Typography>
        <form onSubmit={handleSubmit(this.showConfirmation)}>
            <Row style={{marginTop: '50px'}}>
                <Col>
                    <span>Do you need leaves for multiple days?</span>
                </Col>
                <Col>
                    <Field
                        name="multiple_days"
                        label=""
                        component={RadioButtonGroup}
                        fullWidth
                    />
                </Col>
                <Col sm={4}>
                <span>Select Leave Category</span>
                <Field
                    name="category_of_leave_id"
                    label=" "
                    component={SelectInput}
                    fullWidth
                    data = {this.props.leaveCategories}
                />
                </Col>
            </Row>
          <Row>
            <Col sm={8}>

                {(this.props.formValues && this.props.formValues['multiple_days'] == "true")
                    ? <span>From Date</span>
                    : <span>Leave Date</span>}
              <Field
                name="from_date"
                label=""
                type="date"
                component={InputText}
                fullWidth
             />
            </Col>
            <Col sm={4}>
            <span>Select Leave Type</span>
            <Field
                name="from_date_leave_type"
                label=" "
                component={SelectInput}
            fullWidth
            data={(this.props.formValues && this.props.formValues['multiple_days'] == "false")? [{id: 0, name: "FullDay"},{id:1, name: "FirstHalf"}, {id:2, name: "SecondHalf"}]: [{id: 0, name: "FullDay"}, {id:2, name: "SecondHalf"}] }
             />
            </Col>
            </Row>
            <div style={this.shouldHide()}>
            <Row>
            <Col sm={8}>
              <span>To Date</span>
              <Field
                    name="to_date"
                    label=""
                    component={InputText}
                    type="date"
                    fullWidth
                    min={moment(this.props.formValues ? this.props.formValues['from_date'] : '').add(1, 'days').format("YYYY-MM-DD")}
              />
            </Col>
            <Col sm={4}>
            <span>Select Leave Type</span>
             <Field
                name="to_date_leave_type"
                label=" "
                component={SelectInput}
                fullWidth
             />
            </Col>
            </Row></div>
            <Row>
            <Col sm={8}>

              <span>Reason </span>

              <Field
                        name="reason"
                        label="Reason "
                        component={InputText}
                        type="text"
                        fullWidth
              />
            </Col>
            </Row>
        <Row>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.applyLeaveLoading}
             title={"Saving Leave Application"}
             loading={this.props.applyLeaveLoading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>
          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Save</Button>

                </Col>
              </Row>
            </Col>
        </Row>
        <AlertComponent title="Apply for leave" description = {"Attendence marked for the days selected will be lost once approved!Are you sure you want to continue?"} saveText ={"Yes"} open={this.state.showConfirmationAlert} proceed={()=>{ this.onsubmit()}} cancelText="No" handleClose={()=> this.setState({showConfirmationAlert:false})}/>
        </form>
      </Container>
    )
  }
}

function mapStateToProps(state) {
    return {
        token:state.auth.token,
        message:state.leave.applyLeaveMessage,
        leaveCategories: state.leave.leaveCategories,
        formValues: getFormValues('ApplyForLeave')(state),
        applyLeaveLoading:state.leave.applyLeaveLoading,
        applyLeavedFailed:state.leave.applyLeavedFailed
    }
}
export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "ApplyForLeave",validate }),
    withStyles(styles)
)(ApplyForLeave);
