import axios from "../../axiosConfig";
import { useQuery } from "react-query";

async function getPendingAttendenceDetails(){
    try{
        const res = await axios.get(`/profile/get_my_pending`);
        return res.data;
    }catch(err){
        throw err.response.data;
    }
}
export const usePendingAttendence = () => useQuery('pending_attendence', getPendingAttendenceDetails,{
    refetchOnWindowFocus: false,
})
