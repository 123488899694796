import {CLIENT_CLASS_FETCH_SUCCESS,CLIENT_CLASS_FETCH_FAILED,START_CLIENT_CLASS_FETCH,
    START_CLIENT_CLASS_ADD,CLIENT_CLASS_ADD_SUCCESS,CLIENT_CLASS_ADD_FAILURE
} from "../actions/types";
const DEFAULT_STATE ={
    dclientclassList:[],
    clientclassList:[],
    clientclass:[],
    clientClassAddLoading:false,
    clientClassAddFailed:true,
    clientClassAddMessage:'',
    loading: false,
    errorMessage:''
}

export default (state =DEFAULT_STATE,action)=>{
    switch(action.type){

        case START_CLIENT_CLASS_ADD:
            return{
                ...state,
                clientClassAddLoading:true,
                clientClassAddFailed:true,
                clientClassAddMessage:''
            }

        case CLIENT_CLASS_ADD_SUCCESS:
            return{
                ...state,
                clientClassAddLoading:false,
                clientClassAddFailed:false,
                clientClassAddMessage:"Client class sucessfully added!!!"
            }
        case CLIENT_CLASS_ADD_FAILURE:
            return{
                ...state,
                clientClassAddLoading:false,
                clientClassAddFailed:true,
                clientClassAddMessage:"Sorry Couldnt add client class"
            }
        case START_CLIENT_CLASS_FETCH:
            return{
                ...state,
                loading:true
            }

        case CLIENT_CLASS_FETCH_SUCCESS:
            return{
                ...state,
                loading:false,
                dclientclassList:action.payload
            }
        case CLIENT_CLASS_FETCH_FAILED:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        default:
            return state
    }
}
