import React, { Component, View } from "react";
import { Row, Col, Container, Badge, Table } from "react-bootstrap";
import {usePendingAttendence} from '../../hooks/pending-query'
import Header from "../../components/Layout/Header/Header";
const PendingAttendence =() => {
        const { data, isLoading } = usePendingAttendence()
	//https://stackoverflow.com/a/38242552/5706413
	const convertMinsToHrsMins = (mins) => {
	  let h = Math.floor(mins / 60);
	  let m = mins % 60;
	  h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
	  m = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
	  return `${h}:${m}`;
	}

	return(
           <Container> 
        	<Header title="pending_attendence" />
                {isLoading ?
                <p>Loading..</p>
                : <>
	<Row><Col>
            <Row>
                <h3>
                  <Badge variant="dark">Pending Attendence</Badge>
                </h3>
	    </Row>
		<Row>
              <Table responsive striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {data.pending_attendence &&
                 data.pending_attendence.length
                    ? data.pending_attendence.map((item, key) => (
                        <tr>
                          <td>{item.date}</td>
                          <td>{item.pending_type}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Row></Col>
			
		<Col
            style={{
              marginLeft: 20,
            }}>
		
            <Row>
                <h3>
                  <Badge variant="dark">Pending Timesheet</Badge>
                </h3>
	    </Row>
		<Row>
              <Table responsive striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Pending time (HH:MM)</th>
                  </tr>
                </thead>
                <tbody>
                  {data.pending_timesheet &&
                 data.pending_timesheet.length
                    ? data.pending_timesheet.map((item, key) => (
                        <tr>
                          <td>{item.date}</td>
                          <td>{convertMinsToHrsMins(item.pending_time)}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Row>
		</Col></Row></>}</Container>)
}
export default PendingAttendence;
