import React, { useEffect, useState, useReducer, useCallback } from "react";
import { Row, Col, Container, Badge, Modal, Button } from "react-bootstrap";
import Header from "../../components/Layout/Header/Header";
import Select from "react-select";
import { usePendingUdin } from "../../hooks/udin-query";
import CoreUITable from "../../components/Layout/Table";
import { useHistory } from "react-router-dom";
const options = [
    {
        value: "doc",
        label: "No. of UDIN records where Document upload is pending",
    },
    {
        value: "udin",
        label: "No. of UDIN records where UDIN No. updation is pending",
    },
];

function ViewPending(props) {

    const history = useHistory();
    const tableOptions = [
        {
            text: "Link Udin",
            action: function (id) {
                history.push({
                    pathname: "/addcertificateregister",
                    data: { id: id, type: "update" },
                });
            },
            params: true,
        },
        {
            text: "View Doc",
            action: function (id) {
                history.push({
                    pathname: "/viewdoc",
                    id: id,
                });
            },
            params: true,
        },
    ];
    return (
        <>
            {props.isLoading && <h4>{props.message}</h4>}
            {props.data && props.data.length == 0 && (
                <h4>Sorry, no pending records found</h4>
            )}
            {props.data && props.data.length > 0 && (
                <>
                    <CoreUITable
                        page="pending_udin"
                        title={props.title}
                        data={props.data}
                        options={tableOptions}
                        fields={[
                            "internal_reference",
                            "project",
                            "client",
                            "udin",
                            "date_of_document",
                            "udin_status",
                            {
                                key: "show_details",
                                label: "",
                                _style: { width: "1%" },
                                filter: false,
                            },
                        ]}
                    />
                </>
            )}

        </>
    );
}

export default function UdinPending(props) {
    const { isLoading, mutate, data, error } = usePendingUdin();
    const [message, setMessage] = React.useState("");
    const [modalShow, setModalShow] = React.useState(false);
    const [key, setKey] = React.useState("");
    const [title, setTitle] = React.useState("");
    const handleChange = (event) => {
        //name for react-select field name
        if (modalShow) setModalShow(false);
        console.log("value", event);
        if (event) {
            setKey(event.value);
            setTitle(event.label);
        }
    };

    React.useEffect(() => {
        if (isLoading) setMessage("Loading ");
        if (error) setMessage(error);
        console.log("test", isLoading, error, data);
    }, [isLoading, data, error]);
    React.useEffect(() => {
        if (key !== "") {
            mutate({ key });
            setModalShow(true);
        }
    }, [key]);

    return (
        <Container>
            <Header title="pending_udin" />
            <h1 style={{ marginLeft: "20%" }}>
                {" "}
                <Badge variant="dark">Pending Items</Badge>{" "}
            </h1>
            <Row style={{ marginTop: "5%" }}></Row>
            <Row style={{ alignItems: "center", marginTop: "5%" }}>
                <Col sm={4}>
                    <span>Select</span>
                </Col>
                <Col sm={4}>
                    <Select
                        options={options}
                        name="report_type"
                        onChange={(e) => handleChange(e)}
                    />
                </Col>
            </Row>
            {modalShow && (
                <ViewPending
                    data={data}
                    isLoading={isLoading}
                    message={message}
                    onHide={() => setModalShow(false)}
                    title={title}
                />
            )}
        </Container>
    );
}
