import { NEW_REGISTER_ADDED_SUCCESSFULLY,FAILED_ADDING_NEW_REGISTER,START_ADDING_NEW_REGISTER,
START_FETCHING_ISSUE_REGISTERS_LIST,FETCHING_ISSUE_REGISTERS_LIST_DONE,FETCHING_ISSUE_REGISTERS_LIST_FAILED} from '../actions/types';

const DEFAULT_STATE = {
    issueRegisterLoading: false,
    issueRegisterMessage: "",
    issueRegisterFailed: true,
    issueRegisterList:[],
    issueRegisterFetchMessage:'',
    issueRegisterFetchListLoading: false
}
export default (state = DEFAULT_STATE,action) => {
    switch(action.type){
        case START_ADDING_NEW_REGISTER:
            return{
                ...state,
                issueRegisterLoading: true,
                issueRegisterFailed:false
            }
        case FAILED_ADDING_NEW_REGISTER:
            return {
                ...state,
                issueRegisterLoading: false,
                issueRegisterFailed: true,
                issueRegisterMessage: action.payload
            }
        case NEW_REGISTER_ADDED_SUCCESSFULLY:
            return {
                ...state,
                issueRegisterLoading: false,
                issueRegisterFailed: false,
                issueRegisterMessage: action.payload
            }
        case START_FETCHING_ISSUE_REGISTERS_LIST:
            return {
                ...state,
                issueRegisterFetchListLoading:true,
                issueRegisterFetchMessage:''
            }
        case FETCHING_ISSUE_REGISTERS_LIST_DONE:
            return {
                ...state,
                issueRegisterFetchListLoading:false,
                issueRegisterList:action.payload
            }
        case FETCHING_ISSUE_REGISTERS_LIST_FAILED:
            return {
                ...state,
                issueRegisterFetchListLoading:false,
                issueRegisterFetchMessage:action.payload
            }
        default:
            return state
    }
}
