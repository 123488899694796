import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";

const BreadCrumbComponent = (props) => {
    const {
        history,
        location: { pathname },
    } = props;
    const pathnames = pathname.split("/").filter((x) => x);
    return (
        <Breadcrumbs style={{ margin: "20px 30px" }}>
            <Link
                onClick={() => history.push("/")}
                style={{ fontSize: "18px", cursor: "pointer" }}
            >
                Home
            </Link>
            {pathnames.map((name, index) => {
                const routeTo = `${pathnames.slice(0, index + 1).join("/")}`;
                return index == pathnames.length - 1 ? (
                    <Link
                        onClick={() => history.push(routeTo)}
                        style={{ fontSize: "18px", curosr: "pointer" }}
                    >
                        {name}
                    </Link>
                ) : (
                    <Typography style={{ fontSize: "18px" }}>{name}</Typography>
                );
            })}
        </Breadcrumbs>
    );
};

export default withRouter(BreadCrumbComponent);
