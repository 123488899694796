import {
    START_FETCH_SETTING,
    FETCHING_SETTING_FAILED,
    FETCHING_SETTING_SUCCESS,
    START_UPDATE_SETTING,
    UPDATE_SETTING_SUCCESS,
    UPDATE_SETTING_FAILED,
    START_FETCH_SETTING_LIST,
    FETCH_SETTING_LIST_FAILED,
    FETCH_SETTING_LIST_SUCCESS,
    START_ADD_SETTING,
    ADD_SETTING_SUCCESS,
    ADD_SETTING_FAILED,
} from "./types";
import axios from "axios";
import { BaseUrl } from "./api";

export const fetchSetting = (key, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_FETCH_SETTING,
        });
        try {
            const res = await axios.get(`${BaseUrl}/settings/${key}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: FETCHING_SETTING_SUCCESS,
                    payload: res.data["setting"],
                });
            } else {
                dispatch({
                    type: FETCHING_SETTING_FAILED,
                    payload: res.data["message"],
                });
            }
        } catch (error) {
            dispatch({
                type: FETCHING_SETTING_FAILED,
                payload: error.message,
            });
        }
    };
};
export const fetchSettingList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_FETCH_SETTING_LIST,
        });
        try {
            const res = await axios.get(`${BaseUrl}/settings`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: FETCH_SETTING_LIST_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: FETCH_SETTING_LIST_FAILED,
                    payload: "Fetching user failed",
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_SETTING_LIST_FAILED,
                payload: error.message,
            });
        }
    };
};

export const updateSetting = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_UPDATE_SETTING,
        });
        try {
            const res = await axios.put(
                `${BaseUrl}/settings/${data["key"]}`,
                { setting: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: UPDATE_SETTING_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: UPDATE_SETTING_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: UPDATE_SETTING_FAILED,
                payload: error.message,
            });
        }
    };
};

export const addSetting = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_ADD_SETTING,
        });
        try {
            const res = await axios.post(`${BaseUrl}/settings`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.data.status) {
                dispatch({
                    type: ADD_SETTING_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: ADD_SETTING_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: ADD_SETTING_FAILED,
                payload: error.message,
            });
        }
    };
};
