//@format
import React, { Component, useState, useCallback, useEffect } from "react";
import { Row, Col, Container, Badge, Modal, Table } from "react-bootstrap";
import {
    reduxForm,
    Field,
    change,
    initialize,
    FieldArray,
    formValueSelector,
} from "redux-form";
import { compose } from "redux";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import keys from "lodash/keys";
import pickBy from "lodash/pickBy";
import get from "lodash/get";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { connect, useDispatch, useSelector } from "react-redux";
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import {
    addBackToAvailableProject,
    deleteFromAvailableProject,
} from "../../actions/projectsAction";
import {
    addBackToAvailableOpe,
    deleteFromAvailableOpe,
} from "../../actions/opeAction";
import { fetchSacMasterByCode } from "../../actions/sacmasterAction";
import { checkIfProjectIsRecurring } from "../../actions/invoiceAction";
import styles from "../modelstyles";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import {
    InputText,
    SelectInput,
    TextArea,
    validate,
    Item,
    SavingDetailsModal,
    findByMatchingProperties,
    SnackbarAlert,
    AlertComponent,
    RenderSwitch,
} from "../../utils/formComponents";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import MultiSelectComponent from "../../utils/multiselect";
import DraftView from "./ViewInvoice";
const number = (value) =>
    value && isNaN(Number(value)) ? "Must be a number" : undefined;
const required = (value) => (value !== undefined ? undefined : "Required");
const selectStyles = {
    menu: (base) => ({
        ...base,
        zIndex: 100,
    }),
};

function InvoiceOpeDetailsAttribues(props) {
    const { invoiceOpeAvailable, index, fields, details } = props;
    const state = useSelector((state) => state);
    const ope_id = selector(state, `${props.details}.ope_id`);
    const billed_amount = selector(state, `${props.details}.billed_amount`);
    const dispatch = useDispatch();

    useEffect(() => {
        if (ope_id) {
            dispatch(
                change(
                    "AddInvoice",
                    `${props.details}.actual_amount`,
                    ope_id.ope_amount
                )
            );
            if (!billed_amount)
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.details}.billed_amount`,
                        ope_id.ope_amount
                    )
                );
            dispatch(deleteFromAvailableOpe(ope_id));
        }
    }, [ope_id]);

    const clearField = () => {
        if (ope_id) dispatch(addBackToAvailableOpe(ope_id));
        fields.remove(index);
    };

    return (
        <tr key={index}>
            <td>
                <Field
                    name={`${details}.ope_id`}
                    label=""
                    props={{
                        type: "object",
                    }}
                    component={MultiSelectComponent}
                    {...{
                        data: invoiceOpeAvailable,
                        isMulti: false,
                    }}
                    fullWidth
                    validate={[required]}
                    isDisabled={ope_id ? true : false}
                />
            </td>

            <td>
                <Field
                    name={`${details}.actual_amount`}
                    component={InputText}
                    type="text"
                    disabled={true}
                    fullWidth
                />
            </td>

            <td>
                <Field
                    name={`${details}.billed_amount`}
                    component={InputText}
                    type="number"
                    fullWidth
                    validate={[required, number]}
                />
            </td>
            <td>
                <Button onClick={() => clearField()}>
                    <ClearIcon />
                </Button>
            </td>
        </tr>
    );
}
const renderOpeDetailsField = (props) => {
    const {
        fields,
        meta: { touched, error },
        validationMessage,
        validatePreviousRow,
        clearValidationAndNewRow,
        totalActual,
        totalBilled,
    } = props;
    return (
        <tbody>
            {fields.map((details, index) => (
                <InvoiceOpeDetailsAttribues
                    details={details}
                    fields={fields}
                    index={index}
                    key={index}
                    invoiceOpeAvailable={props.invoiceOpeAvailable}
                />
            ))}
            <tr>
                <td>Total</td>
                <td>{totalActual}</td>
                <td>{totalBilled}</td>
                <td></td>
            </tr>
            {validationMessage !== "" && <tr>{validationMessage}</tr>}
            <tr>
                <Button
                    onClick={() =>
                        validatePreviousRow(fields)
                            ? clearValidationAndNewRow(fields)
                            : null
                    }
                    color="primary"
                    variant="contained"
                >
                    <AddIcon />
                </Button>
                {touched && error && <span>{error}</span>}
            </tr>
        </tbody>
    );
};
function InvoiceOpeDetails(props) {
    const [validationMessage, setValidationMessage] = useState("");
    const [currentIndex, setCurrentIndex] = useState(undefined);
    const [totalBilled, setTotalBilled] = useState(0);
    const [totalActual, setTotalActual] = useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
        if (validationMessage !== "") {
            let timer = setTimeout(() => setValidationMessage(""), 5000);
            return () => {
                clearTimeout(timer);
            };
        } //https://stackoverflow.com/a/53090848/5706413
    }, [validationMessage]);
    useEffect(() => {
        let first_num = props.details.match(/\[(-?\d+)\]/)[1]; //https://stackoverflow.com/a/53573061/5706413
        setCurrentIndex(first_num);
    }, []);
    useEffect(() => {
        let sum = 0;
        let billed_sum = 0;
        let t = get(
            props,
            `invoice_opes_attributes[${currentIndex}][invoice_ope_details_attributes]`,
            undefined
        );
        if (t) {
            props.invoice_opes_attributes[currentIndex][
                "invoice_ope_details_attributes"
            ].map((item, index) => {
                if (item.ope_id) {
                    sum = sum + item.ope_id.ope_amount;
                    if (item.billed_amount)
                        billed_sum = billed_sum + Number(item.billed_amount);
                }
            });
        }
        setTotalBilled(Math.round(billed_sum));
        setTotalActual(Math.round(sum));
        dispatch(
            change(
                "AddInvoice",
                `${props.details}.amount`,
                Math.round(billed_sum)
            )
        );
    }, [
        JSON.stringify(
            get(
                props,
                `invoice_opes_attributes[${currentIndex}][invoice_ope_details_attributes]`,
                undefined
            )
        ),
    ]);

    const validatePreviousRow = (data) => {
        let first_num = data.name.match(/\[(-?\d+)\]/)[1]; //https://stackoverflow.com/a/53573061/5706413
        setCurrentIndex(first_num);
        if (data.length > 0) {
            let lastItem =
                props.invoice_opes_attributes[first_num][
                    "invoice_ope_details_attributes"
                ][data.length - 1];
            let missing = "";
            const keys = ["ope_id", "billed_amount"];
            const hasAllKeys = keys.every((item) =>
                lastItem.hasOwnProperty(item)
            );
            if (!hasAllKeys) {
                setValidationMessage("Please fill all fields to proceed!!");
                return false;
            } else {
                //if props.length =1 then filter project based on sac_master of
                //0 th project
                return true;
            }
        } else {
            return true;
        }
    };

    const clearValidationAndNewRow = (data) => {
        setValidationMessage("");
        data.push({});
    };

    return (
        <Modal
            show={props.show}
            onHide={() => props.onCloseDetails()}
            size="lg"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Select Opes</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Table responsive style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th style={{ width: "30%" }}>Ope</th>
                            <th>Actual</th>
                            <th>Billed</th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        <FieldArray
                            name={`${props.details}.invoice_ope_details_attributes`}
                            component={renderOpeDetailsField}
                            invoiceOpeAvailable={props.invoiceOpeAvailable}
                            validationMessage={validationMessage}
                            validatePreviousRow={(data) =>
                                validatePreviousRow(data)
                            }
                            clearValidationAndNewRow={(data) =>
                                clearValidationAndNewRow(data)
                            }
                            totalActual={totalActual}
                            totalBilled={totalBilled}
                        />
                    }
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => props.onCloseDetails()}
                >
                    Done
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
function InvoiceOpe(props) {
    const [showDetails, setShowDetails] = useState(false);
    const { ope, index, fields, invoiceOpeAvailable } = props;
    const onCloseDetails = () => {
        setShowDetails(false);
    };
    const removeRow = () => {
        let t1 = get(
            props,
            `invoice_opes_attributes[${props.index}][invoice_ope_details_attributes][0][ope_id][id]`,
            undefined
        );
        if (t1) {
            props.setRowValidationMessage(
                "Sorry, to delete this row please delete value inside it"
            );
            setTimeout(function () {
                props.setRowValidationMessage("");
            }, 5000);
            // setTimeout(() => this.setState({message:''}), 9000);
        } else {
            props.fields.remove(props.index);
        }
    };
    return (
        <>
            <tr key={index}>
                <td>
                    <Field
                        name={`${ope}.particulars`}
                        label="Particulars*"
                        component={InputText}
                        type="text"
                        fullWidth
                        validate={required}
                    />
                </td>
                <td>
                    <Button onClick={() => setShowDetails(true)}>Select</Button>
                </td>
                <td>
                    <Field
                        name={`${ope}.amount`}
                        component={InputText}
                        type="number"
                        validate={required}
                        disabled={true}
                    />
                </td>
                <td>
                    <Button onClick={() => removeRow()}>
                        <ClearIcon />
                    </Button>
                </td>
            </tr>
            <InvoiceOpeDetails
                show={showDetails}
                details={ope}
                onCloseDetails={() => onCloseDetails()}
                invoiceOpeAvailable={invoiceOpeAvailable}
                invoice_opes_attributes={props.invoice_opes_attributes}
            />
        </>
    );
}

function BasicDetailsAttribues(props) {
    const [showError, setShowError] = useState(false);
    const [opeNames, setOpeName] = useState("");
    const state = useSelector((state) => state);
    const project_id = selector(state, `${props.details}.project_id`);
    console.log("project id check inside basic details attributes", project_id);
    const actual_amount = selector(state, `${props.details}.actual_amount`);
    const dispatch = useDispatch();
    const { available_projects, index, fields, details } = props;
    useEffect(() => {
        if (project_id) {
            dispatch(deleteFromAvailableProject(project_id.id)); //newrequirement changed function to just chg color to green
            dispatch(
                change(
                    "AddInvoice",
                    `${props.details}.budgeted_amount`,
                    project_id["budgeted_bill_amount"]
                )
            );
            if (!actual_amount)
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.details}.actual_amount`,
                        project_id["budgeted_bill_amount"]
                    )
                );
            dispatch(
                checkIfProjectIsRecurring(project_id.id, props.token)
            ).then((resp) => {
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.details}.previous_actual_amount`,
                        resp
                    )
                );
            });
        }
    }, [project_id]);
    useEffect(() => {
        dispatch(change("AddInvoice", `${props.details}.invoice_type`, 1));
    }, []);
    const clearField = () => {
        let t = get(props, "invoice_opes_attributes", undefined);
        let ope_name = "";
        if (t) {
            //attempting to temove related opes if already added
            props.invoice_opes_attributes.forEach((item) => {
                let indexes = map(
                    keys(
                        pickBy(
                            item["invoice_ope_details_attributes"],
                            (path) => {
                                return (
                                    path &&
                                    path.ope_id &&
                                    project_id &&
                                    path.ope_id.project_id === project_id.id
                                );
                            }
                        )
                    ),
                    Number
                );
                indexes.forEach((index) => {
                    ope_name =
                        ope_name +
                        ", " +
                        item["invoice_ope_details_attributes"][index]["ope_id"][
                            "name"
                        ];
                });
            });
        }
        if (ope_name !== "") {
            setShowError(true);
            setOpeName(ope_name);
        } else {
            okRemove();
        }
    };
    const okRemove = () => {
        if (project_id) dispatch(addBackToAvailableProject(project_id));
        props.fields.remove(props.index);
    };
    return (
        <tr key={index}>
            <td>
                <Field
                    name={`${details}.project_id`}
                    label=""
                    props={{
                        type: "object",
                    }}
                    component={MultiSelectComponent}
                    {...{
                        data: available_projects,
                        isMulti: false,
                    }}
                    fullWidth
                    validate={[required]}
                    isDisabled={project_id ? true : false}
                />
            </td>
            <td>
                <Field
                    name={`${details}.invoice_type`}
                    component={SelectInput}
                    fullWidth
                    validate={[required, number]}
                />
            </td>
            <td>
                <Field
                    name={`${details}.budgeted_amount`}
                    component={InputText}
                    type="text"
                    disabled={true}
                    fullWidth
                />
            </td>
            <td>
                <Field
                    name={`${details}.previous_actual_amount`}
                    component={InputText}
                    type="text"
                    disabled={true}
                    fullWidth
                />
            </td>
            <td>
                <Field
                    name={`${details}.actual_amount`}
                    component={InputText}
                    type="number"
                    fullWidth
                    validate={[required, number]}
                />
            </td>
            <td>
                <Button onClick={() => clearField()}>
                    <ClearIcon />
                </Button>
            </td>
            <AlertComponent
                title="Delete slected row?"
                description={`Are you sure you want to delete this row? It seems you have selected ${opeNames}  related to this project`}
                open={showError}
                cancelText="No, dont proceed"
                saveText="Yes Proceed"
                handleClose={() => {
                    setShowError(false);
                    setOpeName("");
                }}
                proceed={() => okRemove()}
            />
        </tr>
    );
}

const renderBasicDetailsField = (props) => {
    const {
        validationMessage,
        validatePreviousRow,
        clearValidationAndNewRow,
        fields,
        meta: { touched, error },
        available_projects,
        invoice_opes_attributes,
        cantDeleteProject,
        total_budgeted,
        total_actual,
        token,
    } = props;
    return (
        <tbody>
            {fields.map((details, index) => (
                <BasicDetailsAttribues
                    details={details}
                    fields={fields}
                    index={index}
                    key={index}
                    available_projects={available_projects}
                    invoice_opes_attributes={invoice_opes_attributes}
                    token={token}
                />
            ))}
            <tr>
                <td>Total</td>
                <td></td>
                <td>{total_budgeted}</td>
                <td></td>
                <td>{total_actual}</td>
                <td></td>
            </tr>
            {validationMessage !== "" && <tr>{validationMessage}</tr>}
            <tr>
                <Button
                    onClick={() =>
                        validatePreviousRow(fields)
                            ? clearValidationAndNewRow(fields)
                            : null
                    }
                    color="primary"
                    variant="contained"
                >
                    <AddIcon />
                </Button>
                {touched && error && <span>{error}</span>}
            </tr>

            <SnackbarAlert
                key="validationMessage"
                open={validationMessage === "" ? false : true}
                message={validationMessage}
            />
        </tbody>
    );
};

function BasicDetails(props) {
    const [validationMessage, setValidationMessage] = useState("");
    const [currentIndex, setCurrentIndex] = useState(undefined);
    const [totalBudgeted, setTotalBudgeted] = useState(0);
    const [totalActual, setTotalActual] = useState(0);
    const [availiableProjects, setAvailiableProjects] = useState([]);
    const [sacCode, setSacCode] = useState(undefined);
    const [projectDeletionMsg, setProjectDeletionMsg] = useState("");
    const state = useSelector((state) => state);
    //const project_id = selector(state, `${props.details}.project_id`)
    const dispatch = useDispatch();

    useEffect(() => {
        let first_num = props.details.match(/\[(-?\d+)\]/)[1]; //https://stackoverflow.com/a/53573061/5706413
        setAvailiableProjects([...props.available_projects]);
        setCurrentIndex(first_num);
    }, []);

    useEffect(() => {
        setAvailiableProjects([...props.available_projects]);
        let t = get(
            props,
            `invoice_basics_attributes[${currentIndex}]['invoice_basic_details_attributes'][0]['project_id']['sac_code']`,
            undefined
        );
        filterBasedOnFirstProjectSac(t);
    }, [JSON.stringify(props.available_projects)]);

    useEffect(() => {
        let t = get(
            props,
            `invoice_basics_attributes[${currentIndex}]['invoice_basic_details_attributes'][0]['project_id']['sac_code']`,
            undefined
        );
        filterBasedOnFirstProjectSac(t);
    }, [
        get(
            props,
            `invoice_basics_attributes[${currentIndex}]['invoice_basic_details_attributes'][0]['project_id']['id']`,
            undefined
        ),
    ]);
    useEffect(() => {
        let t = get(
            props,
            `invoice_basics_attributes[${currentIndex}]['invoice_basic_details_attributes']`,
            undefined
        );
        if (t) getTotal();
    }, [
        JSON.stringify(
            get(
                props,
                `invoice_basics_attributes[${currentIndex}]['invoice_basic_details_attributes']`,
                undefined
            )
        ),
    ]);

    /**componentDidUpdate(prevProps){

        console.log("component did update", prevProps.available_projects, this.props.available_projects)
        if(JSON.stringify(prevProps.available_projects) !== JSON.stringify(this.props.available_projects)){
            this.setState({
                available_projects: [...this.props.available_projects]
            },()=>{
                this.filterBasedOnFirstProjectSac(prevProps)
            })
        }
        if(!this.state.currentIndex) return
        if(JSON.stringify(prevProps.invoice_basics_attributes[this.state.currentIndex]['invoice_basic_details_attributes']) !== JSON.stringify(this.props.invoice_basics_attributes[this.state.currentIndex]['invoice_basic_details_attributes'])){
            this.getTotal()
        }

     }**/
    const filterBasedOnFirstProjectSac = (t) => {
        if (t) {
            setAvailiableProjects(
                [...props.available_projects].filter(
                    (item) => item.sac_code === t
                )
            );
            setValidationMessage(
                `Only projects with same SAC(${t}) will be shown!!`
            );
            setTimeout(function () {
                setValidationMessage("");
            }, 5000);
        }
    };
    const clearValidationAndNewRow = (fields) => {
        setValidationMessage("");
        fields.push({});
    };
    const validatePreviousRow = (data) => {
        let first_num = data.name.match(/\[(-?\d+)\]/)[1]; //https://stackoverflow.com/a/53573061/5706413
        setCurrentIndex(first_num);
        if (data.length > 0) {
            let lastItem =
                props.invoice_basics_attributes[first_num][
                    "invoice_basic_details_attributes"
                ][data.length - 1];
            let missing = "";
            const keys = ["project_id", "invoice_type", "actual_amount"];
            const hasAllKeys = keys.every((item) =>
                lastItem.hasOwnProperty(item)
            );
            if (!hasAllKeys) {
                setValidationMessage("Please fill all fields to proceed!!");
                setTimeout(function () {
                    setValidationMessage("");
                }, 5000); // wait 5 seconds, then reset to false
                return false;
            } else {
                //if props.length =1 then filter project based on sac_master of
                //0 th project
                return true;
            }
        } else {
            return true;
        }
    };

    const getTotal = () => {
        let sum = 0;
        let actual_sum = 0;
        props.invoice_basics_attributes[currentIndex][
            "invoice_basic_details_attributes"
        ].map((item, index) => {
            if (item.project_id) {
                sum = sum + item.project_id.budgeted_bill_amount;
                if (item.actual_amount)
                    actual_sum = actual_sum + Number(item.actual_amount);
            }
        });
        setTotalBudgeted(Math.round(sum));
        setTotalActual(Math.round(actual_sum));
        dispatch(
            change(
                "AddInvoice",
                `${props.details}.base_amount`,
                Math.round(actual_sum)
            )
        );
    };
    /**
filterBasedOnFirstProjectSac(prevProps){
        console.log("current index",this.state.currentIndex, this.props.details)
        let t1 = get(this.props, `invoice_basics_attributes[${this.state.currentIndex}]['invoice_basic_details_attributes'][0]['project_id']['id']`, undefined)
        let t2 = get(prevProps, `invoice_basics_attributes[${this.state.currentIndex}]['invoice_basic_details_attributes'][0]['project_id']['id']`, undefined)
        console.log("t t2",t1,t2, prevProps,this.state.available_projects, this.props.available_projects)
        if(!t1) return
        this.setState({available_projects: this.state.available_projects.filter(item=> item.sac_code ===this.props.invoice_basics_attributes[this.state.currentIndex]['invoice_basic_details_attributes'][0]['project_id']['sac_code'])})
        if( this.props.invoice_basics_attributes[this.state.currentIndex]['invoice_basic_details_attributes'][0]['project_id']['id']!== t2 ){
            this.setState({
                validationMessage: `Only projects with same SAC(${this.props.invoice_basics_attributes[this.state.currentIndex]['invoice_basic_details_attributes'][0]['project_id']['sac_code']}) will be shown!!`
            })
            setTimeout(function(){
                this.setState({validationMessage: ''});
            }.bind(this),5000);
        }
    }**/
    const getSacCode = () => {
        let t1 = get(
            props,
            `invoice_basics_attributes[${currentIndex}]['invoice_basic_details_attributes'][0]['project_id']['sac_code']`,
            undefined
        );
        return t1;
    };
    /**componentDidMount(){
        let first_num = this.props.details.match(/\[(-?\d+)\]/)[1] //https://stackoverflow.com/a/53573061/5706413
        this.setState({
            available_projects: [...this.props.available_projects],
            currentIndex: first_num
        },()=>{
            this.filterBasedOnFirstProjectSac(undefined)
        })    }
componentDidUpdate(prevProps){

        console.log("component did update", prevProps.available_projects, this.props.available_projects)
        if(JSON.stringify(prevProps.available_projects) !== JSON.stringify(this.props.available_projects)){
            this.setState({
                available_projects: [...this.props.available_projects]
            },()=>{
                this.filterBasedOnFirstProjectSac(prevProps)
            })
        }
        if(!this.state.currentIndex) return
        if(JSON.stringify(prevProps.invoice_basics_attributes[this.state.currentIndex]['invoice_basic_details_attributes']) !== JSON.stringify(this.props.invoice_basics_attributes[this.state.currentIndex]['invoice_basic_details_attributes'])){
            this.getTotal()
        }

     }**/
    const { index, fields, available_projects } = props;
    return (
        <Modal
            show={props.show}
            onHide={() => props.onCloseDetails(getSacCode())}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            fullscreen={true}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Select Projects</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Table responsive style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th style={{ width: "40%" }}>Projects</th>
                            <th style={{ width: "20%" }}>Invoice Type</th>
                            <th style={{ width: "10%" }}>Budgeted</th>
                            <th style={{ width: "10%" }}>Previous Actual</th>
                            <th style={{ width: "20%" }}>Actual</th>
                            <th></th>
                        </tr>
                    </thead>
                    {
                        <FieldArray
                            name={`${props.details}.invoice_basic_details_attributes`}
                            component={renderBasicDetailsField}
                            validationMessage={validationMessage}
                            validatePreviousRow={(data) =>
                                validatePreviousRow(data)
                            }
                            clearValidationAndNewRow={(data) =>
                                clearValidationAndNewRow(data)
                            }
                            available_projects={availiableProjects}
                            invoice_opes_attributes={
                                props.invoice_opes_attributes
                            }
                            total_budgeted={totalBudgeted}
                            total_actual={totalActual}
                            token={props.token}
                        />
                    }
                </Table>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => props.onCloseDetails(getSacCode())}
                >
                    Done
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function BasicAttributes(props) {
    const [showBasicDetails, setShowBasicDetails] = useState(false);
    const [sacMaster, setSacMaster] = useState({});
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    //const project_id = selector(state, `${props.details}.project_id`)
    const amount = selector(state, `${props.basics}.base_amount`);
    const igst = selector(state, `${props.basics}.igst`);
    const particulars = selector(state, `${props.basics}.particulars`);
    const cgst = selector(state, `${props.basics}.cgst`);
    const sgst = selector(state, `${props.basics}.sgst`);
    const central_cess = selector(state, `${props.basics}.rate_central_cess`);
    const state_cess = selector(state, `${props.basics}.rate_state_cess`);
    const rate_general = selector(state, `${props.basics}.rate_general`);
    const total = selector(state, `${props.basics}.total`);
    const sac_code = selector(state, `${props.basics}.sac_code`);
    const ccess = selector(state, `${props.basics}.ccess`);
    const scess = selector(state, `${props.basics}.scess`);

    /**constructor(props){
        super(props)
        this.state={
            show_basic_details: false,
            sac_master: {},
        }
        this.onCloseDetails = this.onCloseDetails.bind(this)
        this.calculateTotal = this.calculateTotal.bind(this)
        this.clearAllFields = this.clearAllFields.bind(this)//except base amount
        this.sumOfAllFields = this.sumOfAllFields.bind(this)
        this.removeRow = this.removeRow.bind(this)

    }**/
    const removeRow = () => {
        let t1 = get(
            props,
            `invoice_data[${props.index}][invoice_basic_details_attributes][0][project_id][id]`,
            undefined
        );
        if (t1) {
            props.setRowValidationMessage(
                "Sorry, to delete this row please delete value inside it"
            );
            setTimeout(function () {
                props.setRowValidationMessage("");
            }, 5000);
            // setTimeout(() => this.setState({message:''}), 9000);
        } else {
            props.fields.remove(props.index);
        }
    };
    const onCloseDetails = (sac_code) => {
        setShowBasicDetails(false);
        if (sac_code) {
            dispatch(fetchSacMasterByCode(props.token, sac_code)).then(
                (resp) => {
                    if (resp) {
                        setSacMaster({ ...resp });
                        setSacValues(
                            resp.sac_code,
                            resp.rate_state_cess,
                            resp.rate_central_cess,
                            resp.rate_general
                        );
                    } else {
                        setSacValues(0, 0, 0, 0);
                    }
                }
            );
        } else {
            setSacValues(0, 0, 0, 0);
        }
    };

    const setSacValues = (
        sac_code,
        rate_state_cess,
        rate_central_cess,
        rate_general
    ) => {
        dispatch(change("AddInvoice", `${props.basics}.sac_code`, sac_code));
        dispatch(
            change(
                "AddInvoice",
                `${props.basics}.rate_state_cess`,
                rate_state_cess
            )
        );
        dispatch(
            change(
                "AddInvoice",
                `${props.basics}.rate_central_cess`,
                rate_central_cess
            )
        );
        dispatch(
            change("AddInvoice", `${props.basics}.rate_general`, rate_general)
        );
    };
    const calculateTotal = () => {
        let t = get(props, "place_of_supply_id", undefined);
        let t2 = sacMaster;
        if (!t || !t2) return;
        console.log("place of supply", props);
        let { name } = props.place_of_supply.find(
            (item) => item.id === props.place_of_supply_id
        );
        if (props.tax_code === 0) {
            if (name === "32 - Kerala") {
                dispatch(change("AddInvoice", `${props.basics}.igst`, 0));
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.cgst`,
                        Math.round(
                            (parseFloat(amount) *
                                (1 / 100) *
                                parseFloat(rate_general)) /
                                2
                        )
                    )
                );
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.sgst`,
                        Math.round(
                            (parseFloat(amount) *
                                (1 / 100) *
                                parseFloat(rate_general)) /
                                2
                        )
                    )
                );
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.ccess`,
                        Math.round(
                            parseFloat(amount) *
                                (1 / 100) *
                                parseFloat(central_cess)
                        )
                    )
                );
                dispatch(change("AddInvoice", `${props.basics}.scess`, 0));
                sumOfAllFields();
            } else {
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.igst`,
                        Math.round(
                            parseFloat(amount) * (1 / 100) * rate_general
                        )
                    )
                );
                dispatch(change("AddInvoice", `${props.basics}.cgst`, 0));
                dispatch(change("AddInvoice", `${props.basics}.sgst`, 0));
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.ccess`,
                        Math.round(
                            parseFloat(amount) * (1 / 100) * central_cess
                        )
                    )
                );
                dispatch(change("AddInvoice", `${props.basics}.scess`, 0));
                sumOfAllFields();
            }
        } else if (props.tax_code === 1) {
            if (name === "32 - Kerala") {
                dispatch(change("AddInvoice", `${props.basics}.igst`, 0));
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.cgst`,
                        Math.round(
                            (parseFloat(amount) * (1 / 100) * rate_general) / 2
                        )
                    )
                );
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.sgst`,
                        Math.round(
                            (parseFloat(amount) * (1 / 100) * rate_general) / 2
                        )
                    )
                );
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.ccess`,
                        Math.round(
                            parseFloat(amount) * (1 / 100) * central_cess
                        )
                    )
                );
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.scess`,
                        Math.round(parseFloat(amount) * (1 / 100) * state_cess)
                    )
                );
                sumOfAllFields();
            } else {
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.igst`,
                        Math.round(
                            parseFloat(amount) * (1 / 100) * rate_general
                        )
                    )
                );
                dispatch(change("AddInvoice", `${props.basics}.cgst`, 0));
                dispatch(change("AddInvoice", `${props.basics}.sgst`, 0));
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.ccess`,
                        Math.round(
                            parseFloat(amount) * (1 / 100) * central_cess
                        )
                    )
                );
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.scess`,
                        Math.round(parseFloat(amount) * (1 / 100) * state_cess)
                    )
                );
                sumOfAllFields();
            }
        } else if (props.tax_code === 2) {
            if (name === "00 - Other Territory") {
                dispatch(
                    change(
                        "AddInvoice",
                        `${props.basics}.igst`,
                        Math.round(
                            parseFloat(amount) * (1 / 100) * rate_general
                        )
                    )
                );
                dispatch(change("AddInvoice", `${props.basics}.cgst`, 0));
                dispatch(change("AddInvoice", `${props.basics}.sgst`, 0));
                dispatch(change("AddInvoice", `${props.basics}.scess`, 0));
                dispatch(change("AddInvoice", `${props.basics}.ccess`, 0));
                sumOfAllFields();
            } else {
                clearAllFields();
                sumOfAllFields();
            }
        } else if (props.tax_code === 3) {
            if (name === "00 - Other Territory") {
                dispatch(change("AddInvoice", `${props.basics}.igst`, 0));
                dispatch(change("AddInvoice", `${props.basics}.cgst`, 0));
                dispatch(change("AddInvoice", `${props.basics}.sgst`, 0));
                dispatch(change("AddInvoice", `${props.basics}.scess`, 0));
                dispatch(change("AddInvoice", `${props.basics}.ccess`, 0));
                sumOfAllFields();
            } else {
                clearAllFields();
                sumOfAllFields();
            }
        } else {
            //without tax
            clearAllFields();
            sumOfAllFields();
            //this.props.dispatch(change("AddInvoice", `${this.props.basics}.total`,this.props.amount))
        }
    };
    const sumOfAllFields = () => {
        let sum = Math.round(amount + igst + cgst + sgst + scess + ccess);
        dispatch(change("AddInvoice", `${props.basics}.total`, sum));
    };
    const clearAllFields = () => {
        dispatch(change("AddInvoice", `${props.basics}.igst`, 0));
        dispatch(change("AddInvoice", `${props.basics}.cgst`, 0));
        dispatch(change("AddInvoice", `${props.basics}.sgst`, 0));
        dispatch(change("AddInvoice", `${props.basics}.scess`, 0));
        dispatch(change("AddInvoice", `${props.basics}.ccess`, 0));
    };
    /**componentDidUpdate(prevProps){
        let t1 = get(props, `invoice_data[${props.index}]['invoice_basic_details_attributes'][0]['project_id']['sac_code']`, undefined)
        if(JSON.stringify(this.props.invoice_data) !== JSON.stringify(prevProps.invoice_data) || this.props.place_of_supply_id !== prevProps.place_of_supply_id || this.props.tax_code !== prevProps.tax_code || this.props.sac_code !== prevProps.sac_code){
            this.calculateTotal()
            console.log("item is", this.props.invoice_data[this.props.index])
        }

    }**/
    useEffect(() => {
        if(props.place_of_supply.length>0) calculateTotal();
    }, [
        props.tax_code,
        sac_code,
        props.place_of_supply_id,
        rate_general,
        state_cess,
        central_cess,
        igst,
        cgst,
        sgst,
        scess,
        ccess,
        amount,
        props.place_of_supply
    ]);
    const { basics, index, fields } = props;
    return (
        <>
            <tr key={index}>
                <td>
                    <Field
                        name={`${basics}.particulars`}
                        label="Particulars*"
                        component={InputText}
                        type="text"
                        fullWidth
                        validate={required}
                    />
                </td>
                <td>
                    <Button
                        onClick={() => {
                            setShowBasicDetails(true);
                        }}
                    >
                        {" "}
                        Select{" "}
                    </Button>
                </td>
                <td>
                    <Field
                        name={`${basics}.sac_code`}
                        label="SAC*"
                        component={InputText}
                        type="text"
                        fullWidth
                        disabled={true}
                    />
                </td>
                <td>
                    <Field
                        name={`${basics}.rate_general`}
                        //label="Rate General*"
                        parse={(value) => Number(value)}
                        component={InputText}
                        type="number"
                        fullWidth
                        disabled={true}
                    />
                </td>
                <td>
                    <Field
                        name={`${basics}.rate_state_cess`}
                        //label="Rate Cess*"
                        parse={(value) => Number(value)}
                        component={InputText}
                        type="number"
                        fullWidth
                        disabled={true}
                    />
                </td>
                <td>
                    <Field
                        name={`${basics}.rate_central_cess`}
                        //label="Central Cess"
                        parse={(value) => Number(value)}
                        component={InputText}
                        type="number"
                        fullWidth
                        disabled={true}
                        //validate={[ required,number]}
                    />
                </td>
                <td>
                    <Field
                        name={`${basics}.base_amount`}
                        label="Amount"
                        parse={(value) => Number(value)}
                        component={InputText}
                        type="text"
                        fullWidth
                        disabled={true}
                        validate={[required, number]}
                    />
                </td>
                <td>
                    <Field
                        name={`${basics}.igst`}
                        label="Igst"
                        component={InputText}
                        parse={(value) => Number(value)}
                        type="text"
                        disabled={true}
                        fullWidth
                    />
                </td>
                <td>
                    <Field
                        name={`${basics}.cgst`}
                        label="Cgst"
                        component={InputText}
                        parse={(value) => Number(value)}
                        type="text"
                        disabled={true}
                        fullWidth
                    />
                </td>
                <td>
                    <Field
                        name={`${basics}.sgst`}
                        label="Sgst"
                        component={InputText}
                        type="text"
                        disabled={true}
                        fullWidth
                    />
                </td>
                <td>
                    <Field
                        name={`${basics}.ccess`}
                        label="CCESS"
                        component={InputText}
                        type="text"
                        disabled={true}
                        fullWidth
                    />
                </td>
                <td>
                    <Field
                        name={`${basics}.scess`}
                        label="SCESS"
                        component={InputText}
                        type="text"
                        disabled={true}
                        fullWidth
                    />
                </td>
                <td>
                    <Field
                        name={`${basics}.total`}
                        label="total"
                        component={InputText}
                        parse={(value) => Number(value)}
                        type="text"
                        disabled={true}
                        fullWidth
                    />
                </td>

                <td style={{ width: "1%" }}>
                    <Button onClick={() => removeRow()}>
                        <ClearIcon />
                    </Button>
                </td>
            </tr>
            <BasicDetails
                show={showBasicDetails}
                details={basics}
                onCloseDetails={(sac_code) => onCloseDetails(sac_code)}
                available_projects={props.available_projects}
                invoice_basics_attributes={props.invoice_data}
                invoice_opes_attributes={props.invoice_opes_attributes}
                token={props.token}
            />
        </>
    );
}
/**BasicAttributes = connect ((state, props) => ({
    amount: selector(state, `${props.basics}.base_amount`),
    igst: selector(state, `${props.basics}.igst`),
    particulars: selector(state, `${props.basics}.particulars`),
    cgst: selector(state, `${props.basics}.cgst`),
    sgst: selector(state, `${props.basics}.sgst`),
    central_cess: selector(state, `${props.basics}.rate_central_cess`),
    state_cess: selector(state, `${props.basics}.rate_state_cess`),
    rate_general: selector(state, `${props.basics}.rate_general`),
    total:  selector(state, `${props.basics}.total`),
    sac_code: selector(state, `${props.basics}.sac_code`),
    ccess: selector(state, `${props.basics}.ccess`),
    scess: selector(state, `${props.basics}.scess`),

}))(BasicAttributes)**/

const renderOpesField = (props) => {
    const {
        fields,
        meta: { touched, error },
        resOpeList,
        invoiceOpeAvailable,
        invoice_opes_attributes,
        opeTotal,
        setRowValidationMessage,
    } = props;
    return (
        <tbody>
            {fields.map((ope, index) => (
                <InvoiceOpe
                    ope={ope}
                    fields={fields}
                    index={index}
                    key={index}
                    resOpeList={resOpeList}
                    invoiceOpeAvailable={invoiceOpeAvailable}
                    invoice_opes_attributes={invoice_opes_attributes}
                    setRowValidationMessage={(txt) =>
                        setRowValidationMessage(txt)
                    }
                />
            ))}
            <tr>
                <td>Total</td>
                <td></td>
                <td>{opeTotal}</td>
                <td></td>
            </tr>
            <tr>
                <Button
                    onClick={() => fields.push({})}
                    color="primary"
                    variant="contained"
                >
                    <AddIcon />
                </Button>
                {touched && error && <span>{error}</span>}
            </tr>
        </tbody>
    );
};
const renderBasicsField = (props) => {
    const {
        fields,
        meta: { touched, error },
        placeOfSupplyId,
        placeOfSupplyList,
        taxCode,
        availableClientProjects,
        invoice_basics_attributes,
        token,
        invoice_opes_attributes,
        basicTotal,
        setRowValidationMessage,
    } = props;

    /**if(fields.length === 0){
        fields.push({})
    }**/
    return (
        <tbody>
            {fields.map((basics, index) => (
                <BasicAttributes
                    basics={basics}
                    fields={fields}
                    index={index}
                    key={index}
                    place_of_supply_id={placeOfSupplyId}
                    place_of_supply={placeOfSupplyList}
                    tax_code={taxCode}
                    available_projects={availableClientProjects}
                    invoice_data={invoice_basics_attributes}
                    token={token}
                    invoice_opes_attributes={invoice_opes_attributes}
                    setRowValidationMessage={(txt) =>
                        setRowValidationMessage(txt)
                    }
                />
            ))}
            <tr>
                <td>Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{basicTotal}</td>
                <td></td>
            </tr>

            <tr>
                <Button
                    onClick={() => fields.push({})}
                    color="primary"
                    variant="contained"
                >
                    <AddIcon />
                </Button>
                {touched && error && <span>{error}</span>}
            </tr>
        </tbody>
    );
};
const RenderSelectInput = ({ input, options, name, id }) => (
    <Select
        {...input}
        id={id}
        name={name}
        options={options}
        value={input.value}
        onChange={(value) => input.onChange(value)}
        onBlur={(value) => input.onBlur()}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        styles={selectStyles}
    />
);

class AddInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            loading: false,
            invoiceAddFailed: true,
            //new_updates
            basicTotal: 0,
            opeTotal: 0,
            rowValidation: "",
            original_data: {},
            showClientChangeError: false,
            showExchangeRateError: false,
            oldClient: undefined,
            values: {},
            draft: false,
        };
        this.onsubmit = this.onsubmit.bind(this);
        //this.renderBasicsField = this.renderBasicsField.bind(this);
        //this.renderOpesField = this.renderOpesField.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.changeClient = this.changeClient.bind(this);
        this.setRowValidationMessage = this.setRowValidationMessage.bind(this);
    }
    componentWillMount() {
        this.props.fetchPlaceOfSupplyList(this.props.token);
        this.props.fetchClientList(this.props.token);
        this.props.fetchOrganisation(this.props.token);
        this.props.dispatch(
            change("AddInvoice", "invoice_date", moment().format("YYYY-MM-DD"))
        );
        this.props.dispatch(change("AddInvoice", "place_of_supply_id", 1));
        if (this.props.location.data) {
            this.props
                .fetchInvoiceDetails(
                    this.props.location.data.id,
                    this.props.token
                )
                .then((resp) => {
                    this.props.initialize(resp);
                    this.setState({
                        oldClient: resp.client_id,
                    });
                    this.loadAndSetRespectiveSacValues(resp);
                    this.setState({
                        original_data: { ...resp },
                    });
                });
        }
    }
    loadAndSetRespectiveSacValues(resp) {
        if (get(resp, "invoice_basics_attributes", undefined)) {
            resp.invoice_basics_attributes.forEach((item, index) => {
                if (get(item, "invoice_basic_details_attributes", undefined)) {
                    this.props
                        .fetchSacMasterByCode(
                            this.props.token,
                            item["invoice_basic_details_attributes"][0]
                                .project_id.sac_code
                        )
                        .then((resp) => {
                            if (resp) {
                                this.props.dispatch(
                                    change(
                                        "AddInvoice",
                                        `invoice_basics_attributes[${index}].sac_code`,
                                        resp.sac_code
                                    )
                                );
                                this.props.dispatch(
                                    change(
                                        "AddInvoice",
                                        `invoice_basics_attributes[${index}].rate_state_cess`,
                                        resp.rate_state_cess
                                    )
                                );
                                this.props.dispatch(
                                    change(
                                        "AddInvoice",
                                        `invoice_basics_attributes[${index}].rate_central_cess`,
                                        resp.rate_central_cess
                                    )
                                );
                                this.props.dispatch(
                                    change(
                                        "AddInvoice",
                                        `invoice_basics_attributes[${index}].rate_general`,
                                        resp.rate_general
                                    )
                                );
                            } else {
                                this.props.dispatch(
                                    change(
                                        "AddInvoice",
                                        `invoice_basics_attributes[${index}].sac_code`,
                                        0
                                    )
                                );
                                this.props.dispatch(
                                    change(
                                        "AddInvoice",
                                        `invoice_basics_attributes[${index}].rate_state_cess`,
                                        0
                                    )
                                );
                                this.props.dispatch(
                                    change(
                                        "AddInvoice",
                                        `invoice_basics_attributes[${index}].rate_central_cess`,
                                        0
                                    )
                                );
                                this.props.dispatch(
                                    change(
                                        "AddInvoice",
                                        `invoice_basics_attributes[${index}].rate_general`,
                                        0
                                    )
                                );
                            }
                        });
                }
            });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.message !== "") {
            setTimeout(() => this.setState({ message: "" }), 9000);
        }

        if (!this.state.invoiceAddFailed) {
            if (this.state.draft) {
                let temp = this.props.location.data ? true : false;
                let t = get(this.props, "doc_currency_id.id", undefined);
                let currency_info;
                if(t){
                    currency_info = this.props.currencies.find((item)=> item.id == this.props.doc_currency_id.id )
                }else{
                    currency_info = {name: "Indian Rupee", code: "INR"}
                }
                this.props.history.replace({
                    pathname: "/draftInvoice",
                    state: {
                        values: {...this.state.values, currency_name: currency_info.name, currency_code: currency_info.code},
                        id: this.props.invoiceId,
                        invoiceOpe: this.props.invoiceOpe,
                        invoiceBasics: this.props.invoiceBasics,
                        updateAction: temp,
                    },
                });
            } else {
                this.props.history.goBack();
            }
        }
        let basic_sum = 0;
        if (
            JSON.stringify(this.props.invoice_basics_attributes) !==
            JSON.stringify(prevProps.invoice_basics_attributes)
        ) {
            this.props.invoice_basics_attributes.forEach((item, index) => {
                basic_sum = basic_sum + item.total;
            });
            this.setState({
                basicTotal: basic_sum,
            });
        }
        let ope_sum = 0;
        if (
            JSON.stringify(this.props.invoice_opes_attributes) !==
            JSON.stringify(prevProps.invoice_opes_attributes)
        ) {
            this.props.invoice_opes_attributes.forEach((item, index) => {
                ope_sum = ope_sum + item.amount;
            });
            this.setState({
                opeTotal: ope_sum,
            });
        }
        if (
            prevState.opeTotal !== this.state.opeTotal ||
            this.state.basicTotal !== prevState.basicTotal
        ) {
            this.props.dispatch(
                change(
                    "AddInvoice",
                    "grand_total",
                    this.state.basicTotal + this.state.opeTotal
                )
            );
        }
        if (this.props.new_invoice_number !== prevProps.new_invoice_number) {
            this.props.dispatch(
                change(
                    "AddInvoice",
                    "invoice_number",
                    this.props.new_invoice_number
                )
            );
        }
        //this.props.dispatch(change("AddInvoice","grand_total",sum))
        if (!isEqual(prevProps.gstinList, this.props.gstinList)) {
            if (this.props.gstinList.length == 1) {
                this.props.dispatch(
                    change(
                        "AddInvoice",
                        "gstin_of_party_id",
                        this.props.gstinList[0].id
                    )
                );
            } else if (this.props.gstinList.length < 1) {
                this.props.dispatch(
                    change("AddInvoice", "gstin_of_party_id", "unregistered")
                );
            }
        } else if (this.props.gstinList.length == 0) {
            this.props.dispatch(
                change("AddInvoice", "gstin_of_party_id", "unregistered")
            );
        } else {
        }
        if (this.props.client !== prevProps.client) {
            this.props.respectiveOpeFetch(
                this.props.client.id,
                this.props.token
            );
            this.props.fetchClientProjects(this.props.token, {
                invoice: true,
                id: this.props.client.id,
            });
        }
        if (this.props.billToClient !== prevProps.billToClient) {
            this.props.fetchClientGstins({
                client: { id: this.props.billToClient.id },
            });
        }
        if (this.props.nature && !prevProps.nature) {
            this.props.fetchCurrency();
        }
        if (
            this.props.nature &&
            this.props.doc_currency_id &&
            (this.props.doc_currency_id !== prevProps.doc_currency_id ||
                this.props.invoice_date !== prevProps.invoice_date)
        ) {
            this.props
                .fetchExchangeRate(
                    this.props.invoice_date,
                    this.props.doc_currency_id.id
                )
                .then((data) => {
                    if (!data) this.setState({ showExchangeRateError: true });
                });
        }
        if (
            this.props.org &&
            this.props.invoice_date &&
            (prevProps.org !== this.props.org ||
                prevProps.invoice_date !== this.props.invoice_date)
        ) {
            this.props.fetchPossiblenIvoiceNumber(
                this.props.token,
                this.props.invoice_date,
                this.props.org.id
            );
        }
    }
    closeModal() {
        this.setState({
            loading: this.props.loading,
            invoiceAddFailed: this.props.invoiceAddFailed,
        });
    }
    setRowValidationMessage(txt) {
        this.setState({
            rowValidation: txt,
        });
    }
    validateInvoiceBasics(invoice_data) {
        let keys = [],
            hasAllKeys = false,
            valid = true;
        if (get(invoice_data, "invoice_basics_attributes", undefined)) {
            start: for (var basics of invoice_data[
                "invoice_basics_attributes"
            ]) {
                if (
                    get(basics, "invoice_basic_details_attributes", undefined)
                ) {
                    for (var item of basics[
                        "invoice_basic_details_attributes"
                    ]) {
                        keys = ["project_id", "actual_amount", "invoice_type"];
                        hasAllKeys = keys.every((i) => item.hasOwnProperty(i));
                        if (!hasAllKeys) {
                            this.setRowValidationMessage(
                                `Please fill all fields related to project ${basics["particulars"]} to proceed!!`
                            );
                            valid = false;
                            break start;
                        }
                        item["project_id"] = item["project_id"]["id"];
                        if ("budgeted_amount" in item)
                            delete item["budgeted_amount"];
                        if ("previous_actual_amount" in item)
                            delete item["previous_actual_amount"];
                    }
                }
                [
                    "rate_central_cess",
                    "rate_state_cess",
                    "rate_general",
                ].forEach((key) => {
                    basics[key] = parseFloat(basics[key]);
                });
            }
        }
        return valid;
    }
    validateInvoiceOpes(invoice_data) {
        let keys = [],
            hasAllKeys = false,
            valid = true;
        if (get(invoice_data, "invoice_opes_attributes", undefined)) {
            start: for (var basics of invoice_data["invoice_opes_attributes"]) {
                if (get(basics, "invoice_ope_details_attributes", undefined)) {
                    for (var item of basics["invoice_ope_details_attributes"]) {
                        keys = ["ope_id", "billed_amount"];
                        hasAllKeys = keys.every((i) => item.hasOwnProperty(i));
                        if (!hasAllKeys) {
                            this.setRowValidationMessage(
                                `Please fill all fields related to Ope ${basics["particulars"]} to proceed!!`
                            );
                            valid = false;
                            break start;
                        }

                        item["ope_id"] = item["ope_id"]["id"];
                        if ("actual_amount" in item)
                            delete item["actual_amount"];
                    }
                }
            }
        }
        return valid;
    }
    addLocalAmounts = (invoice_data) => {
        let er;
        if (invoice_data["nature"] == "E") {
            er = this.props.er;
        } else {
            er = 1; //by default
        }
        invoice_data["loc_amount"] = Math.round(
            invoice_data["grand_total"] * er
        );
        if (get(invoice_data, "invoice_basics_attributes", undefined)) {
            for (var basics of invoice_data["invoice_basics_attributes"]) {
                basics["loc_base"] = Math.round(basics["base_amount"] * er);
                basics["loc_total"] = Math.round(basics["total"] * er);
                if (
                    get(basics, "invoice_basic_details_attributes", undefined)
                ) {
                    for (var item of basics[
                        "invoice_basic_details_attributes"
                    ]) {
                        item["loc_amount"] = Math.round(
                            item["actual_amount"] * er
                        );
                    }
                }
            }
        }
        if (get(invoice_data, "invoice_opes_attributes", undefined)) {
            for (var basics of invoice_data["invoice_opes_attributes"]) {
                basics["loc_amount"] = Math.round(basics["amount"] * er);
                if (get(basics, "invoice_ope_details_attributes", undefined)) {
                    for (var item of basics["invoice_ope_details_attributes"]) {
                        item["loc_amount"] = Math.round(
                            item["billed_amount"] * er
                        );
                    }
                }
            }
        }
    };
    updationCheckForMissingValues = (
        invoice_data,
        parent_key,
        child_key,
        property
    ) => {
        let temp = {},
            obj = {},
            obj1 = {};
        if (get(this.state, `original_data[${parent_key}]`, undefined)) {
            if (get(invoice_data, parent_key, undefined)) {
                this.state.original_data[parent_key].forEach((item) => {
                    obj = invoice_data[parent_key].find(
                        (o) => o.id === item.id
                    );
                    if (obj) {
                        if (get(item, child_key, undefined)) {
                            if (get(obj, child_key, undefined)) {
                                item[child_key].forEach((details) => {
                                    obj1 = obj[child_key].find(
                                        (o) => o.id === details.id
                                    );
                                    if (!obj1) {
                                        temp = {
                                            id: details.id,
                                            _destroy: true,
                                            [property]: details[property]["id"],
                                        };
                                        obj[child_key].push({ ...temp });
                                    }
                                });
                            } else {
                                item[child_key].forEach((item) => {
                                    temp = {
                                        id: item.id,
                                        _destroy: true,
                                        [property]: item[property]["id"],
                                    };
                                    if (!obj[child_key]) obj[child_key] = [];
                                    obj[child_key].push({ ...temp });
                                });
                            }
                        }
                    } else {
                        temp = {
                            id: item.id,
                            _destroy: true,
                            particulars: item.particulars,
                        };
                        invoice_data[parent_key].push({ ...temp });
                    }
                });
                return;
            } else {
                this.state.original_data[parent_key].forEach((item) => {
                    temp = {
                        id: item.id,
                        _destroy: true,
                        particulars: item.particulars,
                    };
                    if (!invoice_data[parent_key])
                        invoice_data[parent_key] = [];
                    invoice_data[parent_key].push({ ...temp });
                });
                return;
            }
        }
    };
    changeClient(event) {
        if (this.state.oldClient && this.state.oldClient.id !== event.id) {
            this.setState({ showClientChangeError: true });
        } else {
            this.setState({ oldClient: event });
            this.props.dispatch(
                change("AddInvoice", "bill_to_client_id", event)
            );
        }
    }
    okChangeClient() {
        this.setState({ showClientChangeError: false });
        this.setState({ oldClient: this.props.client });
        this.props.dispatch(
            change("AddInvoice", "bill_to_client_id", this.props.client)
        );
        this.props.dispatch(
            change("AddInvoice", "invoice_opes_attributes", [])
        );
        this.props.dispatch(
            change("AddInvoice", "invoice_basics_attributes", [])
        );
    }
    dontChangeClient() {
        this.setState({ showClientChangeError: false });
        this.props.dispatch(
            change("AddInvoice", "client_id", this.state.oldClient)
        );
    }
    closeExchangeRateError() {
        this.setState({ showExchangeRateError: false });
        this.props.dispatch(change("AddInvoice", "doc_currency_id", null));
    }
    async onsubmit(formData) {
        //formData["ope_ids"] = this.state.selectedOpe;
        let invoice_data = JSON.parse(JSON.stringify(formData)); //{ ...formData };
        this.setState({ values: invoice_data }); //for using in draft view deep copy
        if (invoice_data["category"] === 1) this.setState({ draft: true });
        if (invoice_data["nature"]) {
            invoice_data["doc_currency_id"] =
            invoice_data["doc_currency_id"]["id"];
            invoice_data["exchange_rate"] = this.props.er;
        } else {
            delete invoice_data["doc_currency_id"];
        }
        invoice_data["grand_total"] = Math.round(invoice_data["grand_total"]);
        invoice_data["nature"] = invoice_data["nature"] ? "E" : "D";
        invoice_data["client_id"] = invoice_data["client_id"]["id"];
        invoice_data["organisation_id"] = invoice_data["organisation_id"]["id"];
        invoice_data["bill_to_client_id"] =
            invoice_data["bill_to_client_id"]["id"];
        if (invoice_data["gstin_of_party_id"] === "unregistered")
            delete invoice_data["gstin_of_party_id"];
        /**
	    //commented out on may 2022 coz grand_total missing in draft
	 * if ("grand_total" in invoice_data && invoice_data["category"] === 1)
            delete invoice_data["grand_total"];
	 **/
        if ("id" in invoice_data) delete invoice_data["id"];
        Promise.all([
            this.validateInvoiceBasics(invoice_data),
            this.validateInvoiceOpes(invoice_data),
            this.addLocalAmounts(invoice_data),
        ]).then((values) => {
            if (values[0] && values[1]) {
                this.setState({ loading: true });
                if (this.props.location.data) {
                    invoice_data["updated_by_id"] = this.props.user.id;
                } else {
                    invoice_data["created_by_id"] = this.props.user.id;
                }
                if (this.props.location.data) {
                    Promise.all([
                        this.updationCheckForMissingValues(
                            invoice_data,
                            "invoice_opes_attributes",
                            "invoice_ope_details_attributes",
                            "ope_id"
                        ),
                        this.updationCheckForMissingValues(
                            invoice_data,
                            "invoice_basics_attributes",
                            "invoice_basic_details_attributes",
                            "project_id"
                        ),
                    ]).then((values) => {
                        this.props.updateInvoice(
                            invoice_data,
                            this.props.token,
                            this.props.location.data.id
                        );
                    });
                } else {
                    this.props.addInvoice(invoice_data, this.props.token);
                }
                this.setState({
                    message: this.props.message,
                });
            }
        });
    }
    render() {
        const { handleSubmit, classes } = this.props;
        return (
            <div style={{ marginLeft: 10, marginRight: 10 }}>
                <Header title={"add_invoice"} />
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                >
                    <h1>
                        {" "}
                        <Badge variant="dark"> Add Invoice </Badge>{" "}
                    </h1>
                </Typography>
                <Row>
                    <Col sm={4}></Col>
                </Row>
                <form onSubmit={handleSubmit(this.onsubmit)}>
                    <Row>
                        <h3>
                            <Badge variant="dark">Basic Details</Badge>
                        </h3>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <span>Select Organisation</span>
                            <Field
                                name="organisation_id"
                                label=""
                                props={{
                                    type: "object",
                                }}
                                component={MultiSelectComponent}
                                {...{
                                    data: this.props.organisation,
                                    isMulti: false,
                                }}
                                fullWidth
                            />
                        </Col>
                        <Col sm={4}>
                            <span>Invoice Number</span>
                            <Field
                                name="invoice_number"
                                label="Invoice Number "
                                component={InputText}
                                type="text"
                                disabled
                                fullWidth
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={8}>
                            <span>Select Client</span>
                            <Field
                                name="client_id"
                                options={this.props.dclients}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                component={RenderSelectInput}
                                onChange={(value) => this.changeClient(value)}
                            />
                        </Col>
                        <Col sm={4}>
                            <span>Invoice Date</span>
                            <Field
                                name="invoice_date"
                                label=""
                                component={InputText}
                                fullWidth
                                type="date"
                                max={moment().format("YYYY-MM-DD")}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={8}>
                            <span>Bill to Client</span>

                            <Field
                                name="bill_to_client_id"
                                label=" "
                                props={{
                                    type: "object",
                                }}
                                component={MultiSelectComponent}
                                {...{
                                    data: this.props.dclients,
                                    isMulti: false,
                                }}
                                fullWidth
                            />
                        </Col>
                        <Col sm={4}>
                            {this.props.gstinList.length < 1 && (
                                <Field
                                    name="gstin_of_party_id"
                                    label="GSTIN *"
                                    component={InputText}
                                    type="text"
                                    disabled={true}
                                    fullWidth
                                />
                            )}
                            {this.props.gstinList.length >= 1 && (
                                <Field
                                    name="gstin_of_party_id"
                                    label="GSTIN *"
                                    component={SelectInput}
                                    data={this.props.gstinList}
                                    fullWidth
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <Field
                                name="place_of_supply_id"
                                label="Place of supply*"
                                component={SelectInput}
                                data={
                                    this.props.place_of_supply.placeOfSupplyList
                                }
                                fullWidth
                            />
                        </Col>
                        <Col sm={4}>
                            <Field
                                name="tax_code"
                                label="Tax code *"
                                component={SelectInput}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                        <Col sm={8}>
                            <Field
                                name="nature"
                                component={RenderSwitch}
                                label="Export?"
                                defaultValue={false}
                            />
                        </Col>
                        {this.props.nature && (
                            <Col sm={4}>
                                <span>Doc currency</span>
                                <Field
                                    name="doc_currency_id"
                                    options={this.props.currencies}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    component={RenderSelectInput}
                                    onChange={(value) => {
                                        this.props.dispatch(
                                            change(
                                                "AddInvoice",
                                                "doc_currency_id",
                                                value
                                            )
                                        );
                                    }}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <h3>
                            <Badge variant="dark">Basic</Badge>
                        </h3>
                    </Row>
                    <Table responsive style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "10%" }}>Particulars</th>
                                <th>Projects</th>
                                <th>SAC Code</th>
                                <th>General Rate</th>
                                <th>Rate SCESS</th>
                                <th>Rate CCESS</th>
                                <th>Amount</th>
                                <th>IGST</th>
                                <th>CGST</th>
                                <th>SGST</th>
                                <th>CCESS</th>
                                <th>SCESS</th>
                                <th>Total</th>
                                <th></th>
                            </tr>
                        </thead>

                        {
                            <FieldArray
                                name="invoice_basics_attributes"
                                component={renderBasicsField}
                                placeOfSupplyId={this.props.placeOfSupplyId}
                                placeOfSupplyList={
                                    this.props.place_of_supply.placeOfSupplyList
                                }
                                taxCode={this.props.taxCode}
                                availableClientProjects={
                                    this.props.availableClientProjects
                                }
                                invoice_basics_attributes={
                                    this.props.invoice_basics_attributes
                                }
                                token={this.props.token}
                                invoice_opes_attributes={
                                    this.props.invoice_opes_attributes
                                }
                                basicTotal={this.state.basicTotal}
                                setRowValidationMessage={(txt) =>
                                    this.setRowValidationMessage(txt)
                                }
                            />
                        }
                    </Table>
                    <Row>
                        <h3>
                            <Badge variant="dark">Ope</Badge>
                        </h3>
                    </Row>

                    <Table responsive style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "10%" }}>Particulars</th>
                                <th>Nature</th>
                                <th>Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        {
                            <FieldArray
                                name="invoice_opes_attributes"
                                component={renderOpesField}
                                resOpeList={this.props.resOpeList}
                                invoiceOpeAvailable={
                                    this.props.invoiceOpeAvailable
                                }
                                invoice_opes_attributes={
                                    this.props.invoice_opes_attributes
                                }
                                opeTotal={this.state.opeTotal}
                                setRowValidationMessage={(txt) =>
                                    this.setRowValidationMessage(txt)
                                }
                            />
                        }
                    </Table>
                    <Row>
                        <Col sm={8}></Col>
                        <Col sm={4}>
                            <span>Grand Total</span>
                            <Field
                                name="grand_total"
                                label=""
                                component={InputText}
                                fullWidth
                                type="text"
                                validate={[required, number]}
                            />
                        </Col>
                    </Row>
                    <SnackbarAlert
                        key={this.state.rowValidation}
                        open={this.state.rowValidation === "" ? false : true}
                        message={this.state.rowValidation}
                        onClick={() => this.setRowValidationMessage("")}
                    />
                    <AlertComponent
                        title="Change Client?"
                        description="Changing the client will reset the form. All data entered will be lost. Are you sure want to proceed?"
                        open={this.state.showClientChangeError}
                        cancelText="No, dont proceed"
                        saveText="Yes Proceed"
                        handleClose={() => {
                            this.dontChangeClient();
                        }}
                        proceed={() => this.okChangeClient()}
                    />
                    <AlertComponent
                        title="Echange Rate missing"
                        description="Exchange Rate is not available for the particular date selected, please update ER to invoice in Foreign Currency"
                        open={this.state.showExchangeRateError}
                        saveText="Ok"
                        proceed={() => this.closeExchangeRateError()}
                    />
                    <Row>
                        <Col sm={4}>
                            <SavingDetailsModal
                                className={classes.modal}
                                show={this.state.loading}
                                title={"Saving Invoice Details"}
                                loading={this.props.loading}
                                message={this.props.message}
                                onClick={this.closeModal}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col sm={8}>{this.state.message}</Col>
                        <Col>
                            <Row className="float-right">
                                <Col>
                                    <Button
                                        onClick={handleSubmit((values) =>
                                            this.onsubmit({
                                                ...values,
                                                category: 1,
                                            })
                                        )}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Draft
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        onClick={handleSubmit((values) =>
                                            this.onsubmit({
                                                ...values,
                                                category: 0,
                                            })
                                        )}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Finalise
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </form>

                {/**this.state.draft &&<ViewInvoice values = {{...this.state.values}} />**/}
            </div>
        );
    }
}
const selector = formValueSelector("AddInvoice");
function mapStateToProps(state, ownProps) {
    return {
        clientProjects: state.project.clientProjects,
        availableClientProjects: state.project.availableClientProjects,
        invoiceProjectsSelected: state.project.invoiceProjectsSelected,
        dclients: state.client.dclientList,
        gstinList: state.client.gstinList,
        organisation: state.organisation.dorganisationList,
        token: state.auth.token,
        user: state.auth.user,
        resOpeList: state.ope.resOpeList,
        invoiceOpeSelected: state.ope.invoiceOpeSelected,
        invoiceOpeAvailable: state.ope.invoiceOpeAvailable,
        message: state.invoice.invoiceAddMessage,
        invoiceId: state.invoice.invoiceId,
        invoiceOpe: state.invoice.invoiceOpe,
        invoiceBasics: state.invoice.invoiceBasics,
        loading: state.invoice.invoiceAddLoading,
        invoiceAddFailed: state.invoice.invoiceAddFailed,
        invoice_basics_attributes: selector(state, "invoice_basics_attributes"),
        invoice_opes_attributes: selector(state, "invoice_opes_attributes"),
        invoice_gstin: selector(state, "gstin_of_party_id"),
        org: selector(state, "organisation_id"),
        client: selector(state, "client_id"),
        billToClient: selector(state, "bill_to_client_id"),
        placeOfSupplyId: selector(state, "place_of_supply_id"),
        taxCode: selector(state, "tax_code"),
        place_of_supply: state.placeOfSupply,
        new_invoice_number: state.invoice.invoiceNumber,
        invoice_date: selector(state, "invoice_date"),
        nature: selector(state, "nature"),
        doc_currency_id: selector(state, "doc_currency_id"),
        er: state.exchangeRate.er,
        erLoading: state.exchangeRate.erLoading,
        currencies: state.currency.currency,
    };
}
export default compose(
    withRouter,
    connect(mapStateToProps, actions),
    reduxForm({
        form: "AddInvoice",
        validate,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    }),
    withStyles(styles)
)(AddInvoice);
