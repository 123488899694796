import React from "react";
import PageTemplate from "../components/Layout/PageTemplate/PageTemplate";
import CoreUITable from "../components/Layout/Table";
import { useDscSignatures } from "../hooks/dsc-query/dscSignatures";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useHistory } from "react-router-dom";
const DscSignaturePage = () => {
    const history = useHistory();
    const { isLoading, isError, data, error, refetch } = useDscSignatures();
    if (isLoading)
        return <ProgressBar striped variant="success" animated now={40} />;
    if (isError) return <>Error...</>;
    const options = [
        {
            text: "View",
            action: function (id) {
                history.push({ pathname: "/add_dsc_signature" });
            },
            params: true,
        },
        {
            text: "Edit",
            action: function (id) {
            },
            params: true,
        },
    ];
    return (
        <PageTemplate>
            <CoreUITable
                page="dsc_signatures"
                title="Dsc Signatures"
                addPath="/add_dsc_signature"
                options={options}
                data={data}
                fields={
                    data[0]
                        ? Object.keys(data[0])
                        : [
                              "id",
                              "client",
                              "project",
                              "request_particulars",
                              "dsc_id",
                              "user",
                              "document_category",
                              "document_particulars",
                          ]
                }
            />
            {/**
                {
                  key: 'show_details',
                  label: '',
                  _style: { width: '1%' },
                  filter: false
                }
            **/}
        </PageTemplate>
    );
};

export default DscSignaturePage;
