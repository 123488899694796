import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "../reducers";
const JWT_TOKEN = localStorage.getItem("JWT_TOKEN")

export default function configStore(){
   return createStore(reducers, {
        auth :{
            token : JWT_TOKEN,
            isAuthenticated: JWT_TOKEN? true : false
        }
    }, applyMiddleware(reduxThunk))
}
