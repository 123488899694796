import React, {useEffect } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {getDocUrl} from "../../actions/certificateregisterAction";
import {useLocation} from 'react-router-dom';
import { makeStyles, createStyles } from "@material-ui/core/styles";
export default function ViewDoc(props) {

	const doc_data = useSelector(state => state);
    const dispatch = useDispatch();
	const {id}  = useLocation();
	const classes = useStyles();
	useEffect(() => {
        if(id){
            dispatch(getDocUrl(doc_data.auth.token,id))
        }else{
            dispatch(getDocUrl(doc_data.auth.token,props.id))
        }
	},[]);

  // Declare a new state variable, which we'll call "count"  const [count, setCount] = useState(0);
  return (
    <div className={classes.root}>
    	{doc_data.certificateregister.docUrl ? <iframe src={doc_data.certificateregister.docUrl}  className={classes.iframe} allowFullScreen/> : <p>{doc_data.certificateregister.message !== ''? doc_data.certificateregister.message: null}</p>}
	</div>
  );
}
const useStyles = makeStyles(() =>
  	createStyles({
     	root: {
			width: '100%',
			margin: '0',
		},
		iframe:{
			display: 'block',
			border: 'none',
			width: '100%',
			height: '100vh',
		}
	})
);
