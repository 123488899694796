import {
    AUTH_SIGNUP,
    AUTH_ERROR,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    FETCHING_USER,
    FETCHING_USER_DONE,
    FETCHING_USER_FAILED,
    START_LOGIN,
    FETCH_TOKEN_FROM_LOCAL,
    START_SIGNUP,
    INCRIMENT_NOTIFICATION_COUNT,
    CLEAR_NOTIFICATION_COUNT,
    VERIFY_PASSKEY_LOADING,
    VERIFY_PASSKEY,
    VERIFY_PASSKEY_FAILED,
    GET_PASSKEY,
} from "../actions/types";

const DEFAULT_STATE = {
    isAuthenticated: false,
    token: "",
    errorMessage: "",
    loading: false,
    user: {},
    signupFailed: true,
    passKey: false,
    passKeyLoading: false,
    passKeyFailed: null,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_TOKEN_FROM_LOCAL:
            return {
                ...state,
                token: action.payload,
            };
        case AUTH_SIGNUP:
            return {
                ...state,
                token: action.payload,
                //isAuthenticated: true,
                errorMessage: action.payload,
                loading: false,
                //user:action.userDetails
            };
        case AUTH_ERROR:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
                signupFailed: true,
            };
        case AUTH_LOGIN:
            return {
                ...state,
                token: action.payload,
                isAuthenticated: true,
                errorMessage: "",
                user: action.userDetails,
            };
        case AUTH_LOGOUT:
            return {
                ...state,
                token: "",
                isAuthenticated: false,
                errorMessage: "",
                user: {},
            };
        case FETCHING_USER:
            return {
                ...state,
                loading: true,
                isAuthenticated: false,
                errorMessage: "",
            };
        case FETCHING_USER_DONE:
            return {
                ...state,
                isAuthenticated: true,
                errorMessage: "",
                user: action.payload,
                loading: false,
            };
        case FETCHING_USER_FAILED:
            return {
                ...state,
                isAuthenticated: false,
                errorMessage: action.payload,
                loading: false,
            };
        case START_LOGIN:
            return {
                ...state,
                errorMessage: action.payload,
            };
        case START_SIGNUP:
            return {
                ...state,
                loading: true,
                signupFailed: false,
            };
        case INCRIMENT_NOTIFICATION_COUNT:
            return {
                ...state,
                user: {
                    ...state.user,
                    unread_notifications: state.user.unread_notifications + 1,
                },
            };
        case CLEAR_NOTIFICATION_COUNT: //decriment by 1
            return {
                ...state,
                user: {
                    ...state.user,
                    unread_notifications: state.user.unread_notifications - 1,
                },
            };
        case VERIFY_PASSKEY_LOADING:
            return {
                ...state,
                passKeyLoading: action.payload,
            };
        case VERIFY_PASSKEY:
            return {
                ...state,
                passKey: action.payload,
            };

        case VERIFY_PASSKEY_FAILED:
            return {
                ...state,
                passKeyFailed: action.payload,
            };

        case GET_PASSKEY:
            return {
                ...state,
                passKey: action.payload,
            };

        default:
            return state;
    }
};
