import axios from '../axiosConfig';
import {FETCH_START_CURRENCY, FETCH_CURRENCY_SUCCESS, FETCH_CURRENCY_FAILED } from "./types";

export const fetchCurrency = (data) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_START_CURRENCY,
        });
        try {
            const res = await axios.get('/currency');
            console.log('response',res);
            if (res.statusText == "OK") {
                console.log("status ok!!!", res.data);
                dispatch({
                    type: FETCH_CURRENCY_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type:  FETCH_CURRENCY_FAILED,
                    payload: res.data,
                });
            }
        } catch (error) {
            // console.log(error);
            dispatch({
                type: FETCH_CURRENCY_FAILED,
                payload: error,
            });
        }
    };
};

