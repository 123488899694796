import React from 'react';
import { Link, Text, View, StyleSheet,Font } from '@react-pdf/renderer';
import {numberToWord} from '../../utils/numberToWord'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        //alignSelf: 'flex-start',
        justifyContent: 'space-between'
    },
    invoiceDetails:{

        borderTopWidth: 1,
        borderTopColor: '#112131',
        borderTopStyle: 'solid',
        flexDirection: 'row',
        paddingTop: '3%',
        alignItems: 'flex-start'
    },
    clientDetails: {
        borderTopWidth: 1,
        borderTopColor: '#112131',
        borderTopStyle: 'solid',
        flexDirection: 'column',
        paddingTop: '3%',
        alignItems: 'center'
    },
    clientDetailsItem:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        display: 'flex',
        paddingBottom: '1%',
        alignItems: 'stretch'
    },
    invoiceDetailsItem:{
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '1%',
        flex: 1
    },
    titleTop:{
        marginTop: '2%',
        fontSize: 10,
        fontFamily: "'Open Sans', sans-serif"
    },
    particularsTitle:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
        marginTop: '1%'
    },
    particularsColumnTitle:{
        flex: '1',
        fontSize: 10,
        fontFamily: "'Open Sans', sans-serif",
        alignItems: 'stretch',
        justifySelf: 'flex-start',
    },
    particularsColumnDetails:{
        flex: '1',
        fontSize: 8,
        fontFamily: "'Open Sans',light",
        alignItems: 'stretch',
        justifySelf: 'flex-start',

    },
    particularDetailsBox:{
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderTopColor: '#112131',
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        borderTopStyle: 'solid',
        flexDirection: 'column',
        paddingTop: '1%',
        paddingBottom: '1%'
    },
    totalTable:{
        borderBottomWidth: 1,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        flexDirection: 'column',
        paddingTop: '3%',
        alignSelf: 'flex-end',
        width: '50%'
        //justifySelf: 'flex-end',


    },
    totalText:{
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
        fontFamily: "'Open Sans',light",
        fontSize: 8

    },
    totalTablesItem:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '1%'
    },
    lightText:{
        fontFamily: "'Open Sans',light",
        fontSize: 8,
        textAlign: 'justify'
    }


})

export default ({props})=>(
    <View style={styles.container}>
        <View style={{width: '50%'}}>
        <Text style={styles.titleTop}>Invoice details</Text>
        <View style={styles.invoiceDetails}>
            <View style={styles.invoiceDetailsItem}>
                <Text style={styles.lightText}>Invoice No.</Text>
                <Text style={styles.lightText}>Date</Text>
                <Text style={styles.lightText}>PAN</Text>
                <Text style={styles.lightText}>GSTIN</Text>
                <Text style={styles.lightText}>Place of Supply</Text>
            </View>
            <View style={styles.invoiceDetailsItem}>
                <Text style={styles.lightText}>{props.invoice_number}</Text>
                <Text style={styles.lightText}>{props.invoice_date.split("-").reverse().join("/")}</Text>
                <Text style={styles.lightText}>{props.pan}</Text>
                <Text style={styles.lightText}>{props.gstin}</Text>
                <Text style={styles.lightText}>{props.place_of_supply}</Text>
            </View>

        </View>

        <Text  style={styles.titleTop}>Customer details</Text>
        <View style={styles.clientDetails}>
            <View style={styles.clientDetailsItem}>
                <Text style={[styles.lightText, {width: '30%'}]}>Name</Text>
                <Text style={[styles.lightText, {width: '70%'}]}>{props.client.name}</Text>
            </View>
            <View style={[styles.clientDetailsItem,{paddingBottom: "0%"}]}>
                <Text style={[styles.lightText, {width: '30%'}]}>Address</Text>
                <Text style={[styles.lightText, {width: '70%'}]}>{props.client.address}</Text>
            </View>
            <View style={[styles.clientDetailsItem,{paddingBottom: "0%"}]}>
                <Text style={[styles.lightText, {width: '30%'}]}></Text>
                <Text style={[styles.lightText, {width: '70%'}]}>{props.client.city},{props.client.district}</Text>
            </View>
            <View style={[styles.clientDetailsItem,{paddingBottom: "0%"}]}>
                <Text style={[styles.lightText, {width: '30%'}]}></Text>
                <Text style={[styles.lightText, {width: '70%'}]}>{props.client.state}-{props.client.pin}</Text>
            </View>
            <View style={styles.clientDetailsItem}>
                <Text style={[styles.lightText, {width: '30%'}]}>GSTIN</Text>
                <Text style={[styles.lightText, {width: '70%'}]}>{props.client.gstin}</Text>
            </View>
        </View>
        </View>
        <View style={styles.particularsTitle}>
            <Text style={[styles.particularsColumnTitle,{width: '7%',}]}>Sl No</Text>
            <Text style={[styles.particularsColumnTitle,{width: '63%'}]}>Particulars</Text>
            <Text style={[styles.particularsColumnTitle,{width: '15%', textAlign: 'right'}]}>SAC</Text>
            <Text style={[styles.particularsColumnTitle,{width: '15%', textAlign: 'right'}]}>{`Amount (${props.currency_code})`}</Text>
        </View>
        <View style={styles.particularDetailsBox}>
            {
                props.particulars.map((item,index)=>{
                    return(<View style={[styles.particularsTitle, {alignItems: 'stretch', display: 'flex'}]}>
                        <Text style={[styles.particularsColumnDetails,{width: '7%',}]}>{index +1}</Text>
                        <Text style={[styles.particularsColumnDetails,{width: '63%'}]}>{item.particulars}</Text>
                        <Text style={[styles.particularsColumnDetails,{width: '15%', textAlign: 'right'}]}>{item.sac_code}</Text>
                        <Text style={[styles.particularsColumnDetails,{width: '15%', textAlign: 'right'}]}>{item.base_amount.toLocaleString()}</Text>
                    </View>)

                })
            }
            {
                    (props.opes && props.opes.length >0) &&
                    <>
                    <View style={styles.particularsTitle}>
                        <Text style={[styles.particularsColumnTitle,{width: '7%',}]}>     </Text>
                        <Text style={[styles.particularsColumnTitle,{width: '63%', textDecoration: 'underline'}]}>Reimbursements</Text>
                        <Text style={[styles.particularsColumnTitle,{width: '15%', textAlign: 'right'}]}>   </Text>
                        <Text style={[styles.particularsColumnTitle,{width: '15%', textAlign: 'right'}]}>     </Text>
                    </View>
                    {
                        props.opes.map((item,index)=>{
                            return(<View style={[styles.particularsTitle, {alignItems: 'stretch', display: 'flex'}]}>
                                <Text style={[styles.particularsColumnDetails,{width: '7%',}]}>{index +1}</Text>
                                <Text style={[styles.particularsColumnDetails,{width: '63%'}]}>{item.particulars}</Text>
                                <Text style={[styles.particularsColumnDetails,{width: '15%', textAlign: 'right'}]}></Text>
                                <Text style={[styles.particularsColumnDetails,{width: '15%', textAlign: 'right'}]}>{item.amount.toLocaleString()}</Text>
                            </View>)

                        })
                    }
                    </>
            }
        </View>
        <View style={styles.totalTable}>
            <View style={styles.totalTablesItem}>
                <Text style={styles.particularsColumnTitle}>Taxable Value</Text>
                 <Text style={styles.lightText}>{props.taxable_amount.toLocaleString()}</Text>
             </View>
             <View style={styles.totalTablesItem}>
                 <Text style={styles.particularsColumnTitle}>Reimbursements</Text>
                 <Text style={styles.lightText}>{props.reimbursements.toLocaleString()}</Text>
            </View>
            <View style={styles.totalTablesItem}>
                 <Text style={styles.lightText}>Central GST (9%)</Text>
                 <Text style={styles.lightText}>{props.cgst.toLocaleString()}</Text>
             </View>
             <View style={styles.totalTablesItem}>
                 <Text style={styles.lightText}>State GST (9%)</Text>
                 <Text style={styles.lightText}>{props.sgst.toLocaleString()}</Text>
            </View>
            <View style={styles.totalTablesItem}>
                 <Text style={styles.lightText}>Integrated GST (18%)</Text>
                 <Text style={styles.lightText}>{props.igst.toLocaleString()}</Text>
            </View>
            {/**<View style={styles.totalTablesItem}>
                 <Text style={styles.lightText}>Kerala flodd cess (9%)</Text>
                 <Text style={styles.lightText}>{props.kfc.toLocaleString()}</Text>
                 </View>**/}
             <View style={styles.totalTablesItem}>
                 <Text style={styles.particularsColumnTitle}>{`Total (${props.currency_code})`}</Text>
                 <Text style={styles.lightText}>{props.grand_total.toLocaleString()}</Text>
             </View>
         </View>
        <Text style={styles.totalText}>{`${props.currency_name} ${numberToWord(Math.floor(props.grand_total))}`}</Text>

    </View>
)
