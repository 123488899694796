import React from "react";
import { useMutation, QueryClient, useQuery } from "react-query";
import axios from "../../axiosConfig";
import { padZeros, getMinutes } from "../../utils/formComponents";
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
        },
    },
});

async function getBookedTimeByDate({ queryKey }) {
    try {
        const res = await axios.get(
            `/get_my_time_booked_by_date/${queryKey[1]}`
        );
        return res.data;
    } catch (err) {
        throw err.response.data;
    }
}

async function saveTimesheet(formData) {
    try {
        const res = await axios.post(`/timesheet`, formData);
        return res.data;
    } catch (err) {
        throw err.response.data;
    }
}

export const useGetBookedTimeByDate = (date, verifyDateChange) =>
    useQuery(["bookedTime", date], getBookedTimeByDate, {
        refetchOnWindowFocus: false,
        enabled: verifyDateChange,
        select: React.useCallback(
            (data) => {
                //conert minutes to HH:MM format
                let hour, min;
                console.log("select data", data);

                try {
                    Object.keys(data).map((key) => {
                        data[key].map((item) => {
                            hour = Math.floor(item.allocated_time / 60);
                            hour = padZeros(hour);
                            min = item.allocated_time % 60;
                            min = padZeros(min);
                            item["allocated_time"] = hour + ":" + min;
                            console.log("item", item);
                        });
                    });
                    return data
                } catch (error) {
                    console.log("eror", error);
                    return null
                }
            },
            [date]
        ),
    });

export const useTimesheetAdd = () =>
    useMutation((data) => saveTimesheet(data), {
        onSuccess: (data) => {
            queryClient.invalidateQueries("bookedTime");
        },
    });
