import axios from '../axiosConfig';
import {FETCH_EXCHANGE_RATE_START, FETCH_EXCHANGE_RATE_SUCCESS, FETCH_EXCHANGE_RATE_FAILED } from "./types";

export const fetchExchangeRate = (invoice_date, currency_id) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_EXCHANGE_RATE_START,
        });
        try {
            const res = await axios.get(`/exchange_rate/${currency_id}/${invoice_date}`);
            console.log('response',res);
            if (res.statusText == "OK") {
                // console.log("status ok!!!", res.data);
                dispatch({
                    type: FETCH_EXCHANGE_RATE_SUCCESS,
                    payload: res.data,
                });
                return res.data[0]
            } else {
                dispatch({
                    type:  FETCH_EXCHANGE_RATE_FAILED,
                    payload: res.data,
                });
                return undefined
            }
        } catch (error) {
            // console.log(error);
            dispatch({
                type: FETCH_EXCHANGE_RATE_FAILED,
                payload: error,
            });
            return undefined
        }
    };
};

