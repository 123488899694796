import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field, formValueSelector } from "redux-form";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import MultiSelectComponent from "../../utils/multiselect";
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import {InputText,TextArea,SelectInput, SavingDetailsModal, RenderSwitch, compareDates} from "../../utils/formComponents";

const validate = (inputs,props) =>{
    const errors = {};
    if(!('name' in inputs)){
         errors.name = "Name required"
    }
    if(!inputs.in_office){
        if(!('employee_id' in inputs)){
           errors.employee_id= "Required"
        }
        //for custdoian sub heading
        if(!inputs.dsc_custodians_attributes || !inputs.dsc_custodians_attributes.length){
           errors.dsc_custodian_ = "Custodian required!"
        }else{
            const errorArray = []
            inputs.dsc_custodians_attributes.forEach((data,index) =>{
                const error ={}
                if(!data || !data.employee_id){
                    error.employee_id ="Required"
                    errorArray[index] = error
                }
            })
            if(errorArray.length){
                errors.dsc_custodians_attributes = errorArray
            }
        }

    }
    if(!('date' in inputs)){
        errors.date = "Required"
    }else if(props.location.data.title === "Custodian Details"){
        if(!compareDates(moment(new Date(props.location.data.last_custodian_change_date)).format("DD-MM-YYYY"),moment(inputs.date).format("DD-MM-YYYY"))){
        errors.date = " Received on date has to be greater or equal to last custdoians received on date "+props.location.data.last_custodian_change_date

        }
    }else{
        if(!compareDates(moment(new Date(props.location.data.last_movement_date)).format("DD-MM-YYYY"),moment(inputs.date).format("DD-MM-YYYY"))){
        errors.date = "Hand over date has to be greater or equal to last receipt date "+props.location.data.last_movement_date
        }

    }
    if(!inputs.movement_reg_details_attributes || !inputs.movement_reg_details_attributes.length){
        errors.movement_reg_details_ = "Please provide receipt details"
    }else{
        const receiptArrayError = []
        inputs.movement_reg_details_attributes.forEach((data,index) =>{
            const receiptErrors={}
            if(!data || !data.name){
                receiptErrors.name = "Required"
                receiptArrayError[index] = receiptErrors
            }
            if(!data || !data.date){
                receiptErrors.date = "Required"
                receiptArrayError[index] = receiptErrors
            }else{
                if(!compareDates(moment(new Date(props.location.data.last_movement_date)).format("DD-MM-YYYY"),moment(data.date).format("DD-MM-YYYY"))){
                    receiptErrors.date = "Received on date has to be greater or equal to last hand over date "+props.location.data.last_movement_date
                     receiptArrayError[index] = receiptErrors
                }

            }
        })
        if(receiptArrayError.length){
            errors.movement_reg_details_attributes = receiptArrayError
        }
    }
    return errors

}
class MovementregisterMovement extends Component{
   constructor(props){
    super(props);
    this.state = {
        message:'',
        loading:false,
        movementRegisterUpdateFailed: true,
        movement_register: {}
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);

   }
    renderError({input, meta, ...props}){
        return(
            meta.error ? <span {...props} style={{ color: 'red' }} >Error : {meta.error}</span>: null
        )
    }

    componentDidUpdate(){
        if(!this.state.movementRegisterUpdateFailed){
            this.props.history.goBack();
        }
    }
    componentWillMount(){
        if(this.props.location.data['title'] && (this.props.location.data['title'] === 'Custodian Details' || this.props.location.data['title'] === "In")){
            this.props.fetchEmployeeDropDownList(this.props.token);
        }
    }
    closeModal(){
        this.setState({
            loading:this.props.loading,
            movementRegisterUpdateFailed:this.props.movementRegisterUpdateFailed
        })
    }
    async onsubmit(formData) {
        formData['movement_register_id']=this.props.location.data['movement_register_id'];
        let dup_data = {...formData};
        formData['in_out_status']=this.props.location.data['title'];
        dup_data['employee_id'] = dup_data['employee_id'] ? formData['employee_id']['id'] : null
        await this.setState({
            loading:true,
            movement_register: formData
        })
        if(this.props.location.data && this.props.location.data['title'] === "Custodian Details"){
            await this.props.dscCustodianUpdate(this.props.token,dup_data);

        }else if(this.props.location.data['title'] === "Out"){
            await this.props.movementRegisterMovement(this.props.token,this.state.movement_register);
        }else{
            let custodian ={};
            custodian[0]=Object.assign({}, formData['dsc_custodians_attributes']['0']);
            custodian[0]['employee_id']= (!this.props.in_office && formData['dsc_custodians_attributes']['0']['employee_id']) ? formData['dsc_custodians_attributes']['0']['employee_id']['id'] : null
            custodian[0]['date'] = formData['movement_reg_details_attributes'][0]['date']
            const new_obj2 = {...this.state.movement_register,dsc_custodians_attributes: custodian}
            await this.setState({
                movement_register: new_obj2
            })
            let movement ={}
            movement[0] = Object.assign({},formData['movement_reg_details_attributes'][0]);
            movement[0]['in_out_status'] = "In";
            const new_obj3 = {...this.state.movement_register,movement_reg_details_attributes: movement}
            new_obj3["skip_encryption"] = true; //becoz password encrypted each time movement registe is updated
            await this.setState({
                movement_register: new_obj3
            })
            await this.props.dscMovementIn(this.props.token,this.state.movement_register,this.props.location.data['movement_register_id'])

        }
        this.setState({
            message:this.props.message
        })
    }
   render(){
       const { handleSubmit,classes } = this.props;
       return(
        <Container>
            <Header title="movement_register_movement" />
            <Typography component="h1" variant="h2" align="center" color="textPrimary" >
                <h1> <Badge variant="dark">{this.props.location.data.title}</Badge></h1>
            </Typography>
           <form onSubmit={handleSubmit(this.onsubmit)}>
           { this.props.location.data && this.props.location.data['title'] === "Custodian Details" ? <div>
                <Row><Col sm={8}>
                    <Field
                        name= "in_office"
                        component={RenderSwitch}
                        //custom props
                        label= "Dsc in Office?"
                        //id={props.id}
                    />
                </Col>

                {!this.props.in_office && <Col sm={4}>
                    <span>Name</span>
                    <Field
                        name='employee_id'
                        props={{
                            type: 'object'
                        }}
                        component={MultiSelectComponent}
                        {...{
                            data:this.props.employeeList,
                            isMulti : false
                        }

                        }
                        fullWidth
                    />
                    </Col>}
                </Row>
                <Row>
                    <Col sm={8}>
                        <span>Date</span>
                        <Field
                        name='date'
                        component = {InputText}
                        type= "date"
                        fullWidth />
                    </Col>
                     <Col sm={4}>
                        <span>Remarks</span>
                        <Field
                            name='remarks'
                            component={InputText}
                            type="text"
                            fullWidth
                        />
                    </Col>
                </Row>
               </div>
            :<div>
            {this.props.location.data['title'] === "Out"? <div>
            <Row>
            <Col sm={8}>
              <Field
                name='name'
                label="Hand over to"
                component={InputText}
                type="text"
                fullWidth
              />
            </Col>

            <Col sm={4}>
                <span>Hand over date</span>
                <Field
                name='date'
                component = {InputText}
                type= "date"
                fullWidth />
            </Col>
        </Row>
        <Row>
            <Col sm={8}>
                <Field
                    name='remarks'
                    label="Remarks on hand over"
                    component={InputText}
                    type="text"
                    fullWidth
                />
            </Col>
        </Row>
            </div>:
            <div>
            <h2> <Badge variant="dark">Receipt Details</Badge></h2>
            <Field name="movement_reg_details_" component={this.renderError} />
        <Row>
            <Col sm={8}>
              <Field
                name={`movement_reg_details_attributes[0].name`}
                label="Received From"
                component={InputText}
                type="text"
                fullWidth
              />
            </Col>

            <Col sm={4}>
                <span>Received on</span>
                <Field
                name={`movement_reg_details_attributes[0].date`}
                component = {InputText}
                type= "date"
                fullWidth />
            </Col>
        </Row>
        <Row>
        <Col sm={8}>
              <Field
                name={`movement_reg_details_attributes[0].remarks`}
                label="Remarks on receipt"
                component={InputText}
                type="text"
                fullWidth
              />
            </Col>


        </Row>
        <h2> <Badge variant="dark">Custodian Details</Badge></h2>
         <Row>
        <Col sm={8}>
             <Field
                name= "in_office"
                component={RenderSwitch}
                //custom props
                label= "Dsc in Office?"
                //id={props.id}
            />
        </Col>
        </Row>
        <Field name="dsc_custodian_" component={this.renderError} />
        <Row>
        {!this.props.in_office && <Col sm={8}>
              <span>Custodian Employee</span>
              <Field
                  name={`dsc_custodians_attributes[0].employee_id`}
                  props={{
                      type: 'object'
                  }}
                  component={MultiSelectComponent}
                  {...{
                      data:this.props.employeeList,
                      isMulti : false
                  }

                  }
                  fullWidth
              />
            </Col>}

            <Col sm={4}>
              <Field
                name={`dsc_custodians_attributes[0].remarks`}
                label="Remarks on custody"
                component={InputText}
                type="text"
                fullWidth
              />
            </Col>
        </Row>

            </div>}</div>}
        <Row>
            <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Changeing movemen register status"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />

        </Row>
        <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>
        </Container>
       )
   }
}
const selector = formValueSelector('MovementregisterMovement')
function mapStateToProps(state) {
    return {
        token: state.auth.token,
        message:state.movementregister.movementStatusChangeMessage,
        loading:state.movementregister.movementStatusChangeLoading,
        movementRegisterUpdateFailed:state.movementregister.movementStatusChangeFailed,
        employeeList:state.employee.employeeDropdownList,
        in_office: selector(state, 'in_office')
    }
}
export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "MovementregisterMovement",
validate }),
    withStyles(styles)
)(MovementregisterMovement);
