import React from 'react';
import Header from '../Header/Header';

const PageTemplate = ({children}) => {
    return (
        <div className="page-template" style={{marginBottom:'55px'}}>
            <Header title="dashboard">
                {children}
            </Header>
        </div>
    );
};

export default PageTemplate;
