import React from 'react';
import { ProgressBar } from 'react-bootstrap';

// COMPONENTS
import CoreUITable from '../components/Layout/Table';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';

// HOOKS
import {
  useCancelFinalInvoice,
  useFinalInvoiceList,
} from '../hooks/invoice-query';

// UTILS
import { AlertComponent } from '../utils/formComponents';

const FinalInvoicePage = () => {
  const finalInvoice = useFinalInvoiceList();
  const mutateFinalInvoice = useCancelFinalInvoice();
  const [invoiceId, setInvoiceId] = React.useState(undefined);
  const [showAlert, setShowAlert] = React.useState(false);

  const options = [
    {
      text: 'View Invoice',
      action: function (id) {
        const win = window.open(`viewInvoice/${id}`, '_blank');
        win.focus();
      },
      params: true,
    },
    {
      text: 'Cancel Invoice',
      action: function (id) {
        setShowAlert(true);
        setInvoiceId(id);
      },
      params: true,
    },
  ];

  const proceedToAbort = () => {
    setShowAlert(false);
    mutateFinalInvoice.mutate(invoiceId);
  };

  if (finalInvoice.isLoading)
    return <ProgressBar striped variant='success' animated now={40} />;

  return (
    <PageTemplate>
      <CoreUITable
        page='final_invoices'
        title='Final Invoices'
        options={options}
        data={finalInvoice.data}
        fields={[
          'id',
          'date',
          'client',
          'organisation',
          'invoice_number',
          {
            key: 'show_details',
            label: '',
            _style: { width: '1%' },
            filter: false,
          },
        ]}
      />

      <AlertComponent
        title='Cancel Final Invoice'
        description={`Are you sure you cancel this final invoice?`}
        open={showAlert}
        cancelText='No, dont proceed'
        saveText='Yes Proceed'
        handleClose={() => {
          setShowAlert(false);
        }}
        proceed={() => proceedToAbort()}
      />
    </PageTemplate>
  );
};

export default FinalInvoicePage;
