import React from "react";
import { useFirmAssetMovement } from "../../hooks/firmAsset-query";
import { useEmployeeDropDown } from "../../hooks/employee-query/employee";
import ProgressBar from "react-bootstrap/ProgressBar";
import { compareDates } from "../../utils/formComponents";
import cloneDeep from "lodash/cloneDeep";
import {
    Row,
    Col,
    Container,
    Badge,
    Card,
    Table,
    Modal,
} from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Formik, Field, Form, ErrorMessage, FieldArray, getIn } from "formik";
import styles from "./index.module.css";
import Switch from "@material-ui/core/Switch";

const selectStyles = {
    menu: (base) => ({
        ...base,
        zIndex: 100,
    }),
};

export function ChangeCustodian({ data, id, show, handleClose, user }) {
    const { data: employees } = useEmployeeDropDown();
    const {
        isLoading: movementLoading,
        mutate,
        data: movementData,
        error: movementError,
    } = useFirmAssetMovement();
    let initialValues = {
        inOffice: true,
        remarks: "",
        date_of_handover: "",
        custodian_employee_id: "",
    };
    if (data) {
        console.log(
            "initialValues",
            initialValues,
            data.firm_asset_details.last_custodian,
            data.firm_asset_details.last_custodian !== "Office"
        );
        initialValues = {
            ...initialValues,
            ...(data.firm_asset_details.last_custodian !== "Office" && {
                inOffice: false,
                custodian_employee_id:
                    data.firm_asset_details.last_custodian_details,
            }),
        };
    }
    React.useEffect(() => {
        console.log("initialValues", initialValues);
        handleClose();
    }, [movementData]);
    function formatAndSubmit(formData) {
        let data = cloneDeep(formData);
        if (data.inOffice) delete data.custodian_employee_id;
        if (data.custodian_employee_id)
            data.custodian_employee_id = data.custodian_employee_id["id"];
        data["created_by_id"] = user.id;
        data["firm_asset_id"] = id;
        console.log("test", data);
        mutate({ ...data });
    }
    function validate(inputs) {
        console.log("validate custodian change", inputs);
        let errors = {};
        if (!inputs.remarks) errors.remarks = "Required";
        if (!inputs.date_of_handover) errors.date_of_handover = "Required";
        if (!inputs.custodian_employee_id && !inputs.inOffice) {
            errors.custodian_employee_id = "Required";
        } else if (
            data.firm_asset_details.last_custodian_details &&
            data.firm_asset_details.last_custodian_details.name ==
                inputs.custodian_employee_id.name && !inputs.inOffice
        ) {
            errors.custodian_employee_id = "Cant be same";
        } else if (data.inOffice == initialValues.inOffice) {
            errors.inOffice = "cant be inoffice";
        }
        if (
            !compareDates(
                moment(
                    new Date(data.firm_asset_details.last_custodian_change_date)
                ).format("DD-MM-YYYY"),
                moment(inputs.date_of_handover).format("DD-MM-YYYY")
            )
        ) {
            errors.date_of_handover =
                "Date has to be greater or equal to last custdoians received on date " +
                data.firm_asset_details.last_custodian_change_date;
        }
        return errors;
    }
    function renderError(error) {
        return (
            <div
                style={{
                    marginTop: "2%",
                    alignItems: "center",
                }}
            >
                {error}
            </div>
        );
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Change custodian</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {movementError && (
                            <Row>
                                <span>{movementError.message}</span>
                            </Row>
                        )}
                        <Formik
                            validate={validate}
                            initialValues={initialValues}
                            onSubmit={async (values) =>
                                formatAndSubmit({ ...values })
                            }
                        >
                            {({ values, errors, touched, setFieldValue }) => (
                                <Form>
                                    <Row>
                                        <Col>
                                            <>
                                                <span>In office</span>{" "}
                                                <Field
                                                    name="inOffice"
                                                    component={Switch}
                                                    checked={values.inOffice}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            "inOffice",
                                                            e.target.checked
                                                        )
                                                    }
                                                    inputProps={{
                                                        "aria-label":
                                                            "controlled",
                                                    }}
                                                />
                                            </>
                                            {errors.inOffice &&
                                                touched.inOffice &&
                                                renderError(errors.inOffice)}
                                        </Col>
                                        <Col>
                                            {!values.inOffice && (
                                                <>
                                                    <span>
                                                        Select Custodian
                                                    </span>
                                                    <Field
                                                        component={Select}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        options={
                                                            employees
                                                                ? employees.employee_list
                                                                : []
                                                        }
                                                        getOptionLabel={(
                                                            option
                                                        ) => option.name}
                                                        getOptionValue={(
                                                            option
                                                        ) => option.id}
                                                        name="Custodian Employee"
                                                        styles={selectStyles}
                                                        value={
                                                            values.custodian_employee_id
                                                                ? values.custodian_employee_id
                                                                : undefined
                                                        }
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "custodian_employee_id",
                                                                e || undefined
                                                            );
                                                        }}
                                                    />
                                                    {errors.custodian_employee_id &&
                                                        touched.custodian_employee_id &&
                                                        renderError(
                                                            errors.custodian_employee_id
                                                        )}
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className={styles.rowPadding}>
                                        <Col>
                                            <span>Remarks</span>
                                            <Field
                                                name="remarks"
                                                type="text"
                                                style={{ width: "100%" }}
                                            />

                                            {errors.remarks &&
                                                touched.remarks &&
                                                renderError(errors.remarks)}
                                        </Col>
                                        <Col>
                                            <span>date of handover</span>
                                            <Field
                                                name="date_of_handover"
                                                type="date"
                                                style={{ width: "100%" }}
                                            />
                                            {errors.date_of_handover &&
                                                touched.date_of_handover &&
                                                renderError(
                                                    errors.date_of_handover
                                                )}
                                        </Col>
                                    </Row>
                                    <Row className={styles.rowPadding}>
                                        {!movementLoading ? (
                                            <>
                                                {" "}
                                                <Button
                                                    variant="secondary"
                                                    onClick={handleClose}
                                                >
                                                    Close
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="primary"
                                                >
                                                    Save Changes
                                                </Button>
                                            </>
                                        ) : (
                                            <ProgressBar
                                                striped
                                                variant="success"
                                                animated
                                                now={40}
                                            />
                                        )}
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </>
    );
}
