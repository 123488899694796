import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Select from 'react-select';
import { Form, Formik } from 'formik';
import { TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { find } from 'lodash';

import Header from '../../components/Layout/Header/Header';

import { useSpecMasterList } from '../../hooks/spec-query/spec';
import { AlertComponent } from '../../utils/formComponents';
import {
  useCreateSpecific,
  useUpdateSpecific,
} from '../../hooks/specifics-query/specific';
import { useProjectSubClass } from '../../hooks/project-query/projects';

const initValues = {
  description: null,
  spec_master_id: null,
  project_sub_class_id: null,
};

const AddSpecifics = () => {
  const router = useHistory();
  const formData = useSelector((state) => state.specmaster.form);

  const specificMutate = useCreateSpecific();
  const specificUpdate = useUpdateSpecific();
  const specQuery = useSpecMasterList();
  const projectQuery = useProjectSubClass();
  const [error, setError] = React.useState(null);
  const [showDetails, setShowDetails] = React.useState(false);

  const specOption = React.useMemo(() => {
    if (specQuery.isLoading) return [];
    return specQuery.data.map((d) => ({ value: d.id, label: d.name }));
  }, [specQuery.isLoading, specQuery.data]);

  const projectOption = React.useMemo(() => {
    if (projectQuery.isLoading) return [];
    return projectQuery.data.map((d) => ({
      value: d.id,
      label: d.name,
    }));
  }, [projectQuery.isLoading, projectQuery.data]);

  const projectOptionValue = () => {
    if (formData) {
      return find(projectOption, { label: formData.project_sub_class });
    }
    return projectOption[0];
  };

  const specOptionValue = () => {
    if (formData) {
      return find(specOption, { label: formData.spec_master });
    }
    return specOption[0];
  };

  const initialValues = React.useMemo(() => {
    const hasForm = formData ? formData : false;
    if (hasForm) {
      const initForm = Object.keys(formData).reduce((acc, cum) => {
        if (cum === 'project_sub_class') {
          return {
            ...acc,
            project_sub_class_id: find(projectOption, {
              label: formData.project_sub_class,
            }),
          };
        }
        if (cum === 'spec_master') {
          return {
            ...acc,
            spec_master_id: find(specOption, { label: formData.spec_master }),
          };
        }
        return {
          ...acc,
          [cum]: formData[cum],
        };
      }, {});
      delete initForm.id;
      return initForm;
    }
    return initValues;
  }, [formData, projectOption, specOption]);

  return (
    <Container>
      <Header title='add_specific' />
      <Typography
        component='h1'
        variant='h2'
        align='center'
        color='textPrimary'
        gutterBottom
      >
        Add Specific
      </Typography>
      <Row>
        <Col sm={4}>
          <Typography
            component='h6'
            variant='h6'
            align='center'
            color='error'
            gutterBottom
          >
            {error}
          </Typography>
        </Col>
      </Row>
      <Formik
        initialValues={initialValues}
        onSubmit={async (data) => {
          if (formData) {
            const form = {
              id: formData.id,
              data,
            };
            specificUpdate.mutate(form, {
              onSuccess: () => {
                setShowDetails(true);
                router.goBack();
              },
              onError: (err) => {
                setError(err.message);
              },
            });
          } else {
            specificMutate.mutate(data, {
              onSuccess: () => {
                setShowDetails(true);
                router.goBack();
              },
              onError: (err) => {
                setError(err.message);
              },
            });
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Row>
              <Col sm={6}>
                <label style={{ marginBottom: '16px' }}>Description</label>
                <TextField
                  type='text'
                  fullWidth
                  margin='normal'
                  autoFocus
                  id='description'
                  variant='outlined'
                  label='description '
                  defaultValue={formData && formData.description}
                  required
                  onChange={(e) => setFieldValue('description', e.target.value)}
                />
              </Col>
              <Col sm={6}>
                <label
                  id='field_type'
                  style={{ marginTop: '13px', marginBottom: '16px' }}
                >
                  Specmaster
                </label>
                {!specQuery.isLoading && (
                  <Select
                    id='spec_master_id'
                    required
                    options={specOption}
                    defaultValue={specOptionValue()}
                    onChange={(e) => setFieldValue('spec_master_id', e.value)}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <label
                  id='field_type'
                  style={{ marginTop: '12px', marginBottom: '16px' }}
                >
                  Project Sub
                </label>
                {!projectQuery.isLoading && (
                  <Select
                    id='project_sub_class_id'
                    required
                    options={projectOption}
                    defaultValue={projectOptionValue()}
                    onChange={(e) =>
                      setFieldValue('project_sub_class_id', e.value)
                    }
                  />
                )}
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col>
                <Row className='float-right'>
                  <Col>
                    <Button type='submit' variant='contained' color='primary'>
                      {formData ? 'UPDATE' : 'ADD'}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      <AlertComponent
        title='Saving'
        description={'Spec master successfully created'}
        saveText={'OK'}
        open={showDetails}
        proceed={() => {
          setShowDetails(false);
          router.goBack();
        }}
      />
    </Container>
  );
};

export default AddSpecifics;
