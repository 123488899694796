import React, { Component } from "react";
import { Row, Col, Container, Badge, Table } from "react-bootstrap";
import {
    reduxForm,
    Field,
    FieldArray,
    reset,
    initialize,
    formValueSelector,
    change,
} from "redux-form";
import { compose } from "redux";
import ClearIcon from "@material-ui/icons/Clear";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
    InputText,
    SelectInput,
    TextArea,
    validate,
    SavingDetailsModal,
    SelectState,
    SelectDesignation,
    SelectManagementStatus,
    RenderSwitch,
} from "../../utils/formComponents";
import MultiSelectComponent from "../../utils/multiselect";
import Header from "../../components/Layout/Header/Header";
import styles from "../modelstyles";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core";
import { PhoneNumber } from "../../utils/formComponents";
class AddClient extends Component {
    renderTanField({ fields, meta: { touched, error } }) {
        return (
            <Container>
                <Row style={{ width: "70%", marginTop: "3%" }}>
                    <Col sm={4}>
                        <h5>Tan details</h5>
                    </Col>{" "}
                </Row>
                <Table responsive style={{ width: "70%", marginTop: "2%" }}>
                    <thead>
                        <tr>
                            <th style={{ width: "30%" }}>TAN</th>
                            <th>Remarks</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td sm={5}>
                                <Button
                                    onClick={() => {
                                        fields.push({});
                                    }}
                                >
                                    Add
                                </Button>
                            </td>
                        </tr>
                        {fields.length > 0 &&
                            fields.map((tan, index) => (
                                <tr
                                    style={{
                                        marginTop: "2%",
                                        alignItems: "center",
                                    }}
                                >
                                    <td>
                                        <Field
                                            name={`${tan}.tan`}
                                            type="text"
                                            component={InputText}
                                            fullwidth
                                        />
                                    </td>
                                    <td>
                                        <Field
                                            name={`${tan}.remarks`}
                                            type="text"
                                            component={InputText}
                                            fullwidth
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            onClick={() => fields.remove(index)}
                                        >
                                            <ClearIcon />
                                        </Button>
                                    </td>
                                    <td></td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
                {touched && error && <span>{error}</span>}
            </Container>
        );
    }

    renderGstinField({ fields, meta: { touched, error } }) {
        return (
            <Container>
                {fields.map((gstin, index) => (
                    <div key={index}>
                        <h4>GSTIN {index + 1}</h4>
                        <Row>
                            <Col>
                                <span>State</span>
                                <Field
                                    name={`${gstin}.state`}
                                    type="text"
                                    component={SelectState}
                                    label="state"
                                    fullwidth
                                />
                            </Col>

                            <Col>
                                <span>GSTIN</span>
                                <Field
                                    name={`${gstin}.gstin`}
                                    type="text"
                                    component={InputText}
                                    label="GSTIN"
                                    fullwidth
                                />
                            </Col>
                            <Col>
                                <span>Taxable</span>
                                <Field
                                    name={`${gstin}.taxable`}
                                    type="text"
                                    component={SelectInput}
                                    label="taxable"
                                    fullwidth
                                />
                            </Col>

                            <Button onClick={() => fields.remove(index)}>
                                <ClearIcon />
                            </Button>
                        </Row>
                    </div>
                ))}
                <div>
                    <Button
                        onClick={() => fields.push({})}
                        color="primary"
                        variant="contained"
                    >
                        Add Gstin
                    </Button>
                    {touched && error && <span>{error}</span>}
                </div>
            </Container>
        );
    }
    renderManagementField({ fields, meta: { touched, error } }) {
        return (
            <Container>
                {fields.map((management, index) => (
                    <div key={index}>
                        <h4>Contact Details {index + 1}</h4>
                        <Row>
                            <Col>
                                <Field
                                    name={`${management}.name`}
                                    type="text"
                                    component={InputText}
                                    label="Name*"
                                />
                            </Col>
                            <Col>
                                <Field
                                    name={`${management}.din`}
                                    type="number"
                                    component={InputText}
                                    label="DIN"
                                />
                            </Col>
                            <Col>
                                <Field
                                    name={`${management}.address`}
                                    type="text"
                                    component={InputText}
                                    label="Address"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field
                                    name={`${management}.pin`}
                                    type="number"
                                    component={InputText}
                                    label="PIN*"
                                />
                            </Col>
                            <Col>
                                <Field
                                    name={`${management}.city`}
                                    type="text"
                                    component={InputText}
                                    label="City*"
                                />
                            </Col>
                            <Col>
                                <span>State</span>
                                <Field
                                    name={`${management}.state`}
                                    component={SelectState}
                                    fullwidth
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field
                                    name={`${management}.pan`}
                                    type="text"
                                    component={InputText}
                                    label="PAN"
                                />
                            </Col>
                            <Col>
                                <Field
                                    name={`${management}.aadhaar`}
                                    type="text"
                                    component={InputText}
                                    label="Aadhaar"
                                />
                            </Col>
                            <Col>
                                <span>Designation</span>
                                <Field
                                    name={`${management}.designation`}
                                    component={SelectDesignation}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Field
                                    name={`${management}.contact`}
                                    label="Contact Number"
                                    component={PhoneNumber}
                                />
                            </Col>
                            <Col>
                                <Field
                                    name={`${management}.email`}
                                    type="text"
                                    component={InputText}
                                    label="Email*"
                                />
                            </Col>
                            <Col>
                                <span>Status</span>
                                <Field
                                    name={`${management}.status`}
                                    component={SelectManagementStatus}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Button onClick={() => fields.remove(index)}>
                                <ClearIcon />
                            </Button>
                        </Row>
                    </div>
                ))}

                <div>
                    <Button
                        onClick={() => fields.push({})}
                        color="primary"
                        variant="contained"
                    >
                        Add Contact Details
                    </Button>
                    {touched && error && <span>{error}</span>}
                </div>
            </Container>
        );
    }

    renderCredentialsField({ fields, meta: { touched, error } }) {
        return (
            <Container>
                {fields.map((credential, index) => (
                    <div key={index}>
                        <h4>Credentials {index + 1}</h4>
                        <Row>
                            <Field
                                name={`${credential}.portal`}
                                type="text"
                                component={InputText}
                                label="Portal"
                            />

                            <Field
                                name={`${credential}.username_digest`}
                                type="text"
                                component={InputText}
                                label="User Name"
                            />
                            <Field
                                name={`${credential}.password_digest`}
                                type="text"
                                component={InputText}
                                label="Password"
                            />
                            <Field
                                name={`${credential}.remarks`}
                                type="text"
                                component={InputText}
                                label="remarks"
                            />

                            <Button onClick={() => fields.remove(index)}>
                                <ClearIcon />
                            </Button>
                        </Row>
                    </div>
                ))}

                <div>
                    <Button
                        onClick={() => fields.push({})}
                        color="primary"
                        variant="contained"
                    >
                        Add Credential
                    </Button>
                    {touched && error && <span>{error}</span>}
                </div>
            </Container>
        );
    }

    constructor(props) {
        super(props);
        this.state = {
            message: "",
            loading: false,
            clientAddFailed: true,
            clientClass: "",
            nested_attributes: {
                client_credentials_attributes: [],
                client_gstins_attributes: [],
                management_details_attributes: [],
                client_tans_attributes: [],
            },
            client_data: {},
        };

        this.onsubmit = this.onsubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    componentDidUpdate(prevProps) {
        setTimeout(() => this.setState({ message: "" }), 9000);
        if (!this.state.clientAddFailed) {
            this.props.history.goBack();
        }
        if (this.props.clientClassId !== prevProps.clientClassId) {
            var result = this.props.dclientclassList.filter((obj) => {
                return obj.id === this.props.clientClassId;
            });
            this.setState({
                clientClass: result[0] !== undefined ? result[0].name : null,
            });
        }
    }
    closeModal() {
        this.setState({
            loading: this.props.loading,
            clientAddFailed: this.props.clientAddFailed,
        });
    }
    async onsubmit(formData) {
        if (this.state.clientClass === "LLP" && formData["cin"])
            delete formData["cin"];
        if (this.state.clientClass === "Company" && formData["llpin"])
            delete formData["llpin"];
        if (
            this.props.contractType !== "Subcontract" &&
            formData["main_contractor_id"]
        )
            delete formData["main_contractor_id"];
        this.setState({
            loading: true,
            client_data: { ...formData },
        });
        await this.setState((prevState) => ({
            client_data: {
                ...prevState.client_data,
                lead_partner_id: formData["lead_partner_id"]["id"],
                country_id: formData['country_id']['id'],
                industry_id: formData['industry_id']['id'],
                assignment_partner_id: formData["assignment_partner_id"]["id"],
                ...(formData["main_contractor_id"] && {
                    main_contractor_id: formData["main_contractor_id"]["id"],
                }),
                ...(this.props.location.data && {
                    updated_by_id: this.props.user.id,
                }),
            },
        }));
        //formData['lead_partner_id']= formData['lead_partner_id']['id']
        if (!this.props.location.data) {
            await this.props.addClient(
                this.state.client_data,
                this.props.token
            );
        } else {
            await this.props.updateClient(
                this.props.details.id,
                this.state.client_data,
                this.props.token,
                this.state.nested_attributes
            );
        }
        this.setState({
            message: this.props.message,
        });
    }
    componentDidMount() {
        this.props.fetchClientClassList(this.props.token);
        this.props.fetchPartnerList(this.props.token);
        this.props.fetchCountries();
        this.props.fetchIndustries();
        this.props.fetchClientList(this.props.token);
        if (this.props.location.data) {
            this.props
                .fetchClientDetails(
                    this.props.location.data.id,
                    this.props.token,
                    "client"
                )
                .then((resp) => {
                    this.props.initialize(resp);
                    if (resp) {
                        this.setState({
                            nested_attributes: {
                                client_credentials_attributes:
                                    resp["client_credentials_attributes"],
                                client_gstins_attributes:
                                    resp["client_gstins_attributes"],
                                management_details_attributes:
                                    resp["management_details_attributes"],
                                client_tans_attributes:
                                    resp["client_tans_attributes"],
                            },
                        });
                    }
                    //this.props.dispatch(initialize('AddClient',resp))
                });
        } else {
            this.props.dispatch(reset("AddClient"));
            var d = new Date();
            var n = d.getFullYear();
            this.props.dispatch(change("AddClient", "association_year", n));
            this.props.dispatch(change("AddClient", "client_status", "Active"));
        }
    }
    render() {
        const { handleSubmit, classes } = this.props;
        let state = this.state;
        return (
            <Container>
                <Header title="add_client" />
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                >
                    <h1>
                        {" "}
                        <Badge variant="dark"> Add Client</Badge>
                    </h1>
                </Typography>
                <h2>
                    {" "}
                    <Badge variant="dark">Basic Details</Badge>
                </h2>
                <form onSubmit={handleSubmit(this.onsubmit)}>
                    <Row>
                        <Col sm={8}>
                            <span>Client Short Name</span>
                            <Field
                                name="short_name"
                                label="Short Name *"
                                component={InputText}
                                type="text"
                                fullWidth
                            />
                        </Col>
                        <Col sm={4}>
                            <span>Client Name</span>
                            <Field
                                name="name"
                                label="Name *"
                                component={InputText}
                                type="text"
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <span>Select Client Class</span>
                            <Field
                                name="client_class_id"
                                label=""
                                component={SelectInput}
                                fullWidth
                                data={this.props.dclientclassList}
                            />
                        </Col>
                        <Col sm={4}>
                            <span>Group</span>
                            <Field
                                name="group"
                                label="Group *"
                                component={InputText}
                                type="text"
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                            <Col sm={8}>
                                <span>Select Country</span>
                                <Field
                                    name="country_id"
                                    label=""
                                    props={{
                                        type: "object",
                                    }}
                                    component={MultiSelectComponent}
                                    {...{
                                        data: this.props.countries,
                                        isMulti: false,
                                    }}
                                    fullWidth
                                />
                            </Col>
                            <Col sm={4}>
                                <span>Select Industry</span>
                                <Field
                                    name="industry_id"
                                    label=""
                                    props={{
                                        type: "object",
                                    }}
                                    component={MultiSelectComponent}
                                    {...{
                                        data: this.props.industries,
                                        isMulti: false,
                                    }}
                                    fullWidth
                                />
                            </Col>
                        </Row>

                    <Row>
                        <Col sm={8}>
                            <span>Contract type</span>
                            <Field
                                name="contract_type"
                                label=""
                                component={SelectInput}
                                fullWidth
                                data={[
                                    { name: "Direct", id: "Direct" },
                                    { name: "Sub-Contract", id: "Subcontract" },
                                ]}
                            />
                        </Col>
                        {this.props.contractType === "Subcontract" && (
                            <Col sm={4}>
                                <span>Select main contractor</span>
                                <Field
                                    name="main_contractor_id"
                                    label=" "
                                    props={{
                                        type: "object",
                                    }}
                                    component={MultiSelectComponent}
                                    {...{
                                        data: this.props.dclients,
                                        isMulti: false,
                                    }}
                                    fullWidth
                                />{" "}
                            </Col>
                        )}
                        </Row>
                                            <Row></Row>
                    <Divider />
                    <h2>
                        {" "}
                        <Badge variant="dark">Contact</Badge>
                    </h2>
                    <Row>
                        <Col sm={8}>
                            <span>Contact Person</span>
                            <Field
                                name="contact_person"
                                label="Contact Person"
                                component={InputText}
                                type="text"
                                fullWidth
                            />
                        </Col>
                        <Col sm={4}>
                            <span>Contact Number</span>
                            <Field
                                name="contact_no"
                                label="Contact Number"
                                component={PhoneNumber}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <span>Address</span>
                            <Field
                                name="address"
                                label="Address *"
                                component={InputText}
                                type="text"
                                fullWidth
                            />
                        </Col>

                        <Col sm={4}>
                            <span>Pin Code</span>
                            <Field
                                name="pin"
                                label="Pin Code *"
                                component={InputText}
                                type="number"
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <span>City</span>
                            <Field
                                name="city"
                                label="City *"
                                component={InputText}
                                type="text"
                                fullWidth
                            />
                        </Col>
                        <Col sm={4}>
                            <span>Select State</span>
                            <Field
                                name="state"
                                label=""
                                component={SelectInput}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <span>District</span>
                            <Field
                                name="district"
                                label="District *"
                                component={InputText}
                                type="text"
                                fullWidth
                            />
                        </Col>
                        <Col sm={4}>
                            <span>Email</span>
                            <Field
                                name="email"
                                label="Email *"
                                component={InputText}
                                type="text"
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Divider />
                    <h4>
                        <Badge variant="dark">Statutory Details</Badge>
                    </h4>
                    <Row>
                        <Col sm={8}>
                            <span>PAN Number</span>
                            <Field
                                name="pan"
                                label="PAN Number *"
                                component={InputText}
                                type="text"
                                fullWidth
                            />
                        </Col>
                        {this.state.clientClass === "Company" && (
                            <Col sm={4}>
                                <span>cin</span>
                                <Field
                                    name="cin"
                                    label="CIN"
                                    component={InputText}
                                    fullWidth
                                />
                            </Col>
                        )}
                        {this.state.clientClass === "LLP" && (
                            <Col sm={4}>
                                <span>LLPIN</span>
                                <Field
                                    name="llpin"
                                    label="LLPIN"
                                    component={InputText}
                                    fullWidth
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col sm={8}>
                            {this.state.clientClass === "Individual" && (
                                <Field
                                    name="aadhaar"
                                    type="text"
                                    component={InputText}
                                    label="Aadhaar"
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <FieldArray
                            name="client_gstins_attributes"
                            component={this.renderGstinField}
                        />
                    </Row>
                    <Divider />
                    <h4>
                        <Badge variant="dark">Contact Details</Badge>
                    </h4>
                    <Row>
                        <FieldArray
                            name="management_details_attributes"
                            component={this.renderManagementField}
                        />
                    </Row>
                    <Divider />
                    <h4>
                        <Badge variant="dark">Credentials</Badge>
                    </h4>
                    <Row>
                        <FieldArray
                            name="client_credentials_attributes"
                            component={this.renderCredentialsField}
                        />
                    </Row>
                    <Divider />
                    <h4>
                        <Badge variant="dark">TAN Details</Badge>
                    </h4>
                    <Row>
                        <FieldArray
                            name="client_tans_attributes"
                            component={this.renderTanField}
                        />
                    </Row>

                    <Divider />

                    <h4>
                        <Badge variant="dark">Internal</Badge>
                    </h4>
                    <Row>
                        <Col sm={8}>
                            <span>Association Year</span>
                            <Field
                                name="association_year"
                                label=""
                                component={InputText}
                                type="number"
                                fullWidth
                            />
                        </Col>
                        <Col sm={4}>
                            <span>Select Client Risk</span>
                            <Field
                                name="client_risk"
                                label=""
                                component={SelectInput}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <span>Lead Channel</span>
                            <Field
                                name="lead_channel"
                                label="Lead Channel"
                                type="text"
                                component={InputText}
                                fullWidth
                            />
                        </Col>
                        <Col sm={4}>
                            <span>Select Client Status</span>
                            <Field
                                name="client_status"
                                label=""
                                component={SelectInput}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <span>Select Lead Partner</span>
                            <Field
                                name="lead_partner_id"
                                label=""
                                props={{
                                    type: "object",
                                }}
                                component={MultiSelectComponent}
                                {...{
                                    data: this.props.leadpartners,
                                    isMulti: false,
                                }}
                                fullWidth
                            />
                        </Col>
                        <Col sm={4}>
                            <span>Select Assignment Partner</span>
                            <Field
                                name="assignment_partner_id"
                                label=""
                                props={{
                                    type: "object",
                                }}
                                component={MultiSelectComponent}
                                {...{
                                    data: this.props.leadpartners,
                                    isMulti: false,
                                }}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <SavingDetailsModal
                                className={classes.modal}
                                show={this.state.loading}
                                title={"Saving Client Details"}
                                loading={this.props.loading}
                                message={this.props.message}
                                onClick={this.closeModal}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={8}>
                            <Field
                                name="internal_client"
                                component={RenderSwitch}
                                //custom props
                                label="Internal client?"
                                //id={props.id}
                            />
                        </Col>
                        <Col sm={4}>
                            <span>Client relation remarks</span>
                            <Field
                                name="client_relation_remarks"
                                label="Remarks"
                                type="text"
                                component={InputText}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col>
                            <Row className="float-right">
                                <Col>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {this.props.location.data
                                            ? "Update"
                                            : "Add"}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </form>
            </Container>
        );
    }
}
const selector = formValueSelector("AddClient");
function mapStateToProps(state) {
    return {
        dclientclassList: state.clientClass.dclientclassList,
        loading: state.client.clientAddLoading,
        clientAddFailed: state.client.clientAddFailed,
        token: state.auth.token,
        user: state.auth.user,
        message: state.client.clientAddMessage,
        leadpartners: state.employee.partnerList,
        countries: state.countries.countries,
        industries: state.industries.industries,
        details: state.client.client_details,
        dclients: state.client.dclientList,
        clientClassId: selector(state, "client_class_id"),
        contractType: selector(state, "contract_type"),
    };
}

export default compose(
    withRouter,
    connect(mapStateToProps, actions),
    // withStyles(styles),
    reduxForm({
        form: "AddClient",
        validate,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    }),
    withStyles(styles)
)(AddClient);
