import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CommonList from '../views/CommonTableView';
const OutwardRegisterPage = () => {
    return (
        <PageTemplate>
 <CommonList page={"outward_register"}/>
        </PageTemplate>
    );
};

export default OutwardRegisterPage;

