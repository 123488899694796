import React, { useEffect, useState, useReducer, useCallback } from "react";
import {
    Row,
    Col,
    Container,
    Badge,
    Table,
    Modal,
    Button,
} from "react-bootstrap";
import Header from "../../components/Layout/Header/Header";
import Select from "react-select";
import { useOrganisation } from "../../hooks/organisation-query";
import { useInvoiceReport } from "../../hooks/invoice-query";
import CoreUITable from "../../components/Layout/Table";
import { jsonTocsv, downloadCsv } from "../../utils/jsonTocsv";
const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value,
    };
};
const options = [
    { value: "gst_summary_report", label: "Gst Summary Report" },
    { value: "gst_detailed_report", label: "Gst Detailed Report" },
    { value: "hsn", label: "hsn" },
];

function ViewReoprt(props) {
    return (
        <>
                {props.isLoading && <h4>{props.message}</h4>}
                {props.data && props.data.length == 0 && (
                    <h4>Sorry, no invoices present in between these dates</h4>
                )}
                {props.data && props.data.length > 0 && (
                    <>
                        <CoreUITable
                            page="invoice_reports"
                            title="Gst report"
                            data={props.data}
                            options={[]}
                            fields={['id', 'date','invoice_number','client', 'gstin', 'organisation', 'base_amount', 'ope','cgst','sgst','igst', 'kfc','ccess','grand_total']}
                        />
                    </>
                )}
            <Row>
                <Col sm={4}><Button onClick={props.onHide}>Close</Button></Col>
                {props.data && props.data.length > 0 && (
                <Col sm={4}><Button onClick={props.downloadCsv}>Download csv</Button></Col>
                )}
            </Row>
        </>
    );
}

export default function GstnReport(props) {
    const [formData, setFormData] = useReducer(formReducer, {});
    //https://www.digitalocean.com/community/tutorials/how-to-build-forms-in-react
    const { isLoading, mutate, data, error } = useInvoiceReport();
    const orgQuery = useOrganisation();
    const [message, setMessage] = React.useState("");
    const [modalShow, setModalShow] = React.useState(false);

    const handleChange = (event, name=null) => {//name for react-select field name
        if(modalShow) setModalShow(false)
        if (event.target) {
            setFormData({
                name: event.target.name,
                value: event.target.value,
            });
        } else {
            setFormData({
                name: name,
                value: event.value,
            });
        }
    };

    const orgOptions = React.useMemo(() => {
        if (orgQuery.isLoading) return [];
        return orgQuery.data.map((d) => ({
            value: d.id,
            label: d.organisation_code,
        }));
    }, [orgQuery.isLoading, orgQuery.data]);

    const generateCsv = useCallback(() => {
        if (data.length && data.length > 0) {
            let temp = jsonTocsv(data);
            downloadCsv(
                temp,
                `${formData["report_type"]}_${formData["from"]}_to_${formData["to"]}.csv`,
                "text/csv;charset=utf-8;"
            );
        }
    }, [data]);
    React.useEffect(() => {
        if (isLoading) setMessage("Loading report");
        if (error) setMessage(error);
    }, [isLoading, data, error]);

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("values", formData);
        mutate({ ...formData });
        setModalShow(true);
    };
    return (
        <Container>
            <Header title="gst_report" />
            <h1 style={{ marginLeft: "20%" }}>
                {" "}
                <Badge variant="dark">Gst Report</Badge>{" "}
            </h1>
            <Row style={{ marginTop: "5%" }}></Row>
            <form onSubmit={handleSubmit}>
                <fieldset>
                     <Row style={{ alignItems: "center" }}>
                        <Col sm={4}>
                            <span>Organisation</span>
                        </Col>
                        <Col sm={4}>
                            <Select
                                options={orgOptions}
                                name="organisation_id"
                                onChange={(e)=>handleChange(e, "organisation_id")}
                            />
                        </Col>
                    </Row>

                    <Row style={{ alignItems: "center", marginTop: "5%"}}>
                        <Col sm={4}>
                            <span>Report type</span>
                        </Col>
                        <Col sm={4}>
                            <Select
                                options={options}
                                name="report_type"
                            onChange={(e)=>handleChange(e, "report_type")}
                            />
                        </Col>
                    </Row>
                    <Row style={{ alignItems: "center", marginTop: "5%" }}>
                        <Col sm={4}>
                            <span>From date</span>
                        </Col>
                        <Col sm={4}>
                            <input
                                name="from"
                                type="date"
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row style={{ alignItems: "center", marginTop: "5%" }}>
                        <Col sm={4}>
                            <span>To date</span>
                        </Col>
                        <Col sm={4}>
                            <input
                                name="to"
                                type="date"
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                </fieldset>
                <Button
                    style={{ alignItems: "center", marginTop: "5%" }}
                    type="submit"
                    primary
                >
                    View
                </Button>
            </form>
            {modalShow && <ViewReoprt
                data={data}
                isLoading={isLoading}
                message={message}
                onHide={() => setModalShow(false)}
                downloadCsv={() => {
                    generateCsv();
                    setModalShow(false);
                }}
            />}
        </Container>
    );
}
