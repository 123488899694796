import React, {Component} from 'react';
import { Row, Col,Container,Badge,Card,Table } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Header from "../../components/Layout/Header/Header";
import styles from '../modelstyles';
import { withStyles } from '@material-ui/core';
import * as actions from "../../actions";
import { connect } from 'react-redux';
import { compose } from "redux";
import {withRouter} from 'react-router-dom';
class ProjectDetails extends Component {
    constructor(props){
        super(props);
        if (this.props.location.data){
            this.props.fetchProjectDetails(this.props.location.data.id,this.props.token)
        }

    }
    render(){
        const {classes} = this.props;
        return(
            <Container>
            <Header title="project_details"/>
            <Card>
             <Card.Header className="text-center">Project Details</Card.Header>
                <Card.Body>
                <Row>
                    <Col>
                        <Col>Name {this.props.project.name}</Col>
                    </Col>

                    <Col>
                        <Col>Client {this.props.project['client_id'] != undefined ? this.props.project.client_id['name']:null}</Col>
                    </Col>

                    <Col>
                        <Col>In charge Employee {this.props.project['in_charge_employee_id'] != undefined ? this.props.project.in_charge_employee_id['name']: null}</Col>
                    </Col>
                </Row>
                <Row>
                        Description  {this.props.project.desciption}
                </Row>

                <Row>
                    <Col>
                        <Col>In charge Partner {this.props.project['in_charge_partner_id'] != undefined ? this.props.project.in_charge_partner_id['name']:null}</Col>
                    </Col>

                    <Col>
                          <Col>Approvel Status {this.props.project.approvel_status +""}</Col>
                    </Col>

                    <Col>
                        <Col> Project Status {this.props.project.status +""}</Col>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Col>Project Category {this.props.project['project_category_id'] != undefined ?this.props.project['project_category_id']['name']:null}</Col>
                    </Col>

                    <Col>
                          <Col>Udin Mandatory {this.props.project.udin_mandatory}</Col>
                    </Col>
                    <Col>
                    Organisaion {this.props.project.organisaion_id != undefined ?this.props.project.organisaion_id['name']: null}
                    </Col>

                </Row>

                <Row>
                    <Col>
                        <Col>Any deliverables {this.props.project.any_deliverables +""}</Col>
                    </Col>

                    <Col>
                          <Col>Due Date {this.props.project.due_date}</Col>
                    </Col>

                    <Col>
                        <Col>Bidgeted Bill Amount {this.props.project.budgeted_bill_amount}</Col>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Col>Billables {this.props.project.billable +""}</Col>
                    </Col>

                </Row>
            </Card.Body>
            </Card>
            <Row></Row>

            <Card>
             <Card.Header className="text-center">Team Members</Card.Header>
                <Card.Body>
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.project.project_members_attributes  && this.props.project.project_members_attributes.length ? this.props.project.project_members_attributes.map((item,key)=>
                    <tr>
                    <td>{item.emp_id}</td>
                    <td>{item.name}</td>
                    </tr>):null}
                </tbody>
            </Table>
            </Card.Body>
            </Card>
            </Container>
        )
    }
}
function mapStateToProps(state){
    return{
        token:state.auth.token,
        project:state.project.project,
    }
}
export default compose(withRouter,connect(mapStateToProps,actions),  withStyles(styles))(ProjectDetails);
