import React from 'react';
import { Link, Text, View, StyleSheet,Font } from '@react-pdf/renderer';
import Ampersand from './helper'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
        justifyContent: 'space-between'
  },
    headerFirstRow:{
        //justifyContent: 'flex-end',
        justifyContent: 'stretch'
    },
    headerSecondRow: {
        //flexDirection: 'column',
        //flexGrow: 9,
        //alignSelf: 'flex-end',
        justifySelf: 'flex-end',
    },
    headerThirdRow:{
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        //alignItems: 'center'
    },
    name: {
        fontSize: 20,
        fontFamily: 'James',
        textAlign: 'right'
    },
    subName:{
        fontSize: 13.5,
        fontFamily: "James",
        textAlign: 'right'
    },
    invoiceHeader:{
        fontFamily:"Open Sans",
        fontSize: 14
    },
    address: {
        fontFamily:"'Open Sans',light",
        fontSize: 8,
        textAlign: 'right'
  }
})

export default ({props, nature})=>(
    <View style={styles.container}>
        <View styel = {styles.headerFirstRow}>
            {props.code === 'JNR' ? <Text style={styles.name}>JOSEPH <Ampersand /> RAJARAM</Text>:
            <Text style={[styles.name,{fontSize: 25.5}]}>JAMES <Ampersand /> JAMES</Text>}
        </View>
        <View style = {styles.headerSecondRow}>
            {props.code !== 'JJBS' ? <Text style={styles.subName}>CHARTERED ACCOUNTANTS</Text>:
            <Text style={[styles.subName,{fontSize: 14}]}>BUSSINESS SOLUTIONS LLP</Text>}
        </View>
        <View style = {styles.headerThirdRow}>
            <View><Text style={styles.invoiceHeader}>{nature == "E" ? `Export Invoice` : `Invoice`}</Text></View>
            <View style={{width: '20%'}}>
                <Text style={styles.address}>{props.address}</Text>
                <Text style={styles.address}>{`${props.city}, ${props.state} - ${props.pin}`}</Text>
                <Text style={styles.address}>{`Tel:${props.contact_no}`}</Text>
            </View>
        </View>



    </View>


    )

