import axios from "../../axiosConfig";
import { useQuery } from "react-query";

async function getClients() {
  try {
    const res = await axios.get(`/client`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getClientDetailsList() {
  try {
    const res = await axios.get(`/client_list`);
    return res.data["client"];
  } catch (err) {
    throw err.response.data;
  }
}

async function getRoles() {
  try {
    const res = await axios.get(`/access_roles`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

export const useClients = () => useQuery("clients", getClients);

export const useClientDetailList = () =>
  useQuery("clientDetailList", getClientDetailsList);

export const useRoles = () => useQuery("roles", getRoles);
