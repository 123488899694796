export const filterOpeOnSelection =(invoiceOpeAvailable, selected)=>{
    //remove from available ope list, also remove whole section if this was the
    //only ope
    let index = invoiceOpeAvailable.findIndex(item=> item.project_id === selected.project_id)
    console.log("filterOpeOnSelection", invoiceOpeAvailable,selected,index)
    if(index >=0){
        if(invoiceOpeAvailable[index].options.length > 0){
            if(invoiceOpeAvailable[index].options.length >1){
                return invoiceOpeAvailable[index]['options'].filter(item=> item.id !== selected.id)
            }else{
                return invoiceOpeAvailable.filter(item=> item.project_id !== selected.project_id)
            }
        }else{
            return invoiceOpeAvailable.filter(item=> item.project_id !== selected.project_id)
        }
    }else{
        return [...invoiceOpeAvailable]
    }
}
export const undoOpeSelection =(state, payload)=>{
    //1 . remove from slected ope list(already done in reducer itself)
    //2 . add to available list
    let index = state.invoiceOpeAvailable.findIndex(item=> item.project_id === payload.project_id)
    if(index >=0){
        let alreadyPresent = state.invoiceOpeAvailable[index]['options'].findIndex(item => item.id === payload.id)
        if(alreadyPresent < 0){
            return {
                    ...state,
                    invoiceOpeAvailable: {
                    ...state.invoiceOpeAvailable,
                    options: [
                        ...state.invoiceOpeAvailable.options,
                        payload
                    ]
                }
            }
        }
        return [...state.invoiceOpeAvailable]
    }else{
        let item = {
            label: payload.label,
            project_id: payload.project_id,
            options:[{
                id: payload.id,
                name: payload.name,
                ope_amount: payload.ope_amount,
                label: payload.label,
                project_id: payload.project_id
            }]
        }
        return [...state.invoiceOpeAvailable, item]
    }
}
//below fn unwanted as we are going to load ope list according to client so ope
//list is independent of project, also this fn can be used if there is option to
//reload the ope list(simply to load latest opes)
export const initialOpeFilter =(invoiceOpeAvailable, invoiceOpeSelected)=>{
    let temp = [...invoiceOpeAvailable]
    invoiceOpeSelected.forEach((item)=>{
       temp  = filterOpeOnSelection(temp,item)
    })
    return [...temp]
}
