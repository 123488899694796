import React, { Component } from 'react';
import { Row, Col, Container, Badge, Table, Card } from 'react-bootstrap';
import {
  reduxForm,
  Field,
  change,
  formValueSelector,
  FieldArray,
  getFormValues,
} from 'redux-form';
import { compose } from 'redux';
import Header from '../../components/Layout/Header/Header';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import styles from '../modelstyles';
import { withRouter } from 'react-router-dom';
import { withStyles, Divider } from '@material-ui/core';
import {
  InputText,
  SelectInput,
  validate,
  SavingDetailsModal,
  CustomValidationAlert,
  SnackbarAlert,
} from '../../utils/formComponents';
import MultiSelectComponent from '../../utils/multiselect';
import * as recurringData from '../../assets/recurring.json';
import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear';
class AddProject extends Component {
  renderMemberField = ({ fields, meta: { error, submitFailed } }) => {
    return (
      <Card className='container-fluid'>
        <Card.Header className='text-center'>Team Members</Card.Header>
        <Card.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Member</th>
                <th style={{ width: '25%' }}>Designation</th>
                <th>Role</th>
                <th>Spec Role</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {fields.map((member, index) => (
                <tr>
                  <td>
                    <div style={{ marginTop: '60%' }}>{index}</div>
                  </td>
                  <td>
                    <div style={{ marginTop: '15%' }}>
                      <Field
                        name={`${member}.member_id`}
                        label=' '
                        props={{
                          type: 'object',
                        }}
                        component={MultiSelectComponent}
                        {...{
                          data: this.props.employeeList,
                          isMulti: false,
                        }}
                        fullWidth
                      />
                    </div>
                  </td>

                  <td>
                    <Field
                      name={`${member}.designation`}
                      component={InputText}
                      type='text'
                      disabled={true}
                    />
                  </td>

                  <td>
                    <div style={{ marginTop: '15%' }}>
                      <Field
                        name={`${member}.role_id`}
                        label=''
                        props={{
                          type: 'object',
                        }}
                        component={MultiSelectComponent}
                        {...{
                          data: this.props.roles,
                          isMulti: false,
                        }}
                        fullWidth
                      />
                    </div>
                </td>
                <td>
                    <Field
                        name={`${member}.spec_role`}
                        label=''
                        component={SelectInput}
                        data ={[{id: 0, name: "Preparer"}, {id: 1, name: "Reviewer"}]}
                        fullWidth
                    />
                </td>

                  <td>
                    <div style={{ marginTop: '15%' }}>
                      <Button
                        onClick={() => {
                          if (
                            this.props.formValues[
                              'project_members_attributes'
                            ] &&
                            this.props.formValues['project_members_attributes'][
                              index
                            ] !== undefined
                          ) {
                            if (
                              this.props.formValues[
                                'project_members_attributes'
                              ][index]['member_id'] !== undefined
                            ) {
                              this.props.addBackEmployee(
                                this.props.formValues[
                                  'project_members_attributes'
                                ][index]['member_id']
                              );
                            }
                          }
                          fields.remove(index);
                        }}
                      >
                        <ClearIcon />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        {submitFailed && error && <span>{error}</span>}
        <Row>
          <Col></Col>
          <Col>
            <Button
              className='float-right'
              onClick={() => fields.push({})}
              color='secondary'
              variant='contained'
            >
              Add Member
            </Button>
          </Col>
        </Row>
      </Card>
    );
  };

  constructor(props) {
    super(props);
    let d = new Date();
    let this_month = d.getMonth();

    this.state = {
      loading: false,
      prev_project_sub_class: '',
      original_sac_master: undefined, //storing of original project while only editing to avaoid overiding sac_master
      projectAddFailed: true,
      members: [],
      showSnackbar: false,
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      current_month: this_month,
      current_year: d.getFullYear(),
      next_year: d.getFullYear() + 1,
      recurring: false,
      recurring_data: {
        recurring_monthly: false,
        recurring_quaterly: false,
        due_date_specific_date: false,
        due_date_month_end: false,
        first_occurence: false,
        first_occurence_month: '',
      },
      project_data: {
        client_id: '',
        in_charge_employee_id: '',
        in_charge_partner_id: '',
        organisation_id: '',
        project_category_id: '',
        sac_master_id: '',
        project_members_attributes: [],
        recurrence_project_members_attributes: [],
        recurrence_attributes: {},
      },
      simulation_data: {
        created_once: [],
        auto_generation: [],
      },
      project_start_date: new Date(),
      project_due_date: new Date(),
      view_simulation: false,
      disable_add_button: false,
      show_due_date_alert: false,
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getYear = this.getYear.bind(this);
    this.showSimulation = this.showSimulation.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
  }
  autoCreationLoop() {}
  closeSnackbar() {
    this.setState({
      showSnackbar: false,
    });
  }

  showSimulation(frequency) {
    if (this.props.formValues['name'] !== undefined) {
      switch (frequency) {
        case 'Monthly':
          this.showMonthlySimulation();
          break;
        case 'Quaterly':
          if (
            this.props.formValues['first_occurence_financial_year'] !==
              undefined &&
            this.props.formValues['q_start_month'] !== undefined &&
            this.props.formValues['q_due_month'] !== undefined
          ) {
            this.showQuaterlySimulation();
          } else {
            this.setState({
              showSnackbar: true,
            });
          }
          break;
        default:
          this.showYearlySimulation();
      }
    } else {
      this.setState({
        showSnackbar: true,
      });
    }
  }

  showQuaterlySimulation() {
    this.setState({
      view_simulation: true,
    });
    let fy = this.props.formValues['first_occurence_financial_year'].split('-');
    let y0 = Number(fy[0]);
    let y1 = Number(fy[0]) + 1;
    let now = new Date();
    if (this.props.creation_code === 'Specific_date_of_next_month_or_quarter') {
      if (
        this.props.formValues['first_occurence_month_or_quater'] === 'Q3' ||
        this.props.formValues['first_occurence_month_or_quater'] === 'Q4'
      ) {
        y0 = y1;
      }
    } else {
      if (this.props.formValues['first_occurence_month_or_quater'] === 'Q4') {
        y0 = y1;
      }
    }
    let start_date = this.getActualDate(
      this.props.formValues['q_start_month'],
      y0,
      this.props.choosenRecurrenceCreationDate,
      this.props.formValues['date_of_creation']
    );
    let d = moment(start_date),
      temp = [],
      i = 0;
    for (d; d <= moment(now); d = d.add(3, 'M')) {
      temp[i] = {};
      if (this.props.creation_code === 'Specific_date_of_month_or_quarter') {
        temp[i]['project_name'] =
          this.props.formValues['name'] +
          ' | ' +
          this.getQuaterYear(d.month(), d.year());
      } else {
        let append_data = d.clone();
        append_data = append_data.subtract(3, 'M');
        temp[i]['project_name'] =
          this.props.formValues['name'] +
          ' | ' +
          this.getQuaterYear(append_data.month(), append_data.year());
      }
      temp[i]['creation_date'] = moment().format('YYYY-MM-DD');
      temp[i]['month_year'] = moment([d.year(), d.month(), 1]).format(
        'YYYY-MM-DD'
      );
      temp[i]['due_date'] = this.props.formValues['due_date'];
      i++;
    }
    let condition = d.clone(),
      temp_auto = [],
      j = 0;
    condition = condition.add(6, 'M');
    for (d; d <= condition; d.add(3, 'M')) {
      temp_auto[j] = {};
      if (this.props.creation_code === 'Specific_date_of_month_or_quarter') {
        temp_auto[j]['project_name'] =
          this.props.formValues['name'] +
          ' | ' +
          this.getQuaterYear(d.month(), d.year());
      } else {
        let append_data = d.clone();
        append_data = append_data.subtract(3, 'M');
        temp_auto[j]['project_name'] =
          this.props.formValues['name'] +
          ' | ' +
          this.getQuaterYear(append_data.month(), append_data.year());
      }
      temp_auto[j]['creation_date'] = this.getActualDate(
        d.month(),
        d.year(),
        this.props.choosenRecurrenceCreationDate,
        this.props.formValues['date_of_creation']
      );
      let du_date = d.clone();
      switch (this.props.due_date_code) {
        case 'Specific_date_of_month_or_quarter':
          if (
            this.props.creation_code ===
            'Specific_date_of_next_month_or_quarter'
          ) {
            du_date.subtract(3, 'M');
          }
          break;
        default:
          if (
            this.props.creation_code === 'Specific_date_of_month_or_quarter'
          ) {
            du_date.add(3, 'M');
          }
      }
      let diff =
        (this.props.formValues['q_due_month'] % 3) -
        (this.props.formValues['q_start_month'] % 3);
      if (diff > 0) {
        du_date.add(diff, 'M');
      } else if (diff < 0) {
        du_date.subtract(Math.abs(diff), 'M');
      }
      temp_auto[j]['due_date'] = this.getActualDate(
        du_date.month(),
        du_date.year(),
        this.props.choosenRecurrenceDueDate,
        this.props.formValues['recurring_due_date']
      );
      j++;
    }
    this.updateSimulationData(temp, temp_auto);
  }
  updateSimulationData(temp, temp_auto) {
    let now = new Date();
    /**if (Array.isArray(temp) && !temp.length) {
            temp[0] = temp_auto[0];
            temp[0]['creation_date'] = moment().format('YYYY-MM-DD')

            temp_auto.splice(0,1)
        }**/
    var c_parts = temp_auto[0]['creation_date'].split('-');
    var d_parts = temp_auto[0]['due_date'].split('-');
    if (
      new Date(c_parts[0], c_parts[1] - 1, c_parts[2]) >
      new Date(d_parts[0], d_parts[1] - 1, d_parts[2])
    ) {
      this.setState({
        disable_add_button: true,
        show_due_date_alert: true,
      });
    } else {
      this.setState({
        disable_add_button: false,
        show_due_date_alert: false,
      });
    }
    if (new Date(c_parts[0], c_parts[1] - 1, c_parts[2]) <= now) {
      temp_auto.splice(0, 1);
    }

    let newObj2 = {
      ...this.state.simulation_data,
      auto_generation: temp_auto,
      created_once: temp,
    };
    this.setState({
      simulation_data: newObj2,
    });
  }
  getActualDate(month, year, date_code, date) {
    let start_date = 0;
    if (date_code === 'Specific Date') {
      if (Number(date) > new Date(Number(year), month + 1, 0).getDate()) {
        start_date = new Date(Number(year), month + 1, 0).getDate();
      } else {
        start_date = Number(date);
      }
    } else {
      start_date = new Date(Number(year), month + 1, 0).getDate();
    }
    return moment([year, month, start_date]).format('YYYY-MM-DD');
  }
  getFyYearly(year, month) {
    if (month < 3) {
      return year - 1 + '-' + (year % 100);
    } else {
      return year + '-' + ((year + 1) % 100);
    }
  }
  showYearlySimulation() {
    this.setState({
      view_simulation: true,
    });
    let fy = this.props.formValues['first_occurence_financial_year'].split('-');
    let y0 = Number(fy[0]);
    let y1 = Number(fy[0]) + 1;
    let now = new Date();
    if (this.props.creation_code === 'Specific_date_of_next_month_or_quarter') {
      if (this.props.formValues['q_start_month'] < 3) {
        y0 = y1 + 1;
      } else {
        y0 = y0 + 1;
      }
    } else {
      if (this.props.formValues['q_start_month'] < 3) {
        y0 = y1;
      }
    }
    let start_date = this.getActualDate(
      this.props.formValues['q_start_month'],
      y0,
      this.props.choosenRecurrenceCreationDate,
      this.props.formValues['date_of_creation']
    );
    let d = moment(start_date),
      temp = [],
      i = 0;
    for (d; d <= moment(now); d = d.add(1, 'y')) {
      temp[i] = {};
      debugger;
      if (this.props.creation_code === 'Specific_date_of_month_or_quarter') {
        temp[i]['project_name'] =
          this.props.formValues['name'] +
          ' | ' +
          this.getFyYearly(d.year(), d.month());
      } else {
        let append_data = d.clone();
        append_data = append_data.subtract(1, 'year');
        temp[i]['project_name'] =
          this.props.formValues['name'] +
          ' | ' +
          this.getFyYearly(append_data.year(), append_data.month());
      }
      temp[i]['creation_date'] = moment().format('YYYY-MM-DD');
      temp[i]['month_year'] = moment([d.year(), d.month(), 1]).format(
        'YYYY-MM-DD'
      );
      temp[i]['due_date'] = this.props.formValues['due_date'];
      i++;
    }
    let condition = d.clone(),
      temp_auto = [],
      j = 0;
    condition = condition.add(3, 'y');
    for (d; d <= condition; d.add(1, 'y')) {
      temp_auto[j] = {};
      if (this.props.creation_code === 'Specific_date_of_month_or_quarter') {
        temp_auto[j]['project_name'] =
          this.props.formValues['name'] +
          ' | ' +
          this.getFyYearly(d.year(), d.month());
      } else {
        let append_data = d.clone();
        append_data = append_data.subtract(1, 'y');
        temp_auto[j]['project_name'] =
          this.props.formValues['name'] +
          ' | ' +
          this.getFyYearly(append_data.year(), append_data.month());
      }
      temp_auto[j]['creation_date'] = this.getActualDate(
        d.month(),
        d.year(),
        this.props.choosenRecurrenceCreationDate,
        this.props.formValues['date_of_creation']
      );
      let du_date = d.clone();
      switch (this.props.due_date_code) {
        case 'Specific_date_of_month_or_quarter':
          if (
            this.props.creation_code ===
            'Specific_date_of_next_month_or_quarter'
          ) {
            du_date.subtract(1, 'y');
          }
          break;
        default:
          if (
            this.props.creation_code === 'Specific_date_of_month_or_quarter'
          ) {
            du_date.add(1, 'y');
          }
      }
      if (this.props.formValues['q_start_month'] < 3) {
        if (this.props.formValues['q_due_month'] > 3) {
          du_date.subtract(1, 'y');
        }
      } else {
        if (this.props.formValues['q_due_month'] < 3) {
          du_date.add(1, 'y');
        }
      }
      temp_auto[j]['due_date'] = this.getActualDate(
        this.props.formValues['q_due_month'],
        du_date.year(),
        this.props.choosenRecurrenceDueDate,
        this.props.formValues['recurring_due_date']
      );
      j++;
    }
    this.updateSimulationData(temp, temp_auto);
  }
  showMonthlySimulation() {
    this.setState({
      view_simulation: true,
    });
    let start_date = 1;
    // let due_date = 1;
    // let due_month = 0;
    // let due_year = 2020;
    // let start_month = 0;
    // let begining = new Date();
    let start_year = 2020;
    let creation_start_month = 1;
    let now = new Date();

    switch (this.props.creation_code) {
      case 'Specific_date_of_month_or_quarter':
        if (this.props.choosenRecurrenceCreationDate === 'Specific Date') {
          if (
            Number(this.props.formValues['date_of_creation']) >
            new Date(
              Number(this.props.formValues['first_occurence_year']),
              this.props.formValues['first_occurence_month_or_quater'] + 1,
              0
            ).getDate()
          ) {
            start_date = new Date(
              Number(this.props.formValues['first_occurence_year']),
              this.props.formValues['first_occurence_month_or_quater'] + 1,
              0
            ).getDate();
          } else {
            start_date = Number(this.props.formValues['date_of_creation']);
          }
        } else {
          start_date = new Date(
            Number(this.props.formValues['first_occurence_year']),
            this.props.formValues['first_occurence_month_or_quater'] + 1,
            0
          ).getDate();
        }
        creation_start_month =
          this.props.formValues['first_occurence_month_or_quater'];
        start_year = Number(this.props.formValues['first_occurence_year']);
        break;
      default:
        //case 'Specific_date_of_next_month':
        if (this.props.formValues['first_occurence_month_or_quater'] === 11) {
          creation_start_month = 0;
          start_year =
            Number(this.props.formValues['first_occurence_year']) + 1;
          if (this.props.choosenRecurrenceCreationDate === 'Specific Date') {
            if (
              Number(this.props.formValues['date_of_creation']) >
              new Date(
                Number(this.props.formValues['first_occurence_year']) + 1,
                1,
                0
              ).getDate()
            ) {
              start_date = new Date(
                Number(this.props.formValues['first_occurence_year']) + 1,
                1,
                0
              ).getDate();
            } else {
              start_date = Number(this.props.formValues['date_of_creation']);
            }
          } else {
            start_date = new Date(
              Number(this.props.formValues['first_occurence_year']),
              1,
              0
            ).getDate();
          }
        } else {
          creation_start_month =
            this.props.formValues['first_occurence_month_or_quater'] + 1;
          start_year = Number(this.props.formValues['first_occurence_year']);
          if (this.props.choosenRecurrenceCreationDate === 'Specific Date') {
            if (
              Number(this.props.formValues['date_of_creation']) >
              new Date(
                Number(this.props.formValues['first_occurence_year']),
                this.props.formValues['first_occurence_month_or_quater'] + 2,
                0
              ).getDate()
            ) {
              start_date = new Date(
                Number(this.props.formValues['first_occurence_year']),
                this.props.formValues['first_occurence_month_or_quater'] + 2,
                0
              ).getDate();
            } else {
              start_date = Number(this.props.formValues['date_of_creation']);
            }
          } else {
            start_date = new Date(
              Number(this.props.formValues['first_occurence_year']),
              this.props.formValues['first_occurence_month_or_quater'] + 2,
              0
            ).getDate();
          }
        }
    }
    let temp = [],
      i = 0;
    this.setState({
      project_start_date: moment([
        start_year,
        creation_start_month,
        start_date,
      ]),
    });
    for (
      let d = moment([start_year, creation_start_month, start_date]);
      d <= moment(now);
      d = d.add(1, 'M')
    ) {
      temp[i] = {};
      if (this.props.creation_code === 'Specific_date_of_month_or_quarter') {
        temp[i]['project_name'] =
          this.props.formValues['name'] +
          ' | ' +
          d.format('MMM') +
          '/' +
          (d.year() % 100);
      } else {
        let append_data = d.clone();
        temp[i]['project_name'] =
          this.props.formValues['name'] +
          ' | ' +
          append_data.subtract(1, 'M').format('MMM') +
          '/' +
          (append_data.year() % 100);
      }
      temp[i]['month_year'] = moment([d.year(), d.month(), 1]).format(
        'YYYY-MM-DD'
      ); //(d.month()+1)+"/"+(d.year()%100)
      temp[i]['creation_date'] = moment().format('YYYY-MM-DD');
      temp[i]['due_date'] = this.props.formValues['due_date'];
      i++;
    }
    //let newObj = {...this.state.simulation_data, created_once: temp}
    //this.setState({
    //   simulation_data: newObj
    //})
    let temp_auto = [],
      j = 0;
    let loop_start =
      moment([start_year, creation_start_month, start_date]) > moment(now)
        ? moment([start_year, creation_start_month, start_date])
        : moment(now);
    let conditon =
      moment([start_year, creation_start_month, start_date]) > moment(now)
        ? moment([start_year, creation_start_month, start_date]).add(2, 'M')
        : moment(now).add(2, 'M');
    for (let d = loop_start; d <= conditon; d = d.add(1, 'M')) {
      temp_auto[j] = {};
      if (this.props.creation_code === 'Specific_date_of_month_or_quarter') {
        temp_auto[j]['project_name'] =
          this.props.formValues['name'] +
          ' | ' +
          d.format('MMM') +
          '/' +
          (d.year() % 100);
      } else {
        let append_data2 = d.clone();
        temp_auto[j]['project_name'] =
          this.props.formValues['name'] +
          ' | ' +
          append_data2.subtract(1, 'M').format('MMM') +
          '/' +
          (append_data2.year() % 100);
      }
      temp_auto[j]['month_year'] = d.month() + 1 + '/' + (d.year() % 100);
      switch (this.props.creation_code) {
        case 'Specific_date_of_month_or_quarter':
          if (this.props.choosenRecurrenceCreationDate === 'Specific Date') {
            if (
              Number(this.props.formValues['date_of_creation']) >
              new Date(d.year(), d.month() + 1, 0).getDate()
            ) {
              temp_auto[j]['creation_date'] = moment([
                d.year(),
                d.month(),
                new Date(d.year(), d.month() + 1, 0).getDate(),
              ]).format('YYYY-MM-DD');
            } else {
              temp_auto[j]['creation_date'] = moment([
                d.year(),
                d.month(),
                Number(this.props.formValues['date_of_creation']),
              ]).format('YYYY-MM-DD');
            }
          } else {
            temp_auto[j]['creation_date'] = moment([
              d.year(),
              d.month(),
              new Date(d.year(), d.month() + 1, 0).getDate(),
            ]).format('YYYY-MM-DD');
          }
          break;
        default:
          if (this.props.choosenRecurrenceCreationDate === 'Specific Date') {
            if (
              Number(this.props.formValues['date_of_creation']) >
              new Date(d.year(), d.month() + 1, 0).getDate()
            ) {
              temp_auto[j]['creation_date'] = moment([
                d.year(),
                d.month(),
                new Date(d.year(), d.month() + 1, 0).getDate(),
              ]).format('YYYY-MM-DD');
            } else {
              temp_auto[j]['creation_date'] = moment([
                d.year(),
                d.month(),
                Number(this.props.formValues['date_of_creation']),
              ]).format('YYYY-MM-DD');
            }
          } else {
            temp_auto[j]['creation_date'] = moment([
              d.year(),
              d.month(),
              new Date(d.year(), d.month() + 1, 0).getDate(),
            ]).format('YYYY-MM-DD');
          }
      }
      switch (this.props.due_date_code) {
        case 'Specific_date_of_month_or_quarter':
          let du_date = d.clone();
          if (
            this.props.creation_code ===
            'Specific_date_of_next_month_or_quarter'
          ) {
            du_date.subtract(1, 'M');
          }
          if (this.props.choosenRecurrenceDueDate === 'Specific Date') {
            if (
              Number(this.props.formValues['recurring_due_date']) >
              new Date(du_date.year(), du_date.month() + 1, 0).getDate()
            ) {
              temp_auto[j]['due_date'] = moment([
                du_date.year(),
                du_date.month(),
                new Date(du_date.year(), du_date.month() + 1, 0).getDate(),
              ]).format('YYYY-MM-DD');
            } else {
              temp_auto[j]['due_date'] = moment([
                du_date.year(),
                du_date.month(),
                Number(this.props.formValues['recurring_due_date']),
              ]).format('YYYY-MM-DD');
            }
          } else {
            temp_auto[j]['due_date'] = moment([
              du_date.year(),
              du_date.month(),
              new Date(du_date.year(), du_date.month() + 1, 0).getDate(),
            ]).format('YYYY-MM-DD');
          }

          break;
        default:
          let dup_date = d.clone();
          if (
            this.props.creation_code === 'Specific_date_of_month_or_quarter'
          ) {
            dup_date.add(1, 'M');
          }
          if (this.props.choosenRecurrenceDueDate === 'Specific Date') {
            if (
              Number(this.props.formValues['recurring_due_date']) >
              new Date(dup_date.year(), dup_date.month() + 1, 0).getDate()
            ) {
              temp_auto[j]['due_date'] = moment([
                dup_date.year(),
                dup_date.month(),
                new Date(dup_date.year(), dup_date.month() + 1, 0).getDate(),
              ]).format('YYYY-MM-DD');
            } else {
              temp_auto[j]['due_date'] = moment([
                dup_date.year(),
                dup_date.month(),
                Number(this.props.formValues['recurring_due_date']),
              ]).format('YYYY-MM-DD');
            }
          } else {
            temp_auto[j]['due_date'] = moment([
              dup_date.year(),
              dup_date.month(),
              new Date(dup_date.year(), dup_date.month() + 1, 0).getDate(),
            ]).format('YYYY-MM-DD');
          }
      }
      j++;
    }
    this.updateSimulationData(temp, temp_auto);
  }
  closeModal() {
    this.setState({
      loading: this.props.loading,
      projectAddFailed: this.props.projectAddFailed,
    });
  }
  componentDidUpdate(prevProps) {
    if (!this.state.projectAddFailed) {
      this.props.history.goBack();
    }
    if (this.props.isRecurring !== prevProps.isRecurring) {
      if (this.props.isRecurring !== 'true') {
        this.setState({
          view_simulation: false,
          disable_add_button: false,
        });
      } else {
        this.setState({
          disable_add_button: true,
        });
      }
    }
    //below if block to fetch auto_recurring value based on project subclass
    if (
        this.props.isRecurring &&
        this.props.project_sub_class &&
      this.state.prev_project_sub_class !== this.props.project_sub_class['id']
    ) {
      this.setState({
        prev_project_sub_class: this.props.project_sub_class['id'],
      });
      this.props.fetchDefaultProjectAutoRecurringStatus(
        this.props.token,
        this.props.project_sub_class['id']
      );
    }
      if (
          (this.props.project_sub_class && prevProps.project_sub_class && this.props.project_sub_class['id'] !== prevProps.project_sub_class['id']) || (this.props.project_sub_class && !prevProps.project_sub_class)) {
      if (
        !this.state.original_sac_master ||
        this.props.formValues.sac_master_id !== this.state.original_sac_master
      )
        this.props.fetchSacMasterId(
          this.props.token,
          this.props.project_sub_class['id']
        );
    }
    if (
      this.props.sub_class_props.auto_recurring !== undefined &&
      this.props.sub_class_props.auto_recurring !==
        prevProps.sub_class_props.auto_recurring
    ) {
      this.props.dispatch(
        change(
          'AddProject',
          'auto_recurring',
          this.props.sub_class_props.auto_recurring
        )
      );
    }
    if (
      this.props.sub_class_props.sac_master_id !== undefined &&
      this.props.sub_class_props.sac_master_id !==
        prevProps.sub_class_props.sac_master_id
    ) {
      this.props.dispatch(
        change(
          'AddProject',
          'sac_master_id',
          this.props.sub_class_props.sac_master_id
        )
      );
    }
  }
  formatProjectData() {}
  getYear(month) {
    if (month < this.state.current_month) {
      return this.state.current_year + 1;
    } else {
      return this.state.current_year;
    }
  }
  getQuaterYear(month, year) {
    let fy = '';
    switch (true) {
      case month < 3:
        fy = `${year - 1}-${year % 100}`;
        return 'Q4/' + fy;
      case month < 6:
        fy = `${year}-${(year + 1) % 100}`;
        return 'Q1/' + fy;
      case month < 9:
        fy = `${year}-${(year + 1) % 100}`;
        return 'Q2/' + fy;
      default:
        fy = `${year}-${(year + 1) % 100}`;
        return 'Q3/' + fy;
    }
  }
  getNextQuater(quater) {
    switch (quater) {
      case 'Q1':
        return 'Q2';
      case 'Q2':
        return 'Q3';
      case 'Q3':
        return 'Q4';
      default:
        return 'Q1';
    }
  }
  getCreationCode(frequency) {
    switch (frequency) {
      case 'Monthly':
        return recurringData['m_creation_code'];
      case 'Quaterly':
        return recurringData['q_creation_code'];
      default:
        return recurringData['y_creation_code'];
    }
  }
  getMonths(quater, code) {
    if (code === 'Specific_date_of_next_month_or_quarter') {
      quater = this.getNextQuater(quater);
    }
    switch (quater) {
      case 'Q4':
        return recurringData['Months'].slice(0, 3);
      case 'Q1':
        return recurringData['Months'].slice(3, 6);
      case 'Q2':
        return recurringData['Months'].slice(6, 9);
      default:
        return recurringData['Months'].slice(9, 12);
    }
  }
  async onsubmit(formData) {
    await this.setState({
      loading: true,
      project_data: JSON.parse(JSON.stringify(formData)),
    });
    let dup_project = JSON.parse(JSON.stringify(formData));
    let recurrence = [];
    recurrence = {};
    if (formData['recurring'] === 'true') {
      dup_project['first_due_date'] = formData['due_date'];
      dup_project['name'] = formData['name'];
      dup_project['add_recurrence_relation'] = true;
      //dup_project['name'] = this.state.simulation_data['created_once'][0]['project_name']
      if (formData['date_of_creation_type'] === 'Specific Date') {
        dup_project['date_of_creation_type'] = formData['date_of_creation'];
      }
      if (formData['due_date_type'] === 'Specific Date') {
        dup_project['due_date_type'] = formData['recurring_due_date'];
      }
      if (
        formData['frequency'] === 'Quaterly' ||
        formData['frequency'] === 'Yearly'
      ) {
        dup_project['date_of_creation_type'] =
          this.props.formValues['q_start_month'] +
          '|' +
          dup_project['date_of_creation_type'];
        dup_project['due_date_type'] =
          this.props.formValues['q_due_month'] +
          '|' +
          dup_project['due_date_type'];
      } else {
      }
      /**
        recurrence['creation_code'] = formData['creation_code']
        recurrence['due_date_code'] = formData['due_date_code']
        recurrence['creation_date'] = dup_project['date_of_creation_type']
        recurrence['due_date'] = dup_project['due_date_type']
        recurrence['frequency'] = formData['frequency']
        recurrence['auto_recurring'] = formData['auto_recurring']
         **/
      if (this.state.simulation_data['created_once'].length > 0) {
        recurrence['next_creation_date'] =
          this.state.simulation_data['created_once'][0]['month_year']; //taking frist element
      } else {
        recurrence['next_creation_date'] =
          this.state.simulation_data['auto_generation'][0]['creation_date'];
      }
      //https://stackoverflow.com/a/43041334/5706413
      var rec_state = { ...this.state.project_data };
      rec_state['first_due_date'] = formData['due_date'];
      rec_state['creation_code'] = formData['creation_code'];
      rec_state['due_date_code'] = formData['due_date_code'];
      rec_state['creation_date'] = dup_project['date_of_creation_type'];
      rec_state['due_date'] = dup_project['due_date_type'];
      rec_state['frequency'] = formData['frequency'];
      rec_state['auto_recurring'] = formData['auto_recurring'];
      rec_state['next_creation_date'] = recurrence['next_creation_date'];

      //rec_state.recurrence_attributes = recurrence
      await this.setState({
        project_data: rec_state,
      });
    } else {
      dup_project['add_recurrence_relation'] = false;
    }
    if (!this.props.location.data) {
      await this.setState((prevState) => ({
        project_data: {
          ...prevState.project_data,
            client_id: formData['client_id']['id'],
            project_sub_class_id: formData['project_sub_class_id']['id'],
          name: dup_project['name'],
          original_name: dup_project['original_name'],
          organisation_id: formData['organisation_id']['id'],
          sac_master_id: formData['sac_master_id']['id'],
          add_recurrence_relation: dup_project['add_recurrence_relation'],
        },
      }));
    } else {
      await this.setState((prevState) => ({
        project_data: {
          ...prevState.project_data,
            client_id: formData['client_id']['id'],
            project_sub_class_id: formData['project_sub_class_id']['id'],
          sac_master_id: formData['sac_master_id']['id'],
          name: dup_project['name'],
          original_name: dup_project['original_name'],
          //in_charge_employee_id: formData['in_charge_employee_id']['id'],
          //in_charge_partner_id: formData['in_charge_partner_id']['id'],
          organisation_id: formData['organisation_id']['id'],
          project_members_attributes: dup_project['project_members_attributes'],
        },
      }));
    }

    let temp_pro =
      this.state.project_data['project_members_attributes'].slice();
    temp_pro.forEach((item, index) => {
      temp_pro[index]['member_id'] = item['member_id']['id'];
      temp_pro[index]['role_id'] = item['role_id']['id'];
    });
    //to set project memebrs dynamically for normal project, recurring project
    let recurring_key =
      formData['recurring'] === 'true'
        ? 'recurrence_project_members_attributes'
        : 'project_members_attributes';
    const newObj = {
      ...this.state.project_data,
      [recurring_key]: temp_pro,
    };
    await this.setState({
      project_data: newObj,
    });
    if (!this.props.location.data) {
      await this.props.addProject(this.state.project_data, this.props.token);
    } else {
      await this.props.updateProject(
        this.state.project_data,
        this.props.token,
        this.props.details['id'],
        this.state.members
      );
    }
    this.setState({
      message: this.props.message,
    });
  }
  componentWillMount() {
    this.props.fetchMemberRoles(this.props.token);
    this.props.fetchClientList(this.props.token);
    this.props.fetchOrganisation(this.props.token);
    this.props.fetchEmployeeDropDownList(this.props.token);
    this.props.fetchProjectCategoryList(this.props.token);
    this.props.fetchPartnerList(this.props.token);
    this.props.fetchSacmasterList(this.props.token);
    if (this.props.location.data) {
      this.props
        .fetchProjectDetails(this.props.location.data.id, this.props.token)
        .then((resp) => {
          if (resp.recurring) {
            if (resp.frequency === 'Monthly') {
              resp['first_occurence_month_or_quater'] =
                this.state.months.indexOf(
                  resp['first_occurence_month_or_quater']
                );
            }
          }

          this.setState({
            members: resp['project_members_attributes'],
            original_sac_master: resp['sac_master_id'],
          });
          this.props.initialize(resp);
          //below code filtering out timesheet code already selected
          let obj = [];
          for (var item in resp['project_members_attributes']) {
            for (var key in resp['project_members_attributes'][item]) {
              if (key.includes('member_id')) {
                obj.push(resp['project_members_attributes'][item][key]['id']);
              }
            }
          }
          this.props.initialFilteringEmployee(obj);
        });
    } else {
      this.props.dispatch(change('AddProject', 'status', 'YetToCommence'));
    }
  }

  render() {
    const { handleSubmit, classes, choosenRecurringType, q_month } = this.props;
    console.log(this.props.dprojectCategoryList);
    console.log(this.props.dclients);
    let temp =
      choosenRecurringType === 'Monthly'
        ? recurringData['Months']
        : recurringData['Quater'];
    return (
      <Container className={classes.containerScroll}>
        <Header title='add_project' />
        <Typography
          component='h1'
          variant='h2'
          align='center'
          color='textPrimary'
        >
          Add Project
        </Typography>
        <Row>
          <Col sm={4}></Col>
        </Row>
        <form onSubmit={handleSubmit(this.onsubmit)}>
          <h3>
            <Badge variant='dark'>Basic Details</Badge>
          </h3>
          <Row>
            <Col sm={8}>
              <span>Select Client</span>

              <Field
                name='client_id'
                label=' '
                props={{
                  type: 'object',
                }}
                component={MultiSelectComponent}
                {...{
                  data: this.props.dclients,
                  isMulti: false,
                }}
                fullWidth
              />
            </Col>

            <Col sm={4}>
              <span>Project Short Name</span>
              <Field
                name='name'
                label='Name *'
                component={InputText}
                type='text'
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <span>Description</span>
              <Field
                name='description'
                label='Description *'
                component={InputText}
                type='text'
                fullWidth
              />
            </Col>
            <Col sm={4}>
              <span>Select Project Category</span>
              <Field
                name='project_sub_class_id'
                label=' '
                props={{
                  type: 'object',
                }}
                component={MultiSelectComponent}
                {...{
                  data: this.props.dprojectCategoryList,
                  isMulti: false,
                }}
                fullWidth
              />
            </Col>
          </Row>

          <Divider />
          <h3>
            <Badge variant='dark'>Team</Badge>
          </h3>
          <Row>
            <FieldArray
              name='project_members_attributes'
              component={this.renderMemberField}
            />
          </Row>
          <Divider />
          <h3>
            <Badge variant='dark'>For Certificate register Module</Badge>
          </h3>
          <Row>
            <Col sm={8}>
              <span>Any deliverables?</span>
              <Field
                name='any_deliverables'
                label='any deliverables? *'
                component={SelectInput}
                fullWidth
              />
            </Col>
            <Col sm={4}>
              <span>Is UDIN mandatory </span>
              <Field
                name='udin_mandatory'
                label='udin mandatory ?*'
                component={SelectInput}
                fullWidth
              />
            </Col>
          </Row>
          <Divider />
          <h3>
            <Badge variant='dark'>Others</Badge>
          </h3>
          <Row>
            {this.props.location.data && (
              <Col sm={4}>
                <span>Project Status</span>
                <Field
                  name='status'
                  label='Status *'
                  component={SelectInput}
                  fullWidth
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col sm={8}>
              <span>Billable or not? </span>
              <Field
                name='billable'
                label='billable or not? *'
                component={SelectInput}
                fullWidth
              />
            </Col>
	    {(this.props.billable == "true") && <Col sm={4}>
              <span>Budgeted bill amount </span>
              <Field
                name='budgeted_bill_amount'
                label='budgeted bill amount*'
                component={InputText}
                type='number'
                fullWidth
              />
            </Col>}
          </Row>
          <Row>
            <Col sm={8}>
              <span>Select Organisation</span>
              <Field
                name='organisation_id'
                label=''
                props={{
                  type: 'object',
                }}
                component={MultiSelectComponent}
                {...{
                  data: this.props.organisation,
                  isMulti: false,
                }}
                fullWidth
              />
            </Col>
            {!this.props.location.data && (
              <Col sm={4}>
                <span>Is it recurring project? </span>
                <Field
                  name='recurring'
                  label='recurring or not? *'
                  component={SelectInput}
                  fullWidth
                />
              </Col>
            )}
          </Row>
          {this.props.isRecurring === 'true' && (
            <Row>
              <Col sm={8}>
                <span>Frequency</span>
                <Field
                  name='frequency'
                  label='frequency *'
                  component={SelectInput}
                  fullWidth
                />
              </Col>
              <Col>
                <span>Auto create</span>
                <Field
                  name='auto_recurring'
                  label='auto recurring *'
                  component={SelectInput}
                  fullWidth
                />
              </Col>
            </Row>
          )}

          {this.props.isRecurring === 'true' && (
            <Row>
              {this.props.choosenRecurringType !== 'Yearly' && (
                <Col sm={8}>
                  <span>First occurence</span>
                  <Field
                    name='first_occurence_month_or_quater'
                    label=' '
                    props={{
                      type: 'object',
                    }}
                    component={SelectInput}
                    {...{
                      data: { temp },
                    }}
                    fullWidth
                  />
                </Col>
              )}
              {this.props.choosenRecurringType === 'Monthly' && (
                <Col sm={4}>
                  <span>First occurence year</span>
                  <Field
                    name='first_occurence_year'
                    label=''
                    component={InputText}
                    type='number'
                    fullWidth
                  />
                </Col>
              )}
              {(this.props.choosenRecurringType === 'Quaterly' ||
                this.props.choosenRecurringType === 'Yearly') && (
                <Col sm={4}>
                  <span>First occurence financial year</span>
                  <Field
                    name='first_occurence_financial_year'
                    label=''
                    component={InputText}
                    type='text'
                    fullWidth
                  />
                </Col>
              )}
            </Row>
          )}
          {this.props.isRecurring === 'true' &&
            this.props.choosenRecurringType !== undefined && (
              <Row>
                <Col>
                  <span>Creation date</span>
                  <Field
                    name='creation_code'
                    props={{
                      type: 'object',
                    }}
                    component={SelectInput}
                    {...{
                      data: this.getCreationCode(
                        this.props.choosenRecurringType
                      ),
                    }}
                    fullWidth
                  />
                </Col>
                {this.props.choosenRecurringType !== 'Monthly' && (
                  <Col>
                    <span>Select Month</span>
                    <Field
                      name='q_start_month'
                      props={{
                        type: 'object',
                      }}
                      component={SelectInput}
                      {...{
                        data:
                          this.props.choosenRecurringType === 'Quaterly'
                            ? this.getMonths(q_month, this.props.creation_code)
                            : recurringData['Months'],
                      }}
                      fullWidth
                    />
                  </Col>
                )}
                {(this.props.creation_code ===
                  'Specific_date_of_month_or_quarter' ||
                  this.props.creation_code ===
                    'Specific_date_of_next_month_or_quarter') && (
                  <Col>
                    <span>Ener the date</span>
                    <Field
                      name='date_of_creation_type'
                      label=''
                      component={SelectInput}
                      fullWidth
                    />
                  </Col>
                )}

                {(this.props.creation_code ===
                  'Specific_date_of_month_or_quarter' ||
                  this.props.creation_code ===
                    'Specific_date_of_next_month_or_quarter') &&
                  this.props.choosenRecurrenceCreationDate ===
                    'Specific Date' && (
                    <Col>
                      <span>Ener the date</span>
                      <Field
                        name='date_of_creation'
                        label=''
                        component={InputText}
                        type='number'
                        fullWidth
                      />
                    </Col>
                  )}
              </Row>
            )}
          {this.props.isRecurring === 'true' &&
            this.props.choosenRecurringType !== undefined && (
              <Row>
                <Col>
                  <span>Recurring Due Date </span>
                  <Field
                    name='due_date_code'
                    props={{
                      type: 'object',
                    }}
                    component={SelectInput}
                    {...{
                      data: this.getCreationCode(
                        this.props.choosenRecurringType
                      ),
                    }}
                    fullWidth
                  />
                </Col>
                {this.props.choosenRecurringType !== 'Monthly' && (
                  <Col>
                    <span>Select Month</span>
                    <Field
                      name='q_due_month'
                      props={{
                        type: 'object',
                      }}
                      component={SelectInput}
                      {...{
                        data:
                          this.props.choosenRecurringType === 'Quaterly'
                            ? this.getMonths(q_month, this.props.due_date_code)
                            : recurringData['Months'],
                      }}
                      fullWidth
                    />
                  </Col>
                )}

                {(this.props.due_date_code ===
                  'Specific_date_of_month_or_quarter' ||
                  this.props.due_date_code ===
                    'Specific_date_of_next_month_or_quarter') && (
                  <Col>
                    <span>Enter the due date</span>
                    <Field
                      name='due_date_type'
                      component={SelectInput}
                      fullWidth
                    />
                  </Col>
                )}
                {(this.props.due_date_code ===
                  'Specific_date_of_month_or_quarter' ||
                  this.props.due_date_code ===
                    'Specific_date_of_next_month_or_quarter') &&
                  this.props.choosenRecurrenceDueDate === 'Specific Date' && (
                    <Col>
                      <span>Ener the date</span>
                      <Field
                        name='recurring_due_date'
                        label=''
                        component={InputText}
                        type='number'
                        fullWidth
                      />
                    </Col>
                  )}
              </Row>
            )}
          <Row>
            <Col sm={8}>
              <span>
                {this.props.isRecurring === 'true'
                  ? 'Initial due date'
                  : 'Due date'}
              </span>
              <Field
                name='due_date'
                component={InputText}
                type='date'
                fullWidth
              />
            </Col>
            <Col sm={4}>
              <span>Sac master</span>
              <Field
                name='sac_master_id'
                label=''
                props={{
                  type: 'object',
                }}
                component={MultiSelectComponent}
                {...{
                  data: this.props.sacMasterList,
                  isMulti: false,
                }}
                fullWidth
              />
            </Col>
          </Row>
          {this.props.isRecurring === 'true' && (
            <Row className='float-right'>
              <Button
                variant='contained'
                color='primary'
                onClick={() =>
                  this.showSimulation(this.props.choosenRecurringType)
                }
                type='button'
              >
                Simulate
              </Button>
            </Row>
          )}
          <Row>
            <Col sm={4}>
              <SavingDetailsModal
                className={classes.modal}
                show={this.state.loading}
                title={'Saving Project Details'}
                loading={this.props.loading}
                message={this.props.message}
                onClick={this.closeModal}
              />
            </Col>
            <SnackbarAlert
              keyValue={'simulation'}
              open={this.state.showSnackbar}
              onClose={this.closeSnackbar}
              message={'Please ener more details !!'}
              onClick={this.closeSnackbar}
            />
          </Row>
          <Row>
            <CustomValidationAlert
              show={this.state.show_due_date_alert}
              onClose={() => this.setState({ show_due_date_alert: false })}
              title={'Due date error !!'}
              message={'Due date must be greater than creation date'}
            />
          </Row>
          {this.state.view_simulation === true && (
            <div>
              <Table responsive='sm'>
                <thead>
                  <tr>
                    <th></th>
                    <th>Creation date</th>
                    <th>Due date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Created at once</td>
                    <td></td>
                    <td></td>
                  </tr>
                  {this.state.simulation_data['created_once'] &&
                    this.state.simulation_data['created_once'].map(
                      (item, key) => (
                        <tr>
                          <td>{item.project_name}</td>
                          <td>{item.creation_date}</td>
                          <td>{item.due_date}</td>
                        </tr>
                      )
                    )}
                  <tr>
                    <td>Auto generation</td>
                    <td></td>
                    <td></td>
                  </tr>

                  {this.state.simulation_data['auto_generation'] &&
                    this.state.simulation_data['auto_generation'].map(
                      (item, key) => (
                        <tr>
                          <td>{item.project_name}</td>
                          <td>{item.creation_date}</td>
                          <td>{item.due_date}</td>
                          <td></td>
                        </tr>
                      )
                    )}
                </tbody>
              </Table>
            </div>
          )}
          <Row></Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <Row className='float-right'>
                <Col>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={this.state.disable_add_button}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    );
  }
}
const selector = formValueSelector('AddProject');
function mapStateToProps(state) {
  return {
    dclients: state.client.dclientList,
    token: state.auth.token,
    employeeList: state.employee.employeeDropdownList,
    dprojectCategoryList: state.projectClass.dprojectCategoryList,
    sub_class_props: state.projectClass,
    partners: state.employee.partnerList,
    loading: state.project.projectAddLoading,
    projectAddFailed: state.project.projectAddFailed,
    details: state.project.project,
    message: state.project.projectAddMessage,
    organisation: state.organisation.dorganisationList,
    isRecurring: selector(state, 'recurring'),
    billable: selector(state, 'billable'),
    creation_code: selector(state, 'creation_code'),
    due_date_code: selector(state, 'due_date_code'),
    roles: state.memberRole.role,
    choosenRecurringType: selector(state, 'frequency'),
    choosenFirstOccurence: selector(state, 'first_occurence'),
    project_sub_class: selector(state, 'project_sub_class_id'),
    formValues: getFormValues('AddProject')(state),
    choosenRecurrenceDueDate: selector(state, 'due_date_type'),
    q_month: selector(state, 'first_occurence_month_or_quater'),
    choosenRecurrenceCreationDate: selector(state, 'date_of_creation_type'),
    sacMasterList: state.sacmaster.sacMasterList,
  };
}
export default compose(
  withRouter,
  connect(mapStateToProps, actions),
  reduxForm({
    form: 'AddProject',
    validate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  }),
  withStyles(styles)
)(AddProject);
