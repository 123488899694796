import {
  START_SAC_MASTER_ADD,
  SAC_MASTER_ADD_SUCCESS,
  SAC_MASTER_ADD_FAILED,
  START_SPEC_MASTER_FETCH,
  START_SPEC_MASTER_FAILED,
  START_SPEC_MASTER_SUCCESS,
  STORE_FORM,
} from '../actions/types';

const DEFAULT_STATE = {
  sacMasterList: [],
  specMasterList: [],
  loading: false,
  errorMessage: '',
  message: '',
  sacmasterAddLoading: false,
  sacmasterAddFailed: true,
  form: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case START_SAC_MASTER_ADD:
      return {
        ...state,
        sacmasterAddLoading: true,
        sacmasterAddFailed: false,
        message: '',
      };
    case SAC_MASTER_ADD_SUCCESS:
      return {
        ...state,
        sacmasterAddLoading: false,
        sacmasterAddFailed: false,
        message: action.payload,
      };
    case SAC_MASTER_ADD_FAILED:
      return {
        ...state,
        sacmasterAddLoading: false,
        sacmasterAddFailed: true,
        message: action.payload,
      };
    case START_SPEC_MASTER_FETCH:
      return {
        ...state,
        loading: true,
        specMasterList: [],
      };
    case START_SPEC_MASTER_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case START_SPEC_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        specMasterList: action.payload,
      };
    case STORE_FORM:
      return {
        ...state,
        form: action.payload,
      };
    default:
      return state;
  }
};
