export const FIRM_ASSET_STATUS = [
    {
        name: "Live",
        id: 0
    },
    {
        name: "Retired",
        id: 1
    },
];
