import axios from 'axios';
import {
  AUTH_SIGNUP,
  AUTH_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  VERIFY_PASSKEY,
  FETCHING_USER,
  FETCHING_USER_DONE,
  FETCHING_USER_FAILED,
  START_LOGIN,
  START_SIGNUP,
  FETCH_TOKEN_FROM_LOCAL,
  VERIFY_PASSKEY_LOADING,
  VERIFY_PASSKEY_FAILED,
  GET_PASSKEY,
} from './types';
import { BaseUrl } from './api';
export const signup = (data) => {
  return async (dispatch) => {
    dispatch({
      type: START_SIGNUP,
    });
    try {
      let user_data = { user: data };
      const res = await axios.post(`${BaseUrl}/register`, user_data);
      if (!res.data.status) {
        dispatch({
          type: AUTH_ERROR,
          payload: res.data.message,
        });
      } else {
        dispatch({
          type: AUTH_SIGNUP,
          payload: res.data.message,
          //userDetails: res.data.user
        });
      }
      //localStorage.setItem("JWT_TOKEN", res.data.jwt);
    } catch (error) {
      dispatch({
        type: AUTH_ERROR,
        payload: error.message,
      });
    }
  };
};

export const login = (data) => {
  return async (dispatch) => {
    try {
      let user_data = {
        user: {
          work_email: data.email,
          password: data.password,
        },
      };
      const res = await axios.post(`${BaseUrl}/login`, user_data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          dataType: 'json',
        },
      });
      if (res.data.status) {
        dispatch({
          type: AUTH_LOGIN,
          payload: res.data.jwt,
          userDetails: res.data.user,
        });
        localStorage.setItem('JWT_TOKEN', res.data.jwt);
      } else {
        dispatch({
          type: AUTH_ERROR,
          payload: res.data.message,
        });
      }
    } catch (error) {
      dispatch({
        type: AUTH_ERROR,
        payload: error.message,
      });
    }
  };
};
export const logout = () => {
  return async (dispatch) => {
    localStorage.removeItem('JWT_TOKEN');
    dispatch({
      type: AUTH_LOGOUT,
      payload: '',
    });
  };
};
export const fetchCurrentUser = (token) => {
  // takes the token in localStorage and finds out who it belongs to
  return async (dispatch) => {
    dispatch({
      type: FETCHING_USER,
      payload: '',
    }); //tells the app we are fetching
    try {
      const res = await axios.get(`${BaseUrl}/profile`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          dataType: 'json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data.status === 'success') {
        dispatch({
          type: FETCHING_USER_DONE,
          payload: res.data.user_profile,
        });
      } else {
        dispatch({
          type: FETCHING_USER_FAILED,
          payload: '', //"Fetching user failed"
        });
      }
    } catch (error) {
      dispatch({
        type: FETCHING_USER_FAILED,
        payload: '', //"Fetching user failed"
      });
    }
  };
};
export const fetchTokenLocal = () => {
  return async (dispatch) => {
    dispatch({
        type: FETCH_TOKEN_FROM_LOCAL,
        payload:  localStorage.getItem("JWT_TOKEN")
    })
  };
};
export const startSignin = () => {
  return async (dispatch) => {
    dispatch({
      type: START_LOGIN,
      payload: '',
    });
  };
};

export const startSignup = () => {
  return async (dispatch) => {
    dispatch({
      type: START_SIGNUP,
      payload: '',
    });
  };
};

export const getPassKey = () => {
  return async (dispatch) => {
    const passkey = localStorage.getItem('PASS_KEY') === 'true';
    dispatch({
      type: GET_PASSKEY,
      payload: passkey,
    });
  };
};

export const verifyPasskey = (passkey) => {
  return async (dispatch) => {
    dispatch({
      type: VERIFY_PASSKEY_LOADING,
      payload: true,
    });

    try {
      const res = await axios.post(`${BaseUrl}/verify_passkey`, {user: passkey});
      console.log(res);
        if(res.data.passkey_verified){
            dispatch({
                type: VERIFY_PASSKEY,
                payload: true,
            });
            localStorage.setItem('PASS_KEY', true);
        }else{
             dispatch({
                type: VERIFY_PASSKEY_FAILED,
                payload: false,
             });
            localStorage.setItem('PASS_KEY', false);
        }
    } catch (error) {
      console.log(error);
      dispatch({
        type: VERIFY_PASSKEY_FAILED,
        payload: false,
      });
      localStorage.setItem('PASS_KEY', false);
    } finally {
      dispatch({
        type: VERIFY_PASSKEY_LOADING,
        payload: false,
      });
    }
  };
};
