import React, { useState, useEffect, useCallback } from 'react';
import {
  CCardBody,
  CBadge,
  CButton,
  CCollapse,
  CDataTable,
} from '@coreui/react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

export default function Table({ props }) {
  const [details, setDetails] = useState([]);
  const [editPath, setEditPath] = useState('');
  const [addPath, setAddPath] = useState('');
  const [viewPath, setViewPath] = useState('');
  const [perPage, setPerPage] = useState(
    localStorage.getItem(`rows_per_page_${props.page}`) !== null
      ? localStorage.getItem(`rows_per_page_${props.page}`)
      : 10
  );

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };
  /**React.useEffect(()=>{
        setPerPage(localStorage.getItem(`rows_per_page_${props.page}`) !== null ? localStorage.getItem(`rows_per_page_${props.page}`) : 10)
    },[props])**/

  const paginationChanged = useCallback((e) => {
    localStorage.setItem(`rows_per_page_${props.page}`, e);
    setPerPage(e);
  }, []);
  const callBackFn = (option, item) => {
    option.action(option['params'] ? item.id : null);
  };
  const viewButtons = useCallback(
    (item) => {
      return (
        <>
          {props.options.map((option) => {
            return (
              <CButton
                size='sm'
                color={
                  item.employee_status !== 'Active' && option.text === 'Suspend'
                    ? 'secondary'
                    : 'info'
                }
                className='ml-1'
                disabled={
                  item.employee_status !== 'Active' && option.text === 'Suspend'
                }
                onClick={() => option.action(option['params'] ? item.id : item)}
              >
                {option.text}
              </CButton>
            );
          })}
        </>
      );
    },
    [props]
  );
    const getDetailsText = useCallback((item)=>{
      console.log("internal ref", item,props.addPath)
      if(props.addPath === '/addcertificateregister'){
          return <h4>{item.internal_reference}</h4>
      }else{
          return <h4>{item.name}</h4>
      }
  },[props.addPath])
  return (
    <CCardBody>
      <div style={{ flexDirection: 'row' }}>
        {props.addPath !== '' ? (
          <div style={{ marginLeft: '96%' }}>
            <Link className='button--signup--intro button' to={props.addPath}>
              <IconButton color='primary' aria-label='add'>
                <Icon>add_plus</Icon>
              </IconButton>
            </Link>
          </div>
        ) : null}
        <CDataTable
          items={props.data}
          fields={props.fields}
          columnFilter
          tableFilter
          cleaner
          itemsPerPageSelect
          itemsPerPage={perPage}
          hover
          sorter
          pagination
          onPaginationChange={(e) => paginationChanged(e)}
          loading={props.loading}
          scopedSlots={{
            show_details: (item) => {
              return (
                <td className='py-2'>
                  <CButton
                    color='primary'
                    variant='outline'
                    shape='square'
                    size='sm'
                    onClick={() => {
                      toggleDetails(item.id);
                    }}
                  >
                    {details.includes(item.id) ? 'Hide' : 'Show'}
                  </CButton>
                </td>
              );
            },
            details: (item) => {
              return (
                <CCollapse show={details.includes(item.id)}>
                    <CCardBody>
                        {getDetailsText(item)}
                    {viewButtons(item)}
                  </CCardBody>
                </CCollapse>
              );
            },
          }}
        />
      </div>
    </CCardBody>
  );
}
