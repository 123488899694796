import React from "react";
import { styles } from "./styles";
import { Table } from "react-bootstrap";

export const InvoiceDetails = ({ client_invoices }) => {
    return (
        <div style={styles.infoSectionContainer}>
            <div style={styles.infoContainer}>
                <h3 style={styles.infoHeading}>Invoices</h3>
                {client_invoices && client_invoices.length > 0 ? (
                    <div style={styles.info}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Invoice No.</th>
                                    <th>Date</th>
                                    <th>Item</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>

                            <tbody>
                                {client_invoices.map((invoice) => (
                                    <tr>
                                        <td>{invoice.invoice_no}</td>
                                        <td>{invoice.date}</td>
                                        <td>{invoice.item}</td>
                                        <td>{invoice.amount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <div>No invoice detail available</div>
                )}
            </div>
        </div>
    );
};
