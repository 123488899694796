//@format
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchClientDetails } from "../../actions";
import { updateInvoice } from "../../actions";
import ReactDOM from "react-dom";
import Button from "@material-ui/core/Button";
import { PDFViewer } from "@react-pdf/renderer";
import { Row, Col } from "react-bootstrap";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
} from "@react-pdf/renderer";
import Header from "../ViewInvoice/Header";
import Body from "../ViewInvoice/Body";
import Footer from "../ViewInvoice/Footer";
import FooterLastLine from "../ViewInvoice/FooterLastLine";
// Create styles
import openSans from "../../assets/fonts/OpenSans-Regular.ttf";
import openSansiLight from "../../assets/fonts/OpenSans-Light.ttf";
import openSansBold from "../../assets/fonts/OpenSans-Bold.ttf";
import jamesAndJames from "../../assets/fonts/Flamenco-Regular.ttf";
import subHeading from "../../assets/fonts/ERAS.TTF";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useLocation, useHistory } from "react-router-dom";
import { useOrganisationBillingInfo } from "../../hooks/organisation-query";
import { SavingDetailsModal } from "../../utils/formComponents";
import stylesModel from "../modelstyles";
import cloneDeep from 'lodash/cloneDeep';
Font.register({
    family: "'Open Sans', sans-serif",
    src: openSansBold,
});
Font.register({
    family: "'Open Sans',light",
    src: openSansiLight,
});
Font.register({
    family: "Open Sans",
    src: openSans,
});
Font.register({
    family: "James",
    src: jamesAndJames,
});
Font.register({
    family: "subHeading",
    src: subHeading,
});

const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        padding: 30,
        //justifyContent: 'space-between'
    },
});

const DraftView = () => {
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        state: { values, id, invoiceOpe, invoiceBasics, updateAction },
    } = useLocation();
    console.log("draft values", values, invoiceOpe, invoiceBasics, updateAction);
    const [completeValues, setCompleteValues] = useState({});//if updateAction then we dont use this as wjile loading edit it will already have all ids present whereas while creation success it will be missing ids in nested fields,on finalising this after creation caused data duplication in nested attributes as ids is missing
    const [loading, setLoading] = useState(true);
    const [finalSaveLoading, setFinalSaveLoading] = useState(false);
    const invoiceAddMessage = useSelector(
        (state) => state.invoice.invoiceAddMessage
    );
    const invoiceFinalAddLoading = useSelector(
        (state) => state.invoice.invoiceAddLoading
    );
    const client_details = useSelector((state) => state.client.client_details);
    const invoiceAddFailed = useSelector(
        (state) => state.invoice.invoiceAddFailed
    );
    const [saveFailed, setFailed] = useState(true);
    const {
        data: org,
        isLoading,
        error,
    } = useOrganisationBillingInfo(values.organisation_id);
    const [invoiceDetails, setInvoiceDetails] = useState({
        bank_details: {},
        cgst: 0.0,
        client: {},
        grand_total: 0,
        igst: 0,
        invoice_date: "",
        invoice_number: "",
        kfc: 0,
        ccess: 0,
        organization: {},
        pan: null,
        particulars: [],
        opes: [],
        place_of_supply: "",
        reimbursements: 0,
        sgst: 0,
        taxable_amount: 0,
    });

    useEffect(() => {
        if (token && values && values.bill_to_client_id) {
            dispatch(
                fetchClientDetails(
                    values.bill_to_client_id,
                    token,
                    "client_details"
                )
            );
        }
        if (values && values.invoice_basics_attributes) {
            setInvoiceDetails((details) => {
                return {
                    ...details,
                    particulars: removeDeletedParticulars(
                        values.invoice_basics_attributes
                    ),
                };
            });
            setInvoiceDetails((details) => {
                return { ...details, cgst: 0 };
            });
            setInvoiceDetails((details) => {
                return { ...details, grand_total: 0 };
            });
            setInvoiceDetails((details) => {
                return { ...details, igst: 0 };
            });
            setInvoiceDetails((details) => {
                return { ...details, sgst: 0 };
            });
            setInvoiceDetails((details) => {
                return { ...details, kfc: 0 };
            });
            setInvoiceDetails((details) => {
                return { ...details, ccess: 0 };
            });
            setInvoiceDetails((details) => {
                return { ...details, reimbursements: 0 };
            });
            setInvoiceDetails((details) => {
                return { ...details, taxable_amount: 0 };
            });

            values.invoice_basics_attributes.map((attr) => {
                if (!attr._destroy) {
                    setInvoiceDetails((details) => {
                        return { ...details, cgst: details.cgst + attr.cgst };
                    });
                    setInvoiceDetails((details) => {
                        return { ...details, kfc: details.kfc + attr.scess };
                    });
                    setInvoiceDetails((details) => {
                        return { ...details, ccess: details.ccess + attr.ccess };
                    });
                    setInvoiceDetails((details) => {
                        return { ...details, igst: details.igst + attr.igst };
                    });
                    setInvoiceDetails((details) => {
                        return { ...details, sgst: details.sgst + attr.sgst };
                    });
                    setInvoiceDetails((details) => {
                        return {
                            ...details,
                            taxable_amount:
                                details.taxable_amount + attr.base_amount,
                        };
                    });
                }
            });
            if(values && values.invoice_opes_attributes){
            	setInvoiceDetails((details) => {
                    return {
                        ...details,
                        opes: removeDeletedParticulars(
                        values.invoice_opes_attributes
                        ),
                    };
            	});
                values.invoice_opes_attributes.map((attr) => {
                    if (!attr._destroy) {
                        setInvoiceDetails((details) => {
                            return {
                                ...details,
                                grand_total: details.grand_total + attr.amount,
                            };
                        });
                        setInvoiceDetails((details) => {
                            return {
                                ...details,
                                reimbursements:
                                    details.reimbursements + attr.amount,
                            };
                        });
                    }
                });
            }
            setInvoiceDetails((details) => {
                return {
                    ...details,
                    grand_total:
                        details.grand_total +
                        details.reimbursements +
                        details.taxable_amount +
                        details.igst +
                        details.cgst +
                        details.sgst +
                        details.kfc +
                        details.ccess
                };
            });
        }
        if (values && values.invoice_date)
            setInvoiceDetails((details) => {
                return { ...details, invoice_date: values.invoice_date };
            });
        if (values && values.invoice_number)
            setInvoiceDetails((details) => {
                return { ...details, invoice_number: values.invoice_number };
            });
    }, [token, values, dispatch]);
    useEffect(() => {
        let temp = cloneDeep(values)
        console.log("draft view values before updation",values, updateAction)
        let basic = {}, ope = {}
        if(!updateAction){// for update action we have all the id details
            if(temp['invoice_basics_attributes']){
                //we need preserve tax rates from create form + we need id of
                //each attributes we get after save in case of create action
                //only
                temp['invoice_basics_attributes'].forEach((item, index)=>{
                    basic = invoiceBasics.find(obj =>(obj.particulars === item.particulars && obj.base_amount == item.base_amount)
)
                    item['id'] = basic['id']
                    item['_destroy'] = false
                    item['invoice_basic_details_attributes'] = basic['invoice_basic_details_attributes']
                })
            }
            if(temp['invoice_opes_attributes']){
                temp['invoice_opes_attributes'].forEach((item, index)=>{
                    ope = invoiceOpe.find(obj =>(obj.particulars === item.particulars && obj.amount == item.amount)
)
                    item['id'] = ope['id']
                    item['_destroy'] = false
                    item['invoice_ope_details_attributes'] = ope['invoice_ope_details_attributes']
                })

            }
            setCompleteValues(temp)
            console.log("draft view complete values after updation",completeValues,temp )
        }
    }, [id])
    useEffect(() => {
        console.log("invoice details", invoiceDetails);
    }, [invoiceDetails]);

    useEffect(() => {
        setInvoiceDetails((details) => {
            return { ...details, client: client_details };
        });
    }, [client_details]);

    useEffect(() => {
        if (!saveFailed) history.goBack();
    }, [saveFailed]);

    useEffect(() => {
        if (org) {
            setInvoiceDetails((details) => {
                return { ...details, organisation: org.organisation };
            });
            setInvoiceDetails((details) => {
                return { ...details, bank_details: org.bank_details };
            });
            setLoading(false);
        }
    }, [org]);
    function removeDeletedParticulars(data) {
        //remove where _destroy true
        return data.filter(function (el) {
            return !el._destroy;
        });
    }
    function finalise() {
	console.log("values check finalise start", values,completeValues)
        removeDeleted()//to remove delete data, as deleteing was already performed this data is irrelevent
        setFinalSaveLoading(true);
        if(updateAction){
            values["category"] = 0;
            dispatch(updateInvoice(values, token, id));
        }else{
            completeValues["category"] = 0;
            dispatch(updateInvoice(completeValues, token, id));
        }
        console.log("values check finalise after operation", values,updateAction)
    }
    function removeDeleted() {
        values["invoice_basics_attributes"] = removeDeletedParticulars(
            values.invoice_basics_attributes
        );
        if(values && values['invoice_opes_attributes'])values["invoice_opes_attributes"] = removeDeletedParticulars(
            values.invoice_opes_attributes
        );
    }
    return (
        <div>
            {loading ? (
                <ProgressBar striped variant="success" animated now={40} />
            ) : (
                <>
                    <Row xs={2} md={4} lg={6}>
                        <Col>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    history.replace({
                                        pathname: "/addinvoice",
                                        data: { id: id, type: "update" },
                                    })
                                }
                            >
                                Edit
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => finalise()}
                            >
                                Finalise
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => history.goBack()}
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                    <PDFViewer style={{ height: "100vh", width: "100vw" }}>
                        <Document>
                            <Page size="A4" style={styles.page}>
                                <Header props={invoiceDetails.organisation} nature={values.nature}/>
                                <Body props={{...invoiceDetails, currency_code: values.currency_code, currency_name: values.currency_name}} />
                                <Footer props={{...invoiceDetails, nature: values.nature}} />
                                <FooterLastLine />
                            </Page>
                        </Document>
                    </PDFViewer>
                    <SavingDetailsModal
                        className={stylesModel.modal}
                        show={finalSaveLoading}
                        title={"Saving Invoice Details"}
                        loading={invoiceFinalAddLoading}
                        message={invoiceAddMessage}
                        onClick={() => {
                            setFinalSaveLoading(invoiceFinalAddLoading);
                            setFailed(invoiceAddFailed);
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default DraftView;
