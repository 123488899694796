import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import get from "lodash/get";
import Ampersand from "./helper";
const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        marginTop: "2%",
        alignItems: "flex-end",
        justifyContent: "center",
    },
    notes: {
        width: "65%",
        flexDirection: "column",
    },
    bottomLogo: {
        width: "35%",
        flexDirection: "column",
        alignItems: "flex-end",
    },
    notesTitle: {
        borderBottomWidth: 1,
        borderBottomColor: "#112131",
        borderBottomStyle: "solid",
        flexDirection: "column",
        paddingTop: "3%",
    },
    notesTitleText: {
        marginTop: "2%",
        fontSize: 10,
        fontFamily: "'Open Sans', sans-serif",
    },
    notesText: {
        fontSize: 8,
        fontFamily: "'Open Sans',light",
        marginTop: "1%",
    },
    footerOrgName: {
        fontSize: 16,
        fontFamily: "James",
        marginTop: "1%",
    },
    signatureColumn:{
        borderWidth: 1,
        borderColor: '#112131',
        borderStyle: 'solid',
        width: "40%",
        height: "10%",
        marginTop: "9%"
    }
});

export default ({ props }) => (
    <View style={styles.container}>
        <View style={styles.notes}>
            <View style={styles.notesTitle}>
                <Text style={styles.notesTitleText}>Notes</Text>
            </View>
            <Text style={styles.notesText}>
                -Tax should not be deducted on the GST component charged on the
                invoice as per Circular no. 23 of 2011 dated 19 July 2017 issued
                by the Central Board of Direct Taxes, Ministry of Finance,
                Government of lndia.
            </Text>
            <Text style={styles.notesText}>
                -Tax on this invoice is not payable under reverse charge.
            </Text>
            <Text style={styles.notesText}>
                -All invoices are due on receipt. We appreciate your prompt
                payment.
            </Text>
            <Text
                style={styles.notesText}
            >{`-Cheque/DD shoutd be made payable to '${get(
                props,
                "organisation.name",
                ""
            )}'. Payments can also be made by RTGS, NEFI or lMPS to the following account:`}</Text>
            <Text style={styles.notesText}>-Bank Account details</Text>
            <Text
                style={[styles.notesText, { marginLeft: "2%" }]}
            >{`Account Name-${get(props, "bank_details.acc_name")}`}</Text>
            <Text style={[styles.notesText, { marginLeft: "2%" }]}>{`${get(
                props,
                "bank_details.bank_name",
                "-"
            )} ${get(props, "bank_details.location", "")}`}</Text>
            <Text
                style={[styles.notesText, { marginLeft: "2%" }]}
            >{`Account Number-${get(props, "bank_details.acc_num", "")}`}</Text>

            <Text
                style={[styles.notesText, { marginLeft: "2%" }]}
            >{`IFSC Code-${get(props, "bank_details.ifsc")} `}</Text>
            <Text></Text>
            {props.nature == "E" && <Text
                style={styles.notesText}
            >-Supply meant for export under Bond or Letter of Undertaking without payment of Integrated Tax (IGST)</Text>}
            <Text style={styles.notesText}>-E &O.E</Text>
        </View>
        <View style={styles.bottomLogo}>
            <View>
                {props.organisation.code === "JNR" ? (
                    <Text style={styles.notesText}>
                        For{" "}Joseph & Rajaram
                        {/**<Text style={styles.footerOrgName}>
                            Joseph <Ampersand /> Rajaram
                            </Text>**/}
                    </Text>
                ) : (
                    <Text style={styles.notesText}>
                        For{" "}James & James
                        {/**<Text style={styles.footerOrgName}>
                            James <Ampersand /> James
                            </Text>**/}
                    </Text>
                )}
            </View>
            {props.organisation.code !== "JJBS" ? (
                <Text style={[styles.notesText,{fontSize: 7.5}]}>Chartered Accountants</Text>
            ) : (
                <Text style={[styles.notesText, {fontSize: 5}]}> BUSSINESS SOLUTIONS LLP </Text>
            )}
            <View style={styles.signatureColumn}></View>
        </View>
    </View>
);
