import axios from "../../axiosConfig";
import { useQuery,useMutation, useQueryClient} from "react-query";


async function leaveapprovelAction(data){
    //try{
        let url = ""
        if(data["leave_status"] == "Approved"){
           url = "approve_leave"
        }else if(data["leave_status"] == "Rejected"){
           url = "reject_leave"
        }else{
            url = "withdraw_leave"
        }
        //let url = data["leave_status"] == "Approved" ? "approve_leave" : "reject_leave"
        const res = await axios.patch(`/${url}/${data.id}`,data);
        return res
        /**}catch(err){
        console.log("error in approve",err)
        throw err.response.data;
    }**/
}


async function getLeaveDetailsById({queryKey}){
    try{
        const res = await axios.get(`/leave/${queryKey[1]}`);
        return res.data;
    }catch(err){

        throw err.response.data;
    }
}


export const useLeave = (id) => useQuery(['recurringProject',id], getLeaveDetailsById,{
    refetchOnWindowFocus: false,
    enabled: !!id
})
export const useLeaveUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation(
  (data) => leaveapprovelAction(data),
    {
        onSuccess: (recurringProject,data) => { queryClient.invalidateQueries('recurringProjects')}
    }
)}

