import React, { useState } from "react";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import { styles } from "./styles";

export const ContactAccordion = ({ contact }) => {
    const [showDetails, setShowDetails] = useState(false);
    return (
        <div style={styles.contactAccordionContainer}>
            <div
                onClick={() => setShowDetails((details) => !details)}
                style={styles.contactAccordion}
            >
                <div>More</div>
                {showDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
            {showDetails && (
                <div style={styles.info}>
                    <div>
                        <div>PAN</div>
                        <div>Aadhar</div>
                        <div>DIN</div>
                        <div>Address</div>
                        <div>City</div>
                        <div>PIN</div>
                        <div>State</div>
                        <div>status</div>
                    </div>
                    <div>
                        <div>{contact.pan}</div>
                        <div>{contact.aadhaar}</div>
                        <div>{contact.din}</div>
                        <div>{contact.address}</div>
                        <div>{contact.city}</div>
                        <div>{contact.pin}</div>
                        <div>{contact.state}</div>
                        <div>{contact.status}</div>
                    </div>
                </div>
            )}
        </div>
    );
};
