import React from "react";
import { styles } from "./styles";
import { AssignmentInd } from "@material-ui/icons";
import { Table } from "react-bootstrap";

export const Credentials = ({ client_credentials }) => {
    return (
        <div style={styles.infoContainer}>
            {client_credentials ? (

                <Table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Portal</th>
                            <th>Username</th>
                            <th>Password</th>
                            <th>Remarks</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {client_credentials.map((credential) => (
                        <tr>
                                <td>{credential.id || "---"}</td>
                                <td>{credential.portal || "---"}</td>
                                <td>{credential.username_digest || "---"}</td>
                                <td>
                                    {credential.password_digest}
                                    <span
                                        onClick={() =>
                                            navigator.clipboard.writeText(
                                                credential.password_digest
                                            )
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <AssignmentInd />
                                    </span>{" "}
                                </td>
                                <td>{credential.remarks || "---"}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <div>No credentials</div>
            )}
        </div>
    );
};
