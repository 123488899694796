import {START_FETCH_SETTING,FETCHING_SETTING_FAILED,FETCHING_SETTING_SUCCESS, START_UPDATE_SETTING,UPDATE_SETTING_SUCCESS,UPDATE_SETTING_FAILED,START_FETCH_SETTING_LIST,FETCH_SETTING_LIST_FAILED,FETCH_SETTING_LIST_SUCCESS,START_ADD_SETTING,ADD_SETTING_SUCCESS,ADD_SETTING_FAILED} from '../actions/types';

const DEFAULT_STATE = {
    settingsList: [],
    loading: false,
    setting: {},
    settingAddLoading: false,
    settingAddFailed: true,
    message: '',
    errorMessage: ''
}
export default (state = DEFAULT_STATE,action) =>{
    switch(action.type){
        case START_UPDATE_SETTING:
            return{
                ...state,
                settingAddLoading: true,
                settingAddFailed: false
            }
        case UPDATE_SETTING_SUCCESS:
            return{
                ...state,
                settingAddFailed: false,
                settingAddLoading: false,
                message:action.payload
            }
        case UPDATE_SETTING_FAILED:
            return{
                ...state,
                settingAddFailed: true,
                settingAddLoading: false,
                message: action.payload
            }
        case START_FETCH_SETTING_LIST:
            return{
                ...state,
                loading: true,
                settingsList: []
            }
        case FETCH_SETTING_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                settingsList: action.payload
            }
        case FETCH_SETTING_LIST_FAILED:
            return{
                ...state,
                loading: false,
                settingsList: []
            }
        case START_FETCH_SETTING:
            return{
                ...state,
                loading: true,
                setting:{}
            }
        case FETCHING_SETTING_SUCCESS:
            return{
                ...state,
                loading: false,
                setting: action.payload
            }
        case FETCHING_SETTING_FAILED:
            return{
                ...state,
                loading: false,
                setting: {}
            }
        case START_ADD_SETTING:
            return{
                ...state,
                settingAddFailed: false,
                settingAddLoading: true,
                message:''
            }
        case ADD_SETTING_SUCCESS:
            return{
                ...state,
                settingAddFailed: false,
                settingAddLoading: false,
                message:action.payload
            }
        case ADD_SETTING_FAILED:
            return{
                ...state,
                settingAddFailed: true,
                settingAddLoading: false,
                message:action.payload
            }
        default:
            return state
    }
}
