import React from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
function PendingVerification(){

    return(
        <Container>
            <Row><h1><Badge variant="dark">Welcome to Cue, plese wait for verification from admin</Badge></h1></Row>
        </Container>
    )
}
export default PendingVerification;
