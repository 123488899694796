import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CommonList from '../views/CommonTableView';
const OpePage = () => {
    return (
        <PageTemplate>
           <CommonList
            page={"ope"}
        />
        </PageTemplate>
    );
};

export default OpePage;

