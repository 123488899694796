import {
    INVOICES_FETCH_SUCCESS,
    INVOICES_FETCH_FAILED,
    START_INVOICES_FETCH,
    START_INVOICE_ADD,
    INVOICE_ADD_SUCCESS,
    INVOICE_ADD_FAILURE,
    START_INVOICE_UPDATE,
    START_INVOICE_FETCH_BY_ID,
    INVOICE_FETCH_BY_ID_SUCCESS,
    INVOICE_FETCH_BY_ID_FAILED,
    INVOICE_UPDATE_FAILED,
    INVOICE_UPDATE_SUCCESS,
    START_FINAL_INVOICE_FETCH,
    SUCCESS_FINAL_INVOICE_FETCH,
    FAILED_FINAL_INVOICE_FETCH,
    GET_INVOICE_NUMBER_FETCH,
    GET_INVOICE_NUMBER_FAIL,
    GET_INVOICE_NUMBER_SUCCESS,
} from "../actions/types";
const DEFAULT_STATE = {
    invoicesList: [],
    invoice: [],
    invoiceId: null,//for handling drafts view,edit
    invoiceOpe: [],
    invoiceBasics: [],
    loading: false,
    errorMessage: "",
    invoiceAddFailed: true,
    invoiceAddLoading: false,
    invoiceAddMessage: "",
    finalInvoice: {},
    invoiceNumber: "",
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case START_FINAL_INVOICE_FETCH:
            return {
                ...state,
                loading: true,
                finalInvoice: {},
            };
        case SUCCESS_FINAL_INVOICE_FETCH:
            return {
                ...state,
                loading: false,
                finalInvoice: action.payload,
            };
        case FAILED_FINAL_INVOICE_FETCH:
            return {
                ...state,
                loading: false,
                finalInvoice: {},
            };
        case START_INVOICE_FETCH_BY_ID:
            return {
                ...state,
                loading: true,
            };

        case INVOICE_FETCH_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                invoice: action.payload,
            };
        case INVOICE_FETCH_BY_ID_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
            };
        case START_INVOICE_UPDATE:
            return {
                ...state,
                invoiceAddFailed: false,
                invoiceAddLoading: true,
                invoiceAddMessage: "",
                invoiceId: null
            };
        case INVOICE_UPDATE_SUCCESS:
            return {
                ...state,
                invoiceAddFailed: false,
                invoiceAddLoading: false,
                invoiceAddMessage: action.payload,
                invoiceId: action.id
            };
        case INVOICE_UPDATE_FAILED:
            return {
                ...state,
                invoiceAddFailed: true,
                invoiceAddLoading: false,
                invoiceAddMessage: action.payload,
            };
        case START_INVOICE_ADD:
            return {
                ...state,
                invoiceAddFailed: false,
                invoiceAddLoading: true,
                invoiceAddMessage: "",
                invoiceId: null,
                invoiceBasics: [],
                invoiceOpe: []
            };

        case INVOICE_ADD_SUCCESS:
            return {
                ...state,
                invoiceAddFailed: false,
                invoiceAddLoading: false,
                invoiceAddMessage: action.payload,
                invoiceId: action.data.id,
                invoiceOpe: action.data.invoice_opes_attributes,
                invoiceBasics: action.data.invoice_basics_attributes
            };
        case INVOICE_ADD_FAILURE:
            return {
                ...state,
                invoiceAddFailed: true,
                invoiceAddLoading: false,
                invoiceAddMessage: action.payload,
            };

        case START_INVOICES_FETCH:
            return {
                ...state,
                loading: true,
                invoicesList: [],
            };

        case INVOICES_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                invoicesList: action.payload,
            };
        case INVOICES_FETCH_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload,
            };
        case GET_INVOICE_NUMBER_FETCH:
            return {
                ...state,
                invoiceNumber: "",
            };
        case GET_INVOICE_NUMBER_FAIL:
            return {
                ...state,
                invoiceNumber: action.payload,
            };
        case GET_INVOICE_NUMBER_SUCCESS:
            return {
                ...state,
                invoiceNumber: action.payload,
            };
        default:
            return state;
    }
};
