import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Select from 'react-select';
import { Checkbox, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import Header from '../../components/Layout/Header/Header';
import { Form, Formik } from 'formik';
import {
  useCreateSpecMaster,
  useUpdateSpecMaster,
} from '../../hooks/spec-query/spec';
import { AlertComponent } from '../../utils/formComponents';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

const options = [
  { value: 0, label: 'String', dbValue: 'is_string' },
  { value: 1, label: 'Boolean', dbValue: 'is_boolean' },
  { value: 2, label: 'Numerical', dbValue: 'is_numerical' },
  { value: 3, label: 'Date', dbValue: 'is_date' },
];

let initValues = {
  name: '',
  detailed_name: '',
  field_type: options[0],
  max_characters: null,
  min_characters: null,
  allow_symbols: false,
};

const AddSpecMaster = () => {
  const router = useHistory();
  const formData = useSelector((state) => state.specmaster.form);
  const [showMaxMinChar, setShowMaxMinChar] = React.useState(false);
  const specMutate = useCreateSpecMaster();
  const specUpdate = useUpdateSpecMaster();
  const [error, setError] = React.useState(null);
  const [showDetails, setShowDetails] = React.useState(false);

  const optionInitValue = () => {
    if (formData) {
      return find(options, { dbValue: formData.field_type });
    }
    return options[0];
  };

  const initialValues = React.useMemo(() => {
    const hasForm = formData ? formData : false;

    if (hasForm) {
      const findFieldType = find(options, { dbValue: formData.field_type });
      setShowMaxMinChar(findFieldType.value);
      const initForm = Object.keys(formData).reduce((acc, cum) => {
        if (cum === 'field_type') {
          return {
            ...acc,
            [cum]: findFieldType,
          };
        }
        return {
          ...acc,
          [cum]: formData[cum],
        };
      }, {});
      delete initForm.created_at;
      delete initForm.updated_at;
      return initForm;
    }
    return initValues;
  }, [formData]);

  return (
    <Container>
      <Header title='add_spec_master' />
      <Typography
        component='h1'
        variant='h2'
        align='center'
        color='textPrimary'
        gutterBottom
      >
        Add Spec Master
      </Typography>
      <Row>
        <Col sm={4}>
          <Typography
            component='h6'
            variant='h6'
            align='center'
            color='error'
            gutterBottom
          >
            {error}
          </Typography>
        </Col>
      </Row>
      <Formik
        initialValues={initialValues}
        onSubmit={async (data) => {
          if (formData) {
            const form = {
              id: formData.id,
              data,
            };
            specUpdate.mutate(form, {
              onSuccess: () => {
                setShowDetails(true);
                router.goBack();
              },
              onError: (err) => {
                setError(err.message);
              },
            });
          } else {
            specMutate.mutate(data, {
              onSuccess: () => {
                setShowDetails(true);
                router.goBack();
              },
              onError: (err) => {
                setError(err.message);
              },
            });
          }
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Row>
                <Col sm={6}>
                  <label>Name</label>
                  <TextField
                    type='text'
                    fullWidth
                    margin='normal'
                    autoFocus
                    id='name'
                    variant='outlined'
                    label='name '
                    required
                    defaultValue={formData && formData.name}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                  />
                </Col>
                <Col sm={6}>
                  <label>Detailed Name</label>
                  <TextField
                    type='text'
                    required
                    fullWidth
                    variant='outlined'
                    margin='normal'
                    autoFocus
                    id='detailed_name'
                    label='detailed name'
                    defaultValue={formData && formData.detailed_name}
                    onChange={(e) =>
                      setFieldValue('detailed_name', e.target.value)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <label
                    id='field_type'
                    style={{ marginTop: '12px', marginBottom: '16px' }}
                  >
                    Field type
                  </label>
                  <Select
                    id='field_type'
                    required
                    options={options}
                    defaultValue={optionInitValue()}
                    onChange={(e) => {
                      setShowMaxMinChar(e.value);
                      setFieldValue('field_type', e.value);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  {showMaxMinChar === 0 && (
                    <>
                      <label
                        id='allow_symbols'
                        style={{ marginTop: '12px', marginBottom: '12px' }}
                      >
                        Allow symbols?
                      </label>
                      <Checkbox
                        id='allow_symbols'
                        defaultChecked={formData && formData.allow_symbols}
                        onChange={(e) =>
                          setFieldValue('allow_symbols', e.target.checked)
                        }
                      />
                    </>
                  )}
                </Col>
              </Row>
              {(showMaxMinChar === 0 || showMaxMinChar === 2) && (
                <Row>
                  <Col sm={6}>
                    <label
                      style={{ marginTop: '12px', marginBottom: '12px' }}
                      id='max_characters '
                    >
                      Maximum Characters
                    </label>
                    <TextField
                      type='number'
                      fullWidth
                      margin='normal'
                      autoFocus
                      required={
                        values.field_type === 0 || values.field_type === 2
                      }
                      id='max_characters'
                      variant='outlined'
                      defaultValue={formData && formData.max_characters}
                      disabled={values.field_type}
                      onChange={(e) =>
                        setFieldValue('max_characters', e.target.value)
                      }
                    />
                  </Col>
                  <Col sm={6}>
                    <label
                      style={{ marginTop: '12px', marginBottom: '12px' }}
                      id='min_characters'
                    >
                      Minimum Characters
                    </label>
                    <TextField
                      type='number'
                      fullWidth
                      margin='normal'
                      variant='outlined'
                      autoFocus
                      defaultValue={formData && formData.min_characters}
                      required={
                        values.field_type === 0 || values.field_type === 2
                      }
                      disabled={values.field_type}
                      id='min_characters'
                      onChange={(e) =>
                        setFieldValue('min_characters', e.target.value)
                      }
                    />
                  </Col>
                </Row>
              )}

              <Row style={{ marginTop: 20 }}>
                <Col>
                  <Row className='float-right'>
                    <Col>
                      <Button type='submit' variant='contained' color='primary'>
                        {formData ? 'UPDATE' : 'Add'}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>

      <AlertComponent
        title='Saving'
        description={'Spec master successfully created'}
        saveText={'OK'}
        open={showDetails}
        proceed={() => {
          setShowDetails(false);
          router.goBack();
        }}
      />
    </Container>
  );
};

export default AddSpecMaster;
