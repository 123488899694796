/* eslint no-undef: "off"*/
import get from "lodash/get";
export const getBrowser = () => {
    // Opera 8.0+
    console.log(typeof opr);
    var oprAddons;
    if (typeof opr === "object") {
        oprAddons = get(opr, 'opr.addons', 'undefined');
    } else {
        oprAddons = false;
    }
    var isOpera =
        (!!window.opr && !!oprAddons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0;

    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
        })(
            !window["safari"] ||
                (typeof safari !== "undefined" &&
                    window["safari"].pushNotification)
        );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    var isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

    // Blink engine detection
    var isBlink = (isChrome || isOpera) && !!window.CSS;
    //check db user_devices, browser enum values[based on those i am returning
    //these]
    if (isSafari) {
        return 0;
    } else if (isFirefox) {
        return 1;
    } else if (isOpera) {
        return 2;
    } else if (isChrome) {
        return 3;
    } else {
        return 4;
    }
};
