import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCurrentUser, fetchTokenLocal } from "../../actions";
export default (OriginalComponent) => {
    class MixedComponent extends Component {
        componentWillMount() {
            if (!this.props.user) {
                this.fetchUser();
            }
        }
        componentDidMount() {
            if (typeof this.props.token == "undefined") {
                this.props.fetchTokenLocal();
            }

            if (!this.props.isAuth && !this.props.token) {
                this.props.history.push("/login");
            }
        }
        componentDidUpdate() {
            if (!this.props.isAuth && !this.props.token) {
                this.props.history.push("/login");
            }
        }
        async fetchUser() {
            await this.props.fetchCurrentUser(this.props.token);
            if (!this.props.isAuth && !this.props.loading) {
                this.props.history.push("/login");
            } else {
            }
        }
        render() {
            return <OriginalComponent />;
        }
    }
    function mapStateToProps(state) {
        return {
            isAuth: state.auth.isAuthenticated,
            token: state.auth.token,
            user: state.auth.user,
            loading: state.auth.loading
        };
    }

    return connect(mapStateToProps, { fetchCurrentUser, fetchTokenLocal })(
        MixedComponent
    );
};
