
import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field,change } from "redux-form";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import MultiSelectComponent from "../../utils/multiselect"
import {InputText,TextArea,SelectInput,validate,SavingDetailsModal} from "../../utils/formComponents";
class AddNewRegister extends Component{

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        issueRegisterFailed: true
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
}

componentDidUpdate(){
    if(!this.state.issueRegisterFailed){
      this.props.history.goBack();
    }
  }

closeModal(){
    this.setState({
        loading:this.props.loading,
        issueRegisterFailed:this.props.issueRegisterFailed
    })
}
async onsubmit(formData) {
    this.setState({
        loading:true
    })
    await this.props.issueNewRegister(this.props.token,formData);
    this.setState({
        message:this.props.message
    })
}
  render () {
    let state = this.state;
const { handleSubmit,classes } = this.props;
    return (
      <Container>
        <Header title="add_new_register" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" >
       Issue Register
      </Typography>
        <form onSubmit={handleSubmit(this.onsubmit)}>
          <Row>
            <Col sm={8}>
              <span>Name of the DSC holder</span>
              <Field
                name="name"
                label="Name of DSC holder"
                component={InputText}
                type="text"
                fullWidth
             />
            </Col>
            <Col sm={4}>
            <span>Token Provider</span>
              <Field
                name="token_provider"
                label="token provider"
                component={InputText}
                type="text"
                fullWidth
             />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <span>Token Number</span>
              <Field
                name="token_number"
                label="token number *"
                component={InputText}
                type="number"
                fullWidth
              />
            </Col>
            <Col sm={4}>
              <span>Issue Date</span>
              <Field
                name="issue_date"
                component={InputText}
                type="date"
                fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
                <span>Expiry Date</span>
                <Field
                name="expiry_date"
                label=""
                component={InputText}
                type="date"
                fullWidth
            />
    </Col>
            <Col sm={4}>
                <span>Password</span>
                <Field
                name="password"
                label="Password"
                component={InputText}
                type="text"
                fullWidth
            />
            </Col>
        </Row>
        <Row>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving Details  "}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>
          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    )
  }
}

function mapStateToProps(state) {
    return {
        token:state.auth.token,
        message:state.issueregister.issueRegisterMessage,
        loading:state.issueregister.issueRegisterLoading,
        issueRegisterFailed:state.issueregister.issueRegisterFailed
    }
}
export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddNewRegister",
validate }),
    withStyles(styles)
)(AddNewRegister);
