import React, { useState } from "react";
import { styles } from "./styles";
import { Table } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { AssignmentInd } from "@material-ui/icons";

export const DscDetails = ({ client_dscs, signed_documents }) => {
    const [show, setShow] = useState(false);
    return (
        <div>
            <div style={styles.infoContainer}>
                <h3 style={styles.infoHeading}>Tokens</h3>

                {client_dscs && client_dscs.length > 0 ? (
                    <Table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Password</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {client_dscs.map((dsc) => (
                                <tr>
                                    <td>{dsc.id || "---"}</td>
                                    <td>{dsc.name || "---"}</td>
                                    <td>{dsc.status || "---"}</td>
                                    <td>
                                        {dsc.password ? (
                                            <>
                                                ********{"  "}
                                                <span
                                                    onClick={() =>
                                                        navigator.clipboard.writeText(
                                                            dsc.password
                                                        )
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <AssignmentInd />
                                                </span>{" "}
                                            </>
                                        ) : (
                                            "---"
                                        )}
                                    </td>
                                    <td>
                                        <Button
                                            variant="primary"
                                            onClick={() => setShow(true)}
                                        >
                                            More
                                        </Button>
                                    </td>

                                    <Modal
                                        show={show}
                                        onHide={() => setShow(false)}
                                        style={{
                                            marginTop: "50px",
                                        }}
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                {dsc.name}
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div style={styles.info}>
                                                <div>
                                                    <div>Token Provider</div>
                                                    <div>Token No.</div>
                                                    <div>Expiry date</div>
                                                    <div>Remarks</div>
                                                    <div>Custodian</div>
                                                </div>
                                                <div>
                                                    <div>
                                                        {dsc.token_provider ||
                                                            "---"}
                                                    </div>
                                                    <div>
                                                        {dsc.token_no || "---"}
                                                    </div>
                                                    <div>
                                                        {dsc.expiry_date ||
                                                            "---"}
                                                    </div>
                                                    <div>
                                                        {dsc.remarks || "---"}
                                                    </div>
                                                    <div>
                                                        {dsc.custodian || "---"}
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant="secondary"
                                                onClick={() => setShow(false)}
                                            >
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div> No DSC found </div>
                )}
            </div>
            <div style={styles.infoContainer}>
                <h3 style={styles.infoHeading}>Signed Documents</h3>

                {signed_documents && signed_documents.length > 0 ? (
                    <Table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Project</th>
                                <th>Date of signing</th>
                                <th>Document Category</th>
                                <th>Document paticulars</th>
                                <th>Created by</th>
                            </tr>
                        </thead>

                        <tbody>
                            {signed_documents.map((dsc) => (
                                <tr>
                                    <td>{dsc.id || "---"}</td>
                                    <td>{dsc.name || "---"}</td>
                                    <td>{dsc.project || "---"}</td>
                                    <td>{dsc.date_of_signing || "---"}</td>
                                    <td>{dsc.document_category || "---"}</td>
                                    <td>{dsc.document_particulars || "---"}</td>
                                    <td>{dsc.created_by || "---"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div> No DSC found </div>
                )}
            </div>
        </div>
    );
};
