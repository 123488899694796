import React from "react";
import { styles } from "./styles";
import { Table } from "react-bootstrap";

export const StatutoryDetails = ({ client_details }) => {
    return (
        <div style={styles.infoSectionContainer}>
            <div style={styles.infoContainer}>
                <h3 style={styles.infoHeading}>GSTIN</h3>
                {client_details.client_gstins_attributes &&
                client_details.client_gstins_attributes.length > 0 ? (
                    <div style={styles.info}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>GSTIN</th>
                                    <th>State</th>
                                    <th>Taxable</th>
                                </tr>
                            </thead>

                            <tbody>
                                {client_details.client_gstins_attributes.map(
                                    (gst) => (
                                        <tr>
                                            <td>{gst.gstin}</td>
                                            <td>{gst.state}</td>
                                            <td>
                                                {gst.taxable ? "YES" : "NO"}
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <div>No GSTIN detail available</div>
                )}
            </div>

            <div style={styles.infoContainer}>
                <h3 style={styles.infoHeading}>TAN</h3>
                {client_details.client_tans_attributes &&
                client_details.client_tans_attributes.length > 0 ? (
                    <div style={styles.info}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Tan</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>

                            <tbody>
                                {client_details.client_tans_attributes.map(
                                    (tan) => (
                                        <tr>
                                            <td>{tan.tan}</td>
                                            <td>{tan.remarks}</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <div>No TANs detail available</div>
                )}
            </div>

            <div style={styles.infoContainer}>
                <h3 style={styles.infoHeading}>Other details</h3>
                <div style={styles.info}>
                    <div>
                        <div>PAN</div>
                        <div>Aadhaar</div>
                        <div>CIN</div>
                        <div>LLPIN</div>
                    </div>
                    <div>
                        <div>{client_details.pan || "--"}</div>
                        <div>{client_details.aadhaar || "--"}</div>
                        <div>{client_details.cin || "--"}</div>
                        <div>{client_details.llpin || "--"}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
