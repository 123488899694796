import React, { Component } from "react";
import {
    Row,
    Col,
    Container,
    Badge,
    Card,
    Table,
    ProgressBar,
} from "react-bootstrap";
import Select from "react-select";
import Header from "../../components/Layout/Header/Header";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import styles from "../modelstyles";
import { AlertComponent } from "../../utils/formComponents";
import {
    useEmployeeDesignation,
    useEmployeeDetails,
    useEmployeeDropDown,
    useEmployeeApprove,
} from "../../hooks/employee-query/employee";
import {
    useDesignation,
    useOrganisation,
} from "../../hooks/organisation-query";
import { useRoles } from "../../hooks/client-query/clients";
import { Formik, Field, Form, ErrorMessage, FieldArray, getIn } from "formik";
import { useHistory, useLocation } from "react-router-dom";
const validate = (inputs) => {
    let errors = {};
    console.log("values", inputs);
    if (!inputs.doj) errors.doj = "Required";
    if (!inputs.qualification) errors.qualification = "Required";
    if (!inputs.icai_article) errors.icai_article = "Required";
    if (!inputs.designation_id) errors.designation_id = "Required";
    if (!inputs.access_role_id) errors.access_role_id = "Required";
    if (!inputs.approvel_status) errors.approvel_status = "Required";
    if (!inputs.reporting_to_id) errors.reporting_to_id = "Required";
    if (!inputs.leave_reporting_to_id)
        errors.leave_reporting_to_id = "Required";
    if (!inputs.prior_years_of_experiance)
        errors.prior_years_of_experiance = "Required";
    if (!inputs.initial) errors.initial = "Required";
    if (!inputs.organisation_id) errors.organisation_id = "Required";
    return errors;
};
const selectStyles = {
    menu: (base) => ({
        ...base,
        zIndex: 100,
    }),
};
const ApproveEmployee = () => {
    const location = useLocation();
    const employeeQuery = useEmployeeDetails(location.state.id);
    const orgQuery = useOrganisation();
    const rolesQuery = useRoles();
    const designationQuery = useDesignation();
    const employeeDesignationQuery = useEmployeeDesignation(location.state.id);
    const employeeDropDown = useEmployeeDropDown();
    const [showDetails, setShowDetails] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const history = useHistory();
    const {
        isLoading: updateLoading,
        mutate,
        data: updateData,
        error,
        isSuccess,
    } = useEmployeeApprove();
    console.log(employeeDropDown);
    const orgOptions = React.useMemo(() => {
        if (orgQuery.isLoading) return [];
        return orgQuery.data.map((d) => ({
            value: d.id,
            label: d.organisation_code,
        }));
    }, [orgQuery.isLoading, orgQuery.data]);

    const empDropdownOptions = React.useMemo(() => {
        if (employeeDropDown.isLoading) return [];
        return employeeDropDown.data.employee_list.map((d) => ({
            value: d.id,
            label: d.name,
        }));
    }, [employeeDropDown.isLoading, employeeDropDown.data]);

    const designationOptions = React.useMemo(() => {
        if (designationQuery.isLoading) return [];
        return designationQuery.data.map((d) => ({
            value: d.id,
            label: d.name,
        }));
    }, [designationQuery.isLoading, designationQuery.data]);

    const roleOptions = React.useMemo(() => {
        if (rolesQuery.isLoading) return [];
        return rolesQuery.data.map((d) => ({
            value: d.id,
            label: d.name,
        }));
    }, [rolesQuery.isLoading, rolesQuery.data]);
    React.useEffect(() => {
        if (updateLoading) setMessage("updating employee details");
        if (isSuccess) setMessage("Successfully approved employee");
        if (error) setMessage(error);
    }, [isSuccess, updateLoading, error]);
    React.useEffect(() => {
        if (!showDetails) {
            if (isSuccess) {
                history.goBack();
            }
        }
    }, [showDetails]);
    let initialValues = employeeQuery.data ? employeeQuery.data.data : {};

    if (
        employeeQuery.isLoading ||
        orgQuery.isLoading ||
        employeeDropDown.isLoading ||
        rolesQuery.isLoading ||
        employeeDesignationQuery.isLoading
    )
        return <ProgressBar striped variant="success" animated now={40} />;

    const employeeDetails = employeeQuery.data;
    function formatAndSubmit(values) {
        values["designation_id"] = values.designation_id.value;
        values["organisation_id"] = values["organisation_id"]["value"];
        values["approvel_status"] = values["approvel_status"]["value"];
        values["icai_article"] = values["icai_article"]["value"];
        values["access_role_id"] = values["access_role_id"]["value"];
        values["leave_reporting_to_id"] =
            values["leave_reporting_to_id"]["value"];
        values["reporting_to_id"] = values["reporting_to_id"]["value"];
        if(values["previous_employee_id"])values["previous_employee_id"] =
            values["previous_employee_id"]["value"];
        mutate({ ...values });
        setShowDetails(true);
    }
    return (
        <Container>
            <Header title="add_nature_of_expense" />
            <h1>
                <Badge>Approve Employee</Badge>
            </h1>
            <Row>
                <Col sm={4}></Col>
            </Row>
            <Formik
                validate={validate}
                initialValues={initialValues}
                onSubmit={async (values) =>
                    formatAndSubmit(JSON.parse(JSON.stringify(values)))
                }
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <Row>
                            <Col md={9} className="col-padl">
                                <Card className="b-l card table-responsive">
                                    <Table className="bdr-left">
                                        <tbody>
                                            <tr className="tbl-bodr">
                                                <td>Name : {values["name"]}</td>
                                                <td>
                                                    Gender: {values["gender"]}
                                                </td>
                                                <td>DOb : {values["dob"]}</td>
                                            </tr>
                                            <tr className="tbl-bodr">
                                                <td>
                                                    Address :{values["address"]}
                                                </td>
                                                <td>
                                                    Personal Email :{" "}
                                                    {values["personal_email"]}
                                                </td>
                                                <td>
                                                    {" "}
                                                    Work Email :{" "}
                                                    {values["work_email"]}
                                                </td>
                                            </tr>
                                            <tr className="tbl-bodr">
                                                <td>
                                                    Contact.No :
                                                    {values["contact"]}
                                                </td>
                                                <td>
                                                    Blood group:{" "}
                                                    {values["bloodgroup"]}
                                                </td>
                                                <td>
                                                    state : {values["state"]}
                                                </td>
                                            </tr>
                                            <tr className="tbl-bodr">
                                                <td>City : {values["city"]}</td>
                                                <td>
                                                    PAN card: {values["pan"]}
                                                </td>
                                                <td>
                                                    Aadhaar :{" "}
                                                    {values["aadhaar"]}
                                                </td>
                                            </tr>
                                            <tr className="tbl-bodr">
                                                <td>
                                                    <span>DOJ</span>
                                                    <Field
                                                        name="doj"
                                                        type="date"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                    {errors.doj && touched.doj && (
                                                        <div
                                                            style={{
                                                                marginTop: "2%",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            {errors.doj}
                                                        </div>
                                                    )}
                                                </td>
                                                <td>
                                                    <span>Designation</span>
                                                    <Field
                                                        name="designation_id"
                                                        options={
                                                            designationOptions
                                                        }
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        component={Select}
                                                        value={
                                                            values.designation_id
                                                                ? values.designation_id
                                                                : undefined
                                                        }
                                                        onChange={(event) => {
                                                            setFieldValue(
                                                                "designation_id",
                                                                event
                                                            );
                                                        }}
                                                        styles={selectStyles}
                                                    />

                                                    <div
                                                        style={{
                                                            marginTop: "2%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {errors.designation_id}
                                                    </div>
                                                </td>
                                                <td>
                                                    <span>
                                                        Previous Employee
                                                    </span>
                                                    <Field
                                                        name="previous_employee_id"
                                                        options={
                                                            empDropdownOptions
                                                        }
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        component={Select}
                                                        value={
                                                            values.previous_employee_id
                                                                ? values.previous_employee_id
                                                                : undefined
                                                        }
                                                        onChange={(event) => {
                                                            setFieldValue(
                                                                "previous_employee_id",
                                                                event
                                                            );
                                                        }}
                                                        styles={selectStyles}
                                                    />

                                                    <div
                                                        style={{
                                                            marginTop: "2%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {
                                                            errors.previous_employee_id
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="tbl-bodr">
                                                <td>
                                                    <span>Access Roles</span>
                                                    <Field
                                                        name="access_role_id"
                                                        options={roleOptions}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        component={Select}
                                                        value={
                                                            values.access_role_id
                                                                ? values.access_role_id
                                                                : undefined
                                                        }
                                                        onChange={(event) => {
                                                            setFieldValue(
                                                                "access_role_id",
                                                                event
                                                            );
                                                        }}
                                                        styles={selectStyles}
                                                    />
                                                    <div
                                                        style={{
                                                            marginTop: "2%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {errors.access_role_id}
                                                    </div>
                                                </td>
                                                <td>
                                                    <span>Organisation</span>
                                                    <Field
                                                        name="organisation_id"
                                                        options={orgOptions}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        component={Select}
                                                        value={
                                                            values.organisation_id
                                                                ? values.organisation_id
                                                                : undefined
                                                        }
                                                        onChange={(event) => {
                                                            setFieldValue(
                                                                "organisation_id",
                                                                event
                                                            );
                                                        }}
                                                        styles={selectStyles}
                                                    />

                                                    <div
                                                        style={{
                                                            marginTop: "2%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {errors.organisation_id}
                                                    </div>
                                                </td>
                                                <td>
                                                    <span>Initial</span>
                                                    <Field
                                                        name="initial"
                                                        type="text"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                    {errors.initial &&
                                                        touched.initial && (
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        "2%",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                {errors.initial}
                                                            </div>
                                                        )}
                                                </td>
                                            </tr>
                                            <tr className="tbl-bodr">
                                                <td>
                                                    <span>Approvel Status</span>
                                                    <Field
                                                        name="approvel_status"
                                                        options={[
                                                            {
                                                                label: "True",
                                                                value: true,
                                                            },
                                                            {
                                                                label: "False",
                                                                value: false,
                                                            },
                                                        ]}
                                                        isSearchable={false}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        component={Select}
                                                        value={
                                                            values.approvel_status
                                                                ? values.approvel_status
                                                                : undefined
                                                        }
                                                        onChange={(event) => {
                                                            setFieldValue(
                                                                "approvel_status",
                                                                event
                                                            );
                                                        }}
                                                        styles={selectStyles}
                                                    />

                                                    <div
                                                        style={{
                                                            marginTop: "2%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {errors.approvel_status}
                                                    </div>
                                                </td>
                                                <td>
                                                    <span>
                                                        Leave Reporting To
                                                    </span>

                                                    <Field
                                                        name="leave_reporting_to_id"
                                                        options={
                                                            empDropdownOptions
                                                        }
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        component={Select}
                                                        value={
                                                            values.leave_reporting_to_id
                                                                ? values.leave_reporting_to_id
                                                                : undefined
                                                        }
                                                        onChange={(event) => {
                                                            setFieldValue(
                                                                "leave_reporting_to_id",
                                                                event
                                                            );
                                                        }}
                                                        styles={selectStyles}
                                                    />

                                                    <div
                                                        style={{
                                                            marginTop: "2%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {
                                                            errors.leave_reporting_to_id
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <span>Reporting To</span>
                                                    <Field
                                                        name="reporting_to_id"
                                                        options={
                                                            empDropdownOptions
                                                        }
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        component={Select}
                                                        value={
                                                            values.reporting_to_id
                                                                ? values.reporting_to_id
                                                                : undefined
                                                        }
                                                        onChange={(event) => {
                                                            setFieldValue(
                                                                "reporting_to_id",
                                                                event
                                                            );
                                                        }}
                                                        styles={selectStyles}
                                                    />

                                                    <div
                                                        style={{
                                                            marginTop: "2%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {errors.reporting_to_id}
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr className="tbl-bodr">
                                                <td>
                                                    <span>Qualification</span>

                                                    <Field
                                                        name="qualification"
                                                        type="text"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            marginTop: "2%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {errors.qualification}
                                                    </div>
                                                </td>
                                                <td>
                                                    <span>
                                                        Prior years of
                                                        Experience
                                                    </span>

                                                    <Field
                                                        name="prior_years_of_experiance"
                                                        type="text"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                    {errors.prior_years_of_experiance &&
                                                        touched.prior_years_of_experiance && (
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        "2%",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                {
                                                                    errors.prior_years_of_experiance
                                                                }
                                                            </div>
                                                        )}
                                                </td>
                                                <td>
                                                    <span
                                                        style={{
                                                            marginBottom: "6px",
                                                        }}
                                                    >
                                                        ICAI Article?
                                                    </span>
                                                    <Field
                                                        name="icai_article"
                                                        options={[
                                                            {
                                                                label: "true",
                                                                value: true,
                                                            },
                                                            {
                                                                label: "false",
                                                                value: false,
                                                            },
                                                        ]}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        component={Select}
                                                        isSearchable={false}
                                                        value={
                                                            values.icai_article
                                                                ? values.icai_article
                                                                : undefined
                                                        }
                                                        onChange={(event) => {
                                                            setFieldValue(
                                                                "icai_article",
                                                                event
                                                            );
                                                        }}
                                                        styles={selectStyles}
                                                    />

                                                    <div
                                                        style={{
                                                            marginTop: "2%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        {errors.icai_article}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col sm={4}>
            <SavingDetailsModal
              className={classes.modal}
              show={this.state.loading}
              title={"Saving Nature of expense Details"}
              loading={this.props.loading}
              message={this.props.message}
              onClick={this.closeModal}
            />
          </Col> */}
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col>
                                <Row className="float-right">
                                    <Col>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Update
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
            <AlertComponent
                title="Updating"
                description={message}
                saveText={"OK"}
                open={showDetails}
                proceed={() => setShowDetails(false)}
            />
        </Container>
    );
};

export default ApproveEmployee;
