import React, { useMemo, useState, useEffect } from "react";
import PageTemplate from "../components/Layout/PageTemplate/PageTemplate";
import Table from "../components/ReactTableComponent";
import Button from "react-bootstrap/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "../assets/add.svg";
import {
    useAssetCategoryList,
    useFirmAssetList,
} from "../hooks/firmAsset-query";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
import { FIRM_ASSET_STATUS } from "../utils/constants";

const FirmAssetsPage = () => {
    const { data: assetCategoryList } = useAssetCategoryList();

    const [tableOption, setTableOption] = useState({});
    const { isLoading, isError, data, error } = useFirmAssetList(tableOption);
    console.log("data", tableOption);
    const columns = useMemo(
        () => [
            {
                header: "Serial No",
                accessorKey: "serial_no",
                meta: {
                    filterType: "text",
                },
                cell: (props) => {
                    const serial_no = props.row.original.serial_no;
                    return (
                        <Link
                            to={`/firm_asset_details/${props.row.original.id}`}
                        >
                            {serial_no}
                        </Link>
                    );
                },
            },
            {
                cell: (info) => info.getValue(),
                header: "Category of asset",
                accessorKey: "category_of_asset",
                meta: {
                    filterType: "select",
                    options: assetCategoryList,
                },
            },
            {
                cell: (info) => info.getValue(),
                header: "Status",
                accessorKey: "status",
                meta: {
                    filterType: "select",
                    options: FIRM_ASSET_STATUS,
                },
            },
            {
                cell: (info) => info.getValue(),
                header: "Custodian",
                accessorKey: "custodian_employee",
            },
        ],
        [assetCategoryList]
    );

    const renderAdd = React.useCallback(() => {
        return (
            <Link to={`/add_firm_assets`}>
                <IconButton color="primary" aria-label="add" component="label">
                    <img
                        src={AddIcon}
                        style={{ height: "60%", width: "60%" }}
                        alt="add"
                    />
                </IconButton>
            </Link>
        );
    }, []);
    if (isLoading)
        return <ProgressBar striped variant="success" animated now={40} />;
    if (isError) return <>Error...</>;
    return (
        <PageTemplate>
            <div
                style={{
                    marginLeft: "90%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <>{renderAdd()}</>
            </div>
            <Table
                columns={columns}
                data={data.firm_assets}
                setTableOption={(data) => setTableOption(data)}
                //totalItems={data? data.totalItems : 0}
                pageCount={Math.ceil(
                    data.total_count /
                        (tableOption.pageSize ? tableOption.pageSize : 10)
                )}
            />
        </PageTemplate>
    );
};

export default FirmAssetsPage;
