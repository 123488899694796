import {
    START_OPE_ADD,
    START_OPE_FETCH,
    OPE_FETCH_SUCCESS,
    OPE_ADD_SUCCESS,
    OPE_FETCH_FAILURE,
    OPE_ADD_FAILURE,
    OPE_AMOUNT_FETCH_SUCCESS,
    OPE_AMOUNT_FETCH_FAILED,
    START_OPE_AMOUNT_FETCH,
    START_RESPECTIVE_OPE_FETCH,
    RESPECTIVE_OPE_FETCH_DONE,
    RESPECTIVE_OPE_FETCH_FAILED,
    DELETE_FROM_AVAILABLE_OPES,
    ADD_BACK_TO_AVAILABLE_OPES,
} from "../actions/types";
import {
    filterOpeOnSelection,
    undoOpeSelection,
    initialOpeFilter,
} from "../utils/invoiceOpeHelpers";
const DEFAULT_STATE = {
    opeList: [],
    resOpeList: [],
    invoiceOpeSelected: [],
    invoiceOpeAvailable: [],
    ope_amount: 0,
    loading: false,
    opeFetchMessage: "",
    opeAddMessage: "",
    opeAddLoading: false,
    opeAddFailed: true,
};
export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case ADD_BACK_TO_AVAILABLE_OPES:

            return {
                ...state,
                invoiceOpeAvailable: undoOpeSelection(state, action.payload),
                invoiceOpeSelected: state.invoiceOpeSelected.filter(
                    (item) => item.id !== action.payload.id
                ),
            };
        case DELETE_FROM_AVAILABLE_OPES:
            return {
                ...state,
                invoiceOpeSelected: [
                    ...state.invoiceOpeSelected,
                    action.payload,
                ],
                invoiceOpeAvailable: filterOpeOnSelection(
                    state.invoiceOpeAvailable,
                    action.payload
                ),
            };
        case START_OPE_FETCH:
            return {
                ...state,
                loading: true,
                opeFetchMessage: "",
            };

        case OPE_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                opeList: action.payload,
            };
        case OPE_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                opeFetchMessage: action.payload,
            };

        case START_RESPECTIVE_OPE_FETCH:
            return {
                ...state,
                loading: true,
                invoiceOpeAvailable: [],
                invoiceOpeSelected: [],
            };

        case RESPECTIVE_OPE_FETCH_DONE:
            return {
                ...state,
                loading: false,
                resOpeList: action.payload,
                invoiceOpeAvailable: initialOpeFilter(
                    action.payload,
                    state.invoiceOpeSelected
                ), //action.payload.filter(i => !state.invoiceOpeSelected.includes(i.id))//https://stackoverflow.com/a/63380693/5706413
            };
        case RESPECTIVE_OPE_FETCH_FAILED:
            return {
                ...state,
                loading: false,
                resOpeList: [],
            };
        case START_OPE_AMOUNT_FETCH:
            return {
                ...state,
                loading: true,
            };

        case OPE_AMOUNT_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                ope_amount: action.payload,
            };
        case OPE_AMOUNT_FETCH_FAILED:
            return {
                ...state,
                loading: false,
                ope_amount: action.payload,
            };
        case START_OPE_ADD:
            return {
                ...state,
                opeAddLoading: true,
                opeAddFailed: false,
            };

        case OPE_ADD_SUCCESS:
            return {
                ...state,
                opeAddLoading: false,
                opeAddFailed: false,
                opeAddMessage: action.payload,
            };
        case OPE_ADD_FAILURE:
            return {
                ...state,
                opeAddLoading: false,
                opeAddFailed: true,
                loading: false,
                opeAddMessage: action.payload,
            };
        default:
            return state;
    }
};
