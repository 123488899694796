import {START_EMPLOYEE_LIST_FETCH,EMPLOYEE_LIST_FETCH_SUCCESS,EMPLOYEE_LIST_FETCH_FAILED,
EMPLOYEE_DROP_DOWN_LIST_FETCH_SUCCESS,EMPLOYEE_DROP_DOWN_LIST_FETCH_FAILED,START_EMPLOYEE_DROP_DOWN_LIST_FETCH,START_LEAD_PARTNER_FETCH,LEAD_PARTNER_FETCH_SUCCESS,LEAD_PARTNER_FETCH_FAILED,START_EMPLOYEE_APPROVEL,EMPLOYEE_APPROVEL_SUCCESS,EMPLOYEE_APPROVEL_FAILED,START_FETCH_EMLOYEE_DETAILS,FETCH_EMPLOYEE_DETAILS_SUCCESS,FETCH_EMPLOYEE_DETAILS_FAILED,ADD_BACK_EMPLOYEE, INITIAL_FILTERING_EMPLOYEE, REMOVE_EMPLOYEE
} from "../actions/types";
const DEFAULT_STATE={
    employeeList:[],
    employeeDropdownList:[],
    partnerList:[],
    approvelMessage:'',
    loading:false,
    errorMessage:'',
    employeeDetails: {},
};
export default(state=DEFAULT_STATE,action)=>{
    switch(action.type){
        case ADD_BACK_EMPLOYEE:
            return{
                ...state,
                employeeDropdownList: [...state.employeeDropdownList, action.payload]
            }
        case REMOVE_EMPLOYEE:
            return{
                ...state,
                employeeDropdownList: state.employeeDropdownList.filter(item => JSON.stringify(item) !== JSON.stringify(action.payload))
            }
        case INITIAL_FILTERING_EMPLOYEE:
            return{
                ...state,
                employeeDropdownList: state.employeeDropdownList.filter(function(item){
                    return action.payload.indexOf(item.id) === -1;
               })

            }
        case START_EMPLOYEE_APPROVEL:
            return{
                ...state,
                loading: true,
                approvelMessage: ''
            }
        case EMPLOYEE_APPROVEL_SUCCESS:
            return{
                ...state,
                loading: false,
                approvelMessage: action.payload
            }
        case EMPLOYEE_APPROVEL_FAILED:
            return{
                ...state,
                loading: false,
                approvelMessage: action.payload
            }
        case START_FETCH_EMLOYEE_DETAILS:
            return{
                ...state,
                loading: true,
                employeeDetails: {}
            }
        case FETCH_EMPLOYEE_DETAILS_SUCCESS:
            return{
                ...state,
                loading: false,
                employeeDetails: action.payload
            }
        case FETCH_EMPLOYEE_DETAILS_FAILED:
            return{
                ...state,
                loading: false,
                employeeDetails: {}
            }
        case START_LEAD_PARTNER_FETCH:
            return{
                ...state,
                loading:true,
            }
        case LEAD_PARTNER_FETCH_SUCCESS:
            return{
                ...state,
                loading:false,
                partnerList:action.payload
            }
        case LEAD_PARTNER_FETCH_FAILED:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        case START_EMPLOYEE_DROP_DOWN_LIST_FETCH:
            return{
                ...state,
                loading:true,
            }
        case EMPLOYEE_DROP_DOWN_LIST_FETCH_SUCCESS:
            return{
                ...state,
                loading:false,
                employeeDropdownList:action.payload
            }
        case EMPLOYEE_DROP_DOWN_LIST_FETCH_FAILED:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        case START_EMPLOYEE_LIST_FETCH:
            return{
                ...state,
                loading:true,
            }
        case EMPLOYEE_LIST_FETCH_SUCCESS:
            return{
                ...state,
                loading:false,
                employeeList:action.payload
            }
        case EMPLOYEE_LIST_FETCH_FAILED:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload
            }
        default:
            return state
    }
}
