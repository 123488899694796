import {
    TextField,
    FormControl,
    MenuItem,
    FilledInput,
    OutlinedInput,
    Select,
    InputLabel,
    FormHelperText,
    Checkbox,
    ListItemText,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import * as sdata from "../../assets/states.json";
import * as taxdata from "../../assets/tax_code.json";
import * as bgdata from "../../assets/bloodgroup.json";
import * as gdata from "../../assets/gender.json";
import * as bdata from "../../assets/boolean.json";
import React, { useState, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { Modal } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import { Done } from "@material-ui/icons";
import { change, Field } from "redux-form";
import moment from "moment";
import filter from "lodash/filter";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import MaskedInput from "react-text-mask";
import CircularProgress from "@material-ui/core/CircularProgress";
import { opeAmountFetch, respectiveOpeFetch } from "../../actions/opeAction";
import Dialog from "@material-ui/core/Dialog";
import Switch from "@material-ui/core/Switch";
import Alert from "react-bootstrap/Alert";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import { Row, Col } from "react-bootstrap";
import { default as Mselect } from "react-select";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(0.5),
        },
    },
}));
//https://github.com/redux-form/redux-form/issues/3686#issuecomment-353109723
const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

export const FileInput = ({
    input: { value: omitValue, onChange, onBlur, ...inputProps },
    meta: omitMeta,
    ...props
}) => {
    return (
        <input
            onChange={adaptFileEventToValue(onChange)}
            onBlur={adaptFileEventToValue(onBlur)}
            type="file"
            accept=".pdf"
            {...props.input}
            {...props}
        />
    );
};
export const PhoneNumber = (props) => {
    // `value` will be the parsed phone number in E.164 format.
    // Example: "+12133734253".
    return (
        <>
            <PhoneInput
                international
                countryCallingCodeEditable={false}
                {...props.input}
                defaultCountry="IN"
            />
            {props.meta.touched && props.meta.error && (
                <span style={{ color: "red" }}>{props.meta.error}</span>
            )}
        </>
    );
};
export const RenderSwitch = ({
    input,
    label,
    meta: { touched, error },
    ...custom
}) => {
    let temp = input.value === true ? true : false;
    return (
        <FormControlLabel
            control={
                <Switch
                    {...input}
                    {...custom}
                    checked={temp}
                    onChange={(e, checked) => input.onChange(checked)}
                />
            }
            label={label}
        />
    );
};
export const CustomValidationAlert = (props) => {
    return (
        <Alert
            show={props.show}
            variant="danger"
            onClose={props.onClose}
            dismissible
        >
            <Alert.Heading>{props.title}</Alert.Heading>
            <p>{props.message}</p>
        </Alert>
    );
};
export const SnackbarAlert = (props) => {
    return (
        <Snackbar
            key={props.keyValue}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={props.open}
            autoHideDuration={6000}
            onClose={props.onClose}
            message={props.message}
            action={
                <React.Fragment>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={props.onClick}
                    >
                        <CloseIcon />
                    </IconButton>
                </React.Fragment>
            }
        />
    );
};
export const findByMatchingProperties = (set, properties) => {
    let projects = [];
    if (properties) {
        set.forEach((item, index) => {
            if (item["project_id"]) {
                if (item["project_id"]["id"] === properties["id"]) {
                    projects.push(item);
                }
            }
        });
    }
    return projects;
};
export const AlertComponent = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.cancelText && (
                    <Button onClick={props.handleClose} color="primary">
                        {props.cancelText}
                    </Button>
                )}
                <Button onClick={props.proceed} color="primary" autoFocus>
                    {props.saveText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export const NewCommentAlert = (props) => {
    return (
        <Toast show={props.open} onClose={props.handleClose}>
            <Toast.Header>
                <strong className="mr-auto">New Comment</strong>
                <small>just now</small>
            </Toast.Header>
            <Toast.Body>New comment added in poject diary</Toast.Body>
        </Toast>
    );
};
export const FirstHalfSecondHalfRadio = ({
    input,
    label,
    type,
    fullWidth,
    meta: { touched, invalid, error },
    custom,
    ...rest
}) => {
    return (
        <div>
            <RadioGroup
                aria-label={label}
                name={input.name}
                onChange={(value) => {
                    input.onChange(value);
                }}
                defaultValue="false"
            >
                <FormControlLabel
                    value="FirstHalf"
                    control={<Radio />}
                    label="First Half"
                />
                <FormControlLabel
                    value="SecondHalf"
                    control={<Radio />}
                    label="Second Half"
                />
                <FormHelperText>{touched && error}</FormHelperText>
            </RadioGroup>
        </div>
    );
};

export const RadioButtonGroup = ({
    input,
    label,
    type,
    fullWidth,
    meta: { touched, invalid, error },
    custom,
    ...rest
}) => {
    return (
        <div>
            <RadioGroup
                aria-label={label}
                name={input.name}
                onChange={(value) => {
                    input.onChange(value);
                }}
                defaultValue="false"
            >
                <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Yes"
                />
                <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="No"
                />
                <FormHelperText>{touched && error}</FormHelperText>
            </RadioGroup>
        </div>
    );
};
function updateDialogButton(props) {
    if (props.name === "book_time") {
        let button = <></>;
        if (!props.value.button_disabled) {
            button = (
                <Button onClick={props.update} color="primary">
                    Book
                </Button>
            );
        }
        return button;
    } else {
        return (
            <Button onClick={props.update} color="primary">
                Update
            </Button>
        );
    }
}
export const UpdateDialog = (props) => {
    let item = (
        <Select autoWidth value={props.value} onChange={props.handleChange}>
            <MenuItem value="">
                <em>Activity Status</em>
            </MenuItem>
            <MenuItem value="Open">Open</MenuItem>

            <MenuItem value="Closed">Closed</MenuItem>
        </Select>
    );
    if (props.name === "due_date") {
        item = (
            <TextField
                type="date"
                value={props.value}
                onChange={props.handleChange}
            />
        );
    } else if (props.name === "project_status") {
        item = (
            <Select autoWidth value={props.value} onChange={props.handleChange}>
                <MenuItem value="">
                    <em>Project Status</em>
                </MenuItem>
                <MenuItem value="InProgress">In progress</MenuItem>
                <MenuItem value="WorkComplete">Work complete</MenuItem>
                {props.user !== undefined &&
                (props.user.designation === "Partner" ||
                    props.user.designation == "Admin") ? (
                    <MenuItem value="Suspended">Suspended</MenuItem>
                ) : null}
            </Select>
        );
    } else if (props.name === "dsc_basic_details_update") {
        item = (
            <div>
                <Row>
                    <Col>
                        <TextField
                            autoFocus
                            value={props.value.name}
                            onChange={props.handleChange}
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                        />
                    </Col>
                    <Col>
                        <TextField
                            autoFocus
                            value={props.value.password}
                            onChange={props.handleChange}
                            margin="dense"
                            id="password"
                            label="Password"
                            type=""
                            disabled={props.isAuthenticated ? false : true}
                            fullWidth
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            autoFocus
                            value={props.value.token_no}
                            onChange={props.handleChange}
                            margin="dense"
                            id="token_no"
                            label="Token No"
                            type="text"
                            fullWidth
                        />
                    </Col>
                    <Col>
                        <TextField
                            autoFocus
                            value={props.value.expiry_date}
                            onChange={props.handleChange}
                            margin="dense"
                            id="expiry_date"
                            label="Expiry Date"
                            type="date"
                            fullWidth
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextField
                            autoFocus
                            value={props.value.token_provider}
                            onChange={props.handleChange}
                            margin="dense"
                            id="token_provider"
                            label="Token Provider"
                            type="text"
                            fullWidth
                        />
                    </Col>
                    <Col>
                        <TextField
                            autoFocus
                            value={props.value.remarks}
                            onChange={props.handleChange}
                            margin="dense"
                            id="remarks"
                            label="Remarks"
                            type="text"
                            fullWidth
                        />
                    </Col>
                </Row>
            </div>
        );
    } else if (props.name === "book_time") {
        item = (
            <div>
                <Row>
                    <Col>
                        <TextField
                            autoFocus
                            value={props.value.date}
                            onChange={props.handleChange}
                            margin="dense"
                            id="date"
                            label=""
                            type="date"
                            fullWidth
                        />
                    </Col>
                    {props.value.showFileds && (
                        <>
                            <Col>Time booked for the day</Col>
                            <Col>
                                <TextField
                                    disabled={true}
                                    id="day_total"
                                    value={props.value.day_total}
                                    margin="dense"
                                    fullWidth
                                />
                            </Col>
                        </>
                    )}
                </Row>
                {props.value.showFileds && (
                    <Row>
                        <Col></Col>
                        <Col>Time</Col>
                        <Col>
                            <MaskedInput
                                placeholder="HH:MM"
                                guide={false}
                                id="allocated_time"
                                value={props.value.allocated_time}
                                className={props["className"]}
                                disabled={false}
                                mask={[/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
                                pipe={createAutoCorrectedDatePipe("HH:MM")}
                                onChange={props.handleChange}
                            />
                        </Col>
                    </Row>
                )}
                {props.loading && <CircularProgress size={24} />}
                {props.value.warn && (
                    <Row>
                        <Chip
                            size="small"
                            icon={<WarningIcon />}
                            label={props.value.warnMessage}
                            color="primary"
                        />
                    </Row>
                )}
            </div>
        );
    }else if(props.name == "billing_status"){
        item = (
            <Select autoWidth value={props.value} onChange={props.handleChange}>
                <MenuItem value="">
                    <em>Billing Status</em>
                </MenuItem>
                <MenuItem value="Unbilled">Unbilled</MenuItem>
                <MenuItem value="Progressive">Progressive</MenuItem>
                <MenuItem value="Billed">Billed</MenuItem>
                <MenuItem value="Mbilled">Mbilled</MenuItem>
                <MenuItem value="NA">NA</MenuItem>
            </Select>
        );
    }else {
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{props.description}</DialogContentText>
                    {item}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Cancel
                    </Button>
                    {updateDialogButton(props)}
                </DialogActions>
            </Dialog>
        </div>
    );
};
export const MultiselectFormDialog = (props) => {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
                classes={{ paper: props.styles }}
                fullWidth
            >
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{props.subTitle}</DialogContentText>
                    <span>Name</span>
                    <Mselect
                        name={props.name}
                        isMulti={props.isMulti}
                        options={props.options}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={props.handleItemChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={
                            props.value ? props.value : props.setInitialValues
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={props.updateClients} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export const FormDialog = (props) => {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Verification</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To view client credentials, please enter your password
                    </DialogContentText>
                    <TextField
                        autoFocus
                        value={props.password}
                        onChange={props.handlePasswordChange}
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={props.reAuthenticate} color="primary">
                        Verify
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export const HourMinutes = ({
    input,
    label,
    type,
    fullWidth,
    meta: { touched, invalid, error },
    custom,
    ...rest
}) => {
    return (
        <MaskedInput
            {...input}
            {...custom}
            placeholder={label}
            guide={false}
            className={rest["className"]}
            disabled={rest["disabled"]}
            mask={[/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
            pipe={createAutoCorrectedDatePipe("HH:MM")}
        />
    );
};

export const getMinutes = (data) => {
    let min = 0;
    let hm;
    if (data.includes(":")) {
        hm = data.split(":");
        if (!isNaN(hm[1])) {
            min = 60 * Number(hm[0]) + Number(hm[1]);
        } else {
            min = 60 * Number(hm[1]);
        }
    } else {
        min = 60 * Number(data);
    }
    return min;
};
export const padZeros = (number) => {
    if (number < 10) {
        return "0" + number;
    } else {
        return number;
    }
};
export const SavingDetailsModal = (props) => {
    let message = "Connecting to server...";
    return (
        <Modal
            show={props.show}
            disableBackdropClick={true}
            className={props.className}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.loading ? message : props.message}</Modal.Body>
            <Modal.Footer>
                {props.loading ? null : (
                    <Button variant="contained" onClick={props.onClick}>
                        Ok
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
export const Item = (props) => {
    return (
        <div>
            {props.value.project_name !== "" ? props.value.project_name : null}
            {"\n"}
            <div onClick={props.onClick}>
                <li>
                    <Row>
                        <Col>{props.value.particulars}</Col>{" "}
                        <Col>{props.value.ope_amount}</Col>{" "}
                        <Col> {props.value.selected ? <Done /> : null}</Col>
                    </Row>
                </li>
            </div>
        </div>
    );
};
export const InputText = ({
    input,
    label,
    type,
    max,
    min,
    fullWidth,
    meta: { touched, invalid, error },
    custom,
    ...rest
}) => {
    // console.log("check input text data",custom,rest)

    return (
        <TextField
            {...input}
            {...custom}
            label={label}
            error={touched && invalid}
            helperText={touched && error}
            fullWidth={fullWidth}
            margin="normal"
            variant="outlined"
            type={type}
            InputProps={{ inputProps: { max: max, min: min } }}
            disabled={rest["disabled"]}
            onChange={(value) => {
                input.onChange(value);
            }}
        />
    );
};
export const compareDates = (d1, d2) => {
    let result = false;
    if (d1 && d2) {
        var parts_d1 = d1.split("-");
        var d1 = Number(parts_d1[2] + parts_d1[1] + parts_d1[0]);
        var parts_d2 = d2.split("-");
        var d2 = Number(parts_d2[2] + parts_d2[1] + parts_d2[0]);
        result = d1 <= d2;
    }
    return result;
};
export const TextArea = ({
    input,
    label,
    fullWidth,
    meta: { touched, invalid, error },
    custom,
}) => {
    return (
        <TextField
            {...input}
            {...custom}
            style={{ width: "100%" }}
            label={label}
            error={touched && invalid}
            helperText={touched && error}
            fullWidth={fullWidth}
            multiline
            rowsMax="4"
            margin="normal"
        />
    );
};
export const SelectManagementStatus = ({
    input,
    label,
    fullWidth,
    meta: { touched, invalid, error },
    custom,
    ...rest
}) => {
    const isError = (touched && error) === undefined ? false : touched && error;
    let menuItems = (
        <Select
            autoWidth
            {...input}
            {...custom}
            input={<OutlinedInput name="age" id="filled-simple" />}
        >
            <MenuItem value="">
                <em>Status</em>
            </MenuItem>
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Suspended">Suspended</MenuItem>
        </Select>
    );
    return (
        <FormControl error={isError} variant="filled" fullWidth={fullWidth}>
            <InputLabel htmlFor="name-error">{label}</InputLabel>
            {menuItems}
            <FormHelperText>{touched && error}</FormHelperText>
        </FormControl>
    );
};

export const SelectDesignation = ({
    input,
    label,
    fullWidth,
    meta: { touched, invalid, error },
    custom,
    ...rest
}) => {
    const isError = (touched && error) === undefined ? false : touched && error;
    let menuItems = (
        <Select
            autoWidth
            {...input}
            {...custom}
            input={<OutlinedInput name="age" id="filled-simple" />}
        >
            <MenuItem value="">
                <em>Designation</em>
            </MenuItem>
            <MenuItem value="Director">Director</MenuItem>
            <MenuItem value="Partner">Partner</MenuItem>
            <MenuItem value="Proprietor">Proprietor</MenuItem>
        </Select>
    );
    return (
        <FormControl error={isError} variant="filled" fullWidth={fullWidth}>
            <InputLabel htmlFor="name-error">{label}</InputLabel>
            {menuItems}
            <FormHelperText>{touched && error}</FormHelperText>
        </FormControl>
    );
};

export const SelectState = ({
    input,
    label,
    fullWidth,
    meta: { touched, invalid, error },
    custom,
    ...rest
}) => {
    const isError = (touched && error) === undefined ? false : touched && error;
    let menuItems = (
        <Select
            autoWidth
            {...input}
            {...custom}
            input={<OutlinedInput name="age" id="filled-simple" />}
        >
            <MenuItem value="">
                <em>State</em>
            </MenuItem>
            {sdata.states.map((obj) => (
                <MenuItem value={obj.name}>{obj.name}</MenuItem>
            ))}
        </Select>
    );
    return (
        <FormControl error={isError} variant="filled" fullWidth={fullWidth}>
            <InputLabel htmlFor="name-error">{label}</InputLabel>
            {menuItems}
            <FormHelperText>{touched && error}</FormHelperText>
        </FormControl>
    );
};

export const SelectInput = ({
    input,
    label,
    fullWidth,
    meta: { touched, invalid, error },
    custom,
    ...rest
}) => {
    const ldata = rest;
    const isError = (touched && error) === undefined ? false : touched && error;
    let menuItems = (
        <Select
            autoWidth
            {...input}
            {...custom}
            input={<OutlinedInput name="age" id="filled-simple" />}
        >
            <MenuItem value="">
                <em>Select</em>
            </MenuItem>
            {bdata.Boolean.map((obj) => (
                <MenuItem value={obj.name}>{obj.name}</MenuItem>
            ))}
        </Select>
    );
    switch (input.name) {
        case "state":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="age" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>State</em>
                    </MenuItem>
                    {sdata.states.map((obj) => (
                        <MenuItem value={obj.name}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );
            break;
        case "place_of_supply_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="age" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Place of supply</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );
            break;
        case "contract_type":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="age" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Contract type</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );
            break;
        case "gstin_of_party_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="age" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Gstin</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.gstin}</MenuItem>
                    ))}
                </Select>
            );
            break;

        case "tax_code":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="tax_code" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Tax Code</em>
                    </MenuItem>
                    {taxdata.tax_code.map((obj) => (
                        <MenuItem value={obj.key}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;
        case "gender":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="age" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Gender</em>
                    </MenuItem>
                    {gdata.Gender.map((obj) => (
                        <MenuItem value={obj.name}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );
            break;
        case "due_date_type":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            name="due date type"
                            id="filled-simple"
                        />
                    }
                >
                    <MenuItem value="">
                        <em>Date of creation</em>
                    </MenuItem>
                    <MenuItem value="Month end">Month End</MenuItem>
                    <MenuItem value="Specific Date">Specific Date</MenuItem>
                </Select>
            );
            break;

        case "date_of_creation_type":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="status" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Date of creation</em>
                    </MenuItem>
                    <MenuItem value="Month end">Month End</MenuItem>
                    <MenuItem value="Specific Date">Specific Date</MenuItem>
                </Select>
            );
            break;

        case "due_date_code":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="status" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Due date</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.key}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );
            break;


        case "frequency":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="status" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Frequency</em>
                    </MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                    <MenuItem value="Quaterly">Quaterly</MenuItem>
                    <MenuItem value="Yearly">Yearly</MenuItem>
                </Select>
            );
            break;

        case "mode":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="status" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Mode of delivery</em>
                    </MenuItem>
                    <MenuItem value="Courier">Courier</MenuItem>
                    <MenuItem value="Post">Post</MenuItem>
                    <MenuItem value="PhysicalHandover">
                        Physical Handover
                    </MenuItem>
                </Select>
            );
            break;
        case "creation_code":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="status" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Creation date</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.key}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );
            break;

        case "client_risk":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="status" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Client Riske</em>
                    </MenuItem>
                    <MenuItem value="Low">Low</MenuItem>
                    <MenuItem value="Moderate">Moderate</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                </Select>
            );
            break;
        case "lead_channel":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="status" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Select Lead channel</em>
                    </MenuItem>
                    <MenuItem value="Sulekha">Sulekha</MenuItem>
                    <MenuItem value="Walkin">Walkin</MenuItem>
                    <MenuItem value="Personal">Personal</MenuItem>
                    <MenuItem value="Google">Goole</MenuItem>
                </Select>
            );
            break;
        case "activity_status":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            name="activity_status"
                            id="filled-simple"
                        />
                    }
                >
                    <MenuItem value="">
                        <em>Activity Status</em>
                    </MenuItem>
                    <MenuItem value="Open">Open</MenuItem>

                    <MenuItem value="Closed">Closed</MenuItem>
                </Select>
            );
            break;
        case "from_date_leave_type":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="status" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Leave Type</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );
            break;

        case "to_date_leave_type":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="status" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Leave Type</em>
                    </MenuItem>
                    <MenuItem value={0}>Full Day</MenuItem>
                    <MenuItem value={1}>First Half</MenuItem>
                </Select>
            );
            break;
        case "client_status":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="status" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Client Statuse</em>
                    </MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Suspended">Suspended</MenuItem>
                </Select>
            );
            break;

        case "type_of_document":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput name="udin_status" id="filled-simple" />
                    }
                >
                    <MenuItem value="">
                        <em>Type of Document</em>
                    </MenuItem>
                    <MenuItem value="Attestation">Attestation</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                </Select>
            );
            break;
        case "type_of_sign":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput name="udin_status" id="filled-simple" />
                    }
                >
                    <MenuItem value="">
                        <em>Type of document</em>
                    </MenuItem>
                    <MenuItem value="Digital">Digital</MenuItem>
                    <MenuItem value="Physical">Physical</MenuItem>
                </Select>
            );
            break;
        case "site":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="site" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Site</em>
                    </MenuItem>
                    <MenuItem value="Office">Office</MenuItem>
                    <MenuItem value="WFH">Work From Home</MenuItem>
                    <MenuItem value="ClientLocation">Client Location</MenuItem>
                </Select>
            );
            break;
        case "presence":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="presence" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Presence</em>
                    </MenuItem>
                    <MenuItem value="Present">Present</MenuItem>
                    <MenuItem value="Absent">Absent</MenuItem>
                </Select>
            );
            break;

        case "udin_status":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput name="udin_status" id="filled-simple" />
                    }
                >
                    <MenuItem value="">
                        <em>Udin Status</em>
                    </MenuItem>
                    <MenuItem value="Issued">Issued</MenuItem>
                    <MenuItem value="Revoked">Revoked</MenuItem>
                </Select>
            );
            break;
        case "status":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="status" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Project Status</em>
                    </MenuItem>
                    <MenuItem value="YetToCommence">Yet to Commence</MenuItem>
                    <MenuItem value="Billed">Billed</MenuItem>
                    <MenuItem value="WorkComplete">Work Complete</MenuItem>
                    <MenuItem value="InProgress">In Progress</MenuItem>
                    <MenuItem value="Suspended">Suspended</MenuItem>
                    <MenuItem value="Archived">Archived</MenuItem>
                </Select>
            );
            break;
        case "employee_type":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="age" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Employee Type</em>
                    </MenuItem>
                    <MenuItem value="Manager">Manager</MenuItem>
                    <MenuItem value="Partner">Partner</MenuItem>
                    <MenuItem value="Associate">Associate</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                </Select>
            );
            break;
        case "category_of_leave_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            name="first_occurence_month_or_quater"
                            id="filled-simple"
                        />
                    }
                >
                    <MenuItem value="">
                        <em>Select</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;
        case "q_start_month":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            name="first_occurence_month_or_quater"
                            id="filled-simple"
                        />
                    }
                >
                    <MenuItem value="">
                        <em>Select</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.key}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;
        case "q_due_month":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            name="first_occurence_month_or_quater"
                            id="filled-simple"
                        />
                    }
                >
                    <MenuItem value="">
                        <em>Select</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.key}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;

        case "first_occurence_month_or_quater":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            name="first_occurence_month_or_quater"
                            id="filled-simple"
                        />
                    }
                >
                    <MenuItem value="">
                        <em>Select</em>
                    </MenuItem>
                    {ldata.data.temp.map((obj) => (
                        <MenuItem value={obj.key}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;
        case "organisation_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput name="client_id" id="filled-simple" />
                    }
                >
                    <MenuItem value="">
                        <em>Organisation</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;

        case "designation_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput name="client_id" id="filled-simple" />
                    }
                >
                    <MenuItem value="">
                        <em>Designation</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;
        case "access_role_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput name="client_id" id="filled-simple" />
                    }
                >
                    <MenuItem value="">
                        <em>Access Roles</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;

        case "client_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput name="client_id" id="filled-simple" />
                    }
                >
                    <MenuItem value="">
                        <em>Client Name</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;

        case "movement_register_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput name="client_id" id="filled-simple" />
                    }
                >
                    <MenuItem value="">
                        <em>Client Name</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;

        case "project_sub_class_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            name="project_sub_class_id"
                            id="filled-simple"
                        />
                    }
                >
                    <MenuItem value="">
                        <em>Project Category</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;
        case "project_class_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            name="project_sub_class_id"
                            id="filled-simple"
                        />
                    }
                >
                    <MenuItem value="">
                        <em>Project Class</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;

        case "lead_partner_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            name="lead_partner_id"
                            id="filled-simple"
                        />
                    }
                >
                    <MenuItem value="">
                        <em>Lead Partner</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;
        case "in_charge_partner_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            name="incharge_partner_id"
                            id="filled-simple"
                        />
                    }
                >
                    <MenuItem value="">
                        <em>Partners</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;
        case "in_charge_employee_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            name="incharge_employee_id"
                            id="filled-simple"
                        />
                    }
                >
                    <MenuItem value="">
                        <em>Employee</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;
        case "project_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput name="project_id" id="filled-simple" />
                    }
                >
                    <MenuItem value="">
                        <em>Projecs</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;
        case "client_class_id":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={
                        <OutlinedInput
                            name="project_category_id"
                            id="filled-simple"
                        />
                    }
                >
                    <MenuItem value="">
                        <em>Client Class</em>
                    </MenuItem>
                    {ldata.data.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );

            break;
        case "bloodgroup":
            menuItems = (
                <Select
                    autoWidth
                    {...input}
                    {...custom}
                    input={<OutlinedInput name="age" id="filled-simple" />}
                >
                    <MenuItem value="">
                        <em>Select</em>
                    </MenuItem>
                    {bgdata.Bloodgroup.map((obj) => (
                        <MenuItem value={obj.name}>{obj.name}</MenuItem>
                    ))}
                </Select>
            );
            break;
        default:
            if (input.name.includes("invoice_basic_details_attributes[")) {
                menuItems = (
                    <Select
                        autoWidth
                        {...input}
                        {...custom}
                        input={
                            <OutlinedInput name="status" id="filled-simple" />
                        }
                    >
                        <MenuItem value="">
                            <em>Invoice Type</em>
                        </MenuItem>
                        <MenuItem value={1}>Final Invoice</MenuItem>
                        <MenuItem value={0}>Progressive Invoice</MenuItem>
                    </Select>
                );
            } else if (input.name.includes("project_members_attributes[")) {
                menuItems = (
                    <Select
                        autoWidth
                        {...input}
                        {...custom}
                        input={
                            <OutlinedInput name="role_id" id="filled-simple" />
                        }
                    >
                        <MenuItem value="">
                            <em>Role</em>
                        </MenuItem>
                        {ldata.data.map((obj) => (
                            <MenuItem value={obj.id}>{obj.name}</MenuItem>
                        ))}
                    </Select>
                );
            } else {
            }
    }
    return (
        <FormControl error={isError} variant="filled" fullWidth={fullWidth}>
            <InputLabel htmlFor="name-error">{label}</InputLabel>
            {menuItems}
            <FormHelperText>{touched && error}</FormHelperText>
        </FormControl>
    );
};
function validateEmail(email) {
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
function validateFinanYear(year) {
    var regx = /^[0-9]{4}[\-][0-9]{2}$/;
    if (regx.test(year)) {
        var parts = year.split("-");
        if (Number(parts[0]) % 100 == Number(parts[1]) - 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}
export const validate = (inputs) => {
    // console.log("trying to extract time from date",moment(inputs['8_30-10-2019']).format('HH:mm'))
    const errors = {};
    if (!("city" in inputs)) errors.city = "City name required";

    if (!("name" in inputs)) errors.name = "Name required";

    if (!("address" in inputs)) errors.address = "Address equired";

    if (!("multiple_days" in inputs)) {
        errors.multiple_days = "Required";
    } else if (inputs.multiple_days === "true") {
        if (!("to_date_leave_type" in inputs)) {
            errors.to_date_leave_type = "Required";
        }
        if (!("to_date" in inputs)) {
            errors.to_date = "Required";
        }
    } else {
    }
    if (!("category_of_leave_id" in inputs)) {
        errors.category_of_leave_id = "Required";
    }
    if (!("reason" in inputs)) {
        errors.reason = "Required";
    }
    if (!("from_date" in inputs)) {
        errors.from_date = "Required";
    }
    if (!("from_date_leave_type" in inputs)) {
        errors.from_date_leave_type = "Required";
    }

    if (!("user_id" in inputs)) {
        errors.user_id = " Work Email Id Required";
    } else {
        if (!validateEmail(inputs.user_id)) {
            errors.user_id = "Enter a valid Email Id";
        }
    }

    if (!("email" in inputs)) {
        errors.email = "Email Id Required";
    } else {
        if (!validateEmail(inputs.email)) {
            errors.email = "Enter a valid Email Id";
        }
    }
    if (!("first_occurence_financial_year" in inputs)) {
        errors.first_occurence_financial_year = "Financila year is required";
    } else {
        if (!validateFinanYear(inputs.first_occurence_financial_year)) {
            errors.first_occurence_financial_year =
                "Enter financial year in format YYYY-YY";
        }
    }
    if (!("description" in inputs)) {
        errors.description = "description required";
    }
    if (!("password" in inputs)) {
        errors.password = "Password is Required";
    } else {
        if (inputs.password.length < 6) {
            errors.password = "Minimum length of password is 6 ";
        }
    }
    if (!("pin" in inputs)) {
        errors.pin = "Pincode is Required";
    } else {
        if (inputs.pin.toString().length <= 6) {
        } else {
            errors.pin = "Pincode should be a number less than 6 digits";
        }
    }

    if (!("pincode" in inputs)) {
        errors.pincode = "Pincode is Required";
    } else {
        if (inputs.pincode.toString().length <= 6) {
        } else {
            errors.pincode = "Pincode should be a number less than 6 digits";
        }
    }

    if (!("dob" in inputs)) errors.dob = "Date of birth is Required";

    if (!("doj" in inputs)) errors.doj = "Date of joining is Required";

    if (!("aadhaar" in inputs)) {
        errors.aadhaar = "Aadhaar number  is Required";
    } else {
        if (inputs.aadhaar.toString().length == 12) {
        } else {
            errors.aadhaar = "Aadhaar number should be 12 digits";
        }
    }
    if (!("sac_short_name" in inputs)) {
        errors.sac_short_name = "Required";
    }
    if (!("sac_code" in inputs)) {
        errors.sac_code = "Required";
    }
    if (!("description" in inputs)) {
        errors.description = "Required";
    }
    if (!("rate_general" in inputs)) {
        errors.rate_general = "Required";
    }
    if (!("rate_central_cess" in inputs)) {
        errors.rate_central_cess = "Required";
    }
    if (!("rate_state_cess" in inputs)) {
        errors.rate_state_cess = "Required";
    }
    if (!("short_name" in inputs)) {
        errors.short_name = "Required";
    } else {
        if (inputs.short_name.length > 25) {
            errors.short_name = "Only 25 charecters allowed";
        }
    }
    if (!("llpin" in inputs)) {
        errors.llpin = "Required";
    } else {
        if (inputs.llpin.length > 8) {
            errors.llpin = "Only 8 charecters allowed";
        }
    }
    if (!("contact" in inputs)) {
        errors.contact = "Contact number  is Required";
    } else {
        if (inputs.contact.length >= 10) {
        } else {
            errors.contact = "contact number should not be less than 10 digits";
        }
    }
    if (!("activity_status" in inputs)) {
        errors.activity_status = "Activity status is required";
    }
    if (!("gender" in inputs)) {
        errors.gender = "Gender is Required";
    } else {
        if (inputs.gender === "Gender")
            errors.gender = "Please select one from list";
    }
    if (!("employee_type" in inputs)) {
        errors.employee_type = "Employee Type is Required";
    } else {
        if (inputs.employee_type === "Employee Type")
            errors.employee_type = "Please select one from list";
    }
    if (!("bloodgroup" in inputs)) {
        errors.bloodgroup = "Blood Group is Required";
    } else {
        if (inputs.bloodgroup === "Blood Group")
            errors.gender = "Please select one from list";
    }
    if (!("state" in inputs)) {
        errors.state = "State is Required";
    } else {
        if (inputs.state === "State")
            errors.state = "Please select one from list";
    }
    if (!("country_id" in inputs)) {
        errors.country_id = "Country is Required";
    }
    if (!("industry_id" in inputs)) {
        errors.industry_id = "Industry is Required";
    }
    if (!("lead_partner_id" in inputs)) {
        errors.lead_partner_id = "Lead Partner is Required";
    }
    if (!("assignment_partner_id" in inputs)) {
        errors.assignment_partner_id = "Assignment Partner is Required";
    }
    if (!("client_class_id" in inputs)) {
        errors.client_class_id = "Client class is required";
    }
    if ("cin" in inputs) {
        if (inputs.cin && inputs.cin.length > 21)
            errors.cin = "Please enter a valid cin (max legth =21)";
    } else {
        errors.cin = "Client cin is required";
    }
    if (!("client_status" in inputs)) {
        errors.client_status = "Client Status is required";
    }
    if (!("holiday_code_id" in inputs)) {
        errors.holiday_code_id = "Required";
    }
    if (!("presence" in inputs)) {
        errors.presence = "Required";
    }

    if (!("site" in inputs)) {
        errors.site = "Required";
    }

    if (!("date" in inputs)) {
        errors.date = "Required";
    }
    if (!("holiday_code_id" in inputs)) {
        errors.holiday_code_id = "Required";
    }

    if (!("client_id" in inputs)) {
        errors.client_id = "Please select a client";
    }
    if (!("in_charge_employee_id" in inputs)) {
        errors.in_charge_employee_id = "Please select a employee";
    }
    if (!("in_charge_partner_id" in inputs)) {
        errors.in_charge_partner_id = "Please select a patner";
    }
    if (!("status" in inputs)) {
        errors.status = "Status is required";
    }
    if (!("project_category_id" in inputs)) {
        errors.project_category_id = "Project category is required";
    }
    if (!("udin_mandatory" in inputs)) {
        errors.udin_mandatory = "Input required";
    }
    if (!("project_members_attributes" in inputs)) {
        errors.project_members_attributes = "Please select members";
    }
    if (!("any_deliverables" in inputs)) {
        errors.any_deliverables = "Input required";
    }

    if (!("organisation_id" in inputs)) {
        errors.organisation_id = "Please select Organisation";
    }

    if (!("billable" in inputs)) {
        errors.billable = "Input required";
    }

    if (!("budgeted_bill_amount" in inputs)) {
        errors.budgeted_bill_amount = "Input required";
    }
    if (!("due_date" in inputs)) {
        errors.due_date = "Input required";
    }
    if (!("invoice_basics_attributes" in inputs)) {
        errors.invoice_basics_attributes = "Please add basic details";
    }
    if (!("project_id" in inputs)) {
        errors.project_id = "Please select a project";
    }
    if (!("type_of_document" in inputs)) {
        errors.type_of_document = "Please select type of document";
    }
    if (!("signing_partner_id" in inputs)) {
        errors.signing_partner_id = "Please select signing partner";
    }
    if (!("date_of_document" in inputs)) {
        errors.date_of_document = "Please enter date of document";
    }
    if (!("date_of_udin_generation" in inputs)) {
        errors.date_of_udin_generation = "Please enter date of udin generation";
    }
    if (!("type_of_sign" in inputs)) {
        errors.type_of_sign = "Please enter type of signature";
    }
    if (!("internal_reference" in inputs)) {
        errors.internal_reference = "Please enter internal reference";
    }

    if (!("designation_id" in inputs)) {
        errors.designation_id = "Required";
    }
    if (!("access_role_id" in inputs)) {
        errors.access_role_id = "Required";
    }

    if (!("approvel_status" in inputs)) {
        errors.approvel_status = "Required";
    }
    if (!("udin" in inputs)) {
        errors.udin = "Please enter udin";
    }else{
        if(inputs.udin.length !== 18 ){errors.udin = "Maximum/Minimum charecters allowed for udin is 18"; }
    }
    if (!("udin_status" in inputs)) {
        errors.udin_status = "please select udin status";
    }
    if (!("invoice_opes_attributes" in inputs)) {
        errors.invoice_opes_attributes = "Opes required";
    }
    if (!("bill_to_client_id" in inputs)) {
        errors.bill_to_client_id = "required";
    }
    if (!("place_of_supply_id" in inputs)) {
        errors.place_of_supply_id = "required";
    }
    if (!("tax_code" in inputs)) {
        errors.tax_code = "required";
    }
    if (!("invoice_basic_details_attributes" in inputs)) {
        errors.invoice_basic_details_attributes =
            "Please click on select to add project details";
    }
    if (!("invoice_date" in inputs)) {
        errors.invoice_date = "Please enter invoice date";
    }
    if (!("ope_amount" in inputs)) {
        errors.ope_amount = "Please enter ope amount";
    }
    if (!("nature_of_expense_id" in inputs)) {
        errors.nature_of_expense_id = "Please enter nature of expense";
    }
    if (!("particulars" in inputs)) {
        errors.particulars = "Please enter particulars";
    }
    if (!("organisation_code" in inputs)) {
        errors.organisation_code = "Please enter organisation code";
    }
    if (!("activity_short_description" in inputs)) {
        errors.activity_short_description =
            "please enter activity short description";
    }
    if (!("activity_long_description" in inputs)) {
        errors.activity_long_description =
            "Please enter activity long description";
    }
    if (!("due_date" in inputs)) {
        errors.due_date = "Please enter due date";
    }
    if (!("recurring" in inputs)) {
        errors.recurring = "Please selecto option";
    }
    if (!("recurring_type" in inputs)) {
        errors.recurring_type = "Please select on option";
    }
    if (!("main_contractor_id" in inputs)) {
        errors.main_contractor_id = "Please select on option";
    }
    if (!("contact_no" in inputs)) {
        errors.contact_no = "Required";
    } else {
        if (!isValidPhoneNumber(inputs.contact_no)) {
            errors.contact_no = "Invalid number";
        }
    }

    if (!("recurring_due_date" in inputs)) {
        errors.recurring_due_date = "Please select recurring due date";
    }
    if (!("first_occurence_month_or_quater" in inputs)) {
        errors.first_occurence_month_or_quater =
            "Please select month or quater";
    }
    if (
        !inputs.client_credentials_attributes ||
        !inputs.client_credentials_attributes
    ) {
    } else {
        const credentialArrayErrors = [];
        inputs.client_credentials_attributes.forEach((credential, index) => {
            const credentialErrors = {};
            if (!credential || !credential.particular) {
                credentialErrors.particular = "Required";
                credentialArrayErrors[index] = credentialErrors;
            }

            if (!credential || !credential.username_digest) {
                credentialErrors.username_digest = "Required";
                credentialArrayErrors[index] = credentialErrors;
            }
            if (!credential || !credential.password_digest) {
                credentialErrors.password_digest = "Required";
                credentialArrayErrors[index] = credentialErrors;
            }
        });
        if (credentialArrayErrors.length) {
            errors.client_credentials_attributes = credentialArrayErrors;
        }
    }
    if (
        !inputs.management_details_attributes ||
        !inputs.management_details_attributes
    ) {
    } else {
        const managementArrayErrors = [];
        inputs.management_details_attributes.forEach((management, index) => {
            const managementError = {};
            if (!management || !management.name) {
                managementError.name = "Required";
                managementArrayErrors[index] = managementError;
            }
            if (
                !management ||
                !management.din ||
                management.din.toString().length > 8
            ) {
                managementError.din = "Required, maximum 8 digits";
                managementArrayErrors[index] = managementError;
            }
            if (!management || !management.address) {
                managementError.address = "Required";
                managementArrayErrors[index] = managementError;
            }
            if (
                !management ||
                !management.pin ||
                management.pin.toString().length > 6
            ) {
                managementError.pin = "Required, maximum 6 digits";
                managementArrayErrors[index] = managementError;
            }
            if (!management || !management.designation) {
                managementError.designation = "Required";
                managementArrayErrors[index] = managementError;
            }
            if (
                !management ||
                !management.aadhaar ||
                management.aadhaar.length !== 12
            ) {
                managementError.aadhaar = "Required, length is 12";
                managementArrayErrors[index] = managementError;
            }
            if (!management || !management.contact || management.contact) {
                managementError.contact = management.contact
                    ? isValidPhoneNumber(management.contact)
                        ? undefined
                        : "Invalid phone number"
                    : "Phone number required";
                managementArrayErrors[index] = managementError;
            }

            if (!management || !management.status) {
                managementError.status = "Required";
                managementArrayErrors[index] = managementError;
            }
            if (
                !management ||
                !management.email ||
                !validateEmail(management.email)
            ) {
                managementError.email =
                    "Email Required, must be in valid format";
                managementArrayErrors[index] = managementError;
            }
            if (
                !management ||
                !management.pan ||
                management.pan.length !== 10
            ) {
                managementError.pan = "Required, length is 10";
                managementArrayErrors[index] = managementError;
            }
            if (!management || !management.state) {
                managementError.state = "Required";
                managementArrayErrors[index] = managementError;
            }
            if (!management || !management.city) {
                managementError.city = "Required";
                managementArrayErrors[index] = managementError;
            }
        });
        if (managementArrayErrors.length) {
            errors.management_details_attributes = managementArrayErrors;
        }
    }
    if (
        !inputs.project_members_attributes ||
        !inputs.project_members_attributes.length
    ) {
        errors.project_members_attributes = {
            _error: "At least one member must be entered",
        };
    } else if (
        filter(inputs.project_members_attributes, {
            role_id: { name: "Engagement Partner" },
        }).length === 0
    ) {
        errors.project_members_attributes = {
            _error: "At least one member must be Engagement Partner",
        };
    } else if (
        filter(inputs.project_members_attributes, {
            spec_role: 0, //prepearer
        }).length === 0
    ) {
        errors.project_members_attributes = {
            _error: "At least one member must be preparer",
        };
    } else if (
        filter(inputs.project_members_attributes, {
            spec_role: 1, //reviewer
        }).length === 0
    ) {
        errors.project_members_attributes = {
            _error: "At least one member must be reviewer",
        };
    }else {
        const errorsArray = [];
        inputs.project_members_attributes.forEach((member, index) => {
            const memberErrors = {};
            if (!member || !member.member_id) {
                memberErrors.member_id = "Required";
                errorsArray[index] = memberErrors;
            }
            if (!member || !member.role_id) {
                memberErrors.role_id = "Required";
                errorsArray[index] = memberErrors;
            }
        });
        if (errorsArray.length) {
            errors.project_members_attributes = errorsArray;
        }
    }
    if (
        !inputs.client_tans_attributes ||
        !inputs.client_tans_attributes.length
    ) {
    } else {
        const tanArrayErrors = [];
        inputs.client_tans_attributes.forEach((tan, tanIndex) => {
            const tanErrors = {};
            if (!tan || !tan.tan) {
                tanErrors.tan = "Required";
                tanArrayErrors[tanIndex] = tanErrors;
            } else {
                if (tan.tan.length > 10) {
                    tanErrors.tan = "Length limited to maximum 10 charecters";
                    tanArrayErrors[tanIndex] = tanErrors;
                }
            }
            if (!tan || !tan.remarks) {
                tanErrors.remarks = "Required";
                tanArrayErrors[tanIndex] = tanErrors;
            }
        });
        if (tanArrayErrors.length) {
            errors.client_tans_attributes = tanArrayErrors;
        }
    }

    if (
        !inputs.client_gstins_attributes ||
        !inputs.client_gstins_attributes.length
    ) {
    } else {
        const gstinArrayErrors = [];
        inputs.client_gstins_attributes.forEach((gstin, gstinIndex) => {
            const gstinErrors = {};
            if (!gstin || !gstin.state) {
                gstinErrors.state = "Required";
                gstinArrayErrors[gstinIndex] = gstinErrors;
            }
            if (!gstin || !gstin.gstin || gstin.gstin.length !== 15) {
                gstinErrors.gstin = "Required, length must be 15 charecters";
                gstinArrayErrors[gstinIndex] = gstinErrors;
            }
            if (!gstin || !gstin.taxable) {
                gstinErrors.taxable = "Required";
                gstinArrayErrors[gstinIndex] = gstinErrors;
            }
        });
        if (gstinArrayErrors.length) {
            errors.client_gstins_attributes = gstinArrayErrors;
        }
    }

    return errors;
};
