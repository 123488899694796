import axios from '../../axiosConfig';
import { useQuery, useQueryClient, useMutation } from 'react-query';

async function getClientProjects({ queryKey }) {
  try {
    let data = JSON.stringify({ id: queryKey[1] });
    const res = await axios.get(`/client_projects/${data}`);
    return res.data['projects'];
  } catch (err) {
    throw err.response.data;
  }
}
async function updateBillingStatus(data){
    try{
        const res = await axios.patch(`/project/billing_status/${data.id}`, data);
        return res.data['message']
    }catch(err){
        throw err.response.data;
    }
}

async function getProjectSubClass() {
  try {
    const res = await axios.get(`/project_sub_class`);
    return res.data['project_sub_classes'];
  } catch (err) {
    throw err.response.data;
  }
}
async function getUserProjects({queryKey}){
  try {
    const res = await axios.get(`/project_list/${queryKey[1]}`);
    return res.data['project'];
  } catch (err) {
    throw err.response.data;
  }
}
async function getProjectsByFilter({ queryKey }) {
  try {
    const res = await axios.get(`/all_projects_by_filter/${queryKey[1]}`);
    return res.data['project'];
  } catch (err) {
    throw err.response.data;
  }
}
export const useProjects = (client_id) =>
  useQuery(['clientProjects', client_id], getClientProjects, {
    refetchOnWindowFocus: false,
    enabled: !!client_id,
  });

export const useGetUserProject = (appendClientName) =>
    useQuery(['userProjects', appendClientName], getUserProjects, {
        refetchOnWindowFocus: false,
    })
export const useProjectSubClass = () =>
    useQuery(['project_sub_class'], getProjectSubClass);

export const useProjectsByFilter = (filter) =>
    useQuery(['ProjectsByFilter', filter], getProjectsByFilter, {
        refetchOnWindowFocus: false,
    })

export const useUpdateBillingStatus = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => updateBillingStatus(data),{
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries('ProjectsByFilter')
        },
    })
}

