import axios from "axios";
import { BaseUrl } from "./api";
import {
    FETCH_TIMESHEET_CODE_SUCCESS,
    FETCH_TIMESHEET_CODE_FAILED,
    FETCH_TINESHEET_CODE_START,
    ADD_ITEM_BACK_IN_TIMESHEET_CODE,
    REMOVE_TIMESHEET_CODE_ITEM,
    INITIAL_FILTERING_TIME_SHEET_CODES,
} from "./types";

export const addBackItem = (item) => {
    return async (dispatch) => {
        dispatch({
            type: ADD_ITEM_BACK_IN_TIMESHEET_CODE,
            payload: item,
        });
    };
};

export const initialFiltering = (item) => {
    return async (dispatch) => {
        dispatch({
            type: INITIAL_FILTERING_TIME_SHEET_CODES,
            payload: item,
        });
    };
};

export const removeItem = (item) => {
    return async (dispatch) => {
        dispatch({
            type: REMOVE_TIMESHEET_CODE_ITEM,
            payload: item,
        });
    };
};

export const fetchTimesheetCodes = (token) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_TINESHEET_CODE_START,
        });
        try {
            const res = await axios.get(`${BaseUrl}/timesheet_codes`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.data.status) {
                dispatch({
                    type: FETCH_TIMESHEET_CODE_SUCCESS,
                    payload: res.data["data"],
                });
            } else {
                dispatch({
                    type: FETCH_TIMESHEET_CODE_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_TIMESHEET_CODE_FAILED,
                payload: error.message,
            });
        }
    };
};
