import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import UdinPending from "../views/PendingUdin";

const ReportPage = () => {
    return (
        <PageTemplate>
            {/**<iframe className="embed-responsive min-vh-100 d-inline-block" src="https://datastudio.google.com/s/mzydOZczvLo" allowfullscreen></iframe>**/}
            <UdinPending/>
        </PageTemplate>
    );
};

export default ReportPage;

