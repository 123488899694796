import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field ,change, formValueSelector} from "redux-form";
import { compose } from "redux";
import { connect } from 'react-redux';
import * as actions from "../../actions";
import Header from "../../components/Layout/Header/Header";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import DatePicker from "react-datepicker";
import {InputText,SelectInput,TextArea,validate,SavingDetailsModal,SnackbarAlert, RenderSwitch,FirstHalfSecondHalfRadio} from "../../utils/formComponents";
class AddAttendence extends Component {

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        date: new Date(),
        timeSet: false,
        attendenceAddFailed:true,
        showSnackbar: false
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);

}
    componentWillMount(){
        //could'nt find other way to set default value for toogle
         this.props.dispatch(change("AddAttendence","full_day", true))
    }
closeModal(){
    this.setState({
        loading:this.props.loading,
        attendenceAddFailed:this.props.addAttendenceFailed
    })
}
componentDidUpdate(){
    if(!this.state.attendenceAddFailed){
        this.props.history.goBack();
    }
    if(!this.state.timeSet){
        this.handleChange(new Date())
         this.setState({
             timeSet: true
         })
    }
}
closeSnackbar(){
    this.setState({
        showSnackbar: false
    })
}
handleChange = data =>{
    let p_day = new Date()
    if(data <= p_day){
        this.props.fetchRespectiveCode(this.props.token, moment(data).format("YYYY-MM-DD")).then(resp=>{
            if(resp){
                this.props.dispatch(change("AddAttendence",'code',resp))
                this.setState({
                    showSnackbar: true
                })
            }else{
                this.props.dispatch(change("AddAttendence",'code',"General Working Day"))
            }
        })
        this.props.dispatch(change("AddAttendence","date",moment(data).format("YYYY-MM-DD")))
        this.props.dispatch(change("AddAttendence","day", moment(data).format("dddd")))
    }
   }
async onsubmit(formData) {
    this.setState({
        loading:true
    })
    formData['user_id']=this.props.user.id
    if(formData['full_day']){
        formData['attendence_type'] = "FullDay"
    }else{
        formData['attendence_type'] = formData['first_half_orSecond']
    }
    formData['presence'] = "Present" // user can only mark as present so hard coding here
    await this.props.markAttendence(formData,this.props.token);
    this.setState({
        message:this.props.message
    })
}
  render () {
const { handleSubmit,classes } = this.props;
    let state = this.state;
    return (
      <Container>
      <Header title="attendence" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
        <h1> <Badge variant="dark"> Mark Attendence </Badge> </h1>
      </Typography>
        <Row>
          <Col sm={4}>
          </Col>

        </Row>

        <form onSubmit={handleSubmit(this.onsubmit)} noValidate>
          <Row>
            <Col sm={4}>
                  <DatePicker
                        //selected={this.state.date}
                        onChange={this.handleChange}
                        inline
                    />
            </Col>
            <Col sm={4}>
                <Row style={{marginTop: '10px'}}>
                    <Field
                        name= "full_day"
                        component={RenderSwitch}
                        label= "Full day?"
                        defaultValue={true}
                    />
                </Row>
                {!this.props.full_day &&
                <Row>
                    <Col>
                        <span>First half or Second half?  </span>
                    </Col>
                    <Col>
                        <Field
                            name="first_half_orSecond"
                            label=""
                            component={FirstHalfSecondHalfRadio}
                            fullWidth
                        />
                    </Col>
                </Row>}
            </Col>
            <Col sm={4}>
                <Row>
                    <span>Date</span>
                    <Field
                        name="date"
                        component={InputText}
                        type="date"
                        disabled={true}
                        fullWidth
                    />
                </Row>
                <Row>
                    <Field
                        name="day"
                        label="Day *"
                        component={InputText}
                        type="text"
                        disabled={true}
                        fullWidth
                    />

                </Row>
                <Row>
              <span>Code</span>
              <Field
                        name="code"
                        component={InputText}
                        type="text"
                        disabled={true}
                        fullWidth
              />
          </Row>

            </Col>
        </Row>
        <Row>

            <Col>
              <span>Site</span>
              <Field
                        name="site"
                        component={SelectInput}
                        fullWidth
              />
          </Col>
           <Col>
              <span>Remarks</span>
              <Field
                        name="remark"
                        label="Remark*"
                        component={InputText}
                        type="text"
                        fullWidth
              />
          </Col>

        </Row>

        <Row>

          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving Attendence details"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <SnackbarAlert
        keyValue={this.props.holidayCode.length !== 0 ? this.props.holidayCode : undefined}
        open={this.state.showSnackbar}
        onClose={this.closeSnackbar}
        message={"Selected day is marked as "+this.props.holidayCode+"!!"}
        onClick={this.closeSnackbar}
      />
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>


          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Mark Present</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>

      </Container>
    )
  }
}
const selector = formValueSelector('AddAttendence')
function mapStateToProps(state) {
    return {
        token:state.auth.token,
        user:state.auth.user,
        message:state.attendence.addAttendenceMessage,
        loading:state.attendence.addAttendenceLoading,
        addAttendenceFailed:state.attendence.addAttendenceFailed,
        holidayCode: state.holidayMaster.holidayCode,
        full_day: selector(state, 'full_day')
    }
}

export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddAttendence",
validate }),
    withStyles(styles)
)(AddAttendence);
