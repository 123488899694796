import axios from "../../axiosConfig";
import { useQuery, useMutation, useQueryClient } from "react-query";
import encodeQueryData from "../../utils/encodeQuery";
// query function
async function getAssetCategoryList() {
    try {
        const res = await axios.get(`/asset_categories`);
        return res.data;
    } catch (err) {
        throw err.response.data;
    }
}

async function getFirmAssetsList(data) {
    try {
        let querystring = "";
        querystring += encodeQueryData(data);
        const res = await axios.get(`/firm_assets/?${querystring}`);
        return res.data;
    } catch (err) {
        console.log("err", err);
        throw err.response.data;
    }
}
async function getFirmAssetById({queryKey}) {
    try {
        const res = await axios.get(`/firm_assets/${queryKey[1]}`);
        return res.data;
    } catch (err) {
        console.log("err", err);
        throw err.response.data;
    }
}
async function firmAssetMovement(data) {
    try {
        const res = await axios.post(`/firm_asset_details`, {firm_asset_details: data});
        return res.data;
    } catch (err) {
        console.log(err, "err")
        throw err.response.data;
    }
}

async function createFirmAsset(data) {
    try {
        const res = await axios.post(`/firm_assets`, data);
        return res.data;
    } catch (err) {
        throw err.response.data;
    }
}

// query hooks

export const useFirmAssetList = (data) =>
    useQuery(["firm_assets", data], () => getFirmAssetsList(data), {
        keepPreviousData: true,
    });

export const useAssetCategoryList = () =>
    useQuery(["asset_category"], getAssetCategoryList);

export const useCreateFirmAsset = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => createFirmAsset(data), {
        onSuccess: () => queryClient.invalidateQueries("firm_assets"),
    });
};
export const useFirmAssetMovement = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => firmAssetMovement(data), {
        onSuccess: () => queryClient.invalidateQueries("firm_asset_details"),
    });
};
export const useFirmAssetDetails = (data)=>  useQuery(["firm_asset_details", data], getFirmAssetById,{
        refetchOnWindowFocus: false,
        enabled: !!data
    });
