import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_NATURE_OF_EXPENSE_ADD,
    START_NATURE_OF_EXPENSE_FETCH,
    DONE_NATURE_OF_EXPENSE_ADD,
    DONE_NATURE_OF_EXPENSE_FETCH,
    FAILED_NATURE_OF_EXPENSE_ADD,
    FAILED_NATURE_OF_EXPENSE_FETCH,
} from "./types";

export const fetchNatureofexp = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_NATURE_OF_EXPENSE_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/nature_of_expense_list`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: DONE_NATURE_OF_EXPENSE_FETCH,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: FAILED_NATURE_OF_EXPENSE_FETCH,
                    payload: "Fetching expense  list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: FAILED_NATURE_OF_EXPENSE_FETCH,
                payload: "Fetching expense  list failed",
            });
        }
    };
};

export const addNatureofexp = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_NATURE_OF_EXPENSE_ADD,
        });
        try {
            const res = await axios.post(`${BaseUrl}/nature_of_expense`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "Created") {
                dispatch({
                    type: DONE_NATURE_OF_EXPENSE_ADD,
                    payload: "Sucessfully created  nature of expense",
                });
            } else {
                dispatch({
                    type: FAILED_NATURE_OF_EXPENSE_ADD,
                    payload: "Adding expense  failed",
                });
            }
        } catch (error) {
            dispatch({
                type: FAILED_NATURE_OF_EXPENSE_ADD,
                payload: "Adding expense  failed",
            });
        }
    };
};
