import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CommonList from '../views/CommonTableView';
const ClientClassPage = () => {
    return (
        <PageTemplate>
           <CommonList page={"client_class"}/>
        </PageTemplate>
    );
};

export default ClientClassPage;

