import React from "react";
import { styles } from "./styles";

export const InternalDetails = ({ client_details }) => {
    return (
        <div style={styles.infoSectionContainer}>
            {client_details ? (
                <div style={styles.infoContainer}>
                    <h3 style={styles.infoHeading}>Internal</h3>
                    <div style={styles.info}>
                        <div>
                            <div>Association Year</div>
                            <div>Lead Partner</div>
                            <div>Lead Channel</div>
                            <div>Assignment Partner</div>
                            <div>Internal Client</div>
                            <div>Last modified user</div>
                            <div>Last modified time</div>
                            <div>Created by user</div>
                            <div>Created time</div>
                        </div>
                        <div>
                            <div>
                                {client_details.association_year ? client_details.association_year : "---"}
                            </div>
                            <div>{client_details.lead_partner ? client_details.lead_partner : "---"}</div>
                            <div>{client_details.lead_channel ? client_details.lead_channel : "---"}</div>
                            <div>
                                {client_details.assignment_partner_id
                                    ? client_details.assignment_partner_id.name
                                    : "---"}
                            </div>
                            <div>
                                {client_details.internal_client ? "YES" : "NO"}
                            </div>
                            <div>
                                {client_details.last_modified_by || "---"}
                            </div>
                            <div>
                                {client_details.last_modified_on || "---"}
                            </div>
                            <div>{client_details.created_by || "---"}</div>
                            <div>{client_details.created_on || "---"}</div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>No internal details available</div>
            )}
        </div>
    );
};
