import React from "react";
import { styles } from "./styles";

export const BasicDetails = ({ client_details }) => {
    return (
        <div style={styles.infoSectionContainer}>
            <div style={styles.infoContainer}>
                <h3 style={styles.infoHeading}>General Info</h3>
                <div style={styles.info}>
                    <div>
                        <div>Client ID</div>
                        <div>Name</div>
                        <div>Address</div>
                        <div>City</div>
                        <div>District</div>
                        <div>PIN</div>
                        <div>State</div>
                    </div>
                    <div>
                        <div>{client_details.id}</div>
                        <div>{client_details.name}</div>
                        <div>{client_details.address}</div>
                        <div>{client_details.city}</div>
                        <div>{client_details.district}</div>
                        <div>{client_details.pin}</div>
                        <div>{client_details.state}</div>
                    </div>
                </div>
            </div>

            <div style={styles.infoContainer}>
                <h3 style={styles.infoHeading}>Contact</h3>
                <div style={styles.info}>
                    <div>
                        <div>Email</div>
                        <div>Contact No.</div>
                        <div>Contact Person</div>
                    </div>
                    <div>
                        <div>{client_details.email}</div>
                        <div>{client_details.contact_no}</div>
                        <div>{client_details.contact_person}</div>
                    </div>
                </div>
            </div>

            <div style={styles.infoContainer}>
                <h3 style={styles.infoHeading}>Classification</h3>
                <div style={styles.info}>
                    <div>
                        <div>Short Name</div>
                        <div>Class</div>
                        <div>Group</div>
                        <div>Contact Type</div>
                        <div>Main contractor</div>
                        <div>Risk</div>
                        <div>Status</div>
                    </div>
                    <div>
                        <div>{client_details.short_name}</div>
                        <div>{client_details.client_class}</div>
                        <div>{client_details.group || "--"}</div>
                        <div>{client_details.contact_type || "--"}</div>
                        <div>{client_details.main_contractor_id ? client_details.main_contractor_id.name : "--"}</div>
                        <div>{client_details.client_risk || "--"}</div>
                        <div>{client_details.client_status || "--"}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
