import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import invoiceReducer from './invoiceReducer';
import authReducer from './auth';
import clientReducer from './clientReducer';
import projectReducer from './projectReducer';
import clientclassReducer from './clientclassReducer';
import projectclassReducer from './projectclassReducer';
import employeeReducer from './employeeReducer';
import opeReducer from './opeReducer';
import changeReducer from './changeReducer';
import searchReducer from './searchReducer';
import organisationReducer from './organisationReducer';
import timesheetReducer from './timesheetReducer';
import natureofexpenseReducer from './natureofexpenseReducer';
import issueregisterReducer from './issueregisterReducer';
import leaveReducer from './leaveReducer';
import movementRegisterReducer from './movementregisterReducer';
import certificateregisterReducer from './certificateregisterReducer';
import activityReducer from './activityReducer';
import projectactivityReducer from './projectactivityReducer';
import projectdiaryReducer from './projectdiaryReducer';
import websocketReducer from './websocketReducer';
import projectdashboardReducer from './projectdashboardReducer';
import inwardregisterReducer from './inwardregisterReducer';
import outwardregisterReducer from './outwardregisterReducer';
import auditdocumentregisterReducer from './auditdocumentregisterReducer';
import sacmasterReducer from './sacmasterReducer';
import specmasterReducer from './specmasterReducer';
import accessRolesReducer from './accessRolesReducer';
import designationReducer from './designationReducer';
import timesheetcodeReducer from './timesheetcodeReducer';
import holidayCodeReducer from './holidayCodeReducer';
import roleReducer from './roleReducer';
import holidatmasterReducer from './holidatmasterReducer';
import attendenceReducer from './attendenceReducer';
import projectSuperClassReducer from './projectSuperClassReducer';
import leadChannelReducer from './leadChannelReducer';
import settingsReducer from './settingsReducer';
import placeOfSupplyReducer from './placeOfSupplyReducer';
import notificationsReducer from './notificationsReducer';
import countriesReducer from './countriesReducer';
import industriesReducer from './industriesReducer';
import currencyReducer from './currencyReducer';
import exchangeRateReducer from './exchangeRateReducer';

export default combineReducers({
  changed: changeReducer,
  form: formReducer,
  leave: leaveReducer,
  invoice: invoiceReducer,
  auth: authReducer,
  search: searchReducer,
  client: clientReducer,
  employee: employeeReducer,
  ope: opeReducer,
  clientClass: clientclassReducer,
  project: projectReducer,
  projectClass: projectclassReducer,
  organisation: organisationReducer,
  natureofexpense: natureofexpenseReducer,
  timesheet: timesheetReducer,
  issueregister: issueregisterReducer,
  movementregister: movementRegisterReducer,
  certificateregister: certificateregisterReducer,
  activity: activityReducer,
  projectActivity: projectactivityReducer,
  projectDiary: projectdiaryReducer,
  webSocket: websocketReducer,
  projectDashboard: projectdashboardReducer,
  auditDocument: auditdocumentregisterReducer,
  inwardRegister: inwardregisterReducer,
  outwardRegister: outwardregisterReducer,
  sacmaster: sacmasterReducer,
  specmaster: specmasterReducer,
  roles: accessRolesReducer,
  designation: designationReducer,
  memberRole: roleReducer,
  timesheetCode: timesheetcodeReducer,
  holidayCode: holidayCodeReducer,
  holidayMaster: holidatmasterReducer,
  attendence: attendenceReducer,
  projectSuperClass: projectSuperClassReducer,
  settingsReducer: settingsReducer,
  placeOfSupply: placeOfSupplyReducer,
    notifications: notificationsReducer,
    industries: industriesReducer,
    countries: countriesReducer,
    currency: currencyReducer,
    exchangeRate: exchangeRateReducer
  //leadChannels: leadChannelReducer
});
