import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_CLIENT_SEARCH,
    CLIENT_SEARCH_DONE,
    CLIENT_SEARCH_FAILED,
    SEARCH_OVER,
    START_PROJECT_SEARCH,
    PROJECT_SEARCH_DONE,
    PROJECT_SEARCH_FAILED,
    MOVEMENT_REGISTER_SERACH_FAILED,
    MOVEMENT_REGISTER_SERACH_SUCCESS,
    START_SEARCH_MOVEMENT_REGISTER,
} from "./types";

export const projectSearch = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_PROJECT_SEARCH,
        });
        try {
            const res = await axios.post(`${BaseUrl}/project/search`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: PROJECT_SEARCH_DONE,
                    payload: res.data["project"],
                });
            } else {
                dispatch({
                    type: PROJECT_SEARCH_FAILED,
                    payload: "search  failed",
                });
            }
        } catch (error) {
            dispatch({
                type: PROJECT_SEARCH_FAILED,
                payload: "searching  failed",
            });
        }
    };
};

export const clientSearch = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_CLIENT_SEARCH,
        });
        try {
            const res = await axios.post(`${BaseUrl}/client/search`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: CLIENT_SEARCH_DONE,
                    payload: res.data["client"],
                });
            } else {
                dispatch({
                    type: CLIENT_SEARCH_FAILED,
                    payload: "search client failed",
                });
            }
        } catch (error) {
            dispatch({
                type: CLIENT_SEARCH_FAILED,
                payload: "searching client  failed",
            });
        }
    };
};
export const searchMovementRegister = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_SEARCH_MOVEMENT_REGISTER,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/movement_register/search`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: MOVEMENT_REGISTER_SERACH_SUCCESS,
                    payload: res.data["movement_registers"],
                });
            } else {
                dispatch({
                    type: MOVEMENT_REGISTER_SERACH_FAILED,
                    payload: "adding new movement register failed",
                });
            }
        } catch (error) {
            dispatch({
                type: MOVEMENT_REGISTER_SERACH_FAILED,
                payload: "adding new movement register failed",
            });
        }
    };
};

export const searchOver = () => {
    return async (dispatch) => {
        dispatch({
            type: SEARCH_OVER,
        });
    };
};
