import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CommonList from '../views/CommonTableView';
const SacMasterPage = () => {
    return (
        <PageTemplate>
           <CommonList page={"sac_master"}/>
        </PageTemplate>
    );
};

export default SacMasterPage;

