import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field ,change} from "redux-form";
import { compose } from "redux";
import { connect } from 'react-redux';
import * as actions from "../../actions";
import Header from "../../components/Layout/Header/Header";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import DatePicker from "react-datepicker";
import MultiSelectComponent from "../../utils/multiselect";
import {InputText,SelectInput,TextArea,validate,SavingDetailsModal} from "../../utils/formComponents";
class AddHoliday extends Component {

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        date: new Date(),
        holidayAddFailed:true
    };

    this.onsubmit = this.onsubmit.bind(this);
 this.closeModal = this.closeModal.bind(this);

}

closeModal(){
    this.setState({
        loading:this.props.loading,
        holidayAddFailed:this.props.addHolidayFailed
    })
}
componentDidMount(){
    this.props.fetchHolidayCode(this.props.token)
}
componentDidUpdate(){
    if(!this.state.holidayAddFailed){
        this.props.history.goBack();
    }
}
handleChange = data =>{
   this.props.dispatch(change("AddHoliday","date",moment(data).format("YYYY-MM-DD")))
   this.props.dispatch(change("AddHoliday","day", moment(data).format("dddd")))
}
async onsubmit(formData) {

    this.setState({
        loading:true
    })
    formData['created_by_id'] = this.props.user.id
    formData['holiday_code_id'] = formData['holiday_code_id']['id']
    await this.props.addHoliday(formData,this.props.token);
    this.setState({
        message:this.props.message
    })
}
  render () {
const { handleSubmit,classes } = this.props;
    let state = this.state;
    return (
      <Container>
      <Header title="holiday_master" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
        <h1> <Badge variant="dark"> Holiday Master </Badge> </h1>
      </Typography>
        <Row>
          <Col sm={4}>
          </Col>

        </Row>

        <form onSubmit={handleSubmit(this.onsubmit)} noValidate>
          <Row>
            <Col sm={8}>
                  <DatePicker
        //selected={this.state.date}
        onChange={this.handleChange}
            inline
      />
            </Col>
            <Col sm={4}>
                <Row>
                    <span>Date</span>
                    <Field
                        name="date"
                        component={InputText}
                        type="date"
                        fullWidth
              />
</Row>
                <Row>
                    <Field
                        name="day"
                        label="Day *"
                        component={InputText}
                        type="text"
                        disabled={true}
                        fullWidth
              />

        </Row>
            </Col>
        </Row>
        <Row>
            <Col>
              <span>Select Code</span>
              <Field
                  name="holiday_code_id"
                  label=""
                  props={{
                      type: 'object'
                  }}
                  component={MultiSelectComponent}
                  {...{
                      data:this.props.holidayCodeList,
                      isMulti : false
                  }

                  }
                  fullWidth
              />

            </Col>
           <Col>
              <span>Remarks</span>
              <Field
                        name="remark"
                        label="Remark*"
                        component={InputText}
                        type="text"
                        fullWidth
              />
          </Col>

        </Row>

        <Row>
        <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving Holiday  details"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>


          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>

      </Container>
    )
  }
}

function mapStateToProps(state) {
    return {
        token:state.auth.token,
        user:state.auth.user,
        message:state.holidayMaster.addHolidayMessage,
        loading:state.holidayMaster.loading,
        addHolidayFailed:state.holidayMaster.addHolidayFailed,
        holidayCodeList: state.holidayCode.holiday_codes
    }
}

export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddHoliday",
validate }),
    withStyles(styles)
)(AddHoliday);
