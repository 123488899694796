import {START_APPLYING_FOR_LEAVE,APPLYING_FOR_LEAVE_SUCCESS,APPLYING_FOR_LEAVE_FAILED,
START_FETCH_LEAVE_APPLICATION,LEAVE_APPLICATION_FETCH_SUCCESS,LEAVE_APPLICATION_FETCH_FAILED,
    START_LEAVE_APPLICATION_LIST_FETCH,LEAVE_APPLICATION_LIST_FETCH_SUCCESS,LEAVE_APPLICATION_LIST_FETCH_FAILED,
    LEAVE_CATEGORY_FETCH_START, LEAVE_CATEGORY_FETCH_SUCCESS, LEAVE_CATEGORY_FETCH_FAILED
} from "../actions/types";

const DEFAULT_STATE = {
    leaveList: [],
    leaveListLoading: false,
    applyLeaveLoading: false,
    applyLeavedFailed: true,
    applyLeaveMessage: '',
    leaveListerrorMessage:'',
    leaveCategories: []

}

export default (state = DEFAULT_STATE,action) => {
    switch(action.type){
        case START_APPLYING_FOR_LEAVE:
            return{
                ...state,
                applyLeaveLoading:true,
                applyLeavedFailed:false,
                applyLeaveMessage:''
            }
        case APPLYING_FOR_LEAVE_SUCCESS:
            return {
                ...state,
                applyLeaveLoading:false,
                applyLeavedFailed:false,
                applyLeaveMessage:action.payload
            }
        case APPLYING_FOR_LEAVE_FAILED:
            return {
                ...state,
                applyLeaveLoading:false,
                applyLeavedFailed:true,
                applyLeaveMessage:action.payload
            }
        case START_LEAVE_APPLICATION_LIST_FETCH:
            return {
                ...state,
                leaveListLoading:true,
                leaveList:[],
                leaveListerrorMessage:''
            }
        case LEAVE_APPLICATION_LIST_FETCH_SUCCESS:
            return {
                ...state,
                leaveList:action.payload,
                leaveListLoading:false
            }
        case LEAVE_APPLICATION_LIST_FETCH_FAILED:
            return {
                ...state,
                leaveListLoading: false,
                leaveListerrorMessage:action.payload
            }
        case LEAVE_CATEGORY_FETCH_START:
            return{
                ...state,
                leaveCategories: [],
            }
        case LEAVE_CATEGORY_FETCH_SUCCESS:
            return{
                ...state,
                leaveCategories: action.payload
            }
        case LEAVE_CATEGORY_FETCH_FAILED:
            return{
                ...state,
                leaveCategories: []
            }
        default:
            return state
    }
}
