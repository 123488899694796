import React, { Component, Fragment } from "react";
import Header from "../../components/Layout/Header/Header";
import { Row, Col, Container, Badge } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import styles from "../modelstyles";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { pickBy, startsWith } from "lodash";
import DatePicker from "react-datepicker";
import { compose } from "redux";
import {
    reduxForm,
    Field,
    initialize,
    change,
    getFormValues,
    FieldArray,
} from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../actions";
import MultiSelectComponent from "../../utils/multiselect";
import {
    InputText,
    validate,
    padZeros,
    SavingDetailsModal,
    HourMinutes,
    getMinutes,
} from "../../utils/formComponents";
import "react-datepicker/dist/react-datepicker.css";
import ClearIcon from "@material-ui/icons/Clear";
import { unregisterField } from "redux-form";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
    Tooltip,
    TableContainer,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { FixedSizeGrid as Grid } from "react-window";

class TimeSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            timesheetAddFailed: true,
            project_total: [],
            code_total: [],
            code_attributes: [{ id: 0 }],
            day_total: [],
            day_diff: [],
            time_sheet_valid: true,
            date: new Date(),
            today: new Date(),
            weekDays: new Array(),
            dayNames: new Array(),
        };
        this.getDayTotal = this.getDayTotal.bind(this);
        this.getProjectTotal = this.getProjectTotal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
        this.isTimeSheetValid = this.isTimeSheetValid.bind(this);
        this.cell = this.cell.bind(this);
    }
    getCodeTotal(code) {
        let total = 0;
        if (
            this.props.formValues &&
            this.props.formValues["code_attributes"] !== undefined
        ) {
            if (
                this.props.formValues["code_attributes"][code] !== "undefined"
            ) {
                for (var item in this.props.formValues["code_attributes"][
                    code
                ]) {
                    if (
                        item !== "time_sheet_code_id" &&
                        item !== "isdisabled"
                    ) {
                        total =
                            total +
                            getMinutes(
                                this.props.formValues["code_attributes"][code][
                                    item
                                ]
                            );
                    }
                }
            }
            let hour = Math.floor(total / 60);
            hour = padZeros(hour);
            let min = total % 60;
            min = padZeros(min);
            this.state["code_total"][code] = hour + ":" + min;
            return typeof this.state["code_total"][code] !== "undefined"
                ? this.state["code_total"][code]
                : "00:00";
        } else {
            return "00:00";
        }
    }

    getProjectTotal(project) {
        let total = 0;
        if (this.props.formValues) {
            var result = pickBy(this.props.formValues, (value, key) => {
                return startsWith(key, project + "_");
            });
            for (var project in result) {
                if (result[project] !== "undefined") {
                    total = total + getMinutes(result[project]);
                }
            }
            let hour = Math.floor(total / 60);
            hour = padZeros(hour);
            let min = total % 60;
            min = padZeros(min);
            this.state["project_total"][project] = hour + ":" + min;
            return typeof this.state["project_total"][project] !== "undefined"
                ? this.state["project_total"][project]
                : "00:00";
        } else {
            return "00:00";
        }
    }
    getDayTotal(day) {
        let total = 0;
        if (this.props.formValues) {
            this.props.project.forEach((item, index) => {
                if (
                    typeof this.props.formValues[item.id + "_" + day] !==
                    "undefined"
                ) {
                    total =
                        total +
                        getMinutes(this.props.formValues[item.id + "_" + day]);
                }
            });
            if (this.props.formValues["code_attributes"] !== undefined) {
                this.props.formValues["code_attributes"].forEach(
                    (item, index) => {
                        if (item[day] !== undefined) {
                            total = total + getMinutes(item[day]);
                        }
                    }
                );
            }
            let diff = 420 - total;
            let diff_h = Math.floor(Math.abs(diff) / 60);
            diff_h = padZeros(diff_h);
            let diff_min = Math.abs(diff) % 60;
            diff_min = padZeros(diff_min);
            this.state["day_diff"][day] = diff_h + ":" + diff_min;
            let hour = Math.floor(total / 60);
            hour = padZeros(hour);
            let min = total % 60;
            min = padZeros(min);
            this.state["day_total"][day] = hour + ":" + min;
            return typeof this.state["day_total"][day] !== "undefined"
                ? this.state["day_total"][day]
                : "00:00";
        } else {
            return "00:00";
        }
    }
    closeModal() {
        this.setState({
            loading: this.props.loading,
            timesheetAddFailed: this.props.timesheetAddFailed,
        });
    }
    isTimeSheetValid() {
        for (var key in this.state.day_total) {
            let parts = key.split("-");
            let day = moment(parts[2] + "-" + parts[1] + "-" + parts[0]).format(
                "dddd"
            );
            if (
                day === "Saturday" ||
                day === "Sunday" ||
                this.compareDates(
                    key,
                    moment(this.state.today).format("DD-MM-YYYY")
                )
            ) {
            } else {
                let min = getMinutes(this.state.day_total[key]);
                if (min > 1440) {
                    // removed min < 420
                    this.setState({
                        time_sheet_valid: false,
                    });
                    return false;
                }
            }
        }
        return true;
    }
    async onsubmit(formData) {
        let temp = this.isTimeSheetValid();
        if (temp) {
            this.setState({
                loading: true,
            });
            await this.props.logTimesheet(
                formData,
                this.props.token,
                this.props.user,
                this.props.editable_days
            );
            this.setState({
                message: this.props.message,
            });
        }
    }
    componentDidMount() {
        this.props.fetchProject(this.props.token, true);
        this.props.fetchTimesheetCodes(this.props.token);
        this.handleChange(this.state.date);
        this.props.fetchSetting("days_editable_timesheet", this.props.token);
        this.props.fetchTimesheet(this.props.token).then((resp) => {
            console.log("response received timesheet",resp)
            this.props.initialize(resp);
            this.setState({
                code_attributes: resp["code_attributes"],
            });
            //below code filtering out timesheet code already selected
            let obj = [];
            for (var item in resp["code_attributes"]) {
                for (var key in resp["code_attributes"][item]) {
                    if (key.includes("time_sheet_code_id")) {
                        obj.push(resp["code_attributes"][item][key]["id"]);
                    }
                }
            }
            this.props.initialFiltering(obj);
        });
    }

    compareDates = (d1, d2) => {
        let result = false;
        if (d1 && d2) {
            var parts_d1 = d1.split("-");
            var d1 = Number(parts_d1[2] + parts_d1[1] + parts_d1[0]);
            var parts_d2 = d2.split("-");
            var d2 = Number(parts_d2[2] + parts_d2[1] + parts_d2[0]);
            result = d1 > d2;
            //logic for 10 days backward not editable https://stackoverflow.com/a/2627493/5706413
            if (!result) {
                const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                const firstDate = new Date(
                    parts_d1[2],
                    parts_d1[1] - 1,
                    parts_d1[0]
                );
                const secondDate = new Date(
                    parts_d2[2],
                    parts_d2[1] - 1,
                    parts_d2[0]
                );
                const diffDays = Math.round(
                    Math.abs((firstDate - secondDate) / oneDay)
                );
                if (this.props.editable_days["value"]) {
                    result =
                        diffDays > Number(this.props.editable_days["value"]);
                } else {
                    result = diffDays > 5;
                }
            }
        }
        return result;
    };
    handleChange = (date) => {
        this.setState({
            date: date,
        });
        let temp = date;
        var month, day, year, newdate;
        temp.setDate(temp.getDate() - temp.getDay() + 1);
        let week = [],
            names = [];
        for (var i = 0; i < 7; i++) {
            names.push(temp.toLocaleDateString("en-IN", { weekday: "long" }));
            month = temp.getMonth() + 1; //months from 1-12
            day = temp.getDate();
            month = padZeros(month);
            day = padZeros(day);
            year = temp.getFullYear();
            newdate = day + "-" + month + "-" + year;
            week.push(newdate);
            temp.setDate(temp.getDate() + 1);
        }
        this.setState({
            weekDays: week,
            dayNames: names,
        });
    };
    selectedCode(index) {
        if (this.props.formValues !== undefined) {
            if (
                typeof this.props.formValues["code_attributes"] !== "undefined"
            ) {
                if (
                    this.props.formValues["code_attributes"][index] !==
                        undefined &&
                    this.props.formValues["code_attributes"][index] !== null
                ) {
                    if (
                        this.props.formValues["code_attributes"][index][
                            "time_sheet_code_id"
                        ] !== undefined
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
    cell = ({ columnIndex, rowIndex, style }) => {
        let element = <></>;
        let rowId = rowIndex - 1; //to compensate for header row
        if (this.props.project.length === 0) return null;
        if (rowId == -1) {
            //show headers
            if (columnIndex == 0) {
                element = <>Projects</>;
            } else if (columnIndex > 0 && columnIndex <= 7) {
                element = (
                    <>
                        <Row>{this.state.weekDays[columnIndex - 1]}</Row>
                        <Row>{this.state.dayNames[columnIndex - 1]}</Row>
                    </>
                );
            } else if (columnIndex == 8) {
                element = <>Total</>;
            } else {
                //leave this column for code attribute buttons
            }
        } else if (rowId >= 0 && rowId < this.props.project.length) {
            //show projectss values
            if (columnIndex == 0) {
                element = <>{this.props.project[rowId]["name"]}</>;
            } else if (columnIndex > 0 && columnIndex <= 7) {
                element = (
                    <>
                        <Field
                            name={
                                this.props.project[rowId].id +
                                "_" +
                                this.state.weekDays[columnIndex - 1]
                            }
                            component={HourMinutes}
                            label="HH:MM"
                            className={this.props.classes.hourMinutes}
                            disabled={this.compareDates(
                                this.state.weekDays[columnIndex - 1],
                                moment(this.state.today).format("DD-MM-YYYY")
                            )}
                        />
                    </>
                );
            } else if (columnIndex == 8) {
                element = (
                    <>{this.getProjectTotal(this.props.project[rowId].id)}</>
                );
            } else {
                //leave this column for code attribute buttons
            }
        } else if (
            rowId >= this.props.project.length &&
            rowId <
                this.props.project.length + this.state.code_attributes.length &&
            rowId - this.props.project.length <=
                this.state.code_attributes.length
        ) {
            //show code attributes values
            if (columnIndex == 0) {
                element = (
                    <>
                        <Field
                            name={`code_attributes[${
                                rowId - this.props.project.length
                            }].time_sheet_code_id`}
                            label=""
                            props={{
                                type: "object",
                            }}
                            component={MultiSelectComponent}
                            {...{
                                data: this.props.timesheetCodes,
                                isMulti: false,
                                isDisabled: this.state.code_attributes[
                                    rowId - this.props.project.length
                                ]["isdisabled"]
                                    ? true
                                    : false,
                            }}
                            fullWidth
                        />
                    </>
                );
            } else if (columnIndex > 0 && columnIndex <= 7) {
                element = (
                    <>
                        <Field
                            name={`code_attributes[${
                                rowId - this.props.project.length
                            }].${this.state.weekDays[columnIndex - 1]}`}
                            component={HourMinutes}
                            label="HH:MM"
                            className={this.props.classes.hourMinutes}
                            disabled={
                                this.compareDates(
                                    this.state.weekDays[columnIndex - 1],
                                    moment(this.state.today).format(
                                        "DD-MM-YYYY"
                                    )
                                ) ||
                                this.selectedCode(
                                    rowId - this.props.project.length
                                )
                            }
                        />
                    </>
                );
            } else if (columnIndex == 8) {
                element = (
                    <>{this.getCodeTotal(rowId - this.props.project.length)}</>
                );
            } else {
                //leave this column for code attribute buttons
                element = (
                    <>
                        <Button
                            disabled={
                                this.state.code_attributes[
                                    rowId - this.props.project.length
                                ]["isdisabled"]
                                    ? true
                                    : false
                            }
                            onClick={() => {
                                if (
                                    this.props.formValues["code_attributes"] &&
                                    this.props.formValues["code_attributes"][
                                        rowId - this.props.project.length
                                    ] !== undefined
                                ) {
                                    if (
                                        this.props.formValues[
                                            "code_attributes"
                                        ][rowId - this.props.project.length][
                                            "time_sheet_code_id"
                                        ] !== undefined
                                    ) {
                                        this.props.addBackItem(
                                            this.props.formValues[
                                                "code_attributes"
                                            ][
                                                rowId -
                                                    this.props.project.length
                                            ]["time_sheet_code_id"]
                                        );
                                    }
                                    this.setState({
                                        code_attributes:
                                            this.state.code_attributes.filter(
                                                (item) =>
                                                    item.id !==
                                                    rowId -
                                                        this.props.project
                                                            .length
                                            ),
                                    });
                                    this.props.formValues[
                                        "code_attributes"
                                    ].splice(
                                        rowId - this.props.project.length,
                                        1
                                    );
                                } else {
                                    this.setState({
                                        code_attributes:
                                            this.state.code_attributes.filter(
                                                (item) =>
                                                    item.id !==
                                                    rowId -
                                                        this.props.project
                                                            .length
                                            ),
                                    });
                                }
                                //this.props.dispatch(unregisterField('TimeSheet', `code_attributes[${rowId-this.props.project.length}]`))
                            }}
                        >
                            <ClearIcon />
                        </Button>
                    </>
                );
            }
        } else if (
            rowId ==
            this.props.project.length + this.state.code_attributes.length
        ) {
            //show total
            if (columnIndex == 0) {
                element = <>Total</>;
            } else if (columnIndex > 0 && columnIndex <= 7) {
                element = (
                    <>
                        {" "}
                        {this.getDayTotal(this.state.weekDays[columnIndex - 1])}
                    </>
                );
            } else {
            }
        } else {
            //show diffrence
            if (columnIndex == 0) {
                element = <>Diffrence</>;
            } else if (columnIndex > 0 && columnIndex <= 7) {
                element = (
                    <>
                        {this.state.day_diff[
                            this.state.weekDays[columnIndex - 1]
                        ] !== undefined
                            ? this.state.day_diff[
                                  this.state.weekDays[columnIndex - 1]
                              ]
                            : "420"}
                    </>
                );
            } else {
            }
        }
        return (
            <div className={this.props.classes.timesheetItem} style={style}>
                {element}
            </div>
        );
    };
    render() {
        const { handleSubmit, classes } = this.props;
        return (
            <div className={classes.container}>
                <Header title="timesheet" />
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="textPrimary"
                >
                    Timesheet
                </Typography>
                <DatePicker
                    //selected={this.state.date}
                    onChange={this.handleChange}
                    inline
                />
                <form onSubmit={handleSubmit(this.onsubmit)}>
                    <Grid
                        className={classes.Grid}
                        columnCount={10}
                        columnWidth={window.innerWidth * 0.075}
                        height={600}
                        rowCount={
                            this.props.project.length +
                            this.state.code_attributes.length +
                            3
                        }
                        rowHeight={window.innerHeight * 0.15}
                        width={window.innerWidth * 0.75}
                    >
                        {this.cell}
                    </Grid>

                    <Button
                        onClick={() =>
                            this.setState({
                                code_attributes: [
                                    ...this.state.code_attributes,
                                    { id: this.state.code_attributes.length },
                                ],
                            })
                        }
                        color="primary"
                        variant="contained"
                    >
                        Add Item
                    </Button>
                    <Row>
                        <Col sm={4}>
                            <SavingDetailsModal
                                className={classes.modal}
                                show={!this.state.time_sheet_valid}
                                title={"TimeSheet Data Invalid"}
                                loading={this.props.loading}
                                message={
                                    "Make sure the total of daily allocated time is between  7 hours and 24 Hours"
                                }
                                onClick={(e) => {
                                    this.setState({ time_sheet_valid: true });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <SavingDetailsModal
                                className={classes.modal}
                                show={this.state.loading}
                                title={"Saving Timesheet data..."}
                                loading={this.props.loading}
                                message={this.props.message}
                                onClick={this.closeModal}
                            />
                        </Col>
                    </Row>

                    <Row className="float-right">
                        <Col>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        project: state.project.projectList,
        token: state.auth.token,
        user: state.auth.user,
        timesheetCodes: state.timesheetCode.timesheetCodes,
        message: state.timesheet.timesheetAddMessage,
        timesheetAddFailed: state.timesheet.timesheetAddFailed,
        loading: state.timesheet.timesheetAddLoading,
        editable_days: state.settingsReducer.setting,
        formValues: getFormValues("TimeSheet")(state),
    };
}
export default compose(
    withRouter,
    reduxForm({
        form: "TimeSheet",
        validate,
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
    }),
    withStyles(styles),
    connect(mapStateToProps, actions)
)(TimeSheet);
