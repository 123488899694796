import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
// import AboutWrapper from '../containers/AboutWrapper/AboutWrapper'
import TimeSheet from '../views/TimeSheet';
const TimeSheetPage = () => {
    return (
        <PageTemplate>
           <TimeSheet page={"timesheet"}/>
        </PageTemplate>
    );
};

export default TimeSheetPage;

