import React, { Component } from 'react';
// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
// Material helpers
import { withStyles } from '@material-ui/core';
// Material components
import IconButton from '@material-ui/core/IconButton';
// Shared components
import SearchBar from 'material-ui-search-bar';
import SearchIcon from '../../../../assets/search.svg';
import AddIcon from '../../../../assets/add.svg';
// Component styles
import styles from './styles';
import { connect } from 'react-redux';
import * as actions from '../../../../actions';
import { compose } from 'redux';
class CommonToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
    };
    this.search = this.search.bind(this);
  }
  search() {
    if (this.state.input !== '') {
      switch (this.props.page) {
        case 'client':
          this.props.clientSearch(
            { client: { term: this.state.input } },
            this.props.token
          );
          break;
        case 'project':
          this.props.projectSearch(
            { project: { term: this.state.input } },
            this.props.token
          );
          break;
        case 'movement_register':
          this.props.searchMovementRegister(
            { movement_register: { term: this.state.input } },
            this.props.token
          );
          break;

        default:
      }
    }
  }
  cancelSearch() {
    this.setState({
      input: '',
    });
    this.props.searchOver();
    localStorage.setItem(this.props.page + '_search', '');
  }
  testOnhange(props) {
    this.setState({
      input: props,
    });
    localStorage.setItem(this.props.page + '_search', props);

    if (props == '') {
      this.props.searchOver();
    }
  }
  renderButton(page, classes) {
    if (page === 'client') {
      return (
        <Link className='button--signup--intro button' to='/addclient'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'leave_management') {
      return (
        <Link className='button--signup--intro button' to='/apply_for_leave'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'settings') {
      return (
        <Link className='button--signup--intro button' to='/add_settings'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'certificate_register') {
      return (
        <Link
          className='button--signup--intro button'
          to='/addcertificateregister'
        >
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'sac_master') {
      return (
        <Link className='button--signup--intro button' to='/addsacmaster'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'spec_master') {
      return (
        <Link className='button--signup--intro button' to='/addspecmaster'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'outward_register') {
      return (
        <Link className='button--signup--intro button' to='/addoutwardregister'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'inward_register') {
      return (
        <Link className='button--signup--intro button' to='/addinwardregister'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'project_sub_class') {
      return (
        <Link className='button--signup--intro button' to='/addprojectsubclass'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'project_class') {
      return (
        <Link className='button--signup--intro button' to='/addprojectclass'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'issue_register') {
      return (
        <Link className='button--signup--intro button' to='/add_new_register'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'movement_register') {
      return (
        <div>
          <Link
            className='button--signup--intro button'
            to='/add_new_movement_register'
          >
            <img src={AddIcon} className={classes.icons} alt='add' />
          </Link>
        </div>
      );
    } else if (page === 'project') {
      return (
        <Link className='button--signup--intro button' to='/addproject'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'holiday_master') {
      return (
        <Link className='button--signup--intro button' to='/addholiday'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'attendence') {
      return (
        <Link className='button--signup--intro button' to='/addattendence'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'client_class') {
      return (
        <Link className='button--signup--intro button' to='/addclientclass'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'audit_document_register') {
      return (
        <Link className='button--signup--intro button' to='/addauditdocument'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'ope') {
      return (
        <Link className='button--signup--intro button' to='/addope'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'invoice') {
      return (
        <Link className='button--signup--intro button' to='/addinvoice'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'activity') {
      return (
        <Link className='button--signup--intro button' to='/addactivity'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'organisation') {
      return (
        <Link className='button--signup--intro button' to='/addorganisation'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else if (page === 'nature_of_expense') {
      return (
        <Link className='button--signup--intro button' to='/addnatureofexpense'>
          <IconButton color='primary' aria-label='add'>
            <img src={AddIcon} className={classes.icons} alt='add' />
          </IconButton>
        </Link>
      );
    } else {
    }
  }
  render() {
    const { classes, className, page } = this.props;

    let placeholder = 'searh projects';
    if (page == 'client') {
      placeholder = 'search clients';
    } else if (page == 'invoice') {
      placeholder = 'search inovices';
    } else if (page === 'ope') {
      placeholder = 'search ope';
    } else if (page === 'client') {
      placeholder = 'search clients';
    } else if (page === 'leave_management') {
      placeholder = 'search leaves';
    } else if (page === 'issue_register') {
      placeholder = 'search DSCs Issued';
    } else if (page === 'certificate_register') {
      placeholder = 'Search Certificate Register';
    } else if (page === 'project_class') {
      placeholder = 'search project class';
    } else if (page === 'project_sub_class') {
      placeholder = 'search project sub class';
    } else if (page === 'settings') {
      placeholder = 'search settings';
    } else {
    }
    const rootClassName = classNames(classes.root, className);

    return (
      <div className={classes.root}>
        <div className={classes.row}>
          <SearchBar
            className={classes.searchInput}
            value={localStorage.getItem(this.props.page + '_search')}
            onChange={this.testOnhange.bind(this)}
            onCancelSearch={this.cancelSearch.bind(this)}
            onRequestSearch={() => this.search}
          />
          {/** <SearchInput
            className={classes.searchInput}
            placeholder={placeholder}
            onChange={this.testOnhange.bind(this)}
        />**/}
          <IconButton onClick={this.search} color='primary'>
            <img src={SearchIcon} className={classes.icons} alt='add' />
          </IconButton>
          <span className={classes.spacer} />
          <span className={classes.spacer} />
          {this.renderButton(page, classes)}
        </div>
      </div>
    );
  }
}

CommonToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array,
};

CommonToolbar.defaultProps = {
  selectedUsers: [],
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
  };
}
export default compose(
  connect(mapStateToProps, actions),
  withStyles(styles)
)(CommonToolbar);
//export default withStyles(styles)(CommonToolbar);
