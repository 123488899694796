
import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from "../../actions";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Header from "../../components/Layout/Header/Header";
import {InputText,SelectInput,TextArea,validate,SavingDetailsModal} from "../../utils/formComponents";
import styles from '../modelstyles';
import { withStyles } from '@material-ui/core';

class AddClientClass extends Component {

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        clientClassAddFailed:true
    };
    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
}
componentDidUpdate(){
    setTimeout(() => this.setState({message:''}), 9000);
    if(!this.state.clientClassAddFailed){
         this.props.history.goBack();
    }
  }

closeModal(){
    this.setState({
        loading:this.props.loading,
        clientClassAddFailed:this.props.clientClassAddFailed
    })

}
async onsubmit(formData) {
    this.setState({
        loading:true
    })
    await this.props.addClientClass(formData,this.props.token);
    this.setState({
        message:this.props.message
    })
}
  render () {
const { handleSubmit,classes } = this.props;
    let state = this.state;
    return (
      <Container>
       <Header title={"add_client_class"}/>
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
      Add Client Class
      </Typography>
        <Row>
          <Col sm={4}>
          </Col>

        </Row>
        <form onSubmit={handleSubmit(this.onsubmit)} noValidate>
          <Row>
            <Col sm={8}>
              <span>Client Class</span>
              <Field
                        name="name"
                        label="Name *"
                        component={InputText}
                        type="text"
                        fullWidth
              />

            </Col>
           <Col sm={4}>


          </Col>
        </Row>

        <Row>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving Client Class Details"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>


          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>

      </Container>
    )
  }
}

function mapStateToProps(state) {
    return {
        token:state.auth.token,
        errorMessage:state.clientClass.errorMessage,
        message:state.clientClass.clientClassAddMessage,
        loading:state.clientClass.clientClassAddLoading,
        clientClassAddFailed:state.clientClass.clientClassAddFailed

    }
}

export default compose(withRouter,
  connect(mapStateToProps, actions),
  // withStyles(styles),
  reduxForm({ form: "AddClientClass",
validate }),
    withStyles(styles)
)(AddClientClass);
