import React from 'react';
import { Link } from 'react-router-dom';
// Externals
import Table from './table'
import CIcon from '@coreui/icons-react'
//testing out core-ui
import {
      CBadge,
      CCard,
      CCardBody,
      CCardHeader,
      CCol,
      CDataTable,
      CRow
} from '@coreui/react';
import AddIcon from "../../../assets/add.svg";

export default function CoreUITable (props){

    return(
        <>
            <CRow>
                <CCol sm="12">
                <CCard>
                <CCardHeader>
                    {props.title}
                </CCardHeader>
                <Table props={props}/>
                </CCard>
                </CCol>
            </CRow>
        </>
    )
}
