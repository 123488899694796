import React, { useState } from "react";
import { useGetSettings } from "../../hooks/settings-query";
import {
    useGetBookedTimeByDate,
    useTimesheetAdd,
} from "../../hooks/timesheet-query";
import { useGetUserProject } from "../../hooks/project-query/projects";
import { useClients } from "../../hooks/client-query/clients";
import ProgressBar from "react-bootstrap/ProgressBar";
import { fetchTimesheetCodes } from "../../actions/timsheetCodeAction";
import { Row, Col, Container, Badge, Table } from "react-bootstrap";
import Header from "../../components/Layout/Header/Header";
import { Formik, Field, Form, ErrorMessage, FieldArray, getIn } from "formik";
import {
    AlertComponent,
    getMinutes,
    padZeros,
} from "../../utils/formComponents";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import DatePicker from "react-datepicker";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
let initialValues = {
    project_data: [],
    codedata: [],
};
const selectStyles = {
    menu: (base) => ({
        ...base,
        zIndex: 100,
    }),
};
export default function AddNewTimeSheet(props) {
    const { data: projects } = useGetUserProject();
    const { data: clients } = useClients();
    const { data: setting } = useGetSettings("days_editable_timesheet");
    const [date, setDate] = useState(new Date());
    const [verifyDateChange, setVerifyDateChange] = useState(true);
    const [formValues, setFormValues] = React.useState({});
    const [showDetails, setShowDetails] = React.useState(false);
    const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
    const [tempDate, setTempDate] = useState(new Date());
    const [diff, setDiff] = React.useState("00:00");
    const [message, setMessage] = React.useState("");
    const { data: initialValues, isLoading } = useGetBookedTimeByDate(
        date,
        verifyDateChange
    ); //only refetch data if user accpeted the date change prompmt
    const {
        isLoading: timesheetAddLoading,
        mutate,
        data: addData,
        error,
    } = useTimesheetAdd();
    const history = useHistory();
    const state = useSelector((state) => state);
    const [saved, setSaved] = useState(false);
    const { user, token } = state.auth;
    const { timesheetCodes } = state.timesheetCode;
    console.log("test", initialValues, addData);
    const dispatch = useDispatch();
    const validateSelection = (value) => {
        let error;
        if (!value) {
            error = "Required to proceed";
        }
        return error;
    };
    React.useEffect(() => {
        setSaved(false);
    }, [initialValues]);
    const signingDateValidation = (expiry_date) => {
        if (moment(expiry_date).isBefore(moment().format("YYYY-MM-DD"))) {
            return expiry_date;
        } else {
            return moment().format("YYYY-MM-DD");
        }
    };
    const filterProjects = (values, index) => {
        //first filter and get current clients projects
        //second filter out any already selected projects
        try {
            let client = clients.find(
                (item) => item.id === values.project_data[index]["client_id"].id
            ).name;
            let selected_projects = values.project_data.map((item) => {
                if (item.project_id) {
                    return item.project_id.id;
                } else {
                    return;
                }
            });

            let res = projects.filter(
                (item) =>
                    item.client_name === client &&
                    !selected_projects.includes(item.id)
            );
            return res
        } catch (err) {
            console.log("error", err);
            return [];
        }
    };
    React.useEffect(() => {
        dispatch(fetchTimesheetCodes(token));
    }, []);
    React.useEffect(() => {
        console.log("error", timesheetAddLoading, addData, error);
        if (timesheetAddLoading) setMessage(`Saveing timesheet for ${date}`);
        if (addData && addData.message) setMessage(addData["message"]);
        if (error) setMessage(error);
    }, [timesheetAddLoading, addData, error]);

    React.useEffect(() => {
        if (!showDetails) {
            if (addData && addData["status"]) {
                setSaved(true);
                //history.goBack();
            }
        }
    }, [showDetails]);
    const compareDates = (d1, d2) => {
        let result = false;
        if (d1 && d2) {
            var parts_d1 = d1.split("-");
            var d1 = Number(parts_d1[2] + parts_d1[1] + parts_d1[0]);
            var parts_d2 = d2.split("-");
            var d2 = Number(parts_d2[2] + parts_d2[1] + parts_d2[0]);
            result = d1 > d2;
            //logic for 10 days backward not editable https://stackoverflow.com/a/2627493/5706413
            if (!result) {
                const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                const firstDate = new Date(
                    parts_d1[2],
                    parts_d1[1] - 1,
                    parts_d1[0]
                );
                const secondDate = new Date(
                    parts_d2[2],
                    parts_d2[1] - 1,
                    parts_d2[0]
                );
                const diffDays = Math.round(
                    Math.abs((firstDate - secondDate) / oneDay)
                );
                if (setting["value"]) {
                    result = diffDays > Number(setting["value"]);
                } else {
                    result = diffDays > 5;
                }
            }
        }
        return result;
    };
    function getTotal(values) {
        let total = 0;
        if (!values) return "00:00";
        Object.keys(values).map((key) => {
            console.log("key", key);
            values[key].map((item) => {
                console.log("item", item);
                total = total + getMinutes(item.allocated_time || "00:00");
            });
        });
        let hour = Math.floor(total / 60);
        hour = padZeros(hour);
        let min = total % 60;
        min = padZeros(min);
        //setTotal(total);
        let diff = user.working_time - total;
        let diff_h = Math.floor(Math.abs(diff) / 60);
        diff_h = padZeros(diff_h);
        let diff_min = Math.abs(diff) % 60;
        diff_min = padZeros(diff_min);
        setDiff(`${diff_h}:${diff_min}`);
        return `${hour}:${min}`;
    }
    function formatAndSubmit(formData) {
        let values = cloneDeep(formData);
        console.log("cehck initial values", initialValues);
        Object.keys(values).map((key) => {
            values[key].map((item) => {
                item["allocated_time"] = getMinutes(
                    item.allocated_time || "00:00"
                );
                item["user_id"] = user.id;
                item[
                    key === "project_data" ? "project_id" : "timesheet_code_id"
                ] =
                    item[
                        key === "project_data"
                            ? "project_id"
                            : "timesheet_code_id"
                    ]["id"];
                item["date"] = date;
            });
        });
        values = getDeletedRows({ ...values });
        mutate({ timesheet: values });
        setShowDetails(true);
    }
    function getDeletedRows(values) {
        //get deleted rows and make it zero
        Object.keys(initialValues).map((key) => {
            initialValues[key].map((item) => {
                if (
                    !values[key].find(
                        (newValue) =>
                            newValue[
                                key === "project_data"
                                    ? "project_id"
                                    : "timesheet_code_id"
                            ] ===
                            item[
                                key === "project_data"
                                    ? "project_id"
                                    : "timesheet_code_id"
                            ]["id"]
                    )
                ) {
                    let temp = {};
                    temp["date"] = date;
                    temp["allocated_time"] = 0;
                    temp[
                        key === "project_data"
                            ? "project_id"
                            : "timesheet_code_id"
                    ] =
                        item[
                            key === "project_data"
                                ? "project_id"
                                : "timesheet_code_id"
                        ]["id"];
                    temp["user_id"] = user.id;
                    values[key].push(temp);
                }
            });
        });
        return values;
    }
    function handleChange(d2) {
        //check if unsaved data exists, request if user is sure
        //check if new date within editable days
        let not_editable = compareDates(
            moment(d2).format("DD-MM-YYYY"),
            moment(new Date()).format("DD-MM-YYYY")
        );
        if (!not_editable) {
            setTempDate(d2);
            setShowConfirmationAlert(true);
        }
    }
    if (isLoading)
        return <ProgressBar striped variant="success" animated now={40} />;

    return (
        <div style={{padding: '3%'}}>
            <Header title="new_timesheet" />
            <h1 style={{ marginLeft: "20%" }}>
                {" "}
                <Badge variant="dark">Book your time</Badge>{" "}
            </h1>
            <Row>
                <Col sm={4}><DatePicker selected={date} onChange={handleChange} inline /></Col>
            <Col sm={8}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values) => {
                    formatAndSubmit({ ...values });
                }}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <Row style={{ width: "70%", marginTop: "3%" }}>
                            <Col sm={4}>
                                <h5>Projects</h5>
                            </Col>{" "}
                        </Row>

                        <Table
                            responsive
                            style={{ width: "70%", marginTop: "2%" }}
                        >
                            <thead>
                                <tr>
                                    <th style={{ width: "30%" }}>Client</th>
                                    <th>Project</th>
                                    <th>HH:MM</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <FieldArray name="project_data">
                                {({ insert, remove, push }) => (
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <td sm={5}>
                                                <Button
                                                    onClick={() => {
                                                        push({
                                                            project_id: "",
                                                            user_id: user.id,
                                                            date: date,
                                                            allocated_time: "",
                                                        });
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                            </td>
                                        </tr>

                                        {values &&
                                            values.project_data.length > 0 &&
                                            values.project_data.map(
                                                (details, index) => (
                                                    <tr
                                                        style={{
                                                            marginTop: "2%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <td sm={5}>
                                                            <Field
                                                                name={`project_data.${index}.client_id`}
                                                                options={
                                                                    clients
                                                                }
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                component={
                                                                    Select
                                                                }
                                                                value={
                                                                    values
                                                                        .project_data[
                                                                        index
                                                                    ].client_id
                                                                        ? values
                                                                              .project_data[
                                                                              index
                                                                          ]
                                                                              .client_id
                                                                        : undefined
                                                                }
                                                                validate={
                                                                    validateSelection
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setFieldValue(
                                                                        `project_data.${index}.project_id`,
                                                                        null
                                                                    );
                                                                    setFieldValue(
                                                                        `project_data.${index}.client_id`,
                                                                        event
                                                                    );
                                                                }}
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option.name
                                                                }
                                                                getOptionValue={(
                                                                    option
                                                                ) => option.id}
                                                                styles={
                                                                    selectStyles
                                                                }
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        "2%",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                {getIn(
                                                                    errors,
                                                                    `project_data.${index}.client_id`
                                                                )
                                                                    ? "Required to proceed"
                                                                    : ""}
                                                            </div>
                                                        </td>
                                                        <td sm={5} style={{width: '30%'}}>
                                                            <Field
                                                                name={`project_data.${index}.project_id`}
                                                                options={filterProjects(
                                                                    values,
                                                                    index
                                                                )}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                component={
                                                                    Select
                                                                }
                                                                value={
                                                                    values
                                                                        .project_data[
                                                                        index
                                                                    ].project_id
                                                                        ? values
                                                                              .project_data[
                                                                              index
                                                                          ]
                                                                              .project_id
                                                                        : undefined
                                                                }
                                                                validate={
                                                                    validateSelection
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setFieldValue(
                                                                        `project_data.${index}.project_id`,
                                                                        event
                                                                    );
                                                                }}
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option.name
                                                                }
                                                                getOptionValue={(
                                                                    option
                                                                ) => option.id}
                                                                styles={
                                                                    selectStyles
                                                                }
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        "2%",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                {getIn(
                                                                    errors,
                                                                    `project_data.${index}.project_id`
                                                                )
                                                                    ? "Required to proceed"
                                                                    : ""}
                                                            </div>
                                                        </td>
                                                        <td sm={5}>
                                                            <Field
                                                                name={`project_data.${index}.allocated_time`}
                                                                component={
                                                                    MaskedInput
                                                                }
                                                                placeholder="HH:MM"
                                                                guide={false}
                                                                className="form-control"
                                                                disabled={false}
                                                                mask={[
                                                                    /[0-2]/,
                                                                    /[0-9]/,
                                                                    ":",
                                                                    /[0-5]/,
                                                                    /[0-9]/,
                                                                ]}
                                                                pipe={createAutoCorrectedDatePipe(
                                                                    "HH:MM"
                                                                )}
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setFieldValue(
                                                                        `project_data.${index}.allocated_time`,
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                                value={
                                                                    values
                                                                        .project_data[
                                                                        index
                                                                    ]
                                                                        .allocated_time
                                                                        ? values
                                                                              .project_data[
                                                                              index
                                                                          ]
                                                                              .allocated_time
                                                                        : "00:00"
                                                                }
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        "2%",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                {getIn(
                                                                    errors,
                                                                    `project_data.${index}.allocated_time`
                                                                )
                                                                    ? "Required to proceed"
                                                                    : ""}
                                                            </div>
                                                        </td>
                                                        <td sm={5}>
                                                            <Button
                                                                onClick={() =>
                                                                    remove(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <ClearIcon />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                )}
                            </FieldArray>
                        </Table>

                        <Row style={{ width: "70%", marginTop: "3%" }}>
                            <Col sm={4}>
                                <h5>Others</h5>
                            </Col>{" "}
                        </Row>
                        <Table
                            responsive
                            style={{ width: "70%", marginTop: "2%" }}
                        >
                            <thead>
                                <tr>
                                    <th style={{ width: "30%" }}>
                                        Timesheet code
                                    </th>
                                    <th></th>
                                    <th>HH:MM</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <FieldArray name="codedata">
                                {({ insert, remove, push }) => (
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <td sm={5}>
                                                <Button
                                                    onClick={() => {
                                                        push({
                                                            timesheet_code_id:
                                                                "",
                                                            user_id: user.id,
                                                            date: date,
                                                            allocated_time: "",
                                                        });
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                            </td>
                                        </tr>

                                        {values &&
                                            values.codedata.length > 0 &&
                                            values.codedata.map(
                                                (details, index) => (
                                                    <tr
                                                        style={{
                                                            marginTop: "2%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <td sm={5}>
                                                            <Field
                                                                name={`codedata.${index}.timesheet_code_id`}
                                                                options={timesheetCodes.filter(
                                                                    (item) =>
                                                                        (!values.codedata
                                                                            .map(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    item
                                                                                        .timesheet_code_id
                                                                                        .id
                                                                            )
                                                                            .includes(
                                                                                item.id
                                                                            ) && item.name !== "Leave")
                                                                )}
                                                                className="basic-multi-select"
                                                            classNamePrefix="select"
isDisabled={values.codedata[index].timesheet_code_id.name === "Leave"}
                                                                component={
                                                                    Select
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setFieldValue(
                                                                        `codedata.${index}.timesheet_code_id`,
                                                                        event
                                                                    );
                                                                }}
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option.name
                                                                }
                                                                getOptionValue={(
                                                                    option
                                                                ) => option.id}
                                                                styles={
                                                                    selectStyles
                                                            }

                                                                value={
                                                                    values
                                                                        .codedata[
                                                                        index
                                                                    ].timesheet_code_id
                                                                        ? values
                                                                              .codedata[
                                                                              index
                                                                          ]
                                                                              .timesheet_code_id
                                                                        : undefined
                                                                }
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        "2%",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                {getIn(
                                                                    errors,
                                                                    `codedata.${index}.timesheet_code_id`
                                                                )
                                                                    ? "Required to proceed"
                                                                    : ""}
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                        <td sm={5}>
                                                            <Field
                                                                name={`codedata.${index}.allocated_time`}
                                                                component={
                                                                    MaskedInput
                                                                }
                                                                placeholder="HH:MM"
                                                                guide={false}
                                                                className="form-control"
                                                                disabled={values.codedata[index].timesheet_code_id.name === "Leave"}
                                                                mask={[
                                                                    /[0-2]/,
                                                                    /[0-9]/,
                                                                    ":",
                                                                    /[0-5]/,
                                                                    /[0-9]/,
                                                                ]}
                                                                pipe={createAutoCorrectedDatePipe(
                                                                    "HH:MM"
                                                                )}
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setFieldValue(
                                                                        `codedata.${index}.allocated_time`,
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                            }

                                                                value={
                                                                    values
                                                                        .codedata[
                                                                        index
                                                                    ]
                                                                        .allocated_time
                                                                        ? values
                                                                              .codedata[
                                                                              index
                                                                          ]
                                                                              .allocated_time
                                                                        : "00:00"
                                                                }
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        "2%",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                {getIn(
                                                                    errors,
                                                                    `codedata.${index}.allocated_time`
                                                                )
                                                                    ? "Required to proceed"
                                                                    : ""}
                                                            </div>
                                                        </td>
                                                        <td sm={5}>
                                                            <Button
                                                                onClick={() =>
                                                                    remove(
                                                                        index
                                                                    )
                                                                }
                                                                disabled={values.codedata[index].timesheet_code_id.name === "Leave"}
                                                            >
                                                                <ClearIcon />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                )}
                            </FieldArray>
                        </Table>
                        <Row>
                            <Col>Total</Col>
                            <Col>{getTotal(values)}</Col>
                        </Row>
                        <Row>
                            <Col>Excessive/shortage [total working hours - total booked]</Col>
                            <Col>{diff}</Col>
                        </Row>
                        <Button
                            type="submit"
                            style={{ marginLeft: "89%" }}
                            variant="contained"
                            color="primary"
                            disabled={showDetails}
                        >
                            Save
                        </Button>
                    </Form>
                )}
            </Formik>
            </Col></Row>
            <AlertComponent
                title="Saving"
                description={message}
                saveText={"OK"}
                open={showDetails}
                proceed={() => setShowDetails(false)}
            />
            <AlertComponent
                title="Switch date?"
                description={
                    "Any unsaved data would be lost! Are you sure you want to continue?"
                }
                saveText={"Yes"}
                open={showConfirmationAlert}
                proceed={() => {
                    setDate(tempDate);
                    setVerifyDateChange(true);
                    setShowConfirmationAlert(false);
                }}
                cancelText="No"
                handleClose={() => {
                    setVerifyDateChange(false);
                    setShowConfirmationAlert(false);
                }}
            />
        </div>
    );
}
