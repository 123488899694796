import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CommonList from '../views/CommonTableView';
const AttendencePage = () => {
    return (
        <PageTemplate>
           <CommonList
            page={"attendence"}
        />
        </PageTemplate>
    );
};

export default AttendencePage;

