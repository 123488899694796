import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_PROJECT_ACTIVITY_FETCH,
    START_ADD_PROJECT_ACTIVITY,
    PROJECT_ACTIVITY_FETCH_FAILED,
    PROJECT_ACTIVITY_FETCH_SUCCESS,
    ADD_PROJECT_ACTIVITY_FAILED,
    ADD_PROJECT_ACTIVITY_SUCCESS,
    START_PROJECT_ACTIVITY_DUE_DATE_UPDATE,
    PROJECT_ACTIVITY_DUE_DATE_UPDATE_SUCCESS,
    PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_FAILED,
    START_PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE,
    PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_SUCCESS,
    PROJECT_ACTIVITY_DUE_DATE_UPDATE_FAILED,
} from "./types";

export const fetchProjectActivity = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_PROJECT_ACTIVITY_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/project_activity/${data}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: PROJECT_ACTIVITY_FETCH_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: PROJECT_ACTIVITY_FETCH_FAILED,
                    payload: res.data,
                });
            }
        } catch (error) {
            dispatch({
                type: PROJECT_ACTIVITY_FETCH_FAILED,
                payload: error,
            });
        }
    };
};

export const addProjectActivity = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_ADD_PROJECT_ACTIVITY,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/project_activity`,
                { project_activity: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.status === 200) {
                dispatch({
                    type: ADD_PROJECT_ACTIVITY_SUCCESS,
                    payload: "Sucessfully added project activity",
                });
            } else {
                dispatch({
                    type: ADD_PROJECT_ACTIVITY_FAILED,
                    payload: res.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ADD_PROJECT_ACTIVITY_FAILED,
                payload: error.message,
            });
        }
    };
};

export const updateActivityStatus = (id, data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/update_activity_status/${id}`,
                { activity_status: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_SUCCESS,
                    payload: {
                        message: res.data["message"],
                        data: res.data["project_activity"],
                    },
                });
            } else {
                dispatch({
                    type: PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_FAILED,
                payload: error.message,
            });
        }
    };
};

export const updateDueDate = (id, data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_PROJECT_ACTIVITY_DUE_DATE_UPDATE,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/update_due_date/${id}`,
                { due_date: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: PROJECT_ACTIVITY_DUE_DATE_UPDATE_SUCCESS,
                    payload: {
                        message: res.data["message"],
                        data: res.data["project_activity"],
                    },
                });
            } else {
                dispatch({
                    type: PROJECT_ACTIVITY_DUE_DATE_UPDATE_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: PROJECT_ACTIVITY_DUE_DATE_UPDATE_FAILED,
                payload: error.message,
            });
        }
    };
};
