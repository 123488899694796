export const AUTH_SIGNUP = "AUTH_SIGNUP";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const SET_USER = "SET_USER";
export const FETCH_TOKEN_FROM_LOCAL = "FETCH_TOKEN_FROM_LOCAL";
export const FETCHING_USER = "FETCHING_USER";
export const FETCHING_USER_DONE = "FETCHING_USER_DONE";
export const FETCHING_USER_FAILED = "FETCHING_USER_FAILED";
export const START_LOGIN = "START_LOGIN";
export const START_SIGNUP = "START_SIGNUP";
export const VERIFY_PASSKEY = "VERIFY_PASSKEY";
export const VERIFY_PASSKEY_LOADING = "VERIFY_PASSKEY_LOADING";
export const VERIFY_PASSKEY_FAILED = "VERIFY_PASSKEY_FAILED";
export const GET_PASSKEY = "GET_PASSKEY";

export const START_CLIENT_UPDATE = "START_CLIENT_UPDATE";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
export const CLIENT_UPDATE_FAILED = "CLIENT_UPDATE_FAILED";

export const START_CLIENT_DETAILS_FETCH = "START_CLIENT_DETAILS_FETCH";
export const CLIENT_DETAILS_FETCH_SUCCESS = "CLIENT_DETAILS_FETCH_SUCCESS";
export const CLIENT_DETAILS_FETCH_FAILED = "CLIENT_DETAILS_FETCH_FAILED";

//fetch employee list for dashborad listing

export const START_EMPLOYEE_LIST_FETCH = "START_EMPLOYEE_LIST_FETCH";
export const EMPLOYEE_LIST_FETCH_SUCCESS = "EMPLOYEE_LIST_FETCH_SUCCESS";
export const EMPLOYEE_LIST_FETCH_FAILED = "EMPLOYEE_LIST_FETCH_FAILED";
//fetch drop down employee list
export const START_EMPLOYEE_DROP_DOWN_LIST_FETCH =
  "START_EMPLOYEE_DROP_DOWN_LIST_FETCH";
export const EMPLOYEE_DROP_DOWN_LIST_FETCH_SUCCESS =
  "EMPLOYEE_DROP_DOWN_LIST_FETCH_SUCCES";
export const EMPLOYEE_DROP_DOWN_LIST_FETCH_FAILED =
  "EMPLOYEE_DROP_DOWN_LIST_FETCH_FAILED";
//fetch for edit maybe
export const START_EMPLOYEE_FETCH = "START_EMPLOYEE_FETCH";
export const EMPLOYEE_FETCH_SUCCESS = "EMPLOYEE_FETCH_SUCCESS";
export const EMPLOYEE_FETCH_FAILED = "EMPLOYEE_FETCH_FAILED";

//fetch lead partner for drop down
export const START_LEAD_PARTNER_FETCH = "START_LEAD_PARTNER_FETCH";
export const LEAD_PARTNER_FETCH_SUCCESS = "LEAD_PARTNER_FETCH_SUCCESS";
export const LEAD_PARTNER_FETCH_FAILED = "LEAD_PARTNER_FETCH_FAILED";

//FETCH CLIENT includes all details

export const START_CLIENT_FETCH = "START_CLIENT_FETCH";
export const CLIENT_FETCH_SUCCESS = "CLIENT_FETCH_SUCCESS";
export const CLIENT_FETCH_FAILED = "CLIENT_FETCH_FAILED";

//fetch employee list

// CLIENT CLASS
// fecth client class list for dropdown
export const START_CLIENT_CLASS_FETCH = "START_CLIENT_CLASS_FETCH";
export const CLIENT_CLASS_FETCH_SUCCESS = "CLIENT_CLASS_FETCH_SUCCESS";
export const CLIENT_CLASS_FETCH_FAILED = "CLIENT_CLASS_FETCH_FAILED";
//update
// delete

//PROJECT CATEGORYIES
//fetch project categories list for drop down
export const START_PROJECT_CATEGORY_FETCH = "START_PROJECT_CATEGORY_FETCH";
export const PROJECT_CATEGORY_FETCH_SUCCESS = "PROJECT_CATEGORY_FETCH_SUCCESS";
export const PROJECT_CATEGORY_FETCH_FAILED = "PROJECT_CATEGORY_FETCH_FAILED";
//update
// delete

// PROJECTS
// fetch name,id
export const START_PROJECT_FETCH = "START_PROJECT_FETCH";
export const PROJECT_FETCH_SUCCESS = "PROJECT_FETCH_SUCCESS";
export const PROJECT_FETCH_FAILED = "PROJECT_FETCH_FAILED";

export const START_PROJECT_FETCH_BY_ID = "START_PROJECT_FETCH_BY_ID";
export const PROJECT_FETCH_BY_ID_SUCCESS = "PROJECT_FETCH_BY_ID_SUCCESS";
export const PROJECT_FETCH_BY_ID_FAILED = "PROJECT_FETCH_BY_ID_FAILED";

export const START_PROJECT_UPDATE = "START_PROJECT_UPDATE";
export const PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS";
export const PROJECT_UPDATE_FAILED = "PROJECT_UPDATE_FAILED";

//invoices list

export const START_INVOICES_FETCH = "START_INVOICES_FETCH";
export const INVOICES_FETCH_SUCCESS = "INVOICES_FETCH_SUCCESS";
export const INVOICES_FETCH_FAILED = "INVOICES_FETCH_FAILED";

//invoice fech details by id
export const START_INVOICE_FETCH_BY_ID = "START_INVOICE_FETCH_BY_ID";
export const INVOICE_FETCH_BY_ID_SUCCESS = "INVOICE_FETCH_BY_ID_SUCCESS";
export const INVOICE_FETCH_BY_ID_FAILED = "INVOICE_FETCH_BY_ID_FAILED";
//invoice update
export const START_INVOICE_UPDATE = "START_INVOICE_UPDATE";
export const INVOICE_UPDATE_FAILED = "INVOICE_UPDATE_FAILED";
export const INVOICE_UPDATE_SUCCESS = "INVOICE_UPDATE_SUCCESS";
//fetch projects with all details

export const START_PROJECT_FETCH_DETAILS_LIST =
  "START_PROJECT_FETCH_DETAILS_LIST";
export const PROJECT_FETCH_SUCCESS_DETAILS_LIST =
  "PROJECT_FETCH_SUCCESS_DETAILS_LIST";
export const PROJECT_FETCH_FAILED_DETAILS_LIST =
  "PROJECT_FETCH_FAILED_DETAILS_LIST";
// delete
// update

// CLIENTS-list basic
// fetch
export const START_CLIENT_LIST_FETCH = "START_CLIENT_LIST_FETCH";
export const CLIENT_LIST_FETCH_SUCCESS = "CLIENT_LIST_FETCH_SUCCESS";
export const CLIENT_LIST_FETCH_FAILURE = "CLIENT_LIST_FETCH_FAILURE";
// delete
// update
// INVOICES
// fetch
// delete
// update
// OPE
// fetch send project ids
export const START_OPE_FETCH = "START_OPE_FETCH";
export const OPE_FETCH_SUCCESS = "OPE_FETCH_SUCCESS";
export const OPE_FETCH_FAILURE = "OPE_FETCH_FAILURE";

export const START_OPE_ADD = "START_OPE_ADD";
export const OPE_ADD_SUCCESS = "OPE_ADD_SUCCESS";
export const OPE_ADD_FAILURE = "OPE_ADD_FAILURE";

export const START_OPE_AMOUNT_FETCH = "START_OPE_AMOUNT_FETCH";
export const OPE_AMOUNT_FETCH_SUCCESS = "OPE_AMOUNT_FETCH_SUCCESS";
export const OPE_AMOUNT_FETCH_FAILED = "OPE_AMOUNT_FETCH_FAILED";

export const UPDATED_FORM = "UPDATED_FORM";

export const START_CLIENT_CLASS_ADD = "START_CLIENT_CLASS_ADD";
export const CLIENT_CLASS_ADD_SUCCESS = "CLIENT_CLASS_ADD_SUCCESS";
export const CLIENT_CLASS_ADD_FAILURE = "CLIENT_CLASS_ADD_FAILURE";

export const START_PROJECT_CLASS_ADD = "START_PROJECT_CLASS_ADD";
export const PROJECT_CLASS_ADD_SUCCESS = "PROJECT_CLASS_ADD_SUCCESS";
export const PROJECT_CLASS_ADD_FAILURE = "PROJECT_CLASS_ADD_FAILURE";

export const START_CLIENT_ADD = "START_CLIENT_ADD";
export const CLIENT_ADD_SUCCESS = "CLIENT_ADD_SUCCESS";
export const CLIENT_ADD_FAILURE = "CLIENT_ADD_FAILURE";

export const START_INVOICE_ADD = "START_INVOICE_ADD";
export const INVOICE_ADD_SUCCESS = "INVOICE_ADD_SUCCESS";
export const INVOICE_ADD_FAILURE = "INVOICE_ADD_FAILURE";

export const START_CALCULATE_INVOICE_TOTAL = "START_CALCULATE_INVOICE_TOTAL";
export const CALCULATE_INVOICE_TOTAL_DONE = "CALCULATE_INVOICE_TOTAL_DONE";

export const START_PROJECT_ADD = "START_PROJECT_ADD";
export const PROJECT_ADD_SUCCESS = "PROJECT_ADD_SUCCESS";
export const PROJECT_ADD_FAILURE = "PROJECT_ADD_FAILURE";

export const START_RESPECTIVE_OPE_FETCH = "START_RESPECTIVE_OPE_FETCH";
export const RESPECTIVE_OPE_FETCH_DONE = "RESPECTIVE_OPE_FETCH_DONE";
export const RESPECTIVE_OPE_FETCH_FAILED = "RESPECTIVE_OPE_FETCH_FAILED";

export const START_PROJECT_SEARCH = "START_PROJECT_SEARCH";
export const PROJECT_SEARCH_DONE = "PROJECT_SEARCH_DONE";
export const PROJECT_SEARCH_FAILED = "PROJECT_SEARCH_FAILED";

export const START_CLIENT_SEARCH = "START_CLIENT_SEARCH";
export const CLIENT_SEARCH_DONE = "CLIENT_SEARCH_DONE";
export const CLIENT_SEARCH_FAILED = "CLIENT_SEARCH_FAILED";

//export const START_PROJECT_SEARCH = "START_PROJECT_SEARCH";
//export const PROJECT_SEARCH_DONE = "PROJECT_SEARCH_DONE";
//export const PROJECT_SEARCH_FAILED = "PROJECT_SEARCH_FAILED";

export const SEARCH_OVER = "SEARCH_OVER";

export const START_ORGANISATION_ADD = "START_ORGANISATION_ADD";
export const DONE_ORGANISATION_ADD = "DONE_ORGANISATION_ADD";
export const FAILED_ORGANISATION_ADD = "FAILED_ORGANISATION_ADD";

export const START_ORGANISATION_FETCH = "START_ORGANISATION_FETCH";
export const DONE_ORGANISATION_FETCH = "DONE_ORGANISATION_FETCH";
export const FAILED_ORGANISATION_FETCH = "FAILED_ORGANISATION_FETCH";

export const START_NATURE_OF_EXPENSE_ADD = "START_NATURE_OF_EXPENSE_ADD";
export const DONE_NATURE_OF_EXPENSE_ADD = "DONE_NATURE_OF_EXPENSE_ADD";
export const FAILED_NATURE_OF_EXPENSE_ADD = "FAILED_NATURE_OF_EXPENSE_ADD";

export const START_NATURE_OF_EXPENSE_FETCH = "START_NATURE_OF_EXPENSE_FETCH";
export const DONE_NATURE_OF_EXPENSE_FETCH = "DONE_NATURE_OF_EXPENSE_FETCH";
export const FAILED_NATURE_OF_EXPENSE_FETCH = "FAILED_NATURE_OF_EXPENSE_FETCH";

export const START_TIMESHEET_FETCH = "START_TIMESHEET_FETCH";
export const TIMESHEET_FETCH_SUCCESS = "TIMESHEET_FETCH_SUCCESS";
export const TIMESHEET_FETCH_FAILED = "TIMESHEET_FETCH_FAILED";

export const START_TIMESHEET_ADD = "START_TIMESHEET_ADD";
export const TIMESHEET_ADDED_SUCCESSFULLY = "TIMESHEET_ADDED_SUCCESSFULLY";
export const ADDING_TIMESHEET_FAILED = "ADDING_TIMESHEET_FAILED";

export const START_FETCHING_ISSUE_REGISTERS_LIST =
  "START_FETCHING_ISSUE_REGISTERS_LIST";
export const FETCHING_ISSUE_REGISTERS_LIST_DONE =
  "FETCHING_ISSUE_REGISTERS_LIST_DONE";
export const FETCHING_ISSUE_REGISTERS_LIST_FAILED =
  "FETCHING_ISSUE_REGISTERS_LIST_FAILED";

export const START_ADDING_NEW_REGISTER = "START_ADDING_NEW_REGISTER";
export const NEW_REGISTER_ADDED_SUCCESSFULLY =
  "NEW_REGISTER_ADDED_SUCCESSFULLY";
export const FAILED_ADDING_NEW_REGISTER = "FAILED_ADDING_NEW_REGISTER";

export const START_REAUTH = "START_REAUTH";
export const DONE_REAUTH = "DONE_REAUTH";
export const FAILED_REAUTH = "FAILED_REAUTH";

export const START_APPLYING_FOR_LEAVE = "START_APPLYING_FOR_LEAVE";
export const APPLYING_FOR_LEAVE_SUCCESS = "APPLYING_FOR_LEAVE_SUCCESS";
export const APPLYING_FOR_LEAVE_FAILED = "APPLYING_FOR_LEAVE_FAILED";

//for fetching leave application by id
export const START_FETCH_LEAVE_APPLICATION = "START_FETCH_LEAVE_APPLICATION";
export const LEAVE_APPLICATION_FETCH_SUCCESS =
  "LEAVE_APPLICATION_FETCH_SUCCESS";
export const LEAVE_APPLICATION_FETCH_FAILED = "LEAVE_APPLICATION_FETCH_FAILED";
//fo fetching leave application list
export const START_LEAVE_APPLICATION_LIST_FETCH =
  "START_LEAVE_APPLICATION_LIST_FETCH";
export const LEAVE_APPLICATION_LIST_FETCH_SUCCESS =
  "LEAVE_APPLICATION_LIST_FETCH_SUCCESS";
export const LEAVE_APPLICATION_LIST_FETCH_FAILED =
  "LEAVE_APPLICATION_LIST_FETCH_FAILED";
//for movement reister fetch

export const START_MOVEMENT_REGISTER_LIST_FETCH =
  "START_MOVEMENT_REGISTER_LIST_FETCH";
export const MOVEMENT_REGISTER_LIST_FETCH_SUCCESS =
  "MOVEMENT_REGISTER_LIST_FETCH_SUCCESS";
export const MOVEMENT_REGISTER_LIST_FETCH_FAILED =
  "MOVEMENT_REGISTER_LIST_FETCH_FAILED";

export const START_MOVEMENT_REGISTER_STATUS_CHANGE =
  "START_MOVEMENT_REGISTER_STATUS_CHANGE";
export const MOVEMENT_REGISTER_STATUS_CHANGE_SUCCESS =
  "MOVEMENT_REGISTER_STATUS_CHANGE_SUCCESS";
export const MOVEMENT_REGISTER_STATUS_CHANGE_FAILED =
  "MOVEMENT_REGISTER_STATUS_CHANGE_FAILED";

export const START_MOVEMENT_REGISTER_FETCH_BY_ID =
  "START_MOVEMENT_REGISTER_FETCH_BY_ID";
export const MOVEMENT_REGISTER_FETCH_BY_ID_DONE =
  "MOVEMENT_REGISTER_FETCH_BY_ID_DONE";
export const MOVEMENT_REGISTER_FETCH_BY_ID_FAILED =
  "MOVEMENT_REGISTER_FETCH_BY_ID_FAILED";

export const START_ADD_NEW_MOVEMENT_REGISTER =
  "START_ADD_NEW_MOVEMENT_REGISTER";
export const ADDING_NEW_MOVEMENT_REGISTER_FAILED =
  "ADDING_NEW_MOVEMENT_REGISTER_FAILED";
export const ADDING_NEW_MOVEMENT_REGISTER_SUCCESS =
  "ADDING_NEW_MOVEMENT_REGISTER_SUCCESS";

export const START_MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH =
  "START_MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH";
export const MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_SUCCESS =
  "MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_SUCCESS";
export const MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_FAILED =
  "MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_FAILED";

export const START_CERTIFICATE_REGISTER_ADD = "START_CERTIFICATE_REGISTER_ADD";
export const CERTIFICATE_REGISTER_ADD_DONE = "CERTIFICATE_REGISTER_ADD_DONE";
export const CERTIFICATE_REGISTER_ADD_FAILED =
  "CERTIFICATE_REGISTER_ADD_FAILED";

export const START_CERTIFICATE_REGISTER_EDIT =
  "START_CERTIFICATE_REGISTER_EDIT";
export const CERTIFICATE_REGISTER_EDIT_FAILED =
  "CERTIFICATE_REGISTER_EDIT_FAILED";
export const CERTIFICATE_REGISTER_EDIT_SUCCESS =
  "CERTIFICATE_REGISTER_EDIT_SUCCESS";

export const START_CERTIFICATE_REGISTER_LIST_FETCH =
  "START_CERTIFICATE_REGISTER_LIST_FETCH";
export const CERTIFICATE_REGISTER_LIST_FECH_DONE =
  "CERTIFICATE_REGISTER_LIST_FECH_DONE";
export const CERTIFICATE_REGISTER_LIST_FECH_FAILED =
  "CERTIFICATE_REGISTER_LIST_FECH_FAILED";

//general activity
export const START_GENERAL_ACTIVITY_ADD = "START_GENERAL_ACTIVITY_ADD";
export const GENERAL_ACTIVITY_ADD_SUCCESS = "GENERAL_ACTIVITY_ADD_SUCCESS";
export const GENERAL_ACTIVITY_ADD_FAILED = "GENERAL_ACTIVITY_ADD_FAILED";

export const START_GENERAL_ACTIVITY_FETCH = "START_GENERAL_ACTIVITY_FETCH";
export const GENERAL_ACTIVITY_FECH_SUCCESS = "GENERAL_ACTIVITY_FECH_SUCCESS";
export const GENERAL_ACTIVITY_FETCH_FAILED = "GENERAL_ACTIVITY_FETCH_FAILED";

//project activity
export const START_PROJECT_ACTIVITY_FETCH = "START_PROJECT_ACTIVITY_FETCH";
export const PROJECT_ACTIVITY_FETCH_FAILED = "PROJECT_ACTIVITY_FETCH_FAILED";
export const PROJECT_ACTIVITY_FETCH_SUCCESS = "PROJECT_ACTIVITY_FETCH_SUCCESS";

export const START_ADD_PROJECT_ACTIVITY = "START_ADD_PROJECT_ACTIVITY";
export const ADD_PROJECT_ACTIVITY_FAILED = "ADD_PROJECT_ACTIVITY_FAILED";
export const ADD_PROJECT_ACTIVITY_SUCCESS = "ADD_PROJECT_ACTIVITY_SUCCESS";

export const START_PROJECT_ACTIVITY_DUE_DATE_UPDATE =
  "START_PROJECT_ACTIVITY_DUE_DATE_UPDATE";
export const PROJECT_ACTIVITY_DUE_DATE_UPDATE_SUCCESS =
  "PROJECT_ACTIVITY_DUE_DATE_UPDATE_SUCCESS";
export const PROJECT_ACTIVITY_DUE_DATE_UPDATE_FAILED =
  "PROJECT_ACTIVITY_DUE_DATE_UPDATE_FAILED";

export const START_PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE =
  "START_PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE";
export const PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_SUCCESS =
  "PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_SUCCESS";
export const PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_FAILED =
  "PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_FAILED";
//diary action
export const START_PROJECT_DIARY_FETCH = "START_PROJECT_DIARY_FETCH";
export const PROJECT_DIARY_FETCH_SUCCESS = "PROJECT_DIARY_FETCH_SUCCESS";
export const PROJECT_DIARY_FETCH_FAILED = "PROJECT_DIARY_FETCH_FAILED";

// project speicifc
export const PROJECT_SPECIFIC_FETCH = "PROJECT_SPECIFIC_FETCH";
export const PROJECT_SPECIFIC_SUCCESS = "PROJECT_SPECIFIC_SUCCESS";
export const PROJECT_SPECIFIC_FAILED = "PROJECT_SPECIFIC_FAILED";

export const START_PROJECT_DIARY_ADD = "START_PROJECT_DIARY_ADD";
export const PROJECT_DIARY_ADD_SUCCESS = "PROJECT_DIARY_ADD_SUCCESS";
export const PROJECT_DIARY_ADD_FAILED = "PROJECT_DIARY_ADD_FAILED";

export const NEW_COMMENT_RECEIVED = "NEW_COMMENT_RECEIVED";
export const NEW_COMMENT_MERGE_SUCCESS = "NEW_COMMENT_MERGE_SUCCESS";
export const NEW_COMMENT_MERGE_FAILED = "NEW_COMMENT_MERGE_FAILED";

export const CONNECTED_TO_CHANNEL = "CONNECTED_TO_CHANNEL";
export const DISCONNECTED_CHANNEL = "DISCONNECTED_CHANNEL";

export const CLOSE_NEW_COMMENT_ALERT = "CLOSE_NEW_COMMENT_ALERT";

export const SET_PROJECT_DASHBOARD_DATA = "SET_PROJECT_DASHBOARD_DATA";

export const START_ADD_AUDIT_DOCUEMNT_REGISTER =
  "START_ADD_AUDIT_DOCUEMNT_REGISTER";
export const ADD_AUDIT_DOCUMENT_REGISTER_FAILED =
  "ADD_AUDIT_DOCUMENT_REGISTER_FAILED";
export const ADD_AUDIT_DOCUMENT_REGISTER_SUCCESS =
  "ADD_AUDIT_DOCUMENT_REGISTER_SUCCESS";

export const FETCH_AUDIT_DOCUMENT_REGISTER_START =
  "FETCH_AUDIT_DOCUMENT_REGISTER_START";
export const FETCH_AUDIT_DOCUMENT_REGISTER_SUCCESS =
  "FETCH_AUDIT_DOCUMENT_REGISTER_SUCCESS";
export const FETCH_AUDIT_DOCUMENT_REGISTER_FAILED =
  "FETCH_AUDIT_DOCUMENT_REGISTER_FAILED";

export const FETCH_CLIENT_PROJECT_START = "FETCH_CLIENT_PROJECT_START";
export const FETCH_CLIENT_PROJECT_SUCCESS = "FETCH_CLIENT_PROJECT_SUCCESS";
export const FETCH_CLIENT_PROJECT_FAILED = "FETCH_CLIENT_PROJECT_FAILED";

export const FETCH_INWARD_REGISTER_START = "FETCH_INWARD_REGISTER_START";
export const FETCH_INWARD_REGISTER_FAILED = "FETCH_INWARD_REGISTER_FAILED";
export const FETCH_INWARD_REGISTER_SUCCESS = "FETCH_INWARD_REGISTER_SUCCESS";

export const FETCH_OUTWARD_REGISTER_START = "FETCH_OUTWARD_REGISTER_START";
export const FETCH_OUTWARD_REGISTER_SUCCESS = "FETCH_OUTWARD_REGISTER_SUCCESS";
export const FETCH_OUTWARD_REGISTER_FAILED = "FETCH_OUTWARD_REGISTER_FAILED";

export const START_OUTWARD_REGISTER_ADD = "START_OUTWARD_REGISTER_ADD";
export const OUTWARD_REGISTER_ADD_SUCCESS = "OUTWARD_REGISTER_ADD_SUCCESS";
export const OUTWARD_REGISTER_ADD_FAILED = "OUTWARD_REGISTER_ADD_FAILED";

export const START_INWARD_REGISTER_ADD = "START_INWARD_REGISTER_ADD";
export const INWARD_REGISTER_ADD_SUCCESS = "INWARD_REGISTER_ADD_SUCCESS";
export const INWARD_REGISTER_ADD_FAILED = "INWARD_REGISTER_ADD_FAILED";

export const START_SAC_MASTER_ADD = "START_SAC_MASTER_ADD";
export const SAC_MASTER_ADD_SUCCESS = "SAC_MASTER_ADD_SUCCESS";
export const SAC_MASTER_ADD_FAILED = "SAC_MASTER_ADD_FAILED";

export const START_SAC_MASTER_FETCH_LIST = "START_SAC_MASTER_FETCH_LIST";
export const SAC_MASTER_FETCH_LIST_SUCCESS = "SAC_MASTER_FETCH_LIST_SUCCESS";
export const SAC_MASTER_FETCH_LIST_FAILED = "SAC_MASTER_FETCH_LIST_FAILED";

export const START_CLIENT_GSTIN_FETCH = "START_CLIENT_GSTIN_FETCH";
export const CLIENT_GSTIN_FETCH_SUCCESS = "CLIENT_GSTIN_FETCH_SUCCESS";
export const CLIENT_GSTIN_FETCH_FAILED = "CLIENT_GSTIN_FETCH_FAILED";

export const SET_CLIENT_LOCATION = "SET_CLIENT_LOCATION";

export const FETCH_LEAD_CHANNELS_START = "FETCH_LEAD_CHANNELS_START";
export const FETCH_LEAD_CHANNELS_FAILED = "FETCH_LEAD_CHANNELS_FAILED";
export const FETCH_LEAD_CHANNELS_SUCCESS = "FETCH_LEAD_CHANNELS_SUCCESS";

export const FETCH_TINESHEET_CODE_START = "FETCH_TINESHEET_CODE_START";
export const FETCH_TIMESHEET_CODE_SUCCESS = "FETCH_TIMESHEET_CODE_SUCCESS";
export const FETCH_TIMESHEET_CODE_FAILED = "FETCH_TIMESHEET_CODE_FAILED";

export const SET_SELECTED_GSTIN = "SET_SELECTED_GSTIN";

export const START_EMPLOYEE_APPROVEL = "START_EMPLOYEE_APPROVEL";
export const EMPLOYEE_APPROVEL_FAILED = "EMPLOYEE_APPROVEL_FAILED";
export const EMPLOYEE_APPROVEL_SUCCESS = "EMPLOYEE_APPROVEL_SUCCESS";

export const START_FETCH_EMLOYEE_DETAILS = "START_FETCH_EMLOYEE_DETAILS";
export const FETCH_EMPLOYEE_DETAILS_SUCCESS = "FETCH_EMPLOYEE_DETAILS_SUCCESS";
export const FETCH_EMPLOYEE_DETAILS_FAILED = "FETCH_EMPLOYEE_DETAILS_FAILED";

export const START_FETCH_DESIGNATION = "START_FETCH_DESIGNATION";
export const DESIGNATION_FETCH_SUCCESS = "DESIGNATION_FETCH_SUCCESS";
export const DESIGNATION_FETCH_FAILED = "DESIGNATION_FETCH_FAILED";

export const START_FETCH_ACCESS_ROLES = "START_FETCH_ACCESS_ROLES";
export const ACCESS_ROLES_FETCH_SUCCESS = "ACCESS_ROLES_FETCH_SUCCESS";
export const ACCESS_ROLES_FETCH_FAILED = "ACCESS_ROLES_FETCH_FAILED";

// for fetchig member roles
export const START_FETCH_ROLES = "START_FETCH_ROLES";
export const ROLE_FETCH_SUCCESS = "ROLE_FETCH_SUCCESS";
export const ROLE_FETCH_FAILED = "ROLE_FETCH_FAILED";
// for getting updated list for showing in timesheet for timesheet codes
// to show only unselected items in dropdown
//
export const REMOVE_EMPLOYEE = "REMOVE_EMPLOYEE";
export const ADD_BACK_EMPLOYEE = "ADD_BACK_EMPLOYEE";
export const INITIAL_FILTERING_EMPLOYEE = "INITIAL_FILTERING_EMPLOYEE";

export const REMOVE_TIMESHEET_CODE_ITEM = "REMOVE_TIMESHEET_CODE_ITEM";
export const ADD_ITEM_BACK_IN_TIMESHEET_CODE =
  "ADD_ITEM_BACK_IN_TIMESHEET_CODE";
export const INITIAL_FILTERING_TIME_SHEET_CODES =
  "INITIAL_FILTERING_TIME_SHEET_CODES";

export const START_FETCH_ATTENDENCE_LIST = "START_FETCH_ATTENDENCE_LIST";
export const FETCH_ATTENDENCE_LIST_SUCCESS = "FETCH_ATTENDENCE_LIST_SUCCESS";
export const FETCH_ATTENDENCE_LIST_FAILED = "FETCH_ATTENDENCE_LIST_FAILED";

export const START_MARK_ATTENDENCE = "START_MARK_ATTENDENCE";
export const MARK_ATTENDENCE_SUCCESS = "MARK_ATTENDENCE_SUCCESS";
export const MARK_ATTENDENCE_FAILED = "MARK_ATTENDENCE_FAILED";

export const START_FETCH_HOLIDAY_LIST = "START_FETCH_HOLIDAY_LIST";
export const HOLIDAY_LIST_FETCH_SUCCESS = "HOLIDAY_LIST_FETCH_SUCCESS";
export const HOLIDAY_LIST_FETCH_FAILED = "HOLIDAY_LIST_FETCH_FAILED";

export const START_ADD_HOLIDAY = "START_ADD_HOLIDAY";
export const HOLIDAY_ADD_SUCCESS = "HOLIDAY_ADD_SUCCESS";
export const HOLIDAY_ADD_FAILED = "HOLIDAY_ADD_FAILED";

export const START_FETCH_HOLIDAY_CODE_LIST = "START_FETCH_HOLIDAY_CODE_LIST";
export const HOLIDAY_CODE_LIST_FETCH_SUCCESS =
  "HOLIDAY_CODE_LIST_FETCH_SUCCESS";
export const HOLIDAY_CODE_LIST_FETCH_FAILED = "HOLIDAY_CODE_LIST_FETCH_FAILED";

export const START_FETCH_RESPECTIVE_HOLIDAY_CODE =
  "START_FETCH_RESPECTIVE_HOLIDAY_CODE";
export const FETCH_RESPECTIVE_HOLIDAY_CODE_SUCCESS =
  "FETCH_RESPECTIVE_HOLIDAY_CODE_SUCCESS";
export const FETCH_RESPECTIVE_HOLIDAY_CODE_FAILED =
  "FETCH_RESPECTIVE_HOLIDAY_CODE_FAILED";

export const START_ADD_PROJECT_SUPER_CLASS = "START_ADD_PROJECT_SUPER_CLASS";
export const ADD_PROJECT_SUPER_CLASS_SUCCESS =
  "ADD_PROJECT_SUPER_CLASS_SUCCESS";
export const ADD_PROJECT_SUPER_CLASS_FAILED = "ADD_PROJECT_SUPER_CLASS_FAILED";

export const START_FETCH_PROJECT_SUPER_CLASS =
  "START_FETCH_PROJECT_SUPER_CLASS";
export const FETCH_PROJECT_SUPER_CLASS_SUCCESS =
  "FETCH_PROJECT_SUPER_CLASS_SUCCESS";
export const FETCH_PROJECT_SUPER_CLASS_FAILED =
  "FETCH_PROJECT_SUPER_CLASS_FAILED";

export const START_UPDATE_PROJECT_STATUS = "START_UPDATE_PROJECT_STATUS";
export const UPDATE_PROJECT_STATUS_SUCCESS = "UPDATE_PROJECT_STATUS_SUCCESS";
export const UPDATE_PROJECT_STATUS_FAILED = "UPDATE_PROJECT_STATUS_FAILED";

export const START_FETCH_COMBINED_ACTIVITY = "START_FETCH_COMBINED_ACTIVITY";
export const FETCH_COMBINED_ACTIVITY_SUCCESS =
  "FETCH_COMBINED_ACTIVITY_SUCCESS";
export const FETCH_COMBINED_ACTIVITY_FAILED = "FETCH_COMBINED_ACTIVITY_FAILED";

export const START_FETCH_SETTING_LIST = "START_FETCH_SETTING_LIST";
export const FETCH_SETTING_LIST_SUCCESS = "FETCH_SETTING_LIST_SUCCESS";
export const FETCH_SETTING_LIST_FAILED = "FETCH_SETTING_LIST_FAILED";

export const START_ADD_SETTING = "START_ADD_SETTING";
export const ADD_SETTING_SUCCESS = "ADD_SETTING_SUCCESS";
export const ADD_SETTING_FAILED = "ADD_SETTING_FAILED";

export const START_UPDATE_SETTING = "START_UPDATE_SETTING";
export const UPDATE_SETTING_SUCCESS = "UPDATE_SETTING_SUCCESS";
export const UPDATE_SETTING_FAILED = "UPDATE_SETTING_FAILED";

export const START_FETCH_SETTING = "START_FETCH_SETTING";
export const FETCHING_SETTING_FAILED = "FETCHING_SETTING_FAILED";
export const FETCHING_SETTING_SUCCESS = "FETCHING_SETTING_SUCCESS";

export const START_SUBMIT_FOR_ARCHIVEL = "START_SUBMIT_FOR_ARCHIVEL";
export const SUBMIT_FOR_ARCHIVEL_SUCCESS = "SUBMIT_FOR_ARCHIVEL_SUCCESS";
export const SUBMIT_FOR_ARCHIVEL_FAILED = "SUBMIT_FOR_ARCHIVEL_FAILED";

export const START_UPDATE_DSC_CLIENT = "START_UPDATE_DSC_CLIENT";
export const UPDATE_DSC_CLIENT_SUCCESS = "UPDATE_DSC_CLIENT_SUCCESS";
export const UPDATE_DSC_CLIENT_FAILED = "UPDATE_DSC_CLIENT_FAILED";

export const START_UPDATE_DSC_CUSTODIAN = "START_UPDATE_DSC_CUSTODIAN";
export const UPDATE_DSC_CUSTDOIAN_SUCCESS = "UPDATE_DSC_CUSTDOIAN_SUCCESS";
export const UPDATE_DSC_CUSTDOIAN_FAILED = "UPDATE_DSC_CUSTDOIAN_FAILED";

export const START_IN_MOVEMENT = "START_IN_MOVEMENT";
export const IN_MOVEMENT_SUCCESS = "IN_MOVEMENT_SUCCESS";
export const IN_MOVEMENT_FAILED = "IN_MOVEMENT_FAILED";

export const START_SEARCH_MOVEMENT_REGISTER = "START_SEARCH_MOVEMENT_REGISTER";
export const MOVEMENT_REGISTER_SERACH_SUCCESS =
  "MOVEMENT_REGISTER_SERACH_SUCCESS";
export const MOVEMENT_REGISTER_SERACH_FAILED =
  "MOVEMENT_REGISTER_SERACH_FAILED";
//for dsc basic details update
export const START_BASIC_DETAILS_UPDATE = "START_BASIC_DETAILS_UPDATE";
export const BASIC_DETAILS_UPDATE_SUCCESS = "BASIC_DETAILS_UPDATE_SUCCESS";
export const BASIC_DETAILS_UPDATE_FAILED = "BASIC_DETAILS_UPDATE_FAILED";

export const FETCH_DOC_URL_START = "FETCH_DOC_URL_START";
export const FETCH_DOC_URL_SUCCESS = "FETCH_DOC_URL_SUCCESS";
export const FETCH_DOC_URL_FAILED = "FETCH_DOC_URL_FAILED";

export const START_FETCH_CERTIFICATE_REG_BY_ID =
  "START_FETCH_CERTIFICATE_REG_BY_ID";
export const FETCH_CERTIFICATE_REG_BY_ID_SUCCESS =
  "FETCH_CERTIFICATE_REG_BY_ID_SUCCESS";
export const FETCH_CERTIFICATE_REG_BY_ID_FAILED =
  "FETCH_CERTIFICATE_REG_BY_ID_FAILED";

export const START_CERTIFICATE_REGISTER_UPDATE =
  "START_CERTIFICATE_REGISTER_UPDATE";
export const CERTIFICATE_REGISTER_UPDATE_SUCCESS =
  "CERTIFICATE_REGISTER_UPDATE_SUCCESS";
export const CERTIFICATE_REGISTER_UPDATE_FAILED =
  "CERTIFICATE_REGISTER_UPDATE_FAILED";

//below for fetching auto recurring ststus
export const FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_START =
  "FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_START";
export const FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_SUCCESS =
  "FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_SUCCESS";
export const FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_FAILED =
  "FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_FAILED";

export const FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_START =
  "FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_START";
export const FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_FAILED =
  "FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_FAILED";
export const FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_SUCCESS =
  "FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_SUCCESS";

export const APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_START =
  "APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_START";
export const APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_SUCCESS =
  "APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_SUCCESS";
export const APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_FAILED =
  "APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_FAILED";
//these 2 below set of types were added for time booking within project,
//but i decided to go with timesheets type
export const FETCH_TIME_BOOKED_FOR_PROJECT_START =
  "FETCH_TIME_BOOKED_FOR_PROJECT_START";
export const FETCH_TIME_BOOKED_FOR_PROJECT_SUCCESS =
  "FETCH_TIME_BOOKED_FOR_PROJECT_SUCCESS";
export const FETCH_TIME_BOOKED_FOR_PROJECT_FAILED =
  "FETCH_TIME_BOOKED_FOR_PROJECT_FAILED";

export const BOOK_TIME_FOR_PROJECT_START = "BOOK_TIME_FOR_PROJECT_START";
export const BOOK_TIME_FOR_PROJECT_SUCCESS = "BOOK_TIME_FOR_PROJECT_SUCCESS";
export const BOOK_TIME_FOR_PROJECT_FAILED = "BOOK_TIME_FOR_PROJECT_FAILED";

export const UPDATE_PROJECT_STATUS_TO_IN_PROGRESS_LOCALLY =
  "UPDATE_PROJECT_STATUS_TO_IN_PROGRESS_LOCALLY";

export const START_FETCH_SAC_MASTER_OF_SUBCLASS =
  "START_FETCH_SAC_MASTER_OF_SUBCLASS";
export const FAILED_FETCH_SAC_MASTER_OF_SUBCLASS =
  "FAILED_FETCH_SAC_MASTER_OF_SUBCLASS";
export const SUCCESS_FETCH_SAC_MASTER_OF_SUBCLASS =
  "SUCCESS_FETCH_SAC_MASTER_OF_SUBCLASS";

export const START_FETCH_PLACE_OF_SUPPLY = "START_FETCH_PLACE_OF_SUPPLY";
export const SUCCESS_FETCH_PLACE_OF_SUPPLY = "SUCCESS_FETCH_PLACE_OF_SUPPLY";
export const FAILED_FETCH_PLACE_OF_SUPPLY = "FAILED_FETCH_PLACE_OF_SUPPLY";

export const SET_AVAILABLE_PROJECTS = "SET_AVAILABLE_PROJECTS";
export const DELETE_FROM_AVILABLE_PROJECTS = "DELETE_FROM_AVILABLE_PROJECTS";
export const ADD_BACK_TO_AVAILABLE_PROJECTS = "ADD_BACK_TO_AVAILABLE_PROJECTS";

export const START_FETCH_SAC_MASTER_DETAILS_BY_CODE =
  "START_FETCH_SAC_MASTER_DETAILS_BY_CODE";
export const SUCCESS_FETCH_SAC_MASTER_DETAILS_BY_CODE =
  "SUCCESS_FETCH_SAC_MASTER_DETAILS_BY_CODE";
export const FAILED_FETCH_SAC_MASTER_DETAILS_BY_CODE =
  "FAILED_FETCH_SAC_MASTER_DETAILS_BY_CODE";

export const DELETE_FROM_AVAILABLE_OPES = "DELETE_FROM_AVAILABLE_OPES";
export const ADD_BACK_TO_AVAILABLE_OPES = "ADD_BACK_TO_AVAILABLE_OPES";

//called on project select in invoice to fetch previous actual value
export const START_IS_RECURRING_CHECK = "START_IS_RECURRING_CHECK";
export const SUCCESS_IS_RECURRING_CHECK = "SUCCESS_IS_RECURRING_CHECK";
export const FAILED_IS_RECURRING_CHECK = "FAILED_IS_RECURRING_CHECK";

export const START_FINAL_INVOICE_FETCH = "START_FINAL_INVOICE_FETCH";
export const SUCCESS_FINAL_INVOICE_FETCH = "SUCCESS_FINAL_INVOICE_FETCH";
export const FAILED_FINAL_INVOICE_FETCH = "FAILED_FINAL_INVOICE_FETCH";

//called to fetch invoice number
export const GET_INVOICE_NUMBER_FETCH = "GET_INVOICE_NUMBER_FETCH";
export const GET_INVOICE_NUMBER_FAIL = "GET_INVOICE_NUMBER_FAIL";
export const GET_INVOICE_NUMBER_SUCCESS = "GET_INVOICE_NUMBER_SUCCESS";

export const CLEAR_NOTIFICATION_COUNT = "CLEAR_NOTIFICATION_COUNT";
export const INCRIMENT_NOTIFICATION_COUNT = "INCRIMENT_NOTIFICATION_COUNT";

export const START_FETCH_NOTIFICATION = "START_FETCH_NOTIFICATION";
export const FAILED_NOTIFICATION_FETCH = "FAILED_NOTIFICATION_FETCH";
export const SUCCESS_NOTIFICATION_FETCH = "SUCCESS_NOTIFICATION_FETCH";

export const NEW_NOTIFICATION_RECEIVED = "NEW_NOTIFICATION_RECEIVED";

export const LEAVE_CATEGORY_FETCH_START = "LEAVE_CATEGORY_FETCH_START";
export const LEAVE_CATEGORY_FETCH_SUCCESS = "LEAVE_CATEGORY_FETCH_SUCCESS";
export const LEAVE_CATEGORY_FETCH_FAILED = "LEAVE_CATEGORY_FETCH_FAILED";

// SPEC MASTER
export const START_SPEC_MASTER_FETCH = "START_SPEC_MASTER_FETCH";
export const START_SPEC_MASTER_SUCCESS = "START_SPEC_MASTER_SUCCESS";
export const START_SPEC_MASTER_FAILED = "START_SPEC_MASTER_FAILED";
export const STORE_FORM = "STORE_FORM";

//countries
export const START_FETCH_COUNTRIES = "START_FETCH_COUNTRIES";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const FETCH_COUNTRIES_FAILED = "FETCH_COUNTRIES_FAILED";

//industries
export const START_FETCH_INDUSTRIES = "START_FETCH_INDUSTRIES";
export const FETCH_INDUSTRIES_SUCCESS = "FETCH_INDUSTRIES_SUCCESS";
export const FETCH_INDUSTRIES_FAILED = "FETCH_INDUSTRIES_FAILED";

export const FETCH_START_CURRENCY = "FETCH_START_CURRENCY";
export const FETCH_CURRENCY_SUCCESS = "FETCH_CURRENCY_SUCCESS";
export const FETCH_CURRENCY_FAILED = "FETCH_CURRENCY_FAILED";

export const FETCH_EXCHANGE_RATE_START = "FETCH_EXCHANGE_RATE_START";
export const FETCH_EXCHANGE_RATE_SUCCESS = "FETCH_EXCHANGE_RATE_SUCCESS";
export const FETCH_EXCHANGE_RATE_FAILED = "FETCH_EXCHANGE_RATE_FAILED";
