import React, { Component } from "react";
import { Row, Col, Container, Badge, Card, Table } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Header from "../../components/Layout/Header/Header";
import { Link } from "react-router-dom";
import styles from "../modelstyles";
import { withStyles } from "@material-ui/core";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { compose } from "redux";
import {
    FormDialog,
    SavingDetailsModal,
    MultiselectFormDialog,
    UpdateDialog,
} from "../../utils/formComponents";
import { withRouter } from "react-router-dom";
import find from "lodash/find";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
class DscDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reAuthenticated: false,
            showDialogForm: false,
            showBasicDetailsEditForm: false,
            password: "",
            showMultiSelectForm: false,
            clients: {},
            basic_details: {},
            basic_details_update: {},
        };
        this.reAuthenticate = this.reAuthenticate.bind(this);
        this.closeDialogForm = this.closeDialogForm.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.closeMultiSelectForm = this.closeMultiSelectForm.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.updateClient = this.updateClient.bind(this);
        this.handleItemChange = this.handleItemChange.bind(this);
        this.updateClients = this.updateClients.bind(this);
        this.handleBasicDetailsChange =
            this.handleBasicDetailsChange.bind(this);
        this.updateBasicDetails = this.updateBasicDetails.bind(this);
    }
    handleItemChange(e) {
        this.setState({ clients: e });
    }
    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }
    handleBasicDetailsChange(e) {
        //https://stackoverflow.com/a/43639228/5706413
        this.setState({
            basic_details: {
                ...this.state.basic_details,
                [e.target.id]: e.target.value,
            },
        });
    }
    updateBasicDetails() {
        this.setState({
            showBasicDetailsEditForm: false,
        });
        if (!this.state.basic_details["password"]) {
            //remove password null from update params,password null occurs when
            //its non verfied users
            const { password, ...withoutFirst } = this.state.basic_details;
            withoutFirst["skip_encryption"] = true;
            this.props.dscBasicDetailsUpdate(
                this.props.token,
                withoutFirst,
                this.props.movement_register_details.id
            );
        } else {
            this.props.dscBasicDetailsUpdate(
                this.props.token,
                this.state.basic_details,
                this.props.movement_register_details.id
            );
        }
    }
    closeModal() {}
    updateClients() {
        this.setState({
            showMultiSelectForm: false,
        });
        let i = 0;
        let dup_clients = {};
        for (const [key, client] of Object.entries(
            this.props.movement_register_details["clients"]
        )) {
            dup_clients[i] = {};
            if (find(this.state.clients, { id: client.id })) {
                dup_clients[i]["id"] = client.client_dsc_id;
                dup_clients[i]["client_id"] = client.id;
                dup_clients[i]["_destroy"] = false;
            } else {
                dup_clients[i]["id"] = client.client_dsc_id;
                dup_clients[i]["client_id"] = client.id;
                dup_clients[i]["_destroy"] = true;
            }
            i++;
        }
        if (this.state.clients !== null) {
            for (const [key, client] of Object.entries(this.state.clients)) {
                if (find(dup_clients, { client_id: client.id })) {
                } else {
                    dup_clients[i] = {};
                    dup_clients[i]["client_id"] = client.id;
                    dup_clients[i]["_destroy"] = false;
                    i++;
                }
            }
        }
        let temp = {};
        temp["client_dscs_attributes"] = {};
        temp["client_dscs_attributes"] = dup_clients;
        temp["skip_encryption"] = true; //becoz password encrypted each time client is updated
        this.props.dscClientUpdate(
            this.props.token,
            temp,
            this.props.movement_register_details.id
        );
    }
    componentWillMount() {
        this.props.fetchClientList(this.props.token);
        if (this.props.location.data) {
            this.props
                .fetchMovementRegisterById(
                    this.props.token,
                    this.props.location.data.id
                )
                .then((resp) => {
                    this.setState({ clients: resp });
                });
        }
    }
    componentDidMount() {
        this.setState({
            clients: this.props.movement_register_details.clients,
        });
    }
    componentDidUpdate(prevProps) {
        if (
            this.props.client_credentialLoadingSuccess &&
            this.props.client_credentialLoadingSuccess !==
                prevProps.client_credentialLoadingSuccess
        ) {
            this.setState({ reAuthenticated: true });
        }
    }
    reAuthenticate() {
        this.setState({
            showDialogForm: false,
        });
        this.props.reAuthenticateDsc(
            {
                id: this.props.movement_register_details.id,
                password: this.state.password,
            },
            this.props.token
        );
    }
    closeDialogForm() {
        this.setState({ showDialogForm: false });
    }
    closeMultiSelectForm() {
        this.setState({ showMultiSelectForm: false });
    }
    updateClient() {}
    render() {
        const { classes } = this.props;
        return (
            <Container>
                <Header title="movement_register_details" />
                <Row className={classes.dsc_details_toolbar}>
                    {this.props.movement_register_details.status === "Out" ? (
                        <Link
                            to={{
                                pathname: "/movement_register_movement",
                                data: {
                                    title: "In",
                                    movement_register_id:
                                        this.props.movement_register_details.id,
                                    last_movement_date:
                                        this.props.movement_register_details
                                            .last_movement_date,
                                },
                            }}
                        >
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                            >
                                IN
                            </Button>
                        </Link>
                    ) : (
                        <Link
                            to={{
                                pathname: "/movement_register_movement",
                                data: {
                                    title: "Out",
                                    movement_register_id:
                                        this.props.movement_register_details.id,
                                    last_movement_date:
                                        this.props.movement_register_details
                                            .last_movement_date,
                                },
                            }}
                        >
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                            >
                                OUT
                            </Button>
                        </Link>
                    )}
                    {this.props.movement_register_details["status"] === "In" ? (
                        <Link
                            to={{
                                pathname: "/movement_register_movement",
                                data: {
                                    title: "Custodian Details",
                                    movement_register_id:
                                        this.props.movement_register_details.id,
                                    last_custodian_change_date:
                                        this.props.movement_register_details
                                            .last_custodian_change_date,
                                },
                            }}
                        >
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                            >
                                Change Custodian
                            </Button>
                        </Link>
                    ) : null}
                    <Button
                        onClick={() => {
                            this.setState({ showMultiSelectForm: true });
                        }}
                        color="primary"
                        size="small"
                        variant="outlined"
                    >
                        Clients
                    </Button>
                </Row>
                <Card>
                    <Card.Header className="text-center">
                        <Row>
                            <Col className="text-right">Basic Details</Col>
                            <Col className="text-right">
                                <div
                                    onClick={() =>
                                        this.setState({
                                            showBasicDetailsEditForm: true,
                                            basic_details:
                                                this.props
                                                    .movement_register_details,
                                        })
                                    }
                                >
                                    <IconButton
                                        color="primary"
                                        aria-label="edit"
                                    >
                                        <Edit />
                                    </IconButton>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row className={classes.rowStyle}>
                            <Col>
                                <Col>
                                    Name:{" "}
                                    <span className={classes.dscSpanColor}>
                                        {" "}
                                        {
                                            this.props.movement_register_details
                                                .name
                                        }
                                    </span>
                                </Col>
                            </Col>

                            <Col>
                                <Col>
                                    Password:{" "}
                                    <span
                                        className={classes.dscSpanColorPassword}
                                    >
                                        {(this.state.reAuthenticated ||
                                            this.props
                                                .movement_register_details[
                                                "isPartner"
                                            ]) &&
                                        this.props.movement_register_details
                                            .password ? (
                                            this.props.movement_register_details
                                                .password
                                        ) : (
                                            <Button
                                                onClick={() => {
                                                    this.setState({
                                                        showDialogForm: true,
                                                    });
                                                }}
                                                color="primary"
                                                variant="contained"
                                            >
                                                Click here to view the password
                                            </Button>
                                        )}
                                    </span>{" "}
                                </Col>
                            </Col>
                        </Row>

                        <Row className={classes.rowStyle}>
                            <Col>
                                <Col>
                                    Token NO:{" "}
                                    <span className={classes.dscSpanColor}>
                                        {" "}
                                        {
                                            this.props.movement_register_details
                                                .token_no
                                        }
                                    </span>{" "}
                                </Col>
                            </Col>
                            <Col>
                                <Col>
                                    Expiry Date:{" "}
                                    <span className={classes.dscSpanColor}>
                                        {" "}
                                        {
                                            this.props.movement_register_details
                                                .expiry_date
                                        }
                                    </span>{" "}
                                </Col>
                            </Col>
                        </Row>

                        <Row className={classes.rowStyle}>
                            <Col>
                                <Col>
                                    {" "}
                                    Token Provider:{" "}
                                    <span className={classes.dscSpanColor}>
                                        {" "}
                                        {
                                            this.props.movement_register_details
                                                .token_provider
                                        }
                                    </span>{" "}
                                </Col>
                            </Col>
                            <Col>
                                <Col>
                                    {" "}
                                    Remarks:{" "}
                                    <span className={classes.dscSpanColor}>
                                        {" "}
                                        {
                                            this.props.movement_register_details
                                                .remarks
                                        }
                                    </span>{" "}
                                </Col>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Row></Row>

                <Card>
                    <Card.Header className="text-center">
                        Movement Details
                    </Card.Header>
                    <Card.Body>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Name</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.movement_register_details
                                    .movement_details &&
                                this.props.movement_register_details
                                    .movement_details.length
                                    ? this.props.movement_register_details.movement_details.map(
                                          (item, key) => (
                                              <tr>
                                                  <td>{key}</td>
                                                  <td>{item.in_out_status}</td>
                                                  <td>{item.date}</td>
                                                  <td>{item.name}</td>
                                                  <td>{item.remarks}</td>
                                              </tr>
                                          )
                                      )
                                    : null}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header className="text-center">
                        Custodian Details
                    </Card.Header>
                    <Card.Body>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Name</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.movement_register_details
                                    .dsc_custodians_attributes &&
                                this.props.movement_register_details
                                    .dsc_custodians_attributes.length
                                    ? this.props.movement_register_details.dsc_custodians_attributes.map(
                                          (item, key) => (
                                              <tr>
                                                  <td>{key}</td>
                                                  <td>{item.date}</td>
                                                  <td>{item.name}</td>
                                                  <td>{item.remarks}</td>
                                              </tr>
                                          )
                                      )
                                    : null}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header className="text-center">
                        Associated Client
                    </Card.Header>
                    <Card.Body>
                        <Table responsive>
                            <thead></thead>
                            <tbody>
                                {this.props.movement_register_details.clients &&
                                this.props.movement_register_details.clients
                                    .length
                                    ? this.props.movement_register_details.clients.map(
                                          (item, key) => (
                                              <tr>
                                                  <td>{key}</td>
                                                  <td>{item.name}</td>
                                              </tr>
                                          )
                                      )
                                    : null}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <FormDialog
                    open={this.state.showDialogForm}
                    handleClose={this.closeDialogForm}
                    reAuthenticate={this.reAuthenticate}
                    password={this.state.password}
                    handlePasswordChange={this.handlePasswordChange}
                />
                <MultiselectFormDialog
                    open={this.state.showMultiSelectForm}
                    handleClose={this.closeMultiSelectForm}
                    isMulti={true}
                    options={this.props.dclients}
                    handleItemChange={this.handleItemChange}
                    value={this.state.clients}
                    title="Update Associated Clients"
                    subTitle="Associated Clients"
                    name="client_id"
                    styles={classes.dialogPaper}
                    updateClients={this.updateClients}
                />
                <UpdateDialog
                    open={this.state.showBasicDetailsEditForm}
                    name="dsc_basic_details_update"
                    title="Update Basic Details"
                    description=""
                    handleClose={() =>
                        this.setState({ showBasicDetailsEditForm: false })
                    }
                    update={this.updateBasicDetails}
                    value={this.state.basic_details}
                    handleChange={this.handleBasicDetailsChange}
                    isAuthenticated={
                        this.state.reAuthenticated ||
                        this.props.movement_register_details["isPartner"]
                            ? true
                            : false
                    }
                />

                <SavingDetailsModal
                    className={classes.modal}
                    show={this.props.client_credentialLoading}
                    title={"Fetching DSC credenials"}
                    loading={this.props.client_credentialLoading}
                    message={this.props.client_credentialLoadingMessage}
                    onClick={this.closeModal}
                />
            </Container>
        );
    }
}
function mapStateToProps(state) {
    return {
        token: state.auth.token,
        movement_register_details:
            state.movementregister.movementRegisterDetails,
        client_credentialLoading: state.client.client_credentialLoading,
        client_credentialLoadingSuccess:
            state.client.client_credentialLoadingSuccess,
        client_credentialLoadingMessage:
            state.client.client_credentialLoadingMessage,
        dclients: state.client.dclientList,
        basicDetailsUpdateLoading:
            state.movementregister.basicDetailsUpdateLoading,
        basicDetailsUpdateMessage:
            state.movementregister.basicDetailsUpdateMessage,
        basicDetailsUpdateFailed:
            state.movementregister.basicDetailsUpdateFailed,
    };
}
export default compose(
    withRouter,
    connect(mapStateToProps, actions),
    withStyles(styles)
)(DscDetails);
