import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_CERTIFICATE_REGISTER_ADD,
    CERTIFICATE_REGISTER_ADD_DONE,
    CERTIFICATE_REGISTER_ADD_FAILED,
    CERTIFICATE_REGISTER_LIST_FECH_DONE,
    CERTIFICATE_REGISTER_LIST_FECH_FAILED,
    START_CERTIFICATE_REGISTER_LIST_FETCH,
    FETCH_DOC_URL_START,
    FETCH_DOC_URL_SUCCESS,
    FETCH_DOC_URL_FAILED,
    START_FETCH_CERTIFICATE_REG_BY_ID,
    FETCH_CERTIFICATE_REG_BY_ID_SUCCESS,
    FETCH_CERTIFICATE_REG_BY_ID_FAILED,
    START_CERTIFICATE_REGISTER_UPDATE,
    CERTIFICATE_REGISTER_UPDATE_SUCCESS,
    CERTIFICATE_REGISTER_UPDATE_FAILED,
} from "./types";

export const getCertificateRegisterById = (token, id) => {
    return async (dispatch) => {
        dispatch({
            type: START_FETCH_CERTIFICATE_REG_BY_ID,
        });
        try {
            const res = await axios.get(
                `${BaseUrl}/certificate_register/${id}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (res.data.status) {
                dispatch({
                    type: FETCH_CERTIFICATE_REG_BY_ID_SUCCESS,
                    payload: res.data.certificate_register,
                });
                return res.data.certificate_register;
            } else {
                dispatch({
                    type: FETCH_CERTIFICATE_REG_BY_ID_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_CERTIFICATE_REG_BY_ID_FAILED,
                payload: error.message,
            });
        }
    };
};
export const updateCertificateRegister = (token, id, data) => {
    return async (dispatch) => {
        dispatch({
            type: START_CERTIFICATE_REGISTER_UPDATE,
        });
        try {
            const fdata = formatFormData(data);
            const res = await axios.patch(
                `${BaseUrl}/certificate_register/${id}`,
                fdata,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: CERTIFICATE_REGISTER_UPDATE_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: CERTIFICATE_REGISTER_UPDATE_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: CERTIFICATE_REGISTER_UPDATE_FAILED,
                payload: error.message,
            });
        }
    };
};

export const getDocUrl = (token, id) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_DOC_URL_START,
        });
        try {
            const res = await axios.get(
                `${BaseUrl}/certificate_registers/doc/${id}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (res.data.status) {
                dispatch({
                    type: FETCH_DOC_URL_SUCCESS,
                    payload: res.data.doc,
                });
            } else {
                dispatch({
                    type: FETCH_DOC_URL_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_DOC_URL_FAILED,
                payload: error.message,
            });
        }
    };
};
export const fetchCertificateRegistersList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_CERTIFICATE_REGISTER_LIST_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/certificate_registers`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (res.statusText == "OK") {
                dispatch({
                    type: CERTIFICATE_REGISTER_LIST_FECH_DONE,
                    payload: res.data["certificate_registers"],
                });
            } else {
                dispatch({
                    type: CERTIFICATE_REGISTER_LIST_FECH_FAILED,
                    payload: "Fetching certificate registers list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: CERTIFICATE_REGISTER_LIST_FECH_FAILED,
                payload: "Fetching certificate registers list failed",
            });
        }
    };
};

const formatFormData = (data) => {
    const fdata = new FormData();
    for (var key of Object.keys(data)) {
        if (key !== "doc") {
            fdata.append(`certificate_register[${key}]`, data[key]);
        } else {
            fdata.append(`certificate_register[${key}]`, data[key]); //new Blob([data[key]], { type: 'multipart/form-data' }));
        }
    }
    return fdata;
};
export const addNewCertificateRegister = (token, data) => {
    return async (dispatch) => {
        dispatch({
            type: START_CERTIFICATE_REGISTER_ADD,
        });
        try {
            /**const fdata = new FormData();
            for (var key of Object.keys(data)) {
                if(key !== "doc"){
                    fdata.append(`certificate_register[${key}]`,data[key])
                }else{
                    fdata.append(`certificate_register[${key}]`, data[key])//new Blob([data[key]], { type: 'multipart/form-data' }));
                }
            }**/
            const fdata = formatFormData(data);
            const res = await axios.post(
                `${BaseUrl}/certificate_register`,
                fdata,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (res.data.status == "success") {
                dispatch({
                    type: CERTIFICATE_REGISTER_ADD_DONE,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: CERTIFICATE_REGISTER_ADD_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: CERTIFICATE_REGISTER_ADD_FAILED,
                payload: error.message,
            });
        }
    };
};
