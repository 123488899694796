import React from "react";
import PageTemplate from "../components/Layout/PageTemplate/PageTemplate";
import CoreUITable from "../components/Layout/Table";
import {
    useProjectsByFilter,
    useUpdateBillingStatus,
} from "../hooks/project-query/projects";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useHistory } from "react-router-dom";
import { AlertComponent, UpdateDialog } from "../utils/formComponents";
import { Link } from "react-router-dom";

const ProgressiveAndUnbilled = () => {
    const history = useHistory();
    const [tempProjectId, setTempProjectId] = React.useState(undefined);
    const [showAlert, setShowAlert] = React.useState(false);
    const [billingStatus, setBillingStatus] = React.useState(undefined)
    const { isLoading, isError, data, error, refetch } = useProjectsByFilter("Progressive_and_Unbilled");
    const {
        isLoading: updateing,
        data: updateMsg,
        error: updateError,
        mutate,
    } = useUpdateBillingStatus();
    if (isLoading)
        return <ProgressBar striped variant="success" animated now={40} />;
    if (isError) return <>Error...</>;
    const options = [
        {
            text: "View",
            action: function (id) {
                let temp = data.find(
                    (x) => x.id === id
                );
                history.push({pathname: '/projectdashboard', data:{"project_data": temp} })
            },
            params: true,
        },

        {
            text: "Change Billing Status",
            action: function (id) {
                let temp = data.find(
                    (x) => x.id === id
                );
                setBillingStatus(temp.billing_status)
                setShowAlert(true);
                setTempProjectId(id);
            },
            params: true,
        },
    ];
    const updateStatus = () => {
        setShowAlert(false);
        let temp = {id: tempProjectId, billing_status: billingStatus}
        mutate(temp);
    };
    return (
        <PageTemplate>
            <CoreUITable
                page="unbilled_projects"
                title="Unbilled Projects"
                options={options}
                data={data}
                fields={[
                    "project_id",
                    "name",
                    "client_name",
                    "status",
                    "due_date",
                    "billing_status",
                    {
                        key: "show_details",
                        label: "",
                        _style: { width: "1%" },
                        filter: false,
                    },
                ]}
            />
            <UpdateDialog
              open={showAlert}
              name="billing_status"
              title="Update billing status"
              description="Please select billing status"
              handleClose={() => {
                    setShowAlert(false);
                }}
              update={updateStatus}
              value={billingStatus}
                handleChange={(e)=>{ setBillingStatus(e.target.value)}}
            />

            <AlertComponent
                title="Updateing.."
                description={
                    updateMsg !== ""
                        ? updateMsg
                        : "Updateing Status,please wait"
                }
                open={updateing}
            />
        </PageTemplate>
    );
};

export default ProgressiveAndUnbilled;
