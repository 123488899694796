import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from 'react-redux';
import * as actions from "../../actions";
import Header from "../../components/Layout/Header/Header";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import {InputText,SelectInput,TextArea,validate,SavingDetailsModal} from "../../utils/formComponents";
class AddSacMaster extends Component {

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        sacmasterAddFailed:true
    };

    this.onsubmit = this.onsubmit.bind(this);
 this.closeModal = this.closeModal.bind(this);

}

closeModal(){
    this.setState({
        loading:this.props.loading,
        sacmasterAddFailed:this.props.sacmasterAddFailed
    })
}
componentDidUpdate(){
    setTimeout(() => this.setState({message:''}), 9000);
    if(!this.state.sacmasterAddFailed){
        this.props.history.goBack();
    }
  }
async onsubmit(formData) {

    this.setState({
        loading:true
    })
    formData['short_name'] = formData['sac_short_name'] //client validation had short_name with 25 limit
    delete formData['sac_short_name']
    await this.props.addSacMaster(formData,this.props.token);
    this.setState({
        message:this.props.message
    })
}
  render () {
const { handleSubmit,classes } = this.props;
    let state = this.state;
    return (
      <Container>
      <Header title="add_sac_master" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
      Add Sac Master
      </Typography>
        <Row>
          <Col sm={4}>
          </Col>

        </Row>
        <form onSubmit={handleSubmit(this.onsubmit)} noValidate>
          <Row>
            <Col sm={8}>
              <span>SAC Code</span>
              <Field
                        name="sac_code"
                        label="sac code *"
                        component={InputText}
                        type="number"
                        fullWidth
              />

            </Col>
           <Col sm={4}>


              <span>Short name</span>
              <Field
                        name="sac_short_name"
                        label="short name *"
                        component={InputText}
                        type="text"
                        fullWidth
              />
          </Col>
        </Row>
 <Row>
            <Col sm={8}>
              <span>Short description</span>
              <Field
                        name="description"
                        label="description *"
                        component={InputText}
                        type="text"
                        fullWidth
              />

            </Col>
           <Col sm={4}>


              <span>Rate General</span>
              <Field
                        name="rate_general"
                        label="rate genral"
                        component={InputText}
                        type="number"
                        fullWidth
              />
          </Col>
        </Row>


        <Row>
          <Col sm={8}>
             <span>Rate State Cess</span>
              <Field
                        name="rate_state_cess"
                        label="rate state cess"
                        component={InputText}
                        type="number"
                        fullWidth
              />

          </Col>
          <Col sm={4}>
             <span>Rate Central Cess</span>
              <Field
                        name="rate_central_cess"
                        label="rate central cess"
                        component={InputText}
                        type="number"
                        fullWidth
              />

          </Col>

        </Row>
        <Row>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving Organisation Details"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>


          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>

      </Container>
    )
  }
}

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        message: state.sacmaster.message,
        loading: state.sacmaster.sacmasterAddLoading,
        sacmasterAddFailed: state.sacmaster.sacmasterAddFailed
    }
}

export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddSacMaster",
validate }),
    withStyles(styles)
)(AddSacMaster);

