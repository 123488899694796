import React from "react";
import { useLocation } from "react-router-dom";
import { useFirmAssetDetails } from "../../hooks/firmAsset-query";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import { ChangeCustodian } from "./movement";
import {
    Row,
    Col,
    Container,
    Badge,
    Card,
    Table,
    Modal,
} from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Header from "../../components/Layout/Header/Header";
import { Link } from "react-router-dom";
import { UpdateDialog } from "../../utils/formComponents";
import styles from "./index.module.css";

export default function FirmAssetDetails() {
    const location = useLocation();
    const state = useSelector((state) => state);
    const { user } = state.auth;
    const id = location.pathname.split("firm_asset_details/").pop() + "";
    const { isLoading, isError, data, error } = useFirmAssetDetails(id);
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);

    if (isLoading)
        return <ProgressBar striped variant="success" animated now={40} />;
    if (isError) return <>Sorry, something went wrong</>;
    return (
        <Container>
            <Header title="firm_asset_details" />
            <Row className={styles.toolbar}>
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => setShow(true)}
                >
                    change custodian
                </Button>
            </Row>
            <Card>
                <Card.Header className="text-center">Basic Details</Card.Header>
                <Card.Body>
                    <Row className={styles.rowStyle}>
                        <Col>
                            <Col>
                                Serial No:{" "}
                                <span className={styles.dscSpanColor}>
                                    {" "}
                                    {data.firm_asset_details.serial_no}
                                </span>
                            </Col>
                        </Col>

                        <Col>
                            <Col>
                                Status:{" "}
                                <span className={styles.dscSpanColor}>
                                    {data.firm_asset_details.status}
                                </span>{" "}
                            </Col>
                        </Col>
                    </Row>

                    <Row className={styles.rowStyle}>
                        <Col>
                            <Col>
                                Category Of asset:{" "}
                                <span className={styles.dscSpanColor}>
                                    {" "}
                                    {data.firm_asset_details.category_of_asset}
                                </span>{" "}
                            </Col>
                        </Col>
                        <Col>
                            <Col>
                                Remarks:{" "}
                                <span className={styles.dscSpanColor}>
                                    {" "}
                                    {data.firm_asset_details.remarks}
                                </span>{" "}
                            </Col>
                        </Col>
                    </Row>

                    <Row className={styles.rowStyle}>
                        <Col>
                            <Col>
                                {" "}
                                Created By:{" "}
                                <span className={styles.dscSpanColor}>
                                    {" "}
                                    {data.firm_asset_details.created_by}
                                </span>{" "}
                            </Col>
                        </Col>
                        <Col>
                            <Col>
                                {" "}
                                updated by:{" "}
                                <span className={styles.dscSpanColor}>
                                    {" "}
                                    {data.firm_asset_details.updated_by ||
                                        " - "}
                                </span>{" "}
                            </Col>
                        </Col>
                    </Row>
                    <Row className={styles.rowStyle}>
                        <Col>
                            <Col>
                                Description:{" "}
                                <span className={styles.dscSpanColor}>
                                    {" "}
                                    {data.firm_asset_details.description}
                                </span>{" "}
                            </Col>
                        </Col>
                        <Col>
                            <Col>
                                Last custodian:{" "}
                                <span className={styles.dscSpanColor}>
                                    {" "}
                                    {data.firm_asset_details.last_custodian}
                                </span>{" "}
                            </Col>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row></Row>

            <Card>
                <Card.Header className="text-center">
                    Movement Details
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Date of handover</th>
                                <th>Remarks</th>
                                <th>Created By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.firm_asset_details.custodians &&
                            data.firm_asset_details.custodians.length
                                ? data.firm_asset_details.custodians.map(
                                      (item, key) => (
                                          <tr>
                                              <td>{key}</td>
                                              <td>{item.name}</td>
                                              <td>{item.date_of_handover}</td>
                                              <td>{item.remarks}</td>
                                              <td>{item.created_by}</td>
                                          </tr>
                                      )
                                  )
                                : null}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <ChangeCustodian
                show={show}
                handleClose={() => handleClose()}
                id={id}
                data={data}
                user={user}
            />
        </Container>
    );
}
