import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
// import AboutWrapper from '../containers/AboutWrapper/AboutWrapper'
import CommonList from '../views/CommonTableView';
const ActivityPage = () => {
    return (
        <PageTemplate>
           <CommonList
            page={"activity"}
        />
        </PageTemplate>
    );
};

export default ActivityPage;

