import React, { Component } from "react";
import Header from "../../components/Layout/Header/Header";

import { withStyles } from "@material-ui/core";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { styles } from "./styles";
import { BasicDetails } from "./BasicDetails";
import { ProjectDetails } from "./ProjectDetails";
import { Credentials } from "./Credentials";
import { Contact } from "./Contact";
import { StatutoryDetails } from "./StatutoryDetails";
import { InternalDetails } from "./InternalDetails";
import { InvoiceDetails } from "./InvoiceDetails";
import { DscDetails } from "./DscDetails";
import BreadCrumbComponent from '../../components/BreadCrumbComponent'
class ClientDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reAuthenticated: false,
            showDialogForm: false,
            password: "",
        };
        if (this.props.location.data) {
            this.props.fetchClientDetails(
                this.props.location.data.id,
                this.props.token,
                "client_details"
            );
        }
        this.reAuthenticate = this.reAuthenticate.bind(this);
        this.closeDialogForm = this.closeDialogForm.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }
    closeModal() {}
    componentDidUpdate(prevProps) {
        if (
            this.props.client_credentialLoadingSuccess &&
            this.props.client_credentialLoadingSuccess !==
                prevProps.client_credentialLoadingSuccess
        ) {
            this.setState({ reAuthenticated: true });
        }
    }
    reAuthenticate() {
        this.setState({
            showDialogForm: false,
        });
        this.props.reAuthenticate(
            { id: this.props.client_details.id, password: this.state.password },
            this.props.token
        );
    }
    closeDialogForm() {
        this.setState({ showDialogForm: false });
    }
    render() {
        return (
            <div className="">
                <Header title="client_details" />
                <BreadCrumbComponent />
                <h2 className="text-center">{this.props.client_details.name}</h2>

                <div className="mx-10" style={styles.body}>
                    <div className="">
                        <Tabs
                            defaultActiveKey="basic"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="basic" title="Basic">
                                <BasicDetails client_details={this.props.client_details} />
                            </Tab>
                            <Tab eventKey="projects" title="Projects">
                                <ProjectDetails
                                    liveOrYetToCommence={this.props.client_details.live_projects}
                                    otherProjects={this.props.client_details.other_projects}
                                    unBilled={this.props.client_details.unbilled_projects}
                                />
                            </Tab>
                            <Tab eventKey="credentials" title="Credentials">
                                <Credentials
                                    client_credentials={
                                        this.props.client_details.client_credentials_attributes
                                    }
                                />
                            </Tab>
                            <Tab eventKey="contact" title="Contact">
                                <Contact
                                    management_details_attributes={
                                        this.props.client_details.management_details_attributes
                                    }
                                />
                            </Tab>
                            <Tab eventKey="statutory" title="Statutory">
                                <StatutoryDetails
                                    client_details={this.props.client_details}
                                />
                            </Tab>
                            <Tab eventKey="invoice" title="Invoice">
                                <InvoiceDetails
                                    client_invoices={this.props.client_details.client_invoices}
                                />
                            </Tab>
                            <Tab eventKey="dsc" title="DSC">
                                <DscDetails
                                    client_dscs={this.props.client_details.client_dscs}
                                    signed_documents={this.props.client_details.signed_documents}
                                />
                            </Tab>
                            <Tab eventKey="internal" title="Internal">
                                <InternalDetails
                                    client_details={this.props.client_details}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        token: state.auth.token,
        client_details: state.client.client_details,
        client_credential: state.client.client_credential,
        client_credentialLoading: state.client.client_credentialLoading,
        client_credentialLoadingSuccess:
            state.client.client_credentialLoadingSuccess,
        client_credentialLoadingMessage:
            state.client.client_credentialLoadingMessage,
    };
}
export default compose(
    withRouter,
    connect(mapStateToProps, actions),
    withStyles(styles)
)(ClientDetails);
