import { Card, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import "./profile.css";
import { Row, Col } from 'react-bootstrap';
import {useLeavesTaken} from '../../hooks/profile-query'
const useStyles = makeStyles({
  root: {
    "& .MuiInput-root" :{
      color:"#FFD700",
      fontWeight:700,
      fontSize:"20px",marginLeft:"10px"
    }
  }

});
    export const Leave  = props =>{
        const classes = useStyles();
        const { data, isLoading } = useLeavesTaken()
    return (
      <div className="body_background" >
        <Card className="Leave" style={{backgroundColor:'#222b36' ,color:'azure' }}>
            {
                isLoading ?
                <p>Loading..</p>
                : <>
                    <Row>
                        <Col><p>Leaves taken:</p></Col>
                        <Col><p>{data}</p></Col>
                    </Row>
                </>
            }
        <Card>

        </Card>
        </Card>
        </div>
    )
    }
