import axios from "axios";
import omit from "lodash/omit";
import find from "lodash/find";
import { BaseUrl } from "./api";
import {
    START_CLIENT_LIST_FETCH,
    CLIENT_LIST_FETCH_SUCCESS,
    CLIENT_LIST_FETCH_FAILURE,
    START_CLIENT_FETCH,
    CLIENT_FETCH_SUCCESS,
    CLIENT_FETCH_FAILED,
    START_CLIENT_ADD,
    CLIENT_ADD_SUCCESS,
    CLIENT_ADD_FAILURE,
    START_CLIENT_DETAILS_FETCH,
    CLIENT_DETAILS_FETCH_SUCCESS,
    CLIENT_DETAILS_FETCH_FAILED,
    START_CLIENT_UPDATE,
    CLIENT_UPDATE_SUCCESS,
    CLIENT_UPDATE_FAILED,
    START_REAUTH,
    DONE_REAUTH,
    FAILED_REAUTH,
    START_CLIENT_GSTIN_FETCH,
    CLIENT_GSTIN_FETCH_SUCCESS,
    CLIENT_GSTIN_FETCH_FAILED,
    SET_CLIENT_LOCATION,
    SET_SELECTED_GSTIN,
} from "./types";
export const updateClient = (id, data, token, nested_attributes) => {
    Object.keys(nested_attributes).forEach(function (element) {
        for (const key of nested_attributes[element]) {
            if (!find(data[element], { id: key.id })) {
                let temp = key;
                temp["_destroy"] = true;
                data[element].push(temp);
            }
        }
    });
    return async (dispatch) => {
        dispatch({
            type: START_CLIENT_UPDATE,
        });
        try {
            const res = await axios.put(
                `${BaseUrl}/client/${id}`,
                { client: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: CLIENT_UPDATE_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: CLIENT_UPDATE_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: CLIENT_UPDATE_FAILED,
                payload: error.message,
            });
        }
    };
};
export const setSelectedGStin = (data) => {
    return async (dispatch) => {
        dispatch({
            type: SET_SELECTED_GSTIN,
            payload: data,
        });
    };
};
export const setClientLocation = (data) => {
    return async (dispatch) => {
        dispatch({
            type: SET_CLIENT_LOCATION,
            payload: data,
        });
    };
};
//get client by id
export const fetchClientGstins = (id, token) => {
    return async (dispatch) => {
        dispatch({ type: START_CLIENT_GSTIN_FETCH });

        try {
            const res = await axios.post(`${BaseUrl}/client/gstin/`, id, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: CLIENT_GSTIN_FETCH_SUCCESS,
                    payload: res.data["gstin"],
                });
            } else {
                dispatch({
                    type: CLIENT_GSTIN_FETCH_FAILED,
                    payload: "client gstin fecth failed",
                });
            }
        } catch (error) {
            dispatch({
                type: CLIENT_GSTIN_FETCH_FAILED,
                payload: error.message,
            });
        }
    };
};

export const fetchClientDetails = (data, token, url) => {
    //url=> client or client_details
    return async (dispatch) => {
        dispatch({
            type: START_CLIENT_DETAILS_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/${url}/${data}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: CLIENT_DETAILS_FETCH_SUCCESS,
                    payload: res.data["client"],
                });
                return res.data["client"];
            } else {
                dispatch({
                    type: CLIENT_DETAILS_FETCH_FAILED,
                    payload: "search client failed",
                });
            }
        } catch (error) {
            dispatch({
                type: CLIENT_DETAILS_FETCH_FAILED,
                payload: "searching client  failed",
            });
        }
    };
};

//client id,name
export const fetchClientList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_CLIENT_LIST_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/client`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: CLIENT_LIST_FETCH_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: CLIENT_LIST_FETCH_FAILURE,
                    payload: "Fetching user failed",
                });
            }
        } catch (error) {
            dispatch({
                type: CLIENT_LIST_FETCH_FAILURE,
                payload: "Fetching user failed",
            });
        }
    };
};

export const fetchClient = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_CLIENT_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/client_list`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: CLIENT_FETCH_SUCCESS,
                    payload: res.data["client"],
                });
            } else {
                dispatch({
                    type: CLIENT_FETCH_FAILED,
                    payload: "Fetching user failed",
                });
            }
        } catch (error) {
            dispatch({
                type: CLIENT_FETCH_FAILED,
                payload: "Fetching user failed",
            });
        }
    };
};

export const addClient = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_CLIENT_ADD,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/client`,
                { client: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: CLIENT_ADD_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: CLIENT_ADD_FAILURE,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: CLIENT_ADD_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const reAuthenticate = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_REAUTH,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/reauth_for_client_cred`,
                { reauth: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data["status"] === "success") {
                dispatch({
                    type: DONE_REAUTH,
                    payload: res["data"]["client"],
                });
                dispatch({
                    type: CLIENT_DETAILS_FETCH_SUCCESS,
                    payload: res.data["client"],
                });
            } else {
                dispatch({
                    type: FAILED_REAUTH,
                    payload: res.errors,
                });
            }
        } catch (error) {
            dispatch({
                type: FAILED_REAUTH,
                payload: error.message,
            });
        }
    };
};
