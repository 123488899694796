import axios from '../../axiosConfig';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// query function
async function getSpecifics() {
  try {
    const res = await axios.get(`/specifics`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}
async function createSpecific(data) {
  try {
    const res = await axios.post(`/specific`, data);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}
async function updateSpecific({ data, id }) {
  try {
    const res = await axios.patch(`/specific/${id}`, data);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

// query hooks

export const useSpecifices = () => useQuery(['specifics'], getSpecifics);

export const useCreateSpecific = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => createSpecific(data), {
    onSuccess: () => queryClient.invalidateQueries('specifics'),
  });
};
export const useUpdateSpecific = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => updateSpecific(form), {
    onSuccess: () => queryClient.invalidateQueries('specifics'),
  });
};
