import { useMutation, QueryClient, useQuery} from "react-query";
import axios from "../../axiosConfig";


const queryClient = new QueryClient({
   defaultOptions: {
     queries: {
       staleTime: Infinity,
     },
   },

})

async function getDocumentCategories(){
    try{
        const res = await axios.get(`/document_categories`);
		return res.data;
    }catch(err){
        throw err.response.data;
    }
}

async function getDscSignatures(){
    try{
        const res = await axios.get(`/dsc_signatures`);
		return res.data;
    }catch(err){
        throw err.response.data;
    }
}

async function addDscSignature(formData) {
	try {
        const res = await axios.post(`/dsc_signature`, formData);
		return res.data;
	} catch (err) {
		throw err.response.data;
	}
}

export const useDscSignatures = () => useQuery('dscSignatures', getDscSignatures)

export const useDocumentCategories = () => useQuery('documentCategories', getDocumentCategories)

export const useDscAdd = () => useMutation(
  (data) => addDscSignature(data),
    {
        onSuccess: (dsc,data) => { queryClient.invalidateQueries('dscSignatures')}
    }
)

