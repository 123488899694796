import { FETCH_EXCHANGE_RATE_START, FETCH_EXCHANGE_RATE_SUCCESS, FETCH_EXCHANGE_RATE_FAILED } from '../actions/types';

const DEFAULT_STATE={
    er: undefined,
    loading: false
}
export default(state=DEFAULT_STATE, action)=>{
    switch(action.type){
        case FETCH_EXCHANGE_RATE_START:
        return{
            ...state,
            er: 1,
            loading: true
        }
        case FETCH_EXCHANGE_RATE_SUCCESS:
            return{
                ...state,
                er: action.payload[0],
                loading: false
            }
        case FETCH_EXCHANGE_RATE_FAILED:
            return{
                ...state,
                er: 1,
                loading: false
            }
        default:
            return state
    }
}

