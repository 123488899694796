import {START_CERTIFICATE_REGISTER_ADD,CERTIFICATE_REGISTER_ADD_DONE,CERTIFICATE_REGISTER_ADD_FAILED,
    CERTIFICATE_REGISTER_LIST_FECH_DONE,CERTIFICATE_REGISTER_LIST_FECH_FAILED,START_CERTIFICATE_REGISTER_LIST_FETCH,FETCH_DOC_URL_START,FETCH_DOC_URL_SUCCESS,FETCH_DOC_URL_FAILED, START_FETCH_CERTIFICATE_REG_BY_ID,FETCH_CERTIFICATE_REG_BY_ID_SUCCESS,FETCH_CERTIFICATE_REG_BY_ID_FAILED,START_CERTIFICATE_REGISTER_UPDATE,CERTIFICATE_REGISTER_UPDATE_FAILED,CERTIFICATE_REGISTER_UPDATE_SUCCESS} from '../actions/types';


const DEFAULT_STATE = {
    docLoading: false,
    docFailed: true,
    docMessage:'',
    docUrl:'',
    certificateRegister: {},//for certficate register edit
    certificateRegisterLoading: false,
    certificateRegisterMessage: "",
    certificateRegisterFailed: true,
    certificateRegisterAddLoading: false,
    certificateRegisterAddMessage: "",
    certificateRegisterAddFailed: true,
    certificateRegisterList:[],
    certificateRegisterFetchMessage:'',
    certificateRegisterFetchListLoading: false
}
export default (state = DEFAULT_STATE,action) => {
    switch(action.type){
        case START_FETCH_CERTIFICATE_REG_BY_ID:
            return{
                ...state,
                certificateRegisterLoading: true,
                certificateRegisterFailed: false,
                certificateRegisterMessage:'',
                certificateRegister:{}
            }
        case FETCH_CERTIFICATE_REG_BY_ID_SUCCESS:
            return{
                ...state,
                certificateRegisterLoading: false,
                certificateRegisterFailed: false,
                certificateRegisterMessage:'',
                certificateRegister: action.payload

            }
        case FETCH_CERTIFICATE_REG_BY_ID_FAILED:
            return{
                ...state,
                certificateRegisterLoading: false,
                certificateRegisterFailed: true,
                certificateRegisterMessage:action.payload,
                certificateRegister: {}

            }

        case FETCH_DOC_URL_START:
            return{
                ...state,
                docLoading: true,
                docFailed:false,
                docUrl: null
            }
        case FETCH_DOC_URL_FAILED:
            return {
                ...state,
                docLoading: false,
                docFailed: true,
                docMessage: action.payload
            }
        case FETCH_DOC_URL_SUCCESS:
            return {
                ...state,
                docLoading: false,
                docFailed: false,
                docUrl: action.payload
            }
        case START_CERTIFICATE_REGISTER_UPDATE:
            return{
                ...state,
                certificateRegisterAddLoading: true,
                certificateRegisterAddFailed:false
            }
        case CERTIFICATE_REGISTER_UPDATE_FAILED:
            return {
                ...state,
                certificateRegisterAddLoading: false,
                certificateRegisterAddFailed: true,
                certificateRegisterAddMessage: action.payload
            }
        case CERTIFICATE_REGISTER_UPDATE_SUCCESS:
            return {
                ...state,
                certificateRegisterAddLoading: false,
                certificateRegisterAddFailed: false,
                certificateRegisterAddMessage: action.payload
            }

        case START_CERTIFICATE_REGISTER_ADD:
            return{
                ...state,
                certificateRegisterAddLoading: true,
                certificateRegisterAddFailed:false
            }
        case CERTIFICATE_REGISTER_ADD_FAILED:
            return {
                ...state,
                certificateRegisterAddLoading: false,
                certificateRegisterAddFailed: true,
                certificateRegisterAddMessage: action.payload
            }
        case CERTIFICATE_REGISTER_ADD_DONE:
            return {
                ...state,
                certificateRegisterAddLoading: false,
                certificateRegisterAddFailed: false,
                certificateRegisterAddMessage: action.payload
            }
        case START_CERTIFICATE_REGISTER_LIST_FETCH:
            return {
                ...state,
                certificateRegisterFetchListLoading:true,
                certificateRegisterFetchMessage:''
            }
        case CERTIFICATE_REGISTER_LIST_FECH_DONE:
            return {
                ...state,
                certificateRegisterFetchListLoading:false,
                certificateRegisterList:action.payload
            }
        case CERTIFICATE_REGISTER_LIST_FECH_FAILED:
            return {
                ...state,
                certificateRegisterFetchListLoading:false,
                certificateRegisterFetchMessage:action.payload
            }
        default:
            return state
    }
}
