import {START_ADD_AUDIT_DOCUEMNT_REGISTER,ADD_AUDIT_DOCUMENT_REGISTER_FAILED,ADD_AUDIT_DOCUMENT_REGISTER_SUCCESS, FETCH_AUDIT_DOCUMENT_REGISTER_START,FETCH_AUDIT_DOCUMENT_REGISTER_SUCCESS,FETCH_AUDIT_DOCUMENT_REGISTER_FAILED} from '../actions/types';
const DEFAULT_STATE={
    auditDocumentList:[],
    loading: false,
    message:'',
    auditDocumentAddFailed:true
}
export default(state=DEFAULT_STATE,action)=>{
    switch(action.type){
        case START_ADD_AUDIT_DOCUEMNT_REGISTER:
            return{
                ...state,
                loading: true,
                message:'',
                auditDocumentAddFailed: false
            }
        case ADD_AUDIT_DOCUMENT_REGISTER_SUCCESS:
            return{
                ...state,
                loading: false,
                message: action.payload,
                auditDocumentAddFailed: false
            }
        case ADD_AUDIT_DOCUMENT_REGISTER_FAILED:
            return{
                ...state,
                loading: false,
                message: action.payload,
                auditDocumentAddFailed: true
            }
        case FETCH_AUDIT_DOCUMENT_REGISTER_START:
            return{
                ...state,
                loading: true,
                message:''
            }
        case FETCH_AUDIT_DOCUMENT_REGISTER_SUCCESS:
            return{
                ...state,
                loading: false,
                auditDocumentList: action.payload
            }
        case FETCH_AUDIT_DOCUMENT_REGISTER_FAILED:
            return{
                ...state,
                loading: false,
                message: action.payload
            }
        default:
            return state
    }
}
