import {
    START_FETCH_NOTIFICATION,
    SUCCESS_NOTIFICATION_FETCH,
    FAILED_NOTIFICATION_FETCH,
    NEW_NOTIFICATION_RECEIVED
} from "../actions/types";

const DEFAULT_STATE = {
    notificationList: [],
    errorMessage:''
}
export default (state = DEFAULT_STATE,action) => {
    switch(action.type){
        case START_FETCH_NOTIFICATION:
            return{
                ...state,
                notificationList: [],
                errorMessage:''
            }
        case SUCCESS_NOTIFICATION_FETCH:
            return{
                ...state,
                notificationList: action.payload,
                errorMessage:''
            }
        case FAILED_NOTIFICATION_FETCH:
            return{
                ...state,
                notificationList: [],
                errorMessage:action.payload
            }
        case NEW_NOTIFICATION_RECEIVED:
            return{
                ...state,
                notificationList: [action.payload, ...state.notificationList]
            }
        default:
            return state
    }
}


