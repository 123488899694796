export const styles = {
	body: {
		margin: '0 30px'
	},
	infoContainer: {
		margin: '20px',
		border: '1px solid',
		padding: '10px 20px',
		borderRadius: '10px',
	},
	infoHeading: {
		marginBottom: '10px',
		borderBottom: '1px solid #cccccc',
		paddingBottom: '5px'
	},
	infoSectionContainer: {
		display: 'grid',
		gridTemplateColumns: 'auto auto',
	},
	info: {
		display: 'grid',
		width: '100%',
		gridTemplateColumns: 'auto auto',
		textAlign: 'left'
	},
	contactAccordionContainer: {
		border: '1px solid',
		padding: '10px 20px',
		borderRadius: '10px',
		marginTop: '5px',
	},
	contactAccordion: {
		display: 'flex',
		justifyContent: 'space-between',
		cursor: 'pointer',
		width: '100%',
	},
}