import {START_ADD_PROJECT_SUPER_CLASS, ADD_PROJECT_SUPER_CLASS_SUCCESS,ADD_PROJECT_SUPER_CLASS_FAILED,START_FETCH_PROJECT_SUPER_CLASS,FETCH_PROJECT_SUPER_CLASS_SUCCESS,FETCH_PROJECT_SUPER_CLASS_FAILED} from "../actions/types";

const DEFAULT_STATE ={
    dprojectCategoryList:[],
    loading: false,
    errorMessage:'',
    message:'',
    projectCategoryAddLoading:false,
    projectCategoryAddFailed:true
}

export default (state =DEFAULT_STATE,action)=>{
    switch(action.type){

        case START_ADD_PROJECT_SUPER_CLASS:
            return{
                ...state,
                projectCategoryAddLoading:true,
                projectCategoryAddFailed:false,
                message:''
            }

        case ADD_PROJECT_SUPER_CLASS_SUCCESS:
            return{
                ...state,
                projectCategoryAddLoading:false,
                projectCategoryAddFailed:false,
                loading:false,
                message:action.payload
            }
        case ADD_PROJECT_SUPER_CLASS_FAILED:
            return{
                ...state,
                projectCategoryAddLoading:false,
                projectCategoryAddFailed:true,
                loading:false,
                message:action.payload
            }
        case START_FETCH_PROJECT_SUPER_CLASS:
            return{
                ...state,
                loading:true
            }

        case FETCH_PROJECT_SUPER_CLASS_SUCCESS:
            return{
                ...state,
                loading:false,
                dprojectCategoryList:action.payload
            }
        case FETCH_PROJECT_SUPER_CLASS_FAILED:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        default :
            return state

    }
}
