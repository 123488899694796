import axios from "../../axiosConfig";
import { useQuery,useMutation, useQueryClient} from "react-query";
async function updatePassword(data){
    try{
        const res = await axios.patch(`/profile/change_password`,{user: data});
        return res.data
    }catch(err){
        throw err.response.data;
    }
}
async function getLifeCycleDetails(){
    try{
        const res = await axios.get(`/profile/life_cycle`);
        return res.data.life_cycle;
    }catch(err){
        throw err.response.data;
    }
}
async function getLeavesTaken(){
    try{
        const res = await axios.get(`/profile/leaves_taken`);
        return res.data.count;
    }catch(err){
        throw err.response.data;
    }
}
async function getMyFirmAssets(){
    try{
        const res = await axios.get(`/profile/my_firm_assets`);
        return res.data;
    }catch(err){
        throw err.response.data;
    }
}

async function getIcaiDetails(){
    try{
        const res = await axios.get(`/profile/icai_article`);
        return res.data;
    }catch(err){
        throw err.response.data;
    }
}
async function updateProfilePic({ data }){
    axios['defaults']['headers']["Content-Type"] = 'application/x-www-form-urlencoded'
    let formData = new FormData()
    formData.append(`user[avatar]`, data.avatar)
    try{
        const res = await axios.patch(`/profile/avatar/${data.id}`, formData);
        return res.data
    }catch(err){
        throw err.response.data;
    }
}

export const useAvatarUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation(
    (data) => updateProfilePic(data),
        {
            onSuccess: () => { }
        }
)}

export const useIcai = () => useQuery('icai', getIcaiDetails,{
    refetchOnWindowFocus: false,
})
export const useLifeCycle =() => useQuery('life_cycle', getLifeCycleDetails,{
    refetchOnWindowFocus: false,
})
export const useLeavesTaken =() => useQuery('leaves_taken', getLeavesTaken,{
    refetchOnWindowFocus: false,
})

export const usePasswordUpdate = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => updatePassword(data),{})
}
export const useFirmAssetinMyHand =() => useQuery('my_firm_assets', getMyFirmAssets,{
    refetchOnWindowFocus: false,
})

