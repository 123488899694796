import {CLIENT_LIST_FETCH_SUCCESS,CLIENT_LIST_FETCH_FAILURE,
    START_CLIENT_LIST_FETCH,CLIENT_FETCH_SUCCESS,CLIENT_FETCH_FAILED,START_CLIENT_FETCH,
START_CLIENT_ADD,CLIENT_ADD_SUCCESS,CLIENT_ADD_FAILURE,CLIENT_DETAILS_FETCH_SUCCESS,
    CLIENT_DETAILS_FETCH_FAILED,START_CLIENT_DETAILS_FETCH,
    CLIENT_UPDATE_FAILED,CLIENT_UPDATE_SUCCESS,START_CLIENT_UPDATE,START_REAUTH,DONE_REAUTH,FAILED_REAUTH,
    START_CLIENT_GSTIN_FETCH, CLIENT_GSTIN_FETCH_SUCCESS, CLIENT_GSTIN_FETCH_FAILED,SET_CLIENT_LOCATION,SET_SELECTED_GSTIN
} from "../actions/types";
const DEFAULT_STATE ={
    dclientList:[],
    clientList:[],
    gstinList:[],
    clientLocation:'',
    client:[],
    client_credential:[],
    client_credentialLoading: false,
    client_credentialLoadingFailed: true,
    client_credentialLoadingSuccess:false,
    client_credentialLoadingMessage: '',
    client_details:[],
    clientAddFailed:true,
    clientAddLoading: false,
    errorMessage:'',
    clientAddMessage:'',
    selected_gstin:{},
    clientDetailsFetchLoading: false
};

export default (state =DEFAULT_STATE,action)=>{
    switch(action.type){
        case START_REAUTH:
            return {
                ...state,
                client_credentialLoading: true,
                client_credential:[],
                client_credentialLoadingSuccess:false,
                client_credentialLoadingMessage:"Veryfying and loading credential"
            }
        case DONE_REAUTH:
            return {
                ...state,
                client_credentialLoading: false,
                client_credentialLoadingSuccess:true,
                client_details: action.payload,
                client_credential: action.payload,
                client_credentialLoadingMessage:"Veryfied and loaded credential"
            }
        case FAILED_REAUTH:
            return {
                ...state,
                client_credentialLoading: false,
                client_credential:[],
                client_credentialLoadingMessage: action.payload

            }
        case START_CLIENT_DETAILS_FETCH:
            return{
                ...state,
                clientDetailsFetchLoading:true,
                client_details:[]
            }
        case CLIENT_DETAILS_FETCH_SUCCESS:
            return{
                ...state,
                clientDetailsFetchLoading: false,
                client_details :action.payload
            }
        case CLIENT_DETAILS_FETCH_FAILED:
            return{
                ...state,
                clientDetailsFetchLoading: false,
                client_details:[]
            }

        case START_CLIENT_UPDATE:
            return{
                ...state,
                clientAddLoading:true,
                clientAddFailed:false,
                clientAddMessage:''
            }
        case CLIENT_UPDATE_SUCCESS:
            return{
                ...state,
                clientAddLoading:false,
                clientAddFailed:false,
                clientAddMessage:action.payload
            }

        case CLIENT_UPDATE_FAILED:
            return{
                ...state,
                clientAddFailed:true,
                clientAddLoading:false,
                clientAddMessage:action.payload

            }
        case START_CLIENT_ADD:
            return{
                ...state,
                clientAddLoading:true,
                clientAddFailed:false,
                clientAddMessage:''
            }

        case CLIENT_ADD_SUCCESS:
            return{
                ...state,
                clientAddLoading:false,
                clientAddFailed:false,
                clientAddMessage:action.payload
            }
        case CLIENT_ADD_FAILURE:
            return{
                ...state,
                clientAddFailed:true,
                clientAddLoading:false,
                clientAddMessage:action.payload

            }
        case START_CLIENT_FETCH:
            return{
                ...state,
                loading:true
            }

        case CLIENT_FETCH_SUCCESS:
            return{
                ...state,
                loading:false,
                client:action.payload
            }
        case CLIENT_FETCH_FAILED:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        case START_CLIENT_GSTIN_FETCH:
            return{
                ...state,
                gstinList:[]
            }
        case CLIENT_GSTIN_FETCH_SUCCESS:
            return{
                ...state,
                gstinList:action.payload
            }
        case CLIENT_GSTIN_FETCH_FAILED:
            return{
                ...state,
                gstinList:[]
            }
        case START_CLIENT_LIST_FETCH:
            return{
                ...state,
                loading:true
            }

        case CLIENT_LIST_FETCH_SUCCESS:
            return{
                ...state,
                loading:false,
                dclientList:action.payload
            }
        case CLIENT_LIST_FETCH_FAILURE:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        case SET_CLIENT_LOCATION:
            return{
                ...state,
                clientLocation: action.payload
            }
        case SET_SELECTED_GSTIN:
            return{
                ...state,
                selected_gstin: action.payload
            }
        default :
            return state

    }
}
