import {
  NEW_COMMENT_RECEIVED,
  START_PROJECT_DIARY_FETCH,
  START_PROJECT_DIARY_ADD,
  PROJECT_DIARY_FETCH_SUCCESS,
  PROJECT_DIARY_FETCH_FAILED,
  PROJECT_DIARY_ADD_SUCCESS,
  PROJECT_DIARY_ADD_FAILED,
  CLOSE_NEW_COMMENT_ALERT,
  PROJECT_SPECIFIC_FETCH,
  PROJECT_SPECIFIC_SUCCESS,
  PROJECT_SPECIFIC_FAILED,
} from "../actions/types";
const DEFAULT_STATE = {
  comments: [],
  commentAddFailed: true,
  commentAddLoading: false,
  commentFetchLoading: false,
  commentAddMessage: "",
  showCommentAlert: false,
  specifics: [],
};
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case PROJECT_DIARY_ADD_SUCCESS:
      return {
        ...state,
        commentAddFailed: false,
        commentAddMessage: action.payload,
      };
    case PROJECT_DIARY_ADD_FAILED:
      return {
        ...state,
        commentAddFailed: true,
        commentAddMessage: action.payload,
      };
    case START_PROJECT_DIARY_ADD:
      return {
        ...state,
        commentAddFailed: false,
        commentAddMessage: "",
        commentAddLoading: false,
      };
    case NEW_COMMENT_RECEIVED:
      return {
        ...state,
        showCommentAlert: true,
        comments: [...state.comments, action.payload],
      };
    case CLOSE_NEW_COMMENT_ALERT:
      return {
        ...state,
        showCommentAlert: false,
      };
    case START_PROJECT_DIARY_FETCH:
      return {
        ...state,
        comments: [],
        commentFetchLoading: true,
      };
    case PROJECT_DIARY_FETCH_SUCCESS:
      return {
        ...state,
        comments: action.payload,
        commentFetchLoading: false,
      };
    case PROJECT_DIARY_FETCH_FAILED:
      return {
        ...state,
        comments: [],
        commentFetchLoading: false,
      };

    case PROJECT_SPECIFIC_FETCH:
      return {
        ...state,
        specifics: [],
      };
    case PROJECT_SPECIFIC_SUCCESS:
      return {
        ...state,
        specifics: action.payload,
      };
    case PROJECT_SPECIFIC_FAILED:
      return {
        ...state,
        specifics: [],
      };

    default:
      return state;
  }
};
