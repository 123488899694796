import {START_PROJECT_FETCH_DETAILS_LIST,PROJECT_FETCH_SUCCESS_DETAILS_LIST,PROJECT_FETCH_FAILED_DETAILS_LIST,PROJECT_FETCH_SUCCESS,PROJECT_FETCH_FAILED,START_PROJECT_FETCH,START_PROJECT_ADD,PROJECT_ADD_SUCCESS,
    PROJECT_ADD_FAILURE,START_PROJECT_FETCH_BY_ID,PROJECT_FETCH_BY_ID_SUCCESS,PROJECT_FETCH_BY_ID_FAILED,
    PROJECT_UPDATE_SUCCESS,PROJECT_UPDATE_FAILED,START_PROJECT_UPDATE,FETCH_CLIENT_PROJECT_START,FETCH_CLIENT_PROJECT_SUCCESS,FETCH_CLIENT_PROJECT_FAILED,START_SUBMIT_FOR_ARCHIVEL,SUBMIT_FOR_ARCHIVEL_SUCCESS,SUBMIT_FOR_ARCHIVEL_FAILED, FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_START,FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_FAILED,FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_SUCCESS, APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_START,APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_SUCCESS,APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_FAILED, UPDATE_PROJECT_STATUS_TO_IN_PROGRESS_LOCALLY, ADD_BACK_TO_AVAILABLE_PROJECTS, DELETE_FROM_AVILABLE_PROJECTS
} from "../actions/types";
const DEFAULT_STATE ={
    dprojectList:[],
    projectList:[],
    project:[],
    clientProjects: [],
    availableClientProjects: [],
    invoiceProjectsSelected: [],// for useing in ope fetch invoice
    loading: false,
    errorMessage:'',
    projectAddFailed:true,
    projectAddLoading: false,
    projectAddMessage:'',
    archivelMessage:'',
    autoApproveMsg: '',
    autoApproveLoading: false,
    autoApproveError: '',
    archivelSubmitLoading: false,
    archivelSubmissionFailed: true
};

export default (state =DEFAULT_STATE,action)=>{
    switch(action.type){
        case DELETE_FROM_AVILABLE_PROJECTS:
            return{
                ...state,
                availableClientProjects: state.availableClientProjects.map(item=> item.id === action.payload ?
                    {...item, color: "#ff0000"} : item
                )
                //availableClientProjects: state.availableClientProjects.filter(item =>item.id !== action.payload),
                //invoiceProjectsSelected: [...state.invoiceProjectsSelected, action.payload]//scenario where project is slected
            }
        case ADD_BACK_TO_AVAILABLE_PROJECTS:
            return{
                ...state,
                availableClientProjects: state.availableClientProjects.map(item=> item.id === action.payload.id ?
                    {...item, color: "#000000"} : item
                )
                //availableClientProjects: [...state.availableClientProjects, action.payload],
                //invoiceProjectsSelected: state.invoiceProjectsSelected.filter(item=> item !== action.payload.id)
            }
        case START_SUBMIT_FOR_ARCHIVEL:
            return{
                ...state,
                archivelMessage:'',
                archivelSubmitLoading: true,
                archivelSubmissionFailed: false
            }
        case SUBMIT_FOR_ARCHIVEL_SUCCESS:
            return {
                ...state,
                archivelMessage: action.payload,
                archivelSubmitLoading: false,
                archivelSubmissionFailed: false
            }
        case SUBMIT_FOR_ARCHIVEL_FAILED:
            return{
                ...state,
                archivelMessage: action.payload,
                archivelSubmitLoading: false,
                archivelSubmissionFailed: true
            }
        case FETCH_CLIENT_PROJECT_START:
            return{
                ...state,
                clientProjects:[],
                availableClientProjects: [],
                invoiceProjectsSelected: []
            }
        case FETCH_CLIENT_PROJECT_SUCCESS:
            return{
                ...state,
                clientProjects: action.payload,
                availableClientProjects: action.payload
            }
        case FETCH_CLIENT_PROJECT_FAILED:
            return{
                ...state,
                clientProjects:[]
            }
        case START_PROJECT_UPDATE:
            return{
                ...state,
                projectAddFailed: false,
                projectAddloading: true,
                projectAddMessage: ""
            }
        case PROJECT_UPDATE_SUCCESS:
            return {
                ...state,
                projectAddloading: false,
                projectAddFailed: false,
                projectAddMessage: action.payload
            }
        case PROJECT_UPDATE_FAILED:
            return{
                ...state,
                projectAddloading: false,
                projectAddFailed: true,
                projectAddMessage: action.payload
            }
        case START_PROJECT_ADD:
            return{
                ...state,
                projectAddloading:true,
                projectAddFailed:false,
                projectAddMessage:''
            }

        case PROJECT_ADD_SUCCESS:
            return{
                ...state,
                projectAddloading:false,
                projectAddFailed:false,
                projectAddMessage:action.payload
            }
        case PROJECT_ADD_FAILURE:
            return{
                ...state,
                projectAddFailed:true,
                projectAddLoading:false,
                projectAddMessage:action.payload

            }

        case START_PROJECT_FETCH_BY_ID:
            return{
                ...state,
                loading:true
            }

        case PROJECT_FETCH_BY_ID_SUCCESS:
            return{
                ...state,
                loading:false,
                project:action.payload
            }
        case PROJECT_FETCH_BY_ID_FAILED:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        case FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_START:
            return{
                ...state,
                loading:true,
                projectList: [],
                errorMessage: ''
            }
        case FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_FAILED:
            return{
                ...state,
                loading:false,
                projectList: [],
                errorMessage: action.payload
            }
        case FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_SUCCESS:
            return{
                ...state,
                loading: false,
                projectList: action.payload,
                errorMessage: ''
            }
        case APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_START:
                return{
                    ...state,
                    autoApproveLoading: true,
                    autoApproveError: '',
                    autoApproveMsg: ''
                }
        case APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_SUCCESS:
                return{
                    ...state,
                    autoApproveLoading: false,
                    projectList: state.projectList.filter(item =>item.id !== action.payload),
                    autoApproveMsg: 'Successfully perform the action!!'
                }
        case APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_FAILED:
                return{
                    ...state,
                    autoApproveLoading: false,
                    autoApproveError: action.payload,
                }
        case START_PROJECT_FETCH_DETAILS_LIST:
            return{
                ...state,
                loading:true,
                projectList: []
            }

        case PROJECT_FETCH_SUCCESS_DETAILS_LIST:
            return{
                ...state,
                loading:false,
                projectList:action.payload
            }
        case PROJECT_FETCH_FAILED_DETAILS_LIST:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        case START_PROJECT_FETCH:
            return{
                ...state,
                loading:true,
                errorMessage:''
            }

        case PROJECT_FETCH_SUCCESS:
            return{
                ...state,
                loading:false,
                dprojectList:action.payload
            }
        case PROJECT_FETCH_FAILED:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        case UPDATE_PROJECT_STATUS_TO_IN_PROGRESS_LOCALLY:
            let index = state.projectList.findIndex(item=>(item.id === action.payload && item.status === "Yet To Commence"))
            if(index){
                let projectList = [...state.projectList]
                projectList[index] = {...projectList[index], status: "In Progress"}
                return{
                    ...state,projectList
                }
            }else{
                return state
            }
        default :
            return state

    }
}
