import React, { Component, View } from "react";
import { Row, Col, Container, Badge, Table } from "react-bootstrap";
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as actions from "../../actions";
import { NewCommentAlert } from "../../utils/formComponents";
import moment from "moment";
//import { intializeSocket  } from '../../actions/webSocketAction';
import { connect } from "react-redux";
import {
  InputText,
  UpdateDialog,
  SavingDetailsModal,
} from "../../utils/formComponents";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import Header from "../../components/Layout/Header/Header";
import styles from "../modelstyles";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
class ProjectDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      id: 0,
      showStatusForm: false,
      showDueDateForm: false,
      showProjectStatusForm: false,
      due_date: new Date(),
      old_due_date: new Date(),
      old_activity_status: "Closed",
      activity_status: "Closed",
      old_project_status: "",
      project_status: "YetToCommence",
      status: "",
      archivelSubmitLoading: false,
      archivelSubmissionFailed: true,
    };
    if (this.props.location.data) {
      this.props.fetchProjectActivity(
        this.props.location.data.project_data["id"],
        this.props.token
      );
      this.props.setProjectDashboard(this.props.location.data.project_data);
    } else {
      this.props.fetchProjectActivity(
        this.props.projectData["id"],
        this.props.token
      );
    }
    this.getDaysLeft = this.getDaysLeft.bind(this);
    this.closeCommentAlert = this.closeCommentAlert.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDueDateChange = this.handleDueDateChange.bind(this);
    this.handleProjectStatusChange = this.handleProjectStatusChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.updateDueDate = this.updateDueDate.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.updateProjectStatus = this.updateProjectStatus.bind(this);
    this.submitForArchivel = this.submitForArchivel.bind(this);
    this.closeArchivelSubmissionAlert =
      this.closeArchivelSubmissionAlert.bind(this);
  }
  closeCommentAlert() {
    this.props.closeCommentAlert();
  }
  handleDueDateChange(e) {
    this.setState({
      due_date: e.target.value,
    });
  }
  handleProjectStatusChange(e) {
    this.setState({
      project_status: e.target.value,
    });
  }
  getCommentStyle(user) {
    if (user === "Auto") {
      return { color: "#A9A9A9" };
    } else {
      return { color: "white" };
    }
  }
  handleStatusChange(e) {
    this.setState({
      activity_status: e.target.value,
    });
  }
  updateDueDate() {
    this.setState({
      showDueDateForm: false,
    });
    let d1 = new Date(this.state.due_date);
    let d2 = new Date(this.state.old_due_date);
    if (d1.getTime() !== d2.getTime()) {
      this.props.updateDueDate(
        this.state.id,
        {
          project_id: this.props.projectData["id"],
          due_date: this.state.due_date,
        },
        this.props.token
      );
    }
  }
  updateProjectStatus() {
    this.setState({
      showProjectStatusForm: false,
    });
    if (
      this.state.project_status !==
      this.state.old_project_status.replace(/\s/g, "")
    ) {
      this.props
        .updateProjectStatus(
          this.props.projectData["id"],
          {
            status: this.state.project_status,
          },
          this.props.token
        )
        .then((resp) => {
          if (resp.status) {
            this.setState({
              status: this.state.project_status,
            });
          }
        });
    }
  }
  submitForArchivel() {
    this.setState({
      archivelSubmitLoading: true,
    });
    this.props
      .submitForArchivel(
        this.props.projectData["id"],
        {
          status: "Pending_archival",
        },
        this.props.token
      )
      .then((resp) => {
        if (resp.status) {
          this.setState({
            status: this.state.project_status,
          });
        }
      });
  }
  updateStatus() {
    this.setState({
      showStatusForm: false,
    });
    if (this.state.activity_status !== this.state.old_activity_status) {
      this.props.updateActivityStatus(
        this.state.id,
        {
          project_id: this.props.projectData["id"],
          activity_status: this.state.activity_status,
        },
        this.props.token
      );
    }
  }
  closeForm() {
    this.setState({
      showStatusForm: false,
      showDueDateForm: false,
      showProjectStatusForm: false,
    });
  }
  componentDidMount() {
    if (this.props.location.data) {
      this.props.initializeSocket(
        this.props.token,
        this.props.location.data.project_data["id"]
      );
      this.props.fetchProjectDiary(
        this.props.location.data.project_data["id"],
        this.props.token
      );
      this.props.fetchProjectSpecific(
        this.props.location.data.project_data["id"],
        this.props.token
      );
      this.setState({
        status: this.props.location.data.project_data["status"],
      });
    } else {
      this.props.initializeSocket(
        this.props.token,
        this.props.projectData["id"]
      );
      this.props.fetchProjectDiary(
        this.props.projectData["id"],
        this.props.token
      );
      this.props.fetchProjectSpecific(
        this.props.projectData["id"],
        this.props.token
      );
      this.setState({
        status: this.props.projectData["status"],
      });
    }
  }
  componentWillUnmount() {
    this.props.unsubscribeSocket(this.props.cable, this.props.channel);
  }
  onsubmit(event) {
    if (Boolean(this.state.comment)) {
      this.props
        .addProjectDiary(
          {
            comment: this.state.comment,
            project_id: this.props.projectData["id"],
          },
          this.props.token
        )
        .then((resp) => {
          if (resp) {
            this.setState({
              comment: "",
            });
          }
        });
    }
    event.preventDefault();
  }
  componentDidUpdate() {
    if (!this.state.archivelSubmissionFailed) {
      this.props.history.goBack();
    }
  }
  closeArchivelSubmissionAlert() {
    this.setState({
      archivelSubmissionFailed: this.props.archivelSubmissionFailed,
      archivelSubmitLoading: this.props.archivelSubmitLoading,
    });
  }
  handleChange(event) {
    this.setState({ comment: event.target.value });
  }
  getDaysLeft(due_date) {
    let d_date = due_date.split("-");
    let t = new Date();
    let diff = moment([
      Number(d_date[0]),
      Number(d_date[1]) - 1,
      Number(d_date[2]),
    ]).diff(moment([t.getFullYear(), t.getMonth(), t.getDate()]), "days");
    if (diff < 0) {
      return diff;
    } else {
      return diff + 1;
    }
  }
  render() {
    const { classes } = this.props;
    console.log(this.props.specifics);
    return (
      <Container className={classes.scroll}>
        <Header title={"projectdashboard"} />
        <Container className={classes.topPart}>
          <Row className={classes.dashboardTopBoxValues}>
            <Col>
              Project Name :{" "}
              <span className={classes.dashboardSpanColor}>
                {this.props.projectData["name"]}
              </span>
            </Col>
            <Col>
              Due Date :{" "}
              <span className={classes.dashboardSpanColor}>
                {this.props.projectData["due_date"]}
              </span>
            </Col>
          </Row>
          <Row className={classes.dashboardTopBoxValues}>
            <Col>
              Client Name :{" "}
              <span className={classes.dashboardSpanColor}>
                {this.props.projectData["client_name"]}
              </span>
            </Col>
            <Col>
              Project Status:{" "}
              <Button
                onClick={() => {
                  this.setState({
                    showProjectStatusForm: true,
                    id: this.props.projectData["id"],
                    project_status: this.state.status,
                    old_project_status: this.state.status,
                  });
                }}
                color="primary"
                variant="contained"
              >
                {this.state.status}
              </Button>
            </Col>
          </Row>
          <Row className={classes.dashboardTopBoxValues}>
            <Col>
              Engagement Partner:{" "}
              <span className={classes.dashboardSpanColor}>
                {this.props.projectData["member"] == undefined ||
                this.props.projectData["member"][0] == undefined
                  ? ""
                  : this.props.projectData["member"][0]["eng_partner"]}{" "}
              </span>
            </Col>
            <Col>
              Team Lead:{" "}
              <span className={classes.dashboardSpanColor}>
                {this.props.projectData["member"] == undefined ||
                this.props.projectData["member"][0] == undefined
                  ? ""
                  : this.props.projectData["member"][0]["lead_name"]}
              </span>
            </Col>
          </Row>
          <Row className={classes.dashboardTopBoxValues}>
            {this.props.projectData["occurence"] == undefined ? null : (
              <Col>
                Occurence:{" "}
                <span className={classes.dashboardSpanColor}>
                  {this.props.projectData["occurence"]}
                </span>
              </Col>
            )}
            <Col>
              <Button
                onClick={this.submitForArchivel}
                color="primary"
                variant="contained"
              >
                Submit for archival
              </Button>
            </Col>
          </Row>
        </Container>
        <Row></Row>
        <NewCommentAlert
          open={this.props.showAlert}
          handleClose={this.closeCommentAlert}
        />
        <Row>
          <Col
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
            }}
          >
            <Row>
              <Col style={{ width: "50%" }}>
                <h3>
                  <Badge variant="dark">Project Activity</Badge>
                </h3>
              </Col>
              <Col
                className="d-flex justify-content-end"
                style={{ width: "50%" }}
              >
                <Link
                  to={{
                    pathname: "/addprojectactivity",
                    data: {
                      id: this.props.projectData["id"],
                    },
                  }}
                >
                  <IconButton color="primary" aria-label="add">
                    <Icon>add_plus</Icon>
                  </IconButton>
                </Link>
              </Col>
            </Row>
            <Row>
              <Table responsive striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Activity No</th>
                    <th>Activity Name</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Days Left</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.projectActivityList &&
                  this.props.projectActivityList.length
                    ? this.props.projectActivityList.map((item, key) => (
                        <tr>
                          <td>{item.activity_no}</td>
                          <td>{item.activity_short_description}</td>
                          <td>{item.activity_long_description}</td>
                          <td>
                            {" "}
                            <Button
                              onClick={() => {
                                this.setState({
                                  showStatusForm: true,
                                  id: item.id,
                                  old_activity_status: item.activity_status,
                                  activity_status: item.activity_status,
                                });
                              }}
                              color="primary"
                              variant="contained"
                            >
                              {item.activity_status}
                            </Button>
                          </td>
                          <td>
                            {" "}
                            <Button
                              onClick={() => {
                                this.setState({
                                  showDueDateForm: true,
                                  id: item.id,
                                  old_due_date: item.due_date,
                                  due_date: item.due_date,
                                });
                              }}
                              color="primary"
                              variant="contained"
                            >
                              {this.getDaysLeft(item.due_date)}
                            </Button>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Row>
            <UpdateDialog
              open={this.state.showDueDateForm}
              name="due_date"
              title="Update Due Date"
              description="Please select new due date"
              handleClose={this.closeForm}
              update={this.updateDueDate}
              value={this.state.due_date}
              handleChange={this.handleDueDateChange}
            />
            <UpdateDialog
              open={this.state.showStatusForm}
              name="activity_status"
              title="Update activity status"
              description="Please select new status"
              handleClose={this.closeForm}
              update={this.updateStatus}
              value={this.state.activity_status}
              handleChange={this.handleStatusChange}
            />
            <UpdateDialog
              open={this.state.showProjectStatusForm}
              name="project_status"
              title="Update project status"
              description="Please select new status"
              handleClose={this.closeForm}
              update={this.updateProjectStatus}
              value={this.state.project_status}
              handleChange={this.handleProjectStatusChange}
              user={this.props.user}
            />

            <SavingDetailsModal
              className={classes.modal}
              show={this.props.updateLoading}
              title={"Updating activity"}
              loading={this.props.updateLoading}
              message={this.props.updateMessage}
            />
            <SavingDetailsModal
              className={classes.modal}
              show={this.state.archivelSubmitLoading}
              title={"Submitting for archivel"}
              loading={this.props.archivelSubmitLoading}
              message={this.props.archivelMessage}
              onClick={this.closeArchivelSubmissionAlert}
            />
          </Col>
          <Col
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
            }}
          >
            <Row>
              {" "}
              <h3>
                <Badge variant="dark">Project Diary</Badge>
              </h3>
            </Row>
            <Row>
              <Table responsive striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.comments && this.props.comments.length
                    ? this.props.comments.map((item, key) => (
                        <tr>
                          <td>
                            <Row
                              className="m-1"
                              style={this.getCommentStyle(item.user)}
                            >
                              {item.user}
                            </Row>
                            <Row
                              className="m-1"
                              style={this.getCommentStyle(item.user)}
                            >
                              {item.created_at}
                            </Row>
                          </td>
                          <td style={this.getCommentStyle(item.user)}>
                            {item.comment}
                          </td>
                        </tr>
                      ))
                    : null}
                  <tr>
                    <td></td>
                    <td>
                      <form onSubmit={this.onsubmit}>
                        <Row>
                          <Col>
                            <label>
                              <input
                                value={this.state.comment}
                                type="text"
                                onChange={this.handleChange}
                              />

                              <input type="submit" value="Add Comment" />
                            </label>
                          </Col>
                        </Row>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
            }}
          >
            <Row>
              <Col style={{ width: "50%" }}>
                <h3>
                  <Badge variant="dark">Project Activity</Badge>
                </h3>
              </Col>
              <Col
                className="d-flex justify-content-end"
                style={{ width: "50%" }}
              >
                <Link
                  to={{
                    pathname: "/addprojectactivity",
                    data: {
                      id: this.props.projectData["id"],
                    },
                  }}
                >
                  <IconButton color="primary" aria-label="add">
                    <Icon>add_plus</Icon>
                  </IconButton>
                </Link>
              </Col>
            </Row>
            <Row>
              <Table responsive striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Value</th>
                    <th>Status</th>
                    <th>Prepare</th>
                    <th>Reviewer</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.specifics && this.props.specifics.length
                    ? this.props.specifics.map((item, key) => (
                        <tr>
                          <td>{item.description}</td>
                          <td>{item.value}</td>
                          <td>{item.status}</td>
                          <td>{item.preparer_members[0]}</td>
                          <td>{item.reviewer_members[0]}</td>
                          <td>
                            <Button
                              onClick={() => {
                                this.setState({
                                  showStatusForm: true,
                                  id: item.id,
                                  old_activity_status: item.activity_status,
                                  activity_status: item.activity_status,
                                });
                              }}
                              color="primary"
                              variant="contained"
                            >
                              SIGN OFF
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                this.setState({
                                  showDueDateForm: true,
                                  id: item.id,
                                  old_due_date: item.due_date,
                                  due_date: item.due_date,
                                });
                              }}
                              color="primary"
                              variant="contained"
                            >
                              EDIT
                            </Button>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </Row>
            <UpdateDialog
              open={this.state.showDueDateForm}
              name="due_date"
              title="Update Due Date"
              description="Please select new due date"
              handleClose={this.closeForm}
              update={this.updateDueDate}
              value={this.state.due_date}
              handleChange={this.handleDueDateChange}
            />
            <UpdateDialog
              open={this.state.showStatusForm}
              name="activity_status"
              title="Update activity status"
              description="Please select new status"
              handleClose={this.closeForm}
              update={this.updateStatus}
              value={this.state.activity_status}
              handleChange={this.handleStatusChange}
            />
            <UpdateDialog
              open={this.state.showProjectStatusForm}
              name="project_status"
              title="Update project status"
              description="Please select new status"
              handleClose={this.closeForm}
              update={this.updateProjectStatus}
              value={this.state.project_status}
              handleChange={this.handleProjectStatusChange}
              user={this.props.user}
            />

            <SavingDetailsModal
              className={classes.modal}
              show={this.props.updateLoading}
              title={"Updating activity"}
              loading={this.props.updateLoading}
              message={this.props.updateMessage}
            />
            <SavingDetailsModal
              className={classes.modal}
              show={this.state.archivelSubmitLoading}
              title={"Submitting for archivel"}
              loading={this.props.archivelSubmitLoading}
              message={this.props.archivelMessage}
              onClick={this.closeArchivelSubmissionAlert}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.auth.token,
    user: state.auth.user,
    projectActivityList: state.projectActivity.projectActivityList,
    comments: state.projectDiary.comments,
    commentAddFailed: state.projectDiary.commentAddFailed,
    commentAddLoading: state.projectDiary.commentAddLoading,
    commentAddMessage: state.projectDiary.commentAddMessage,
    cable: state.webSocket.cable,
    channel: state.webSocket.channel,
    showAlert: state.projectDiary.showCommentAlert,
    specifics: state.projectDiary.specifics,
    projectData: state.projectDashboard.project_data,
    updateLoading: state.projectActivity.updateLoading,
    updateMessage: state.projectActivity.updateMessage,
    archivelMessage: state.project.archivelMessage,
    archivelSubmissionFailed: state.project.archivelSubmissionFailed,
    archivelSubmitLoading: state.project.archivelSubmitLoading,
  };
}
export default compose(
  withRouter,
  connect(mapStateToProps, actions),
  withStyles(styles)
)(ProjectDashboard);
