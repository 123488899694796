
import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field,change,formValueSelector} from "redux-form";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import {Modal} from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import styles from '../modelstyles';
import {withRouter,Link} from 'react-router-dom';
import { withStyles,Tooltip,IconButton } from '@material-ui/core';
import MultiSelectComponent from "../../utils/multiselect"
import Close from "@material-ui/icons/Close";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import {InputText,TextArea,SelectInput,validate,SavingDetailsModal,RenderSwitch,FileInput} from "../../utils/formComponents";
import ViewDoc from '../ViewDoc';
class AddCertificateRegister extends Component{

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        certificateRegisterAddLoading:false,
        certificateRegisterAddFailed:true,
        timeSet:false,
        certificate_data:[],
        show_view_file: false,
        show_remove_file: false,
        delete_doc: false,
		show_doc: false
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showDoc = this.showDoc.bind(this);
    this.disableFields = this.disableFields.bind(this)
    this.disableLinkUdin = this.disableLinkUdin.bind(this)
}

componentWillMount(){
    this.props.fetchClientList(this.props.token)
    this.props.fetchPartnerList(this.props.token);
    this.props.fetchOrganisation(this.props.token);
    this.props.dispatch(
	change(
	    "AddCertificateRegister",
	    "udin_status",
	    "Issued"
	)
    );
    this.props.dispatch(change("AddCertificateRegister","client_present",true)) //by default as client not present
}
componentDidMount(){
    if(this.props.location.data){
        this.props.getCertificateRegisterById(this.props.token,this.props.location.data.id).then(resp=>{
            this.props.initialize(this.props.certificateRegister)
            this.setState({
                show_view_file: resp.attached,
                show_remove_file: resp.attached
            })
        })
    }
}
componentDidUpdate(prevProps){
    setTimeout(() => this.setState({message:''}), 9000);
    console.log("check disabled?",this.disableFields())
    if(!this.state.timeSet){

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    this.props.dispatch(change("AddCertificateRegister","date_of_udin_generation",today))
        this.props.dispatch(change("AddCertificateRegister","date_of_document",today))
        this.setState({
            timeSet:true
        })
    }
    if(!this.state.certificateRegisterAddFailed){
        this.props.history.goBack();
    }
    if(this.props.client_present !== prevProps.client_present){
        if(!this.props.client_present){
            this.props.dispatch(change("AddCertificateRegister","type_of_document","Others"))
        }
    }
  }

closeModal(){
    this.setState({
        certificateRegisterAddLoading:this.props.certificateRegisterAddLoading,
        certificateRegisterAddFailed:this.props.certificateRegisterAddFailed
    })
}
disableFields(){//once created all basic fields need to be disabled
     if(this.props.location.data){
	return true
     }else{
	return false
     }

}
disableLinkUdin(){
	if(this.props.location.data && this.props.certificateRegister && this.props.certificateRegister['udin']){
		return true
	}else{
		return false
	}
}
showDoc(){
    return(
        <Modal show={this.state.show_doc} onHide={()=>{this.setState({show_doc: false})}}>
            <Modal.Header closeButton>
                <Modal.Title>Udin Doc Uploaded</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ViewDoc id={this.props.certificateRegister.id}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>this.setState({show_doc: false})}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}
async onsubmit(formData) {
    if(this.props.location.data){
	formData["updated_by_id"] = this.props.user.id
    }else{
	formData["created_by_id"] = this.props.user.id
    }
    this.setState({
        certificateRegisterAddLoading:true,
        certificate_data: JSON.parse(JSON.stringify(formData))
    })
    let og = formData['organisation_id']['id'] !== undefined ?  formData['organisation_id']['id']:null
    if(this.props.client_present){
        await this.setState(prevState => ({
            certificate_data: {
                ...prevState.certificate_data,
                project_id: formData['project_id']['id'],
                client_id: formData['client_id']['id'],
                signing_partner_id: formData['signing_partner_id']['id'],
                organisation_id: og
            }
        }))
    }else{
        await this.setState(prevState => ({
            certificate_data: {
                ...prevState.certificate_data,
                project_id: null,
                client_id: null,
                signing_partner_id: formData['signing_partner_id']['id'],
                organisation_id: og
            }
        }))
    }
    if(this.props.location.data){
        if(this.state.delete_doc){
            await this.setState(prevState => ({
                certificate_data: {
                    ...prevState.certificate_data,
                    delete_doc: this.state.delete_doc
                }
            }))
        }
        let toDelete = ['project_id', 'client_id', 'client_particulars', 'project_particulars', 'signing_partner_id', 'date_of_document', 'type_of_document', 'type_of_sign', 'udin_status', 'organisation_id', 'id', 'client_present', 'attached']
        let temp = JSON.parse(JSON.stringify(this.state.certificate_data))
        toDelete.forEach((key)=>{
            delete temp[key]
        })
        if(formData['doc'])temp['doc'] = formData['doc']
        await this.props.updateCertificateRegister(this.props.token,this.props.certificateRegister.id,temp)
    }else{
        if(formData['doc'])
            await this.setState(prevState => ({
                certificate_data: {
                    ...prevState.certificate_data,
                    doc: formData['doc']
                }
            }))//certificate_data have json.stringified value, so it cause upload error with doc upload
        await this.props.addNewCertificateRegister(this.props.token,this.state.certificate_data);
    }
    this.setState({
        message:this.props.message
    })
}
  render () {
    let state = this.state;
const { handleSubmit,classes } = this.props;
    return (
      <Container>
        <Header title="add_new_certificate_register" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
       Add New Certificate Register
      </Typography>
        <Row>
          <Col sm={4}>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(this.onsubmit)}>
        <Row>
        <Col sm={this.props.client_present ? 4 : 8}>
            <Field
                name= "client_present"
                component={RenderSwitch}
                label= "Client Present?"
	        disabled = {this.disableFields()}
            />
            </Col>

        {this.props.client_present ? <Col sm={4}>
            <span>Select Client</span>
              <Field
                  name="client_id"
                  label=""
                  props={{
                      type: 'object'
                  }}
                  component={MultiSelectComponent}
                  {...{
                      data:this.props.dclients,
                      isMulti : false
                  }

                  }
                  fullWidth

	        isDisabled = {this.disableFields()}
              />

        </Col> :
        <Col sm={4}>
            <span>Client particulars</span>
              <Field
                        name="client_particulars"
                        label=" "
                        component={InputText}
                        type="text"
                        fullWidth
	        	disabled = {this.disableFields()}
              />
        </Col>
        }
        {this.props.client_present && <Col sm={4}>

              <span>Select Project</span>
              <Field
                name="project_id"
                        label=" "
                        props={{
                            type: 'object'
                        }}
                        component={MultiSelectComponent}
                        {...{
                            data:this.props.clientProjects,
                            isMulti : false
                        }

                        }
                        fullWidth
	        isDisabled = {this.disableFields()}
             />
            </Col>}
        </Row>
        <Row>

            <Col sm={8}>
                <span>Document Description</span>

              <Field
                        name="project_particulars"
                        label=" "
                        component={InputText}
                        type="text"
                        fullWidth
	        disabled = {this.disableFields()}
              />

            </Col>
            <Col sm={4}>
            <span>Select Organisation</span>
              <Field
                  name="organisation_id"
                  label=""
                  props={{
                      type: 'object'
                  }}
                  component={MultiSelectComponent}
                  {...{
                      data:this.props.organisation,
                      isMulti : false
                  }

                  }
                  fullWidth
	        isDisabled = {this.disableFields()}
              />
        </Col>
          </Row>
          <Row>
            <Col sm={8}>

              <span>Select Signing Partner</span>
              <Field
                name="signing_partner_id"
                        label=" "
                        props={{
                            type: 'object'
                        }}
                        component={MultiSelectComponent}
                        {...{
                            data:this.props.partners,
                            isMulti : false
                        }

                        }
                        fullWidth
	        isDisabled = {this.disableFields()}
             />
            </Col>

            <Col sm={4}>

              <span>Date of document</span>

              <Field
                        name="date_of_document"
                        label=" "
                        component={InputText}
                        type="date"
                        fullWidth
	        	disabled = {this.disableFields()}
              />
            </Col>
          </Row>
        <Row>

            <Col sm = {8}>
              <span>Type of Document</span>
              <Field
                        name="type_of_document"
                        label="Type of Document *"
                        component={SelectInput}
                        fullWidth
	        	disabled = {(this.disableFields() || !this.props.client_present)}
              />
            </Col>
            <Col sm={4}>
                         <span>Type of signature</span>
                           <Field
                        name="type_of_sign"
                        label="Type of signature"
                        component={SelectInput}
                        fullWidth
	        	disabled = {this.disableFields()}
                    />
            </Col>
        </Row>
	    {/**<Row>

            <Col sm={8}>
                         <span>Status</span>
                           <Field
                        name="udin_status"
                        label="Status "
                        component={SelectInput}
                        fullWidth
                    />
            </Col>
    </Row>**/}
    <Row>{this.showDoc()}</Row>
    <Row>
        {!this.state.show_view_file ?
                <Col sm={4}>
                    <span>Upload document</span>
                    <Field name="doc" label="select pdf file to upload" component={FileInput} type="file" fullWidth/>
                    </Col>
                    :
                    <Row>
                    <Col sm={4}>
                <Tooltip
                    id="tooltip-top"
                    title="View doc"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                >
                    <IconButton
                        aria-label="Eye"
                        className={classes.tableActionButton}
                        onClick={()=>this.setState({show_doc: true})}
                    >
                        <RemoveRedEyeIcon
                        className={
                            classes.tableActionButtonIcon + " " + classes.edit
                        }
                        />
                    </IconButton>
                </Tooltip>

        </Col>
        <Col sm={4}>
                <Tooltip
                        id="tooltip-top"
                        title="Remove doc"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    aria-label="Close"
                  className={classes.tableActionButton}
                  onClick={()=>this.setState({show_view_file: false, show_remove_file: false, delete_doc: true})}
                  >
                    <Close
                      className={
                        classes.tableActionButtonIcon + " " + classes.edit
                      }
                    />
                  </IconButton>
                </Tooltip>

                </Col>

        </Row>

        }
        </Row>
        <Row>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.certificateRegisterAddLoading}
             title={"Message"}
             loading={this.props.certificateRegisterAddLoading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
	{this.props.location.data ?
	<>
		<Row style={{marginTop: 20}}>
            		<Col sm={8}>Link Udin</Col>
		</Row>
        <Row>

            <Col sm={8}>
                           <span>Internal Reference</span>
                           <Field
                        name="internal_reference"
                        label="Internal Reference"
                        component={InputText}
                        type="text"
			disabled ={true}
                        fullWidth
                    />
            </Col>
        </Row>
	<Row>
            <Col sm={8}>
		   <span>Date of Udin generation</span>
		   <Field
                        name="date_of_udin_generation"
                        label=""
                        component={InputText}
                        type="date"
                        fullWidth
		        disabled = {this.disableLinkUdin()}
                    />
            </Col>
            <Col sm={4}>
		    <span>UDIN</span>
		    <Field
                        name="udin"
                        label="Udin of the document"
                        component={InputText}
                        type="text"
                        fullWidth
		        disabled = {this.disableLinkUdin()}
              	     />
        	</Col>
	 </Row>
	</>: null }
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>
          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">{this.props.location.data ? "Update": "Add"}</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    )
  }
}
const selector = formValueSelector('AddCertificateRegister')
function mapStateToProps(state) {
    return {
        token:state.auth.token,
        user: state.auth.user,
        partners:state.employee.partnerList,
        message:state.certificateregister.certificateRegisterAddMessage,
        clientProjects: state.project.clientProjects,
        organisation : state.organisation.dorganisationList,
        dclients: state.client.dclientList,
        certificateRegister: state.certificateregister.certificateRegister,
        certificateRegisterAddLoading:state.certificateregister.certificateRegisterAddLoading,
        certificateRegisterAddFailed:state.certificateregister.certificateRegisterAddFailed,
        client_present: selector(state,'client_present')
    }
}
export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddCertificateRegister",
      enableReinitialize: true,
validate,keepDirtyOnReinitialize : true }),
    withStyles(styles)
)(AddCertificateRegister);
