import {
    START_SAC_MASTER_ADD,
    START_SAC_MASTER_FETCH_LIST,
    SAC_MASTER_ADD_SUCCESS,
    SAC_MASTER_ADD_FAILED,
    SAC_MASTER_FETCH_LIST_SUCCESS,
    SAC_MASTER_FETCH_LIST_FAILED,
    START_FETCH_SAC_MASTER_DETAILS_BY_CODE,
    SUCCESS_FETCH_SAC_MASTER_DETAILS_BY_CODE,
    FAILED_FETCH_SAC_MASTER_DETAILS_BY_CODE,
} from "./types";
import axios from "axios";
import { BaseUrl } from "./api";

export const fetchSacMasterByCode = (token, code) => {
    //not writing reducers as we are going to pass this  directly to component
    return async (dispatch) => {
        dispatch({
            type: START_FETCH_SAC_MASTER_DETAILS_BY_CODE,
        });
        try {
            const res = await axios.get(
                `${BaseUrl}/sac_master_by_code/${code}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.statusText === "OK") {

                dispatch({
                    type: SUCCESS_FETCH_SAC_MASTER_DETAILS_BY_CODE,
                    payload: res.data["sac_master"],
                });
                return res.data.sac_master;
            } else {
                dispatch({
                    type: FAILED_FETCH_SAC_MASTER_DETAILS_BY_CODE,
                    payload: res.data.message,
                });
                return undefined;
            }
        } catch (error) {
            dispatch({
                type: FAILED_FETCH_SAC_MASTER_DETAILS_BY_CODE,
                payload: error.message,
            });
            return undefined;
        }
    };
};

export const fetchSacmasterList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_SAC_MASTER_FETCH_LIST,
        });
        try {
            const res = await axios.get(`${BaseUrl}/sac_master`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText === "OK") {
                dispatch({
                    type: SAC_MASTER_FETCH_LIST_SUCCESS,
                    payload: res.data["sac_master"],
                });
            } else {
                dispatch({
                    type: SAC_MASTER_FETCH_LIST_FAILED,
                    payload: "Fetching org  list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: SAC_MASTER_FETCH_LIST_FAILED,
                payload: error.message,
            });
        }
    };
};

export const addSacMaster = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_SAC_MASTER_ADD,
        });
        try {
            const res = await axios.post(`${BaseUrl}/sac_master`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.data.status) {
                dispatch({
                    type: SAC_MASTER_ADD_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: SAC_MASTER_ADD_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: SAC_MASTER_ADD_FAILED,
                payload: error.message,
            });
        }
    };
};
