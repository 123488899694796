import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useDispatch,useSelector } from 'react-redux';
import { useHistory,useLocation } from "react-router-dom";
import {logout} from "../../../actions/authAction"
import {initializeSocket, unsubscribeSocket} from "../../../actions/webSocketAction";
import {CLEAR_NOTIFICATION_COUNT} from '../../../actions/types'
import logo from '../../../assets/cue.svg'
import ArrowBack from '@material-ui/icons/ArrowBack';
import SignedInLink from '../Navbar/SignedInLink';
import Footer from '../Footer/Footer';
import RenderMenu from '../MenuList'
import {useMarkNotificationAsRead, useNotifications} from '../../../hooks/notification-query'
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },

    appBar: {
      zIndex: theme.zIndex.drawer +1
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },


  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
    title:{
    flexGrow: 1,
    fontFamily: 'Flamenco'
  },
    root: {
      display: 'flex',
      position:'relative'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
    width: drawerWidth,
  },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            marginLeft: drawerWidth
        },
      padding: theme.spacing.unit,
    },
    icon: {
      width:'60px',
      height:'60px',
      marginRight: theme.spacing.unit
    },

}));

export default function Header({title,children,container}) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const {auth, webSocket, notifications} = useSelector(state => state)
    const {isAuth, user } = auth
    const {cable, channel} = webSocket
    //const {notificationList} = notifications
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const history = useHistory();
    const [open, setOpen] = React.useState(false)
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const dispatch = useDispatch();
    const location  = useLocation();
    const { mutate } = useMarkNotificationAsRead();
    const [fetchNotifications, setFetchNotifications] = React.useState(false)
    const { data: notificationList} = useNotifications(fetchNotifications)

    React.useEffect(()=>{
        //mutate();
    },[notificationList])
    const handleNotificationMenuOpen = (event) => {
        if(anchorEl && anchorEl.contains(event.target)){
            setAnchorEl(null)
        }else{
            setAnchorEl(event.currentTarget)
            setFetchNotifications(true)
        }
    };

    React.useEffect(()=>{
        dispatch(initializeSocket(auth.token))
        return function cleanup(){
            dispatch(unsubscribeSocket(cable, channel))
        }
    },[])

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleCloseNotification= (item) => {
        setAnchorEl(null);
        handleMobileMenuClose();
        setFetchNotifications(false)
        if(item.id){
            mutate(item.notification_id)//mark this notification as read,
            dispatch({type: CLEAR_NOTIFICATION_COUNT})//actually decriment by 1
            history.push({pathname: '/leave_details', id: item.id })
        }
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSignOut = () => {
        setOpen(false);
        dispatch(logout())
    };
    const handleDrawerToggle = () => {
       setMobileOpen(!mobileOpen)
    };

    const menuId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';

    const notificationIcon = (
        (user && user.unread_notifications && user.unread_notifications) >0
            ?   <Badge badgeContent={user.unread_notifications} color="error">
                    <NotificationsIcon />
                </Badge>
            :   <NotificationsIcon />
    )
    const renderMobileMenu = (
        <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        >

        <MenuItem onClick={handleNotificationMenuOpen}>
            <IconButton
                    aria-label="show 11 new notifications"
                    color="inherit"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
            >
                {notificationIcon}
            </IconButton>
            <p>Notifications</p>
        </MenuItem>
        </Menu>
    );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
                  {title==="dashboard"?<IconButton
                      color="secondary"
                      aria-label="Open drawer"
                      onClick={handleDrawerToggle}
                      className={classes.menuButton}
                    >
                      <MenuIcon style={{color: 'white'}}/>
                    </IconButton>:<IconButton
                      onClick={() =>history.goBack()}
                    >
                    <ArrowBack style={{color: 'white'}} />
                    </IconButton>}
            <img src={logo} className={classes.icon}/>
            <Typography variant="h3" color="inherit" noWrap className={classes.title}>
                    cue
            </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>

            <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleNotificationMenuOpen}
            >
                {notificationIcon}
            </IconButton>

          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
        </div>
        <Button color="inherit">{user!=undefined?user.name:null}</Button>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <RenderMenu isMenuOpen = {isMenuOpen} anchorEl = {anchorEl}  handleCloseNotification = {(item)=>handleCloseNotification(item)} notifications = {notificationList? notificationList: []}/>
      {title === "dashboard"?<nav className={classes.drawer}>
                  <Hidden xsUp implementation="css">
                    <Drawer
                    container={container}
                      variant="temporary"
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                    >
                    <SignedInLink
                     pathname={location.pathname}
                     open={open}
                     user={user}
                    handleClose={handleClose}
                    handleClickOpen={handleClickOpen}
                    handleCloseSignOut={handleCloseSignOut}
                     />
                     </Drawer>
                  </Hidden>
                  <Hidden xsDown implementation="css">
                    <Drawer
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                      variant="permanent"
                      open
                    >
                      <SignedInLink
                         pathname={location.pathname}
                         open={open}
                        handleClose={handleClose}
                        user={user}
                        handleClickOpen={handleClickOpen}
                        handleCloseSignOut={handleCloseSignOut}
                         />
                    </Drawer>
                  </Hidden>
                  </nav>:null}
                   <main className={classes.content}>
                  <div className={classes.toolbar} />
                  {children}

            {title==="dashboard"?<Footer/>:null}
                </main>
    </div>
  );
}

