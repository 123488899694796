import React from "react";
import PageTemplate from "../components/Layout/PageTemplate/PageTemplate";
import CoreUITable from "../components/Layout/Table";
import { useHistory } from "react-router-dom";
import { useCertificateRegisterList } from "../hooks/udin-query";
import ProgressBar from "react-bootstrap/ProgressBar";

const CertificateRegisterPage = () => {
    const { isLoading, isError, data, error, refetch } =
        useCertificateRegisterList();
    const history = useHistory();
    if (isLoading)
        return <ProgressBar striped variant="success" animated now={40} />;
    if (isError) return <>Error...</>;
    const options = [
        {
            text: "Link Udin",
            action: function (id) {
                history.push({
                    pathname: "/addcertificateregister",
                    data: { id: id, type: "update" },
                });
            },
            params: true,
        },
        {
            text: "View Doc",
            action: function (id) {
                history.push({
                    pathname: "/viewdoc",
                    id: id,
                });
            },
            params: true,
        },
    ];

    return (
        <PageTemplate>
            <CoreUITable
                page="udin"
                title="Certificate Registers"
                addPath="/addcertificateregister"
                options={options}
                data={data["certificate_registers"]}
                fields={[
                    "internal_reference",
                    "project",
                    "client",
                    "udin",
                    "date_of_document",
                    "udin_status",
                    {
                        key: "show_details",
                        label: "",
                        _style: { width: "1%" },
                        filter: false,
                    },
                ]}
            />
        </PageTemplate>
    );
};

export default CertificateRegisterPage;
