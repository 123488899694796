import {
    START_MOVEMENT_REGISTER_LIST_FETCH,
    MOVEMENT_REGISTER_LIST_FETCH_SUCCESS,
    MOVEMENT_REGISTER_LIST_FETCH_FAILED,
    START_ADD_NEW_MOVEMENT_REGISTER,
    ADDING_NEW_MOVEMENT_REGISTER_FAILED,
    ADDING_NEW_MOVEMENT_REGISTER_SUCCESS,
    MOVEMENT_REGISTER_STATUS_CHANGE_SUCCESS,
    MOVEMENT_REGISTER_STATUS_CHANGE_FAILED,
    START_MOVEMENT_REGISTER_STATUS_CHANGE,
    MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_SUCCESS,
    MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_FAILED,
    START_MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH,
    START_MOVEMENT_REGISTER_FETCH_BY_ID,
    MOVEMENT_REGISTER_FETCH_BY_ID_DONE,
    MOVEMENT_REGISTER_FETCH_BY_ID_FAILED,
    START_UPDATE_DSC_CLIENT,
    START_UPDATE_DSC_CUSTODIAN,
    UPDATE_DSC_CUSTDOIAN_FAILED,
    UPDATE_DSC_CUSTDOIAN_SUCCESS,
    UPDATE_DSC_CLIENT_FAILED,
    UPDATE_DSC_CLIENT_SUCCESS,
    START_IN_MOVEMENT,
    IN_MOVEMENT_SUCCESS,
    IN_MOVEMENT_FAILED,
    BASIC_DETAILS_UPDATE_SUCCESS,
    BASIC_DETAILS_UPDATE_FAILED,
    START_BASIC_DETAILS_UPDATE,
} from "../actions/types";

const DEFAULT_STATE = {
    movementRegisterAddLoading: false,
    movementRegisterAddMessage: "",
    movementRegisterAddFailed: true,
    movementRegisterList: [],
    movementRegisterListFetchMessage: "",
    movementRegisterFetchListLoading: false,
    movementRegisterDropdownList: [],
    movementRegisterDropdownLoading: false,
    movementRegisterDropdownFetchMessage: "",
    movementRegisterDropdownFetchFailed: true,
    movementStatusChangeLoading: false,
    movementStatusChangeFailed: true,
    updateClientLoading: false,
    updateClientFailed: true,
    updateClientMessage: "",
    movementStatusChangeMessage: "",
    movementRegisterDetails: {},
    movementRegisterLoading: false,
    basicDetailsUpdateLoading: false,
    basicDetailsUpdateFailed: true,
    basicDetailsUpdateMessage: "",
};
export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case START_MOVEMENT_REGISTER_FETCH_BY_ID:
            return {
                ...state,
                movementRegisterDetails: {},
                movementRegisterLoading: true,
            };
        case MOVEMENT_REGISTER_FETCH_BY_ID_FAILED:
            return {
                ...state,
                movementRegisterLoading: false,
            };
        case MOVEMENT_REGISTER_FETCH_BY_ID_DONE:
            return {
                ...state,
                movementRegisterLoading: false,
                movementRegisterDetails: action.payload,
            };
        case START_IN_MOVEMENT:
            return {
                ...state,
                movementStatusChangeLoading: true,
                movementStatusChangeFailed: false,
                movementStatusChangeMessage: "",
            };
        case START_BASIC_DETAILS_UPDATE:
            return {
                ...state,
                basicDetailsUpdateLoading: true,
                basicDetailsUpdateFailed: false,
                basicDetailsUpdateMessage: "",
            };
        case BASIC_DETAILS_UPDATE_SUCCESS: {
            return {
                ...state,
                basicDetailsUpdateLoading: false,
                basicDetailsUpdateFailed: false,
                basicDetailsUpdateMessage: action.payload["message"],
                movementRegisterDetails: {
                    ...state.movementRegisterDetails,
                    name: action.payload["data"]["name"],
                    expiry_date: action.payload["data"]["expiry_date"],
                    password: action.payload["data"]["password"],
                    token_no: action.payload["data"]["token_no"],
                    token_provider: action.payload["data"]["token_provider"],
                    remarks: action.payload["data"]["remarks"],
                },
            };
        }
        case BASIC_DETAILS_UPDATE_FAILED:
            return {
                ...state,
                basicDetailsUpdateLoading: false,
                basicDetailsUpdateFailed: true,
                basicDetailsUpdateMessage: action.payload,
            };
        case IN_MOVEMENT_SUCCESS:
            return {
                ...state,
                movementStatusChangeLoading: false,
                movementStatusChangeMessage: action.payload["message"],
                movementRegisterDetails: {
                    ...state.movementRegisterDetails,
                    movement_details:
                        action.payload["data"]["movement_details"],
                    dsc_custodians_attributes:
                        action.payload["data"]["dsc_custodians_attributes"],
                    status: action.payload["data"]["in_out_status"],
                    last_movement_date: action.payload["data"]["date"],
                },
            };
        case IN_MOVEMENT_FAILED:
            return {
                ...state,
                movementStatusChangeLoading: false,
                movementStatusChangeFailed: true,
                movementStatusChangeMessage: action.payload,
            };

        case START_MOVEMENT_REGISTER_STATUS_CHANGE:
            return {
                ...state,
                movementStatusChangeLoading: true,
                movementStatusChangeFailed: false,
                movementStatusChangeMessage: "",
            };
        case MOVEMENT_REGISTER_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                movementStatusChangeLoading: false,
                movementStatusChangeMessage: action.payload["message"],
                movementRegisterDetails: {
                    ...state.movementRegisterDetails,
                    movement_details: [
                        ...state.movementRegisterDetails.movement_details,
                        action.payload["data"],
                    ],
                    status: action.payload["data"]["in_out_status"],
                    last_movement_date: action.payload["data"]["date"],
                },
            };
        case MOVEMENT_REGISTER_STATUS_CHANGE_FAILED:
            return {
                ...state,
                movementStatusChangeLoading: false,
                movementStatusChangeFailed: true,
                movementStatusChangeMessage: action.payload,
            };
        case START_UPDATE_DSC_CLIENT:
            return {
                ...state,
                updateClientLoading: true,
                updateClientFailed: false,
            };
        case UPDATE_DSC_CLIENT_SUCCESS:
            return {
                ...state,
                updateClientLoading: false,
                updateClientFailed: false,
                updateClientMessage: action.payload["message"],
                movementRegisterDetails: {
                    ...state.movementRegisterDetails,
                    clients: action.payload["data"],
                },
            };
        case UPDATE_DSC_CLIENT_FAILED:
            return {
                ...state,
                updateClientLoading: false,
                updateClientFailed: true,
                updateClientMessage: action.payload,
            };
        case START_UPDATE_DSC_CUSTODIAN:
            return {
                ...state,
                movementStatusChangeLoading: true,
                movementStatusChangeFailed: false,
                movementStatusChangeMessage: "",
            };
        case UPDATE_DSC_CUSTDOIAN_SUCCESS:
            return {
                ...state,
                movementStatusChangeLoading: false,
                movementStatusChangeMessage: action.payload["message"],
                movementRegisterDetails: {
                    ...state.movementRegisterDetails,
                    dsc_custodians_attributes: [
                        ...state.movementRegisterDetails
                            .dsc_custodians_attributes,
                        action.payload["data"],
                    ],
                    last_custodian_change_date: action.payload["data"]["date"],
                },
            };
        case UPDATE_DSC_CUSTDOIAN_FAILED:
            return {
                ...state,
                movementStatusChangeLoading: false,
                movementStatusChangeFailed: true,
                movementStatusChangeMessage: action.payload,
            };

        case START_MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH:
            return {
                ...state,
                movementRegisterDropdownList: [],
                movementRegisterDropdownLoading: true,
                movementRegisterDropdownFetchMessage: "",
                movementRegisterDropdownFetchFailed: false,
            };
        case MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_SUCCESS:
            return {
                ...state,
                movementRegisterDropdownLoading: false,
                movementRegisterDropdownList: action.payload,
                movementRegisterDropdownFetchFailed: false,
            };
        case MOVEMENT_REGISTER_DROPDOWN_LIST_FETCH_FAILED:
            return {
                ...state,
                movementRegisterDropdownLoading: false,
                movementRegisterDropdownFetchFailed: true,
                movementRegisterDropdownFetchMessage: action.apyload,
            };
        case START_ADD_NEW_MOVEMENT_REGISTER:
            return {
                ...state,
                movementRegisterAddLoading: true,
                movementRegisterAddFailed: false,
            };
        case ADDING_NEW_MOVEMENT_REGISTER_SUCCESS:
            return {
                ...state,
                movementRegisterAddLoading: false,
                movementRegisterAddFailed: false,
                movementRegisterAddMessage: action.payload,
            };
        case ADDING_NEW_MOVEMENT_REGISTER_FAILED:
            return {
                ...state,
                movementRegisterAddLoading: false,
                movementRegisterAddFailed: true,
                movementRegisterAddMessage: action.payload,
            };
        case START_MOVEMENT_REGISTER_LIST_FETCH:
            return {
                ...state,
                movementRegisterFetchListLoading: true,
                movementRegisterListFetchListMessage: "",
            };
        case MOVEMENT_REGISTER_LIST_FETCH_SUCCESS:
            return {
                ...state,
                movementRegisterFetchListLoading: false,
                movementRegisterList: action.payload,
            };
        case MOVEMENT_REGISTER_LIST_FETCH_FAILED:
            return {
                ...state,
                movementRegisterFetchListLoading: false,
                movementRegisterFetchMessage: action.payload,
            };

        default:
            return state;
    }
};
