import {
    START_OUTWARD_REGISTER_ADD,
    OUTWARD_REGISTER_ADD_SUCCESS,
    OUTWARD_REGISTER_ADD_FAILED,
    FETCH_OUTWARD_REGISTER_START,
    FETCH_OUTWARD_REGISTER_SUCCESS,
    FETCH_OUTWARD_REGISTER_FAILED,
} from "./types";
import axios from "axios";
import { BaseUrl } from "./api";

export const fetchOutwardRegisterList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_OUTWARD_REGISTER_START,
        });
        try {
            const res = await axios.get(`${BaseUrl}/outward_register`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (res.data.status) {
                dispatch({
                    type: FETCH_OUTWARD_REGISTER_SUCCESS,
                    payload: res.data["outward_register"],
                });
            } else {
                dispatch({
                    type: FETCH_OUTWARD_REGISTER_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_OUTWARD_REGISTER_FAILED,
                payload: error.message,
            });
        }
    };
};

export const addOutwardRegister = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_OUTWARD_REGISTER_ADD,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/outward_register`,
                { outward_register: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: OUTWARD_REGISTER_ADD_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: OUTWARD_REGISTER_ADD_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: OUTWARD_REGISTER_ADD_FAILED,
                payload: error.message,
            });
        }
    };
};
