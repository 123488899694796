import React from 'react'
import { Row, Col,Container,Badge,Table } from 'react-bootstrap';
import Header from "../../components/Layout/Header/Header";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Button from '@material-ui/core/Button';
import { useLeave, useLeaveUpdate } from '../../hooks/leave-query'
import { useHistory,useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { AlertComponent} from "../../utils/formComponents";
import ProgressBar from 'react-bootstrap/ProgressBar'
let initialValues = {
    approver_remarks: '',
    leave_status: "Pending_for_approval"
}
export default function ApproveLeave(){
    const [approveStatus,setApproveStatus] = React.useState(null)

    const [showDetails, setShowDetails] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const [showWithdrawAlert, setShowWithdrawAlert] = React.useState(false)

    const {isLoading:updateLoading, mutate, data: updateData, error} = useLeaveUpdate()
    const history = useHistory();
    //already added redux related data fetching
    const state = useSelector(state => state)
    const { token,user } = state.auth
    const {id}  = useLocation();
    const { data:initialValues, isLoading} = useLeave(id)

    React.useEffect(()=>{
        if(updateLoading) setMessage(`Leave request ${approveStatus} in progress`)
        if(updateData ) setShowDetails(false)
        if(error) {
            setMessage(error.message)
        }
    },[updateData, updateLoading, error])
    React.useEffect(()=>{
        if(initialValues && initialValues.user_id === user.id && initialValues.leave_status === 'Pending_for_approval')initialValues['approver_remarks'] = "not yet approved"
    },[initialValues])
    React.useEffect(()=>{
        if(!showDetails){
            if(updateData && updateData['status'] == 204){
              history.goBack();
            }
        }
    },[updateLoading, showDetails])
    const validateSelection=(value)=>{
        let error;
        if(!value){
            error = 'Required to proceed';
        }
        return error
    }
    const withdrawLeave=()=>{
        setApproveStatus("Withdrawn");
        setShowWithdrawAlert(false)
        let values = {...initialValues}
        values['leave_status'] = "Withdrawn"
        mutate({...values})
        setShowDetails(true)
    }
    if ( isLoading) return <ProgressBar striped variant="success" animated now={40} />;
    return(
    <Container>
        <Header title="approve_leave"/>
        <h1 style={{marginLeft: '20%'}}> <Badge variant="dark">Approve Leave</Badge> </h1>
        <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
                values['leave_status'] = approveStatus
                mutate({...values})
                setShowDetails(true)
            }}
            >
            {({ values,errors, touched,setFieldValue }) => (
            <Form>
                <Row style={{ marginTop: '5%'}}></Row>
                <Row style={{ marginTop: '2%',alignItems: 'center'}}>
                    <Col sm={4} >
                        <span>User</span>
                    </Col>
                    <Col sm={4}>
                        <span>{values.user}</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: '2%',alignItems: 'center'}}>
                    <Col sm={4} >
                        {values.multiple_days ? <span>From</span> : <span>Date</span>}
                    </Col>
                    <Col sm={4}>
                        <Row>
                            <Col>
                                <span>{values.from_date}</span>
                            </Col>
                            <Col>
                                <span>{values.from_date_leave_type}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {values.multiple_days && <Row style={{ marginTop: '2%',alignItems: 'center'}}>
                    <Col sm={4} >
                        <span>To date</span>
                    </Col>
                    <Col sm={4}>
                        <Row>
                            <Col>
                                <span>{values.to_date}</span>
                            </Col>
                            <Col>
                                <span>{values.to_date_leave_type}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>}
                <Row style={{ marginTop: '2%',alignItems: 'center'}}>
                    <Col sm={4} >
                        <span>Reason</span>
                    </Col>
                    <Col sm={4} >
                        <span>{values.reason}</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: '2%',alignItems: 'center'}}>
                    <Col sm={4} >
                        <span>Approver Remark</span>
                    </Col>

                    <Col sm={4}>
                        <Field
                            name="approver_remarks"
                            type="text"
                            style={{width: '100%'}}
                            validate={validateSelection}
                            disabled= {values.user_id === user.id}
                        />
                        { !values.approver_remarks && errors.approver_remarks && touched.approver_remarks && <div  style={{ marginTop: '2%',alignItems: 'center'}}>{errors.approver_remarks}</div>}
                    </Col>

                </Row>
                <Row style={{ marginTop: '2%',alignItems: 'center'}}>
                    <Col sm={4}></Col>
                    {(values.leave_status === 'Pending_for_approval' && values.user_id !== user.id) ? <Col sm={4}>
                        <Row>
                            <Col>
                                <Button onClick={() => setApproveStatus("Rejected")} type="submit"  variant="contained" color="primary">Reject</Button>
                            </Col>
                            <Col>
                                <Button onClick={() => setApproveStatus("Approved")}  type="submit" variant="contained" color="primary">Approve</Button>
                            </Col>
                        </Row>
                        </Col>: <Col sm={4}><h2>{!error &&<Badge variant="dark">{values.leave_status}</Badge>}</h2></Col>}
                </Row>
                {(values.user_id === user.id && (values.leave_status === 'Pending_for_approval' ||values.leave_status === 'Approved')) && <Row style={{ marginTop: '2%',alignItems: 'center'}}>
                    <Col>
                        <Button onClick={() => setShowWithdrawAlert(true)} variant="contained" color="primary">Withdraw</Button>
                    </Col>

                </Row>}
            </Form>
                )}
            </Formik>

            <AlertComponent title="Saving" description = {message} saveText ={"OK"} open={showDetails} proceed={()=> setShowDetails(false)}/>
            <AlertComponent title="Withdraw leave?" description = {"Are you sure you want to withdraw this leave?"} saveText ={"Yes"} open={showWithdrawAlert} proceed={()=>{ withdrawLeave()}} cancelText="No" handleClose={()=> setShowWithdrawAlert(false)}/>
        </Container>
                )
            }

