import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_EMPLOYEE_LIST_FETCH,
    EMPLOYEE_LIST_FETCH_SUCCESS,
    EMPLOYEE_LIST_FETCH_FAILED,
    START_EMPLOYEE_FETCH,
    START_LEAD_PARTNER_FETCH,
    EMPLOYEE_FETCH_SUCCESS,
    LEAD_PARTNER_FETCH_SUCCESS,
    EMPLOYEE_FETCH_FAILED,
    LEAD_PARTNER_FETCH_FAILED,
    START_EMPLOYEE_DROP_DOWN_LIST_FETCH,
    EMPLOYEE_DROP_DOWN_LIST_FETCH_SUCCESS,
    EMPLOYEE_DROP_DOWN_LIST_FETCH_FAILED,
    START_EMPLOYEE_APPROVEL,
    EMPLOYEE_APPROVEL_SUCCESS,
    EMPLOYEE_APPROVEL_FAILED,
    START_FETCH_EMLOYEE_DETAILS,
    FETCH_EMPLOYEE_DETAILS_SUCCESS,
    FETCH_EMPLOYEE_DETAILS_FAILED,
    REMOVE_EMPLOYEE,
    ADD_BACK_EMPLOYEE,
    INITIAL_FILTERING_EMPLOYEE,
} from "./types.js";

export const addBackEmployee = (item) => {
    return async (dispatch) => {
        dispatch({
            type: ADD_BACK_EMPLOYEE,
            payload: item,
        });
    };
};

export const initialFilteringEmployee = (item) => {
    return async (dispatch) => {
        dispatch({
            type: INITIAL_FILTERING_EMPLOYEE,
            payload: item,
        });
    };
};
export const removeEmployee = (item) => {
    return async (dispatch) => {
        dispatch({
            type: REMOVE_EMPLOYEE,
            payload: item,
        });
    };
};

export const fetchEmployeeDetails = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_FETCH_EMLOYEE_DETAILS,
        });
        try {
            const res = await axios.get(`${BaseUrl}/employee/${data}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText === "OK") {
                dispatch({
                    type: FETCH_EMPLOYEE_DETAILS_SUCCESS,
                    payload: res.data["data"],
                });
                return res.data["data"];
            } else {
                dispatch({
                    type: FETCH_EMPLOYEE_DETAILS_FAILED,
                    payload: "Fetch failed",
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_EMPLOYEE_DETAILS_FAILED,
                payload: error.message,
            });
        }
    };
};

//this below function unused from november 11
export const approveEmploy = (token, data, id) => {
    return async (dispatch) => {
        dispatch({
            type: START_EMPLOYEE_APPROVEL,
        });
        try {
            const res = await axios.put(
                `${BaseUrl}/approve_employee/${id}`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: EMPLOYEE_APPROVEL_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: EMPLOYEE_APPROVEL_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: EMPLOYEE_APPROVEL_FAILED,
                payload: error.message,
            });
        }
    };
};
//employee id,name,type,app status
export const fetchEmployeeList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_EMPLOYEE_LIST_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/employee_list`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText === "OK") {
                dispatch({
                    type: EMPLOYEE_LIST_FETCH_SUCCESS,
                    payload: res.data["employee_details"],
                });
            } else {
                dispatch({
                    type: EMPLOYEE_LIST_FETCH_FAILED,
                    payload: "Fetching user failed",
                });
            }
        } catch (error) {
            dispatch({
                type: EMPLOYEE_LIST_FETCH_FAILED,
                payload: "Fetching user failed",
            });
        }
    };
};

export const fetchEmployeeDropDownList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_EMPLOYEE_DROP_DOWN_LIST_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/employee_drop_down_list`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText === "OK") {
                dispatch({
                    type: EMPLOYEE_DROP_DOWN_LIST_FETCH_SUCCESS,
                    payload: res.data["employee_list"],
                });
            } else {
                dispatch({
                    type: EMPLOYEE_DROP_DOWN_LIST_FETCH_FAILED,
                    payload: "Fetching drop down list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: EMPLOYEE_DROP_DOWN_LIST_FETCH_FAILED,
                payload: "Fetching drop down list failed",
            });
        }
    };
};

export const fetchPartnerList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_LEAD_PARTNER_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/partner_drop_down_list`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText === "OK") {
                dispatch({
                    type: LEAD_PARTNER_FETCH_SUCCESS,
                    payload: res.data["partner_list"],
                });
            } else {
                dispatch({
                    type: LEAD_PARTNER_FETCH_FAILED,
                    payload: "Fetching drop down list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: LEAD_PARTNER_FETCH_FAILED,
                payload: "Fetching drop down list failed",
            });
        }
    };
};
