import ActionCable from "action-cable-react-jwt";
import { API_WS_ROOT } from "./api";
import {
    NEW_COMMENT_RECEIVED,
    NEW_COMMENT_MERGE_SUCCESS,
    NEW_COMMENT_MERGE_FAILED,
    CONNECTED_TO_CHANNEL,
    DISCONNECTED_CHANNEL,
    INCRIMENT_NOTIFICATION_COUNT,
    NEW_NOTIFICATION_RECEIVED,
} from "./types";

export const unsubscribeSocket = (cable, channel) => {
    return async (dispatch) => {
        try {
            cable.subscriptions.remove(channel);
        } catch (error) {
        }
    };
};
export const initializeSocket = (token, data = undefined) => {
    return async (dispatch) => {
        let App = {};
        App.cable = ActionCable.createConsumer(API_WS_ROOT, token);
        let channelData = data
            ? { channel: "ProjectCommentsChannel", project_id: `${data}` }
            : { channel: "UserChannel" };
        const usersChannel = App.cable.subscriptions.create(channelData, {
            connected: () => {
                dispatch({
                    type: CONNECTED_TO_CHANNEL,
                    payload: { channel: usersChannel, cable: App.cable },
                });
            },
            disconnected: () => {
                dispatch({
                    type: DISCONNECTED_CHANNEL,
                });
            },
            received: (notification_data) => {
                if (data) {
                    dispatch({
                        type: NEW_COMMENT_RECEIVED,
                        payload: notification_data,
                    });
                } else {
                    dispatch({
                        type: INCRIMENT_NOTIFICATION_COUNT,
                    });
                    dispatch({
                        type: NEW_NOTIFICATION_RECEIVED,
                        payload: notification_data,
                    });
                }
            },
        });
    };
};
