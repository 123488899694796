import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { storeForm } from '../actions/specmasterAction';

import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CoreUITable from '../components/Layout/Table';

import { useSpecMasterList } from '../hooks/spec-query/spec';

const SpecMasterPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const specQuery = useSpecMasterList();

  const options = [
    {
      text: 'Edit',
      action: function (data) {
        dispatch(storeForm(data));
        history.push('/addspecmaster');
      },
    },
    // {
    //   text: 'Cancel Invoice',
    //   action: function (id) {

    //   },
    // },
  ];

  if (specQuery.isLoading)
    return <ProgressBar striped variant='success' animated now={40} />;

  return (
    <PageTemplate>
      <CoreUITable
        page='specmaster'
        title='Spec Master'
        addPath='addspecmaster'
        options={options}
        data={specQuery.data}
        fields={[
          'name',
          'detailed_name',
          'field_type',
          'allow_symbols',
          'max_characters',
          'min_characters',
          {
            key: 'show_details',
            label: '',
            _style: { width: '1%' },
            filter: false,
          },
        ]}
      />

      {/* <AlertComponent
        title='Cancel Final Invoice'
        description={`Are you sure you cancel this final invoice?`}
        open={showAlert}
        cancelText='No, dont proceed'
        saveText='Yes Proceed'
        handleClose={() => {
          setShowAlert(false);
        }}
        proceed={() => proceedToAbort()}
      /> */}
    </PageTemplate>
  );
};

export default SpecMasterPage;
