import { getMessaging, getToken, onMessage } from "firebase/messaging";
import app from "./firebase";
import { getBrowser } from "./getBrowser";
const messaging = getMessaging(app);

export const checkToken = async (setTokenId, saveToken) => {
    try {
        const currentToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FCM_VAPID,
        });
        const browser = getBrowser()
        if (currentToken) {
            saveToken.mutate({token: currentToken, browser: browser, type: 'save_token'}, {
                onSuccess: () => {
                    setTokenId(currentToken);
                },
                onError: (err) => {
                    console.log("toekn save error",err)
                    setTokenId(null);
                },
            });
        } else {
            setTokenId(null);
        }
    } catch (err) {
        setTokenId(null);
        console.log("error",err)
    }
};

export const onMessageListener = (handleOpenSnackbar) => {
    return onMessage(messaging, (payload) => {
        const { data } = payload;
        console.log("Foreground Message", data, payload);
        const { body } = data;
        handleOpenSnackbar(body);
    });
};
