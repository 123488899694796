import {ADD_PROJECT_ACTIVITY_FAILED,ADD_PROJECT_ACTIVITY_SUCCESS,START_ADD_PROJECT_ACTIVITY,START_PROJECT_ACTIVITY_FETCH,PROJECT_ACTIVITY_FETCH_FAILED,PROJECT_ACTIVITY_FETCH_SUCCESS,
START_PROJECT_ACTIVITY_DUE_DATE_UPDATE,START_PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE,
PROJECT_ACTIVITY_DUE_DATE_UPDATE_SUCCESS,PROJECT_ACTIVITY_DUE_DATE_UPDATE_FAILED,
PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_SUCCESS,PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_FAILED} from '../actions/types';
const DEFAULT_STATE = {
    projectActivityList: [],
    projectActivityAddFailed: true,
    projectActivityAddLoading: true,
    projectActivityAddMessage: '',
    errorMessage:'',
    updateLoading: false,
    updateMessage: '',
    projectActivityFetchLoading: false
};

export default (state = DEFAULT_STATE,action)=>{
    switch(action.type){
        case START_PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE:
            return{
                ...state,
                updateMessage:'',
                updateLoading: true
            }
        case PROJECT_ACTIVITY_DUE_DATE_UPDATE_SUCCESS:
            return{
                ...state,
                updateMessage: action.payload['message'],
                updateLoading: false,
                projectActivityList:action.payload['data']
            }
        case PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_FAILED:
            return{
                ...state,
                updateMessage: action.payload['message'],
                updateLoading: false,
            }
        case START_PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE:
            return{
                ...state,
                updateMessage: '',
                updateLoading: true,
            }
        case PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_FAILED:
            return{
                ...state,
                updateLoading: false,
                updateMessage: action.payload['message']
            }
        case PROJECT_ACTIVITY_ACTIVITY_STATUS_UPDATE_SUCCESS:
            return{
                ...state,
                updateLoading: false,
                projectActivityList: action.payload['data'],
                updateMessage: action.payload['message']
            }
        case START_PROJECT_ACTIVITY_FETCH:
            return{
                ...state,
                projectActivityList: [],
                errorMessage: action.payload,
                projectActivityFetchLoading: true
            }
        case PROJECT_ACTIVITY_FETCH_SUCCESS:
            return{
                ...state,
                errorMessage: '',
                projectActivityList:action.payload,
                projectActivityFetchLoading: false
            }
        case PROJECT_ACTIVITY_FETCH_FAILED:
            return{
                ...state,
                projectActivityList: [],
                errorMessage: action.payload,
                projectActivityFetchLoading: false
            }
        case START_ADD_PROJECT_ACTIVITY:
            return{
                ...state,
                projectActivityAddFailed: false,
                projectActivityAddLoading: true,
                projectActivityAddMessage: ''
            }
        case ADD_PROJECT_ACTIVITY_SUCCESS:
            return{
                ...state,
                projectActivityAddFailed: false,
                projectActivityAddLoading: false,
                projectActivityAddMessage: action.payload
            }
        case ADD_PROJECT_ACTIVITY_FAILED:
            return{
                ...state,
                projectActivityAddFailed: true,
                projectActivityAddLoading: false,
                projectActivityAddMessage: action.payload
            }
        default:
            return state
    }
}

