import { FETCH_START_CURRENCY, FETCH_CURRENCY_SUCCESS, FETCH_CURRENCY_FAILED } from '../actions/types';

const DEFAULT_STATE={
    currency: []
}
export default(state=DEFAULT_STATE, action)=>{
    switch(action.type){
        case FETCH_START_CURRENCY:
        return{
            ...state,
            currency:[]
        }
        case FETCH_CURRENCY_SUCCESS:
            return{
                ...state,
                currency: action.payload
            }
        case FETCH_CURRENCY_FAILED:
            return{
                ...state,
                currency:[]
            }
        default:
            return state
    }
}

