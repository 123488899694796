import React, { Component } from 'react';
import Select from 'react-select';
import { compose } from 'redux';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import { removeItem, addBackItem } from '../../actions/timsheetCodeAction';
import { fetchClientProjects } from '../../actions/projectsAction';
import { removeEmployee, addBackEmployee } from '../../actions/employeeAction';
class MultiSelectComponent extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      isError:
        (this.props.meta.touched && this.props.meta.error) === undefined
          ? false
          : this.props.meta.touched && this.props.meta.error,
      member_ids: [],
      project_ids: [],
    };
  }
  setInitialValues() {
    if (typeof this.props.formValuesClient != 'undefined') {
      switch (this.props.input.name) {
        case 'lead_partner_id':
          return this.props.formValuesClient['lead_partner_id'];
        case 'assignment_partner_id':
          return this.props.formValuesClient['assignment_partner_id'];
        case 'country_id':
          return this.props.formValuesClient['country_id'];
        case 'industry_id':
          return this.props.formValuesClient['industry_id'];
        default:
      }
    }
    if (this.props.formValuesTimesheet !== undefined) {
      switch (this.props.input.name) {
        default:
          let number_pattern = /(\d+)/;
          let num = this.props.input.name.match(number_pattern)[1];
          if (
            this.props.formValuesTimesheet['code_attributes'][num] !== undefined
          ) {
            this.props.dispatch(
              removeItem(
                this.props.formValuesTimesheet['code_attributes'][num][
                  'time_sheet_code_id'
                ]
              )
            );
            return this.props.formValuesTimesheet['code_attributes'][num][
              'time_sheet_code_id'
            ];
          }
      }
    }
    if (typeof this.props.formValues !== 'undefined') {
      switch (this.props.input.name) {
        case 'organisation_id':
          return this.props.formValues['organisation_id'];
        case 'client_id':
          return this.props.formValues['client_id'];
        case 'project_sub_class_id':
          return this.props.formValues['project_sub_class_id'];
        case 'in_charge_employee_id':
          return this.props.formValues['in_charge_employee_id'];
        case 'in_charge_partner_id':
          return this.props.formValues['in_charge_partner_id'];
        case 'sac_master_id':
          return this.props.formValues['sac_master_id'];
        default:
          let number_pattern = /(\d+)/;
          let num = this.props.input.name.match(number_pattern)[1];
          if (
            this.props.formValues['project_members_attributes'][num] !==
            undefined
          ) {
            if (
              this.props.input.name.includes(
                'project_members_attributes[' + num + '].role_id'
              )
            ) {
              return this.props.formValues['project_members_attributes'][num][
                'role_id'
              ];
            } else {
              this.props.dispatch(
                removeEmployee(
                  this.props.formValues['project_members_attributes'][num][
                    'member_id'
                  ]
                )
              );
              return this.props.formValues['project_members_attributes'][num][
                'member_id'
              ];
            }
          }
      }
    }
  }
  handleChange(event) {
    if (this.props.isMulti && event !== null) {
      switch (this.props.input.name) {
        case 'client_dscs_attributes[0].client_ids':
          this.props.dispatch(
            change(
              'AddNewMovementRegister',
              'client_dscs_attributes[0].client_ids',
              event
            )
          );
          break;
        default:
      }
    } else {
      switch (this.props.input.name) {
        case 'organisation_id':
          if (this.props.location.pathname === '/addinvoice') {
            this.props.dispatch(change('AddInvoice', 'organisation_id', event));
          } else if (
            this.props.location.pathname === '/addcertificateregister'
          ) {
            this.props.dispatch(
              change('AddCertificateRegister', 'organisation_id', event)
            );
          } else {
            this.props.dispatch(change('AddProject', 'organisation_id', event));
          }
          break;
        case 'bill_to_client_id':
          this.props.dispatch(change('AddInvoice', 'bill_to_client_id', event));
          break;
        case 'client_id':
          if (this.props.location.pathname === '/addauditdocument') {
            this.props.dispatch(
              fetchClientProjects(this.props.token, {
                id: event.id,
              })
            );
            this.props.dispatch(
              change('AddAuditDocumentRegister', 'client_id', event)
            );
          } else if (this.props.location.pathname === '/addope') {
            this.props.dispatch(
              fetchClientProjects(this.props.token, {
                id: event.id,
                unbilled: true,
              })
            );
            this.props.dispatch(change('AddOpe', 'client_id', event));
          } else if (this.props.location.pathname === '/addinwardregister') {
            this.props.dispatch(
              change('AddInwardRegister', 'client_id', event)
            );
          } else if (this.props.location.pathname === '/addoutwardregister') {
            this.props.dispatch(
              change('AddOutwardRegister', 'client_id', event)
            );
          } else if (this.props.location.pathname === '/addinvoice') {
            //this.props.dispatch(setClientLocation(event.state));
            this.props.dispatch(change('AddInvoice', 'client_id', event));
            this.props.dispatch(
              change('AddInvoice', 'bill_to_client_id', event)
            );
          } else if (
            this.props.location.pathname === '/addcertificateregister'
          ) {
            this.props.dispatch(
              fetchClientProjects(this.props.token, {
                  id: event.id,
                  live_projects: true
              })
            );
            this.props.dispatch(
              change('AddCertificateRegister', 'client_id', event)
            );
          } else {
            this.props.dispatch(change('AddProject', 'client_id', event));
          }
          break;
        case 'project_sub_class_id':
          if (this.props.location.pathname === '/addauditdocument') {
            this.props.dispatch(
              fetchClientProjects(this.props.token, {
                id: event.id,
              })
            );
            this.props.dispatch(
              change('AddAuditDocumentRegister', 'project_sub_class_id', event)
            );
          } else if (this.props.location.pathname === '/addope') {
            this.props.dispatch(
              fetchClientProjects(this.props.token, {
                id: event.id,
                unbilled: true,
              })
            );
            this.props.dispatch(
              change('AddOpe', 'project_sub_class_id', event)
            );
          } else if (this.props.location.pathname === '/addinwardregister') {
            this.props.dispatch(
              change('AddInwardRegister', 'project_sub_class_id', event)
            );
          } else if (this.props.location.pathname === '/addoutwardregister') {
            this.props.dispatch(
              change('AddOutwardRegister', 'project_sub_class_id', event)
            );
          } else if (this.props.location.pathname === '/addinvoice') {
            //this.props.dispatch(setClientLocation(event.state));
            this.props.dispatch(
              change('AddInvoice', 'project_sub_class_id', event)
            );
            this.props.dispatch(
              change('AddInvoice', 'bill_to_project_sub_class_id', event)
            );
          } else if (
            this.props.location.pathname === '/addcertificateregister'
          ) {
            this.props.dispatch(
              fetchClientProjects(this.props.token, {
                id: event.id,
              })
            );
            this.props.dispatch(
              change('AddCertificateRegister', 'project_sub_class_id', event)
            );
          } else {
            this.props.dispatch(
              change('AddProject', 'project_sub_class_id', event)
            );
          }
          break;
        case 'holiday_code_id':
          this.props.dispatch(change('AddHoliday', 'holiday_code_id', event));
          break;
        case 'in_charge_employee_id':
          this.props.dispatch(
            change('AddProject', 'in_charge_employee_id', event)
          );
          break;
        case 'in_charge_partner_id':
          this.props.dispatch(
            change('AddProject', 'in_charge_partner_id', event)
          );
           break;
        case 'industry_id':
          this.props.dispatch(
            change('AddClient', 'industry_id', event)
          );
          break;
        case 'country_id':
          this.props.dispatch(
            change('AddClient', 'country_id', event)
          );
          break;
        case 'sac_master_id':
          if (this.props.location.pathname === '/addProject') {
            this.props.dispatch(change('AddProject', 'sac_master_id', event));
          } else {
            this.props.dispatch(
              change('AddProjectSubClass', 'sac_master_id', event)
            );
          }
          break;
        case 'lead_partner_id':
          this.props.dispatch(change('AddClient', 'lead_partner_id', event));
          break;
        case 'assignment_partner_id':
          this.props.dispatch(
            change('AddClient', 'assignment_partner_id', event)
          );
          break;
        case 'nature_of_expense_id':
          this.props.dispatch(change('AddOpe', 'nature_of_expense_id', event));
          break;
        case 'project_id':
          if (this.props.location.pathname === '/addcertificateregister') {
            this.props.dispatch(
              change('AddCertificateRegister', 'project_id', event)
            );
          } else if (this.props.location.pathname === '/addope') {
            this.props.dispatch(change('AddOpe', 'project_id', event));
          } else if (this.props.location.pathname === '/addauditdocument') {
            this.props.dispatch(
              change('AddAuditDocumentRegister', 'project_id', event)
            );
          } else {
          }
          break;
        case 'signing_partner_id':
          this.props.dispatch(
            change('AddCertificateRegister', 'signing_partner_id', event)
          );
          break;
        case 'activity_id':
          this.props.dispatch(change('AddActivity', 'activity_id', event));
          break;
        case 'employee_id':
          this.props.dispatch(
            change('MovementregisterMovement', 'employee_id', event)
          );
          break;
        case 'main_contractor_id':
          this.props.dispatch(change('AddClient', 'main_contractor_id', event));
          break;
        case 'dsc_custodians_attributes[0].employee_id':
          if (this.props.location.pathname === '/movement_register_movement') {
            this.props.dispatch(
              change(
                'MovementregisterMovement',
                'dsc_custodians_attributes[0].employee_id',
                event
              )
            );
          } else {
            this.props.dispatch(
              change(
                'AddNewMovementRegister',
                'dsc_custodians_attributes[0].employee_id',
                event
              )
            );
          }
          break;
        default:
          let number_pattern = /(\d+)/;
          let num = this.props.input.name.match(number_pattern)[1];
          if (
            this.props.input.name.includes('invoice_basic_details_attributes[')
          ) {
            this.props.dispatch(
              change('AddInvoice', this.props.input.name, event)
            );
          } else if (
            this.props.input.name.includes('invoice_opes_attributes[')
          ) {
            this.props.dispatch(
              change('AddInvoice', this.props.input.name, event)
            );
          } else if (this.props.input.name.includes('code_attributes[')) {
            if (
              this.props.formValuesTimesheet !== undefined &&
              this.props.formValuesTimesheet['code_attributes'] &&
              this.props.formValuesTimesheet['code_attributes'][num] !==
                undefined
            ) {
              if (
                this.props.formValuesTimesheet['code_attributes'][num][
                  'time_sheet_code_id'
                ] !== undefined
              ) {
                this.props.dispatch(
                  addBackItem(
                    this.props.formValuesTimesheet['code_attributes'][num][
                      'time_sheet_code_id'
                    ]
                  )
                );
              }
              this.props.dispatch(
                change('TimeSheet', this.props.input.name, event)
              );
              this.props.dispatch(removeItem(event));
            } else {
              this.props.dispatch(
                change('TimeSheet', this.props.input.name, event)
              );
              this.props.dispatch(removeItem(event));
            }
          } else if (
            this.props.input.name.includes('project_members_attributes[')
          ) {
            if (
              this.props.input.name.includes(
                'project_members_attributes[' + num + '].role_id'
              )
            ) {
              this.props.dispatch(
                change('AddProject', this.props.input.name, event)
              );
            } else if (
              this.props.formValues !== undefined &&
              this.props.formValues['project_members_attributes'] &&
              this.props.formValues['project_members_attributes'][num] !==
                undefined
            ) {
              if (
                this.props.formValues['project_members_attributes'][num][
                  'member_id'
                ] !== undefined
              ) {
                this.props.dispatch(
                  addBackEmployee(
                    this.props.formValues['project_members_attributes'][num][
                      'member_id'
                    ]
                  )
                );
              }
              this.props.dispatch(
                change('AddProject', this.props.input.name, event)
              );
              this.props.dispatch(removeEmployee(event));
              this.props.dispatch(
                change(
                  'AddProject',
                  'project_members_attributes[' + num + '].designation',
                  event.designation
                )
              );
            } else {
              this.props.dispatch(
                change('AddProject', this.props.input.name, event)
              );
              this.props.dispatch(removeEmployee(event));
              this.props.dispatch(
                change(
                  'AddProject',
                  'project_members_attributes[' + num + '].designation',
                  event.designation
                )
              );
            }
          }
      }
    }
  }
  render() {
    let menuItems = (
      <Select
        isMulti={this.props.isMulti}
        isDisabled={this.props.isDisabled}
        value={
          this.props.input.value
            ? this.props.input.value
            : this.setInitialValues()
        }
        name='colors'
        options={this.props.data}
        onBlur={() => {
          this.props.input.onBlur(this.props.input.value);
        }}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        onChange={this.handleChange}
        className='basic-multi-select'
        classNamePrefix='select'
        styles={{
          menu: (base) => ({
            ...base,
            zIndex: 100,
          }),
          option: (styles, { data }) => ({
            ...styles,
            ...(data.color && { color: data.color }),
          }),
        }}
      />
    );

    return (
      <FormControl
        error={this.state.isError}
        variant='filled'
        fullWidth={this.props.fullWidth}
      >
        <InputLabel htmlFor='select-multiple-checkbox'>
          {this.props.label}
        </InputLabel>
        {menuItems}
        <FormHelperText>
          {this.props.meta.touched && this.props.meta.error}
        </FormHelperText>
      </FormControl>
    );
  }
}
function mapStateToProps(state) {
  return {
    formValues: getFormValues('AddProject')(state),
    formValuesClient: getFormValues('AddClient')(state),
    formValuesTimesheet: getFormValues('TimeSheet')(state),
    token: state.auth.token,
  };
}
export default compose(
  withRouter,
  connect(mapStateToProps)
)(MultiSelectComponent);
