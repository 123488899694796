import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { storeForm } from '../actions/specmasterAction';

import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
import CoreUITable from '../components/Layout/Table';

import { useSpecifices } from '../hooks/specifics-query/specific';

const SpecificsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const specificQuery = useSpecifices();
  const options = [
    {
      text: 'Edit',
      action: function (data) {
        dispatch(storeForm(data));
        history.push('/addspecific');
      },
    },
    // {
    //   text: 'Delete Specific',
    //   action: function (id) {
    //     setShowAlert(true);
    //     setInvoiceId(id);
    //   },
    //   params: true,
    // },
  ];

  if (specificQuery.isLoading)
    return <ProgressBar striped variant='success' animated now={40} />;

  return (
    <PageTemplate>
      <CoreUITable
        page='specifics'
        title='Specifics'
        addPath='addspecific'
        options={options}
        data={specificQuery.data}
        fields={[
          'id',
          'description',
          'project_sub_class',
          'spec_master',
          {
            key: 'show_details',
            label: '',
            _style: { width: '1%' },
            filter: false,
          },
        ]}
      />

      {/* <AlertComponent
        title='Cancel Final Invoice'
        description={`Are you sure you cancel this final invoice?`}
        open={showAlert}
        cancelText='No, dont proceed'
        saveText='Yes Proceed'
        handleClose={() => {
          setShowAlert(false);
        }}
        proceed={() => proceedToAbort()}
      /> */}
    </PageTemplate>
  );
};

export default SpecificsPage;
