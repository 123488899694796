import {START_FETCH_DESIGNATION, DESIGNATION_FETCH_SUCCESS, DESIGNATION_FETCH_FAILED} from '../actions/types';

const DEFAULT_STATE={
    designation:[]
}
export default(state=DEFAULT_STATE, action)=>{
    switch(action.type){
        case START_FETCH_DESIGNATION:
        return{
            ...state,
            designation:[]
        }
        case DESIGNATION_FETCH_SUCCESS:
            return{
                ...state,
                designation: action.payload
            }
        case DESIGNATION_FETCH_FAILED:
            return{
                ...state,
                designation:[]
            }
        default:
            return state
    }
}

