import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import Badge from 'react-bootstrap/Badge'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link, Redirect} from "react-router-dom";
import {startSignin,login} from "../../actions"
import logo from '../../assets/cue.png'
import jj_logo from '../../assets/jj.png'
import lock from '../../assets/lock.svg';
const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
        textTransform: "none"
    },
    registerLink: {
        textDecoration: "none",
        display: "block",
        padding: "20px 0",
        color: "primary",
        float: 'right'
    },
    topPart:{
        backgroundColor: theme.palette.secondary.main
    }
});
export const renderInput = ({
    input,
    label,
    meta: { touched, error },
    ...custom
}) => (
        <Input
            {...input}
            {...custom}
        />
    )


class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.onsubmit = this.onsubmit.bind(this);
    }
    componentDidMount(){
        this.props.startSignin()
      }
    async onsubmit(formData) {
        await this.props.login(formData);
        if(!this.props.errorMessage){
            this.props.history.push("/");
        }
    }


    render() {
        const { classes, handleSubmit } = this.props;
        if(this.props.isAuth){
            return(
                <Redirect to='/'/>
            )
        }
        return (

            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                <div className="rounded" >
                <Row className="d-flex justify-content-center">
                        <Image src={logo} className="w-50 p-3 h-75 d-inline-block"/>
                </Row>
                <Row className="d-flex justify-content-center">
                        <Image src={jj_logo} className="w-75 p-3 h-75 d-inline-block"/>
                </Row>
                <Row className="d-flex justify-content-center">
                    <h3>
                        <Image src={lock} className="d-inline-block"/>
            {/**<Badge pill variant="light">
                            Login to Cue
                        </Badge>**/}
                    </h3>
                </Row>
                </div>
                    <form className={classes.form} onSubmit={handleSubmit(this.onsubmit)}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="work_email">Email Address</InputLabel>
                            <Field component={renderInput} id="work_email" name="email" autoComplete="email" autoFocus />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Field component={renderInput} name="password" type="password" id="password" autoComplete="current-password" />
                        </FormControl>
                        {
                            this.props.errorMessage ?
                                <Typography component="h6" >
                                    {this.props.errorMessage}
                                </Typography> : ""
                        }
                        <Link className={classes.registerLink} to="/signup">
                            Sign Up
                    </Link>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign In
                    </Button>

                    </form>
                </Paper>
            </main>
        );
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        errorMessage: state.auth.errorMessage,
        isAuth : state.auth.isAuthenticated,
    }
}

export default compose(
    connect(mapStateToProps, {startSignin,login}),
    withStyles(styles),
    reduxForm({ form: "SignIn" })
)(SignIn);
