import {UPDATED_FORM} from "../actions/types";
const DEFAULT_STATE={

}
export default (state =DEFAULT_STATE,action)=>{
    switch(action.type){
        case UPDATED_FORM:
            return{
                ...state
            }
        default:
            return state
    }
}
