import React, { Component,View } from 'react';
import { Link } from 'react-router-dom';
// Externals
import DemoTable from './demoTable'
import CIcon from '@coreui/icons-react'
//testing out core-ui
import {
      CBadge,
      CCard,
      CCardBody,
      CCardHeader,
      CCol,
      CDataTable,
      CRow
} from '@coreui/react';
import * as actions from "../../actions";
import AddIcon from "../../assets/add.svg";
import moment from 'moment';
import { compose } from "redux";
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { UpdateDialog,padZeros, getMinutes} from "../../utils/formComponents";
import styles from '../modelstyles';
import { withStyles } from '@material-ui/core';
import isEqual from 'lodash/isEqual';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


class CoreUITable extends Component {
    constructor(props){
        super(props)
        this.state = {
            showBookingForm: false,
            project: {},
            bookingData: {
                date: new Date(),
                allocated_time: '00:00',
                day_total: '00:00',
                showFileds: false,
                warn: false,
                warnMessage: '',
                button_disabled: true
            },
            showTimesheetAlert: false
        }
        this.handleBookingChange = this.handleBookingChange.bind(this)
        this.bookTime = this.bookTime.bind(this)
        this.startBooking = this.startBooking.bind(this)
    }
     handleBookingChange(e){
         this.setState({
            bookingData:{
                ...this.state.bookingData,
                [e.target.id]: e.target.value,
                warn: false,
            }
         })
         if(e.target.id === 'date'){

             let disabled = this.compareDates(moment(e.target.value).format("DD-MM-YYYY"),moment(new Date()).format("DD-MM-YYYY"))
             if(!disabled){
                this.props.fetchTimeBooked(
                    {
                        project_id: this.state.bookingData.project_id,
                        date: e.target.value
                    },
                    this.props.token
                )
            }else{
                this.setState({
                    bookingData:{
                        ...this.state.bookingData,
                        showFileds: false,
                        warn: true,
                        warnMessage: "You are not allowed to edit or book on this date"
                    }
                })

            }
         }

     }
    startBooking(data){
        this.setState({showBookingForm: true})
        this.setState({bookingData: {}})
        this.setState({
            bookingData:{
                ...this.state.bookingData,
                project_id: data.id,
                project_name: data.name,
                date: moment(new Date()).format("YYYY-MM-DD"),
                showFileds: false,
                button_disabled: true,
                warnMessage: '',
                warn: false
            }
        })
        this.props.fetchTimeBooked(
                 {
                     project_id: data.id,
                     date: moment(new Date()).format("YYYY-MM-DD")
                 },
                 this.props.token
             )


    }
    bookTime(){
        if(getMinutes(this.state.bookingData.day_total)> 420){
        }else if(getMinutes(this.state.bookingData.day_total)> 1440){
            //this dosent happen
        }else{}
        this.props.bookTimeForProject({
                project_id: this.state.bookingData.project_id,
                date: this.state.bookingData.date,
                allocated_time: getMinutes( this.state.bookingData.allocated_time)
            },
            this.props.token
        )
    }
    compareDates=(d1, d2)=>{
         let result = false;
         if(d1 && d2){
            var parts_d1 =d1.split('-');
            var d1 = Number(parts_d1[2] + parts_d1[1] + parts_d1[0]);
            var parts_d2 = d2.split('-');
            var d2 = Number(parts_d2[2] + parts_d2[1] + parts_d2[0]);
            result = d1>d2;
            //logic for 10 days backward not editable https://stackoverflow.com/a/2627493/5706413
             if(!result){
                const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                const firstDate = new Date(parts_d1[2], parts_d1[1]-1, parts_d1[0]);
                const secondDate = new Date(parts_d2[2], parts_d2[1]-1, parts_d2[0]);
                const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
                if(this.props.editable_days['value']){
                    result = diffDays>Number(this.props.editable_days['value']);
                }else{
                   result = diffDays>5
                }
            }
         }
        return result;
    }

    showErrorAlert(){
        if(this.props.page === 'pendingRecApprovel' ){
            return (this.props.autoRecurring['autoApproveMsg'] !== '' ? this.props.autoRecurring['autoApproveMsg'] : this.props.autoRecurring['autoApproveError'])
        }else if(this.props.page === 'project' && this.props.timeSheet.timesheetAddMessage !=''){
            return  this.props.timeSheet.timesheetAddMessage
        }else{
            return ''
        }

     }
     renderTable=(page)=>{
		let fields = []
		let data = []
        let temp = <></>
        let title = ''
        let add_path = ''

		if(page=="project" || page === "pendingArchivedProjects" || page === "archivedProjects"){
            fields = ['project_id','name','client_name','status','due_date','billing_status',{
                  key: 'show_details',
                  label: '',
                  _style: { width: '1%' },
                  filter: false
                }];
			data = this.props.project
            temp = <DemoTable fields={fields} data={this.props.project} page = {page} loading={this.props.autoRecurring.loading} showBookingForm = {(data)=> {this.startBooking(data)}}/>
            if( page=="project"){
                title = "Projects"
                add_path = '/addproject'
            }else if(page === 'pendingArchivedProjects'){
                title = "Pending Archived Projects"
            }else{
                title = "Archived Projects"
            }
		}else if(page === 'pendingRecApprovel'){
			fields = ['name','occurence','client_name', {
                  key: 'show_details',
                  label: '',
                  _style: { width: '1%' },
                  filter: false
                }]
            data = this.props.project
            title = "Pending Auto Create Approvel"
			temp = <DemoTable fields={fields} data={this.props.project} page = {page} loading={this.props.autoRecurring.loading} reject={(data)=>this.props.approvelActionPendingApprovel(this.props.token, 'reject', data)} approve={(data)=>this.props.approvelActionPendingApprovel(this.props.token, 'approve', data)}/>
		}else if(this.props.page === 'final_invoices'){
            fields = ['id','date','client','organisation','invoice_number', {
                  key: 'show_details',
                  label: '',
                  _style: { width: '1%' },
                  filter: false
                }]
            data = this.props.invoicesList.invoicesList
            title = "Final Invoices"
			temp = <DemoTable fields={fields} data={data} page = {page} loading={this.props.invoicesList.loading} />

        }else if(this.props.page === 'invoice'){
            fields = ['id','date','client','organisation', {
                  key: 'show_details',
                  label: '',
                  _style: { width: '1%' },
                  filter: false
                }]
            data = this.props.invoicesList.invoicesList
            title = "Invoices"
			temp = <DemoTable fields={fields} data={data} page = {page} loading={this.props.invoicesList.loading} />

        }else{
        }
            return(
                <><CRow>
                    <CCol sm="12">
                    <CCard>
                    <CCardHeader>
                        {title}
                    </CCardHeader>
                        <>{temp}</>
					</CCard>
                    </CCol>
                </CRow>
				<Snackbar open={this.props.autoRecurring['autoApproveMsg'] !== '' || this.props.autoRecurring['autoApproveError'] !== '' || (this.props.timeSheet.timesheetAddMessage !=='' && this.state.showTimesheetAlert)} autoHideDuration={600} >
        <Alert  severity= {(this.props.autoRecurring['autoApproveMsg'] !== '' || !this.props.timeSheet.timesheetAddFailed) ? "success" : "error"}>
            {this.showErrorAlert()}
        </Alert>
      </Snackbar></>
                )
     }
    componentDidMount() {
        if(this.props.page==="pendingRecApprovel"){
            this.props.fetchPendingAutoCreateProjectList(this.props.token)
        }else if(this.props.page === "project"){
            this.props.fetchProject(this.props.token)
            this.props.fetchSetting("days_editable_timesheet",this.props.token)
        }else if(this.props.page === "pendingArchivedProjects"){
            this.props.fetchAllProjectByFilter(this.props.token,"Pending_archival")
        }else if(this.props.page === "archivedProjects"){
            this.props.fetchAllProjectByFilter(this.props.token,"Archived")
        }else if(this.props.page === 'final_invoices'){
            this.props.fetchInvoicesList(this.props.token, "Final")
        } else if(this.props.page === 'invoice'){
            this.props.fetchInvoicesList(this.props.token, "Draft")
        }else{
        }
    }
    formatAndAssignTime(data){
        let hour = Math.floor(data/60);
        hour = padZeros(hour);
        let min = (data%60);
        min = padZeros(min);
        return hour+":"+min
    }
    componentDidUpdate(prevProps,prevState){
        let day_total = 0
        if(!isEqual(this.props.timeSheet.timesheet, prevProps.timeSheet.timesheet)){
            if(this.props.timeSheet.timesheet && this.props.timeSheet.timesheet.day_total < 0){
                day_total = Math.abs(this.props.timeSheet.timesheet.day_total) + 420
            }else{
                day_total = this.props.timeSheet.timesheet.day_total + this.props.timeSheet.timesheet.allocated_time
            }
            this.setState({
            bookingData:{
                ...this.state.bookingData,
                day_total: day_total ? this.formatAndAssignTime(day_total)  : '00:00',
                allocated_time: this.props.timeSheet.timesheet.allocated_time ? this.formatAndAssignTime(this.props.timeSheet.timesheet.allocated_time) : '00:00',
                showFileds: true,
                button_disabled: day_total > 1440 ? true :false,
                warn: day_total > 420 ? true : false,
                warnMessage: day_total > 1440 ? " You are trying to book more than 24 hrs for this date"  : " You are trying to book more than 7 hrs for this date"
            }
        })
        }
        if(this.state.bookingData.allocated_time !== prevState.bookingData.allocated_time){
            if(this.props.timeSheet.timesheet && this.props.timeSheet.timesheet.day_total < 0){
                day_total = Math.abs(this.props.timeSheet.timesheet.day_total) + 420
            }else{
                day_total = this.props.timeSheet.timesheet.day_total ?this.props.timeSheet.timesheet.day_total  : 0
            }
            let total = day_total + getMinutes(this.state.bookingData.allocated_time)
            this.setState({
                bookingData:{
                    ...this.state.bookingData,
                    day_total: this.formatAndAssignTime(total)
                }
            })
        }
        if(this.state.bookingData.day_total !== prevState.bookingData.day_total){
            if(getMinutes(this.state.bookingData.day_total) > 420 && getMinutes(this.state.bookingData.day_total) <= 1440 && !this.state.bookingData.warn ){
                this.setState({
                    bookingData:{
                        ...this.state.bookingData,
                        warn: true,
                        warnMessage: "You are trying to book  more than 7 hrs for this date!!",
                        button_disabled: false
                    }
                })
            }else if( getMinutes(this.state.bookingData.day_total) < 420 && this.state.bookingData.warn){
                this.setState({
                    bookingData:{
                        ...this.state.bookingData,
                        warn: false,
                        button_disabled: false
                    }
                })
            }else if(getMinutes(this.state.bookingData.day_total) > 1440 &&  !this.state.bookingData.button_disabled){
                this.setState({
                    bookingData:{
                        ...this.state.bookingData,
                        warn: true,
                        button_disabled: true,
                        warnMessage: "You are trying to book more than 24 hrs for this date"
                    }
                })
            }else if(getMinutes(this.state.bookingData.day_total) < 1440 && this.state.bookingData.button_disabled){
                this.setState({
                    bookingData:{
                        ...this.state.bookingData,
                        warn: getMinutes(this.state.bookingData.day_total)> 420 ? true : false,
                        button_disabled: false,
                        warnMessage: "You are trying to book more than 7  hrs for this date!!"
                    }
                })
            }
        }
        if( this.props.timeSheet.timesheetAddSuccess !== prevProps.timeSheet.timesheetAddSuccess){
            this.setState({showTimesheetAlert: true})
            this.setState({
                     showBookingForm: false
            })
            setTimeout(()=> {
            this.setState({
                     showTimesheetAlert: false
                })
            }, 600);
        }
    }
    render(){
        return(
            <>
                {this.renderTable(this.props.page)}
                <UpdateDialog open={ this.props.page === 'project' && this.state.showBookingForm}
                    name = "book_time"
                    title = "Book Time"
                    description = {this.state.bookingData.project_name}
                    handleClose = {()=>this.setState({showBookingForm: false})}
                    update = {this.bookTime}
                    value = {this.state.bookingData}
                    handleChange = {this.handleBookingChange}
                className={this.props.classes.hourMinutes}
                loading={this.props.timeSheet.timesheet.loading}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        clients:state.client.client,
        project:state.project.projectList,
		autoRecurring: state.project,
        invoicesList: state.invoice,
        token:state.auth.token,
        projectCategory:state.projectClass.dprojectCategoryList,
        projectSuperClass: state.projectSuperClass.dprojectCategoryList,
        clientClass:state.clientClass.dclientclassList,
        organisation : state.organisation.dorganisationList,
        natureofexpense :state.natureofexpense.dnatureofexpenseList,
        opeList:state.ope.opeList,
        employeeList:state.employee.employeeList,
        clientSearchList:state.search.clientSearchList,
        projectSearchList:state.search.projectSearchList,
        movementRegisterSearchList: state.search.movementRegisterSearchList,
        searchng:state.search.searching,
        issueRegisterList:state.issueregister.issueRegisterList,
        leaveList:state.leave.leaveList,
        certificateRegisterList: state.certificateregister.certificateRegisterList,
        movementRegisterList: state.movementregister.movementRegisterList,
        activityList: state.activity.activityList,
        auditDocumentList: state.auditDocument.auditDocumentList,
        outwardRegisterList: state.outwardRegister.outwardRegisterList,
        inwardRegisterList: state.inwardRegister.inwardRegisterList,
        sacMasterList: state.sacmaster.sacMasterList,
        holidayMaster: state.holidayMaster.holidayList,
        attendence: state.attendence.attendenceList,
        settingsList: state.settingsReducer.settingsList,
        timeSheet: state.timesheet,
        editable_days: state.settingsReducer.setting,
    }
}

export default compose(
    connect(mapStateToProps, actions),
    withStyles(styles)
)(CoreUITable);
