import {CLIENT_SEARCH_DONE,CLIENT_SEARCH_FAILED,START_CLIENT_SEARCH,SEARCH_OVER,
START_PROJECT_SEARCH,PROJECT_SEARCH_DONE,PROJECT_SEARCH_FAILED, START_SEARCH_MOVEMENT_REGISTER,MOVEMENT_REGISTER_SERACH_SUCCESS,MOVEMENT_REGISTER_SERACH_FAILED} from "../actions/types";
const DEFAULT_STATE ={
    projectSearchList:[],
    clientSearchList: [],
    movementRegisterSearchList:[],
    searching:false,
    loading: false,
    errorMessage:'',
};

export default (state =DEFAULT_STATE,action)=>{
    switch(action.type){
        case START_PROJECT_SEARCH:
            return{
                ...state,
                searching:true,
                loading:true,
                projectSearchList:[]
            }

        case PROJECT_SEARCH_DONE:
            return{
                ...state,
                loading:false,
                projectSearchList: action.payload
            }
        case PROJECT_SEARCH_FAILED:
            return{
                ...state,
                loading:false,
                projectSearchList: []

            }
        case START_CLIENT_SEARCH:
            return{
                ...state,
                searching:true,
                loading:true,
                clientSearchList:[]
            }

        case CLIENT_SEARCH_DONE:
            return{
                ...state,
                loading:false,
                clientSearchList:action.payload
            }
        case CLIENT_SEARCH_FAILED:
            return{
                ...state,
                loading:false,
                clientSearchList: []

            }
        case SEARCH_OVER:
            return{
                ...state,
                searching:false
            }
        case START_SEARCH_MOVEMENT_REGISTER:
            return {
                ...state,
                searching:true,
                loading:true,
                movementRegisterSearchList:[]
            }
        case MOVEMENT_REGISTER_SERACH_SUCCESS:
            return {
                ...state,
                loading:false,
                movementRegisterSearchList: action.payload
            }
        case MOVEMENT_REGISTER_SERACH_FAILED:
            return {
               ...state,
                loading:false,
                 movementRegisterSearchList: []

            }

        default :
            return state

    }
}
