import {SET_PROJECT_DASHBOARD_DATA} from './types';

export const setProjectDashboard=(data)=>{
    return async (dispatch)=>{
        dispatch({
            type: SET_PROJECT_DASHBOARD_DATA,
            payload: data
        })
    }
}
