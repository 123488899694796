import {
    START_FETCH_ROLES,
    ROLE_FETCH_SUCCESS,
    ROLE_FETCH_FAILED,
} from "./types";
import axios from "axios";
import { BaseUrl } from "./api";
export const fetchMemberRoles = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_FETCH_ROLES,
        });
        try {
            const res = await axios.get(`${BaseUrl}/role`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText === "OK") {
                dispatch({
                    type: ROLE_FETCH_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: ROLE_FETCH_FAILED,
                    payload: "fetching role  failed",
                });
            }
        } catch (error) {
            dispatch({
                type: ROLE_FETCH_FAILED,
                payload: error.message,
            });
        }
    };
};
