import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
//import CommonList from '../views/CommonTableView';
// import AboutWrapper from '../containers/AboutWrapper/AboutWrapper'
import CoreUITable from '../views/coreUITable';
const InvoicePage = () => {
    return (
        <PageTemplate>
           <CoreUITable page={"invoice"}/>
        </PageTemplate>
    );
};

export default InvoicePage;

