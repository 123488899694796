import { QueryClient, useQuery } from "react-query";
import axios from "../../axiosConfig";

async function getSettingsByKey({ queryKey }) {
    try {
        const res = await axios.get(
            `/settings/${queryKey[1]}`
        );
        return res.data['setting'];
    } catch (err) {
        throw err.response.data;
    }
}
export const useGetSettings = (data) =>
    useQuery(["settings", data], getSettingsByKey, {
        refetchOnWindowFocus: false,
    });
