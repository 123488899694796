import {START_FETCH_ATTENDENCE_LIST, FETCH_ATTENDENCE_LIST_SUCCESS,FETCH_ATTENDENCE_LIST_FAILED, START_MARK_ATTENDENCE,MARK_ATTENDENCE_SUCCESS, MARK_ATTENDENCE_FAILED} from '../actions/types';

const DEFAULT_STATE={
    attendenceList:[],
    loading: false,
    addAttendenceLoading: false,
    addAttendenceFailed: true,
    addAttendenceMessage:''
}

export default (state=DEFAULT_STATE,action)=>{
    switch(action.type){
        case START_FETCH_ATTENDENCE_LIST:
            return{
                ...state,
                attendenceList:[],
                loading: true
            }
        case FETCH_ATTENDENCE_LIST_SUCCESS:
            return{
                ...state,
                attendenceList: action.payload,
                loading: false
            }
        case FETCH_ATTENDENCE_LIST_FAILED:
            return{
                ...state,
                loading: false
            }
        case START_MARK_ATTENDENCE:
            return{
                ...state,
                addAttendenceLoading: true,
                addAttendenceFailed: false,
                addAttendenceMessage: ''
            }
        case MARK_ATTENDENCE_SUCCESS:
            return{
                ...state,
                addAttendenceLoading: false,
                addAttendenceFailed: false,
                addAttendenceMessage: action.payload
            }
        case MARK_ATTENDENCE_FAILED:
            return{
                ...state,
                addAttendenceLoading: false,
                addAttendenceFailed: true,
                addAttendenceMessage: action.payload
            }
        default:
            return state
    }
}
