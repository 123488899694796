import React, { useState,useEffect,useCallback} from 'react'
import {
  CCardBody,
  CBadge,
  CButton,
  CCollapse,
  CDataTable
} from '@coreui/react'
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
    const DemoTable = (props) => {
    const [details, setDetails] = useState([])
    const [editPath,setEditPath] = useState('')
    const [addPath,setAddPath] = useState('')
    const [viewPath,setViewPath] = useState('')
    const [perPage, setPerPage] = useState(localStorage.getItem(`rows_per_page_${props.page}`) !== null ? localStorage.getItem(`rows_per_page_${props.page}`) : 10)
  // const [items, setItems] = useState(usersData)

  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }

   useEffect(()=>{
        if(props.page=="project" || props.page === "pendingArchivedProjects" || props.page === "archivedProjects"){
            setViewPath("/projectdashboard")
            setEditPath("/addProject")
            if(props.page === "project") setAddPath('/addProject')
        }else if(props.page === 'invoice') {
            setAddPath('/addinvoice')
            setEditPath('/addinvoice')
        }else if(props.page === 'final_invoices'){
            setAddPath('/addinvoice')
            setViewPath('/viewInvoice')
        }else{}
       setPerPage(localStorage.getItem(`rows_per_page_${props.page}`) !== null ? localStorage.getItem(`rows_per_page_${props.page}`) : 10)
   },[props])
    const paginationChanged = useCallback((e)=>{
        localStorage.setItem(`rows_per_page_${props.page}`,e)
        setPerPage(e)
    },[])
  const fields = [
    { key: 'name', _style: { width: '40%'} },
    'registered',
    { key: 'role', _style: { width: '20%'} },
    { key: 'status', _style: { width: '20%'} },
    {
      key: 'show_details',
      label: '',
      _style: { width: '1%' },
      filter: false
    }
  ]

  const getBadge = (status)=>{
    switch (status) {
      case 'Active': return 'success'
      case 'Inactive': return 'secondary'
      case 'Pending': return 'warning'
      case 'Banned': return 'danger'
      default: return 'primary'
    }
  }
    const viewButtons = useCallback((item)=>{
      if(props.page === 'pendingRecApprovel'){
          return (
            <>
                    <CButton size="sm" color="info" onClick={() => {props.approve(item.id) }}>
                      Approve
                    </CButton>
                    <CButton size="sm" color="danger" className="ml-1" onClick={() => {props.reject(item.id) }}>
                      Reject
                    </CButton>
            </>
          )
      }else if(props.page === 'archivedProjects' || props.page === 'pendingArchivedProjects'){
        return(
            <>
                    <Link to={{pathname: viewPath, data:{"project_data": item}}}>
                    <CButton size="sm" color="info">
                      View details
                    </CButton></Link>
            </>
        )
    }else if(props.page === 'project'){
        return(
            <>
                    <Link to={{pathname: viewPath, data:{"project_data": item}}}>
                    <CButton size="sm" color="info">
                      View details
                    </CButton></Link>
                    <Link to={{pathname: editPath, data:{"id": item.id,"type":"update"}}}>
                    <CButton size="sm" color="danger" className="ml-1">
                      Edit
                    </CButton>
                    </Link>
                    <CButton size="sm" color="info" className="ml-1" onClick={()=>props.showBookingForm(item)}>
                      Book Time
                    </CButton>
            </>
        )
    }else if(props.page === 'final_invoices'){
        return(
            <>
            <Link to={{pathname: `${viewPath}/${item.id}`}} target="_blank" rel="noopener noreferrer">
                    <CButton size="sm" color="info">
                      View invoice
                    </CButton></Link>
            </>
        )
    }else if(props.page === 'invoice'){
        return(
            <>
                    <Link to={{pathname: editPath, data:{"id": item.id,"type":"update"}}}>
                    <CButton size="sm" color="info">
                      Edit
                    </CButton></Link>
            </>
        )

    }else{

    }
  },[props.page,viewPath,editPath,addPath])
  return (
    <CCardBody>
        <div style={{ flexDirection: 'row' }}>
            {addPath !== '' ? <div style={{marginLeft: '96%'}} >
            <Link
                className="button--signup--intro button"
                to={addPath}>
                    <IconButton color="primary" aria-label="add">
                        <Icon>add_plus</Icon>
                    </IconButton>
            </Link>
            </div> : null}
      <CDataTable
        items={props.data}
        fields={props.fields}
        columnFilter
        tableFilter
        cleaner
        itemsPerPageSelect
        itemsPerPage={perPage}
        hover
        sorter
        pagination
      onPaginationChange = {(e)=>paginationChanged(e)}
      loading = {props.loading}
        scopedSlots = {{

          'show_details':
            item => {
              return (
                <td className="py-2">
                  <CButton
                    color="primary"
                    variant="outline"
                    shape="square"
                    size="sm"
                    onClick={() => { toggleDetails(item.id) }}
                  >
                    { details.includes(item.id) ? 'Hide' : 'Show' }
                  </CButton>
                </td>
              )
            },
          'details':
              item => {
                return (
                <CCollapse show={details.includes(item.id)}>
                  <CCardBody>
                    <h4>
                      {item.name}
                    </h4>
                    {viewButtons(item)}
                  </CCardBody>
                </CCollapse>
              )
            }
        }}
            /></div>
    </CCardBody>
  )
}

export default DemoTable

