import React from "react";
import PageTemplate from "../components/Layout/PageTemplate/PageTemplate";
import CoreUITable from "../components/Layout/Table";
import ProgressBar from "react-bootstrap/ProgressBar";
import { fetchNotifications } from "../actions/notificationAction";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_NOTIFICATION_COUNT } from "../actions/types";
import { useMarkNotificationAsRead } from "../hooks/notification-query";
const NotificationsPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(true);
    const state = useSelector((state) => state);
    const { auth, notifications } = state;
    const { mutate } = useMarkNotificationAsRead();
    React.useEffect(() => {
        dispatch(fetchNotifications(auth.token)).then(() =>
            setIsLoading(false)
        );
    }, []);
    if (isLoading)
        return <ProgressBar striped variant="success" animated now={40} />;
    const options = [
        {
            text: "View",
            action: function (id) {
                let temp = notifications.notificationList.find(
                    (x) => x.id === id
                );
                if (!temp.read) {
                    mutate(temp.notification_id); //mark this notification as read,
                    dispatch({ type: CLEAR_NOTIFICATION_COUNT }); //actually decriment by 1
                }
                history.push({ pathname: "/leave_details", id: id });
            },
            params: true,
        },
    ];
    return (
        <PageTemplate>
            <CoreUITable
                page="notifications"
                title="Notifications"
                options={options}
                data={notifications.notificationList}
                fields={[
                    "id",
                    "msg",
                    "type",
                    "read",
                    {
                        key: "show_details",
                        label: "",
                        _style: { width: "1%" },
                        filter: false,
                    },
                ]}
            />
        </PageTemplate>
    );
};

export default NotificationsPage;
