//@format
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import PendingVerification from "./views/PendingVerification";
import ApproveEmployee from "./views/ApproveEmployee";
import FirmAssetDetails from "./views/FirmAssetDetails";
import AddAttendence from "./views/AddAttendence";
import AddHoliday from "./views/AddHolidayMaster";
import AddOpe from "./views/AddOpe";
import AddAuditDocumentRegister from "./views/AddAuditDocumentRegister";
import AddProject from "./views/AddProject";
import PendingAttendence from "./views/PendingAttendence";
import AddOutwardRegister from "./views/AddOutwardRegister";
import AddInwardRegister from "./views/AddInwardRegister";
import AddSettings from "./views/AddSettings";
import ApproveLeave from "./views/ApproveLeave";
import AddClient from "./views/AddClient";
import AddProjectActivity from "./views/AddProjectActivity";
import AddActivity from "./views/AddActivity";
import AddNewRegister from "./views/AddNewRegister";
import AddInvoice from "./views/AddInvoice";
import DraftView from "./views/AddInvoice/ViewInvoice"; //draft
import AddNewMovementRegister from "./views/AddNewMovementRegister";
import MovementregisterMovement from "./views/MovementregisterMovement";
import MovementRegisterInForm from "./views/MovementRegisterInForm";
import MovementRegisterOutForm from "./views/MovementRegisterOutForm";
import AddOrganisation from "./views/AddOrganisation";
import AddNatureOfExpense from "./views/AddNatureOfExpense";
import AddClientClass from "./views/AddClientClass";
import AddCertificateRegister from "./views/AddCertificateRegister";
import DscDetails from "./views/DscDetails";
import ViewDoc from "./views/ViewDoc";
import ClientDetails from "./views/ClientDetails";
import ProjectDashboard from "./views/ProjectDashboard";
import ProjectDetails from "./views/ProjectDetails";
import GstReport from "./views/GstReport";
import UdinPending from "./views/PendingUdin";
import AddSacMaster from "./views/AddSacMaster";
import EditRecurringProject from "./views/EditRecurrence";
//import InvoiceDetails from './views/InvoiceDetails';
//import CertificateregisterDetails from './views/CertificateregisterDetails';
import ApplyForLeave from "./views/ApplyForLeave";
import AddProjectSubClass from "./views/AddProjectSubClass";
import ViewInvoice from "./views/ViewInvoice";
import AddProjectClass from "./views/AddProjectClass";
import authGuard from "./components/HOCs/auth-guard";
import AddDscSignature from "./views/AddDscSignature";
import AddSpecMaster from "./views/AddSpecMaster";
import AddFirmAssets from "./views/AddFirmAssets";
import {
    TimeSheetPage,
    FinalInvoicePage,
    InvoicePage,
    ReportPage,
    ProjectPage,
    EmployeePage,
    AdminPage,
    ClientPage,
    ClientClassPage,
    ProjectClassPage,
    OpePage,
    OrganisationPage,
    NatureOfExpensePage,
    MovementRegisterPage,
    IssueRegisterPage,
    AuditDocumentRegisterPage,
    CertificateRegisterPage,
    LeaveManagementPage,
    ActivityPage,
    InwardRegisterPage,
    OutwardRegisterPage,
    SacMasterPage,
    AttendencePage,
    HolidayMasterPage,
    ProjectSubClassPage,
    SettingsPage,
    PendingArchivedProjectsPage,
    ArchivedProjectsPage,
    PendingRecApprovelProjectsPage,
    DscSignaturePage,
    RecurringProjectsPage,
    NotificationsPage,
    SpecMasterPage,
    SpecificsPage,
    ProgressiveAndUnbilled,
    FirmAssetsPage
} from "./pages";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import ProfileDetails from "./views/Profile";
import AddNewTimeSheet from "./views/AddNewTimeSheet";
import AddSpecifics from "./views/AddSpecifics";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from "react-redux";
import useNotificationStatus from "./hooks/useNotification";
import { useSaveToken } from "./hooks/notification-query";
import { checkToken, onMessageListener } from "./utils/notification_service";
import isEmpty from "lodash/isEmpty";
const initialAlarmState = {
    open: false,
    message: "",
};

const mobileAlarmStyle = { vertical: "top", horizontal: "center" };
const desktopAlamStyle = {
    vertical: "bottom",
    horizontal: "right",
};

function App() {
    const userInfo = useSelector((state) => state.auth.user);
    const [tokenId, setTokenId] = useState(null);
    const saveToken = useSaveToken();
    const notificationStatus = useNotificationStatus();
    const [alarm, setAlarm] = useState({
        ...initialAlarmState,
        ...desktopAlamStyle,
    });

    const { open, message, vertical, horizontal } = alarm;
    const handleOpenSnackbar = (body) => {
        const { pathname } = window.location;
        if (pathname.includes("notification")) return;

        const { innerWidth: width } = window;

        const style = width < 1025 ? mobileAlarmStyle : desktopAlamStyle;

        setAlarm({
            open: true,
            message: body,
            ...style,
        });
    };
    useEffect(() => {
        if (isEmpty(userInfo)) return () => {};

        let unsubscribe;

        if (notificationStatus === "default") {
            try {
                window.Notification.requestPermission();
            } catch (error) {
                if (error instanceof TypeError) {
                    console.log("safari");
                    try {
                        window.Notification.requestPermission((permission) => {
                            if (permission !== "granted") {
                                // eslint-disable-next-line no-alert
                                alert("Permission denied.");
                            }
                        });
                    } catch (err) {
                        // eslint-disable-next-line no-alert
                        alert(err);
                    }
                } else {
                    console.error(error);
                }
            }
        }

        if (notificationStatus === "granted") {
            checkToken(setTokenId, saveToken);
            unsubscribe = onMessageListener(handleOpenSnackbar);
        }

        if (notificationStatus !== "granted" && tokenId !== null) {
            saveToken.mutate({ type: "delete_token", token: tokenId });
            setTokenId(null);
        }

        return unsubscribe;
    }, [notificationStatus, userInfo]);
    const handleCloseSnackBar = () => {
        setAlarm({ ...alarm, ...initialAlarmState });
    };
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackBar}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    return (
        <MuiThemeProvider theme={theme}>
            <BrowserRouter data-test="appComponent">
                <Switch>
                    <Route exact path="/" component={authGuard(ReportPage)} />
                    <Route
                        exact
                        path="/project"
                        component={authGuard(ProjectPage)}
                    />
                    <Route
                        exact
                        path="/invoice"
                        component={authGuard(InvoicePage)}
                    />
                    <Route
                        exact
                        path="/dsc_signatures"
                        component={authGuard(DscSignaturePage)}
                    />
                    <Route
                        exact
                        path="/invoice_report"
                        component={authGuard(GstReport)}
                    />
                    <Route
                        exact
                        path="/pending_udin"
                        component={authGuard(UdinPending)}
                    />
                    <Route
                        exact
                        path="/add_dsc_signature"
                        component={authGuard(AddDscSignature)}
                    />
                    <Route
                        exact
                        path="/recurring_projects"
                        component={authGuard(RecurringProjectsPage)}
                    />
                    <Route
                        exact
                        path="/final_invoices"
                        component={authGuard(FinalInvoicePage)}
                    />
                    <Route
                        exact
                        path="/activity"
                        component={authGuard(ActivityPage)}
                    />
                    <Route
                        exact
                        path="/leavemanagement"
                        component={authGuard(LeaveManagementPage)}
                    />
                    <Route
                        exact
                        path="/client"
                        component={authGuard(ClientPage)}
                    />
                    <Route
                        exact
                        path="/inward_register"
                        component={authGuard(InwardRegisterPage)}
                    />
                    <Route
                        exact
                        path="/outward_register"
                        component={authGuard(OutwardRegisterPage)}
                    />
                    <Route
                        exact
                        path="/timesheet"
                        component={authGuard(TimeSheetPage)}
                    />
                    <Route
                        exact
                        path="/employee"
                        component={authGuard(EmployeePage)}
                    />
                    <Route
                        exact
                        path="/client_class"
                        component={authGuard(ClientClassPage)}
                    />
                    <Route
                        exact
                        path="/project_class"
                        component={authGuard(ProjectClassPage)}
                    />
                    <Route
                        exact
                        path="/project_sub_class"
                        component={authGuard(ProjectSubClassPage)}
                    />
                    <Route exact path="/ope" component={authGuard(OpePage)} />
                    <Route
                        exact
                        path="/attendence"
                        component={authGuard(AttendencePage)}
                    />
                    <Route
                        exact
                        path="/holiday_master"
                        component={authGuard(HolidayMasterPage)}
                    />
                    <Route
                        exact
                        path="/organisation"
                        component={authGuard(OrganisationPage)}
                    />
                    <Route
                        exact
                        path="/nature_of_expense"
                        component={authGuard(NatureOfExpensePage)}
                    />
                    <Route
                        exact
                        path="/admin"
                        component={authGuard(AdminPage)}
                    />
                    <Route
                        exact
                        path="/settings"
                        component={authGuard(SettingsPage)}
                    />
                    <Route
                        exact
                        path="/add_settings"
                        component={authGuard(AddSettings)}
                    />
                    <Route exact path="/login" component={Signin} />
                    <Route exact path="/signup" component={Signup} />
                    <Route
                        exact
                        path="/pending_for_verification"
                        component={PendingVerification}
                    />
                    <Route
                        exact
                        path="/addorganisation"
                        component={authGuard(AddOrganisation)}
                    />
                    <Route
                        exact
                        path="/addnatureofexpense"
                        component={authGuard(AddNatureOfExpense)}
                    />
                    <Route
                        exact
                        path="/addproject"
                        component={authGuard(AddProject)}
                    />
                    <Route
                        exact
                        path="/addclient"
                        component={authGuard(AddClient)}
                    />
                    <Route exact path="/addope" component={authGuard(AddOpe)} />
                    <Route
                        exact
                        path="/sacmaster"
                        component={authGuard(SacMasterPage)}
                    />
                    <Route
                        exact
                        path="/specmaster"
                        component={authGuard(SpecMasterPage)}
                    />
                    <Route
                        exact
                        path="/specifics"
                        component={authGuard(SpecificsPage)}
                    />

                    <Route
                        exact
                        path="/pending_attendence"
                        component={authGuard(PendingAttendence)}
                    />
                    <Route
                        exact
                        path="/addsacmaster"
                        component={authGuard(AddSacMaster)}
                    />
                    <Route
                        exact
                        path="/addspecmaster"
                        component={authGuard(AddSpecMaster)}
                    />
                    <Route
                        exact
                        path="/addspecific"
                        component={authGuard(AddSpecifics)}
                    />
                    <Route
                        exact
                        path="/add_new_register"
                        component={authGuard(AddNewRegister)}
                    />
                    <Route
                        exact
                        path="/apply_for_leave"
                        component={authGuard(ApplyForLeave)}
                    />
                    <Route
                        exact
                        path="/audit_document_register"
                        component={authGuard(AuditDocumentRegisterPage)}
                    />
                    <Route
                        exact
                        path="/archivedProjects"
                        component={authGuard(ArchivedProjectsPage)}
                    />
                    <Route
                        exact
                        path="/pendingArchivedProjects"
                        component={authGuard(PendingArchivedProjectsPage)}
                    />
                    <Route
                        exact
                        path="/pendingRecApprovel"
                        component={authGuard(PendingRecApprovelProjectsPage)}
                    />
                    <Route
                        exact
                        path="/addcertificateregister"
                        component={authGuard(AddCertificateRegister)}
                    />
                    <Route
                        exact
                        path="/certificate_register"
                        component={authGuard(CertificateRegisterPage)}
                    />
                    <Route
                        exact
                        path="/add_new_movement_register"
                        component={authGuard(AddNewMovementRegister)}
                    />
                    <Route
                        exact
                        path="/movement_register_in"
                        component={authGuard(MovementRegisterInForm)}
                    />
                    <Route
                        exact
                        path="/movement_register_movement"
                        component={authGuard(MovementregisterMovement)}
                    />
                    <Route
                        exact
                        path="/movement_register_out"
                        component={authGuard(MovementRegisterOutForm)}
                    />
                    <Route
                        exact
                        path="/movementRegister"
                        component={authGuard(MovementRegisterPage)}
                    />
                    <Route
                        exact
                        path="/addActivity"
                        component={authGuard(AddActivity)}
                    />
                    <Route
                        exact
                        path="/issueRegister"
                        component={authGuard(IssueRegisterPage)}
                    />
                    <Route
                        exact
                        path="/addprojectsubclass"
                        component={authGuard(AddProjectSubClass)}
                    />
                    <Route
                        exact
                        path="/addprojectclass"
                        component={authGuard(AddProjectClass)}
                    />
                    <Route
                        exact
                        path="/addclientclass"
                        component={authGuard(AddClientClass)}
                    />
                    <Route
                        exact
                        path="/viewInvoice/:id"
                        component={authGuard(ViewInvoice)}
                    />
                    <Route
                        exact
                        path="/addinvoice"
                        component={authGuard(AddInvoice)}
                    />
                    <Route
                        exact
                        path="/draftinvoice"
                        component={authGuard(DraftView)}
                    />
                    <Route
                        path="/addauditdocument"
                        component={authGuard(AddAuditDocumentRegister)}
                    />
                    <Route
                        exact
                        path="/addprojectactivity"
                        component={authGuard(AddProjectActivity)}
                    />
                    <Route
                        exact
                        path="/dscdetails"
                        component={authGuard(DscDetails)}
                    />
                    <Route
                        exact
                    path="/firm_asset_details/:id"
                        component={authGuard(FirmAssetDetails)}
                    />
                    <Route
                        exact
                        path="/viewdoc"
                        component={authGuard(ViewDoc)}
                    />
                    <Route
                        exact
                        path="/clientdetails"
                        component={authGuard(ClientDetails)}
                    />
                    <Route
                        exact
                        path="/projectdashboard"
                        component={authGuard(ProjectDashboard)}
                    />
                    <Route
                        exact
                        path="/projectdetails"
                        component={authGuard(ProjectDetails)}
                    />
                    <Route
                        exact
                        path="/addoutwardregister"
                        component={authGuard(AddOutwardRegister)}
                    />
                    <Route
                        exact
                        path="/addinwardregister"
                        component={authGuard(AddInwardRegister)}
                    />
                    <Route
                        exact
                        path="/addattendence"
                        component={authGuard(AddAttendence)}
                    />
                    <Route
                        exact
                        path="/addholiday"
                        component={authGuard(AddHoliday)}
                    />
                    <Route
                        exact
                        path="/approve_employee"
                        component={authGuard(ApproveEmployee)}
                    />
                    <Route
                        exact
                        path="/profile"
                        component={authGuard(ProfileDetails)}
                    />
                    <Route
                        exact
                        path="/edit_recurrence"
                        component={authGuard(EditRecurringProject)}
                    />
                    <Route
                        exact
                        path="/notifications"
                        component={authGuard(NotificationsPage)}
                    />
                    <Route
                        exact
                        path="/leave_details"
                        component={authGuard(ApproveLeave)}
                    />
                    <Route
                        exact
                        path="/progressive_and_unbilled"
                        component={authGuard(ProgressiveAndUnbilled)}
                    />
                    <Route
                        exact
                        path="/addNewTimeSheet"
                        component={authGuard(AddNewTimeSheet)}
                    />
                    <Route
                        exact
                        path="/add_firm_assets"
                        component={authGuard(AddFirmAssets)}
                    />
                    <Route
                        exact
                        path="/firm_assets"
                        component={authGuard(FirmAssetsPage)}
                    />
                </Switch>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    autoHideDuration={8000}
                    onClose={handleCloseSnackBar}
                    message={message}
                    action={action}
                />
            </BrowserRouter>
        </MuiThemeProvider>
    );
}
export default App;
