import React from 'react';
import PageTemplate from '../components/Layout/PageTemplate/PageTemplate';
// import AboutWrapper from '../containers/AboutWrapper/AboutWrapper'
//import CommonList from '../views/CommonTableView';
import CoreUITable from '../views/coreUITable';
const ArchivedProjectsPage = () => {
    return (
        <PageTemplate>
           <CoreUITable page ={"archivedProjects"}/>
        </PageTemplate>
    );
};

export default ArchivedProjectsPage;


