import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_ORGANISATION_FETCH,
    DONE_ORGANISATION_ADD,
    FAILED_ORGANISATION_ADD,
    DONE_ORGANISATION_FETCH,
    FAILED_ORGANISATION_FETCH,
    START_ORGANISATION_ADD,
} from "./types";

export const fetchOrganisation = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_ORGANISATION_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/org_list`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: DONE_ORGANISATION_FETCH,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: FAILED_ORGANISATION_FETCH,
                    payload: "Fetching org  list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: FAILED_ORGANISATION_FETCH,
                payload: "Fetching org  list failed",
            });
        }
    };
};

export const addOrganisation = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_ORGANISATION_ADD,
        });
        try {
            const res = await axios.post(`${BaseUrl}/organisation`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "Created") {
                dispatch({
                    type: DONE_ORGANISATION_ADD,
                    payload: "Sucessfully created organisation ",
                });
            } else {
                dispatch({
                    type: FAILED_ORGANISATION_ADD,
                    payload: "Adding org  failed",
                });
            }
        } catch (error) {
            dispatch({
                type: FAILED_ORGANISATION_ADD,
                payload: "Adding org  failed",
            });
        }
    };
};
