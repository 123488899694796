import {PROJECT_CATEGORY_FETCH_SUCCESS,PROJECT_CATEGORY_FETCH_FAILED,START_PROJECT_CATEGORY_FETCH,
START_PROJECT_CLASS_ADD,
PROJECT_CLASS_ADD_SUCCESS,
    PROJECT_CLASS_ADD_FAILURE,
    FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_START,
    FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_SUCCESS,
    FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_FAILED,
    START_FETCH_SAC_MASTER_OF_SUBCLASS,
    SUCCESS_FETCH_SAC_MASTER_OF_SUBCLASS,
    FAILED_FETCH_SAC_MASTER_OF_SUBCLASS
} from "../actions/types";
const DEFAULT_STATE ={
    dprojectCategoryList:[],
    projectList:[],
    project:[],
    auto_recurring: undefined,
    sac_master_id: undefined,
    loading: false,
    errorMessage:'',
    message:'',
    projectCategoryAddLoading:false,
    projectCategoryAddFailed:true
};

export default (state =DEFAULT_STATE,action)=>{
    switch(action.type){

        case START_PROJECT_CLASS_ADD:
            return{
                ...state,
                projectCategoryAddLoading:true,
                projectCategoryAddFailed:false,
                message:''
            }
        case START_FETCH_SAC_MASTER_OF_SUBCLASS:
            return{
                ...state,
                sac_master_id: undefined
            }
        case SUCCESS_FETCH_SAC_MASTER_OF_SUBCLASS:
            return{
                ...state,
                sac_master_id: action.payload
            }
        case FAILED_FETCH_SAC_MASTER_OF_SUBCLASS:
            return{
                ...state,
                sac_master_id: undefined
            }
        case FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_START:
            return{
                ...state,
                loading: true,
                errorMessage: '',
                auto_recurring: undefined
            }
        case FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_SUCCESS:
            return{
                ...state,
                loading: false,
                errorMessage: '',
                auto_recurring: action.payload
            }
        case FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_FAILED:
            return{
                ...state,
                loading: false,
                errorMessage: action.payload,
                auto_recurring: undefined
            }
        case PROJECT_CLASS_ADD_SUCCESS:
            return{
                ...state,
                projectCategoryAddLoading:false,
                projectCategoryAddFailed:false,
                loading:false,
                message:action.payload
            }
        case PROJECT_CLASS_ADD_FAILURE:
            return{
                ...state,
                projectCategoryAddLoading:false,
                projectCategoryAddFailed:true,
                loading:false,
                message:action.payload
            }
        case START_PROJECT_CATEGORY_FETCH:
            return{
                ...state,
                loading:true
            }

        case PROJECT_CATEGORY_FETCH_SUCCESS:
            return{
                ...state,
                loading:false,
                dprojectCategoryList:action.payload
            }
        case PROJECT_CATEGORY_FETCH_FAILED:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        default :
            return state

    }
}
