import { BaseUrl } from "./api";
import {
  START_PROJECT_DIARY_FETCH,
  START_PROJECT_DIARY_ADD,
  PROJECT_DIARY_FETCH_SUCCESS,
  PROJECT_DIARY_FETCH_FAILED,
  PROJECT_DIARY_ADD_SUCCESS,
  PROJECT_DIARY_ADD_FAILED,
  NEW_COMMENT_RECEIVED,
  NEW_COMMENT_MERGE_SUCCESS,
  NEW_COMMENT_MERGE_FAILED,
  CLOSE_NEW_COMMENT_ALERT,
  PROJECT_SPECIFIC_FETCH,
  PROJECT_SPECIFIC_FAILED,
  PROJECT_SPECIFIC_SUCCESS,
} from "./types";
import axios from "axios";
export const closeCommentAlert = () => {
  return async (dispatch) => {
    dispatch({
      type: CLOSE_NEW_COMMENT_ALERT,
    });
  };
};
export const fetchProjectDiary = (data, token) => {
  return async (dispatch) => {
    dispatch({
      type: START_PROJECT_DIARY_FETCH,
    });
    try {
      const res = await axios.get(`${BaseUrl}/project_diary/${data}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.statusText == "OK") {
        dispatch({
          type: PROJECT_DIARY_FETCH_SUCCESS,
          payload: res.data["project_diary"],
        });
      } else {
        dispatch({
          type: PROJECT_DIARY_FETCH_FAILED,
          payload: "Fetching project diary failed",
        });
      }
    } catch (error) {
      dispatch({
        type: PROJECT_DIARY_FETCH_FAILED,
        payload: error,
      });
    }
  };
};

export const fetchProjectSpecific = (data, token) => {
  return async (dispatch) => {
    dispatch({
      type: PROJECT_SPECIFIC_FETCH,
    });
    try {
      const res = await axios.get(`${BaseUrl}/project_specifics/${data}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.statusText === "OK") {
        dispatch({
          type: PROJECT_SPECIFIC_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: PROJECT_SPECIFIC_FAILED,
          payload: "Fetching project specific failed",
        });
      }
    } catch (error) {
      dispatch({
        type: PROJECT_SPECIFIC_FAILED,
        payload: error,
      });
    }
  };
};

export const addProjectDiary = (data, token) => {
  return async (dispatch) => {
    dispatch({
      type: START_PROJECT_DIARY_ADD,
    });
    try {
      const res = await axios.post(
        `${BaseUrl}/project_diary`,
        { project_diary: data },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            dataType: "json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.status) {
        dispatch({
          type: PROJECT_DIARY_ADD_SUCCESS,
          payload: res.data.message,
        });
        return true;
      } else {
        dispatch({
          type: PROJECT_DIARY_ADD_FAILED,
          payload: res.data.message,
        });
        return false;
      }
    } catch (error) {
      dispatch({
        type: PROJECT_DIARY_ADD_FAILED,
        payload: error,
      });
      return false;
    }
  };
};
