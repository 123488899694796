import { START_NATURE_OF_EXPENSE_ADD,START_NATURE_OF_EXPENSE_FETCH,
DONE_NATURE_OF_EXPENSE_ADD,DONE_NATURE_OF_EXPENSE_FETCH,
FAILED_NATURE_OF_EXPENSE_ADD,FAILED_NATURE_OF_EXPENSE_FETCH} from "../actions/types";



const DEFAULT_STATE ={
    dnatureofexpenseList:[],
    loading: false,
    errorMessage:'',
    message:'',
    natureofexpenseAddLoading:false,
    natureofexpenseAddFailed:true
};

export default (state =DEFAULT_STATE,action)=>{
    switch(action.type){

        case START_NATURE_OF_EXPENSE_ADD:
            return{
                ...state,
                natureofexpenseAddLoading:true,
                natureofexpenseAddFailed:false,
                message:''
            }

        case DONE_NATURE_OF_EXPENSE_ADD:
            return{
                ...state,
                natureofexpenseAddLoading:false,
                natureofexpenseAddFailed:false,
                loading:false,
                message:action.payload
            }
        case FAILED_NATURE_OF_EXPENSE_ADD:
            return{
                ...state,
                natureofexpenseAddLoading:false,
                natureofexpenseAddFailed:true,
                loading:false,
                message:action.payload
            }
        case START_NATURE_OF_EXPENSE_FETCH:
            return{
                ...state,
                loading:true
            }

        case DONE_NATURE_OF_EXPENSE_FETCH:
            return{
                ...state,
                loading:false,
                dnatureofexpenseList:action.payload
            }
        case FAILED_NATURE_OF_EXPENSE_FETCH:
            return{
                ...state,
                loading:false,
                errorMessage:action.payload

            }
        default :
            return state

    }
}
