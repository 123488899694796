import axios from "axios";
import { BaseUrl } from "./api";
import omit from "lodash/omit";
import find from "lodash/find";
import {
    START_PROJECT_FETCH,
    PROJECT_FETCH_SUCCESS,
    PROJECT_FETCH_FAILED,
    START_PROJECT_FETCH_DETAILS_LIST,
    PROJECT_FETCH_FAILED_DETAILS_LIST,
    PROJECT_FETCH_SUCCESS_DETAILS_LIST,
    START_PROJECT_ADD,
    PROJECT_ADD_SUCCESS,
    PROJECT_ADD_FAILURE,
    START_PROJECT_FETCH_BY_ID,
    PROJECT_FETCH_BY_ID_SUCCESS,
    PROJECT_FETCH_BY_ID_FAILED,
    START_PROJECT_UPDATE,
    PROJECT_UPDATE_SUCCESS,
    PROJECT_UPDATE_FAILED,
    FETCH_CLIENT_PROJECT_START,
    FETCH_CLIENT_PROJECT_SUCCESS,
    FETCH_CLIENT_PROJECT_FAILED,
    START_UPDATE_PROJECT_STATUS,
    UPDATE_PROJECT_STATUS_SUCCESS,
    UPDATE_PROJECT_STATUS_FAILED,
    START_SUBMIT_FOR_ARCHIVEL,
    SUBMIT_FOR_ARCHIVEL_SUCCESS,
    SUBMIT_FOR_ARCHIVEL_FAILED,
    FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_START,
    FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_FAILED,
    FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_SUCCESS,
    APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_START,
    APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_SUCCESS,
    APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_FAILED,
    ADD_BACK_TO_AVAILABLE_PROJECTS,
    DELETE_FROM_AVILABLE_PROJECTS,
} from "./types";

export const deleteFromAvailableProject = (id) => {
    //changed this logic to just add text color [instead of deleting we just turn
    // add a  new color to items text]
    return async (dispatch) => {
        dispatch({
            type: DELETE_FROM_AVILABLE_PROJECTS,
            payload: id,
        });
    };
};

export const addBackToAvailableProject = (project) => {
    //chnage back color to black
    return async (dispatch) => {
        dispatch({
            type: ADD_BACK_TO_AVAILABLE_PROJECTS,
            payload: project,
        });
    };
};
export const fetchClientProjects = (token, data) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_CLIENT_PROJECT_START,
        });
        let url = data.invoice
            ? `invoice_client_projects/${data.id}`
            : `client_projects/${JSON.stringify(data)}`;
        try {
            const res = await axios.get(`${BaseUrl}/${url}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.data.status) {
                dispatch({
                    type: FETCH_CLIENT_PROJECT_SUCCESS,
                    payload: res.data["projects"],
                });
            } else {
                dispatch({
                    type: FETCH_CLIENT_PROJECT_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_CLIENT_PROJECT_FAILED,
                payload: error.message,
            });
        }
    };
};
export const approvelActionPendingApprovel = (token, action, id) => {
    return async (dispatch) => {
        dispatch({
            type: APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_START,
        });
        try {
            const res = await axios.delete(
                `${BaseUrl}/pending_approvel_rec_project/${action}/${id}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.statusText == "No Content") {
                dispatch({
                    type: APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_SUCCESS,
                    payload: id,
                });
            } else {
                dispatch({
                    type: APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_FAILED,
                    payload: "Sorry approvel action failed for some reason!!",
                });
            }
        } catch (error) {
            dispatch({
                type: APPROVEL_ACTION_FOR_PENDING_AUTO_CREATE_PROJECT_FAILED,
                payload: error.message,
            });
        }
    };
};

export const fetchPendingAutoCreateProjectList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_START,
        });
        try {
            const res = await axios.get(
                `${BaseUrl}/pending_approvel_rec_project`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.statusText == "OK") {
                dispatch({
                    type: FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_SUCCESS,
                    payload: res.data["projects"],
                });
            } else {
                dispatch({
                    type: FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_FAILED,
                    payload: "Fetching project list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_PENDING_APPROVEL_FOR_AUTO_CREATE_FAILED,
                payload: error.message,
            });
        }
    };
};

//client id,name
export const fetchProjectList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_PROJECT_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/project`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: PROJECT_FETCH_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: PROJECT_FETCH_FAILED,
                    payload: "Fetching project list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: PROJECT_FETCH_FAILED,
                payload: "Fetching project list failed",
            });
        }
    };
};

//client id,name and other details
export const fetchProject = (token, append_client = false) => {
    return async (dispatch) => {
        dispatch({
            type: START_PROJECT_FETCH_DETAILS_LIST,
        });
        try {
            const res = await axios.get(
                `${BaseUrl}/project_list/${append_client}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.statusText == "OK") {
                dispatch({
                    type: PROJECT_FETCH_SUCCESS_DETAILS_LIST,
                    payload: res.data["project"],
                });
            } else {
                dispatch({
                    type: PROJECT_FETCH_FAILED_DETAILS_LIST,
                    payload: "Fetching project list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: PROJECT_FETCH_FAILED_DETAILS_LIST,
                payload: "Fetching project list failed",
            });
        }
    };
};
//using projects lists types,reducer as response is just same for archived list
//and pending archived list
export const fetchAllProjectByFilter = (token, data) => {
    return async (dispatch) => {
        dispatch({
            type: START_PROJECT_FETCH_DETAILS_LIST,
        });
        try {
            const res = await axios.get(
                `${BaseUrl}/all_projects_by_filter/${data}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.statusText == "OK") {
                dispatch({
                    type: PROJECT_FETCH_SUCCESS_DETAILS_LIST,
                    payload: res.data["project"],
                });
            } else {
                dispatch({
                    type: PROJECT_FETCH_FAILED_DETAILS_LIST,
                    payload: "Fetching project list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: PROJECT_FETCH_FAILED_DETAILS_LIST,
                payload: "Fetching project list failed",
            });
        }
    };
};

export const updateProject = (data, token, id, old_members) => {
    for (const key of old_members) {
        if (!find(data["project_members_attributes"], { id: key.id })) {
            let temp = key;
            temp["_destroy"] = true;
            data["project_members_attributes"].push(temp);
        }
    }

    return async (dispatch) => {
        dispatch({
            type: START_PROJECT_UPDATE,
        });
        try {
            const res = await axios.put(
                `${BaseUrl}/project/${id}`,
                { project: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: PROJECT_UPDATE_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: PROJECT_UPDATE_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: PROJECT_UPDATE_FAILED,
                payload: error.message,
            });
        }
    };
};
export const addProject = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_PROJECT_ADD,
        });
        try {
            let url = data.recurring === "true" ? "recurrence" : "project";
            const res = await axios.post(
                `${BaseUrl}/${url}`,
                { [url]: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: PROJECT_ADD_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: PROJECT_ADD_FAILURE,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: PROJECT_ADD_FAILURE,
                payload: error.message,
            });
        }
    };
};
export const fetchProjectDetails = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_PROJECT_FETCH_BY_ID,
        });
        try {
            const res = await axios.get(`${BaseUrl}/project/${data}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: PROJECT_FETCH_BY_ID_SUCCESS,
                    payload: res.data["project"],
                });
                return res.data["project"];
            } else {
                dispatch({
                    type: PROJECT_FETCH_BY_ID_FAILED,
                    payload: "project fetch failed",
                });
            }
        } catch (error) {
            dispatch({
                type: PROJECT_FETCH_BY_ID_FAILED,
                payload: "project fetch failed",
            });
        }
    };
};
//have not added reducer for below function as i am planning to use state,value
//retuned from this funcion
export const updateProjectStatus = (id, data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_UPDATE_PROJECT_STATUS,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/update_project_status/${id}`,
                { project_status: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: UPDATE_PROJECT_STATUS_SUCCESS,
                    payload: { message: res.data["message"] },
                });
                return { status: res.data.status, message: res.data.message };
            } else {
                dispatch({
                    type: UPDATE_PROJECT_STATUS_FAILED,
                    payload: res.data.message,
                });
                return { status: res.data.status, message: res.data.message };
            }
        } catch (error) {
            dispatch({
                type: UPDATE_PROJECT_STATUS_FAILED,
                payload: error.message,
            });
            return { status: false, message: error.message };
        }
    };
};

export const submitForArchivel = (id, data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_SUBMIT_FOR_ARCHIVEL,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/submit_for_archivel/${id}`,
                { project_status: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: SUBMIT_FOR_ARCHIVEL_SUCCESS,
                    payload: res.data["message"],
                });
                return { status: res.data.status, message: res.data.message };
            } else {
                dispatch({
                    type: SUBMIT_FOR_ARCHIVEL_FAILED,
                    payload: res.data.message,
                });
                return { status: res.data.status, message: res.data.message };
            }
        } catch (error) {
            dispatch({
                type: SUBMIT_FOR_ARCHIVEL_FAILED,
                payload: error.message,
            });
        }
    };
};
