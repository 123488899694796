import axios from "../../axiosConfig";
import { useQuery,useMutation, useQueryClient} from "react-query";

async function saveToken(data){
    try{
        const res = await axios.patch(`/profile/${data.type}`, {user: data});
        return res
    }catch(err){
        throw err.response.data;
    }
}
async function markNotificationAsRead(data){
    try{
        const res = await axios.patch(`/notifications/${data}`);
        return res
    }catch(err){
        throw err.response.data;
    }
}
async function getUnReadNotifications(){
    try{
        const res = await axios.get(`/unreadnotifications`);
        return res.data['notifications'];
    }catch(err){
        throw err.response.data;
    }
}

export const useMarkNotificationAsRead = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => markNotificationAsRead(data))
}

export const useNotifications = (fetch) => useQuery('notifications', getUnReadNotifications,{
    refetchOnWindowFocus: false,
    enabled: fetch
})

export const useSaveToken = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => saveToken(data))
}

