import axios from '../../axiosConfig';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// query function
async function getFinalInvoiceList(type) {
  try {
    const res = await axios.get(`/invoices/type/${type}`);
    return res.data['invoice'];
  } catch (err) {
    throw err.response.data;
  }
}
async function getInvoiceReport(queryKey) {
    console.log("query key", queryKey)
    try {
        const {from, to, report_type, organisation_id} = queryKey
        const res = await axios.get(`/invoice/${report_type}/${organisation_id}/${from}/${to}`);
        return res.data['report'];
  } catch (err) {
        throw err.response.data;
  }
}
async function cancelFinalInvoice(id) {
  try {
    const res = await axios.patch(`/cancel_invoice/${id}`);
    return res.data['message'];
  } catch (err) {
    throw err.response.data;
  }
}

// query hooks

export const useFinalInvoiceList = (type = 'Final') =>
  useQuery(['final_invoice', type], () => getFinalInvoiceList(type));

export const useInvoiceReport = () =>
    useMutation((data) => getInvoiceReport(data),
        {
            onSuccess: (data) => { console.log("sucess", data)}
        }
    );

export const useCancelFinalInvoice = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => cancelFinalInvoice(data), {
    onSuccess: () => queryClient.invalidateQueries('final_invoice'),
  });
};
