import React, { Component, View } from 'react';
import { Link } from 'react-router-dom';
// Externals
import classNames from 'classnames';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
// Material helpers
import { withStyles } from '@material-ui/core';
// Material components
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Tooltip,
  TableContainer,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
// Shared components

// Component styles
import styles from './styles';

class CommonTable extends Component {
  state = {
    rowsPerPage: 25,
    page: 0,
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
    localStorage.setItem(
      `rows_per_page_${this.props.page}`,
      event.target.value
    );
  };
  renderTable = (page, users, classes, className, activeTab, rowsPerPage) => {
    if (page == 'client') {
      /**const fields = ['clientid','name','client_class','contact_person','contact_no'];
            return(<CRow>
			<CCol sm="12">
				<CCard>
				<CCardHeader>
					<CIcon name="cil-grid"/> Advanced CDataTable application
				</CCardHeader>
				<DemoTable fields={fields} data={users}/>
				</CCard>
			</CCol>
            </CRow>)**/
      return (
        <Table stickyHeader aria-label='sticky table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Client Id</TableCell>

              <TableCell align='left'>Client Name</TableCell>
              <TableCell align='left'>Client Class</TableCell>
              <TableCell align='left'>Contact Person</TableCell>
              <TableCell align='left'>Contact Number</TableCell>
              <TableCell align='left'></TableCell>
              <TableCell align='left'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link
                      to={{
                        pathname: '/clientdetails',
                        data: { id: user.id, type: 'update' },
                      }}
                    >
                      <Typography className={classes.nameText} variant='body1'>
                        {user.clientid}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.client_class}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.contact_person}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.contact_no}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.lead_partner}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Link
                      to={{
                        pathname: '/addclient',
                        data: { id: user.id, type: 'update' },
                      }}
                    >
                      <Tooltip
                        id='tooltip-top'
                        title='Edit Client'
                        placement='top'
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label='Edit'
                          className={classes.tableActionButton}
                        >
                          <Edit
                            className={
                              classes.tableActionButtonIcon + ' ' + classes.edit
                            }
                          />
                        </IconButton>
                      </Tooltip>{' '}
                    </Link>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Client'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page == 'outward_register') {
      return (
        <Table stickyHeader aria-label='sticky table' size='small'>
          {' '}
          <TableHead>
            <TableRow>
              <TableCell align='left'>Id</TableCell>
              <TableCell align='left'>Client</TableCell>
              <TableCell align='left'>Details of Document</TableCell>
              <TableCell align='left'>Dispatch/Handover Date</TableCell>
              <TableCell align='left'>Remark</TableCell>
              <TableCell align='left'>Mode</TableCell>
              <TableCell align='left'>Proof of delivery</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Typography className={classes.nameText} variant='body1'>
                      {user.id}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.client}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {user.details_of_document}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.dispatch_or_hand_over_date}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.remarks}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.mode}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.proof_of_delivery}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page == 'inward_register') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          size='small'
          aria-label='a dense table'
        >
          {' '}
          <TableHead>
            <TableRow>
              <TableCell align='left'>Id</TableCell>
              <TableCell align='left'>Client</TableCell>
              <TableCell align='left'>Details of document</TableCell>
              <TableCell align='left'>Date of receipt</TableCell>
              <TableCell align='left'>Remarks</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Typography className={classes.nameText} variant='body1'>
                      {user.id}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.client}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.details_of_document}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.date_of_receipt}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.remarks}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page == 'audit_document_register') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          size='small'
          aria-label='a dense table'
        >
          {' '}
          <TableHead>
            <TableRow>
              <TableCell align='left'>File Number</TableCell>
              <TableCell align='left'>Client</TableCell>
              <TableCell align='left'>Project</TableCell>
              <TableCell align='left'>Description</TableCell>
              <TableCell align='left'>Retention Date</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Typography className={classes.nameText} variant='body1'>
                      {user.file_number}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.client}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.project}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.description}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.retention_date}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page == 'certificate_register') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>UDINID</TableCell>
              <TableCell align='left'>Internal Reference No</TableCell>

              <TableCell align='left'>Project</TableCell>
              <TableCell align='left'>Client</TableCell>
              <TableCell align='left'>Udin</TableCell>
              <TableCell align='left'>Type of Signature</TableCell>
              <TableCell align='left'>Type of document</TableCell>
              <TableCell align='left'>Date of document</TableCell>
              <TableCell align='left'>Date of udin generation</TableCell>
              <TableCell align='left'>Status</TableCell>
              <TableCell align='left'>Doc</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Typography className={classes.nameText} variant='body1'>
                      {user.udinid}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.internal_reference}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {user.project}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.client}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.udin}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.type_of_sign}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.type_of_document}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.date_of_document}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.date_of_udin_generation}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.udin_status}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.doc ? (
                      <Link to={{ pathname: '/viewdoc', id: user.id }}>
                        View
                      </Link>
                    ) : null}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <Link
                      to={{
                        pathname: '/addcertificateregister',
                        data: { id: user.id, type: 'update' },
                      }}
                    >
                      <Tooltip
                        id='tooltip-top'
                        title='Edit Client'
                        placement='top'
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label='Edit'
                          className={classes.tableActionButton}
                        >
                          <Edit
                            className={
                              classes.tableActionButtonIcon + ' ' + classes.edit
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Client'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page == 'employee') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Employee Id</TableCell>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>Designaion</TableCell>
              <TableCell align='left'>Approvel Status</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link
                      to={{
                        pathname: '/approve_employee',
                        data: { id: user.id, type: 'update' },
                      }}
                    >
                      <Typography className={classes.nameText} variant='body1'>
                        {user.emp_id}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.designation}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {user.approvel_status.toString()}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Link
                      to={{
                        pathname: '/approve_employee',
                        data: { id: user.id, type: 'update' },
                      }}
                    >
                      <Tooltip
                        id='tooltip-top'
                        title='Edit Client'
                        placement='top'
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label='Edit'
                          className={classes.tableActionButton}
                        >
                          <Edit
                            className={
                              classes.tableActionButtonIcon + ' ' + classes.edit
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Employee'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page === 'issue_register') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Name of DSC holder</TableCell>
              <TableCell align='left'>Token Provider</TableCell>
              <TableCell align='left'>Expiry Date</TableCell>
              <TableCell align='left'>Issue Date</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to='#'>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.name}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.token_provider}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.expiry_date}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.issue_date}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page == 'movement_register') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>DSC ID</TableCell>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>Custodian</TableCell>
              <TableCell align='left'>In/Out status</TableCell>
              <TableCell align='left'>Expiry Status</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link
                      to={{
                        pathname: '/dscdetails',
                        data: { id: user.id, type: 'update' },
                      }}
                    >
                      {' '}
                      <Typography>{user.dscid}</Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.custodian}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.status}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.expiry_status}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit Client'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Client'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page == 'sac_master') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>SAC Code</TableCell>
              <TableCell align='left'>Short Name</TableCell>
              <TableCell align='left'>Description</TableCell>
              <TableCell align='left'>Rate State Cess</TableCell>
              <TableCell align='left'>Rate Central Cess</TableCell>
              <TableCell align='left'>Rate General</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to='#'>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.sac_code}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.description}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {user.rate_state_cess}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.rate_central_cess}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.rate_general}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit Client'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Client'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page === 'spec_master') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>Detailed Name</TableCell>
              <TableCell align='left'>Field Type</TableCell>
              <TableCell align='left'>Allow Symbol</TableCell>
              <TableCell align='left'>Max Characters</TableCell>
              <TableCell align='left'>Min Characters</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to='#'>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.name}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.detailed_name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.field_type}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {String(user.allow_symbols)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.max_characters ? user.max_characters : '-'}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.min_characters ? user.min_characters : '-'}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit Client'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Client'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page == 'ope') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Nature of Expense</TableCell>
              <TableCell align='left'>Projec Name</TableCell>
              <TableCell align='left'>OpeAmount</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to='#'>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.particulars}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.project}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.ope_amount}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit Client'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Client'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (
      page == 'project' ||
      page === 'pendingArchivedProjects' ||
      page === 'archivedProjects'
    ) {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Project Id</TableCell>
              <TableCell align='left'>Project Name</TableCell>
              <TableCell align='left'>Client Name</TableCell>
              <TableCell align='left'>Project Status</TableCell>
              <TableCell align='left'>Due Date</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell>
                    <Link
                      to={{
                        pathname: '/projectdashboard',
                        data: { project_data: user },
                      }}
                    >
                      <Typography className={classes.nameText} variant='body1'>
                        {user.project_id}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell>{user.name}</TableCell>

                  <TableCell>{user.client_name}</TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell>{user.due_date}</TableCell>

                  <TableCell>
                    <Link
                      to={{
                        pathname: '/addProject',
                        data: { id: user.id, type: 'update' },
                      }}
                    >
                      <Tooltip
                        id='tooltip-top'
                        title='Edit Project'
                        placement='top'
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label='Edit'
                          className={classes.tableActionButton}
                        >
                          <Edit
                            className={
                              classes.tableActionButtonIcon + ' ' + classes.edit
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page === 'activity') {
      return (
        <div>
          {users.map((user) => (
            <Card>
              <Card.Header className='text-center'>
                {user.project_sub_class}
              </Card.Header>
              <Card.Body>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>Activity No</TableCell>
                      <TableCell align='left'>
                        Activity Short description
                      </TableCell>
                      <TableCell align='left'>
                        Activity long description
                      </TableCell>
                      <TableCell align='left'></TableCell>
                      {/**for edit,delete icon */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user.activities && user.activities.length
                      ? user.activities.map((item, key) => (
                          <TableRow
                            className={classes.tableRow}
                            hover
                            key={key}
                          >
                            <TableCell className={classes.tableCell}>
                              <Link to='#'>
                                <Typography
                                  className={classes.nameText}
                                  variant='body1'
                                >
                                  {item.activity_no}
                                </Typography>
                              </Link>
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {item.activity_short_description}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {item.activity_long_description}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Tooltip
                                id='tooltip-top'
                                title='Edit'
                                placement='top'
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <IconButton
                                  aria-label='Edit'
                                  className={classes.tableActionButton}
                                >
                                  <Edit
                                    className={
                                      classes.tableActionButtonIcon +
                                      ' ' +
                                      classes.edit
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                id='tooltip-top-start'
                                title='Remove'
                                placement='top'
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <IconButton
                                  aria-label='Close'
                                  className={classes.tableActionButton}
                                >
                                  <Close
                                    className={
                                      classes.tableActionButtonIcon +
                                      ' ' +
                                      classes.close
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </Card.Body>
            </Card>
          ))}
        </div>
      );
    } else if (page === 'settings') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Id</TableCell>
              <TableCell align='left'>Key</TableCell>
              <TableCell align='left'>Value</TableCell>

              <TableCell align='left'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to='#'>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.id}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.key}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {user.value}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <Link
                      to={{
                        pathname: '/add_settings',
                        data: { key: user.key, value: user.value },
                      }}
                    >
                      <Tooltip
                        id='tooltip-top'
                        title='Edit Setting'
                        placement='top'
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label='Edit'
                          className={classes.tableActionButton}
                        >
                          <Edit
                            className={
                              classes.tableActionButtonIcon + ' ' + classes.edit
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page === 'holiday_master') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Id</TableCell>
              <TableCell align='left'>Code</TableCell>
              <TableCell align='left'>date</TableCell>

              <TableCell align='left'>Created By</TableCell>
              <TableCell align='left'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to='#'>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.id}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.holiday_code}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {user.date}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.created_by}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page === 'attendence') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Date</TableCell>
              <TableCell align='left'>Day</TableCell>
              <TableCell align='left'>Attendence type</TableCell>
              <TableCell align='left'>Presence</TableCell>
              <TableCell align='left'>Site</TableCell>
              <TableCell align='left'>Remarks</TableCell>
              <TableCell align='left'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    {user.date}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {moment(user.date).format('dddd')}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.attendence_type}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.presence}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.site}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.remark}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page === 'organisation') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Organisation Name</TableCell>

              <TableCell align='left'>Organisation Code</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to='#'>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.name}
                      </Typography>
                    </Link>
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {user.organisation_code}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page === 'nature_of_expense') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Nature of expense</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to='#'>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.name}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page === 'client_class') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Class Name</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to='#'>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.name}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page == 'leave_management') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Reason</TableCell>
              <TableCell align='left'>Status</TableCell>
              <TableCell align='left'>From date</TableCell>
              <TableCell align='left'>From date leave type</TableCell>
              <TableCell align='left'>Multiple days?</TableCell>
              <TableCell align='left'>To date</TableCell>
              <TableCell align='left'>To date leave type</TableCell>

              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .map((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to={{ pathname: '/leave_details', id: user.id }}>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.reason}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.status}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.from_date}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.from_date_leave_type}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.multiple_days}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.to_date}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.to_date_leave_type}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit Invoice'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Invoice'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page == 'invoice') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Invoice Id</TableCell>
              <TableCell align='left'>Date</TableCell>
              <TableCell align='left'>Client</TableCell>
              <TableCell align='left'>Organisation</TableCell>
              <TableCell align='left'>Invoice Number</TableCell>
              <TableCell align='left'>Grand Total</TableCell>

              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .map((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to='#'>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.id}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.date}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.client}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.organisation}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.invoice_number}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    {user.total}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Link
                      to={{
                        pathname: '/addInvoice',
                        data: { id: user.id, type: 'update' },
                      }}
                    >
                      <Tooltip
                        id='tooltip-top'
                        title='Edit Invoice'
                        placement='top'
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton
                          aria-label='Edit'
                          className={classes.tableActionButton}
                        >
                          <Edit
                            className={
                              classes.tableActionButtonIcon + ' ' + classes.edit
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Invoice'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page == 'project_class') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Project Class Name</TableCell>

              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to='#'>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.name}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else if (page == 'project_sub_class') {
      return (
        <Table
          stickyHeader
          aria-label='sticky table'
          className={classes.table}
          size='small'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Project Sub Class Name</TableCell>
              <TableCell align='left'>Project Class Name</TableCell>
              <TableCell align='left'>Sac Master</TableCell>
              <TableCell align='left'>Auto create</TableCell>
              <TableCell align='left'></TableCell>
              {/**for edit,delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter((user) => {
                if (activeTab === 1) {
                  return !user.returning;
                }

                if (activeTab === 2) {
                  return user.returning;
                }

                return user;
              })
              .slice(0, rowsPerPage)
              .map((user) => (
                <TableRow className={classes.tableRow} hover key={user.id}>
                  <TableCell className={classes.tableCell}>
                    <Link to='#'>
                      <Typography className={classes.nameText} variant='body1'>
                        {user.name}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tablecell}>
                    {user.project_class}
                  </TableCell>
                  <TableCell className={classes.tablecell}>
                    {user.sac_master}
                  </TableCell>
                  <TableCell className={classes.tablecell}>
                    {user.auto_recurring}
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <Tooltip
                      id='tooltip-top'
                      title='Edit Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Edit'
                        className={classes.tableActionButton}
                      >
                        <Edit
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.edit
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      id='tooltip-top-start'
                      title='Remove Project'
                      placement='top'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <IconButton
                        aria-label='Close'
                        className={classes.tableActionButton}
                      >
                        <Close
                          className={
                            classes.tableActionButtonIcon + ' ' + classes.close
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      );
    } else {
    }
  };
  componentDidMount() {
    this.setState({
      rowsPerPage:
        localStorage.getItem(`rows_per_page_${this.props.page}`) !== null
          ? localStorage.getItem(`rows_per_page_${this.props.page}`)
          : 25,
    });
  }
  render() {
    const { classes, className, users } = this.props;
    const page_type = this.props.page;
    const { activeTab, rowsPerPage, page } = this.state;
    const rootClassName = classNames(classes.root, className);
    return (
      <Paper className={classes.root}>
        <TableContainer className={classes.tableContainer}>
          {this.renderTable(
            page_type,
            users,
            classes,
            className,
            activeTab,
            rowsPerPage
          )}
        </TableContainer>
        <TablePagination
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          component='div'
          count={users.length}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 35, 60, 100, 200, 300, 500]}
        />
      </Paper>
    );
  }
}

CommonTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  users: PropTypes.array.isRequired,
};

CommonTable.defaultProps = {
  users: [],
  onSelect: () => {},
  onShowDetails: () => {},
};

export default withStyles(styles)(CommonTable);
