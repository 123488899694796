export default async function serviceWorkerDev() {
  // const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;

  const firebaseConfig = new URLSearchParams({
    apiKey: process.env.REACT_APP_FCM_API_KEY,
    projectId: process.env.REACT_APP_FCM_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FCM_APP_ID
  }).toString();

  const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js?${firebaseConfig}`;
  const response = await navigator.serviceWorker.register(swUrl);
  console.log('FIREBASE SERVICE WORKER LOADED', response);
}
