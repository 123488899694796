import axios from "../../axiosConfig";
import { useQuery, useMutation } from "react-query";

async function fetchCertificateRegistersList(){
    try{
        const res = await axios.get(`/certificate_registers`);
        return res.data;
    }catch(err){
        throw err.response.data;
    }
}
async function getPendingUdin(queryKey) {
    console.log("query key", queryKey);
    try {
        const { key } = queryKey;
        const res = await axios.get(`/pending_udin/${key}`);
        return res.data["certificate_registers"];
    } catch (err) {
        throw err.response.data;
    }
}
export const usePendingUdin = () =>
    useMutation((data) => getPendingUdin(data), {
        onSuccess: (data) => {
            console.log("sucess", data);
        },
    });
export const useCertificateRegisterList = () => useQuery('certificate_registers', fetchCertificateRegistersList,{
    refetchOnWindowFocus: false,
})
