import React from "react";
import { Row, Col, Container, Badge } from "react-bootstrap";
import Header from "../../components/Layout/Header/Header";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
    AlertComponent,
} from "../../utils/formComponents";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import { Divider, Switch } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
    useFirmAssetList,
    useAssetCategoryList,
    useCreateFirmAsset
} from "../../hooks/firmAsset-query";

import {useEmployeeDropDown } from '../../hooks/employee-query/employee'
import cloneDeep from 'lodash/cloneDeep';
let initialValues = {
    firm_asset_details_attributes: [
        {
            created_by_id: "",
            custodian_employee_id: {},
            date_of_handover: new Date(),
            remarks: "",
        },
    ],
    category_of_asset_id: {},
    detailed_description: "",
    other_remarks: "",
    serial_no: "",
    status: true,
    created_by_id: "",
};
const selectStyles = {
    menu: (base) => ({
        ...base,
        zIndex: 100,
    }),
};
export default function AddFirmAssets(props) {
    const [formValues, setFormValues] = React.useState({});
    const [showDetails, setShowDetails] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const {data: asset_category} = useAssetCategoryList();
    const {
        isLoading: createLoading,
        mutate,
        data: createData,
        error,
    } = useCreateFirmAsset()
    const history = useHistory();
    //already added redux related data fetching
    const state = useSelector((state) => state);
    const { token, user } = state.auth;
    const didMountRef = React.useRef(false);


    function formatAndSubmit(formData) {
        let values = cloneDeep(formData)
        console.log("format and submit", formData)
        values["created_by_id"] = user.id;
        values["status"] = values.status ? "Live" : "Retired"
        values["category_of_asset_id"] = values["category_of_asset_id"]["id"];
        values["firm_asset_details_attributes"][0]["created_by_id"] = user.id;
        values["firm_asset_details_attributes"][0]["remarks"] = "New Asset"
        mutate({ ...values });
        setShowDetails(true);
    }
    function validate(inputs) {
        console.log("validate firm asset",inputs)
        let errors = {};
        if (!inputs.category_of_asset_id) errors.category_of_asset_id = "Required";
        if (!inputs.detailed_description) errors.detailed_description = "Required";
        if (!inputs.status && inputs.status == undefined) errors.status = "Required";
        if (!inputs.serial_no) errors.serial_no = "Required";
        return errors;
    }

    React.useEffect(() => {
        if (createLoading) setMessage("Createing Firm Asset In Progress");
        if (createData && createData.message) setMessage(createData["message"]);
        if (error) setMessage(error.message);
    }, [createData, createLoading, error]);

    React.useEffect(() => {
        if (!showDetails) {
            if (createData && createData["status"]) {
                history.goBack();
            }
        }
    }, [showDetails]);

    return (
        <Container>
            <Header title="add_firm_asset" />
            <h1 style={{ marginLeft: "20%" }}>
                {" "}
                <Badge variant="dark">Add Firm Asset</Badge>{" "}
            </h1>
            <Formik
                validate={validate}
                initialValues={initialValues}
                onSubmit={async (values) => formatAndSubmit({ ...values })}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <Row style={{ marginTop: "2%", alignItems: "center" }}>
                            <Col sm={8}>
                                <span>Select Category Of Asset</span>
                                <Field
                                    name="category_of_asset_id"
                                    options={asset_category}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    component={Select}
                                    value={
                                        values.category_of_asset_id
                                            ? values.category_of_asset_id
                                            : undefined
                                    }
                                    onChange={(event) => {
                                        setFieldValue("category_of_asset_id", event);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    styles={selectStyles}
                                />
                                {errors.category_of_asset_id && touched.category_of_asset_id && (
                                    <div
                                        style={{
                                            marginTop: "2%",
                                            alignItems: "center",
                                        }}
                                    >
                                        {errors.category_of_asset_id}
                                    </div>
                                )}
                            </Col>
                            <Col sm={4}>
                                <span>Detailed Description</span>
                                <Field
                                    name="detailed_description"
                                    type="text"
                                    style={{ width: "100%" }}
                                />
                                {errors.detailed_description && touched.detailed_description && (
                                    <div
                                        style={{
                                            marginTop: "2%",
                                            alignItems: "center",
                                        }}
                                    >
                                        {errors.detailed_description}
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "2%", alignItems: "center" }}>
                            <Col sm={8}>
                                <span>Serial No</span>
                                <Field
                                    name="serial_no"
                                    type="text"
                                    style={{ width: "100%" }}
                                />
                                {errors.serial_no && touched.serial_no && (
                                    <div
                                        style={{
                                            marginTop: "2%",
                                            alignItems: "center",
                                        }}
                                    >
                                        {errors.serial_no}
                                    </div>
                                )}
                            </Col>
                            <Col sm={4}>
                                <span>Other Remarks</span>
                                <Field
                                    name="other_remarks"
                                    type="text"
                                    style={{ width: "100%" }}
                                />
                                {errors.remarks && touched.remarks && (
                                    <div
                                        style={{
                                            marginTop: "2%",
                                            alignItems: "center",
                                        }}
                                    >
                                        {errors.remarks}
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "2%", alignItems: "center" }}>
                            <Col sm={8}>
                                <span>Live ?</span>
                                <Field
                                    name="status"
                                    component={Switch}
                                    checked={values.status}
                                    onChange={(event) =>
                                        setFieldValue(
                                            "status",
                                            event.target.checked
                                        )
                                    }
                                />
                                {errors.status &&
                                    touched.status && (
                                        <div
                                            style={{
                                                marginTop: "2%",
                                                alignItems: "center",
                                            }}
                                        >
                                            {errors.status}
                                        </div>
                                    )}
                            </Col>
                        </Row>
                        <Button
                            type="submit"
                            style={{
                                marginLeft: "95%",
                                marginTop: "2%",
                                marginBottom: "5%",
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                    </Form>
                )}
            </Formik>

            <AlertComponent
                title="Creating Firm Asset"
                description={message}
                saveText={"OK"}
                open={showDetails}
                proceed={() => setShowDetails(false)}
            />
        </Container>
    );
}
