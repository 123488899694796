import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_CLIENT_CLASS_FETCH,
    CLIENT_CLASS_FETCH_SUCCESS,
    CLIENT_CLASS_FETCH_FAILED,
    START_CLIENT_CLASS_ADD,
    CLIENT_CLASS_ADD_SUCCESS,
    CLIENT_CLASS_ADD_FAILURE,
} from "./types";

//client id,name
export const fetchClientClassList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_CLIENT_CLASS_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/client_class`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: CLIENT_CLASS_FETCH_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: CLIENT_CLASS_FETCH_FAILED,
                    payload: "Fetching client category list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: CLIENT_CLASS_FETCH_FAILED,
                payload: "Fetching client category list failed",
            });
        }
    };
};

export const addClientClass = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_CLIENT_CLASS_ADD,
        });
        try {
            const res = await axios.post(`${BaseUrl}/client_class`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "Created") {
                dispatch({
                    type: CLIENT_CLASS_ADD_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: CLIENT_CLASS_ADD_FAILURE,
                    payload: "Fetching client category list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: CLIENT_CLASS_ADD_FAILURE,
                payload: "Fetching client category list failed",
            });
        }
    };
};
