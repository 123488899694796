import {START_FETCH_HOLIDAY_LIST, HOLIDAY_ADD_SUCCESS,HOLIDAY_ADD_FAILED, START_ADD_HOLIDAY, HOLIDAY_LIST_FETCH_SUCCESS,HOLIDAY_LIST_FETCH_FAILED, START_FETCH_RESPECTIVE_HOLIDAY_CODE,FETCH_RESPECTIVE_HOLIDAY_CODE_SUCCESS,FETCH_RESPECTIVE_HOLIDAY_CODE_FAILED} from '../actions/types';

const DEFAULT_STATE={
    holidayList: [],
    loading: false,
    holidayListLoading: false,
    addHolidayFailed: true,
    addHolidayMessage: '',
    holidayCode:''
}

export default (state = DEFAULT_STATE, action) =>{
    switch(action.type){
        case START_FETCH_RESPECTIVE_HOLIDAY_CODE:
            return{
                ...state,
                holidayCode:''
            }
        case FETCH_RESPECTIVE_HOLIDAY_CODE_SUCCESS:
            return{
                ...state,
                holidayCode:action.payload
            }
        case FETCH_RESPECTIVE_HOLIDAY_CODE_FAILED:
            return{
                ...state,
                holidayCode:''
            }
        case START_FETCH_HOLIDAY_LIST:
            return{
                ...state,
                holidayList:[],
                holidayListLoading: true
            }
        case HOLIDAY_LIST_FETCH_SUCCESS:
            return{
                ...state,
                holidayList: action.payload,
                holidayListLoading: false
            }
        case HOLIDAY_LIST_FETCH_FAILED:
            return{
                ...state,
                holidayListLoading: false
            }
        case START_ADD_HOLIDAY:
            return{
                ...state,
                loading: true,
                addHolidayFailed: false,
                addHolidayMessage: ''
            }
        case HOLIDAY_ADD_SUCCESS:
            return{
                ...state,
                loading: false,
                addHolidayFailed: false,
                addHolidayMessage: action.payload
            }
        case HOLIDAY_ADD_FAILED:
            return{
                ...state,
                loading: false,
                addHolidayFailed: true,
                addHolidayMessage: action.payload
            }
        default:
            return state

    }
}

