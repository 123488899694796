import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field,change,formValueSelector } from "redux-form";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import {InputText,TextArea,SelectInput,SavingDetailsModal, RenderSwitch} from "../../utils/formComponents";
import MultiSelectComponent from "../../utils/multiselect";

const validate = inputs =>{
    const errors = {};
    if(!('name' in inputs)){
        errors.name = "Name required"
    }
    if(!('password' in inputs)){
        errors.password = "Password required"
    }
    if(!inputs.in_office){
        if(!inputs.dsc_custodians_attributes || !inputs.dsc_custodians_attributes.length){
           errors.dsc_custodian_ = "Custodian required!"
        }else{
            const errorArray = []
            inputs.dsc_custodians_attributes.forEach((data,index) =>{
                const error ={}
                if(!data || !data.employee_id){
                    error.employee_id ="Required"
                    errorArray[index] = error
                }
            })
            if(errorArray.length){
                errors.dsc_custodians_attributes = errorArray
            }
        }

    }
    if(!inputs.movement_reg_details_attributes || !inputs.movement_reg_details_attributes.length){
        errors.movement_reg_details_ = "Please provide receipt details"
    }else{
        const receiptArrayError = []
        inputs.movement_reg_details_attributes.forEach((data,index) =>{
            const receiptErrors={}
            if(!data || !data.name){
                receiptErrors.name = "Required"
                receiptArrayError[index] = receiptErrors
            }
            if(!data || !data.date){
                receiptErrors.date = "Required"
                receiptArrayError[index] = receiptErrors
            }
        })
        if(receiptArrayError.length){
            errors.movement_reg_details_attributes = receiptArrayError
        }
    }
    return errors
}

class AddNewMovementRegister extends Component{

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        movementRegisterAddFailed: true,
        movement_register: {}
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
}

componentDidUpdate(){
    if(!this.state.movementRegisterAddFailed){
      this.props.history.goBack();
    }
  }
componentWillMount(){
    this.props.fetchClientList(this.props.token);
    this.props.fetchEmployeeDropDownList(this.props.token);
}
closeModal(){
    this.setState({
        loading:this.props.loading,
        movementRegisterAddFailed:this.props.movementRegisterAddFailed
    })
}
renderError({input, meta, ...props}){
    return(
        meta.error  && meta.touched ? <span {...props} style={{ color: 'red' }} >Error : {meta.error}</span>: null
    )
}
async onsubmit(formData) {
    await this.setState({
        loading:true,
        movement_register: formData
    })
    let copy_m_register = {};
    if(formData['client_dscs_attributes']){
        for(let i=0; i< formData['client_dscs_attributes'][0]['client_ids'].length; i++){
        copy_m_register[i]={}
        copy_m_register[i]['client_id'] = formData['client_dscs_attributes'][0]['client_ids'][i]['id']
        }
        const new_obj = { ...this.state.movement_register, client_dscs_attributes: copy_m_register }
        await this.setState({
            movement_register: new_obj
        })
    }
    let custodian ={};
    if(formData['dsc_custodians_attributes']){// added this conditon to handle [0] undefined when in office and remarks not added
        custodian[0]=Object.assign({}, formData['dsc_custodians_attributes']['0']);
        custodian[0]['employee_id']= (!this.props.in_office && formData['dsc_custodians_attributes']['0']['employee_id']) ? formData['dsc_custodians_attributes']['0']['employee_id']['id'] : null
        custodian[0]['date'] = formData['movement_reg_details_attributes'][0]['date']
    }else{
        custodian[0] = {}
        custodian[0]['date'] = formData['movement_reg_details_attributes'][0]['date']
    }
    const new_obj2 = {...this.state.movement_register,dsc_custodians_attributes: custodian}
    await this.setState({
        movement_register: new_obj2
    })

    await this.props.addNewMovemenRegister(this.props.token,this.state.movement_register);
    this.setState({
        message:this.props.message
    })
}
  render () {
    let state = this.state;
const { handleSubmit,classes } = this.props;
    return (
      <Container>
        <Header title="add_new_movement_register" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" >
       <h1> <Badge variant="dark"> Add New Dsc </Badge></h1>
      </Typography>

        <form onSubmit={handleSubmit(this.onsubmit)}>
        <h2> <Badge variant="dark">Basic Details</Badge></h2>
          <Row>
            <Col sm={8}>
              <Field
                name="name"
                label="Name"
                component={InputText}
                type="text"
                fullWidth
             />
            </Col>
            <Col sm={4}>
              <Field
                name="token_provider"
                label="Token Provider"
                component={InputText}
                type="text"
                fullWidth
             />
            </Col>
          </Row>
           <Row>
            <Col sm={8}>
                <span>Expiry Date</span>
                <Field
                name="expiry_date"
                component={InputText}
                type="date"
                fullWidth
                />
            </Col>
            <Col sm={4}>
                <Field
                name="password"
                label="Password"
                component={InputText}
                type="text"
                fullWidth
            />
            </Col>
        </Row>
          <Row>
            <Col sm={8}>
              <Field
                name="token_no"
                label="Token Number"
                component={InputText}
                type="text"
                fullWidth
              />
            </Col>
            <Col sm={4}>
              <Field
                name="remarks"
                label="Remarks on physical token"
                component={InputText}
                type="text"
                fullWidth
              />
            </Col>
        </Row>
        <h2> <Badge variant="dark">Receipt Details</Badge></h2>
        <Field name="movement_reg_details_" component={this.renderError} />
        <Row>
            <Col sm={8}>
              <Field
                name={`movement_reg_details_attributes[0].name`}
                label="Received From"
                component={InputText}
                type="text"
                fullWidth
              />
            </Col>

            <Col sm={4}>
                <span>Received on</span>
                <Field
                name={`movement_reg_details_attributes[0].date`}
                component = {InputText}
                type= "date"
                fullWidth />
            </Col>
        </Row>
        <Row>
        <Col sm={8}>
              <Field
                name={`movement_reg_details_attributes[0].remarks`}
                label="Remarks on receipt"
                component={InputText}
                type="text"
                fullWidth
              />
            </Col>

          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving Details  "}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <h2> <Badge variant="dark">Custodian Details</Badge></h2>

        <Row>
        <Col sm={8}>
             <Field
                name= "in_office"
                component={RenderSwitch}
                //custom props
                label= "Dsc in Office?"
                //id={props.id}
            />
        </Col>
        </Row>
        <Field name="dsc_custodian_" component={this.renderError} />
        <Row>
        {!this.props.in_office && <Col sm={8}>
              <span>Custodian Employee</span>
              <Field
                  name={`dsc_custodians_attributes[0].employee_id`}
                  props={{
                      type: 'object'
                  }}
                  component={MultiSelectComponent}
                  {...{
                      data:this.props.employeeList,
                      isMulti : false
                  }

                  }
                  fullWidth
              />
            </Col>}

            <Col sm={4}>
              <Field
                name={`dsc_custodians_attributes[0].remarks`}
                label="Remarks on custody"
                component={InputText}
                type="text"
                fullWidth
              />
            </Col>
        </Row>
        <h2> <Badge variant="dark">Clients</Badge></h2>
        <Row>
             <Col sm={4}>
             <span>Select Clients Associated</span>
              <Field
                  name={`client_dscs_attributes[0].client_ids`}
                  props={{
                      type: 'object'
                  }}
                  component={MultiSelectComponent}
                  {...{
                      data:this.props.dclients,
                      isMulti : true
                  }

                  }
                  fullWidth
              />
            </Col>
        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>
          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    )
  }
}
const selector = formValueSelector('AddNewMovementRegister')
function mapStateToProps(state) {
    return {
        token: state.auth.token,
        dclients: state.client.dclientList,
        message:state.movementregister.movementRegisterAddMessage,
        loading:state.movementregister.movementRegisterAddLoading,
        movementRegisterAddFailed:state.movementregister.movementRegisterAddFailed,
        employeeList:state.employee.employeeDropdownList,
        in_office: selector(state, 'in_office')
    }
}
export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddNewMovementRegister",
validate }),
    withStyles(styles)
)(AddNewMovementRegister);
