
export const jsonTocsv =(data)=>{
    var json = data
    var fields = Object.keys(json[0])
    var replacer = function(key, value) { return value === null ? '' : value }
    var csv = json.map(function(row){
    return fields.map(function(fieldName){
        return JSON.stringify(row[fieldName], replacer)
    }).join(',')
    })
    csv.unshift(fields.join(',')) // add header column
    csv = csv.join('\r\n');
    console.log(csv)
    return csv

}

export const downloadCsv = (content, filename, contentType) =>{
    // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click()
}
