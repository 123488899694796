
import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { connect } from 'react-redux';
import * as actions from "../../actions";
import Header from "../../components/Layout/Header/Header";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import {InputText,SelectInput,TextArea,validate,SavingDetailsModal} from "../../utils/formComponents";
import MultiSelectComponent from "../../utils/multiselect"
class AddProjectSubClass extends Component {

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        projectCategoryAddFailed:true
    };

    this.onsubmit = this.onsubmit.bind(this);
 this.closeModal = this.closeModal.bind(this);

}

closeModal(){
    this.setState({
        loading:this.props.loading,
        projectCategoryAddFailed:this.props.projectCategoryAddFailed
    })
}
componentWillMount(){
    this.props.fetchProjectSuperClassList(this.props.token)
    this.props.fetchSacmasterList(this.props.token);
}
componentDidUpdate(){
    setTimeout(() => this.setState({message:''}), 9000);
    if(!this.state.projectCategoryAddFailed){
        this.props.history.goBack();
    }
  }
async onsubmit(formData) {

    this.setState({
        loading:true
    })
    let data = {...formData}
    data['sac_master_id'] = formData['sac_master_id']['id']
    await this.props.addProjectClass(data,this.props.token);
    this.setState({
        message:this.props.message
    })
}
  render () {
const { handleSubmit,classes } = this.props;
    let state = this.state;
    return (
      <Container>
      <Header title="add_project_class" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
      Add Project Sub Class
      </Typography>
      <Row style={{ marginTop: '5%'}}></Row>
      <form onSubmit={handleSubmit(this.onsubmit)} noValidate>
          <Row style={{ alignItems: 'center'}}>
                <Col sm={4}>
                    <span>Select Project Category</span>
                </Col>
                <Col sm={4}>
                    <Field
                        name="project_class_id"
                        //label="Category *"
                        component={SelectInput}
                        fullWidth
                        data={this.props.dprojectCategoryList}
                    />

                </Col>
        </Row>
        <Row style={{ alignItems: 'center'}}>
                <Col sm={4}>
                    <span>Project Sub Class</span>
                </Col>
                <Col sm={4}>
                    <Field
                        name="name"
                        //label="Name *"
                        component={InputText}
                        type="text"
                        fullWidth
                    />
                </Col>
        </Row>
        <Row style={{ alignItems: 'center'}}>
                <Col sm={4}>
                    <span>Sac master</span>
                </Col>
                <Col sm={4}>
                    <Field
                        name="sac_master_id"
                        label=""
                        props={{
                            type: 'object'
                        }}
                        component={MultiSelectComponent}
                        {...{
                            data:this.props.sacMasterList,
                            isMulti : false
                        }

                        }
                        fullWidth
                    />
                </Col>
          </Row>
          <Row style={{ alignItems: 'center'}}>
                <Col sm={4}>
                    <span>Auto create</span>
                </Col>
                <Col sm={4}>
                    <Field
                        name="auto_recurring"
                        //label="Category *"
                        component={SelectInput}
                        fullWidth
                    />

                </Col>
        </Row>



        <Row>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving Project Category Details"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>


          <Row style={{marginTop:20}} className='row justify-content-end'>
            <Col sm={5}>
                <Button type="submit" variant="contained" color="primary">Add</Button>
            </Col>
          </Row>
        </form>

      </Container>
    )
  }
}

function mapStateToProps(state) {
    return {
        token:state.auth.token,
        message:state.projectClass.message,
        loading:state.projectClass.projectCategoryAddLoading,
        projectCategoryAddFailed:state.projectClass.projectCategoryAddFailed,
        dprojectCategoryList: state.projectSuperClass.dprojectCategoryList,
        sacMasterList: state.sacmaster.sacMasterList,
    }
}

export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddProjectSubClass",
validate }),
    withStyles(styles)
)(AddProjectSubClass);
