import  Card  from '@material-ui/core/Card';
import React,{useState,useEffect,useRef} from 'react';
import "./profile.css";
import Avatar from '@material-ui/core/Avatar';
import { Button, TextField,InputBase  } from '@material-ui/core';
import { useDispatch,useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { useAvatarUpdate } from '../../hooks/profile-query'
import {InputField} from '../../utils/formikComponents'
import { Row, Col} from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
    let initialValues = {
        "personal_email":"",
        "work_email":"",
        "designation":"",
        "access_role":"",
        "bloodgroup":"",
        "pincode": 0,
        "aadhaar":"",
        "pan":"",
        "state":"",
        "city":"",
        "address":"",
        "gender":"",
        "contact":"",
        "dob":"",
        name:''
    }
    export const GeneralDetails  = props =>{
        const [avatar, setAvatar] = useState('')
        const {auth: {user}} = useSelector(state => state)
        const dispatch = useDispatch();
        const inputFile = useRef(null)
        const {isLoading, mutate, data, error} = useAvatarUpdate()
        useEffect(()=>{
            if(user && user.avatar) setAvatar(user.avatar)
            if(user) Object.assign(initialValues, user)
        },[user])
        const onClickChangeAvatar = () => {
            // `current` points to the mounted file input element
            inputFile.current.click();
        };
        const onChangeAvatar =(event)=>{
            var file = event.target.files[0];
            setAvatar(URL.createObjectURL(file))
            let data = {}
            data['id'] = user.id
            data['avatar'] = file
            mutate({data})
        }
    return (
    <div className="body_background" >
        <Card className="P4" style={{backgroundColor:'#222b36' ,color:'azure' }}  >
           <Badge
           //overlap="circular"
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                badgeContent={<IconButton
                    aria-label="show edit"
                    color="inherit"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                onClick={()=>onClickChangeAvatar()}
                >
                    <EditIcon/>
                </IconButton>
}
            >
                <Avatar alt="Remy Sharp" src={avatar} style={{ height: '100px', width: '100px' }}/>
            </Badge>
            <p style={{fontWeight:700,marginTop:"10px"}}>{(user && user.name) ? user.name : ""}</p>
            <p>Access Level: {(user && user.access_role) ? user.access_role : ""}</p>
            <input
                type='file'
                id='file'
                ref={inputFile}
                style={{display: 'none'}}
                accept="image/png, image/gif, image/jpeg"
                onChange={onChangeAvatar}
            />
        </Card>

         <Card  className="body" border="1px solid #030120" style={{backgroundColor:'#222b36' ,color:'azure' }} >

         <h4 style={{fontWeight:600,marginTop:"10px",marginLeft:"10px"}}>Edit Profile</h4>
         <div style={{ borderTop: "2px solid grey ", marginLeft: 10, marginRight: 20 ,marginBottom:20}}></div>
             <div className="Pdetails">
                <Formik
                    initialValues={initialValues}
                    //onSubmit={async (values) => formatAndSubmit({...values})}
                >
                {({ values,errors, touched,setFieldValue }) => (
                <Form>
                    <Row style={{ marginTop: '4%'}}>
                        <Col>
                            <Field
                                name="name"
                                type="text"
                                label="Name"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="dob"
                                type="date"
                                label="Date of birth"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>

                    </Row>
                    <Row style={{ marginTop: '4%'}}>
                        <Col>
                            <Field
                                name="gender"
                                type="text"
                                label="Gender"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="address"
                                type="text"
                                label="Address"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '4%'}}>
                        <Col>
                            <Field
                                name="state"
                                type="text"
                                label="State"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="city"
                                type="text"
                                label="City"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '4%'}}>
                        <Col>
                            <Field
                                name="contact"
                                type="number"
                                label="Contact"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="pincode"
                                type="number"
                                label="Pin code"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                     <Row style={{ marginTop: '4%'}}>
                        <Col>
                            <Field
                                name="pan"
                                type="text"
                                label="PAN"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="aadhaar"
                                type="number"
                                label="Aadhaar"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '4%'}}>
                        <Col>
                            <Field
                                name="personal_email"
                                type="email"
                                label="Personal Email"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="work_email"
                                type="email"
                                label="Work Email"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                     <Row style={{ marginTop: '4%'}}>
                        <Col>
                            <Field
                                name="designation"
                                type="text"
                                label="Designation"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="access_role"
                                type="text"
                                label="Access Role"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '4%', marginTop: '4%'}}>
                        <Col>
                            <Field
                                name="bloodgroup"
                                type="text"
                                label="Blood Group"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                        <Col>
                            <Field
                                name="emergency_contact"
                                type="number"
                                label="Emergency contact"
                                component={InputField}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    {/**<Button style={{backgroundColor:'#688eff' ,color:'azure',marginTop:"10px",marginLeft:"450px",marginBottom:"10px" ,borderRadius:"20px",outline:"none"}} variant="contained">Save Changes</Button>**/}

                </Form>
                )}
            </Formik>


       </div>

         </Card>

         </div>

    )
    }
