import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_OPE_ADD,
    START_OPE_FETCH,
    OPE_ADD_SUCCESS,
    OPE_FETCH_SUCCESS,
    OPE_FETCH_FAILURE,
    OPE_ADD_FAILURE,
    START_OPE_AMOUNT_FETCH,
    OPE_AMOUNT_FETCH_SUCCESS,
    OPE_AMOUNT_FETCH_FAILED,
    START_RESPECTIVE_OPE_FETCH,
    RESPECTIVE_OPE_FETCH_DONE,
    RESPECTIVE_OPE_FETCH_FAILED,
    ADD_BACK_TO_AVAILABLE_OPES,
    DELETE_FROM_AVAILABLE_OPES,
} from "./types";

export const fetchOpeList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_OPE_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/ope`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (res.statusText == "OK") {
                dispatch({
                    type: OPE_FETCH_SUCCESS,
                    payload: res.data["opes"],
                });
            } else {
                dispatch({
                    type: OPE_FETCH_FAILURE,
                    payload: "Fetching ope list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: OPE_FETCH_FAILURE,
                payload: "Fetching ope list failed",
            });
        }
    };
};

export const addBackToAvailableOpe = (data) => {
    return async (dispatch) => {
        dispatch({
            type: ADD_BACK_TO_AVAILABLE_OPES,
            payload: data,
        });
    };
};

export const deleteFromAvailableOpe = (data) => {
    return async (dispatch) => {
        dispatch({
            type: DELETE_FROM_AVAILABLE_OPES,
            payload: data,
        });
    };
};
export const respectiveOpeFetch = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_RESPECTIVE_OPE_FETCH,
        });
        try {
            const res = await axios.get(
                `${BaseUrl}/get_ope_list/${data}`,

                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.statusText == "OK") {
                dispatch({
                    type: RESPECTIVE_OPE_FETCH_DONE,
                    payload: res.data["ope_list"]["invoice_opes_attributes"],
                });
                return res.data;
            } else {
                dispatch({
                    type: RESPECTIVE_OPE_FETCH_FAILED,
                    payload: "ope fetch failed",
                });
            }
        } catch (error) {
            dispatch({
                type: RESPECTIVE_OPE_FETCH_FAILED,
                payload: "ope fetch  failed",
            });
        }
    };
};
export const opeAmountFetch = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_OPE_AMOUNT_FETCH,
        });
        try {
            const res = await axios.post(`${BaseUrl}/ope_total`, data);
            if (res.statusText == "Created") {
                dispatch({
                    type: OPE_AMOUNT_FETCH_SUCCESS,
                    payload: res.data,
                });
                return res.data;
            } else {
                dispatch({
                    type: OPE_AMOUNT_FETCH_FAILED,
                    payload: "ope amount fetch failed",
                });
            }
        } catch (error) {
            dispatch({
                type: OPE_AMOUNT_FETCH_FAILED,
                payload: "ope amount fetch  failed",
            });
        }
    };
};
export const addOpe = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_OPE_ADD,
        });
        try {
            const res = await axios.post(`${BaseUrl}/ope`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "Created") {
                dispatch({
                    type: OPE_ADD_SUCCESS,
                    payload: "Sucessfully created ope",
                });
            } else {
                dispatch({
                    type: OPE_ADD_FAILURE,
                    payload: "Adding ope failed",
                });
            }
        } catch (error) {
            dispatch({
                type: OPE_ADD_FAILURE,
                payload: "Adding ope  failed",
            });
        }
    };
};
