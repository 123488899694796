import React from "react";
import BTable from 'react-bootstrap/Table';
import {
    Column,
    useReactTable,
    ColumnFiltersState,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    sortingFns,
    getSortedRowModel,
    FilterFn,
    SortingFn,
    FilterFns,
    PaginationState,
    getCoreRowModel,
    flexRender,
} from "@tanstack/react-table";
import IconButton from '@material-ui/core/IconButton';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import { SelectColumnFilter,ColumnFilter } from "./filters/Filters.js";
export default function Table({
    columns = [],
    data = [],
    setTableOption = () => {},
    pageCount = 0,
} = {}) {
    const rerender = React.useReducer(() => ({}), {})[1];
    const [columnFilters, setColumnFilters] = React.useState([]);
    const [sortBy, setSortBy] = React.useState([]);
    const [{ pageIndex, pageSize }, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    });



    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );
    React.useEffect(() => {
        console.log("change detected", columnFilters, pageIndex, pageSize)
        let t = {}
        columnFilters.forEach((item,i)=>{
            t[item['id']] = typeof item['value'] === 'object' ? item['value']['id'] : item['value']
        })
        t = {...t, offset: pageSize*pageIndex, pageSize,sortBy}
        console.log("value", t)
        //if(columnFilters)
        setTableOption({...t});
    }, [pageIndex, pageSize, sortBy, columnFilters]);

    const table = useReactTable({
        data: data,
        columns,
        pageCount: pageCount,
        state: {
            pagination,
            columnFilters,
            sortBy,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSortBy,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        manualFiltering: true,
        manualSorting: true,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
        debugAll: true
    });
    return (
        <div className="p-2">
            <div className="h-2" />
            {columnFilters.length !== 0 &&<div style={{display: 'flex', marginLeft: '1%'}}><IconButton onClick ={()=>setColumnFilters([])}><ThreeSixtyIcon /></IconButton></div>}
            <BTable responsive bordered>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <>
                                                <div
                                                    {...{
                                                        className:
                                                            header.column.getCanSort()
                                                                ? "cursor-pointer select-none"
                                                                : "",
                                                        onClick:
                                                            header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext()
                                                    )}
                                                    {header.column.getIsSorted()
                                                        ? header.column.getIsSorted()
                                                        : null}
                                                </div>
                                                {header.column.getCanFilter() ? (
                                                header.column.columnDef.meta &&<div>
                                                        {header.column.columnDef.meta
                                                            .filterType ===
                                                        "select" ? (
                                                            <SelectColumnFilter
                                                                column={
                                                                    header.column
                                                                }
                                                                options={
                                                                    header
                                                                        .column.columnDef
                                                                        .meta
                                                                        .options
                                                                }
                                                            />
                                                        ) : (
                                                            <ColumnFilter
                                                                column={
                                                                    header.column
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </BTable>
            <div className="h-2" />
            <div className="flex items-center gap-2">
                <button
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                >
                    {"<<"}
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    {"<"}
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    {">"}
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                >
                    {">>"}
                </button>
                <span className="flex items-center gap-1">
                    <div>Page</div>
                    <strong>
                        {table.getState().pagination.pageIndex + 1} of{" "}
                        {table.getPageCount()}
                    </strong>
                </span>
                <span className="flex items-center gap-1">
                    | Go to page:
                    <input
                        type="number"
                        defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={(e) => {
                            const page = e.target.value
                                ? Number(e.target.value) - 1
                                : 0;
                            table.setPageIndex(page);
                        }}
                        className="border p-1 rounded w-16"
                    />
                </span>
                <select
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}
