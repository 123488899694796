import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_FETCH_ATTENDENCE_LIST,
    FETCH_ATTENDENCE_LIST_SUCCESS,
    FETCH_ATTENDENCE_LIST_FAILED,
    START_MARK_ATTENDENCE,
    MARK_ATTENDENCE_SUCCESS,
    MARK_ATTENDENCE_FAILED,
} from "./types";

export const fetchAttendenceList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_FETCH_ATTENDENCE_LIST,
        });
        try {
            const res = await axios.get(`${BaseUrl}/attendences`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText === "OK") {
                dispatch({
                    type: FETCH_ATTENDENCE_LIST_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: FETCH_ATTENDENCE_LIST_FAILED,
                    payload: "Fetching attendence list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: FETCH_ATTENDENCE_LIST_FAILED,
                payload: "Fetching attendence list failed",
            });
        }
    };
};
export const markAttendence = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_MARK_ATTENDENCE,
        });
        try {
            const res = await axios.post(`${BaseUrl}/attendences`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.data.status) {
                dispatch({
                    type: MARK_ATTENDENCE_SUCCESS,
                    payload: res.data.message,
                });
            } else {
                dispatch({
                    type: MARK_ATTENDENCE_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: MARK_ATTENDENCE_FAILED,
                payload: error.message,
            });
        }
    };
};
