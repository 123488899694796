
import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field,change } from "redux-form";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import MultiSelectComponent from "../../utils/multiselect"
import {InputText,TextArea,SelectInput,validate,SavingDetailsModal} from "../../utils/formComponents";
class AddOpe extends Component{

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        opeAddFailed:true,
        timeSet:false,
        ope_data: []
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
}

componentWillMount(){
    this.props.fetchClientList(this.props.token)
    this.props.fetchNatureofexp(this.props.token);
}
componentDidUpdate(){
    setTimeout(() => this.setState({message:''}), 9000);
    if(!this.state.timeSet){

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    this.props.dispatch(change("AddOpe","date",today))
        this.setState({
            timeSet:true
        })
    }
    if(!this.state.opeAddFailed){
        this.props.history.goBack();
    }
  }

closeModal(){
    this.setState({
        loading:this.props.loading,
        opeAddFailed:this.props.opeAddFailed
    })
}
async onsubmit(formData) {

    this.setState({
        loading:true,
        ope_data: formData
    })
    await this.setState(prevState =>({
        ope_data: {
            ...prevState.ope_data,
            project_id: formData['project_id']['id'],
            nature_of_expense_id: formData['nature_of_expense_id']['id']

        }
    }))
    await this.props.addOpe(this.state.ope_data,this.props.token);
    this.setState({
        message:this.props.message
    })
}
  render () {
    let state = this.state;
const { handleSubmit,classes } = this.props;
    return (
      <Container>
        <Header title="add_ope" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
       Add Ope
      </Typography>
        <Row>
          <Col sm={4}>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(this.onsubmit)}>
          <Row>
            <Col sm={8}>

              <span>Select Client</span>
              <Field
                name="client_id"
                        label=" "
                        props={{
                            type: 'object'
                        }}
                        component={MultiSelectComponent}
                        {...{
                            data:this.props.dclients,
                            isMulti : false
                        }

                        }
                        fullWidth
             />
            </Col>

            <Col sm={4}>

              <span>Select Project</span>
              <Field
                name="project_id"
                        label=" "
                        props={{
                            type: 'object'
                        }}
                        component={MultiSelectComponent}
                        {...{
                            data:this.props.clientProjects,
                            isMulti : false
                        }

                        }
                        fullWidth
             />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>

              <Field
                        name="ope_amount"
                        label="Ope Amount *"
                        component={InputText}
                        type="number"
                        fullWidth
              />
        </Col>

            <Col sm={4}>

              <span>Nature of Expense</span>

              <Field
                        name="nature_of_expense_id"
                        label=" "
                        props={{
                            type: 'object'
                        }}
                        component={MultiSelectComponent}
                        {...{
                            data:this.props.natureofexpense,
                            isMulti : false
                        }

                        }
                        fullWidth
              />
            </Col>
          </Row>
        <Row>

            <Col sm={8}>
                           <span>Date</span>
                           <Field
                        name="date"
                        label=""
                        component={InputText}
                        type="date"
                        fullWidth
                    />
            </Col>
            <Col sm={4}>
                         <span>Particulars</span>
                           <Field
                        name="particulars"
                        label=""
                        component={InputText}
                        type="text"
                        fullWidth
                    />
            </Col>
        </Row>
        <Row>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving Ope Details"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>
          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    )
  }
}

function mapStateToProps(state) {
    return {
        token:state.auth.token,
        message:state.ope.opeAddMessage,
        clientProjects: state.project.clientProjects,
        dclients: state.client.dclientList,
        loading:state.ope.opeAddLoading,
        natureofexpense :state.natureofexpense.dnatureofexpenseList,
        opeAddFailed:state.ope.opeAddFailed
    }
}
export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddOpe",
      enableReinitialize: true,
validate }),
    withStyles(styles)
)(AddOpe);
