// CONTAINS CODE FOR FETCHING PROJEC SUB CLASS ITEMS
import axios from 'axios';
import { BaseUrl } from './api';
import {
  START_PROJECT_CLASS_ADD,
  PROJECT_CLASS_ADD_SUCCESS,
  PROJECT_CLASS_ADD_FAILURE,
  START_PROJECT_CATEGORY_FETCH,
  PROJECT_CATEGORY_FETCH_SUCCESS,
  PROJECT_CATEGORY_FETCH_FAILED,
  FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_START,
  FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_SUCCESS,
  FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_FAILED,
  START_FETCH_SAC_MASTER_OF_SUBCLASS,
  FAILED_FETCH_SAC_MASTER_OF_SUBCLASS,
  SUCCESS_FETCH_SAC_MASTER_OF_SUBCLASS,
} from './types';

export const fetchSacMasterId = (token, id) => {
  return async (dispatch) => {
    dispatch({
      type: START_FETCH_SAC_MASTER_OF_SUBCLASS,
    });
    try {
      const res = await axios.get(
        `${BaseUrl}/sac_master_id_of_project_sub_class/${id}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            dataType: 'json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.statusText == 'OK') {
        dispatch({
          type: SUCCESS_FETCH_SAC_MASTER_OF_SUBCLASS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: FAILED_FETCH_SAC_MASTER_OF_SUBCLASS,
          payload: res.data.message,
        });
      }
    } catch (error) {
      dispatch({
        type: FAILED_FETCH_SAC_MASTER_OF_SUBCLASS,
        payload: error.message,
      });
    }
  };
};

export const fetchDefaultProjectAutoRecurringStatus = (token, id) => {
  return async (dispatch) => {
    dispatch({
      type: FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_START,
    });
    try {
      const res = await axios.get(
        `${BaseUrl}/auto_rec_status_of_project_sub_class/${id}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            dataType: 'json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.statusText == 'OK') {
        dispatch({
          type: FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_SUCCESS,
          payload: res.data['auto_recurring'],
        });
      } else {
        dispatch({
          type: FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_FAILED,
          payload: res.data.message,
        });
      }
    } catch (error) {
      dispatch({
        type: FETCH_DEFAULT_PROJECT_SUB_CLASS_STATUS_FAILED,
        payload: error.message,
      });
    }
  };
};

//client id,name
export const fetchProjectCategoryList = (token) => {
  return async (dispatch) => {
    dispatch({
      type: START_PROJECT_CATEGORY_FETCH,
    });
    try {
      const res = await axios.get(`${BaseUrl}/project_sub_class`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          dataType: 'json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.statusText == 'OK') {
        dispatch({
          type: PROJECT_CATEGORY_FETCH_SUCCESS,
          payload: res.data['project_sub_classes'],
        });
      } else {
        dispatch({
          type: PROJECT_CATEGORY_FETCH_FAILED,
          payload: 'Fetching project category list failed',
        });
      }
    } catch (error) {
      dispatch({
        type: PROJECT_CATEGORY_FETCH_FAILED,
        payload: 'Fetching project category list failed',
      });
    }
  };
};

export const addProjectClass = (data, token) => {
  return async (dispatch) => {
    dispatch({
      type: START_PROJECT_CLASS_ADD,
    });
    try {
      const res = await axios.post(`${BaseUrl}/project_sub_class`, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          dataType: 'json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.statusText == 'Created') {
        dispatch({
          type: PROJECT_CLASS_ADD_SUCCESS,
          payload: 'Sucessfully created project category',
        });
      } else {
        dispatch({
          type: PROJECT_CLASS_ADD_FAILURE,
          payload: 'Adding Project category failed',
        });
      }
    } catch (error) {
      dispatch({
        type: PROJECT_CLASS_ADD_FAILURE,
        payload: 'Adding project category failed',
      });
    }
  };
};
