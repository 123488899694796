import React from "react";
import PageTemplate from "../components/Layout/PageTemplate/PageTemplate";
import CoreUITable from "../components/Layout/Table";
import {
    useRecurringProjects,
    useAbortRecurringProjects,
} from "../hooks/recurrence-query/recurrences";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useHistory } from "react-router-dom";
import { AlertComponent } from "../utils/formComponents";
import { Link } from "react-router-dom";

const RecurringProjectsPage = () => {
    const history = useHistory();
    const [tempRecurrenceId, setTempRecurrenceId] = React.useState(undefined);
    const [showAlert, setShowAlert] = React.useState(false);
    const { isLoading, isError, data, error, refetch } = useRecurringProjects();
    const {
        isLoading: aborting,
        data: abortMsg,
        error: abortError,
        mutate,
    } = useAbortRecurringProjects();
    if (isLoading)
        return <ProgressBar striped variant="success" animated now={40} />;
    if (isError) return <>Error...</>;
    const options = [
        {
            text: "View",
            action: function (id) {
                //history.push({pathname: '/add_dsc_signature'})
            },
            params: true,
        },
        {
            text: "Edit",
            action: function (id) {
                history.push({ pathname: "/edit_recurrence", id: id });
            },
            params: true,
        },
        {
            text: "Abort",
            action: function (id) {
                setShowAlert(true);
                setTempRecurrenceId(id);
            },
            params: true,
        },
    ];
    const proceedToAbort = () => {
        setShowAlert(false);
        mutate(tempRecurrenceId);
    };
    return (
        <PageTemplate>
            <CoreUITable
                page="recurring_projects"
                title="Recurring Projects"
                options={options}
                data={data}
                fields={[
                    "name",
                    "client",
                    "frequency",
                    "auto_create",
                    "next_creation_date",
                    "project_sub_class",
                    "budgeted_bill_amount",
                    {
                        key: "show_details",
                        label: "",
                        _style: { width: "1%" },
                        filter: false,
                    },
                ]}
            />
            <AlertComponent
                title="Abort recurring project?"
                description={`Are you sure you abort this recurring project?`}
                open={showAlert}
                cancelText="No, dont proceed"
                saveText="Yes Proceed"
                handleClose={() => {
                    setShowAlert(false);
                }}
                proceed={() => proceedToAbort()}
            />
            <AlertComponent
                title="Aborting.."
                description={
                    abortMsg !== ""
                        ? abortMsg
                        : "Aborting the recurring project,please wait"
                }
                open={aborting}
            />
        </PageTemplate>
    );
};

export default RecurringProjectsPage;
