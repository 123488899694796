export const editableDate=(parts_d1, parts_d2, editable_days)=>{//unknown_date[dd,mm,yy], todays_date[dd,mm,yy]
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    let result = false
    const firstDate = new Date(
        parts_d1[2],
        parts_d1[1] - 1,
        parts_d1[0]
    );
    const secondDate = new Date(
        parts_d2[2],
        parts_d2[1] - 1,
        parts_d2[0]
    );
    const diffDays = Math.round(
        Math.abs((firstDate - secondDate) / oneDay)
    );
    if (editable_days['value']) {
        result =
            diffDays > Number(editable_days['value']);
    } else {
        result = diffDays > 5;
    }
    console.log("check data inside editdate", parts_d1, parts_d2, editable_days['value'], result,diffDays, firstDate,secondDate)
    return !result //negating as true means not editable
}
