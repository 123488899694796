
import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field,change } from "redux-form";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import MultiSelectComponent from "../../utils/multiselect"
import {InputText,TextArea,SelectInput,validate,SavingDetailsModal} from "../../utils/formComponents";
class AddAuditDocumentRegister extends Component{

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        auditDocumentAddFailed:true,
        auditDocument:{}
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
}

componentWillMount(){
    this.props.fetchClientList(this.props.token)
}
componentDidUpdate(){
    setTimeout(() => this.setState({message:''}), 9000);
    if(!this.state.auditDocumentAddFailed){
        this.props.history.goBack();
    }
  }

closeModal(){
    this.setState({
        loading:this.props.loading,
        auditDocumentAddFailed:this.props.auditDocumentAddFailed
    })
}
async onsubmit(formData) {

    this.setState({
        loading:true,
        auditDocument: formData
    })
    await this.setState(prevState =>({
        auditDocument: {
            ...prevState.auditDocument,
            client_id: formData['client_id']['id'],
            project_id: formData['project_id']['id']
        }
    }))
    await this.props.addAuditDocumentegister(this.state.auditDocument,this.props.token);
    this.setState({
        message:this.props.message
    })
}
  render () {
    let state = this.state;
const { handleSubmit,classes } = this.props;
    return (
      <Container>
        <Header title="add_activity" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
       Add Audit Document Register
      </Typography>
        <Row>
          <Col sm={4}>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(this.onsubmit)}>
          <Row>
            <Col sm={8}>
              <span>Select Client</span>

              <Field
                name="client_id"
                label=" "
                  props={{
                      type: 'object'
                  }}
                  component={MultiSelectComponent}
                  {...{
                      data:this.props.dclients,
                      isMulti : false
                  }

                  }
                fullWidth
             />
            </Col>
            <Col sm={4}>
              <span>Select project</span>
              <Field
                  name="project_id"
                  label=" "
                  props={{
                      type: 'object'
                  }}
                  component={MultiSelectComponent}
                  {...{
                      data:this.props.clientProjects,
                      isMulti : false
                  }

                  }
                  fullWidth
              />
            </Col>
          </Row>

          <Row>
            <Col sm={8}>

              <span>File number</span>
              <Field
                        name="file_number"
                        label="File Number"
                        component={InputText}
                        type="text"
                        fullWidth
              />
        </Col>

            <Col sm={4}>

              <span>Decsription</span>

              <Field
                        name="description"
                        label="description"
                        component={InputText}
                        type="text"
                        fullWidth
              />
            </Col>
          </Row>
        <Row>
          <Col sm={8}>
          <span>Retention date</span>

              <Field
                        name="retention_date"
                        label="rentention date"
                        component={InputText}
                        type="date"
                        fullWidth
              />

          </Col>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving audit document Details"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>
          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    )
  }
}

function mapStateToProps(state) {
    return {
        clientProjects: state.project.clientProjects,
        dclients: state.client.dclientList,
        token:state.auth.token,
        message:state.auditDocument.message,
        loading:state.auditDocument.loading,
        auditDocumentAddFailed: state.auditDocument.auditDocumentAddFailed
    }
}
export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddAuditDocumentRegister",
      enableReinitialize: true,
validate }),
    withStyles(styles)
)(AddAuditDocumentRegister);
