import {START_OUTWARD_REGISTER_ADD,OUTWARD_REGISTER_ADD_SUCCESS,OUTWARD_REGISTER_ADD_FAILED,
FETCH_OUTWARD_REGISTER_START,FETCH_OUTWARD_REGISTER_SUCCESS,FETCH_OUTWARD_REGISTER_FAILED} from '../actions/types';
const DEFAULT_STATE={
    outwardRegisterList:[],
    loading: false,
    message:'',
    outwardRegisterAddFailed:true
}
export default(state=DEFAULT_STATE,action)=>{
    switch(action.type){
        case START_OUTWARD_REGISTER_ADD:
            return{
                ...state,
                loading: true,
                message:'',
                outwardRegisterAddFailed: false
            }
        case OUTWARD_REGISTER_ADD_SUCCESS:
            return{
                ...state,
                loading: false,
                message: action.payload,
                outwardRegisterAddFailed: false
            }
        case OUTWARD_REGISTER_ADD_FAILED:
            return{
                ...state,
                loading: false,
                message: action.payload,
                outwardRegisterAddFailed: true
            }
        case FETCH_OUTWARD_REGISTER_START:
            return{
                ...state,
                loading: true,
                message:''
            }
        case FETCH_OUTWARD_REGISTER_SUCCESS:
            return{
                ...state,
                loading: false,
                outwardRegisterList: action.payload
            }
        case FETCH_OUTWARD_REGISTER_FAILED:
            return{
                ...state,
                loading: false,
                message: action.payload
            }
        default:
            return state
    }
}
