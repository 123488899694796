import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_ADDING_NEW_REGISTER,
    NEW_REGISTER_ADDED_SUCCESSFULLY,
    FAILED_ADDING_NEW_REGISTER,
    START_FETCHING_ISSUE_REGISTERS_LIST,
    FETCHING_ISSUE_REGISTERS_LIST_DONE,
    FETCHING_ISSUE_REGISTERS_LIST_FAILED,
} from "./types";

export const fetchIssueRegistersList = (token) => {
    return async (dispatch) => {
        dispatch({
            type: START_FETCHING_ISSUE_REGISTERS_LIST,
        });
        try {
            const res = await axios.get(`${BaseUrl}/issue_registers`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (res.statusText == "OK") {
                dispatch({
                    type: FETCHING_ISSUE_REGISTERS_LIST_DONE,
                    payload: res.data["issue_registers"],
                });
            } else {
                dispatch({
                    type: FETCHING_ISSUE_REGISTERS_LIST_FAILED,
                    payload: "Fetching issue registers list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: FETCHING_ISSUE_REGISTERS_LIST_FAILED,
                payload: "Fetching issue registers list failed",
            });
        }
    };
};

export const issueNewRegister = (token, data) => {
    return async (dispatch) => {
        dispatch({
            type: START_ADDING_NEW_REGISTER,
        });
        try {
            const res = await axios.post(`${BaseUrl}/issue_register`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "Created") {
                dispatch({
                    type: NEW_REGISTER_ADDED_SUCCESSFULLY,
                    payload: "Sucessfully added new register ",
                });
            } else {
                dispatch({
                    type: FAILED_ADDING_NEW_REGISTER,
                    payload: "adding new register failed",
                });
            }
        } catch (error) {
            dispatch({
                type: FAILED_ADDING_NEW_REGISTER,
                payload: "adding new register failed",
            });
        }
    };
};
