import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import { Table } from "react-bootstrap";

export const ProjectDetails = ({ unBilled, liveOrYetToCommence, otherProjects }) => {
    /**const [liveOrYetToCommence, setLiveOrYetToCommence] = useState([]);
    const [unBilled, setUnBilled] = useState([]);
    const [otherProjects, setOtherProjects] = useState([]);

    useEffect(() => {
        if (projects) {
            let arr = projects.filter(
                (project) =>
                    project.status === "YetToCommence" ||
                    project.status === "InProgress"
            );
            setLiveOrYetToCommence(arr);

            arr = projects.filter(
                (project) => project.billing_status === "Unbilled"
            );
            setUnBilled(arr);

            arr = projects.filter(
                (project) =>
                    project.status !== "YetToCommence" &&
                    project.status !== "InProgress" &&
                    project.billing_status !== "Unbilled"
            );
            setOtherProjects(arr);
        }
    }, [projects]);**/

    return (
        <div>
            <div style={styles.infoContainer}>
                <h3 style={styles.infoHeading}>Live Projects</h3>
                { liveOrYetToCommence && liveOrYetToCommence.length > 0 ? (
                    <Table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Billing Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {liveOrYetToCommence.map((project) => (
                                <tr>
                                    <td>{project.id}</td>
                                    <td>{project.name}</td>
                                    <td>{project.status}</td>
                                    <td>{project.billing_status || "---"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div> No projects </div>
                )}
            </div>
            <div style={styles.infoContainer}>
                <h3 style={styles.infoHeading}>Unbilled projects</h3>
                {unBilled && unBilled.length > 0 ? (
                    <Table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Billing Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {unBilled.map((project) => (
                                <tr>
                                    <td>{project.id}</td>
                                    <td>{project.name}</td>
                                    <td>{project.status}</td>
                                    <td>{project.billing_status || "---"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div> No projects </div>
                )}
            </div>
            <div style={styles.infoContainer}>
                <h3 style={styles.infoHeading}>Other projects</h3>
                { otherProjects && otherProjects.length > 0 ? (
                    <Table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Billing Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {otherProjects.map((project) => (
                                <tr>
                                    <td>{project.id}</td>
                                    <td>{project.name}</td>
                                    <td>{project.status}</td>
                                    <td>{project.billing_status || "---"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div> No projects </div>
                )}
            </div>
        </div>
    );
};
