
import React, {Component} from 'react';
import { Row, Col,Container,Badge } from 'react-bootstrap';
import { reduxForm, Field,change } from "redux-form";
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Header from "../../components/Layout/Header/Header";
import * as actions from "../../actions";
import styles from '../modelstyles';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import {InputText,TextArea,SelectInput,validate,SavingDetailsModal} from "../../utils/formComponents";
class AddProjectActivity extends Component{

  constructor(props) {
    super(props);
    this.state = {
        message:'',
        loading:false,
        projectActivityAddFailed:true,
    };

    this.onsubmit = this.onsubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
}

componentWillMount(){
    //this.props.fetchProjectCategoryList(this.props.token)
    //this.props.fetchNatureofexp(this.props.token);
    //this.props.fetchProjectList(this.props.token);
}
componentDidUpdate(){
    setTimeout(() => this.setState({message:''}), 9000);
    if(!this.state.projectActivityAddFailed){
        this.props.history.goBack();
    }
  }

closeModal(){
    this.setState({
        loading:this.props.loading,
        projectActivityAddFailed:this.props.projectActivityAddFailed
    })
}
async onsubmit(formData) {

    this.setState({
        loading:true
    })
    formData['project_id']= this.props.location.data['id']
    await this.props.addProjectActivity(formData,this.props.token);
    this.setState({
        message:this.props.message
    })
}
  render () {
    let state = this.state;
    const { handleSubmit,classes } = this.props;
    return (
      <Container>
        <Header title="add_project_activity" />
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
       Add Project Activity
      </Typography>
        <Row>
          <Col sm={4}>
          </Col>
        </Row>
        <form onSubmit= {handleSubmit(this.onsubmit)}>
          <Row>
            <Col sm={8}>

              <span>Activity short description</span>
              <Field
                        name="activity_short_description"
                        label="short description"
                        component={InputText}
                        type="text"
                        fullWidth
              />
        </Col>

            <Col sm={4}>

              <span>Activity long decsription</span>

              <Field
                        name="activity_long_description"
                        label="long description"
                        component={InputText}
                        type="text"
                        fullWidth
              />
            </Col>
          </Row>
          <Row>
            <Col sm={8}>

              <span>Select Status</span>
              <Field
                  name="activity_status"
                  label="activity status *"
                  component={SelectInput}
                  fullWidth
              />
            </Col>
            <Col sm={4}>
                <span>Select due date</span>
              <Field
                  name="due_date"
                  label=""
                  component={InputText}
                  fullWidth
                  type="date"
              />
            </Col>
          </Row>
        <Row>
          <Col sm={4}>

          <SavingDetailsModal
             className={classes.modal}
             show={this.state.loading}
             title={"Saving project activity Details"}
             loading={this.props.loading}
             message={this.props.message}
             onClick={this.closeModal}
          />
          </Col>

        </Row>
        <Row>
        <Col sm={8}>
          {this.state.message }
        </Col>
        </Row>
          <Row style={{marginTop:20}} >
            <Col >
              <Row className='float-right'>
                <Col>
                <Button type="submit" variant="contained" color="primary">Add</Button>

                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
    )
  }
}

function mapStateToProps(state) {
    return {
        token:state.auth.token,
        message:state.projectActivity.projectActivityAddMessage,
        loading:state.projectActivity.projectActivityAddLoading,
        projectActivityAddFailed:state.projectActivity.projectActivityAddFailed
    }
}
export default compose(withRouter,
  connect(mapStateToProps, actions),
  reduxForm({ form: "AddProjectActivity",
      enableReinitialize: true,
validate }),
    withStyles(styles)
)(AddProjectActivity);
