import React from "react";
import { Row, Col, Container, Badge, Table } from "react-bootstrap";
import Header from "../../components/Layout/Header/Header";
import { Formik, Field, Form, ErrorMessage, FieldArray, getIn } from "formik";
import {
    AlertComponent,
    InputText,
    SelectInput,
} from "../../utils/formComponents";
import Select from "react-select";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import { Divider, Switch } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "react-bootstrap/ProgressBar";
import filter from "lodash/filter";
import find from "lodash/find";
import { fetchMemberRoles } from "../../actions/roleAction";
import { fetchClientList } from "../../actions/clientsAction";
import {
    fetchEmployeeDropDownList,
    addBackEmployee,
    removeEmployee,
} from "../../actions/employeeAction";
import { fetchProjectCategoryList } from "../../actions/projectclassAction";
import { fetchSacmasterList } from "../../actions/sacmasterAction";
import { fetchOrganisation } from "../../actions/organisationAction";
import {
    useRecurringProject,
    useRecurringProjectUpdate,
} from "../../hooks/recurrence-query/recurrences";
import cloneDeep from 'lodash/cloneDeep';
let initialValues = {
    recurrence_project_members_attributes: [
        {
            id: "",
            member_id: "",
            _destroy: false,
            role_id: {},
            spec_role: {},
            designation: {},
        },
    ],
    name: "",
    description: "",
    client_id: {},
    project_sub_class_id: "",
    sac_master_id: "",
    udin_mandatory: "",
    any_deliverables: true,
    budgeted_bill_amount: "",
    organisation_id: "",
    billable: true,
    auto_recurring: true,
};
const selectStyles = {
    menu: (base) => ({
        ...base,
        zIndex: 100,
    }),
};
export default function EditRecurringProject(props) {
    const [formValues, setFormValues] = React.useState({});
    const [showDetails, setShowDetails] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [intialLoading, setInitialLoading] = React.useState(true); //turn false after intial data required is fetched

    const {
        isLoading: updateLoading,
        mutate,
        data: updateData,
        error,
    } = useRecurringProjectUpdate();
    const history = useHistory();
    //already added redux related data fetching
    const state = useSelector((state) => state);
    const { token, user } = state.auth;
    const { role } = state.memberRole;
    const { employeeDropdownList } = state.employee;
    const { dclientList } = state.client;
    const { dprojectCategoryList } = state.projectClass;
    const { dorganisationList } = state.organisation;
    const { sacMasterList } = state.sacmaster;
    const dispatch = useDispatch();
    const { id } = useLocation();
    const { data: initialValues, isLoading } = useRecurringProject(id);
    const didMountRef = React.useRef(false);


    React.useEffect(() => {
        //try to remove members that are selected from employee dropdown
        if (
            !intialLoading &&
            initialValues &&
            initialValues.recurrence_project_members_attributes &&
            initialValues.recurrence_project_members_attributes.length > 0 &&
            !didMountRef.current
        ) {
            initialValues.recurrence_project_members_attributes.map((item) => {
                dispatch(removeEmployee(item["member_id"]));
            });
            didMountRef.current = true;
        }
    }, [initialValues, intialLoading]);

    function getListwithDeletedMembers(data) {
        data.recurrence_project_members_attributes.forEach((item) => {
            item["member_id"] = item["member_id"]["id"];
            item["role_id"] = item["role_id"]["id"];
            if(item['spec_role']['id'] !== undefined)item["spec_role"] = item['spec_role']['id']
        });
        for (const key of initialValues[
            "recurrence_project_members_attributes"
        ]) {
            if (
                !find(data["recurrence_project_members_attributes"], {
                    id: key.id,
                })
            ) {
                let temp = key;
                temp["_destroy"] = true;
                data["recurrence_project_members_attributes"].push(temp);
            }
        }
        return data;
    }
    function formatAndSubmit(formData) {
        let values = cloneDeep(formData)
        values["updated_by_id"] = user.id;
        values["organisation_id"] = values["organisation_id"]["id"];
        values["sac_master_id"] = values["sac_master_id"]["id"];

        values = getListwithDeletedMembers({ ...values });
        mutate({ ...values });
        setShowDetails(true);
    }
    function validate(inputs) {
        console.log("validate rec edit",inputs)
        let errors = {};
        if (!inputs.client_id) errors.client_id = "Required";
        if (!inputs.name) errors.name = "Required";
        if (!inputs.description) errors.description = "Required";
        if (!inputs.project_sub_class_id)
            errors.project_sub_class_id = "Required";
        if (!("any_deliverables" in inputs))
            errors.any_deliverables = "Required";
        if (!("auto_recurring" in inputs)) errors.auto_recurring = "Required";
        if (!("udin_mandatory" in inputs)) errors.udin_mandatory = "Required";
        if (!inputs.budgeted_bill_amount)
            errors.budgeted_bill_amount = "Required";
        if (!inputs.organisation_id) errors.organisation_id = "Required";
        if (!("billable" in inputs)) errors.billable = "Required";
        if (!inputs.sac_master_id) errors.sac_master_id = "Required";
        if (
            !inputs.recurrence_project_members_attributes ||
            !inputs.recurrence_project_members_attributes.length
        ) {
            errors.recurrence_project_members_attributes = {};
            errors.recurrence_project_members_attributes._error =
                "At least one member must be entered";
        } else if (
            filter(inputs.recurrence_project_members_attributes, {
                role_id: { name: "Engagement Partner" },
            }).length === 0
        ) {
            errors.recurrence_project_members_attributes = {};
            errors.recurrence_project_members_attributes._error =
                "At least one member must be Engagement Partner";
        } else if (
            filter(inputs.recurrence_project_members_attributes, {
                spec_role: { id: 0 }//preparer
            }).length === 0
        ) {
            errors.recurrence_project_members_attributes = {};
            errors.recurrence_project_members_attributes._error =
                "At least one member must be Preparere";
        }else if (
            filter(inputs.recurrence_project_members_attributes, {
                spec_role: { id: 1 }//reviewer
            }).length === 0
        ) {
            errors.recurrence_project_members_attributes = {};
            errors.recurrence_project_members_attributes._error =
                "At least one member must be Rivewer";
        } else {
            const errorsArray = [];
            inputs.recurrence_project_members_attributes.forEach(
                (member, index) => {
                    const memberErrors = {};
                    if (!member || !member.member_id) {
                        memberErrors.member_id = "Required";
                        errorsArray[index] = memberErrors;
                    }
                    if (!member || !member.role_id) {
                        memberErrors.role_id = "Required";
                        errorsArray[index] = memberErrors;
                    }
                }
            );
            if (errorsArray.length) {
                errors.recurrence_project_members_attributes = errorsArray;
            }
        }
        return errors;
    }

    React.useEffect(() => {
        if (updateLoading) setMessage("updating recurrence details");
        if (updateData && updateData.message) setMessage(updateData["message"]);
        if (error) setMessage(error);
    }, [updateData, updateLoading, error]);

    React.useEffect(() => {
        if (!showDetails) {
            if (updateData && updateData["status"]) {
                history.goBack();
            }
        }
    }, [showDetails]);

    React.useEffect(() => {
        Promise.all([
            dispatch(fetchMemberRoles(token)),
            dispatch(fetchClientList(token)),
            dispatch(fetchOrganisation(token)),
            dispatch(fetchEmployeeDropDownList(token)),
            dispatch(fetchProjectCategoryList(token)),
            dispatch(fetchSacmasterList(token)),
        ]).then(() => setInitialLoading(false));
    }, []);
    if (intialLoading || isLoading)
        return <ProgressBar striped variant="success" animated now={40} />;
    return (
        <Container>
            <Header title="add_dsc_signature" />
            <h1 style={{ marginLeft: "20%" }}>
                {" "}
                <Badge variant="dark">Edit Recurrence Project</Badge>{" "}
            </h1>
            <Formik
                validate={validate}
                initialValues={initialValues}
                onSubmit={async (values) => formatAndSubmit({ ...values })}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <Row style={{ marginTop: "2%", alignItems: "center" }}>
                            <Col sm={8}>
                                <span>Select Client</span>
                                <Field
                                    name="client_id"
                                    options={dclientList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    component={Select}
                                    value={
                                        values.client_id
                                            ? values.client_id
                                            : undefined
                                    }
                                    onChange={(event) => {
                                        setFieldValue("client_id", event);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    isDisabled={true}
                                    styles={selectStyles}
                                />
                                {errors.client_id && touched.client_id && (
                                    <div
                                        style={{
                                            marginTop: "2%",
                                            alignItems: "center",
                                        }}
                                    >
                                        {errors.client_id}
                                    </div>
                                )}
                            </Col>
                            <Col sm={4}>
                                <span>Project Short Name</span>
                                <Field
                                    name="name"
                                    type="text"
                                    style={{ width: "100%" }}
                                />
                                {errors.name && touched.name && (
                                    <div
                                        style={{
                                            marginTop: "2%",
                                            alignItems: "center",
                                        }}
                                    >
                                        {errors.name}
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "2%", alignItems: "center" }}>
                            <Col sm={8}>
                                <span>Description</span>
                                <Field
                                    name="description"
                                    type="text"
                                    style={{ width: "100%" }}
                                />
                                {errors.description && touched.description && (
                                    <div
                                        style={{
                                            marginTop: "2%",
                                            alignItems: "center",
                                        }}
                                    >
                                        {errors.description}
                                    </div>
                                )}
                            </Col>
                            <Col sm={4}>
                                <span>Select Project Category</span>
                                <Field
                                    name="project_sub_class_id"
                                    options={dprojectCategoryList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    component={Select}
                                    value={
                                        values.project_sub_class_id
                                            ? values.project_sub_class_id
                                            : undefined
                                    }
                                    onChange={(event) => {
                                        setFieldValue(
                                            "project_sub_class_id",
                                            event.id
                                        );
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    isDisabled={true}
                                    styles={selectStyles}
                                />
                                {errors.project_sub_class_id &&
                                    touched.project_sub_class_id && (
                                        <div
                                            style={{
                                                marginTop: "2%",
                                                alignItems: "center",
                                            }}
                                        >
                                            {errors.project_sub_class_id}
                                        </div>
                                    )}
                            </Col>
                        </Row>

                        <Divider
                            style={{ marginTop: "2%", alignItems: "center" }}
                        />
                        <h3>
                            <Badge variant="dark">Team</Badge>
                        </h3>
                        <Table
                            responsive
                            style={{ width: "100%", marginTop: "2%" }}
                        >
                            <thead>
                                <tr>
                                    <th style={{ width: "30%" }}>Member</th>
                                    <th>Designation</th>
                                    <th>Role</th>
                                    <th>Spec Role</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <FieldArray name="recurrence_project_members_attributes">
                                {({ insert, remove, push }) => (
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <td sm={5}>
                                                <Button
                                                    onClick={() => {
                                                        push({
                                                            id: "",
                                                            designation: "",
                                                            member_id: {},
                                                            role_id: {},
                                                            spec_role: {}
                                                        });
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                            </td>
                                        </tr>

                                        {values
                                            .recurrence_project_members_attributes
                                            .length > 0 &&
                                            values.recurrence_project_members_attributes.map(
                                                (details, index) => (
                                                    <tr
                                                        style={{
                                                            marginTop: "2%",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <td sm={5}>
                                                            <Field
                                                                name={`recurrence_project_members_attributes.${index}.member_id`}
                                                                options={
                                                                    employeeDropdownList
                                                                }
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                component={
                                                                    Select
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setFieldValue(
                                                                        `recurrence_project_members_attributes.${index}.member_id`,
                                                                        event
                                                                    );
                                                                    setFieldValue(
                                                                        `recurrence_project_members_attributes.${index}.designation`,
                                                                        event.designation
                                                                    );
                                                                    dispatch(
                                                                        removeEmployee(
                                                                            event
                                                                        )
                                                                    );
                                                                }}
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option.name
                                                                }
                                                                getOptionValue={(
                                                                    option
                                                                ) => option.id}
                                                                styles={
                                                                    selectStyles
                                                                }
                                                                isDisabled={
                                                                    Object.keys(
                                                                        values[
                                                                            "recurrence_project_members_attributes"
                                                                        ][
                                                                            index
                                                                        ][
                                                                            "member_id"
                                                                        ]
                                                                    ).length > 0
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={
                                                                    Object.keys(
                                                                        values[
                                                                            "recurrence_project_members_attributes"
                                                                        ][
                                                                            index
                                                                        ][
                                                                            "member_id"
                                                                        ]
                                                                    ).length > 0
                                                                        ? values[
                                                                              "recurrence_project_members_attributes"
                                                                          ][
                                                                              index
                                                                          ][
                                                                              "member_id"
                                                                          ]
                                                                        : undefined
                                                                }
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        "2%",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                {getIn(
                                                                    errors,
                                                                    `recurrence_project_members_attributes.${index}.member_id`
                                                                )
                                                                    ? "Required to proceed"
                                                                    : ""}
                                                            </div>
                                                        </td>
                                                        <td sm={5}>
                                                            <Field
                                                                name={`recurrence_project_members_attributes.${index}.designation`}
                                                                type="text"
                                                                style={{
                                                                    width: "100%",
                                                                    height: "5%",
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        "2%",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                {getIn(
                                                                    errors,
                                                                    `recurrence_project_members_attributes.${index}.designation`
                                                                )
                                                                    ? "Required to proceed"
                                                                    : ""}
                                                            </div>
                                                        </td>
                                                        <td sm={5}>
                                                            <Field
                                                                name={`recurrence_project_members_attributes.${index}.role_id`}
                                                                options={role}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                component={
                                                                    Select
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setFieldValue(
                                                                        `recurrence_project_members_attributes.${index}.role_id`,
                                                                        event
                                                                    );
                                                                }}
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option.name
                                                                }
                                                                getOptionValue={(
                                                                    option
                                                                ) => option.id}
                                                                styles={
                                                                    selectStyles
                                                                }
                                                                value={
                                                                    values[
                                                                        "recurrence_project_members_attributes"
                                                                    ][index][
                                                                        "role_id"
                                                                    ]
                                                                        ? values[
                                                                              "recurrence_project_members_attributes"
                                                                          ][
                                                                              index
                                                                          ][
                                                                              "role_id"
                                                                          ]
                                                                        : undefined
                                                                }
                                                            />
                                                            <div
                                                                style={{
                                                                    marginTop:
                                                                        "2%",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                {getIn(
                                                                    errors,
                                                                    `recurrence_project_members_attributes.${index}.role_id`
                                                                )
                                                                    ? "Required to proceed"
                                                                    : ""}
                                                            </div>
                                                        </td>
                                                        <td sm={5}>
                                                            <Field
                                                                name={`recurrence_project_members_attributes.${index}.spec_role`}
                                                                options={[{id: 0, name: "Preparer"},{id: 1, name: "Reviewer"}]}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                component={
                                                                    Select
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setFieldValue(
                                                                        `recurrence_project_members_attributes.${index}.spec_role`,
                                                                        event
                                                                    );
                                                                }}
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option.name
                                                                }
                                                                getOptionValue={(
                                                                    option
                                                                ) => option.id}
                                                                styles={
                                                                    selectStyles
                                                                }
                                                                value={
                                                                    values[
                                                                        "recurrence_project_members_attributes"
                                                                    ][index][
                                                                        "spec_role"
                                                                    ]
                                                                        ? values[
                                                                              "recurrence_project_members_attributes"
                                                                          ][
                                                                              index
                                                                          ][
                                                                              "spec_role"
                                                                          ]
                                                                        : undefined
                                                                }
                                                            />
                                                        </td>
                                                        <td sm={5}>
                                                            <Button
                                                                onClick={() => {
                                                                    if (
                                                                        values.recurrence_project_members_attributes &&
                                                                        values
                                                                            .recurrence_project_members_attributes[
                                                                            index
                                                                        ] &&
                                                                        Object.keys(
                                                                            values
                                                                                .recurrence_project_members_attributes[
                                                                                index
                                                                            ][
                                                                                "member_id"
                                                                            ]
                                                                        )
                                                                            .length >
                                                                            0
                                                                    ) {
                                                                        dispatch(
                                                                            addBackEmployee(
                                                                                values
                                                                                    .recurrence_project_members_attributes[
                                                                                    index
                                                                                ][
                                                                                    "member_id"
                                                                                ]
                                                                            )
                                                                        );
                                                                    }

                                                                    remove(
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <ClearIcon />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        <tr
                                            style={{
                                                marginTop: "2%",
                                                alignItems: "center",
                                            }}
                                        >
                                            {" "}
                                            {getIn(
                                                errors,
                                                `recurrence_project_members_attributes._error`
                                            )
                                                ? getIn(
                                                      errors,
                                                      `recurrence_project_members_attributes._error`
                                                  )
                                                : ""}
                                        </tr>
                                    </tbody>
                                )}
                            </FieldArray>
                        </Table>

                        <Divider
                            style={{ marginTop: "2%", alignItems: "center" }}
                        />
                        <h3>
                            <Badge variant="dark">
                                For Certificate register Module
                            </Badge>
                        </h3>
                        <Row style={{ marginTop: "2%", alignItems: "center" }}>
                            <Col sm={8}>
                                <span>Any deliverables?</span>
                                <Field
                                    name="any_deliverables"
                                    component={Switch}
                                    checked={values.any_deliverables}
                                    onChange={(event) =>
                                        setFieldValue(
                                            "any_deliverables",
                                            event.target.checked
                                        )
                                    }
                                />
                                {errors.any_deliverables &&
                                    touched.any_deliverables && (
                                        <div
                                            style={{
                                                marginTop: "2%",
                                                alignItems: "center",
                                            }}
                                        >
                                            {errors.any_deliverables}
                                        </div>
                                    )}
                            </Col>
                            <Col sm={4}>
                                <span>Is UDIN mandatory </span>
                                <Field
                                    name="udin_mandatory"
                                    component={Switch}
                                    checked={values.udin_mandatory}
                                    onChange={(event) =>
                                        setFieldValue(
                                            "udin_mandatory",
                                            event.target.checked
                                        )
                                    }
                                />
                                {errors.udin_mandatory &&
                                    touched.udin_mandatory && (
                                        <div
                                            style={{
                                                marginTop: "2%",
                                                alignItems: "center",
                                            }}
                                        >
                                            {errors.udin_mandatory}
                                        </div>
                                    )}
                            </Col>
                        </Row>
                        <Divider
                            style={{ marginTop: "2%", alignItems: "center" }}
                        />
                        <h3>
                            <Badge variant="dark">Others</Badge>
                        </h3>

                        <Row style={{ marginTop: "2%", alignItems: "center" }}>
                            <Col sm={values.billable ? 4 : 8}>
                                <span>Billable or not? </span>
                                <Field
                                    name="billable"
                                    component={Switch}
                                    checked={values.billable}
                                    onChange={(event) =>
                                        setFieldValue(
                                            "billable",
                                            event.target.checked
                                        )
                                    }
                                />
                                {errors.billable && touched.billable && (
                                    <div
                                        style={{
                                            marginTop: "2%",
                                            alignItems: "center",
                                        }}
                                    >
                                        {errors.billable}
                                    </div>
                                )}
                            </Col>
                            {values.billable && <Col sm={4}>
                                <span>Budgeted bill amount </span>
                                <Field
                                    name="budgeted_bill_amount"
                                    label="budgeted bill amount*"
                                    type="number"
                                    style={{ width: "100%" }}
                                />
                                {errors.budgeted_bill_amount &&
                                    touched.budgeted_bill_amount && (
                                        <div
                                            style={{
                                                marginTop: "2%",
                                                alignItems: "center",
                                            }}
                                        >
                                            {errors.budgeted_bill_amount}
                                        </div>
                                    )}
                            </Col>}
                            <Col sm={4}>
                                <span>Select Organisation</span>
                                <Field
                                    name="organisation_id"
                                    options={dorganisationList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    component={Select}
                                    onChange={(event) => {
                                        setFieldValue("organisation_id", event);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    styles={selectStyles}
                                    value={
                                        values.organisation_id
                                            ? values.organisation_id
                                            : undefined
                                    }
                                />
                                {errors.organisation_id &&
                                    touched.organisation_id && (
                                        <div
                                            style={{
                                                marginTop: "2%",
                                                alignItems: "center",
                                            }}
                                        >
                                            {errors.organisation_id}
                                        </div>
                                    )}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "2%", alignItems: "center" }}>

                            <Col sm={8}>
                                <span>Auto create</span>
                                <Field
                                    name="auto_recurring"
                                    component={Switch}
                                    checked={values.auto_recurring}
                                    onChange={(event) =>
                                        setFieldValue(
                                            "auto_recurring",
                                            event.target.checked
                                        )
                                    }
                                />
                                {errors.auto_recurring &&
                                    touched.auto_recurring && (
                                        <div
                                            style={{
                                                marginTop: "2%",
                                                alignItems: "center",
                                            }}
                                        >
                                            {errors.auto_recurring}
                                        </div>
                                    )}
                            </Col>
                            <Col sm={4}>
                                <span>Sac master</span>
                                <Field
                                    name="sac_master_id"
                                    options={sacMasterList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    component={Select}
                                    onChange={(event) => {
                                        setFieldValue("sac_master_id", event);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    styles={selectStyles}
                                    value={
                                        values.sac_master_id
                                            ? values.sac_master_id
                                            : undefined
                                    }
                                />
                                {errors.sac_master_id &&
                                    touched.sac_master_id && (
                                        <div
                                            style={{
                                                marginTop: "2%",
                                                alignItems: "center",
                                            }}
                                        >
                                            {errors.sac_master_id}
                                        </div>
                                    )}
                            </Col>
                        </Row>

                        <Button
                            type="submit"
                            style={{
                                marginLeft: "95%",
                                marginTop: "2%",
                                marginBottom: "5%",
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                    </Form>
                )}
            </Formik>

            <AlertComponent
                title="Updating"
                description={message}
                saveText={"OK"}
                open={showDetails}
                proceed={() => setShowDetails(false)}
            />
        </Container>
    );
}
