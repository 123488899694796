import axios from "../../axiosConfig";
import { useQuery } from "react-query";

async function getOrganisationBillingInfo({ queryKey }) {
  try {
    const res = await axios.get(`/org_and_bank_details/${queryKey[1]}`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

async function getOrganisations() {
  try {
    const res = await axios.get(`/org_list`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}
async function getDesignation() {
  try {
    const res = await axios.get(`/designation`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
}

export const useOrganisationBillingInfo = (id) =>
  useQuery(["org_billing_info", id], getOrganisationBillingInfo, {
    refetchOnWindowFocus: false,
    enabled: id ? true : false,
  });

export const useOrganisation = () =>
  useQuery(["organisation"], getOrganisations, {
    refetchOnWindowFocus: false,
  });

export const useDesignation = () =>
  useQuery(["designation"], getDesignation, {
    refetchOnWindowFocus: false,
  });
