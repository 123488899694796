import React from "react";
import "./profile.css";
import { makeStyles } from '@material-ui/core/styles';
import {GeneralDetails} from "./General";
import { LifeCycle } from "./LifeCycle";
import { Security } from "./Security";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import { Leave } from "./Leave";
import { ICAI } from "./ICAI";
import { FirmAssets } from './FirmAsset'
import Header from "../../components/Layout/Header/Header";
import { useSelector } from 'react-redux';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  tabsIndicator: {

  },
  tabRoot: {
    "&:hover": {
      color: "#1795be",
    },
    "&$tabSelected": {
      color: "#1795be",

    },
    "&:focus": {
      color: "#1795be"
    }
  }
}))

function ProfileDetails(){
    const classes = useStyles();
    const [name, setName] = React.useState('profile')
    const [value, setValue] = React.useState(0)
    const {auth: {user}} = useSelector(state => state)
    const handleChange = (event, value) => {
        setValue(value)
    };
    React.useEffect(()=>{

    },[])
    return(
        <div className="background" >
            <Header title="profile" />
            <div style={{color:"white" ,fontWeight:"700" ,paddingLeft:"20px"}} >
                <h3 >Profile</h3>
            </div>
            <header className="Header" borderBottom={1} >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                    TabIndicatorProps={{style: {background:'#08698a',},}}
                >

                    <Tab style={{outline:"none",}}  classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                    onClick={()=>setName('profile') }  label={<h3 className="P1">ACCOUNT</h3>}/>
                    <Tab style={{outline:"none"}}  classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                    onClick={()=>setName('life_cycle') } label={<h3 className="P1">LIFE CYCLE</h3>}/>
                    {(user && user.icai_article) && <Tab style={{outline:"none"}}  classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                    onClick={()=> setName('icai')}  label={<h3 className="P1">ICAI</h3>}/>}
                    <Tab style={{outline:"none"}} classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                    onClick={()=>setName('change_password') }  label={<h3 className="P1">SECURITY</h3>}/>
                    <Tab style={{outline:"none"}}  classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                    onClick={()=>setName('leave')}  label={<h3 className="P1">LEAVE</h3>}/>
                    <Tab style={{outline:"none"}}  classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                    onClick={()=>setName('firm_assets')}  label={<h3 className="P1">Firm Assets</h3>}/>
                </Tabs>
            </header>
            <div style={{ borderTop: "2px solid grey ", marginLeft: 10, marginRight: 20 }}></div>
            {(() => {
                switch  (name) {
                    case 'profile':
                    return <GeneralDetails/>

                    case 'life_cycle':
                    return <LifeCycle/>

                    case 'icai':
                        return <ICAI/>
                    case 'change_password':
                        return <Security/>

                    case 'leave':
                        return <Leave/>

                    default:
                        return <FirmAssets/>
                }
            })()}
        </div>
    )
}

export default ProfileDetails;
