import axios from "axios";
import { BaseUrl } from "./api";
import {
    START_INVOICES_FETCH,
    INVOICES_FETCH_SUCCESS,
    INVOICES_FETCH_FAILED,
    START_INVOICE_ADD,
    INVOICE_ADD_SUCCESS,
    INVOICE_ADD_FAILURE,
    START_INVOICE_FETCH_BY_ID,
    INVOICE_FETCH_BY_ID_SUCCESS,
    INVOICE_FETCH_BY_ID_FAILED,
    START_INVOICE_UPDATE,
    INVOICE_UPDATE_FAILED,
    INVOICE_UPDATE_SUCCESS,
    START_IS_RECURRING_CHECK,
    SUCCESS_IS_RECURRING_CHECK,
    FAILED_IS_RECURRING_CHECK,
    START_FINAL_INVOICE_FETCH,
    SUCCESS_FINAL_INVOICE_FETCH,
    FAILED_FINAL_INVOICE_FETCH,
    GET_INVOICE_NUMBER_FETCH,
    GET_INVOICE_NUMBER_FAIL,
    GET_INVOICE_NUMBER_SUCCESS,
} from "./types";

export const finalInvoiceDetailsFetch = (token, data) => {
    return async (dispatch) => {
        dispatch({
            type: START_FINAL_INVOICE_FETCH,
        });
        try {
            const res = await axios.get(
                `${BaseUrl}/view_final_invoice/${data}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: SUCCESS_FINAL_INVOICE_FETCH,
                    payload: res.data["invoice"],
                });
                return res.data["invoice"];
            } else {
                dispatch({
                    type: FAILED_FINAL_INVOICE_FETCH,
                    payload: res.data.message,
                });
                return [];
            }
        } catch (error) {
            dispatch({
                type: FAILED_FINAL_INVOICE_FETCH,
                payload: error.message,
            });
            return [];
        }
    };
};

export const checkIfProjectIsRecurring = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_IS_RECURRING_CHECK,
        });
        try {
            const res = await axios.get(
                `${BaseUrl}/is_project_recurring/${data}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: SUCCESS_IS_RECURRING_CHECK,
                    payload: res.data["previous_actual_amount"],
                });
                return res.data["previous_actual_amount"];
            } else {
                dispatch({
                    type: FAILED_IS_RECURRING_CHECK,
                    payload: 0,
                });
            }
            return 0;
        } catch (error) {
            dispatch({
                type: FAILED_IS_RECURRING_CHECK,
                payload: error.message,
            });
            return 0;
        }
    };
};

export const updateInvoice = (data, token, id) => {
    return async (dispatch) => {
        dispatch({
            type: START_INVOICE_UPDATE,
        });
        try {
            let url =
                data.category == 0
                    ? `${BaseUrl}/invoices/final/${id}`
                    : `${BaseUrl}/invoices/${id}`;
            const res = await axios.put(
                url,
                { invoice: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: INVOICE_UPDATE_SUCCESS,
                    payload: "Successfully updated Invoice",
                    id: res.data.id
                });
            } else {
                dispatch({
                    type: INVOICE_UPDATE_FAILED,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: INVOICE_UPDATE_FAILED,
                payload: "Failed invoice  update",
            });
        }
    };
};

export const fetchInvoiceDetails = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_INVOICE_FETCH_BY_ID,
        });
        try {
            const res = await axios.get(`${BaseUrl}/invoices/${data}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: INVOICE_FETCH_BY_ID_SUCCESS,
                    payload: res.data["invoice"],
                });
                return res.data["invoice"];
            } else {
                dispatch({
                    type: INVOICE_FETCH_BY_ID_FAILED,
                    payload: "invoice fetch failed",
                });
            }
        } catch (error) {
            dispatch({
                type: INVOICE_FETCH_BY_ID_FAILED,
                payload: "invoice fetch failed",
            });
        }
    };
};
//client id,name
export const fetchInvoicesList = (token, type = "Final") => {
    return async (dispatch) => {
        dispatch({
            type: START_INVOICES_FETCH,
        });
        try {
            const res = await axios.get(`${BaseUrl}/invoices/type/${type}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    dataType: "json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.statusText == "OK") {
                dispatch({
                    type: INVOICES_FETCH_SUCCESS,
                    payload: res.data["invoice"],
                });
            } else {
                dispatch({
                    type: INVOICES_FETCH_FAILED,
                    payload: "Fetching invoiec list failed",
                });
            }
        } catch (error) {
            dispatch({
                type: INVOICES_FETCH_FAILED,
                payload: "Fetching invoice list failed",
            });
        }
    };
};

export const addInvoice = (data, token) => {
    return async (dispatch) => {
        dispatch({
            type: START_INVOICE_ADD,
        });
        try {
            const res = await axios.post(
                `${BaseUrl}/invoices/${data.category}`,
                { invoice: data },
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status){
                dispatch({
                    type: INVOICE_ADD_SUCCESS,
                    payload: "Sucessfully added invoices",
                    data: res.data.invoice_data

                });
            } else {
                dispatch({
                    type: INVOICE_ADD_FAILURE,
                    payload: res.data.message,
                });
            }
        } catch (error) {
            dispatch({
                type: INVOICE_ADD_FAILURE,
                payload: error.message,
            });
        }
    };
};
//organisation id and date
export const fetchPossiblenIvoiceNumber = (token, date, organisation_id) => {
    return async (dispatch) => {
        dispatch({
            type: GET_INVOICE_NUMBER_FETCH,
        });
        try {
            const res = await axios.get(
                `${BaseUrl}/invoice/get_possible_number/${organisation_id}/${date}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        dataType: "json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.data.status) {
                dispatch({
                    type: GET_INVOICE_NUMBER_SUCCESS,
                    payload: res.data["invoice_number"],
                });
            } else {
                dispatch({
                    type: GET_INVOICE_NUMBER_FAIL,
                    payload: "invoice fetch failed",
                });
            }
        } catch (error) {
            dispatch({
                type: GET_INVOICE_NUMBER_FAIL,
                payload: "invoice fetch failed",
            });
        }
    };
};
