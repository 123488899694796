import {START_FETCH_ROLES, ROLE_FETCH_SUCCESS, ROLE_FETCH_FAILED} from '../actions/types';

const DEFAULT_STATE={
    role: []
}
export default(state=DEFAULT_STATE, action)=>{
    switch(action.type){
        case START_FETCH_ROLES:
        return{
            ...state,
            role: []
        }
        case ROLE_FETCH_SUCCESS:
            return{
                ...state,
                role: action.payload
            }
        case ROLE_FETCH_FAILED:
            return{
                ...state,
                role: []
            }
        default:
            return state
    }
}

