import React, { useState } from "react";
import DebouncedInput from "../../DebouncedInput";
import Select from "react-select";

const selectStyles = {
    menu: (base) => ({
        ...base,
        zIndex: 100,
    }),
};
export const GlobalFilter = ({ filter, setFilter }) => {
    /**
     * get query to filter the tracks in global scope.
     * @param {string} filter query value to filter.
     * @param {function} setFilter setState function for query value.
     */
    const [value, setValue] = useState(filter);



    return (
        <span id="searchInput">
            <DebouncedInput
                type="text"
                placeholder="Search"
                value={value || ""}
                onChange={(e) => {
                    setFilter(e);
                }}
            />

        </span>
    );
};

export const SelectColumnFilter = ({ column = {}, options = [] }) => {
    const { getFilterValue, setFilterValue } = column;
    return (
        <span id="selectFilter">
            <Select
                options={options}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                name="selectFilter"
                styles={selectStyles}
                value={getFilterValue()|| undefined}
                onChange={(e) => {
                    setFilterValue(e || undefined);
                }}
            />
        </span>
    );
};

export const ColumnFilter = ({ column }) => {
    /**
     * get query to filter the tracks in column scope.
     * @param {object} column object of react-table column for filtering.
     */
    const { getFilterValue, setFilterValue } = column;

    return (
        <span id="columnSearchInput">
            <DebouncedInput
                type="text"
                placeholder="Search"
                value={getFilterValue || ""}
                onChange={(e) => {
                    setFilterValue(e);
                }}
            />
        </span>
    );
};
